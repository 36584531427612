import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { doctorProductDivisionView } from "../../../redux/actions/manufacturerActions";
import { Spinner } from "react-bootstrap";

const DoctorDivisionView = ({ setActiveDoctor, selectedDoctorID }) => {
  const dispatch = useDispatch();
  const [doctorData, setDoctorData] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(doctorData);

  useEffect(() => {
    setLoading(true);
    dispatch(
      doctorProductDivisionView(
        { doctor_id: selectedDoctorID },
        onDoctorDivViewSuccess,
        onDoctorDivViewError
      )
    );
  }, []);

  const onDoctorDivViewSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setDoctorData(data.data.data);
  };

  const onDoctorDivViewError = (err) => {
    console.log(err);
    setLoading(false);
  };

  return (
    <>
      <div
        className="main-list-container my-2 py-1 d-flex justify-content-between flex-column"
        style={{ position: "relative" }}
      >
        <main className="d-flex flex-column w-100 main-container">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => setActiveDoctor(31)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
          <div className="search-container d-flex justify-content-between "></div>
          {loading == true ? (
            <div className="product-view-spinner">
              <Spinner />
            </div>
          ) : (
            <div className="scroll-container">
              {" "}
              <div className="form-width px-4 mx-2">
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Doctor code</label>
                  <p className="fs_14">
                    {doctorData?.code ? doctorData?.code : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Doctor Name</label>
                  <p className="fs_14">
                    {doctorData?.name ? doctorData?.name : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">Phone Number</label>
                  <p className="fs_14">{`+91 ${
                    doctorData?.mobile ? doctorData?.mobile : "--"
                  }`}</p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">Email Address</label>
                  <p className="fs_14">
                    {doctorData?.email ? doctorData?.email : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">City</label>
                  <p className="fs_14">
                    {doctorData?.city?.name ? doctorData?.city?.name : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">Qualification</label>
                  <p className="fs_14">
                    {doctorData?.qualification
                      ? doctorData?.qualification
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default DoctorDivisionView;
