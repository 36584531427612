import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../../components/layout/table-container/table-container";
import TablePagination from "../../../components/Pagination/tablePagination";
import {
  manufacturerPremiumVersionList,
  manufacturerVersionList,
} from "../../../redux/actions/manufacturerActions";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import searcher from "../../../assets/icons/searchInput.svg";
import back from "../../../assets/icons/back.svg";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";

const ManufacturerPremiumVersionTable = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [active, setActive] = useState("");
  const [manufacturerVersionListData, setManufacturerVersionListData] =
    useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(
        manufacturerPremiumVersionList(
          { search, page, status },
          onSuccess,
          onError
        )
      );
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      dispatch(
        manufacturerPremiumVersionList(
          { search, page, status },
          onSuccess,
          onError
        )
      );
    }
  }, [status]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      manufacturerPremiumVersionList(
        { page, search, status },
        onSuccess,
        onError
      )
    );
  }, [page, status]);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setManufacturerVersionListData(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };
  return (
    <MainLayout breadCrumb="ADMIN  /  Manufacturer  / Version History">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="d-flex w-100">
            <div
              className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
              onClick={() => navigate(-1)}
            >
              <img src={back} alt="back_btn" />
              <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                BACK
              </span>
            </div>
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 px-2"
                type="text"
                placeholder="Search manufacturers here by manufacturer ID, manufacturer name..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <>
          <TableContainer>
            <thead>
              <tr className="table-rows color_747f9e">
                <th className="table-heads " width={"4%"}>
                  #
                </th>
                <th className="table-heads " width={"10%"}>
                  Manufacturer ID
                </th>
                <th className="table-heads " width={"12%"}>
                  Manufacturer Name
                </th>
                <th className="table-heads " width={"6%"}>
                  No. of Orders
                </th>
                <th className="table-heads " width={"6%"}>
                  No. of Distributors
                </th>
                <th className="table-heads " width={"8%"}>
                  <span onClick={() => setOpenStatus(!openStatus)}>
                    Status
                    {openStatus ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className=" mx-1" />
                    )}
                  </span>
                  {openStatus && (
                    <div className=" position-relative">
                      <div className="position-absolute status-container d-flex gap-3 px-3">
                        <div className="d-flex align-items-center cursor_pointer">
                          <input
                            className="radio me-2  cursor_pointer"
                            type="radio"
                            id="Active"
                            name="status"
                            value={"active"}
                            checked={status == "active"}
                            onClick={(e) => {
                              setOpenStatus(!openStatus);
                              setStatus(e.target.value);
                              setActive("active");
                            }}
                          />
                          <label
                            htmlFor="Active"
                            className={
                              active === "active"
                                ? "color_262c3d  cursor_pointer"
                                : "color_747f9e  cursor_pointer"
                            }
                          >
                            Active
                          </label>
                        </div>
                        <div className="d-flex align-items-center ">
                          <input
                            className="radio me-2 cursor_pointer"
                            type="radio"
                            id="Inactive"
                            name="status"
                            value={"inactive"}
                            checked={status == "inactive"}
                            onClick={(e) => {
                              setOpenStatus(!openStatus);
                              setStatus(e.target.value);
                              setActive("inactive");
                            }}
                          />
                          <label
                            htmlFor="Inactive"
                            className={
                              active === "inactive"
                                ? "color_262c3d cursor_pointer"
                                : "color_747f9e cursor_pointer"
                            }
                          >
                            Inactive
                          </label>
                        </div>
                        <div className="d-flex align-items-center ">
                          <input
                            className="radio me-2 cursor_pointer"
                            type="radio"
                            id="Draft"
                            name="status"
                            value={"draft"}
                            checked={status == "draft"}
                            onClick={(e) => {
                              setOpenStatus(!openStatus);
                              setStatus(e.target.value);
                              setActive("draft");
                            }}
                          />
                          <label
                            htmlFor="Draft"
                            className={
                              active === "draft"
                                ? "color_262c3d cursor_pointer"
                                : "color_747f9e cursor_pointer"
                            }
                          >
                            Draft
                          </label>
                        </div>

                        <button
                          className="filter-btn px-2 py-0 my-2 fs_12"
                          style={{ height: "28px" }}
                          onClick={() => {
                            setStatus(null);
                            setOpenStatus(!openStatus);
                          }}
                        >
                          CLEAR
                        </button>
                      </div>
                    </div>
                  )}
                </th>
                <th className="table-heads " width={"18%"}>
                  Actions
                </th>
              </tr>
            </thead>
            {loading == true ? (
              <div className="spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : manufacturerVersionListData?.Manufacturer?.length > 0 ? (
              <>
                {manufacturerVersionListData?.Manufacturer?.map(
                  (data, index) => (
                    <tbody>
                      <tr className="table-rows1 ">
                        <td className="table-each-row-data">
                          {(manufacturerVersionListData?.pagination
                            ?.currentPage -
                            1) *
                            10 +
                            (index + 1)}
                        </td>
                        <td className="table-each-row-data">
                          {data.employee_id}
                        </td>
                        <td className="table-each-row-data">{data.name}</td>
                        <td className="table-each-row-data">
                          {data?.orders?.length}
                        </td>
                        <td className="table-each-row-data text-center">
                          {data?.vendorDistributors?.length}
                        </td>
                        {data?.status == "active" ? (
                          <td className="table-each-row-data text_green">
                            Active
                          </td>
                        ) : data?.status == "inactive" ? (
                          <td className="table-each-row-data text_red">
                            Inactive
                          </td>
                        ) : (
                          <td className="table-each-row-data Medium_Purple">
                            Draft
                          </td>
                        )}
                        <td className="table-each-row-data ">
                          <div className="all-button d-flex align-items-center">
                            <button
                              className="button-view"
                              type="button"
                              onClick={() =>
                                navigate(
                                  `/manufacturer/premium-list/version-view/${data.id}`
                                )
                              }
                            >
                              <span className=""> View</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
                )}
              </>
            ) : (
              <>
                <div>
                  <tr className="nodata-container">~ No Data found ~</tr>
                </div>
              </>
            )}
            {/* {manufacturerVersionListData?.Manufacturer?.length > 0 ? (
          manufacturerVersionListData?.Manufacturer?.map((eachRow, index) => (
            <ManufacturerVersionTableData
              key={eachRow.id}
              data={eachRow}
              index={index}
              currentPage={manufacturerVersionListData?.pagination?.currentPage}
            />
          ))
        ) : (
          <div>
            <tr className="nodata-container">~ No Data found ~</tr>
          </div>
        )} */}
          </TableContainer>

          <TablePagination
            data={manufacturerVersionListData?.pagination}
            nextFunc={() => {
              navigate(
                `/manufacturer/premium-list/version-list/${manufacturerVersionListData?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/manufacturer/premium-list/version-list/${manufacturerVersionListData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        </>
      </div>
    </MainLayout>
  );
};

export default ManufacturerPremiumVersionTable;
