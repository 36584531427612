import React, { useEffect, useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import OnboardingDistributorListTable from "./onboarding-distributor-list-table";
import CreateBtn from "../../../components/buttons/createBtn";

const OnboardingDistributor = () => {
  const [search, setSearch] = useState(null);
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Distributor" && item
      );

      setUserData(permissionValue);
    }
  }, []);
  return (
    <MainLayout>
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 px-2"
              type="text"
              placeholder="Search distributors here by distributor ID, distributor name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
              <CreateBtn link="/distributors/onboard-distributor/create" />
            ) : (
              ""
            )}
          </div>
        </div>
        <OnboardingDistributorListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default OnboardingDistributor;
