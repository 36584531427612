import { call, take, takeLatest } from "redux-saga/effects";
import {
  CANCEL_ORDER,
  CUSTOMER_DETAIL,
  CUSTOMER_LIST,
  CUSTOMER_VERSION_DETAIL,
  CUSTOMER_VERSION_LIST,
  DELETE_CUSTOMER,
  DOCUMENT_IMGAGE_UPLOAD,
  EDIT_CUSTOMER,
  ENALBE_DISABLE_CUSTOMER,
  REQUEST_DETAIL,
  REQUEST_LIST,
  DISTRIBUTOR_REQUEST_LIST,
  DISTRIBUTOR_REQUEST_VIEW,
  APPROVE_REJECT_CUSTOMER,
} from "../actions/types";
import {
  customerDeleteApi,
  customerDocumentUploadApi,
  customerEditApi,
  customerEnableDisableApi,
  customerListApi,
  customerOrderCancelApi,
  customerVersionListApi,
  customerVersionViewApi,
  customerViewApi,
  onboardRequestListApi,
  onboardRequestViewApi,
  changeDistriRequestListApi,
  changeDistriRequestRequestViewApi,
  customerApproveRejectApi,
} from "../apis/customerApi";

export function* customerListWorker(action) {
  try {
    const res = yield call(customerListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerDetailWorker(action) {
  try {
    const res = yield call(customerViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerVersionWorker(action) {
  try {
    const res = yield call(customerVersionListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerVersionDetailWorker(action) {
  try {
    const res = yield call(customerVersionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerDeleteWorker(action) {
  try {
    console.log(action.data);
    const res = yield call(customerDeleteApi, action.data);

    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerEnableDisableWorker(action) {
  try {
    const res = yield call(customerEnableDisableApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerOrderCancelWorker(action) {
  try {
    const res = yield call(customerOrderCancelApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* onboardRequestListWorker(action) {
  try {
    const res = yield call(onboardRequestListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* onboardRequestDetailWorker(action) {
  try {
    const res = yield call(onboardRequestViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

//
export function* changeDistriRequestListWorker(action) {
  try {
    const res = yield call(changeDistriRequestListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* changeDistriRequestDetailWorker(action) {
  try {
    const res = yield call(changeDistriRequestRequestViewApi, {
      request_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerApprovalRejectWorker(action) {
  console.log(action);
  try {
    const res = yield call(customerApproveRejectApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* editCustomerWorker(action) {
  try {
    const res = yield call(customerEditApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerUpldWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(customerDocumentUploadApi, file, action.data);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* customerListWatcher() {
  yield takeLatest(CUSTOMER_LIST, customerListWorker);
}

export function* customerDetailWatcher() {
  yield takeLatest(CUSTOMER_DETAIL, customerDetailWorker);
}

export function* customerVersionWatcher() {
  yield takeLatest(CUSTOMER_VERSION_LIST, customerVersionWorker);
}

export function* customerVersionDetailWatcher() {
  yield takeLatest(CUSTOMER_VERSION_DETAIL, customerVersionDetailWorker);
}

export function* customerDeleteWatcher() {
  yield takeLatest(DELETE_CUSTOMER, customerDeleteWorker);
}

export function* customerOrderCancelWatcher() {
  yield takeLatest(CANCEL_ORDER, customerOrderCancelWorker);
}

export function* customerEnableDisableWatcher() {
  yield takeLatest(ENALBE_DISABLE_CUSTOMER, customerEnableDisableWorker);
}

export function* onboardRequestListWatcher() {
  yield takeLatest(REQUEST_LIST, onboardRequestListWorker);
}

export function* onboardRequestDetailWatcher() {
  yield takeLatest(REQUEST_DETAIL, onboardRequestDetailWorker);
}

export function* changeDistriRequestListWatcher() {
  yield takeLatest(DISTRIBUTOR_REQUEST_LIST, changeDistriRequestListWorker);
}
export function* changeDistriRequestDetailWatcher() {
  yield takeLatest(DISTRIBUTOR_REQUEST_VIEW, changeDistriRequestDetailWorker);
}
export function* customerApprovalRejectWatcher() {
  yield takeLatest(APPROVE_REJECT_CUSTOMER, customerApprovalRejectWorker);
}

export function* editCustomerWatcher() {
  yield takeLatest(EDIT_CUSTOMER, editCustomerWorker);
}

export function* customerUpldWatcher() {
  yield takeLatest(DOCUMENT_IMGAGE_UPLOAD, customerUpldWorker);
}
