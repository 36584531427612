import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import searcher from "../../../assets/icons/searchInput.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCityList,
  fetchDistrictList,
  fetchStateList,
} from "../../../redux/actions/commonActions";
import ErrorMsg from "../../../components/form/errorMsg";
import Select from "react-select";
import StateListTable from "../../../components/tables/stateListTable";
import CityListTable from "../../../components/tables/cityListTable";
import {
  settingsCityCreate,
  settingsCityEdit,
  settingsCityList,
} from "../../../redux/actions/settingsActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const SettingsCity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [disable, setDisable] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const [cityListData, setCityListData] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [page, setPage] = useState(1);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
    fetchCityList();
  }, []);

  useEffect(() => {
    fetchCityList();
  }, [page, search]);

  useEffect(() => {
    setPage(1);
    fetchCityList();
  }, [search]);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };

  const onGetStateError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    dispatch(
      fetchDistrictList(
        { state_id: stateId },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
  }, [editEnable, cityId]);

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log(data);
  };

  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const fetchCityList = () => {
    dispatch(settingsCityList({ page, search }, onListSuccess, onListError));
  };

  const onListSuccess = (data) => {
    console.log(data?.data);
    setCityListData(data?.data?.data);
  };

  const onListError = (err) => {
    console.log(err);
  };

  const initialValues = {
    name: "",
    state_id: "",
    district_id: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    state_id: Yup.string().trim().required("Required"),
    district_id: Yup.string().trim().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (!editEnable) {
      dispatch(settingsCityCreate(values, onSuccess, onError));
    } else {
      dispatch(
        settingsCityEdit(
          {
            city_id: cityId,
            district_id: values?.district_id,
            state_id: values?.state_id,
            name: values?.name,
          },
          onEditSuccess,
          onEditError
        )
      );
    }

    resetForm({
      values: {
        name: "",
        state_id: "",
        district_id: "",
      },
    });
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchCityList();
  };

  const onSuccess = (data) => {
    console.log(data);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setShowSuccessPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const onEditSuccess = (data) => {
    console.log(data);
    fetchCityList();
    setEditEnable(false);
  };

  const onEditError = (err) => {
    console.log(err);
  };

  const editSetForm = (item, index, setFieldValue) => {
    setEditEnable(true);
    // setEditIndex(index);
    setDistrictId(item.district_id);
    setStateId(item.state_id);
    setCityId(item?.id);
    setFieldValue("name", item.name);
    setFieldValue("state_id", item.state_id);
    setFieldValue("district_id", item.district_id);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, state_id, district_id },
    } = useFormikContext();
    useEffect(() => {
      if (
        name.trim() != "" &&
        state_id.trim() !== "" &&
        district_id.trim() !== ""
      ) {
        setDisable(false);
      } else setDisable(true);
    }, [name, state_id, district_id]);
    return null;
  };
  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  City">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">City</label>
                </div>
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="select-container mt-4 mb-2">
                    <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                      State
                    </label>
                    <Field name={"state_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={stateOptions}
                          styles={customStyles}
                          placeholder={"Enter your state"}
                          value={stateOptions.filter(
                            (s) => s.value == values.state_id
                          )}
                          onChange={(selected) => {
                            setSelectedState(selected?.value);
                            setFieldValue("state_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="state_id" />
                  </div>
                  <div className="select-container mt-4 mb-2">
                    <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                      District
                    </label>
                    <Field name={"district_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={districtOptions}
                          styles={customStyles}
                          placeholder={"Enter your district"}
                          value={districtOptions.filter(
                            (s) => s.value == values.district_id
                          )}
                          onChange={(selected) => {
                            setSelectedDistrict(selected?.value);
                            setFieldValue("district_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="district_id" />
                  </div>
                  <div className="my-2">
                    <InputField
                      id={"name"}
                      label={"City Name"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter new city name"}
                      name={"name"}
                      value={values?.name}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ maxWidth: "450px" }}
                  >
                    {!editEnable ? (
                      <button
                        type="submit"
                        className="add-button fs_18 fw_700 fs_sm_12 px-4 py-2 w-50"
                        style={{ maxWidth: "210px" }}
                        disabled={disable}
                      >
                        Create
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                        style={{ maxWidth: "210px" }}
                        // disabled={disable}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">City List</label>
              </div>
              <div className="mt-4 inside-table-container mx-4">
                <div className=" my-2 search-container ">
                  <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                    <img className="styled-SVG" src={searcher} color={""} />
                    <input
                      className="search-input fs_14 fw_400 px-2 text-dark"
                      type="text"
                      placeholder="Search city here by city name..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className=" mb-5">
                  <CityListTable
                    cityListData={cityListData}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                    setPage={setPage}
                    page={page}
                  />
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </MainLayout>
  );
};

export default SettingsCity;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
