import React, { useEffect, useRef, useState } from "react";
import "./login.css";
import subLogo from "../../../assets/images/auth/resetPasswordImage.png";
import asscentsLogo from "../../../assets/images/auth/jiviniLogo.png";
import eyeOn from "../../../assets/icons/auth/eye-on.svg";
import eyeOff from "../../../assets/icons/auth/eye-off.svg";
import eyeDead from "../../../assets/icons/auth/eyedead.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import ErrorMsg from "../../../components/form/errorMsg";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { createNewPassword } from "../../../redux/actions/authActions";

const CreateNewPassword = () => {
  const tokenData = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const formRef = useRef();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState();
  const [passwordDisable, setPasswordDisable] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState();
  const [newPasswordDisable, setNewPasswordDisable] = useState(false);
  const [data, setData] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState("");
  const [criteriaMet, setCriteriaMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const passwordCriteria = [
    {
      name: "Your password should be at least 8 characters long.",
      regex: /.{8,}/,
    },
    {
      name: "Include at least one uppercase letter in your password.",
      regex: /[A-Z]/,
    },
    {
      name: "Include at least one lowercase letter in your password.",
      regex: /[a-z]/,
    },
    { name: "Your password should contain at least one digit.", regex: /\d/ },
    {
      name: "specialCharacter",
      regex: /[!@#$%^&*()_+{}\[\]:;<>,.?~-]/,
    },
  ];

  const checkPasswordCriteria = (password) => {
    const newCriteriaMet = {};
    passwordCriteria.forEach((criteria) => {
      newCriteriaMet[criteria.name] = criteria.regex.test(password);
    });
    setCriteriaMet(newCriteriaMet);
  };

  useEffect(() => {
    console.log(tokenData);
  }, []);
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, "Password criteria not matching")
      .required("Required"),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"), // Custom validation
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setSubmitDisable(true);
    values["resetToken"] = tokenData.state;
    dispatch(createNewPassword(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    console.log("checkkkk", data);
    navigate("/new-password-created");
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };
  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const onError = (data, message) => {
    console.log(data.data.message);
    setSubmitDisable(false);
    formRef?.current?.setFieldError("password", data?.errors?.password);
    formRef?.current?.setFieldError(
      "confirm_password",
      data?.errors?.confirm_password
    );
    if (message) {
      setShowErrorPopup(true);
      const str = data.data.message;
      const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
      setShowErrorMsg(str_modify);
      console.log(str_modify);
      // errorToast(message);
    } else setShowErrorPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    console.log(str_modify1);
    // errorToast(data.message);
  };

  const handleChange = (e) => {
    checkPasswordCriteria(e.target.value);
    if (e.target.value.length > 0) {
      setNewPasswordDisable(true);
    } else {
      setNewPasswordDisable(false);
    }
  };

  const handleChange1 = (e) => {
    if (e.target.value.length > 0) {
      setPasswordDisable(true);
    } else {
      setPasswordDisable(false);
    }
  };
  const FormEnableCheck = () => {
    const {
      values: { confirm_password, password },
    } = useFormikContext();
    useEffect(() => {
      if (confirm_password.trim() != "" && password.trim() != "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [confirm_password, password]);
    return null;
  };

  return (
    <div className="login-container fn_Nunito d-flex flex-column flex-lg-row justify-content-center ">
      <div className="mt-">
        <img
          src={asscentsLogo}
          alt="website logo"
          className="login-logo-image d-lg-none"
        />
      </div>
      <div className="left-view  d-flex flex-column  justify-content-center mt-3 mt-lg-0">
        <img
          src={subLogo}
          alt="Asscents Website"
          className="login-img align-self-center"
        />
      </div>
      <div className="right-view justify-content-center d-flex flex-column mt-5 pt-5 pt-lg-0 mt-lg-0">
        <div className="form-container col-12 col-lg-7 mt-5 mt-lg-0 align-self-center d-flex justify-content-around  flex-column">
          <img
            src={asscentsLogo}
            alt="website logo"
            className="login-logo-image d-none d-lg-block"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue, values, setFieldError }) => (
              <Form>
                <FormEnableCheck />
                <h1 className="fw_700 fs_22 text-center text-lg-start">
                  Create New Password
                </h1>

                <div className=" mt-3">
                  <label className="w-100 mx-1 label-input color_262c3d">
                    New Password
                  </label>
                  <Field name={"password"}>
                    {({ field, meta }) => (
                      <div className="position-relative">
                        <input
                          {...field}
                          placeholder="Enter your password"
                          id={"password"}
                          className={"w-100 input-field"}
                          type={newPasswordVisible ? "text" : "password"}
                          onKeyUp={(e) => handleChange(e)}
                        />
                        <img
                          className="login_password_eye__icon cursor_pointer"
                          onClick={() =>
                            setNewPasswordVisible(!newPasswordVisible)
                          }
                          src={
                            newPasswordDisable
                              ? newPasswordVisible
                                ? eyeOn
                                : eyeOff
                              : eyeDead
                          }
                          alt="show password or not"
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMsg name="password" />
                </div>
                <div className="w-100 mt-lg-0">
                  <label className="w-100 mx-1 label-input color_262c3d">
                    Confirm New Password
                  </label>
                  <Field name={"confirm_password"}>
                    {({ field, meta }) => (
                      <div className="position-relative">
                        <input
                          {...field}
                          placeholder="Enter your password"
                          id={"confirm_password"}
                          type={passwordVisible ? "text" : "password"}
                          className={"w-100 input-field"}
                          onKeyUp={(e) => handleChange1(e)}
                        />
                        <img
                          className="login_password_eye__icon cursor_pointer"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          src={
                            passwordDisable
                              ? passwordVisible
                                ? eyeOn
                                : eyeOff
                              : eyeDead
                          }
                          alt="show password or not"
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMsg name="confirm_password" />
                </div>
                <button
                  className={`login-button`}
                  type="submit"
                  disabled={disable || submitDisable}
                >
                  {submitDisable && <Spinner size="sm" />} Create Password
                </button>
                <div
                  className="px-2 "
                  style={{
                    height: "17.5rem",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <div className="position-relative">
                    <div className="criteria-list my-5 gap-1 position-absolute pb-2">
                      <span className="fs_14 fw_700">Password Criteria</span>
                      {passwordCriteria.map((criteria, index) => (
                        <div className="d-flex align-items-center">
                          <div className="mx-2 mt-2"> * </div>
                          <div
                            key={index}
                            className={`criteria ${
                              criteriaMet[criteria.name]
                                ? "criteria-matched"
                                : ""
                            }`}
                          >
                            {criteria.name == "specialCharacter" ? (
                              <label>
                                Add at least one special character <br />
                                {`(e.g., !@#$%^&*()-_=+{}[]:;<>,.?~/)`} to your
                                password.
                              </label>
                            ) : (
                              <span>{criteria.name}</span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <ErrorPopup
            show={showErrorPopup}
            title={showErrorMsg}
            handleClose={closeErrorPopup}
          />
          <ErrorPopup
            show={showErrorPopup1}
            title={showErrorMsg1}
            handleClose={closeErrorPopup1}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
