import React from "react";

const ProductDivisionOffers = ({
  offerType,
  offersList,
  holding_value_unit,
}) => {
  return (
    <div className="m-4">
      <div className="basic-details-container-two ">
        <p className="basic-title">Offers, Quantity and Pricing Details</p>
      </div>

      <div className="product-details-container text-nowrap">
        <div className="px-2 ms-1 py-1 fs_14">
          <p className="color_262c3d fn_Nunito">
            Offer Type :
            <span className="color_2ad3e7 ms-2 fw_500">
              {offerType == "quantity"
                ? "Off Quantity"
                : offerType == "discount"
                ? "Off Discount"
                : offerType == "price"
                ? "Off Price"
                : ""}
            </span>
          </p>
        </div>

        {offersList?.length > 0 &&
          offersList?.map(
            (ele) =>
              ele?.is_default == true && (
                <div className="default-container ms-1">
                  <span className="default-text pt-2">Default</span>
                  {ele?.offers?.length > 0 &&
                    ele?.offers?.map((ex, idx) => (
                      <div className="d-flex gap-lg-4">
                        <div className="id-container ">
                          <p className="label-id color_747f9e">ID</p>
                          <p className="id-number">#{idx + 1}</p>
                        </div>
                        <div className="d-flex overflow-scroll  pb-2 ">
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Min Qty</p>
                            <p className="id-number">
                              {ex?.min_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Max Qty</p>
                            <p className="id-number">
                              {ex?.max_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">
                              Integral Qty
                            </p>
                            <p className="id-number">{ex?.integral_qty}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">MRP</p>
                            <p className="id-number">₹ {ex?.mrp}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTR</p>
                            <p className="id-number">₹ {ex?.ptr}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTS</p>
                            <p className="id-number">₹ {ex?.pts}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Offer"}
                              {offerType == "quantity" && "Quantity"}
                              {offerType == "discount" && "Discounts"}
                            </p>
                            <p className="id-number">
                              {" "}
                              {offerType == "price" && `₹ ${ex?.offer}`}
                              {offerType == "quantity" && `${ex?.offer}`}
                              {offerType == "discount" && `${ex?.offer} %`}
                            </p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Off Price"}
                              {offerType == "quantity" && "Off Quantity"}
                              {offerType == "discount" && "Off Discount"}
                            </p>
                            <p className="id-number">
                              {offerType == "price" && `₹ ${ex?.after_off}`}
                              {offerType == "quantity" && `${ex?.offer_qty}`}
                              {offerType == "discount" && `₹ ${ex?.after_off}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )
          )}

        {offersList?.length > 0 &&
          offersList?.map(
            (ele) =>
              ele?.is_default == false && (
                <div className="default-container ms-1">
                  <span className="default-text pt-2">{ele?.state?.name}</span>
                  {ele?.offers?.length > 0 &&
                    ele?.offers?.map((ex, idx) => (
                      <div className="d-flex gap-lg-4">
                        <div className="id-container ">
                          <p className="label-id color_747f9e">ID</p>
                          <p className="id-number">#{idx + 1}</p>
                        </div>
                        <div className="d-flex overflow-scroll  pb-2 ">
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Min Qty</p>
                            <p className="id-number">
                              {ex?.min_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Max Qty</p>
                            <p className="id-number">
                              {ex?.max_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">
                              Integral Qty
                            </p>
                            <p className="id-number">{ex?.integral_qty}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">MRP</p>
                            <p className="id-number">₹ {ex?.mrp}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTR</p>
                            <p className="id-number">₹ {ex?.ptr}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTS</p>
                            <p className="id-number">₹ {ex?.pts}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Offer"}
                              {offerType == "quantity" && "Quantity"}
                              {offerType == "discount" && "Discounts"}
                            </p>
                            <p className="id-number">
                              {offerType == "price" && `₹ ${ex?.offer}`}
                              {offerType == "quantity" && `${ex?.offer}`}
                              {offerType == "discount" && `${ex?.offer} %`}
                            </p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Off Price"}
                              {offerType == "quantity" && "Off Quantity"}
                              {offerType == "discount" && "Off Discount"}
                            </p>
                            <p className="id-number">
                              {offerType == "price" && `₹ ${ex?.after_off}`}
                              {offerType == "quantity" && `${ex?.offer_qty}`}
                              {offerType == "discount" && `₹ ${ex?.after_off}`}
                            </p>
                          </div>
                          {ex?.doctor_code !== null && (
                            <div className="id-container-w2">
                              <p className="label-id color_747f9e">
                                Linked With
                              </p>
                              <p className="id-number">{ex.doctor_code}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default ProductDivisionOffers;
