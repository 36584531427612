import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/modals/deleteModal";
import { faqDelete } from "../../redux/actions/faqActions";
import "./faq.css";
import ErrorPopup from "../../components/popups/ErrorPopup";
import SuccessPopup from "../../components/popups/SuccessPopup";

const FaqListTableData = ({ data, index, fetchFaqList, currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();

  const delFunc = () => {
    dispatch(faqDelete(deleteId, onDelSuccess, onDeleteError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchFaqList();
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const currentPageQuery = `?currentPage=${currentPage}`;

  const onDeleteError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data.faq_id}</td>
      <td className="table-each-row-data ">
        <span className="d-inline-block text-truncate col-10 faq-qstn-slice text-capitalize">
          {data.question ? data.question : "--"}
        </span>
      </td>
      {/* <td className="table-each-row-data">
          <span className="d-inline-block text-truncate col-10 faq-ans-slice">
            <span
              dangerouslySetInnerHTML={{ __html: data.answer }}
              className=""
            />           
          </span>
        </td> */}
      <td className="table-each-row-data text-capitalize">
        {data?.faqUserTypes?.map((ele, index) => {
          return (
            <span key={index}>
              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
              {ele?.user_type}
            </span>
          );
        })}
      </td>
      <td className="table-each-row-data">
        {data?.addedBy?.name ? data?.addedBy?.name : "--"}
      </td>

      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/faq/view/${data.id}`)}
          >
            <span className=""> View</span>
          </button>
          <span className="vr  vr_line mt-1 "></span>
          <button
            className="button-edit1"
            type="button"
            onClick={() => navigate(`/faq/edit/${data.id}` + currentPageQuery)}
          >
            Edit
          </button>

          <span className="vr  vr_line mt-1 "></span>
          <button
            className="button-disable"
            type="button"
            color={"green"}
            onClick={() => {
              setDeleteId(data.id);
              setDeleteShow(true);
            }}
          >
            Delete
          </button>
        </div>
      </td>

      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
        title={`FAQ - ${data.faq_id} ?`}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
    </tr>
  );
};

export default FaqListTableData;
