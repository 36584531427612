import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import back from "../../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { customerVersionDetial } from "../../../redux/actions/customerActions";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const CustomerVersionView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [customerVersionViewData, setCustomerVersionViewData] = useState([]);
  const [customerVersionOldData, setCustomerVersionOldData] = useState([]);
  const [customerVersionNewData, setCustomerVersionNewData] = useState([]);

  useEffect(() => {
    fetchCustomerVersionView();
  }, []);

  const fetchCustomerVersionView = () => {
    console.log(state);
    setLoading(true)
    dispatch(customerVersionDetial(state, onSuccess, onError));
  };
  const onSuccess = (data) => {
    setLoading(false)
    console.log(data.data.data.doctorVersion.old_data);
    setCustomerVersionViewData(data.data.data);
    setCustomerVersionOldData(data.data.data.doctorVersion.old_data);
    setCustomerVersionNewData(data.data.data.doctorVersion.new_data);
  };
  const onError = (err) => {
    setLoading(false)
    console.log(err);
  };

  const data = [
    {
      heading: "Jivini’s Doctor Code",
      title: customerVersionViewData?.code ? customerVersionViewData?.code : '--',
    },
    {
      heading: "Doctor Name",
      title: customerVersionViewData?.name ? customerVersionViewData?.name : '--',
    },
    {
      heading: "City",
      title: customerVersionViewData?.city?.name ? customerVersionViewData?.city?.name : '--',
    },
    {
      heading: "Pharmacy Type",
      title: customerVersionViewData?.pharmacy_type
        ? customerVersionViewData?.pharmacy_type.charAt(0).toUpperCase() + customerVersionViewData?.pharmacy_type.slice(1)
        : '--',
    },
    {
      heading: "Status",
      title: customerVersionViewData?.status === true ? "Active" : "Inactive",
    },
    {
      heading: "Updated By",
      title: customerVersionViewData?.doctorVersion?.updatedByAdmin?.name
        ? customerVersionViewData?.doctorVersion?.updatedByAdmin?.name
        : '--',
    },
    {
      heading: "Updated At",
      title: customerVersionViewData?.doctorVersion?.updatedAt ?
        moment(customerVersionViewData?.doctorVersion?.updatedAt).format(
          "DD MMM YYYY HH:mm A"
        ) : '--',
    },
  ];
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
    nestedKeyLabel: {
      fontSize: "14px",
    },
    value: {
      fontSize: "14px",
    },
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (customerVersionViewData.code
          ? ` / ${customerVersionViewData.code} - version history`
          : " / version history")}
    </span>
  );

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
      </div>
      {
        loading == true ?
          <div className="view-spinner-container">
            <LoadingSpinnerTable
              show={loading}
            />
          </div> :
          <>
            <div className="scrolling">
              <div className="pt-3 px-4 row">
                {data.map((item, index) => (
                  <div className="mb-2 col-4 col-md-3 col-xl-2 col-xxl label-conatiner mx-3 ">
                    <h5 className="color_b0b3c7 text-nowrap fs_15 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={` fs_16 fn_Nunito text-nowrap fw_600 `}>
                      {item.title === "Active" ? (
                        <p className={` fs_16 fn_Nunito text-nowrap fw_600 text_green`}>
                          {item.title}
                        </p>
                      ) : item.title === "Inactive" ? (
                        <p className={` fs_16 fn_Nunito text-nowrap fw_600 text_red`}>
                          {item.title}
                        </p>
                      ) : (
                        <p className={` fs_16 fn_Nunito text-nowrap fw_600`}>
                          {item.title}
                        </p>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div className=" mx-3 d-none d-xl-block form-sub-details my-3 mb-4">
                <div className="d-flex justify-content-between">
                  <div className="col-5 mx-4 ">JSON Old Data</div>
                  <div className="col-6">
                    <span className="ms-4 ps-2">JSON New Data</span>
                  </div>
                </div>
              </div>
              <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-5  mt-xl-0">
                <div
                  className="col-10 col-xl-6 my-2 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                  style={{ minHeight: "30rem" }}
                >
                  <div>
                    <h6
                      className="px-3 py-3 my-auto"
                      style={{
                        backgroundColor: "#E7E5FF",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Old Data
                    </h6>
                    <div className="">
                      <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                        <>
                          {customerVersionOldData === null ? (
                            <>Empty data</>
                          ) : (
                            <>
                              <JSONTree theme={theme} data={customerVersionOldData} />;
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-10 col-xl-6 my-2 mx-xl-3 mx-auto"
                  style={{ minHeight: "30rem" }}
                >
                  <div>
                    <h6
                      className="px-3 py-3 my-auto"
                      style={{
                        backgroundColor: "#E7E5FF",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      New Data
                    </h6>
                    <div className="">
                      <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                        <>
                          {customerVersionNewData === null ? (
                            <>Empty data</>
                          ) : (
                            <>
                              <JSONTree theme={theme} data={customerVersionNewData} />;
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

    </MainLayout>

  );
};

export default CustomerVersionView;
const newData = [
  {
    heading: "Checker Id",
    title: "adminVersionData?.id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.new_data?.[0]?.name",
  },
  {
    heading: "Admin Id",
    title: "adminVersionData?.new_data?.[0]?.admin_id",
  },
  {
    heading: "Approved By",
    title:
      "adminVersionData?.new_data?.[0]?.approved_by === null ? " -
      " : adminVersionData?.new_data?.[0]?.approved_by,",
  },
  {
    heading: "Approved Date",
    title: "sfsdfdf",
  },
  {
    heading: "Updated Date",
    title: "sfcsdvdsvc",
  },
  {
    heading: "Updated By",
    title: "cvcxvcxvcx",
  },
];
const oldData = [
  {
    heading: "Checker Id",
    title: "adminVersionData?.id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.old_data?.name",
  },
  {
    heading: "Role Id",
    title: "adminVersionData?.old_data?.role_id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.old_data?.name",
  },
  {
    heading: "Email",
    title: "adminVersionData?.old_data?.email",
  },
  {
    heading: "Admin Id",
    title: "adminVersionData?.old_data?.id",
  },

  {
    heading: "Approved Date",
    title: "dgfvgfdcbcv",
  },
  {
    heading: "Updated Date",
    title: "gsfgdgdfgdf",
  },
];
