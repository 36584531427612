import React, { useEffect, useState } from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "15px",
    maxWidth: "30rem",
    height: "15rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

const reimbursementOptions = {
  "forward_cfa": "Full Order Forward from CFA",
  "order_off_qty": "Order Offer Quantity",
  "full_order_qty": "Full Order Quantity",
  "credit_note": "Credit Note for Offer"
}

const DivisionListTable = ({
  maxWidth = "80rem",
  divisionList,
  reimbursement,
  editSetForm,
  setFieldValue,
}) => {
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table className="main-table">
          <thead>
            <tr className="table-rows color_747f9e text-nowrap">
              <th className="table-heads " width={"1%"}>
                #
              </th>
              <th className="table-heads " width={"3%"}>
                Division Name
              </th>
              <th className="table-heads " width={"3%"}>
                Offer Type
              </th>
              <th className="table-heads " width={"3%"}>
                Approval Level
              </th>
              <th className="table-heads" width={"8%"}>
                Reimbursement Method
              </th>
              <th className="table-heads" width={"8%"}>
                Order limit per customer
              </th>
              <th className="table-heads" width={"8%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {divisionList.map((item, index) => (
              <tr className="table-rows1 color_262c3d text-nowrap" key={index}>
                <td className="table-each-row-data">{index + 1}</td>
                <td className="table-each-row-data">{item?.name}</td>
                <td className="table-each-row-data">{item?.offer_type[0].charAt(0).toUpperCase() + item?.offer_type.slice(1)}</td>
                <td className="table-each-row-data">{item?.approval_level}</td>
                <td className="table-each-row-data">
                  {item?.reimbursement_method ? reimbursementOptions[item?.reimbursement_method] : "--"}
                </td>
                <td className="table-each-row-data">{`${item?.order_limit[0].charAt(0).toUpperCase() + item?.order_limit.slice(1)} - ₹ ${item?.order_amount}`}</td>
                <td className="table-each-row-data ">
                  <div className="all-button d-flex align-items-center">
                    <button
                      className="button-edit1"
                      type="button"
                      onClick={() => editSetForm(item, index, setFieldValue)}
                    >
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default DivisionListTable;
