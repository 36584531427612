import { Field } from "formik";
import React from "react";
import ErrorMsg from "../errorMsg";
import "./inputField.css";

const InputCapitalField = ({
  label,
  name,
  type,
  value,
  placeholder,
  color = "Neutral_Color",
  labelColor = "color_747F9E",
  fontWeigth = "fw_400",
  maxLength = null,
  setFieldValue,
  disabled=false
}) => {
  return (
    <div className={`${color} d-flex  flex-column mb-2`}>
      <label className={`${fontWeigth} ${labelColor}  fw_400 fs_14 my-1`}>
        {label}
      </label>
      <Field name={name}>
        {({ field, meta }) => (
          <div className="in-field d-flex">
            <input
              type={type}
              {...field}
              placeholder={placeholder}
              className={`input input-field`}
              maxLength={maxLength}
              value={value}
              onChange={(e) => {
                const value = e.target.value || "";
                setFieldValue(name, value?.toUpperCase());
              }}
              disabled={disabled}
            />
          </div>
        )}
      </Field>
      <ErrorMsg name={name} />
    </div>
  );
};

export default InputCapitalField;
