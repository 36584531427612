import { api } from "./api";

export const customerListApi = (params) => {
  return api.get(`/admin/customer/list`, { params });
};
export const customerViewApi = (params) => {
  return api.get(`/admin/customer/view`, { params: params });
};
export const customerVersionListApi = (params) => {
  return api.get(`/admin/customer/version-list`, { params });
};
export const customerVersionViewApi = (params) => {
  return api.get(`/admin/customer/version-view`, { params: params });
};
export const customerDeleteApi = (params) => {
  return api.delete(`/admin/customer/delete`, { params: params });
};
export const customerEnableDisableApi = (params) => {
  return api.post(`/admin/customer/enable-disable`, params);
};
export const customerOrderCancelApi = (params) => {
  return api.post(`/admin/customer/cancel-order`, params);
};
export const onboardRequestListApi = (params) => {
  return api.get(`/admin/customer/onboard-request-list`, { params });
};
export const onboardRequestViewApi = (params) => {
  return api.get(`/admin/customer/onboard-request-view`, { params: params });
};
export const customerApproveRejectApi = (params) => {
  return api.post(
    `/admin/customer/distributor-change-request-approval`,
    JSON.stringify(params)
  );
};
export const changeDistriRequestListApi = (params) => {
  return api.get(`/admin/customer/distributor-change-request-list`, { params });
};
export const changeDistriRequestRequestViewApi = (params) => {
  return api.get(`/admin/customer/distributor-change-request-view`, {
    params: params,
  });
};

export const customerEditApi = (params) => {
  return api.post(`/admin/customer/edit`, params);
};
export const customerDocumentUploadApi = (params, params2) => {
  console.log(params2, "log data");
  return api.post(
    `/admin/customer/upload-doc?user_type=${params2.user_type}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};
