import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import edit from "../../../assets/icons/edit.svg";
import delet from "../../../assets/icons/delete-bin.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import "../distributor.css";
import { useDispatch } from "react-redux";
import {
  deleteDistributor,
  distributorDetail,
  enableDisableDistributor,
  loginToPortalDistributor,
} from "../../../redux/actions/distributorActions";
import { Document, Page } from "react-pdf";
import EnableDisableModal from "../../../components/modals/enableDisableModal";
import DeleteModal from "../../../components/modals/deleteModal";
import PdfView from "../../../components/modals/pdfView";
import eye from "../../../assets/icons/viewEye.svg";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const OnboardingDistributorView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [distributorDetailData, setDistributorDetailData] = useState(null);
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Distributor" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  const distributorDetailfnc = () => {
    dispatch(distributorDetail(params?.id, onSuccess, onError));
    setLoading(true);
  };

  useEffect(() => {
    distributorDetailfnc();
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setDistributorDetailData(data.data.data.distributor);
    setLoading(false);
  };
  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const enableDisableFunc = () => {
    dispatch(
      enableDisableDistributor(
        distributorDetailData?.id,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    distributorDetailfnc();
  };

  const onEnableDisableSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc1, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const delFunc = () => {
    dispatch(
      deleteDistributor(distributorDetailData.id, deleteSuccess, deleteError)
    );
  };

  const timeOutFnc2 = () => {
    setShowSuccessPopup1(false);
    setDeleteShow(false);
    navigate("/distributor");
  };

  const deleteSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc2, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const deleteError = (err) => {
    console.log(err);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  // const renderVersionTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Version History
  //   </Tooltip>
  // );
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (distributorDetailData?.distributor_id
          ? ` / ${distributorDetailData?.distributor_id} - view`
          : " / view")}
    </span>
  );

  const basicData = [
    {
      heading: "Distributor ID",
      title: distributorDetailData?.distributor_id
        ? distributorDetailData?.distributor_id
        : "--",
    },
    {
      heading: "Firm Name",
      title: distributorDetailData?.firm_name
        ? distributorDetailData?.firm_name
        : "--",
    },
    {
      heading: "Drug license Number - 20A",
      title: distributorDetailData?.drug_licence_number_20_a
        ? distributorDetailData?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug license Number - 20B",
      title: distributorDetailData?.drug_licence_number_20_b
        ? distributorDetailData?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "FSSAI Number",
      title: distributorDetailData?.fssai_number
        ? distributorDetailData?.fssai_number
        : "--",
    },
    {
      heading: "GST Number",
      title: distributorDetailData?.gst_number
        ? distributorDetailData?.gst_number
        : "--",
    },
    {
      heading: "No. of Orders",
      title: distributorDetailData?.orders?.length
        ? distributorDetailData?.orders?.length
        : "--",
    },
    {
      heading: "Linked Manufacturer",
      title:
        distributorDetailData?.vendorDistributors?.length > 0
          ? distributorDetailData?.vendorDistributors?.map((ele, index) => {
              return (
                <span key={index}>
                  {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                  {ele?.vendor?.name}
                </span>
              );
            })
          : "--",
    },
  ];

  console.log(distributorDetailData?.vendorDistributors);

  const contactData = [
    {
      heading: "Mobile Number",
      title: distributorDetailData?.mobile
        ? `91 ${distributorDetailData?.mobile}`
        : "--",
    },
    {
      heading: "Alternate Mobile Number",
      title: distributorDetailData?.alternate_mobile
        ? `91 ${distributorDetailData?.alternate_mobile}`
        : "--",
    },
    {
      heading: "Email Address",
      title: distributorDetailData?.email ? distributorDetailData?.email : "--",
    },
    {
      heading: "Alternate Email Address",
      title: distributorDetailData?.alternate_email
        ? distributorDetailData?.alternate_email
        : "--",
    },
    {
      heading: "Address",
      title: `${
        distributorDetailData?.address?.address_one
          ? distributorDetailData?.address?.address_one
          : "--"
      } ${distributorDetailData?.address?.address_two ? "," : ""} ${
        distributorDetailData?.address?.address_two
          ? distributorDetailData?.address?.address_two
          : ""
      } ${distributorDetailData?.address?.address_three ? "," : ""} ${
        distributorDetailData?.address?.address_three
          ? distributorDetailData?.address?.address_three
          : ""
      }`,
    },
    {
      heading: "Landmark",
      title: distributorDetailData?.address?.landmark
        ? distributorDetailData?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: distributorDetailData?.state?.name
        ? distributorDetailData?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: distributorDetailData?.district?.name
        ? distributorDetailData?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: distributorDetailData?.city?.name
        ? distributorDetailData?.city?.name
        : "--",
    },
    {
      heading: "Pincode",
      title: distributorDetailData?.pincode
        ? distributorDetailData?.pincode
        : "--",
    },
    {
      heading: "District Covered",
      title:
        distributorDetailData?.distributorDistrictCoveredByDistributors.length >
        0
          ? distributorDetailData?.distributorDistrictCoveredByDistributors?.map(
              (ele, index) => {
                return (
                  <span key={index}>
                    {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                    {ele?.district?.name ? ele?.district?.name : "--"}
                  </span>
                );
              }
            )
          : "--",
    },
  ];

  useEffect(() => {
    console.log(
      distributorDetailData?.distributorDistrictCoveredByDistributors
    );
  }, []);

  const loginToPortalFunc = () => {
    dispatch(
      loginToPortalDistributor(params?.id, onNavigateSuccess, onNavigateError)
    );
  };

  const onNavigateSuccess = (data) => {
    if (data.data.data.is_true) {
      window.open(data?.data?.data?.url, "_blank");
    } else {
      setShowErrorPopup(true);
      setShowErrorMsg(data.data.message);
      setTimeout(timeOutFnc, 2000);
    }
  };

  const timeOutFnc = () => {
    setShowErrorPopup(false);
  };

  const onNavigateError = (err) => {
    console.log(err);
  };

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          {/* <VersionHistoryResBtn
            link={`/distributors/distributor/version-view/${params?.id}`}
          /> */}

          {(user_type == "super_admin" && userData?.write) ||
          user_type == "admin" ? (
            <>
              <AdminButton
                className="d-none d-xl-block"
                background="#7065E4"
                color="white"
                width="90px"
                onClick={() =>
                  navigate(
                    `/distributors/onboard-distributor/edit/${params?.id}`
                  )
                }
              >
                <span className="m-2">EDIT</span>
              </AdminButton>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  className="edit-small-btn py-1 px-3 mx-2 d-xl-none"
                  onClick={() =>
                    navigate(
                      `/distributors/onboard-distributor/edit/${params?.id}`
                    )
                  }
                >
                  <img src={edit} alt="edit" className="py-1" />
                </button>
              </OverlayTrigger>
              {distributorDetailData?.status && (
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="140px"
                  type="button"
                  onClick={() => loginToPortalFunc()}
                >
                  <span className="">Login to Portal</span>
                </AdminButton>
              )}
              <AdminButton
                className="d-none d-xl-block"
                background="#FF6F5B"
                color="white"
                width="100px"
                type="button"
                onClick={() => setDeleteShow(true)}
              >
                <span className="mx-2">DELETE</span>
              </AdminButton>
              <button
                className="delete-small-btn py-2 px-3 me-2 d-xl-none"
                // onClick={() => setDelShow(true)}
              >
                <img src={delet} alt="delete" />
              </button>

              <AdminButton
                color={distributorDetailData?.status ? "#FF6F5B" : "#24D626"}
                border={
                  distributorDetailData?.status
                    ? "1px solid #FF6F5B"
                    : "1px solid #24D626"
                }
                width="110px"
                onClick={() => setOnEnableShow(true)}
              >
                <span className="m-2">
                  {distributorDetailData?.status ? "DISABLE" : "ENABLE"}
                </span>
              </AdminButton>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {loading == true ? (
          <div className="view-spinner-container">
            <LoadingSpinnerTable show={loading} />
          </div>
        ) : (
          <div className="fn_Nunito scrolling">
            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
              <label className="basic-title mx-2 mb-1">Basic Details</label>
            </div>
            <div className="row mt-3 pt-3 ms-2">
              {basicData.map((item, index) => (
                <div className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container">
                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p className={`fs_15 fn_Nunito fw_500 color_262c3d`}>
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
              <label className="basic-title mx-2 mb-1">Contact Details</label>
            </div>
            <div>
              <div className="row mt-3">
                <div className="pt-3 ms-2 row">
                  {contactData.map((item, index) => (
                    <div className="mb-2 col-10 col-sm-6 col-md-4 col-xxl-2 mx-3 px-3 ">
                      <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
              <label className="basic-title mx-2 mb-1">Documents</label>
            </div>

            <div className="row mt-3">
              <div className="col-xl-4 col-6">
                <div className="mx-4 px-3">
                  <div className="my-2">
                    <div className="upload-image-label-container d-flex justify-content-between">
                      <label className="my-1 Neutral_Color">
                        Drug License Number - 20A
                      </label>
                    </div>

                    {distributorDetailData?.drug_licence_certificate_20_a !==
                      "" &&
                      distributorDetailData?.drug_licence_certificate_20_a !==
                        null && (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="distributor-doc-upload-container">
                            <Document
                              file={
                                distributorDetailData?.drug_licence_certificate_20_a
                              }
                            >
                              <Page pageNumber={1} width={415} />
                            </Document>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="distributor-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(
                                      distributorDetailData?.drug_licence_certificate_20_a
                                    );
                                    setFileName("Drug License Number - 20A");
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                          <PdfView
                            show={openPdf}
                            handleClose={() => setOpenPdf(false)}
                            pdfUrl={pdfFileUrl}
                            filename={filename}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-6">
                <div className="mx-4 px-3">
                  <div className="my-2">
                    <div className="upload-image-label-container d-flex justify-content-between">
                      <label className="my-1 Neutral_Color">
                        Drug License Number - 20B
                      </label>
                    </div>
                    {distributorDetailData?.drug_licence_certificate_20_b !==
                      "" &&
                      distributorDetailData?.drug_licence_certificate_20_b !==
                        null && (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="distributor-doc-upload-container">
                            <Document
                              file={
                                distributorDetailData?.drug_licence_certificate_20_b
                              }
                            >
                              <Page pageNumber={1} width={415} />
                            </Document>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="distributor-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(
                                      distributorDetailData?.drug_licence_certificate_20_b
                                    );
                                    setFileName("Drug License Number - 20B");
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                          <PdfView
                            show={openPdf}
                            handleClose={() => setOpenPdf(false)}
                            pdfUrl={pdfFileUrl}
                            filename={filename}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-6">
                <div className="mx-4 px-3">
                  <div className="my-2">
                    <div className="upload-image-label-container d-flex justify-content-between">
                      <label className="my-1 Neutral_Color">
                        GST Certificate
                      </label>
                    </div>
                    {distributorDetailData?.gst_certificate !== "" &&
                      distributorDetailData?.gst_certificate !== null && (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="distributor-doc-upload-container">
                            <Document
                              file={distributorDetailData?.gst_certificate}
                            >
                              <Page pageNumber={1} width={415} />
                            </Document>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="distributor-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(
                                      distributorDetailData?.gst_certificate
                                    );
                                    setFileName("GST Certificate");
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                          <PdfView
                            show={openPdf}
                            handleClose={() => setOpenPdf(false)}
                            pdfUrl={pdfFileUrl}
                            filename={filename}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <EnableDisableModal
        enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={distributorDetailData?.id}
        title={`distributor ${distributorDetailData?.distributor_id} ?`}
        // distributor_id={distributorDetailData.distributor_id}
        status={distributorDetailData?.status}
        show={onEnableShow}
      />
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      {/* <ErrorPopup
        show={showErrorPopup}
        title={"Distributor has not yet been onboarded"}
      /> */}
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </MainLayout>
  );
};

export default OnboardingDistributorView;
