import { Form, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import InputField from "../../../components/form/inputField/inputField";
import back from "../../../assets/icons/back.svg";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { chaptersDropDown, trainingQaEdit, trainingQaView } from "../../../redux/actions/ffTrainingAction";
import ErrorMsg from "../../../components/form/errorMsg";
import Select from "react-select";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const TrainingQaEdit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState();
    const [chapterOptions, setChapterOptions] = useState([]);
    const [qaViewData, setQaViewData] = useState(null);
    const params = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTrainingQa();
        dispatch(chaptersDropDown({}, onChapterSuccess, onError));

    }, []);


    const onChapterSuccess = (data) => {
        let newArr = data?.data?.data.map((item) => {
            let obj = {
                value: item?.id,
                label: item?.name
            }
            return obj
        })
        setChapterOptions(newArr)
    }

    const fetchTrainingQa = () => {
        setLoading(true)
        dispatch(trainingQaView({ id: params.id }, onFetchSuccess, onError));
    };

    const onFetchSuccess = (data) => {
        console.log(data.data.data);
        setQaViewData(data.data.data);
        setLoading(false)
    };

    const initialValues = {
        id: params.id,
        question: qaViewData?.question || "",
        priority: qaViewData?.priority || "",
        chapter_id: qaViewData?.chapter_id || "",
        video_url: qaViewData?.video_url || ""
    };

    const validationSchema = Yup.object().shape({
        question: Yup.string().required("Required"),
        video_url: Yup.string().required("Required"),
        chapter_id: Yup.string().required("Required"),
        priority: Yup.number().required("Required"),
    });

    const clearFunc = (setFieldValue) => {
        setFieldValue("question", "");
        setFieldValue("video_url", "");
        setFieldValue("chapter_id", "");
        setFieldValue("priority", "");
    };

    const onSubmit = (values) => {
        console.log(values);
        dispatch(trainingQaEdit(values, onSuccess, onError));
    };

    const timeOutFnc = () => {
        setShowSuccessPopup(false);
        navigate("/ff-training/qa/1");
    };

    const onSuccess = (data) => {
        console.log(data);
        setShowSuccessPopup(true);
        const str = data.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowSuccessMsg(str_modify);
        setTimeout(timeOutFnc, 2000);
    };

    const closeErrorPopup = () => {
        setShowErrorPopup(false);
    };

    const onError = (err) => {
        console.log(err);
        const str = err.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowErrorMsg(str_modify);
        setShowErrorPopup(true);
    };

    return (
        <MainLayout breadCrumb="ADMIN / FIELD FORCE TRAINING / CHAPTER - Create">
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ setFieldValue, values }) => (
                        <Form className="">
                            <div className="search-container d-flex justify-content-between ">
                                <div
                                    className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    <img src={back} alt="back_btn" />
                                    <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                                        BACK
                                    </span>
                                </div>
                                <div className=" d-flex justify-content-between m-1 mb-2">
                                    <AdminButton
                                        background="#E7E5FF"
                                        width="100px"
                                        type="button"
                                        onClick={() => clearFunc(setFieldValue)}
                                    >
                                        CLEAR
                                    </AdminButton>
                                    <AdminButton
                                        background="#7065e4"
                                        color="white"
                                        width="100px"
                                        type="submit"
                                    >
                                        <span className="m-2">SAVE</span>
                                    </AdminButton>
                                </div>
                            </div>
                            {loading == true ? (
                                <div className="view-spinner-container">
                                    <LoadingSpinnerTable show={loading} />
                                </div>
                            ) : (
                                <div className="">
                                    <div className="form-width ms-4 ps-3 py-1">
                                        <div className="my-2 ">
                                            <InputField
                                                id={"question"}
                                                label={"Question"}
                                                labelColor={"color_262c3d"}
                                                type={"text"}
                                                placeholder={"Enter Question"}
                                                name={"question"}
                                                value={values.question}
                                            />
                                        </div>
                                        <div className="my-2 ">
                                            <InputField
                                                id={"video_url"}
                                                label={"Video url"}
                                                labelColor={"color_262c3d"}
                                                type={"text"}
                                                placeholder={"Enter video url"}
                                                name={"video_url"}
                                                value={values.video_url}
                                            />
                                        </div>
                                        <div className="select-container">
                                            <label className="w-100 mx-1 fn_Nunito fs_14">Chapter</label>
                                            <Field name={"chapter_id"}>
                                                {({ field, meta }) => (
                                                    <Select
                                                        {...field}
                                                        options={chapterOptions}
                                                        styles={customStyles}
                                                        placeholder={"Enter role"}
                                                        value={chapterOptions.filter(
                                                            (s) => s.value == values.chapter_id
                                                        )}
                                                        onChange={(selected) => {
                                                            setFieldValue("chapter_id", selected?.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMsg name="chapter_id" />
                                        </div>
                                        <div className="my-2">
                                            <InputField
                                                id={"priority"}
                                                label={"Priority"}
                                                labelColor={"color_262c3d"}
                                                type={"number"}
                                                placeholder={"Enter priority"}
                                                name={"priority"}
                                                value={values.priority}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
                <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
                <ErrorPopup
                    show={showErrorPopup}
                    title={showErrorMsg}
                    handleClose={closeErrorPopup}
                />
            </div>
        </MainLayout>
    );
};

export default TrainingQaEdit;


export const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (base, state) => ({
        ...base,
        border: "1px solid #B0B3C7",
        color: "#464646",
        fontFamily: "Nunito Sans",
        fontWeight: "400",
        fontSize: "15px",
        borderRadius: "8px",
        width: "100%",
        maxWidth: "450px",
        height: "40px",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {},
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#B0B3C7",
            fontSize: "14px",
            fontFamily: "Nunito Sans",
        };
    },
    dropdownIndicator: (base) => ({
        ...base,
        color: "#a9a3e3", // Custom colour
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#E7E5FF" : null,
        color: "#00000",

        // fontFamily: "Nunito",
        "&:hover": {
            backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
        },
    }),
};
