import React from "react";

const ProductOffers = ({ offerType, offersList, holding_value_unit }) => {
  return (
    <div className="m-4">
      <div className="basic-details-container-two ">
        <p className="basic-title">Offers, Quantity and Pricing Details</p>
      </div>

      <div className="product-details-container text-nowrap">
        <div className="px-2 ms-1 py-1 fs_14">
          <p className="color_262c3d fn_Nunito">
            Offer Type :
            <span className="color_2ad3e7 ms-2 fw_500">
              {offerType == "quantity"
                ? "Off Quantity"
                : offerType == "discount"
                  ? "Off Discount"
                  : offerType == "price"
                    ? "Off Price"
                    : ""}
            </span>
          </p>
        </div>

        {offersList?.length > 0 &&
          offersList?.map(
            (ele) =>
              ele?.is_default == true && (
                <div className="default-container ms-1">
                  <span className="default-text pt-2">Default</span>
                  {ele?.offers?.length > 0 &&
                    ele?.offers?.map((ex, idx) => (
                      <div className="d-flex gap-lg-4">
                        <div className="id-container ">
                          <p className="label-id color_747f9e">ID</p>
                          <p className="id-number">#{idx + 1}</p>
                        </div>
                        <div className="d-flex overflow-scroll  pb-2 ">
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Min Qty</p>
                            <p className="id-number">
                              {ex?.min_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Max Qty</p>
                            <p className="id-number">
                              {ex?.max_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">
                              Integral Qty
                            </p>
                            <p className="id-number">{ex?.integral_qty}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">MRP</p>
                            <p className="id-number">₹ {ex?.mrp}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTR</p>
                            <p className="id-number">₹ {ex?.ptr}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTS</p>
                            <p className="id-number">₹ {ex?.pts}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Offer"}
                              {offerType == "quantity" && "Quantity"}
                              {offerType == "discount" && "Discounts"}
                            </p>
                            <p className="id-number">
                              {offerType == "price" && `₹ ${ex?.offer}`}
                              {offerType == "quantity" && `${ex?.offer}`}
                              {offerType == "discount" && `${ex?.offer} %`}
                            </p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {offerType == "price" && "Off Price"}
                              {offerType == "quantity" && "Off Quantity"}
                              {offerType == "discount" && "Off Discount"}
                            </p>
                            <p className="id-number">
                              {offerType == "price" &&
                                `₹ ${parseFloat(ex?.after_off).toFixed(2)}`}
                              {offerType == "quantity" &&
                                `${parseFloat(ex?.offer_qty).toFixed(2)}`}
                              {offerType == "discount" &&
                                `₹ ${parseFloat(ex?.after_off).toFixed(2)}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )
          )}

        {offersList?.length > 0 &&
          offersList?.map(
            (ele) =>
              ele?.is_default == false && (
                <div className="default-container ms-1">
                  <span className="default-text pt-2">{ele?.state?.name}</span>
                  {ele?.offers?.length > 0 &&
                    ele?.offers?.map((ex, idx) => (
                      <div className="d-flex gap-lg-4">
                        <div className="id-container ">
                          <p className="label-id color_747f9e">ID</p>
                          <p className="id-number">#{idx + 1}</p>
                        </div>
                        <div className="d-flex overflow-scroll  pb-2 ">
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Min Qty</p>
                            <p className="id-number">
                              {ex?.min_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">Max Qty</p>
                            <p className="id-number">
                              {ex?.max_qty} {holding_value_unit}
                            </p>
                          </div>
                          <div className="id-container-w1">
                            <p className="label-id color_747f9e">
                              Integral Qty
                            </p>
                            <p className="id-number">{ex?.integral_qty}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">MRP</p>
                            <p className="id-number">₹ {ex?.mrp}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTR</p>
                            <p className="id-number">₹ {ex?.ptr}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">PTS</p>
                            <p className="id-number">₹ {ex?.pts}</p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">Offer type</p>
                            <p className="id-number text-capitalize">
                              {ex.offer_type}
                            </p>
                          </div>
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {ex.offer_type == "price" && "Offer"}
                              {ex.offer_type == "quantity" && "Quantity"}
                              {ex.offer_type == "discount" && "Discounts"}
                            </p>
                            <p className="id-number">
                              {ex.offer_type == "price" &&
                                ex?.offer &&
                                `₹ ${ex?.offer}`}
                              {ex.offer_type == "quantity" &&
                                ex?.offer &&
                                `${ex?.offer}`}
                              {ex.offer_type == "discount" &&
                                ex?.offer &&
                                `${ex?.offer} %`}
                            </p>
                          </div>
                          {/* <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {ex.offer_type == "price" && "Off Price"}
                              {ex.offer_type == "quantity" && "Off Quantity"}
                              {ex.offer_type == "discount" && "Off Discount"}
                            </p>
                            <p className="id-number">
                              {ex.offer_type == "price" &&
                                ex?.after_off &&
                                `₹ ${ex?.after_off}`}
                              {ex.offer_type == "quantity" &&
                                // ex?.after_off &&
                                `${ex?.offer_qty}`}
                              {ex.offer_type == "discount" &&
                                ex?.after_off &&
                                `₹ ${ex?.after_off}`}
                            </p>
                          </div> */}
                          <div className="id-container-w2">
                            <p className="label-id color_747f9e">
                              {ex.offer_type == "price" && "Off Price"}
                              {ex.offer_type == "quantity" && "Off Quantity"}
                              {ex.offer_type == "discount" && "Off Discount"}
                            </p>
                            <p className="id-number">
                              {ex.offer_type == "price" &&
                                ex?.after_off &&
                                `₹ ${ex?.after_off}`}
                              {ex.offer_type == "quantity" &&
                                // ex?.after_off &&
                                `${ex?.offer_qty}`}
                              {ex.offer_type == "discount" &&
                                ex?.after_off &&
                                `₹ ${ex?.after_off}`}
                            </p>
                          </div>
                          {ex?.headQuater !== null && (
                            <div className="id-container-w2">
                              <p className="label-id color_747f9e">
                                Headquater ID
                              </p>
                              <p className="id-number">{ex.headQuater?.name}</p>
                            </div>
                          )}
                          {ex?.doctor_code !== null && (
                            <div className="id-container-w2">
                              <p className="label-id color_747f9e">
                                Linked Doctor
                              </p>
                              <p className="id-number text-wrap" >
                                {ex.doctor_code ? (
                                  ex.doctor_code.split(',').map((code, index) => (
                                    <span key={index}>
                                      {code}
                                      {index < ex.doctor_code.split(',').length - 1 && ', '}
                                    </span>
                                  ))
                                ) : (
                                  "--"
                                )}
                              </p>
                            </div>
                          )}
                          {ex?.pharmacy_code !== null && (
                            <div className="id-container-w2">
                              <p className="label-id color_747f9e">
                                Linked Pharmacy
                              </p>
                              <p className="id-number text-wrap ">
                                {ex.pharmacy_code ? (
                                  ex.pharmacy_code.split(',').map((code, index) => (
                                    <span key={index}>
                                      {code}
                                      {index < ex.pharmacy_code.split(',').length - 1 && ', '}
                                    </span>
                                  ))
                                ) : (
                                  "--"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default ProductOffers;
