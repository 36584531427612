import React from "react";

const FaqSvg = ({ fill = "#B0B3C7" ,
width = "14px",
height = "14px", }) => {
  return (
    <svg
    width={width}
    height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.963 1.53711C7.88569 0.46253 6.3955 -0.0979995 4.86977 0.0141064C2.23118 0.200038 0 2.6773 0 5.42253V8.36189C0 9.54037 0.957002 10.5001 2.13548 10.5001H4.74673C7.77359 10.5001 10.2946 8.36189 10.486 5.63034C10.5954 4.1046 10.0376 2.61442 8.963 1.53711ZM5.24984 8.45759C4.8479 8.45759 4.51978 8.13221 4.51978 7.72754C4.51978 7.3256 4.84516 6.99748 5.24984 6.99748C5.65451 6.99748 5.97989 7.32286 5.97989 7.72754C5.97989 8.13221 5.65178 8.45759 5.24984 8.45759ZM6.092 5.61667C5.83224 5.75885 5.83224 5.8026 5.83224 5.83268C5.83224 6.15532 5.56975 6.41508 5.24984 6.41508C4.92993 6.41508 4.66743 6.15532 4.66743 5.83268C4.66743 5.06981 5.27171 4.73623 5.53147 4.59404C5.701 4.50108 5.87872 4.28233 5.82404 3.97336C5.78302 3.74368 5.58889 3.54954 5.36194 3.51126C5.18421 3.47845 5.01195 3.52493 4.87797 3.63704C4.74399 3.74915 4.67017 3.91047 4.67017 4.08546C4.67017 4.40811 4.40768 4.66787 4.08776 4.66787C3.76785 4.66787 3.50536 4.40811 3.50536 4.08546C3.50536 3.56595 3.73231 3.07925 4.13151 2.74566C4.52798 2.41208 5.05023 2.2699 5.56702 2.3656C6.27246 2.48864 6.85213 3.06831 6.97791 3.77649C7.10095 4.51475 6.74823 5.25574 6.092 5.61667Z"
        fill={fill}
      />
      <path
        d="M13.9996 9.33464V12.2521C13.9996 13.2173 13.2149 14.0021 12.2497 14.0021H9.3322C7.6096 14.0021 6.103 13.0587 5.29639 11.667L5.321 11.6424C8.69784 11.3854 11.4294 8.87528 11.6509 5.71444C11.6618 5.57773 11.6563 5.44375 11.6591 5.30703L11.6673 5.29883C13.059 6.10818 13.9996 7.61204 13.9996 9.33464Z"
        fill={fill}
      />
    </svg>
  );
};

export default FaqSvg;
