import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import mail from "../../../../assets/icons/mail.png";
import "../../modal.css";

const InvoiceMailSent = ({ show, handleClose, manufacturerViewData }) => {
  console.log(manufacturerViewData);
  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="my-modal">
      <ModalBody className="m-3 py-4">
        <div className="">
          <img
            src={mail}
            alt="mail"
            className=" mx-auto d-block"
            style={{ height: "30px" }}
          />
          <p
            className="fn_Nunito fw_600 fs_22 color_262c3d my-2 px-0"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            Mail Sent Successfully
          </p>
        </div>
        <div className="text-start">
          <div className="mt-3">
            <label
              className="color_747f9e fs_14 me-4"
              style={{ minWidth: "130px" }}
            >
              Manufacturer ID
            </label>
            <label className="color_262c3d fs_14">
              {manufacturerViewData?.vendor?.employee_id}
            </label>
          </div>
          <div className="">
            <label
              className="color_747f9e fs_14 me-4"
              style={{ minWidth: "130px" }}
            >
              Manufacturer Name
            </label>
            <label className="color_262c3d fs_14">
            {manufacturerViewData?.vendor?.name}
            </label>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvoiceMailSent;
