import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { AdminButton } from "../buttons/versionHistoryBtn";
import "./modal.css";

const InvoiceSkipModal = ({
  show,
  handleClose,
  title,
  handleSkip,
  setCfaStepComplition,
  setHideCfaRemarks,
  setHideActualRemarks,
  getIsSkip,
  setShowTwoHeading,
  setCfaVerifyText,
  setFieldValue,
}) => {
  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="my-modal">
      <ModalBody>
        <div className="p-2">
          <p className="fn_Nunito fw_700 fs_18 color_262c3d my-2 px-0">
            Are you sure ?
          </p>
          <p className="fn_Nunito fw_400 fs_16 color_747f9e my-2 px-0">
            Do you want to skip this {title}
          </p>
          <div className="d-flex justify-content-around">
            <AdminButton
              width="130px"
              color="white"
              //   border="1px solid #FF6F5B"
              background="#7065e4"
              onClick={() => {
                handleSkip(setFieldValue);
                handleClose();
                setCfaStepComplition(true);
                setHideCfaRemarks(true);
                setHideActualRemarks(true);
                getIsSkip("skip_the_section");
                setCfaVerifyText(true);
              }}
            >
              <span className="m-2">CONTINUE</span>
            </AdminButton>
            <AdminButton
              width="130px"
              background="#FF6F5B"
              color="white"
              onClick={() => handleClose()}
            >
              <span className="m-2">CANCEL</span>
            </AdminButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvoiceSkipModal;
