import {
  CANCEL_ORDER,
  CUSTOMER_DETAIL,
  CUSTOMER_LIST,
  CUSTOMER_VERSION_DETAIL,
  CUSTOMER_VERSION_LIST,
  DELETE_CUSTOMER,
  DOCUMENT_IMGAGE_UPLOAD,
  EDIT_CUSTOMER,
  ENALBE_DISABLE_CUSTOMER,
  REQUEST_DETAIL,
  REQUEST_LIST,
  DISTRIBUTOR_REQUEST_LIST,
  DISTRIBUTOR_REQUEST_VIEW,
  APPROVE_REJECT_CUSTOMER,
} from "./types";

export const customerList = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const customerDetail = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_DETAIL,
    data,
    onSuccess,
    onError,
  };
};
export const customerVersionList = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const customerVersionDetial = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VERSION_DETAIL,
    data,
    onSuccess,
    onError,
  };
};
export const customerDelete = (data, onSuccess, onError) => {
  return {
    type: DELETE_CUSTOMER,
    data,
    onSuccess,
    onError,
  };
};
export const customerOrderCancel = (data, onSuccess, onError) => {
  return {
    type: CANCEL_ORDER,
    data,
    onSuccess,
    onError,
  };
};
export const customerEnableDisable = (data, onSuccess, onError) => {
  return {
    type: ENALBE_DISABLE_CUSTOMER,
    data,
    onSuccess,
    onError,
  };
};

export const onboardRequestList = (data, onSuccess, onError) => {
  return {
    type: REQUEST_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const onboardRequestDetail = (data, onSuccess, onError) => {
  return {
    type: REQUEST_DETAIL,
    data,
    onSuccess,
    onError,
  };
};

export const changeDistriRequestList = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_REQUEST_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const changeDistriRequestView = (id, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_REQUEST_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const custoDistriReqApproveReject = (data, onSuccess, onError) => {
  return {
    type: APPROVE_REJECT_CUSTOMER,
    data,
    onSuccess,
    onError,
  };
};

export const customerEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_CUSTOMER,
    data,
    onSuccess,
    onError,
  };
};
export const documentPhoto1 = (image, data, onSuccess, onError) => {
  return {
    type: DOCUMENT_IMGAGE_UPLOAD,
    image,
    data,
    onSuccess,
    onError,
  };
};
