import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EnableDisableModal from "../../../../components/modals/enableDisableModal";
import {
  enableDisableDivision,
  reimbursementList,
} from "../../../../redux/actions/manufacturerActions";
import "../../manufacturer.css";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import {
  geDivisionReportList,
  gevDivisionViewList,
} from "../../../../redux/actions/godsEyeViewActions";
import LoadingSpinner from "../../../../components/utilites/loaderSpinningContainer";
import searcher from "../../../../assets/icons/searchInput.svg";
import TablePagination from "../../../../components/Pagination/tablePagination";
import DivisionListTableData from "./division-list-table-data";
import exportFromJSON from "export-from-json";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import UpArrow from "../../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../../assets/icons/filterArrowDown.svg";

const DivisionList = ({
  setActiveDivision,
  selectedDivisionData,
  setSelectedDivisionData,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [divisionData, setDivisionData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false); // CSV
  const [showErrorMsg, setshowErrorMsg] = useState(""); // CSV
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const vendorId = params?.id.split("&")?.[0];
  const [onSortName, setonSortName] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      gevDivisionViewList(
        { vendor_id: vendorId, order_name: onSortName },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [onSortName]);

  const divisionList = () => {
    dispatch(
      gevDivisionViewList({ vendor_id: vendorId, order_name: onSortName }, onFetchSuccess, onFetchError)
    );
  };

  useEffect(() => {
    dispatch(
      gevDivisionViewList(
        { vendor_id: vendorId, page, order_name: onSortName },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [page, onSortName]);

  useEffect(() => {
    setPage(1);
    dispatch(
      gevDivisionViewList(
        { vendor_id: vendorId, order_name: onSortName, page, search },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [search, onSortName]);

  const onFetchSuccess = (data) => {
    setLoading(false);
    setDivisionData(data?.data?.data);
  };
  const onFetchError = (err) => {
    console.log(err);
  };
  const enableDisableFunc = (obj) => {
    console.log(obj);
    dispatch(
      enableDisableDivision(obj, onEnableDisableSuccess, onEnableDisableError)
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    divisionList();
  };

  const onEnableDisableSuccess = (data) => {
    setOnEnableShow(false);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };
  const onEnableDisableError = (err) => {
    console.log(err);
    setOnEnableShow(false);
  };

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(geDivisionReportList(vendorId, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };
  console.log("sort name=", onSortName);

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "Division Name": data?.name ? data?.name : "--",
          "Offer Type": data?.offer_type
            ? data?.offer_type.charAt(0).toUpperCase() +
            data?.offer_type.slice(1)
            : "--",
          "Reimbursement Method": data?.reimbursement_method ? data?.reimbursement_method : "--",
          "Order Limit Per Customer": `${data?.order_limit ? data?.order_limit : "--"
            } - ₹ ${data?.order_amount}`,
          Status: data?.status == true ? "Yes" : "No",
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setshowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setshowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  return (
    <>
      <div className="ms-5 mx-3 my-3 mt-4">
        <div className="d-flex justify-content-between">
          <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search division here by division name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <button
              className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
              type="button"
              onClick={() => exportTableCsv()}
            >
              download
            </button>
          </div>
        </div>
        <div
          className="manufacturer-division-table-container"
          style={{ height: "33.5rem" }}
        >
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads py-2" width={"2%"}>
                  #
                </th>
                <th className="table-heads" width={"8%"}>
                  <span onClick={() => setonSortName(!onSortName)}>
                    Division Name
                    {onSortName ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className=" mx-1" />
                    )}
                  </span>
                </th>
                <th className="table-heads" width={"6%"}>
                  Offer Type
                </th>
                <th className="table-heads" width={"6%"}>
                  Reimbursement Method
                </th>
                <th className="table-heads" width={"8%"}>
                  Order limit per customer
                </th>
                <th className="table-heads" width={"8%"}>
                  Status
                </th>
                <th className="table-heads" width={"18%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="division-spinner-container">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {divisionData?.vendorDivision?.length > 0 ? (
                    divisionData?.vendorDivision?.map((data, index) => (
                      <DivisionListTableData
                        data={data}
                        index={index}
                        setActiveDivision={setActiveDivision}
                        setSelectedDivisionData={setSelectedDivisionData}
                        currentPage={divisionData?.pagination?.currentPage}
                        user_type={user_type}
                        userData={userData}
                        setOnEnableShow={setOnEnableShow}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center list-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          <EnableDisableModal
            enableDisableFunc={enableDisableFunc}
            handleClose={() => setOnEnableShow(false)}
            title={`Division ${selectedDivisionData?.name} ?`}
            id={{
              division_id: selectedDivisionData?.id,
              vendor_id: params?.id,
            }}
            status={selectedDivisionData?.status}
            show={onEnableShow}
          />
          <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
        </div>
      </div>
      {divisionData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={divisionData?.pagination}
          nextFunc={() => {
            setPage(divisionData?.pagination?.currentPage + 1);
          }}
          prevFunc={() => {
            setPage(divisionData?.pagination?.currentPage - 1);
          }}
        />
      )}
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeSuccessPopup}
      />
    </>
  );
};

export default DivisionList;
