import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import back from "../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { manufacturerVersionDetial } from "../../redux/actions/manufacturerActions";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const ManufacturerVersionView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [manufacturerVersionViewData, setManufacturerVersionViewData] =
    useState([]);
  const [manufacturerVersionViewNewData, setManufacturerVersionViewNewData] =
    useState([]);
  const [manufacturerVersionViewOldData, setManufacturerVersionViewOldData] =
    useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(manufacturerVersionDetial(params.id, onSuccess, onError));
    setLoading(true);
  }, []);

  const onSuccess = (data) => {
    setManufacturerVersionViewData(data.data.data);
    setManufacturerVersionViewOldData(data.data.data.manufacturer.old_data);
    setManufacturerVersionViewNewData(data.data.data.manufacturer.new_data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
    nestedKeyLabel: {
      fontSize: "14px",
    },
    value: {
      fontSize: "14px",
    },
  };
  const data = [
    {
      heading: "Manufacturer ID",
      title: manufacturerVersionViewData?.manufacturer?.vendor?.employee_id,
    },
    {
      heading: "Manufacturer Name",
      title: manufacturerVersionViewData?.manufacturer?.vendor?.name,
    },
    {
      heading: "No. of Orders",
      title: manufacturerVersionViewData?.no_of_orders,
    },
    {
      heading: "No. of Distributors",
      title: manufacturerVersionViewData?.no_of_distributors,
    },
    {
      heading: "Status",
      title:
        manufacturerVersionViewData?.manufacturer?.vendor?.status == "active"
          ? "Active"
          : manufacturerVersionViewData?.manufacturer?.vendor?.status ==
            "inactive"
          ? "Inactive"
          : "Draft",
    },
    {
      heading: "Updated By",
      title: manufacturerVersionViewData?.manufacturer?.updatedBy?.name
        ? manufacturerVersionViewData?.manufacturer?.updatedBy?.name
        : "--",
    },
    {
      heading: "Updated At",
      title: moment(
        manufacturerVersionViewData?.manufacturer?.updatedAt
      ).format("DD MMM YYYY HH:mm A"),
    },
  ];

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        ` /  ${manufacturerVersionViewData?.manufacturer?.vendor?.employee_id} - Version History `}
    </span>
  );

  return (
    <>
      <MainLayout breadcrumbData={Breadcrumb}>
        <div className="search-container d-flex justify-content-between ">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => navigate(-1)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
        </div>
        <div>
          {loading == true ? (
            <div className="view-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : (
            <>
              <div className="pt-3 px-4 row">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 col-4 col-md-3 col-xl-2 col-xxl label-conatiner mx-3 "
                  >
                    <h5 className="color_b0b3c7 text-nowrap fs_15 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={` fs_16 fn_Nunito text-nowrap fw_600 `}>
                      {item.title === "Active" ? (
                        <p
                          className={` fs_16 fn_Nunito text-nowrap fw_600 text_green`}
                        >
                          {item.title}
                        </p>
                      ) : item.title === "Inactive" ? (
                        <p
                          className={` fs_16 fn_Nunito text-nowrap fw_600 text_red`}
                        >
                          {item.title}
                        </p>
                      ) : item.title === "Draft" ? (
                        <p
                          className={` fs_16 fn_Nunito text-nowrap fw_600 Medium_Purple`}
                        >
                          {item.title}
                        </p>
                      ) : (
                        <p className={` fs_16 fn_Nunito text-nowrap fw_600`}>
                          {item.title}
                        </p>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div className=" mx-3 d-none d-xl-block form-sub-details my-3 mb-4">
                <div className="d-flex justify-content-between">
                  <div className="col-5 mx-4 ">JSON Old Data</div>
                  <div className="col-6">
                    <span className="ms-4 ps-2">JSON New Data</span>
                  </div>
                </div>
              </div>
              <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-5  mt-xl-0">
                <div
                  className="col-10 col-xl-6 my-2 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                  style={{ minHeight: "30rem" }}
                >
                  <div>
                    <h6
                      className="px-3 py-3 my-auto"
                      style={{
                        backgroundColor: "#E7E5FF",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Old Data
                    </h6>
                    <div className="">
                      <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                        <>
                          {manufacturerVersionViewOldData === null ? (
                            <>Empty data</>
                          ) : (
                            <>
                              <JSONTree
                                theme={theme}
                                data={manufacturerVersionViewOldData}
                              />
                              ;
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-10 col-xl-6 my-2 mx-xl-3 mx-auto"
                  style={{ minHeight: "30rem" }}
                >
                  <div>
                    <h6
                      className="px-3 py-3 my-auto"
                      style={{
                        backgroundColor: "#E7E5FF",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      New Data
                    </h6>
                    <div className="">
                      <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                        <>
                          {manufacturerVersionViewNewData === null ? (
                            <>Empty data</>
                          ) : (
                            <>
                              <JSONTree
                                theme={theme}
                                data={manufacturerVersionViewNewData}
                              />
                              ;
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default ManufacturerVersionView;
