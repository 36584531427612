import {
  LOGIN,
  FORGET_PASSWORD,
  VERIFY_EMAIL,
  RESET_PASSWORD,
  LOGOUT,
  CREATE_NEW_PASSWORD,
} from "./types";

export const login = (email, password, onSuccess, onError) => {
  return {
    type: LOGIN,
    email,
    password,
    onSuccess,
    onError,
  };
};

export const forgetPassword = (email, onSuccess, onError) => {
  return {
    type: FORGET_PASSWORD,
    email,
    onSuccess,
    onError,
  };
};

export const verifyResetPassword = (resetToken, onSuccess, onError) => {
  return {
    type: VERIFY_EMAIL,
    resetToken,
    onSuccess,
    onError,
  };
};

export const resetPassword = (data, onSuccess, onError) => {
  return {
    type: RESET_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};

export const createNewPassword = (data, onSuccess, onError) => {
  return {
    type: CREATE_NEW_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};


export const logout = (onSuccess, onError) => {
  return {
    type: LOGOUT,
    onSuccess,
    onError,
  };
};
