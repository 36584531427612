import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { manufacturerVersionList } from "../../redux/actions/manufacturerActions";
import ManufacturerVersionTableData from "./manufacturer-version-table-data";
import UpArrow from "../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../assets/icons/filterArrowDown.svg";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const ManufacturerVersionTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [active, setActive] = useState("");
  const [manufacturerVersionListData, setManufacturerVersionListData] =
    useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(
        manufacturerVersionList({ search, page, status }, onSuccess, onError)
      );
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      dispatch(
        manufacturerVersionList({ search, page, status }, onSuccess, onError)
      );
    }
  }, [status]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      manufacturerVersionList({ page, search, status }, onSuccess, onError)
    );
  }, [page, status]);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setManufacturerVersionListData(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };
  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Manufacturer ID
            </th>
            <th className="table-heads " width={"12%"}>
              Manufacturer Name
            </th>
            <th className="table-heads " width={"6%"}>
              No. of Orders
            </th>
            <th className="table-heads " width={"6%"}>
              No. of Distributors
            </th>
            <th className="table-heads " width={"8%"}>
              <span onClick={() => setOpenStatus(!openStatus)}>
                Status
                {openStatus ? (
                  <img src={UpArrow} className="mb-1 mx-1" />
                ) : (
                  <img src={DownArrow} className=" mx-1" />
                )}
              </span>
              {openStatus && (
                <div className=" position-relative">
                  <div className="position-absolute status-container d-flex gap-3 px-3">
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="Active"
                        name="status"
                        value={"active"}
                        checked={status == "active"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("active");
                        }}
                      />
                      <label
                        htmlFor="Active"
                        className={
                          active === "active"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Active
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="Inactive"
                        name="status"
                        value={"inactive"}
                        checked={status == "inactive"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("inactive");
                        }}
                      />
                      <label
                        htmlFor="Inactive"
                        className={
                          active === "inactive"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Inactive
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="draft"
                        name="status"
                        value={"draft"}
                        checked={status == "draft"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("draft");
                        }}
                      />
                      <label
                        htmlFor="draft"
                        className={
                          active === "draft"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Draft
                      </label>
                    </div>

                    <button
                      className="filter-btn px-2 py-0 my-2 fs_12"
                      style={{ height: "28px" }}
                      onClick={() => {
                        setStatus(null);
                        setOpenStatus(!openStatus);
                      }}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              )}
            </th>
            <th className="table-heads " width={"18%"}>
              Actions
            </th>
          </tr>
        </thead>
        {loading == true ? (
          <div className="spinner-container">
            <LoadingSpinnerTable show={loading} />
          </div>
        ) : manufacturerVersionListData?.Manufacturer?.length > 0 ? (
          <>
            {manufacturerVersionListData?.Manufacturer?.map(
              (eachRow, index) => (
                <ManufacturerVersionTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  currentPage={
                    manufacturerVersionListData?.pagination?.currentPage
                  }
                />
              )
            )}
          </>
        ) : (
          <>
            <div>
              <tr className="nodata-container">~ No Data found ~</tr>
            </div>
          </>
        )}
        {/* {manufacturerVersionListData?.Manufacturer?.length > 0 ? (
          manufacturerVersionListData?.Manufacturer?.map((eachRow, index) => (
            <ManufacturerVersionTableData
              key={eachRow.id}
              data={eachRow}
              index={index}
              currentPage={manufacturerVersionListData?.pagination?.currentPage}
            />
          ))
        ) : (
          <div>
            <tr className="nodata-container">~ No Data found ~</tr>
          </div>
        )} */}
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        <TablePagination
          data={manufacturerVersionListData?.pagination}
          nextFunc={() => {
            navigate(
              `/manufacturer/subscibe-list/version-list/${manufacturerVersionListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/manufacturer/subscibe-list/version-list/${manufacturerVersionListData?.pagination?.currentPage - 1
              }`
            );
            setPage(page - 1);
          }}
        />
      </div>
    </>
  );
};

export default ManufacturerVersionTable;
