import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/modals/deleteModal";
import EnableDisableModal from "../../components/modals/enableDisableModal";
import {
  deleteDistributor,
  enableDisableDistributor,
} from "../../redux/actions/distributorActions";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DistributorListTableData = ({
  data,
  index,
  distributorListFunc,
  currentPage,
}) => {
  const navigate = useNavigate();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Distributor" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  const enableDisableFunc = (id) => {
    dispatch(
      enableDisableDistributor(id, onEnableDisableSuccess, onEnableDisableError)
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    distributorListFunc();
  };

  const onEnableDisableSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const delFunc = () => {
    dispatch(deleteDistributor(data.id, deleteSuccess, deleteError));
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup1(false);
    setDeleteShow(false);
    distributorListFunc();
  };

  const deleteSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc1, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const deleteError = (err) => {
    console.log(err);
    setDeleteShow(false);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {data?.distributorDistrictCoveredByDistributors?.map((ele, index) => (
        <span key={index}>
          {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
          {ele?.district?.name ? ele?.district?.name : "--"}
        </span>
      ))}
    </Tooltip>
  );

  return (
    <tr className="table-rows1" key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data?.distributor_id}</td>
      <td className="table-each-row-data text-capitalize">{data?.firm_name}</td>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <td
          className="table-each-row-data text-truncate text-capitalize"
          style={{ maxWidth: "250px" }}
        >
          {data?.distributorDistrictCoveredByDistributors != [] &&
          data?.distributorDistrictCoveredByDistributors.length > 0
            ? data?.distributorDistrictCoveredByDistributors.map(
                (ele, index) => {
                  return (
                    <span key={index}>
                      {index ? (
                        <span className="mx-1 color_b0b3c7">|</span>
                      ) : (
                        ""
                      )}
                      {ele?.district?.name ? ele?.district?.name : "--"}
                    </span>
                  );
                }
              )
            : "--"}
        </td>
      </OverlayTrigger>
      <td className="table-each-row-data text-center">{data.noOfOrders}</td>
      <td className="table-each-row-data text-center">
        {data.noOfManufacturers}
      </td>
      {data.status === true ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : (
        <td className="table-each-row-data text_red">Inactive</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/distributors/distributor/view/${data.id}`)
            }
          >
            <span className=""> View</span>
          </button>
          {(user_type == "super_admin" && userData?.write) ||
          user_type == "admin" ? (
            <>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className="button-edit1"
                type="button"
                onClick={() =>
                  navigate(`/distributors/distributor/edit/${data.id}`)
                }
              >
                Edit
              </button>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className={data.status ? `button-disable` : `button-enable`}
                type="button"
                color={"green"}
                onClick={() => setOnEnableShow(true)}
              >
                {data.status ? "disable" : "enable"}
              </button>
              <span className="vr vr_line mt-1 "></span>
              <button
                className="button-disable"
                type="button"
                color={"green"}
                onClick={() => {
                  setDeleteShow(true);
                }}
              >
                Delete
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      </td>
      <EnableDisableModal
        enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={data.id}
        title={
          data?.distributor_id !== null
            ? `distributor - ${data.distributor_id} ?`
            : `distributor ?`
        }
        distributor_id={data.distributor_id}
        status={data.status}
        show={onEnableShow}
      />
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        title={
          data?.distributor_id !== null
            ? `distributor - ${data.distributor_id} ?`
            : `distributor ?`
        }
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </tr>
  );
};

export default DistributorListTableData;
