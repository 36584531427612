import React from "react";

const FieldForceVerificationTableData = ({
  data,
  index,
  checkedFieldForce,
  isChecked,
}) => {
  return (
    <tr className="table-rows1 " key={index}>
      <td className="table-each-row-data" width={"6%"}>
        <input
          type={"checkbox"}
          className="CheckBox my-1"
          value="Full order forward from CFA"
          name="ReimbursementCheck"
          onChange={(e) => checkedFieldForce(e, data)}
          checked={isChecked}
        />
      </td>
      <td className="table-each-row-data">{data?.sl_no}</td>
      <td className="table-each-row-data">{data?.employee_id}</td>
      <td className="table-each-row-data">{data?.name}</td>
      <td className="table-each-row-data">{data?.designation}</td>
      <td className="table-each-row-data">{data?.mobile}</td>
      <td className="table-each-row-data">{data?.division_name}</td>
      <td className="table-each-row-data">{data?.headQuarters}</td>
      <td className="table-each-row-data">{data?.state}</td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button className="button-disable" type="button" color={"green"}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default FieldForceVerificationTableData;
