import { call, takeLatest } from "redux-saga/effects";
import {
  CREATE_SUPER_ADMIN,
  DELETE_SUPER_ADMIN,
  PERMISSION_LIST,
  SUPER_ADMIN_DETAIL,
  SUPER_ADMIN_EDIT,
  SUPER_ADMIN_LIST,
  SUPER_ADMIN_VERSION_DETAIL,
  SUPER_ADMIN_VERSION_LIST,
  SUPER_ADMIN_ROLE_LIST,
  DELETE_SUPER_ADMIN_ROLE,
  CREATE_SUPER_ADMIN_ROLE,
  ROLE_PERMISSION_LIST,
  SUPER_ADMIN_ROLE_DETAIL,
  SUPER_ADMIN_ROLE_EDIT,
  SUPER_ADMIN_ROLE_VERSION_LIST,
  SUPER_ADMIN_ROLE_VERSION_DETAIL,
  ROLE_LIST,
} from "../actions/types";
import {
  permissionListApi,
  superAdminCreateApi,
  superAdminDeleteApi,
  superAdminEditApi,
  SuperAdminListApi,
  superAdminVersionDetailApi,
  superAdminVersionListApi,
  superAdminViewApi,
  superAdminRoleListApi,
  superAdminRoleCreateApi,
  superAdminRoleDeleteApi,
  permissionRoleListApi,
  superAdminRoleViewApi,
  superAdminRoleEditApi,
  superAdminRoleVersionListApi,
  superAdminRoleVersionDetailApi,
  roleListApi,
} from "../apis/superAdminApi";

export function* superAdminListWorker(action) {
  try {
    const res = yield call(SuperAdminListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminCreateWorker(action) {
  try {
    const res = yield call(superAdminCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminEditWorker(action) {
  try {
    const res = yield call(superAdminEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminDetailWorker(action) {
  try {
    const res = yield call(superAdminViewApi, {
      admin_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminVersionWorker(action) {
  try {
    const res = yield call(superAdminVersionListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminVersionDetailWorker(action) {
  try {
    const res = yield call(superAdminVersionDetailApi, {
      admin_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fetchPermissionListWorker(action) {
  try {
    const res = yield call(permissionListApi);
    if (res.status == "200") {
      console.log(res);
      let newArr = res.data.data
        .map((ele) => ({
          value: ele.id,
          label: ele.module_name,
        }))
        // .filter((ex) => ex.label != "Setting");
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminDeleteWorker(action) {
  try {
    const res = yield call(superAdminDeleteApi, { admin_id: action.id });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleListWorker(action) {
  try {
    const res = yield call(superAdminRoleListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleCreateWorker(action) {
  try {
    const res = yield call(superAdminRoleCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleEditWorker(action) {
  try {
    const res = yield call(superAdminRoleEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleDetailWorker(action) {
  try {
    const res = yield call(superAdminRoleViewApi, { role_id: action.id });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fetchRolePermissionListWorker(action) {
  try {
    const res = yield call(permissionRoleListApi);
    if (res.status == "200") {
      console.log(res);
      let newArr = res.data.data
        .map((ele) => ({
          id: ele.id,
          label: ele.module_name,
        }))
        // .filter((ex) => ex.label != "Setting");
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleVersionWorker(action) {
  try {
    const res = yield call(superAdminRoleVersionListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleVersionDetailWorker(action) {
  try {
    const res = yield call(superAdminRoleVersionDetailApi, {
      role_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminRoleDeleteWorker(action) {
  try {
    const res = yield call(superAdminRoleDeleteApi, { role_id: action.id });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fetchRoleListWorker(action) {
  try {
    const res = yield call(roleListApi);
    if (res.status == "200") {
      console.log(res);
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.role_name,
        level: ele.role_level,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* superAdminListWatcher() {
  yield takeLatest(SUPER_ADMIN_LIST, superAdminListWorker);
}

export function* superAdminCreateWatcher() {
  yield takeLatest(CREATE_SUPER_ADMIN, superAdminCreateWorker);
}

export function* superAdminEditWatcher() {
  yield takeLatest(SUPER_ADMIN_EDIT, superAdminEditWorker);
}

export function* superAdminDetailWatcher() {
  yield takeLatest(SUPER_ADMIN_DETAIL, superAdminDetailWorker);
}

export function* superAdminVersionWatcher() {
  yield takeLatest(SUPER_ADMIN_VERSION_LIST, superAdminVersionWorker);
}

export function* superAdminVersionDetailWatcher() {
  yield takeLatest(SUPER_ADMIN_VERSION_DETAIL, superAdminVersionDetailWorker);
}

export function* fetchPermissionListWatcher() {
  yield takeLatest(PERMISSION_LIST, fetchPermissionListWorker);
}

export function* superAdminDeleteWatcher() {
  yield takeLatest(DELETE_SUPER_ADMIN, superAdminDeleteWorker);
}

export function* superAdminRoleListWatcher() {
  yield takeLatest(SUPER_ADMIN_ROLE_LIST, superAdminRoleListWorker);
}

export function* superAdminRoleCreateWatcher() {
  yield takeLatest(CREATE_SUPER_ADMIN_ROLE, superAdminRoleCreateWorker);
}

export function* superAdminRoleEditWatcher() {
  yield takeLatest(SUPER_ADMIN_ROLE_EDIT, superAdminRoleEditWorker);
}

export function* superAdminRoleDetailWatcher() {
  yield takeLatest(SUPER_ADMIN_ROLE_DETAIL, superAdminRoleDetailWorker);
}

export function* fetchRolePermissionListWatcher() {
  yield takeLatest(ROLE_PERMISSION_LIST, fetchRolePermissionListWorker);
}

export function* superAdminRoleVersionWatcher() {
  yield takeLatest(SUPER_ADMIN_ROLE_VERSION_LIST, superAdminRoleVersionWorker);
}

export function* superAdminRoleVersionDetailWatcher() {
  yield takeLatest(
    SUPER_ADMIN_ROLE_VERSION_DETAIL,
    superAdminRoleVersionDetailWorker
  );
}

export function* superAdminRoleDeleteWatcher() {
  yield takeLatest(DELETE_SUPER_ADMIN_ROLE, superAdminRoleDeleteWorker);
}
export function* fetchRoleListWatcher() {
  yield takeLatest(ROLE_LIST, fetchRoleListWorker);
}
