import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import "./modal.css";

const LogoutModal = ({ logoutShow, handleLogoutClose, logoutFunc }) => {
  return (
    <Modal
      centered
      show={logoutShow}
      onHide={handleLogoutClose}
      dialogClassName="col-4 logout-modal mx-auto "
      size="sm"
    >
      <ModalBody>
        <div className=" fn_Nunito  p-3">
          <div>
            <label className="fw_700 fs_16 text-center">Are You Sure?</label>
            <label className="fs_12 color_747f9e">
              Are you sure you want to logout ?
            </label>
          </div>
          <div className="mt-3 d-flex justify-content-center gap-4">
            <button
              className="modal-logout-btn fs_12 fw_700 px-4"
              onClick={() => logoutFunc()}
            >
              Logout
            </button>
            <button
              className="modal-cancel-btn fs_12 text-white py-2 px-4 "
              onClick={handleLogoutClose}
            >
              <span className="mx-2">Cancel</span>
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LogoutModal;
