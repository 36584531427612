import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SelectField from "../../../form/selectField/selectField";
import GenerateInvoiceTableModal from "./generateInvoiceTableModal";
import {
  PoOfferType,
  generatePoInvoice,
} from "../../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SuccessPopup from "../../../popups/SuccessPopup";
import ErrorPopup from "../../../popups/ErrorPopup";

const GenerateInvoiceModal = ({ show, handleClose, manufacturerViewData }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [poOfferTypeList, setPoOfferTypeList] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceTableData, setinvoiceTableData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const vendorId = params?.id.split("&")?.[0];
  const currentYear = new Date().getFullYear();
  const numYearsInPast = 10;
  const yearOptions = [];
  for (let i = currentYear; i >= currentYear - numYearsInPast; i--) {
    yearOptions.push({ value: i, label: i });
  }

  useEffect(() => {
    if (show == true) {
      dispatch(
        PoOfferType({ vendor_id: vendorId }, onOfferSuccess, onOfferError)
      );
    }
  }, [show]);

  const onOfferSuccess = (data) => {
    console.log(data, "offer list");
    setPoOfferTypeList(data);
  };
  const onOfferError = (err) => {
    console.log(err);
  };

  const initialValues = {
    vendor_id: manufacturerViewData?.vendor?.id,
    month: "",
    year: "",
    reimbursement_method: "",
  };

  const FormEnableCheck = () => {
    const {
      values: { month, year, reimbursement_method },
    } = useFormikContext();

    useEffect(() => {
      if (month !== "" && year !== "" && reimbursement_method !== "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [month, year, reimbursement_method]);
    return null;
  };

  const validationSchema = Yup.object().shape({
    month: Yup.string().required("Required"),
    year: Yup.string().required("Required"),
    reimbursement_method: Yup.string().required("Required"),
  });

  const reimbursementOptions = [
    { value: "order_off_qty", label: "Order Offer Quantity" },
    { value: "full_order_qty", label: "Full Order Quantity" },
    { value: "credit_note", label: "Credit Note for Offer" }
  ];
  const onSubmit = (values) => {
    dispatch(generatePoInvoice(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    handleClose();
  };

  const onSuccess = (data) => {
    console.log(data);
    setShowInvoice(true);
    setinvoiceTableData(data?.data?.data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName=""
        contentClassName="po-modal col-lg-5 col- mx-auto p-3  fn_Nunito"
      >
        <ModalBody>
          <label className="fn_Nunito fw_700 fs_24 color_262c3d">
            Generate Invoice
          </label>
          <br />
          <span className="fs_14 color_747f9e fn_Nunito">
            Lorem ipsum dolor sit amet consectetur. Nisi lectus mattis elit
            quam.
          </span>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form className="p-1">
                  <FormEnableCheck />
                  <div className="d-flex mt-3">
                    <div className=" col-6 pe-2">
                      <SelectField
                        label={"Month"}
                        labelColor={"color_262c3d"}
                        placeholder={"Select month"}
                        optionList={monthOptions}
                        name={"month"}
                        id={"month"}
                        menuHeight="180px"
                        value={""}
                        style={false}
                      />
                    </div>
                    <div className=" col-6 ps-2">
                      <SelectField
                        label={"Year"}
                        labelColor={"color_262c3d"}
                        placeholder={"Select year"}
                        optionList={yearOptions}
                        name={"year"}
                        id={"year"}
                        menuHeight="180px"
                        style={false}
                      />
                    </div>
                  </div>
                  <SelectField
                    className="w-100"
                    label={"Reimbursement Method"}
                    labelColor={"color_262c3d"}
                    placeholder={"Select Reimbursement Method"}
                    optionList={reimbursementOptions}
                    name={"reimbursement_method"}
                    id={"reimbursement_method"}
                    menuHeight="128px"
                    style={false}
                  />

                  <div className="d-flex">
                    <button
                      type="button"
                      className="close-btn  px-2 py-2 my-2 fs_14 w-50 me-2"
                      onClick={handleClose}
                    >
                      <span>CANCEL</span>
                    </button>
                    <button
                      type="submit"
                      className="filter-btn px-2 py-3 my-2 fs_14 w-50 ms-2 fw_600"
                      disabled={disable}
                    >
                      SUBMIT
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <GenerateInvoiceTableModal
        manufacturerViewData={manufacturerViewData}
        invoiceTableData={invoiceTableData}
        show={showInvoice}
        handleClose={() => setShowInvoice(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default GenerateInvoiceModal;

const monthOptions = [
  {
    label: "January",
    value: "1",
  },
  {
    label: "February",
    value: "2",
  },
  {
    label: "March",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "August",
    value: "8",
  },
  {
    label: "September",
    value: "9",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];
