import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import verifyIcon from "../../../assets/icons/verified.svg";
import "../modal.css";
import { useDispatch } from "react-redux";
import { sendMail } from "../../../redux/actions/manufacturerActions";
import MailSent from "../../popups/MailSent";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import { GEVSendCfaMail } from "../../../redux/actions/godsEyeViewActions";

const TableCfaSendMailModal = ({ show, handleClose, data, gevCfaListFnc }) => {
  const dispatch = useDispatch();
  const [mailSent, setMailSent] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  console.log(data, "dtdtdtdt");

  const sendMailFnc = () => {
    console.log("clicked");
    dispatch(
      GEVSendCfaMail(
        {
          cfa_id: data?.id,
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setMailSent(false);
    gevCfaListFnc();
  };

  const onSuccess = () => {
    console.log(data);
    setMailSent(true);
    handleClose();
    setTimeout(timeOutFnc, 2000);
  };

  const timeOutFnc1 = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc1, 2000);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName=""
        contentClassName="send-mail-small-container mx-auto p-2 fn_Nunito"
        size="md"
      >
        <ModalBody>
          <div className="my-3 mt-4 text-center ">
            <p className="color_747F9E text-nowrap fs_16 fw_500 m-0">
              Mail will be sent to :
            </p>
            <p className="color_262c3d text-nowrap fs_14 fw_500 m-0">
              {data?.email}
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 gap-2">
            <button
              type="button"
              className="modal-cancel-btn fs_14 text-white py-2 px-4 mx-3 fw_700"
              style={{ width: "150px" }}
              onClick={() => sendMailFnc()}
            >
              Send Mail
            </button>
          </div>
        </ModalBody>
      </Modal>
      <MailSent show={mailSent} />
      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
    </>
  );
};

export default TableCfaSendMailModal;
