import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainRoute from "./routes/mainRoute";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { socket } from "./redux/config";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const adminId = localStorage.getItem("adminId");
    console.log(adminId);
    if (adminId) {
      socket.emit("connected", adminId);
    }
  }, []);
  
  return (
    <Provider store={store}>
      <MainRoute />
    </Provider>
  );
}

export default App;
