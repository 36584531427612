import {
  CREATE_DISTRIBUTOR,
  DELETE_DISTROBUTOR,
  DISTRIBUTOR_DETAIL,
  DISTRIBUTOR_ENABLE_DISABLE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_VERSION_LIST,
  DISTRIBUTOR_VESRION_DETAIL,
  MANUFACTURER_DROPDOWN,
  EDIT_DISTRIBUTOR,
  LOGO_UPLOAD,
  GST_CERTIFICATE_UPLOAD,
  DRUG_LICENCE_UPLOAD,
  LOGIN_TO_PORTAL_DISTRIBUTOR,
  ONBOARDING_DISTRIBUTOR_LIST,
  DISTRIBUTOR_SEND_MAIL,
  DISTRIBUTOR_MANUFACTURER_LIST,
  DISTRIBUTOR_DIVISION_LIST,
} from "./types";

export const distributorList = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const onboardingDistributorList = (data, onSuccess, onError) => {
  return {
    type: ONBOARDING_DISTRIBUTOR_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const distributorDetail = (id, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_DETAIL,
    id,
    onSuccess,
    onError,
  };
};

export const createDistributor = (data, onSuccess, onError) => {
  return {
    type: CREATE_DISTRIBUTOR,
    data,
    onSuccess,
    onError,
  };
};

export const distributorVersionList = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const distributorVersionDetail = (id, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_VESRION_DETAIL,
    id,
    onSuccess,
    onError,
  };
};

export const enableDisableDistributor = (id, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_ENABLE_DISABLE,
    id,
    onSuccess,
    onError,
  };
};

export const deleteDistributor = (id, onSuccess, onError) => {
  return {
    type: DELETE_DISTROBUTOR,
    id,
    onSuccess,
    onError,
  };
};

export const manufacturerList = (onSuccess, onError) => {
  return {
    type: MANUFACTURER_DROPDOWN,
    onSuccess,
    onError,
  };
};

export const distributorEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_DISTRIBUTOR,
    data,
    onSuccess,
    onError,
  };
};

export const logoUpload = (image, onSuccess, onError) => {
  return {
    type: LOGO_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const uploadDrugLicence = (image, onSuccess, onError) => {
  return {
    type: DRUG_LICENCE_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const uploadGSTCertificate = (image, onSuccess, onError) => {
  return {
    type: GST_CERTIFICATE_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const loginToPortalDistributor = (
  distributor_id,
  onSuccess,
  onError
) => {
  return {
    type: LOGIN_TO_PORTAL_DISTRIBUTOR,
    distributor_id,
    onSuccess,
    onError,
  };
};
export const DistributorsendMail = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_SEND_MAIL,
    data,
    onSuccess,
    onError,
  };
};

export const fetchDivisionList = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_DIVISION_LIST,
    data,
    onSuccess,
    onError,
  };
};
