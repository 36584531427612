import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import ReactQuill from "react-quill";
import ErrorMsg from "../../../components/form/errorMsg";
import { useNavigate } from "react-router-dom";
import { settingsTermsConditionEdit, settingsTermsConditionView } from "../../../redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";

const TermsConditionEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [termsData, setTermsData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    setLoading(true)
    dispatch(settingsTermsConditionView(onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false)
    setTermsData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false)
  }

  const onError = (err) => {
    setLoading(false)
    console.log(err)
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };


  const initialValues = {
    text: termsData?.terms,
  };
  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Required"),
  });

  const resetFunc = (setFieldValue) => {
    setFieldValue("text", "");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ align: "" }],
    ],
  };

  const onSubmit = (values) => {
    console.log(values);
    dispatch(settingsTermsConditionEdit(values, onEditSuccess, onEditError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/settings/terms-condition/view");
  }

  const onEditSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    setTimeout(timeOutFnc, 2000)
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onEditError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <MainLayout breadCrumb="ADMIN  /  settings / Privacy Policy  / edit">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form className="">
                <div className="search-container d-flex justify-content-between ">
                  <div
                    className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                    onClick={() => navigate(-1)}
                  >
                    <img src={back} alt="back_btn" />
                    <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                      BACK
                    </span>
                  </div>
                  <div className=" d-flex justify-content-between m-1 mb-2">
                    <AdminButton
                      background="#E7E5FF"
                      width="100px"
                      onClick={() => resetFunc(setFieldValue)}
                      type="button"
                    >
                      RESET
                    </AdminButton>
                    <AdminButton
                      background="#2AD3E7"
                      color="white"
                      width="100px"
                      type="submit"
                    >
                      <span className="m-2">SAVE</span>
                    </AdminButton>
                  </div>
                </div>
                <div className="faq-form-width ms-4 my-4 ps-3 py-1">
                  {
                    loading == true ?
                      <div className="spinner-container">
                        <Spinner
                          show={loading}
                        />
                      </div> :
                      <>
                        <div className="my-2 mb-4">
                          <label className="fs_14 color_747f9e fw_400 mb-2">
                            Terms & Conditions Text
                          </label>
                          <div
                            className="faq-text-container"
                            style={{ borderRadius: "15px" }}
                          >
                            <Field name="text">
                              {({ field }) => (
                                <ReactQuill
                                  value={field.value}
                                  onChange={field.onChange(field.name)}
                                  modules={modules}
                                  style={{
                                    maxHeight: "400px",
                                    height: "60vh",
                                    borderRadius: "15px",
                                  }}
                                  className={"quill"}
                                  placeholder={"Enter Terms & Conditions here"}
                                />
                              )}
                            </Field>
                          </div>
                          <ErrorMsg name={"text"} />
                        </div>
                      </>
                  }

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </MainLayout>

      <SuccessPopup
        show={showSuccessPopup}
        title={'Terms and Conditions Updated Successfully'}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>

  );
};

export default TermsConditionEdit;
const createdDummy = [
  { label: "customer" },
  { label: "vendor" },
  { label: "distributor" },
  { label: "field_force_user" },
];
