import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/deleteModal";
import EnableDisableModal from "../../../components/modals/enableDisableModal";

const ReasonRejectionListTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  return (
    <tr className="table-rows1 " key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">
        {data?.title ? data?.title.charAt(0).toUpperCase() + data?.title.slice(1) : '--'}
      </td>

      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/settings/reason-for-rejection/view/${data?.id}`)}
          >
            <span className=""> View</span>
          </button>
          {/* <span className="vr  vr_line mt-1 "></span>
          <button
            className="button-edit1"
            type="button"
            onClick={() => navigate("/reason-for-rejection-edit")}
          >
            Edit
          </button> */}
        </div>
      </td>
    </tr>
  );
};

export default ReasonRejectionListTableData;
