import "./modal.css";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import UploadInvoiceTable from "./updateTable";
import { useDispatch } from "react-redux";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import { invoiceUpdatePtr } from "../../../redux/actions/invoiceVerificationAction";


const UpdatePtrModal = ({ show, handleClose, invoicedProducts, order_id }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState([]);



    useEffect(() => {
        setFormData(invoicedProducts);
    }, []);

    const onSuccess = (data) => {
        console.log(data.data.data);
        handleClose()
    };

    const onError = (err) => {
        console.log(err);
    };

    const updateInvoice = (e, ele) => {
        ele[`${e.target.name}`] = e.target.value;
        let orderProducts = invoicedProducts;
        orderProducts.map((idx) => {
            idx.id == ele.id && (idx[`${e.target.name}`] = e.target.value);
        });
        setFormData(orderProducts);
    };

    const onUpdate = () => {
        console.log(formData, "form data ");
        dispatch(invoiceUpdatePtr({ invoiced_products: formData, order_id }, onSuccess, onError))

    }


    return (
        <>
            <Modal
                centered
                show={show}
                onHide={handleClose}
                dialogClassName="upload-invoice-table-container col-10 px-5 mx-auto"
                size="xl"
            >
                <ModalHeader>
                    <span className="fn_Nunito fw_700 fs_16 Medium_Purple my- px-0">
                        Update Ptr
                    </span>
                </ModalHeader>
                <ModalBody className="text-nowrap">
                    <div>
                        <UploadInvoiceTable
                            invoices={invoicedProducts}
                            updateInvoice={updateInvoice}
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <AdminButton
                            width="120px"
                            background="#7065e4"
                            color="white"
                            type="button"
                            onClick={() => onUpdate()}
                        >
                            <span className="m-2">Update</span>
                        </AdminButton>

                        <AdminButton
                            width="100px"
                            color="#FF6F5B"
                            border="1px solid #FF6F5B"
                            type="button"
                            onClick={() => handleClose()}
                        >
                            <span className="m-1">CLOSE</span>
                        </AdminButton>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UpdatePtrModal;