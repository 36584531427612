import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import InputField from "../../../components/form/inputField/inputField";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import { useDispatch } from "react-redux";
import {
  createDistributor,
  fetchDivisionList,
  manufacturerList,
} from "../../../redux/actions/distributorActions";
import Select from "react-select";
import ErrorMsg from "../../../components/form/errorMsg";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const DistributorCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const phoneRegex = /^([+]\d{2})?\d{10}$/;

  useEffect(() => {
    dispatch(manufacturerList(onManuSuccess, onManuError));
  }, []);
  useEffect(() => {
    if (selectedVendorId !== null) {
      dispatch(
        fetchDivisionList(
          { vendor_id: selectedVendorId },
          onDivisionSuccess,
          onDivisionError
        )
      );
    }
  }, [selectedVendorId]);

  const onManuSuccess = (data) => {
    console.log(data);
    setManufacturerOptions(data);
  };

  const onManuError = (err) => {
    console.log(err.data);
  };

  const onDivisionSuccess = (data) => {
    console.log(data);
    setDivisionOptions(data);
  };

  const onDivisionError = (err) => {
    console.log(err.data);
  };

  const initialValues = {
    firm_name: "",
    vendor_id: "",
    email: "",
    mobile: "",
    division_id: "",
  };
  const validationSchema = Yup.object().shape({
    firm_name: Yup.string().required("Required"),
    vendor_id: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email").required("Required"),
    mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    division_id: Yup.string().required("Required"),
  });

  const clearFunc = (setFieldValue) => {
    setFieldValue("firm_name", "");
    setFieldValue("vendor_id", "");
    setFieldValue("email", "");
    setFieldValue("mobile", "");
    setFieldValue("division_id", "");
  };

  const onSubmit = (values) => {
    dispatch(createDistributor(values, onSuccess, onError));
    console.log(values);
  };
  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate("/distributors/onboard-distributor/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };

  const timeOutFnc1 = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err.data);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc1, 2000);
  };

  return (
    <MainLayout breadCrumb="ADMIN /  FAQ’s - Create">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    type="button"
                    onClick={() => clearFunc(setFieldValue)}
                  >
                    CLEAR
                  </AdminButton>
                  <AdminButton
                    background="#7065e4"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">CREATE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="faq-form-width ms-4 ps-3 py-1">
                <div className="select-container  mt-4">
                  <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                    Manufacturer
                  </label>
                  <Field name={"vendor_id"}>
                    {({ field, meta }) => (
                      <Select
                        {...field}
                        options={manufacturerOptions}
                        styles={customStyles}
                        placeholder={"Select manufacturer"}
                        value={manufacturerOptions.filter(
                          (s) => s.value == values.vendor_id
                        )}
                        onChange={(selected) => {
                          setSelectedVendorId(selected?.value);
                          setFieldValue("vendor_id", selected?.value);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMsg name="vendor_id" />
                </div>
                <div className="select-container">
                  <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                    Division
                  </label>
                  <Field name={"division_id"}>
                    {({ field, meta }) => (
                      <Select
                        {...field}
                        options={divisionOptions}
                        styles={customStyles}
                        placeholder={"Select division"}
                        value={divisionOptions.filter(
                          (s) => s.value == values.division_id
                        )}
                        onChange={(selected) => {
                          setFieldValue("division_id", selected?.value);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMsg name="division_id" />
                </div>
                <div className="my-2">
                  <InputCapitalField
                    setFieldValue={setFieldValue}
                    id={"firm_name"}
                    label={"Name"}
                    labelColor={"color_262c3d"}
                    type={"text"}
                    placeholder={"Enter name"}
                    name={"firm_name"}
                    value={values.firm_name}
                  />
                </div>
                <div className="my-2">
                  <InputField
                    id={"email"}
                    label={"Email Address"}
                    labelColor={"color_262c3d"}
                    type={"text"}
                    placeholder={"Enter email address"}
                    name={"email"}
                    value={values.email}
                  />
                </div>
                <div className="my-2">
                  <InputField        
                    id={"mobile"}
                    label={"Phone Number"}
                    labelColor={"color_262c3d"}
                    type={"number"}
                    placeholder={"Enter phone Number"}
                    name={"mobile"}
                    value={values.mobile}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SuccessPopup
        show={onShowSuccess}
        title={"Distributor Created Successfully"}
      />
      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
    </MainLayout>
  );
};

export default DistributorCreate;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
