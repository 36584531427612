import React, { useEffect, useState } from "react";
import CreateBtn from "../../components/buttons/createBtn";
import VersionHistoryBtn from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import searcher from "../../assets/icons/searchInput.svg";
import DistributorListTable from "./distributor-list-table";

const DistributorList = () => {
  const [search, setSearch] = useState(null);

  return (
    <MainLayout>
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search distributors here by distributor ID, distributor name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className=" d-flex justify-content-between m-1 mb-2">
            <VersionHistoryBtn link="/distributors/distributor/version-list/1" />      
          </div>
        </div>
        <DistributorListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default DistributorList;
