import { call, takeLatest } from "redux-saga/effects";
import {
  customerVerifyListApi,
  customerVerifyViewApi,
  verifyReportApi,
} from "../apis/customerVerifyApi";
import {
  CUSTOMER_VERIFY_DETAIL,
  CUSTOMER_VERIFY_LIST,
  VERIFY_REPORT_MODAL,
} from "../actions/types";

export function* customerVerifyListWorker(action) {
  try {
    const res = yield call(customerVerifyListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* customerVerifyDetailWorker(action) {
  try {
    const res = yield call(customerVerifyViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* verifyReportWorker(action) {
  try {
    const res = yield call(verifyReportApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* customerVerifyListWatcher() {
  yield takeLatest(CUSTOMER_VERIFY_LIST, customerVerifyListWorker);
}
export function* customerVerifyDetailWatcher() {
  yield takeLatest(CUSTOMER_VERIFY_DETAIL, customerVerifyDetailWorker);
}
export function* verifyReportWatcher() {
  yield takeLatest(VERIFY_REPORT_MODAL, verifyReportWorker);
}
