import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import {
    AdminButton,
} from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import DeleteModal from "../../../components/modals/deleteModal";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { chapterDelete, chapterView } from "../../../redux/actions/ffTrainingAction";

const ChaptersView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [deleteShow, setDeleteShow] = useState(false);
    const [chapterViewData, setchapterViewData] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState();
    const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
    const [catchedErrorMsg, setCatchedErrorMsg] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchChapter();
    }, []);

    const fetchChapter = () => {
        setLoading(true);
        dispatch(chapterView({ id: params.id }, onSuccess, onError));
    };

    const onSuccess = (data) => {
        setLoading(false);
        console.log(data.data.data);
        setchapterViewData(data.data.data);
    };

    const closeCatchedErrPopup = () => {
        setCatchedErrorPopup(false);
    };

    const onError = (err) => {
        setLoading(false);
        console.log(err);
        setCatchedErrorPopup(true);
        setCatchedErrorMsg(err.data.message);
        console.log(err.data.message);
    };

    const delFunc = () => {
        dispatch(chapterDelete({ id: params.id }, onDelSuccess, onDeleteError));
    };

    const timeOutFnc = () => {
        setShowSuccessPopup(false);
        navigate("/ff-training/chapters/1");
    };

    const onDelSuccess = (data) => {
        setShowSuccessPopup(true);
        const str = data.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowSuccessMsg(str_modify);
        setTimeout(timeOutFnc, 2000);
    };

    const closeErrorPopup = () => {
        setShowErrorPopup(false);
    };

    const onDeleteError = (err) => {
        console.log(err);
        const str = err.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowErrorMsg(str_modify);
        setShowErrorPopup(true);
    };
    const Breadcrumb = ({ match }) => (
        <span>
            {match?.route?.path?.endsWith("view/:id") &&
                (chapterViewData?.chapter_id
                    ? ` / ${chapterViewData?.chapter_id} - view`
                    : " / view")}
        </span>
    );
    return (
        <MainLayout breadcrumbData={Breadcrumb}>
            <div className="search-container d-flex justify-content-between ">
                <div
                    className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                    onClick={() => navigate(-1)}
                >
                    <img src={back} alt="back_btn" />
                    <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                        BACK
                    </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">


                    <AdminButton
                        background="#7065E4"
                        color="white"
                        width="90px"
                        onClick={() => navigate(`/ff-training/chapters/edit/${params.id}`)}
                    >
                        <span className="m-2">EDIT</span>
                    </AdminButton>

                    <AdminButton
                        background="#FF6F5B"
                        color="white"
                        width="100px"
                        type="button"
                        onClick={() => setDeleteShow(true)}
                    >
                        <span className="mx-2">DELETE</span>
                    </AdminButton>
                </div>
            </div>
            {loading == true ? (
                <div className="view-spinner-container">
                    <LoadingSpinnerTable show={loading} />
                </div>
            ) : (
                <div className="mx-4 px-3 mt-3">
                    <div className="my-2">
                        <label className=" Neutral_Color fs_14">Chapter ID</label>
                        <p className="fs_14">{chapterViewData?.chapter_id}</p>
                    </div>
                    <div className="my-2">
                        <label className=" Neutral_Color fs_14">Chapter Name</label>
                        <p className="fs_14">{chapterViewData?.name}</p>
                    </div>
                    <div className="my-2">
                        <label className=" Neutral_Color fs_14">Priority</label>
                        <p className="fs_14">{chapterViewData?.priority}</p>
                    </div>
                </div>
            )}

            <DeleteModal
                delFunc={delFunc}
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                title={`chapter - ${chapterViewData?.chapter_id} ?`}
            />
            <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
            <ErrorPopup
                show={showErrorPopup}
                title={showErrorMsg}
                handleClose={closeErrorPopup}
            />

            <ErrorPopup
                show={catchedErrorPopup}
                title={catchedErrorMsg}
                handleClose={closeCatchedErrPopup}
            />
        </MainLayout>
    );
};

export default ChaptersView;