import { Field } from "formik";
import React from "react";
import ErrorMsg from "../errorMsg";
import "./inputField.css";

const ValueInputField = ({
  label,
  name,
  type,
  value,
  placeholder,
  color = "Neutral_Color",
  labelColor = "Neutral_Color",
  fontWeigth = "fw_400",
  valueText = "",
  boldValueText = "",
  boldValue = false,
  InputMaxWidth = "450px",
  disabled=false
}) => {
  return (
    <div className={`${color} d-flex  flex-column mb-2`}>
      <label className={`${fontWeigth} ${labelColor} fs_14 my-1`}>
        {label}
      </label>
      <Field name={name}>
        {({ field, meta }) => (
          <div
            className="in-field d-flex align-items-center input-field-container justify-content-between"
            style={{ maxWidth: InputMaxWidth }}
          >
            <input
              type={type}
              {...field}
              placeholder={placeholder}
              className={`input input-field-border`}
              value={value}
              min={0}
              onKeyDown={(e) => {
                if ((e.key == "-" || e.key == "+")) {
                  e.preventDefault();
                }
              }}
              disabled={disabled}
            />

            {boldValue == true ? (
              <span className="fn_Nunito fs_16 fw_600 Medium_Purple px-3">
                {boldValueText}
              </span>
            ) : (
              <span className="fn_Nunito fs_16 fw_500 Medium_Purple px-3 input-value-container">
                {valueText}
              </span>
            )}
          </div>
        )}
      </Field>
      <ErrorMsg name={name} />
    </div>
  );
};

export default ValueInputField;
