import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../manufacturer/manufacturer.css";
import TableContainer from "../../../components/layout/table-container/table-container";
import TablePagination from "../../../components/Pagination/tablePagination";
import { superAdminroleList } from "../../../redux/actions/superAdminActions";
import SuperUserRoleListTableData from "./super-user-role-list-table-data";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const SuperUserRoleListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [superRoleListData, setSuperRoleListData] = useState([]);
  const [page, setPage] = useState(page_no);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(superAdminroleList({ search, page }, onSuccess, onError));
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    dispatch(superAdminroleList({ search, page }, onSuccess, onError));
  }, [page, search]);

  const fetchSuperAdminList = () => {
    dispatch(superAdminroleList({ search, page }, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    setSuperRoleListData(data.data.data);
    console.log(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Role ID
            </th>
            <th className="table-heads " width={"10%"}>
              Role Name
            </th>
            <th className="table-heads " width={"10%"}>
              Role Level
            </th>
            <th className="table-heads " width={"22%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : superRoleListData?.roles?.length > 0 ? (
            <>
              {superRoleListData?.roles?.map((eachRow, index) => (
                <SuperUserRoleListTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  fetchSuperAdminList={fetchSuperAdminList}
                  currentPage={superRoleListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      {superRoleListData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={superRoleListData?.pagination}
          nextFunc={() => {
            navigate(
              `/super-user/role/${
                superRoleListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/super-user/role/${
                superRoleListData?.pagination?.currentPage - 1
              }`
            );
            setPage(page - 1);
          }}
        />
      )}
      {/* <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      /> */}
    </>
  );
};

export default SuperUserRoleListTable;
