import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../../components/layout/table-container/table-container";
import TablePagination from "../../../components/Pagination/tablePagination";
import { onboardRequestList } from "../../../redux/actions/customerActions";
import RequestListTableData from "./request-list-table-data";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const RequestListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [requestListData, setRequestListData] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [approval, setapproval] = useState(null);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      setLoading(true);
      dispatch(
        onboardRequestList({ page, search, approval }, onSuccess, onError)
      );
    }
  }, [search]);

  useEffect(() => {
    if (approval !== null) {
      setPage(1);
      setLoading(true);
      customerList();
    }
  }, [approval]);

  const customerList = () => {
    dispatch(
      onboardRequestList({ page, search, approval }, onSuccess, onError)
    );
  };

  useEffect(() => {
    setLoading(true);
    customerList();
  }, [page, approval]);

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data?.data);
    setRequestListData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            {/* <th className="table-heads " width={"10%"}>
              Jivini’s Doctor Code
            </th>
            <th className="table-heads " width={"10%"}>
              Jivini’s Pharmacy Code
            </th> */}
            <th className="table-heads " width={"10%"}>
              Doctor Name
            </th>
            <th className="table-heads " width={"10%"}>
              Pharmacy Name
            </th>
            <th className="table-heads " width={"10%"}>
              Manufactuer name
            </th>
            <th className="table-heads " width={"10%"}>
              Division
            </th>
            <th className="table-heads " width={"10%"}>
              City
            </th>
            <th className="table-heads " width={"10%"}>
              Mobile Number
            </th>
            <th className="table-heads text-nowrap" width={"8%"}>
              <span onClick={() => setOpenStatus(!openStatus)}>
                Status
                {openStatus ? (
                  <img src={UpArrow} className="mb-1 mx-1" />
                ) : (
                  <img src={DownArrow} className=" mx-1" />
                )}
              </span>
              {openStatus ? (
                <div className="position-relative">
                  <div className="filter-position px-3 status-container d-flex gap-3">
                    <div className="d-flex align-items-center cursor_pointer">
                      <input
                        className="radio me-2"
                        type="radio"
                        id="pending"
                        name="pending"
                        value={"pending"}
                        checked={approval == "pending"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setapproval(e.target.value);
                        }}
                      />
                      <label
                        htmlFor="pending"
                        className={
                          approval == "pending"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Pending
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2"
                        type="radio"
                        id="rejected"
                        name="rejected"
                        value={"rejected"}
                        checked={approval == "rejected"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setapproval(e.target.value);
                        }}
                      />
                      <label
                        htmlFor="rejected"
                        className={
                          approval == "rejected"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Rejected
                      </label>
                    </div>
                    <button
                      className="filter-btn px-2 py-0 my-2 fs_12"
                      style={{ height: "28px" }}
                      onClick={() => {
                        setapproval(null);
                        setOpenStatus(!openStatus);
                      }}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              ) : null}
            </th>
            <th className="table-heads " width={"22%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : requestListData?.data?.length > 0 ? (
            <>
              {requestListData?.data?.map((eachRow, index) => (
                <RequestListTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  currentPage={requestListData?.pagination?.currentPage}
                  customerList={customerList}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {requestListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={requestListData?.pagination}
            nextFunc={() => {
              navigate(
                `/customers/request/${requestListData?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/customers/request/${requestListData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>

      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default RequestListTable;
