import React from "react";

const DoctorVerificationTableData = ({
  data,
  index,
  isChecked,
  checkedDoctor,
}) => {
  return (
    <tr className="table-rows1 "  key={index}>
      <td className="table-each-row-data" width={"6%"}>
        <input
          type={"checkbox"}
          className="CheckBox my-1"
          value="Full order forward from CFA"
          name="ReimbursementCheck"
          onChange={(e) => checkedDoctor(e, data)}
          checked={isChecked}
        />
      </td>
      <td className="table-each-row-data">{data?.sl_no}</td>
      <td className="table-each-row-data">{data?.name}</td>
      <td className="table-each-row-data">{data?.mobile}</td>
      <td className="table-each-row-data">{data?.email}</td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button className="button-disable" type="button" color={"green"}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default DoctorVerificationTableData;
