import React, { useEffect, useState } from "react";
import { gevFieldForceView } from "../../../../../redux/actions/godsEyeViewActions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import back from "../../../../../assets/icons/back.svg";
import { AdminButton } from "../../../../../components/buttons/versionHistoryBtn";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import DownArrow from "../../../../../assets/icons/filterArrowDown.svg";
import UpArrow from "../../../../../assets/icons/filterArrowUp.svg";
import styled from "@emotion/styled";
import "../../../manufacturer.css";
import LoadingSpinnerTable from "../../../../../components/utilites/loaderSpinningContainer";

const FFUserView = ({ vendorId, maxWidth = "85rem" }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [ffUserData, setFfUserData] = useState("");
  const [loading, setLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [active, setActive] = useState("");
  const [status, setStatus] = useState(null);
  const activePath = params?.id.split("&")?.[1];
  const subId = params?.id.split("&")?.[3];

  useEffect(() => {
    gevFFView();
  }, []);

  const gevFFView = () => {
    if (activePath == "fieldForce") {
      dispatch(gevFieldForceView(subId, onffSuccess, onffError));
      setLoading(true);
    }
  };

  const onffSuccess = (data) => {
    console.log(data?.data?.data);
    setFfUserData(data?.data?.data);
    setLoading(false);
  };

  const onffError = (err) => {
    console.log(err);
    setLoading(false);
  };
  const filteredData = ffUserData?.usersOfHeadQuarters?.filter((item) =>
    status == null ? item : item?.status === status && item
  );

  return (
    <>
      <div className="search-container d-flex justify-content-between ">
        <div></div>
        <div className="my-2">
          <AdminButton
            background="#7065E4"
            color="white"
            width="100px"
            onClick={() => {
              navigate(
                `/manufacturer/subscibe-list/view/${vendorId}&fieldForce&edit&${ffUserData?.id}`
              );
            }}
          >
            <span className="m-2">EDIT</span>
          </AdminButton>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {loading == true ? (
          <div className="list-spinner-container">
            <LoadingSpinnerTable show={loading} />
          </div>
        ) : (
          <div className="scroll-container">
            <div className="form-width px-4 mx-2">
              <div className="my-4">
                <label className=" color_747F9E fs_14">User ID</label>
                <p className="fs_14">{ffUserData?.user_id}</p>
              </div>
              <div className="my-4">
                <label className=" color_747F9E fs_14">Field Force ID</label>
                <p className="fs_14">{ffUserData?.code}</p>
              </div>
              <div className="my-4">
                <label className=" color_747F9E fs_14">
                  Company Employee ID
                </label>
                <p className="fs_14">{ffUserData?.employee_id}</p>
              </div>

              <div className="my-4">
                <label className=" color_747F9E fs_14">Employee Name</label>
                <p className="fs_14">{ffUserData?.name}</p>
              </div>
              <div className="my-4">
                <label className=" color_747F9E fs_14">Phone Number</label>
                <p className="fs_14">{ffUserData?.mobile}</p>
              </div>

              <div className="my-4">
                <label className=" color_747F9E fs_14">Email Address</label>
                <p className="fs_14">{ffUserData?.email}</p>
              </div>
              <div className="my-4">
                <label className=" color_747F9E fs_14">Designation</label>
                <p className="fs_14">
                  {ffUserData?.fieldForceDesignation?.name}
                </p>
              </div>
              <div className="my-4">
                <label className=" Neutral_Color fs_14">
                  {" "}
                  Designation Approval level
                </label>
                <p className="fs_14">
                  {ffUserData?.fieldForceDesignation?.approval_level == "L1" &&
                    "Level 1"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L2" &&
                    "Level 2"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L3" &&
                    "Level 3"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L4" &&
                    "Level 4"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L5" &&
                    "Level 5"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L6" &&
                    "Level 6"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L7" &&
                    "Level 7"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L8" &&
                    "Level 8"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L9" &&
                    "Level 9"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L10" &&
                    "Level 10"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L11" &&
                    "Level 11"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L12" &&
                    "Level 12"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L13" &&
                    "Level 13"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L14" &&
                    "Level 14"}
                  {ffUserData?.fieldForceDesignation?.approval_level == "L15" &&
                    "Level 15"}
                  {ffUserData?.fieldForceDesignation?.approval_level ==
                    "admin" && "Admin"}
                </p>
              </div>
              {ffUserData?.fieldForceDesignation?.approval_level != "admin" && (
                <>
                  <div className="my-4">
                    <label className=" color_747F9E fs_14">Division Name</label>
                    {/* <p className="fs_14">{ffUserData?.vendorDivision?.name}</p> */}
                    <p className="fs_14">
                      {ffUserData?.fieldForceDivisions?.length > 0 &&
                        ffUserData?.fieldForceDivisions?.map(
                          (ele, index) => {
                            return (
                              <span className="slice-word" key={index}>
                                <span style={{ color: "#B0B3C7" }}>
                                  {index ? " | " : ""}
                                </span>
                                {ele?.vendorDivision?.name}
                              </span>
                            );
                          }
                        )}
                    </p>
                  </div>
                  <div className="my-4">
                    <label className=" color_747F9E fs_14">Head Quaters</label>
                    <p className="fs_14">
                      {ffUserData?.fieldForceUserHeadQuaters?.length > 0 &&
                        ffUserData?.fieldForceUserHeadQuaters?.map(
                          (ele, index) => {
                            return (
                              <span className="slice-word" key={index}>
                                <span style={{ color: "#B0B3C7" }}>
                                  {index ? " | " : ""}
                                </span>
                                {ele?.headQuater?.name}
                              </span>
                            );
                          }
                        )}
                    </p>
                  </div>
                  <div className="my-4">
                    <label className=" color_747F9E fs_14">
                      Reporting Manager
                    </label>
                    <p className="fs_14">
                      {ffUserData?.reportingManager?.name
                        ? ffUserData?.reportingManager?.name
                        : "--"}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="d-flex text-left px-3 my-3 py-1 form-sub-details fw_600 mx-2">
                Field Force - Users
              </div>
              <div className="table-container mx-4">
                <TableContainer maxWidth={maxWidth}>
                  <table className="main-table">
                    <thead>
                      <tr className="table-rows ">
                        <th className="table-heads text-nowrap " width={"4%"}>
                          #
                        </th>
                        <th
                          className="table-heads text-nowrap color_262c3d"
                          width={"8%"}
                        >
                          User ID
                        </th>
                        <th className="table-heads text-nowrap" width={"8%"}>
                          Field Force Code
                        </th>
                        <th className="table-heads text-nowrap" width={"8%"}>
                          Company Employee ID
                        </th>
                        <th className="table-heads text-nowrap" width={"10%"}>
                          Employee Name
                        </th>
                        <th className="table-heads text-nowrap" width={"6%"}>
                          Designation
                        </th>
                        <th className="table-heads text-nowrap" width={"10%"}>
                          Designation Approval level
                        </th>
                        <th className="table-heads text-nowrap" width={"10%"}>
                          Head Quarters
                        </th>

                        <th className="table-heads text-nowrap" width={"8%"}>
                          Division
                        </th>
                        <th className="table-heads text-nowrap" width={"8%"}>
                          <span
                            onClick={() => {
                              setOpenStatus(!openStatus);
                            }}
                          >
                            Status
                            {openStatus ? (
                              <img src={UpArrow} className="mb-1 mx-1" />
                            ) : (
                              <img src={DownArrow} className=" mx-1" />
                            )}
                          </span>
                          {openStatus ? (
                            <div className="position-relative">
                              <div
                                className="position-absolute status-container d-flex gap-3 px-3"
                                style={{ right: "0" }}
                              >
                                <div className="d-flex align-items-center">
                                  <input
                                    className="radio me-2"
                                    type="radio"
                                    id="State"
                                    name="status"
                                    value={true}
                                    onChange={(e) => setStatus(true)}
                                    checked={status == true}
                                    onClick={(e) => {
                                      setOpenStatus(!openStatus);
                                      setStatus(e.target.value);
                                      setActive("active");
                                    }}
                                  />
                                  <span
                                    for="State"
                                    className={
                                      active === "active"
                                        ? "color_262c3d"
                                        : "color_747f9e"
                                    }
                                  >
                                    Active
                                  </span>
                                </div>
                                <div className="d-flex align-items-center">
                                  <input
                                    className="radio me-2"
                                    type="radio"
                                    id="State"
                                    name="status"
                                    value={false}
                                    onChange={(e) => setStatus(false)}
                                    checked={status == false}
                                    onClick={(e) => {
                                      setOpenStatus(!openStatus);
                                      setStatus(e.target.value);
                                      setActive("inactive");
                                    }}
                                  />
                                  <span
                                    for="State"
                                    className={
                                      active === "inactive"
                                        ? "color_262c3d"
                                        : "color_747f9e"
                                    }
                                  >
                                    Inactive
                                  </span>
                                </div>
                                <button
                                  className="filter-btn px-2 py-0 my-2 fs_12"
                                  style={{ height: "28px" }}
                                  onClick={() => {
                                    setStatus(null);
                                    setOpenStatus(!openStatus);
                                  }}
                                >
                                  CLEAR
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ position: "relative" }}>
                      {filteredData?.length > 0 ? (
                        filteredData?.map((item, index) => {

                          const renderHqTooltip = (props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {item?.fieldForceUserHeadQuaters?.map((ele, index) => (
                                <span key={index}>
                                  {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                                  {ele?.headQuater?.name ? ele?.headQuater?.name : "--"}
                                </span>
                              ))}
                            </Tooltip>
                          );

                          const renderDivTooltip = (props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {item?.fieldForceDivisions?.map((ele, index) => (
                                <span key={index}>
                                  {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                                  {ele?.vendorDivision?.name ? ele?.vendorDivision?.name : "--"}
                                </span>
                              ))}
                            </Tooltip>
                          );
                          return (
                            <tr key={index} className="text-nowrap">
                              <td className="table-each-row-data">{index + 1}</td>
                              <td className="table-each-row-data">
                                {item?.user_id ? item?.user_id : "--"}
                              </td>
                              <td className="table-each-row-data">
                                {item?.code ? item?.code : "--"}
                              </td>
                              <td className="table-each-row-data">
                                {item?.employee_id ? item?.employee_id : "--"}
                              </td>
                              <td className="table-each-row-data text-capitalize">
                                {item?.name ? item?.name : "--"}
                              </td>
                              <td className="table-each-row-data">
                                {item?.fieldForceDesignation?.name
                                  ? item?.fieldForceDesignation?.name
                                  : "--"}
                              </td>
                              <td className="table-each-row-data  text-center text-capitalize">
                                {item?.fieldForceDesignation?.approval_level
                                  ? item?.fieldForceDesignation?.approval_level
                                  : "--"}
                              </td>
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderHqTooltip}
                              >
                                <td className="table-each-row-data text-truncate" style={{ maxWidth: "200px" }}>
                                  {item.fieldForceUserHeadQuaters?.length > 0 &&
                                    item.fieldForceUserHeadQuaters?.map(
                                      (ele, index) => {
                                        return (
                                          <span className="slice-word" key={index}>
                                            <span style={{ color: "#B0B3C7" }}>
                                              {index ? " | " : ""}
                                            </span>
                                            {ele?.headQuater?.name}
                                          </span>
                                        );
                                      }
                                    )}
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderDivTooltip}
                              >
                                <td className="table-each-row-data text-truncate" style={{ maxWidth: "200px" }}>
                                  {item.fieldForceDivisions?.length > 0 &&
                                    item.fieldForceDivisions?.map(
                                      (ele, index) => {
                                        return (
                                          <span className="slice-word" key={index}>
                                            <span style={{ color: "#B0B3C7" }}>
                                              {index ? " | " : ""}
                                            </span>
                                            {ele?.vendorDivision?.name}
                                          </span>
                                        );
                                      }
                                    )}
                                </td>
                              </OverlayTrigger>
                              {item.status ? (
                                <td className="table-each-row-data text_green">
                                  Active
                                </td>
                              ) : (
                                <td className="table-each-row-data text_red">
                                  Inactive
                                </td>
                              )}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="1"
                            className="text-center list-data-container fs_16"
                          >
                            <span>~ No Data Found ~</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </TableContainer>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FFUserView;

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "15px",

    maxWidth: "50rem",
    height: "25rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);
