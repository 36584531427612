import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Topnav from "../components/layout/topnav/topnav";
import SideBar from "../components/layout/sidebar/sidebar";

const PrivateRoute = () => {
  const [open, setOpen] = useState(false);
  return localStorage.getItem("accessToken") ? (
    <>
      <div className="main-layout-main flex-wrap d-flex fn_Nunito">
        <Topnav open={open} setOpen={setOpen} />
        <div className="main-layout-container fn_Nunito d-flex flex-row w-100">
          <div className="col">
            <SideBar open={open} setOpen={setOpen} />
          </div>

          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
