import React, { useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField";
import {
  fetchDistrictList,
  fetchStateList,
} from "../../../../redux/actions/commonActions";
import { useDispatch } from "react-redux";
import ErrorMsg from "../../../../components/form/errorMsg";
import { headquarterAdd } from "../../../../redux/actions/manufacturerActions";
import { useNavigate, useParams } from "react-router-dom";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";

const HeadQuarterAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const params = useParams();
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const vendorId = params?.id.split("&")?.[0];

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log("District", data);
  };
  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const initialValues = {
    name: "",
    state_id: "",
    districts: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    state_id: Yup.string().required("Required"),
    districts: Yup.array()
      .min(1, "Select atleast one district")
      .required("required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    values["vendor_id"] = vendorId;
    dispatch(headquarterAdd(values, onAddSuccess, onAddError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate(
      `/manufacturer/subscibe-list/view/${vendorId}&headQuarters&list&1`
    );
  };

  const onAddSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    setShowSuccessMsg(data.data.message);
    setTimeout(timeOutFnc, 2000);
  };

  const timeOutFnc1 = () => {
    setShowErrorPopup(false);
  };

  const onAddError = (err) => {
    console.log(err.data);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc1, 2000);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, state_id, districts },
    } = useFormikContext();
    useEffect(() => {
      if (name.trim() != "" && state_id.trim() != "" && districts?.length > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [name, state_id, districts]);
    return null;
  };

  return (
    <>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
        <label className="basic-title mx-2 mb-1">ADD Head Quarters</label>
      </div>
      <div className="col-xl-6 col-md-9">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, values }) => (
            <Form>
              <FormEnableCheck />
              <div className="mx-4 px-3">
                <div className="my-2">
                  <InputCapitalField
                    setFieldValue={setFieldValue}
                    id={"name"}
                    label={"Head quarters Name"}
                    labelColor="color_262c3d"
                    type={"text"}
                    placeholder={"Enter head quarters name"}
                    name={"name"}
                    value={values?.name}
                  />
                  <div className="select-container">
                    <label className="w-100 mx-1 fn_Nunito fs_14">State</label>
                    <Field name={"state_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={stateOptions}
                          styles={customStyles}
                          placeholder={"Enter your state"}
                          value={stateOptions.filter(
                            (s) => s.value == values.state_id
                          )}
                          onChange={(selected) => {
                            setSelectedState(selected?.value);
                            setFieldValue("districts", []);
                            setFieldValue("state_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="state_id" />
                  </div>
                  <div className="select-container">
                    <label className="w-100 mx-1 fn_Nunito fs_14">
                      District
                    </label>
                    <Field name={"districts"}>
                      {({ field, meta }) => (
                        <Select
                          isMulti
                          {...field}
                          options={districtOptions}
                          styles={customStyles}
                          placeholder={"Enter your districts"}
                          onChange={(selected) => {
                            console.log(selected?.value);
                            setFieldValue("districts", selected);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="districts" />
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ maxWidth: "450px" }}
                >
                  <button
                    type="submit"
                    className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                    style={{ maxWidth: "210px" }}
                    disabled={disable}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />

      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
    </>
  );
};
export default HeadQuarterAdd;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    // height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
