import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./image-view-modal.css";
import ImageViewOption from "./image-view-option";

const ImageViewModal = ({ show, ShowClose, imageUrlData, imageTitleData }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={ShowClose}
      dialogClassName="image-viewer-container col-11 px-5 mx-auto"
      size="lg"
    >
      <ModalHeader
        className="d-flex justify-content-between align-items-center"
        closeButton
      >
        <span className="fn_Nunito fw_600 fs_18 px-2">
          Image -
          <span className="fw_600 Medium_Purple ms-1 fs_18">
            {imageTitleData}
          </span>
        </span>
      </ModalHeader>
      <ModalBody className="text-nowrap">
        <div
          style={{
            width: "100%",
            height: "45rem",
            position: "relative",
            overflow: "hidden",
          }}
          className="p-2"
        >
          <ImageViewOption image={imageUrlData} alt="Not a proper Image" />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageViewModal;
