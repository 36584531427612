import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import eye from "../../../assets/icons/viewEye.svg";
import PdfView from "../../../components/modals/pdfView";

const ManufacturerDetails = ({ manufacturerViewData, totalOrders }) => {
  const data = [
    {
      heading: "Email Address",
      title: manufacturerViewData?.email,
    },
    {
      heading: "District",
      title: manufacturerViewData?.district?.name,
    },
    {
      heading: "Mobile Number",
      title: `+${manufacturerViewData?.country_code} ${manufacturerViewData?.mobile}`,
    },
    {
      heading: "State",
      title: manufacturerViewData?.state?.name,
    },
    {
      heading: "Alternate Mobile Number",
      title: `+${manufacturerViewData?.country_code} ${manufacturerViewData?.alternate_mobile}`,
    },

    {
      heading: "City",
      title: manufacturerViewData?.city?.name,
    },
    {
      heading: "Address",
      title: `${manufacturerViewData?.address?.address_one}, ${manufacturerViewData?.address?.address_two}, ${manufacturerViewData?.address?.address_three}`,
    },
    {
      heading: "Pincode",
      title: manufacturerViewData?.pincode,
    },
    {
      heading: "Landmark",
      title: manufacturerViewData?.address?.landmark,
    },
  ];
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);

  return (
    <>
      <div className="basic-details-container my-3 mt-4  my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">Basic Details</label>
      </div>
      <div className="row mt-3">
        <div className="col-xl-6">
          <div className=" px-3">
            <div className="my-2">
              <div className="upload-image-label-container d-flex justify-content-between">
                <label className="my-1 Neutral_Color">Manufacturer Logo</label>
              </div>
              <div className="view-logo-upload-container">
                <img
                  className="view-image-upload"
                  style={{ borderRadius: "7px" }}
                  src={manufacturerViewData?.logo}
                />
              </div>
            </div>
            {/* <div className="mb-2">
              <label className=" Neutral_Color fs_14">Manufacturer ID</label>
              <p className="fs_14">{manufacturerViewData?.employee_id}</p>
            </div> */}
            <div className="my-2">
              <label className=" Neutral_Color fs_14">Manufacturer Name</label>
              <p className="fs_14">{manufacturerViewData?.name}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">Drug License 1 </label>
              <p className="fs_14">{manufacturerViewData?.drug_licence_1}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">Drug License 2 </label>
              <p className="fs_14">{manufacturerViewData?.drug_licence_2}</p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className=" px-3">
            <div className="my-2">
              <label className=" Neutral_Color fs_14">CIN. No</label>
              <p className="fs_14">{manufacturerViewData?.cin_number}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">FSSAI Number</label>
              <p className="fs_14">{manufacturerViewData?.fssai_number}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">GSTIN Number</label>
              <p className="fs_14">{manufacturerViewData?.gstin_number}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">PAN Number</label>
              <p className="fs_14">{manufacturerViewData?.pan_number}</p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">Order Validity</label>
              <p className="fs_14">
                {manufacturerViewData?.setting?.order_validity}
              </p>
            </div>
            <div className="my-2">
              <label className=" Neutral_Color fs_14">Invoice Value %</label>
              <p className="fs_14">
                {manufacturerViewData?.invoicing_value ? manufacturerViewData?.invoicing_value + "%" : "--"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="basic-details-container my-3 mt-4  my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">Contact Details</label>
      </div>
      <div>
        <div className="row mt-3">
          <div className="pt-3 ps-5 row">
            {data.map((item, index) => (
              <div
                key={index}
                className="mb-2 col-lg-6 customers-heading-container"
              >
                <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                  {item.heading}
                </h5>
                {item.heading === "Email Address" ? (
                  <p
                    className={`fs_15 fn_Nunito fw_500 color_262c3d text-truncate col-lg-10 email-slice`}
                    title={item.title}
                  >
                    {item.title}
                  </p>
                ) : item.heading ? (
                  <p className="fs_15 fn_Nunito fw_500 color_262c3d">
                    {item.title}
                  </p>
                ) : (
                  <p
                    className={`fs_15 fn_Nunito fw_500 color_262c3d  text-nowrap`}
                  >
                    {item.title}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="basic-details-container my-3 mt-4  my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">Documents</label>
      </div>
      <div className="row mt-3">
        <div className=" col-xl-6">
          <div className=" px-3">
            <div className="my-2">
              <div className="upload-image-label-container d-flex justify-content-between">
                <label className="my-1 Neutral_Color">GST Certificate</label>
              </div>
              {manufacturerViewData?.gst_certificate !== "" &&
                manufacturerViewData?.gst_certificate !== null && (
                  <div
                    className={` h-100`}
                    style={{
                      height: "25rem",
                      // width: "15rem",
                      // border: "1px solid black",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      position: "relative",
                    }}
                  >
                    <div className="manufacturer-doc-upload-container">
                      <Document
                        file={manufacturerViewData?.gst_certificate}
                        options={true}
                      >
                        <Page pageNumber={1} width={415} />
                      </Document>
                    </div>
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                      >
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              // position: "absolute",
                              // zIndex: "55",
                              // top: "80px",
                              // left: "180px",
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setOpenPdf(true);
                              setPdfFileUrl(
                                manufacturerViewData?.gst_certificate
                              );
                              setFileName("GST Certificate");
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                    <PdfView
                      show={openPdf}
                      handleClose={() => setOpenPdf(false)}
                      pdfUrl={pdfFileUrl}
                      filename={filename}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className=" col-xl-6">
          <div className=" px-3">
            <div className="my-2">
              <div className="upload-image-label-container d-flex justify-content-between">
                <label className="my-1 Neutral_Color">
                  Drug Manufacturing License
                </label>
              </div>

              {manufacturerViewData?.drug_manufacturing_license !== "" &&
                manufacturerViewData?.drug_manufacturing_license !== null && (
                  <div
                    className={` h-100`}
                    style={{
                      height: "25rem",
                      // width: "15rem",
                      // border: "1px solid black",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      position: "relative",
                    }}
                  >
                    <div className="manufacturer-doc-upload-container">
                      <Document
                        file={manufacturerViewData?.drug_manufacturing_license}
                      >
                        <Page pageNumber={1} width={415} />
                      </Document>
                    </div>

                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                      >
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              // position: "absolute",
                              // zIndex: "55",
                              // top: "80px",
                              // left: "180px",
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setOpenPdf(true);
                              setPdfFileUrl(
                                manufacturerViewData?.drug_manufacturing_license
                              );
                              setFileName("Drug Manufacturing License");
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <PdfView
        show={openPdf}
        handleClose={() => setOpenPdf(false)}
        pdfUrl={pdfFileUrl}
        filename={filename}
      />
    </>
  );
};

export default ManufacturerDetails;
