import { url } from "../config";
import { api } from "./api";

export const manufacturerListApi = (params) => {
  return api.get(`/admin/manufacturer/list`, { params });
};
export const manufacturerPremiumListApi = (params) => {
  return api.get(`/admin/manufacturer/premium-list`, { params });
};

export const manufacturerCreateDraftApi = (params) => {
  return api.post(`/admin/manufacturer/create-draft`, JSON.stringify(params));
};

export const manufacturerCreateApi = (params) => {
  return api.post(`/admin/manufacturer/create`, JSON.stringify(params));
};
export const manufacturerEditApi = (params) => {
  return api.post(`/admin/manufacturer/edit`, JSON.stringify(params));
};
export const manufacturerViewApi = (params) => {
  return api.get(`/admin/manufacturer/view`, { params: params });
};
export const manufacturerVersionListApi = (params) => {
  return api.get(`/admin/manufacturer/version-list`, { params });
};
export const manufacturerPremiumVersionListApi = (params) => {
  return api.get(`/admin/manufacturer/premium-version-list`, { params });
};
export const manufacturerVersionDetailApi = (params) => {
  return api.get(`/admin/manufacturer/version-view`, { params: params });
};
export const reimbursementListApi = (params) => {
  return api.get(`/admin/manufacturer/reimbursement-list`);
};
export const manufacturerDeleteApi = (params) => {
  return api.delete(`/admin/manufacturer/delete`, { params: params });
};
export const manufacturerEnableDisableApi = (params) => {
  return api.post(`/admin/manufacturer/enable-disable`, JSON.stringify(params));
};

export const manufacturerLogoUploadApi = (params) => {
  return api.post(`/admin/manufacturer/upload-logo`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const manufactuereDocumentUploadApi = (params) => {
  return api.post(`admin/manufacturer/upload-document`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const productCsvUploadApi = (params, file) => {
  return api.post(`/admin/manufacturer/upload-product`, file, {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    baseURL: url,
  });
};

export const productBulkVerify = (params) => {
  return api.post(`/admin/manufacturer/verify-product`, JSON.stringify(params));
};

export const fieldForceCsvUploadApi = (params, file) => {
  return api.post(`/admin/manufacturer/upload-field-force`, file, {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    baseURL: url,
  });
};

export const fieldForceBulkVerify = (params) => {
  return api.post(
    `/admin/manufacturer/verify-field-force-user`,
    JSON.stringify(params)
  );
};

export const distributorCSVUploadApi = (params, file) => {
  return api.post(`/admin/manufacturer/upload-distributor`, file, {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    baseURL: url,
  });
};

export const distributorBulkVerify = (params) => {
  return api.post(
    `/admin/manufacturer/verify-distributor`,
    JSON.stringify(params)
  );
};

export const doctorCSVUploadApi = (params, file) => {
  return api.post(`/admin/manufacturer/upload-doctor`, file, {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    baseURL: url,
  });
};

export const doctorBulkVerify = (params) => {
  return api.post(`/admin/manufacturer/verify-doctor`, JSON.stringify(params));
};

export const pharmacyCSVUploadApi = (params, file) => {
  return api.post(`/admin/manufacturer/upload-pharmacy`, file, {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    baseURL: url,
  });
};

export const pharmacyBulkVerify = (params) => {
  return api.post(
    `/admin/manufacturer/verify-pharmacy`,
    JSON.stringify(params)
  );
};

// DIVISION VIEW STARTS

export const productDivisionViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/products`, { params: params });
};

export const ffDivisionViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/field-force-users`, {
    params: params,
  });
};

export const doctorDivisionViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/doctors`, {
    params: params,
  });
};

export const pharmacyDivisionViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/pharmacies`, {
    params: params,
  });
};

export const doctorDivViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/doctor/view`, {
    params: params,
  });
};
export const createDoctorApi = (params) => {
  return api.post(
    `/admin/manufacturer/division/doctor/create`,
    JSON.stringify(params)
  );
};

export const createPharmacyApi = (params) => {
  return api.post(
    `/admin/manufacturer/division/pharmacy/create`,
    JSON.stringify(params)
  );
};

export const pharmacyDivViewApi = (params) => {
  return api.get(`/admin/manufacturer/division/pharmacy/view`, {
    params: params,
  });
};

// DIVISION VIEW ENDS

export const loginToPortalManufacturerApi = (params) => {
  return api.get(`/admin/manufacturer/login-portal`, { params: params });
};
export const loginToPortalCfaApi = (params) => {
  return api.get(`/admin/manufacturer/cfa/login-portal`, { params: params });
};

export const sendMailApi = (params) => {
  return api.post(`/admin/manufacturer/send-mail`, JSON.stringify(params));
};

export const addDesignationApi = (params) => {
  return api.post(
    `/admin/manufacturer/designation/add`,
    JSON.stringify(params)
  );
};

export const addDivisionApi = (params) => {
  return api.post(`/admin/manufacturer/division/add`, JSON.stringify(params));
};

export const addHeadQuarterApi = (params) => {
  return api.post(
    `/admin/manufacturer/head-quarter/add`,
    JSON.stringify(params)
  );
};

export const editDesignationApi = (params) => {
  return api.post(
    `/admin/manufacturer/designation/edit`,
    JSON.stringify(params)
  );
};

export const editDivisionApi = (params) => {
  return api.post(`/admin/manufacturer/division/edit`, JSON.stringify(params));
};

export const editHeadQuarterApi = (params) => {
  return api.post(
    `/admin/manufacturer/head-quarter/edit`,
    JSON.stringify(params)
  );
};

export const enableDisableHeadQuaterApi = (params) => {
  return api.post(
    `/admin/manufacturer/head-quarter/enable-disable?vendor_id=${params?.vendor_id}&head_quater_id=${params?.head_quater_id}`,
    { params: params }
  );
};

export const enableDisableDivisionApi = (params) => {
  return api.post(
    `/admin/manufacturer/division/enable-disable?vendor_id=${params?.vendor_id}&division_id=${params?.division_id}`,
    { params: params }
  );
};

export const poStateListApi = (params) => {
  return api.get(`/admin/manufacturer/purchase-order/state-list`);
};

export const poDistributorListApi = (params) => {
  return api.get(
    `/admin/manufacturer/purchase-order/distributor-list?vendor_id=${params.vendor_id}&state_id=${params.state_id}`
  );
};
export const poOfferTypeListApi = (params) => {
  return api.get(
    `/admin/manufacturer/purchase-order/offer-list?vendor_id=${params.vendor_id}`
  );
};
export const poListApi = (params) => {
  return api.get(`/admin/manufacturer/purchase-order/list`, { params: params });
};
export const generatePoApi = (params) => {
  return api.post(
    `/admin/manufacturer/purchase-order/generate`,
    JSON.stringify(params)
  );
};
export const poUnvoicedListApi = (params) => {
  return api.get(`/admin/manufacturer/purchase-order/uninvoiced-po-list`, {
    params: params,
  });
};
export const poInvoicedListApi = (params) => {
  return api.get(`/admin/manufacturer/purchase-order/invoiced-po-list`, {
    params: params,
  });
};
export const generatePoInvoiceApi = (params) => {
  return api.get(`/admin/manufacturer/purchase-order/fetch-po-list`, {
    params: params,
  });
};
export const generateInvoiceApi = (params) => {
  return api.post(
    `/admin/manufacturer/purchase-order/generate-invoice`,
    JSON.stringify(params)
  );
};
export const invoiceChangeStatusApi = (params) => {
  return api.post(
    `/admin/manufacturer/purchase-order/change-status`,
    JSON.stringify(params)
  );
};
export const InvoiceDetailsApi = (params) => {
  return api.get(
    `/admin/manufacturer/purchase-order/invoice-view?invoice_id=${params.invoice_id}`
  );
};
export const invoiceSendMailApi = (params) => {
  return api.post(
    `/admin/manufacturer/purchase-order/send-mail`,
    JSON.stringify(params)
  );
};
export const poCsvSendMailApi = (params) => {
  return api.post(
    `admin/manufacturer/purchase-order/generate-csv`,
    JSON.stringify(params)
  );
};
export const poCsvDownloadApi = (params) => {
  return api.post(
    `/admin/manufacturer/purchase-order/generate-csv`,
    JSON.stringify(params)
  );
};

export const poViewApi = (params) => {
  return api.get(
    `/admin/manufacturer/purchase-order/po-view`,
    { params: params }
  );
};

export const toggleAnalyticsApi = (params) => {
  return api.post(
    `/admin/manufacturer/analytics-toggle`,
    JSON.stringify(params)
  );
};
