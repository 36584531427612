import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Select from "react-select";
import "./dashboard.css";
import {
  Chart as ChartJS,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { getYears } from "../../components/utilites/helper";

ChartJS.register(
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const DashboardGraph = ({
  setYear,
  year,
  month,
  setMonth,
  setShowMonthSelect,
  showMonthSelect,
  graphData,
  label,
}) => {
  useEffect(() => {
    console.log(year);
    console.log(setYear);
  });

  const theData = {
    data: {
      labels:
        showMonthSelect == "Monthly"
          ? Array(graphData?.length)
              .fill()
              .map((_, i) => i + 1)
          : [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
      datasets: [
        {
          data: graphData,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, "rgba(42, 211, 231, 0.5)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.5)");
            return gradient;
          },
          tension: 0.4,
          fill: true,
          showLine: true,
          borderColor: "#2AD3E7",
          borderWidth: 2,
          pointStyle: "circle",
          pointRadius: 3,
          pointBackgroundColor: "#2AD3E7",
          pointBorderColor: "#2AD3E7",
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#668185",
          titleColor: "white",
          bodyColor: "white",
          bodyFont: {
            weight: "bold",
            size: 14,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            display: true,
          },
          grid: {
            display: true,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const annualOptions = [
    {
      value: "Annually",
      label: "Annually",
    },
    {
      value: "Monthly",
      label: "Monthly",
    },
  ];
  const yearOptions = getYears()

  const monthOptions = [
    {
      value: "1",
      label: "Jan",
    },
    {
      value: "2",
      label: "Feb",
    },
    {
      value: "3",
      label: "Mar",
    },
    {
      value: "4",
      label: "Apr",
    },
    {
      value: "5",
      label: "May",
    },
    {
      value: "6",
      label: "Jun",
    },
    {
      value: "7",
      label: "July",
    },
    {
      value: "8",
      label: "Aug",
    },
    {
      value: "9",
      label: "Sep",
    },
    {
      value: "10",
      label: "Oct",
    },
    {
      value: "11",
      label: "Nov",
    },
    {
      value: "12",
      label: "Dec",
    },
  ];

  return (
    <div className="graph-container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h5 style={{ color: "#262C3D", width: "300px" }}>{label}</h5>
        </div>
        <div className="dropdown-container">
          <div className="d-flex justify-content-end">
            <div>
              <Select
                options={annualOptions}
                styles={customStyles}
                className="select-element"
                value={annualOptions.filter((s) => s.value == showMonthSelect)}
                onChange={(e) => {
                  if (e.value == "Annually") {
                    setShowMonthSelect("Annually");
                    setMonth(null);
                  } else {
                    setShowMonthSelect("Monthly");
                    setMonth(new Date().getMonth() + 1);
                  }
                }}
              />
            </div>
            <div>
              <Select
                options={yearOptions}
                styles={customStyles}
                value={yearOptions.filter((s) => s.value == year)}
                className="select-element"
                onChange={(e) => {
                  setYear(e.value);
                }}
              />
            </div>
            {showMonthSelect == "Monthly" && (
              <div>
                <Select
                  options={monthOptions}
                  styles={customStyles}
                  className="select-element"
                  value={monthOptions.filter((s) => s.value == month)}
                  onChange={(e) => setMonth(e.value)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Line data={theData.data} options={theData.options}>
        Orders
      </Line>
    </div>
  );
};

export default DashboardGraph;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    backgroundColor: "#E7E5FF",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
