import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Modal, ModalBody } from "react-bootstrap";
import Select from "react-select";
import { Field, Form, Formik, useFormikContext } from "formik";
import PurchaseOrderTable from "./purchase-order/purchaseOrderTable";
import {
  PoDistributorList,
  PoStateList,
  generatePo,
  poCsvDownload,
} from "../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import ErrorMsg from "../../form/errorMsg";
import { useParams } from "react-router-dom";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopup from "../../popups/SuccessPopup";

const GeneratePoModal = ({ show, handleClose, manufacturerViewData }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [purchaseTableShow, setPurchaseTableShow] = useState(false);
  const [poStateList, setPoStateList] = useState([]);
  const [poDistributorList, setPoDistributorList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [purchaseOrderMailData, setPurchaseOrderMailData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [generateCSV, setGenerateCSV] = useState(false);
  const [generateCSVId, setGenerateCSVId] = useState(null);
  const [csvTableData, setcsvTableData] = useState([]);
  const vendorId = params?.id.split("&")?.[0];
  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (generateCSV == true && generateCSVId !== null) {
      dispatch(
        poCsvDownload(
          { purchase_order_id: generateCSVId, vendor_id: vendorId },
          onCsvSuccess,
          onCsvError
        )
      );
    }
  }, [generateCSVId, generateCSV]);

  useEffect(() => {
    if (show == true) {
      dispatch(PoStateList(onStateSuccess, onStateError));
    }
  }, [show]);


  const onStateSuccess = (data) => {
    console.log(data, "state list");
    setPoStateList(data);
  };

  const onStateError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        PoDistributorList(
          { state_id: selectedState, vendor_id: vendorId },
          onGetDistributorSuccess,
          onGetDistributorError
        )
      );
    }
  }, [selectedState]);

  const onGetDistributorSuccess = (data) => {
    if (data.length > 0) {
      setPoDistributorList([{ label: "Select All", value: null }, ...data]);
    }
  };
  console.log(poDistributorList, "distributor list");

  const onGetDistributorError = (err) => {
    console.log(err);
  };

  const initialValues = {
    vendor_id: manufacturerViewData?.vendor?.id,
    state_id: "",
    distributor_id: "",
    reimbursement_method: "",
    order_date_from: "",
    order_date_to: ""
  };
  const FormEnableCheck = () => {
    const {
      values: { state_id, reimbursement_method },
    } = useFormikContext();

    useEffect(() => {
      if (state_id !== "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [state_id, reimbursement_method]);
    return null;
  };

  const reimbursementOptions = [
    { value: "order_off_qty", label: "Order Offer Quantity" },
    { value: "full_order_qty", label: "Full Order Quantity" },
    { value: "credit_note", label: "Credit Note for Offer" }
  ];

  const validationSchema = Yup.object().shape({
    state_id: Yup.string().required("Required"),
    distributor_id: Yup.string().typeError().strict(true).validateSync(""),
    reimbursement_method: Yup.string().typeError().strict(true).validateSync(""),
    vendor_id: Yup.string().required("Required"),
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(generatePo(data, onSuccess, onError));
    handleClose();
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup(false);
  };

  const onSuccess = (data) => {
    setGenerateCSV(true);
    setGenerateCSVId(data?.data?.data?.data?.id);
    console.log(data?.data?.data?.data?.id, "generated data");
    setPurchaseOrderData(data?.data?.data?.data);
    setPurchaseOrderMailData(data?.data?.data);
  };

  const onCsvSuccess = (data) => {
    console.log(data.data.data);
    setcsvTableData(data?.data?.data);
    setPurchaseTableShow(true);
  };

  const onCsvError = (err) => { };

  const timeOutFnc = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err.data.message);
    setShowErrorMsg(err.data.message);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc, 2500);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() => {
          handleClose();
          setPoDistributorList([]);
          setPoStateList([]);
          setSelectedState(null);
        }}
        dialogClassName=""
        contentClassName="po-modal col-5 mx-auto p-3 fn_Nunito"
      >
        <ModalBody>
          <label className="fn_Nunito fw_700 fs_20">
            Generate Purchase Order
          </label>
          <br />
          <div className="my-3">
            <label className="color_747f9e fs_14">Manufacturer</label>
            <br />
            <span>{manufacturerViewData?.vendor?.name}</span>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form className="p-1">
                <FormEnableCheck />
                <div className="my-2">
                  <label className="color_747f9e fw_400 fs_14 my-1">
                    State
                  </label>
                  <Field>
                    {({ form, Field }) => (
                      <Select
                        options={poStateList}
                        styles={customStyles}
                        {...Field}
                        placeholder={"Select state"}
                        name="state_id"
                        id="state_id"
                        value={poStateList.filter(
                          (c) => c.value == values?.state_id
                        )}
                        onChange={(selectedOption) => {
                          setSelectedState(selectedOption?.value);
                          setFieldValue("state_id", selectedOption?.value);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMsg name="state_id" />
                </div>
                <div className="my-2">
                  <label className="color_747f9e fw_400 fs_14 my-1">
                    Distributor
                  </label>
                  <Field>
                    {({ form, Field }) => (
                      <Select
                        options={poDistributorList}
                        styles={customStyles}
                        {...Field}
                        placeholder={"Select distributor"}
                        name="distributor_id"
                        id="distributor_id"
                        value={poDistributorList.filter(
                          (c) => c.value == values?.distributor_id
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            "distributor_id",
                            selectedOption?.value
                          );
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMsg name="distributor_id" />
                </div>
                <div className="my-1">
                  <div className="my-2">
                    <label className="color_747f9e fw_400 fs_14 my-1">
                      Reimbursement Method
                    </label>
                    <Field>
                      {({ form, Field }) => (
                        <Select
                          options={reimbursementOptions}
                          styles={customStyles}
                          {...Field}
                          placeholder={"Select Reimbursement Method"}
                          name="reimbursement_method"
                          id="reimbursement_method"
                          value={reimbursementOptions.filter(
                            (c) => c.value == values?.reimbursement_method
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue("reimbursement_method", selectedOption?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="reimbursement_method" />
                  </div>
                </div>
                <div className="my-1 d-flex gap-3">
                  <div className="my-2 w-100">
                    <label className="color_747f9e fw_400 fs_14 my-1">
                      Order From
                    </label>
                    <Field
                      type="date"
                      style={{
                        border: "1px solid #747F9E",
                        borderRadius: "7px",
                      }}
                      className="py-1 p-2 w-100"
                      placeholder="Select Date"
                      id="order_date_from"
                      name="order_date_from"
                      max={currentDate}
                    />
                    <ErrorMsg name="order_date_from" />
                  </div>
                  <div className="my-2 w-100">
                    <label className="color_747f9e fw_400 fs_14 my-1">
                      Order To
                    </label>
                    <Field
                      type="date"
                      style={{
                        border: "1px solid #747F9E",
                        borderRadius: "7px",
                      }}
                      className="py-1 p-2 w-100"
                      placeholder="Select Date"
                      id="order_date_to"
                      name="order_date_to"
                      max={currentDate}
                    />
                    <ErrorMsg name="order_date_to" />
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    className="close-btn  px-2 py-2 my-2 fs_12 w-50 me-2"
                    onClick={handleClose}
                  >
                    <span>CANCEL</span>
                  </button>
                  <button
                    type="submit"
                    className="filter-btn px-2 py-3 my-2 fs_12 w-50 ms-2 fw_500"
                    disabled={disable}
                  >
                    Generate Purchase Order
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <PurchaseOrderTable
        csvTableData={csvTableData}
        manufacturerViewData={manufacturerViewData}
        purchaseOrderData={purchaseOrderData}
        purchaseOrderMailData={purchaseOrderMailData}
        handleClosePO={() => setPurchaseTableShow(false)}
        showPO={purchaseTableShow}
      />
      <ErrorPopup
        show={showErrorPopup}
        // title={"No verified invoices currently"}
        title={showErrorMsg}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
    </>
  );
};

export default GeneratePoModal;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    height: "43px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
