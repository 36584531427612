import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../../components/layout/table-container/table-container";
import TablePagination from "../../../components/Pagination/tablePagination";
import { customerVersionList } from "../../../redux/actions/customerActions";
import CustomersVersionTableData from "./customer-version-table-data";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const CustomerVersionTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [customerVersionListData, setCustomerVersionListData] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [active, setActive] = useState("");
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(
        customerVersionList({ search, status, page }, onSuccess, onError)
      );
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      dispatch(
        customerVersionList({ search, status, page }, onSuccess, onError)
      );
    }
  }, [status]);

  useEffect(() => {
    dispatch(customerVersionList({ search, status, page }, onSuccess, onError));
    setLoading(true);
  }, [page, status]);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setCustomerVersionListData(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };
  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"3%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Jivini’s Doctor Code
            </th>
            <th className="table-heads " width={"10%"}>
              Jivini’s Pharmacy Code
            </th>
            <th className="table-heads " width={"10%"}>
              Doctor Name
            </th>
            <th className="table-heads " width={"10%"}>
              Pharmacy Name
            </th>
            <th className="table-heads " width={"10%"}>
              City
            </th>
            <th className="table-heads " width={"8%"}>
              Pharmacy Type
            </th>
            <th className="table-heads text-nowrap" width={"6%"}>
              <span onClick={() => setOpenStatus(!openStatus)}>
                Status
                {openStatus ? (
                  <img src={UpArrow} className="mb-1 mx-1" />
                ) : (
                  <img src={DownArrow} className=" mx-1" />
                )}
              </span>
              {openStatus ? (
                <div className=" position-relative">
                  <div className="position-absolute status-container d-flex gap-3 px-3">
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="active"
                        name="status"
                        value={true}
                        checked={status == "true"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("active");
                        }}
                      />
                      <label
                        htmlFor="active"
                        className={
                          active === "active"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Active
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="inactive"
                        name="status"
                        value={false}
                        checked={status == "false"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("inactive");
                        }}
                      />
                      <label
                        htmlFor="inactive"
                        className={
                          active === "inactive"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Inactive
                      </label>
                    </div>
                    <button
                      className="filter-btn px-2 py-0 my-2 fs_12"
                      style={{ height: "28px" }}
                      onClick={() => {
                        setStatus(null);
                        setOpenStatus(!openStatus);
                        setActive("");
                      }}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              ) : null}
            </th>
            <th className="table-heads " width={"30%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : customerVersionListData?.data?.length > 0 ? (
            <>
              {customerVersionListData?.data?.map((eachRow, index) => (
                <CustomersVersionTableData
                  data={eachRow}
                  index={index}
                  key={index}
                  currentPage={customerVersionListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {customerVersionListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={customerVersionListData?.pagination}
            nextFunc={() => {
              navigate(
                `/customers/customer/version-list/${customerVersionListData?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/customers/customer/version-list/${customerVersionListData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
    </>
  );
};

export default CustomerVersionTable;

const data = [
  {
    id: "1",
    doctorCode: "DR1234567",
    pharmacyCode: "PH1234567",
    doctorName: "Dr. Amal Sabu",
    pharmacyName: "1Pharmacy Name Goes...",
    city: "Whitefield, Bengaluru",
    pharmacyType: "Dispensing",
    status: "Active",
  },
  {
    id: "2",
    doctorCode: "DR1234567",
    pharmacyCode: "PH1234569",
    doctorName: "Dr. Amal Sabu",
    pharmacyName: "1Pharmacy Name Goes...",
    city: "Whitefield, Bengaluru",
    pharmacyType: "Dispensing",
    status: "Inactive",
  },
  {
    id: "3",
    doctorCode: "DR1234567",
    pharmacyCode: "PH1234568",
    doctorName: "Dr. Amal Sabu",
    pharmacyName: "1Pharmacy Name Goes...",
    city: "Whitefield, Bengaluru",
    pharmacyType: "Dispensing",
    status: "Active",
  },
];
