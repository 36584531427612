import React, { useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import InputField from "../../../../components/form/inputField/inputField";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField"
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  divisionAdd,
} from "../../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SuccessPopup from "../../../../components/popups/SuccessPopup";

const DivisionAdd = ({ setActiveDivision }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [active, setActive] = useState("");
  const [disable, setDisable] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState('')
  const [reimbursementActive, setReimbursementActive] = useState("");
  const navigate = useNavigate()

  const initialValues = {
    name: "",
    offer_type: "",
    order_limit: "",
    order_amount: "",
    reimbursement_method: "",
    approval_level: "",
  };
  const validationSchema = Yup.object().shape({
    // division_name: Yup.string().required("Required"),
    // offer_type: Yup.string().required("Required"),
    // reimbursement_method: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    values["vendor_id"] = params?.id.split("&")?.[0];
    dispatch(divisionAdd(values, onAddSuccess, onAddError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate(`/manufacturer/subscibe-list/view/${params?.id.split("&")?.[0]}&divisions&list`);
  }

  const onAddSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    setShowSuccessMsg(data.data.message)
    setTimeout(timeOutFnc, 2000)
  };
  const onAddError = (err) => {
    console.log(err);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, offer_type, order_limit, order_amount, reimbursement_method, approval_level },
    } = useFormikContext();
    useEffect(() => {
      if (
        name.trim() != "" &&
        offer_type != "" &&
        order_limit != "" &&
        reimbursement_method != "" &&
        order_amount != 0 &&
        approval_level != ""
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [name, offer_type, order_limit, order_amount, reimbursement_method]);
    return null;
  };

  const options = [
    { value: "quantity", label: "Quantity" },
    { value: "price", label: "Price" },
    { value: "discount", label: "Discount" },
  ];

  const approvalOptions = [
    { value: "L1", label: "LEVEL 1" },
    { value: "L2", label: "LEVEL 2" },
    { value: "L3", label: "LEVEL 3" },
    { value: "L4", label: "LEVEL 4" },
    { value: "L5", label: "LEVEL 5" },
    { value: "L6", label: "LEVEL 6" },
    { value: "L7", label: "LEVEL 7" },
    { value: "L8", label: "LEVEL 8" },
    { value: "L9", label: "LEVEL 9" },
    { value: "L10", label: "LEVEL 10" },
    { value: "L11", label: "LEVEL 11" },
    { value: "L12", label: "LEVEL 12" },
    { value: "L13", label: "LEVEL 13" },
    { value: "L14", label: "LEVEL 14" },
    { value: "L15", label: "LEVEL 15" },
    { value: "admin", label: "ADMIN" },
  ];

  const reimbursementOptions = [
    { value: "forward_cfa", label: "Full Order Forward from CFA" },
    { value: "order_off_qty", label: "Order Offer Quantity" },
    { value: "full_order_qty", label: "Full Order Quantity" },
    { value: "credit_note", label: "Credit Note for Offer" }
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
              <label className="basic-title mx-2 mb-1">Add Divisions</label>
            </div>
            <div className="col-xl-6 col-md-9">
              <div className="mx-4 px-3">
                <div className="my-2">
                  <InputCapitalField
                    setFieldValue={setFieldValue}
                    id={"name"}
                    label={"Division Name"}
                    labelColor="color_747f9e"
                    type={"text"}
                    placeholder={"Enter your division name"}
                    name={"name"}
                    value={values?.name}
                  />
                  <div className="select-container">
                    <label className="w-100 mx-1 fn_Nunito fs_14 color_747f9e">
                      Offer Type
                    </label>
                    <Field name={"offer_type"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={options}
                          styles={customStyles}
                          placeholder={"Select Offer type"}
                          value={options.filter(
                            (s) => s.value == values?.offer_type
                          )}
                          onChange={(selected) => {
                            setFieldValue("offer_type", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="offer_type" />
                  </div>
                  <div className="select-container">
                    <label className="w-100 mx-1 fn_Nunito fs_14 color_747f9e">
                      Approval Level
                    </label>
                    <Field name={"approval_level"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={approvalOptions}
                          styles={customStyles}
                          placeholder={"Select Approval Level"}
                          value={approvalOptions.filter(
                            (s) => s.value == values?.approval_level
                          )}
                          onChange={(selected) => {
                            setFieldValue(
                              "approval_level",
                              selected?.value
                            );
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="approval_level" />
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e">Reimbursement method</label>
                    <div>
                      {reimbursementOptions?.map((ele, index) => (
                        <div
                          className="d-flex align-items-start my-2"
                          key={index}
                        >
                          <span className="fs_14 my-1">
                            <Field
                              type="radio"
                              className="radio me-1"
                              value={ele.value}
                              name="reimbursement_method"
                              onClick={() => {
                                setReimbursementActive(ele.value);
                              }}
                            />
                          </span>
                          <label className={
                            reimbursementActive === ele.value
                              ? "color_262c3d mx-1 mt-1"
                              : "color_747f9e mx-1 mt-1"
                          } >{ele.label}</label>
                        </div>
                      ))}
                      <ErrorMsg name={"reimbursement_method"} />
                    </div>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e">Order limit per customer</label>
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center ">
                        <Field
                          className="radio me-2"
                          type="radio"
                          id="Weekly"
                          name="order_limit"
                          value="weekly"
                          onClick={() => {
                            setActive("Weekly");
                          }}
                        />
                        <label
                          for="Weekly"
                          className={
                            active === "Weekly"
                              ? "color_262c3d"
                              : "color_747f9e"
                          }
                        >
                          Weekly
                        </label>
                      </div>
                      <div className="d-flex align-items-center ">
                        <Field
                          className="radio mx-2"
                          type="radio"
                          id="Monthly"
                          name="order_limit"
                          value="monthly"
                          onClick={() => {
                            setActive("Monthly");
                          }}
                        />
                        <label
                          for="Monthly"
                          className={
                            active === "Monthly"
                              ? "color_262c3d"
                              : "color_747f9e"
                          }
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="d-flex align-items-center ">
                        <Field
                          className="radio mx-2"
                          type="radio"
                          id="Quarterly"
                          name="order_limit"
                          value="quarterly"
                          onClick={() => {
                            setActive("Quarterly");
                          }}
                        />
                        <label
                          for="Quarterly"
                          className={
                            active === "Quarterly"
                              ? "color_262c3d"
                              : "color_747f9e"
                          }
                        >
                          Quarterly
                        </label>
                      </div>
                    </div>
                    <ErrorMsg name={"order_limit"} />
                    <InputField
                      id={"order_amount"}
                      labelColor="color_262c3d"
                      type={"text"}
                      placeholder={"Enter Order Amount"}
                      name={"order_amount"}
                      value={values?.order_amount}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                    style={{ maxWidth: "210px" }}
                    disabled={disable}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg} />
    </>
  );
};
export default DivisionAdd;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
