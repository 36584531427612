import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import back from "../../../../assets/icons/back.svg";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField";
import InputField from "../../../../components/form/inputField/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  gevCfaEdit,
  gevCfaView,
} from "../../../../redux/actions/godsEyeViewActions";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import { fetchStateList } from "../../../../redux/actions/commonActions";
import Select from "react-select";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";
import ErrorPopup from "../../../../components/popups/ErrorPopup";

const CfaEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [cfaData, setCfaData] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setshowErrorMsg] = useState("");
  const subId = params?.id.split("&")?.[3];
  const vendorId = params?.id.split("&")?.[0];

  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  useEffect(() => {
    gevCfaViewFnc();
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  const gevCfaViewFnc = () => {
    setLoading(true);
    dispatch(gevCfaView(subId, onffSuccess, onffError));
  };

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data);
    setCfaData(data?.data?.data);
  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  const initialValues = {
    vendor_id: vendorId,
    cfa_id: subId,
    cfa_name: cfaData?.cfa_name,
    cfa_depot_manager: cfaData?.cfa_depot_manager,
    mobile: cfaData?.mobile,
    alternate_mobile: cfaData?.alternate_mobile,
    email: cfaData?.email,
    address_one: cfaData?.address?.address_one,
    address_two: cfaData?.address?.address_two,
    address_three: cfaData?.address?.address_three,
    landmark: cfaData?.address?.landmark,
    pincode: cfaData?.pincode,
    state_id: cfaData?.state_id,
  };
  const resetFunc = (setFieldValue) => {
    setFieldValue("cfa_name", cfaData?.cfa_name);
    setFieldValue("cfa_depot_manager", cfaData?.cfa_depot_manager);
    setFieldValue("email", cfaData?.email);
    setFieldValue("mobile", cfaData?.mobile);
    setFieldValue("alternate_mobile", cfaData?.alternate_mobile);
    setFieldValue("address_one", cfaData?.address?.address_one);
    setFieldValue("address_two", cfaData?.address?.address_two);
    setFieldValue("address_three", cfaData?.address?.address_three);
    setFieldValue("landmark", cfaData?.address?.landmark);
    setFieldValue("pincode", cfaData?.pincode);
    setFieldValue("state_id", cfaData?.state_id);
  };
  const validationSchema = Yup.object().shape({
    cfa_name: Yup.string().trim().required("Required"),
    cfa_depot_manager: Yup.string().trim().required("Required"),
    state_id: Yup.string().required("Required"),
    mobile: Yup.string()
      .trim()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    alternate_mobile: Yup.string()
      .trim()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    email: Yup.string().email("Not a proper email").trim().required("Required"),
    address_one: Yup.string().trim().required("Required"),
    address_two: Yup.string().trim().required("Required"),
    address_three: Yup.string().trim().required("Required"),
    landmark: Yup.string().trim().required("Required"),
    pincode: Yup.string()
      .trim()
      .matches(pincoderegex, "Not a valid Number")
      .required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    values["address"] = {
      address_one: values.address_one,
      address_two: values.address_two,
      address_three: values.address_three,
      landmark: values.landmark,
    };
    dispatch(gevCfaEdit(values, onSuccess, onError));
  };
  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate(`/manufacturer/subscibe-list/view/${vendorId}&cfa&list&1`);
  };

  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };
  const onError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const str1 = err.data.message;
    const modifiedStr1 = str1[0].toUpperCase() + str1.slice(1);
    setshowErrorMsg(modifiedStr1);
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div></div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="gev-view-spinner-container">
                <LoadingSpinnerTable />
              </div>
            ) : (
              <>
                <div className="scroll-container">
                  {" "}
                  <div className=" px-4 " style={{ maxWidth: "450px" }}>
                    <div className="my-4">
                      <label className=" color_747F9E fs_14">CFA ID</label>
                      <p className="fs_14">{cfaData?.cfa_id}</p>
                    </div>

                    <div className="my-4">
                      <InputCapitalField
                        setFieldValue={setFieldValue}
                        id={"cfa_name"}
                        label={"CFA Name"}
                        type={"text"}
                        placeholder={"Enter CFA name"}
                        name={"cfa_name"}
                        value={values?.cfa_name}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"cfa_depot_manager"}
                        label={"CFA Depot Manager Name"}
                        type={"text"}
                        placeholder={"Enter CFA depot manager name"}
                        name={"cfa_depot_manager"}
                        value={values?.cfa_depot_manager}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"email"}
                        label={"CFA Email Address"}
                        type={"text"}
                        placeholder={"Enter CFA email address"}
                        name={"email"}
                        value={values?.email}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"mobile"}
                        label={"CFA Depot Manager Mobile Number"}
                        type={"text"}
                        placeholder={"Enter mobile number "}
                        name={"mobile"}
                        value={values?.mobile}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"alternate_mobile"}
                        label={"Alternate Mobile Number"}
                        type={"text"}
                        placeholder={"Enter alternate mobile number"}
                        name={"alternate_mobile"}
                        value={values?.alternate_mobile}
                      />
                    </div>
                    <div className="select-container">
                      <label className="w-100 mx-1 fn_Nunito fs_14">
                        State
                      </label>
                      <Field name={"state_id"}>
                        {({ field, meta }) => (
                          <Select
                            {...field}
                            options={stateOptions}
                            styles={customStyles}
                            placeholder={"Enter your state"}
                            value={stateOptions.filter(
                              (s) => s.value == values.state_id
                            )}
                            onChange={(selected) => {
                              setSelectedState(selected?.value);
                              setFieldValue("districts", []);
                              setFieldValue("state_id", selected?.value);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="state_id" />
                    </div>
                    <div className="my-2">
                      <label className=" mx-1 fn_Nunito fs_14 Neutral_Color">
                        Address
                      </label>
                      <div>
                        <Field name={"address_one"}>
                          {({ field, meta }) => (
                            <input
                              {...field}
                              placeholder="Address line 1"
                              id={"address_one"}
                              className={"w-100 input input-field py-2 my-1"}
                              name={"address_one"}
                              value={values?.address_one}
                            />
                          )}
                        </Field>
                        <Field name={"address_two"}>
                          {({ field, meta }) => (
                            <input
                              {...field}
                              placeholder="Address line 2"
                              id={"address_two"}
                              className={"w-100 input input-field py-2 my-1"}
                              name={"address_two"}
                              value={values?.address_two}
                            />
                          )}
                        </Field>
                        <Field name={"address_three"}>
                          {({ field, meta }) => (
                            <input
                              {...field}
                              placeholder="Address line 3"
                              id={"address_three"}
                              className={"w-100 input input-field py-2 my-1"}
                              name={"address_three"}
                              value={values?.address_three}
                            />
                          )}
                        </Field>
                        {(errors.address_one && (
                          <ErrorMsg name={"address_one"} />
                        )) ||
                          (errors.address_two && (
                            <ErrorMsg name={"address_two"} />
                          )) ||
                          (errors.address_three && (
                            <ErrorMsg name={"address_three"} />
                          ))}
                      </div>
                    </div>

                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"landmark"}
                        label={"Landmark"}
                        type={"text"}
                        placeholder={"Enter landmark"}
                        name={"landmark"}
                        value={values?.landmark}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"pincode"}
                        label={"Pincode"}
                        type={"text"}
                        placeholder={"Enter pincode"}
                        name={"pincode"}
                        value={values?.pincode}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <SuccessPopup
              show={onShowSuccess}
              title={"CFA Edited Successfully"}
            />
            <ErrorPopup
              show={showErrorPopup}
              title={showErrorMsg}
              handleClose={closeSuccessPopup}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CfaEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
