import moment from "moment";
import React from "react";

const AnalyticsTable = ({ graphData }) => {
  const getStatusColor = (status) => {
    if (status === "pending") {
      return "Medium_Purple";
    }
    return orderStatus?.find((e) => e?.status === status)?.color;
  };
  return (
    <>
      <div className="analytics-main-list my-2 py-1 d-flex justify-content-between flex-column">
        <div className="analytics-container text-nowrap">
          <table className="main-table">
            <thead>
              <tr className="table-rows">
                <th className="table-heads" width={"5%"}>
                  #
                </th>
                <th className="table-heads" width={"12%"}>
                  Order ID
                </th>
                <th className="table-heads" width={"18%"}>
                  Customer name
                </th>
                <th className="table-heads" width={"15%"}>
                  Order Date
                </th>
                <th className="table-heads" width={"15%"}>
                  Order Value
                </th>
                <th className="table-heads-action" width={"15%"}>
                  Order Status
                </th>
              </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
              {graphData?.completedOrders.length > 0 ? (
                graphData?.completedOrders?.map((tableData, index) => (
                  <tr className="table-rows1">
                    <td className="table-each-row-data">{index + 1}</td>
                    <td className="table-each-row-data">
                      {tableData?.order_id ? tableData?.order_id : "--"}
                    </td>
                    <td className="table-each-row-data text-capitalize">
                      {tableData?.orderHeirarchy?.user_type == "doctor"
                        ? tableData?.doctor?.name
                        : tableData?.pharmacy?.pharmacy_name}
                    </td>
                    <td className="table-each-row-data">
                      {tableData?.createdAt
                        ? moment(tableData?.createdAt).format(
                          "DD MMM YYYY, HH:mm A"
                        )
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {tableData?.total ? `₹ ${tableData?.total}` : "-"}
                    </td>
                    <td className="table-each-row-data table-each-row-data-color-green text-capitalize">
                      <span className={`${getStatusColor(tableData?.status)}`}>
                        {
                          orderStatus?.find(
                            (e) => e?.status === tableData?.status
                          )?.value
                        }
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="1"
                    className="text-center no-data-container fs_16"
                  >
                    <span>~ No Data Found ~</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AnalyticsTable;

export const orderStatus = [
  { status: "acknowledged", value: "Acknowledged", color: "ack_status " },
  {
    status: "partially_acknowledged",
    value: "Partially Acknowledged",
    color: "color_2ad3e7 ",
  },
  { status: "fully_invoiced", value: "Fully Invoiced", color: "text_green" },
  {
    status: "partially_invoiced",
    value: "Partial Invoiced",
    color: "Medium_Purple",
  },
  { status: "pending", value: "Approval Pending", color: "Medium_Purple" },
  { status: "expired", value: "Expired", color: "text-danger" },
  { status: "cancelled", value: "Cancelled", color: "text-danger" },
  { status: "approval_failed", value: "Approval Failed", color: "text-danger" },
  { status: "approved", value: "Approved", color: "color_2ad3e7" },
  { status: "rejected", value: "Rejected", color: "text-danger" },
  { status: "hold", value: "Under Process", color: "text-warning" },
];
