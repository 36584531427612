import React, { useEffect, useState } from "react";
import TableContainer from "../../../components/layout/table-container/table-container";
import DistributorListTableData from "../distributor-list-table-data";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import { useDispatch } from "react-redux";
import {
  distributorList,
  onboardingDistributorList,
} from "../../../redux/actions/distributorActions";
import TablePagination from "../../../components/Pagination/tablePagination";
import { Spinner } from "react-bootstrap";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import OnboardingDistributorListTableData from "./onboarding-distributor-list-table-data";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const OnboardingDistributorListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [distributorListData, setDistributorData] = useState([]);
  const [active, setActive] = useState("");
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(
        onboardingDistributorList({ search, page, status }, onSuccess, onError)
      );
      setLoading(true);
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      dispatch(
        onboardingDistributorList({ search, page, status }, onSuccess, onError)
      );
      setLoading(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      onboardingDistributorList({ search, page, status }, onSuccess, onError)
    );
    setLoading(true);
  }, [page, status]);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setDistributorData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const distributorListFunc = () => {
    dispatch(onboardingDistributorList({ search, page }, onSuccess, onError));
  };

  return (
    <>
      <TableContainer>
        <table>
          <thead>
            <tr className="table-rows color_747f9e">
              <th className="table-heads " width={"4%"}>
                #
              </th>
              <th className="table-heads " width={"10%"}>
                Distributor ID
              </th>
              <th className="table-heads " width={"8%"}>
                Distributor Name
              </th>

              <th className="table-heads" width={"10%"}>
                Manufacturer Name
              </th>
              <th className="table-heads " width={"10%"}>
                Email Status
              </th>
              <th className="table-heads text-nowrap" width={"8%"}>
                <span onClick={() => setOpenStatus(!openStatus)}>
                  Status
                  {openStatus ? (
                    <img src={UpArrow} className="mb-1 mx-1" />
                  ) : (
                    <img src={DownArrow} className=" mx-1" />
                  )}
                </span>
                {openStatus ? (
                  <div className=" position-relative">
                    <div className="position-absolute status-container d-flex gap-3 px-3">
                      <div className="d-flex align-items-center ">
                        <input
                          className="radio me-2"
                          type="radio"
                          id="State"
                          name="status"
                          value={true}
                          checked={status == "true"}
                          onClick={(e) => {
                            setOpenStatus(!openStatus);
                            setStatus(e.target.value);
                            setActive("active");
                          }}
                        />
                        <span
                          Htmlfor="State"
                          className={
                            active === "active"
                              ? "color_262c3d"
                              : "color_747f9e"
                          }
                        >
                          Active
                        </span>
                      </div>
                      <div className="d-flex align-items-center ">
                        <input
                          className="radio me-2"
                          type="radio"
                          id="State"
                          name="status"
                          value={false}
                          checked={status == "false"}
                          onClick={(e) => {
                            setOpenStatus(!openStatus);
                            setStatus(e.target.value);
                            setActive("inactive");
                          }}
                        />
                        <span
                          for="State"
                          className={
                            active === "inactive"
                              ? "color_262c3d"
                              : "color_747f9e"
                          }
                        >
                          Inactive
                        </span>
                      </div>
                      <button
                        className="filter-btn px-2 py-0 my-2 fs_12"
                        style={{ height: "28px" }}
                        onClick={() => {
                          setStatus(null);
                          setOpenStatus(!openStatus);
                        }}
                      >
                        CLEAR
                      </button>
                    </div>
                  </div>
                ) : null}
              </th>
              <th className="table-heads " width={"22%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ position: "relative" }}>
            {loading == true ? (
              <div className="list-spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : distributorListData?.distributors?.length > 0 ? (
              <>
                {distributorListData?.distributors?.map((eachRow, index) => (
                  <OnboardingDistributorListTableData
                    key={eachRow.id}
                    data={eachRow}
                    index={index}
                    distributorListFunc={distributorListFunc}
                    currentPage={distributorListData?.pagination?.currentPage}
                  />
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="1" className="text-center no-data-container fs_16">
                  <span>~ No Data Found ~</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContainer>
      {/* {distributorListData?.pagination?.totalPages > 0 && ( */}
      <div className={loading == true ? `loading-event` : ""}>
        <TablePagination
          data={distributorListData?.pagination}
          nextFunc={() => {
            navigate(
              `/distributors/onboard-distributor/${distributorListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/distributors/onboard-distributor/${distributorListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page - 1);
          }}
        />
      </div>
      {/* )} */}
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default OnboardingDistributorListTable;
