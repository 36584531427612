import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { gevProductView } from "../../../redux/actions/godsEyeViewActions";
import { useDispatch } from "react-redux";
import ProductDivisionBasicView from "../../../components/division/product/productDivisionBasicView";
import ProductDivisionOffers from "./productDivisionOffers";
import { Spinner } from "react-bootstrap";

const ProductDivisionView = ({ setActiveProduct, selectedProductId }) => {
  const dispatch = useDispatch();
  const [productData, setProductData] = useState("");
  const [offerType, setOfferType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(gevProductView(selectedProductId, onffSuccess, onffError));
  }, []);

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data);
    setProductData(data?.data?.data);
    setOfferType(data?.data?.data?.vendorDivision?.offer_type);
  };

  const onffError = (err) => {
    console.log(err);
    setLoading(false);
  };
  return (
    <>
      <div
        className="main-list-container my-2 py-1 d-flex justify-content-between flex-column"
        style={{ position: "relative" }}
      >
        <main className="d-flex flex-column w-100 main-container">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => setActiveProduct(11)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
          <div className="search-container d-flex justify-content-between">
            {" "}
          </div>
          {loading == true ? (
            <div className="product-view-spinner">
              <Spinner />
            </div>
          ) : (
            <div >
              <ProductDivisionBasicView productData={productData} />
              <ProductDivisionOffers
                offerType={offerType}
                offersList={productData?.productStateOffers}
                holding_value_unit={
                  productData?.productPackingDetail?.holding_value_unit
                }
              />
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default ProductDivisionView;
