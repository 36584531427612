import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../../form/errorMsg";
import "./reportIssueModal.css";
import { useDispatch } from "react-redux";
import { verifyReportModal } from "../../../redux/actions/customerVerificationActions";
import ReportIssueSuccessModal from "./reportIssueSuccessModal";
import { useNavigate } from "react-router-dom";

const ReportIssueModal = ({
  show,
  handleClose,
  customerViewData,
  typedata,
}) => {
  const navigate = useNavigate();
  const [radioActive, setradioActive] = useState("doctor");
  const [successModal, setSuccessModal] = useState(false);
  const [mailData, setMailData] = useState("");
  const dispatch = useDispatch();

  console.log(customerViewData);
  console.log(typedata);

  useEffect(() => {
    if (show == false) {
      setradioActive("doctor");
    }
  }, [show, radioActive]);

  const initialValues = {
    user_type: typedata.user_type,
    issue_user_type:
      typedata.user_type == "doctor"
        ? customerViewData?.pharmacy_type == "dispensing"
          ? ""
          : "doctor"
        : typedata.user_type == "pharmacy" &&
          customerViewData?.pharmacy_type == "attach"
        ? "doctor"
        : "",
    pharmacy_type: customerViewData?.pharmacy_type,
    doctor_id:
      typedata.user_type == "doctor"
        ? typedata?.doctor_id
        : typedata.user_type == "pharmacy" &&
          customerViewData?.pharmacy_type == "attach"
        ? customerViewData?.pharmacyDoctors[0]?.doctor_id
        : "",
    pharmacy_id: typedata.user_type == "pharmacy" && typedata?.pharmacy_id,
    issues: [],
  };

  const validationSchema = Yup.object().shape({
    issue_user_type: Yup.string().when("pharmacy_type", {
      is: (pharmacyType) => pharmacyType == "attach" || pharmacyType == "self",
      then: Yup.string().required("Required"),
    }),
    issues: Yup.array()
      .min(1, "At least one should be selected")
      .required("Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    dispatch(verifyReportModal(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    console.log(data);
    handleClose();
    setSuccessModal(true);
    setMailData(data.data.data);
    setTimeout(timeOutFnc, 3000);
  };

  const timeOutFnc = () => {
    setSuccessModal(false);
    navigate("/support/customer-verification/1");
  };

  const onError = (err) => {
    console.log(err.data);
  };
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        contentClassName="report-verify-modal"
      >
        <div className="m-4">
          <ModalBody>
            <div>
              <label className="fn_Nunito fs_20 fw_700 w-100">
                Report an issue
              </label>
              <label className="color_747f9e fn_Nunito fs_16">
                Pharmacy Type
                <label className="color_2ad3e7 ms-2 fn_Nunito fs_16 fw_600 type-name">
                  {customerViewData?.pharmacy_type}
                </label>
              </label>
            </div>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    {typedata.user_type == "doctor" &&
                      customerViewData?.pharmacy_type != "dispensing" && (
                        <div className="my-2 mt-4">
                          <label className="fn_Nunito fs_16 fw_400">
                            Select User Type
                          </label>
                          <div className="d-flex gap-4 fn_Nunito">
                            <div className="d-flex align-items-center mt-2">
                              <Field
                                type="radio"
                                value="doctor"
                                name="issue_user_type"
                                className="me-2 radio"
                                checked={radioActive == "doctor"}
                                onClick={() => {
                                  setradioActive("doctor");
                                  setFieldValue("issues", []);
                                }}
                              />
                              <label
                                className={
                                  radioActive == "doctor"
                                    ? "color_262c3d"
                                    : "color_747f9e"
                                }
                              >
                                Doctor
                              </label>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                              <Field
                                type="radio"
                                value="pharmacy"
                                name="issue_user_type"
                                className="me-2 radio"
                                checked={radioActive == "pharmacy"}
                                onClick={() => {
                                  setradioActive("pharmacy");
                                  setFieldValue("issues", []);
                                }}
                              />
                              <label
                                className={
                                  radioActive == "pharmacy"
                                    ? "color_262c3d"
                                    : "color_747f9e"
                                }
                              >
                                Pharmacy
                              </label>
                            </div>
                          </div>
                          <ErrorMsg name="issue_user_type" />
                        </div>
                      )}
                    {typedata.user_type == "pharmacy" &&
                      customerViewData?.pharmacy_type == "attach" && (
                        <div className="my-2 mt-4">
                          <label className="fn_Nunito fs_16 fw_400">
                            Select User Type
                          </label>
                          <div className="d-flex gap-4 fn_Nunito">
                            <div className="d-flex align-items-center mt-2">
                              <Field
                                type="radio"
                                value="doctor"
                                name="issue_user_type"
                                className="me-2 radio"
                                checked={radioActive == "doctor"}
                                onClick={() => {
                                  setradioActive("doctor");
                                  setFieldValue("issues", []);
                                }}
                              />
                              <label
                                className={
                                  radioActive == "doctor"
                                    ? "color_262c3d"
                                    : "color_747f9e"
                                }
                              >
                                Doctor
                              </label>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                              <Field
                                type="radio"
                                value="pharmacy"
                                name="issue_user_type"
                                className="me-2 radio"
                                checked={radioActive == "pharmacy"}
                                onClick={() => {
                                  setradioActive("pharmacy");
                                  setFieldValue("issues", []);
                                }}
                              />
                              <label
                                className={
                                  radioActive == "pharmacy"
                                    ? "color_262c3d"
                                    : "color_747f9e"
                                }
                              >
                                Pharmacy
                              </label>
                            </div>
                          </div>
                          <ErrorMsg name="issue_user_type" />
                        </div>
                      )}

                    {radioActive == "doctor" && (
                      <>
                        <div className="fn_Nunito mb-3">
                          <span className="fs_16 fw_400">Issues</span>

                          <>
                            {doctorIssueData?.map((ele, index) => (
                              <div
                                className="d-flex my-1  align-items-center justify-content-start"
                                key={index}
                              >
                                <div>
                                  <span className="fs_14 my-1">
                                    <Field
                                      type={"checkbox"}
                                      className="CheckBox"
                                      value={ele?.value}
                                      name="issues"
                                    />
                                  </span>
                                </div>
                                <div className="">
                                  <label className="fs_16 mx-2 mt-2">
                                    {ele?.label}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                          <ErrorMsg name="issues" />
                        </div>
                      </>
                    )}
                    {radioActive == "pharmacy" && (
                      <>
                        <div className="fn_Nunito mb-3">
                          <span className="fs_16 fw_400">Issues</span>

                          <>
                            {pharmacyIssueData?.map((ele, index) => (
                              <div
                                className="d-flex my-1  align-items-center justify-content-start"
                                key={index}
                              >
                                <div>
                                  <span className="fs_14 my-1">
                                    <Field
                                      type={"checkbox"}
                                      className="CheckBox"
                                      value={ele?.value}
                                      name="issues"
                                    />
                                  </span>
                                </div>
                                <div className="">
                                  <label className="fs_16 mx-2 mt-2">
                                    {ele?.label}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                          <ErrorMsg name="issues" />
                        </div>
                      </>
                    )}
                    <div className=" d-flex m-1">
                      <AdminButton
                        width="266px"
                        height="52px"
                        type="button"
                        color="#FF6F5B"
                        border="1px solid #FF6F5B"
                        onClick={() => handleClose()}
                      >
                        <span className="m-2">CANCEL</span>
                      </AdminButton>
                      <AdminButton
                        width="266px"
                        height="52px"
                        type="submit"
                        background="#7065E4"
                        border-radius="5px"
                        color="white"
                        // onClick={() => ShowClose()}
                      >
                        SEND MAIL
                      </AdminButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <ReportIssueSuccessModal
        mailData={mailData}
        show={successModal}
        handleClose={() => setSuccessModal(false)}
      />
    </>
  );
};

export default ReportIssueModal;

const doctorIssueData = [
  {
    label: "MCI certificate not matching",
    value: "MCI certificate not matching",
  },
  {
    label: "Reupload photos",
    value: "Reupload photos",
  },
  {
    label: "Doctor name not matching",
    value: "Doctor name not matching",
  },
  {
    label: "Address not matching",
    value: "Address not matching",
  },
  {
    label: "Distributors",
    value: "Distributors",
  },
];
const pharmacyIssueData = [
  {
    label: "Drug license number not matching",
    value: "Drug license number not matching",
  },
  {
    label: "GST number not matching",
    value: "GST number not matching",
  },
  {
    label: "Name not matching",
    value: "Name not matching",
  },
  {
    label: "Address not matching",
    value: "Address not matching",
  },
  {
    label: "Reupload photo",
    value: "Reupload photo",
  },
];
