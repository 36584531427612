import React, { useState } from "react";
import VersionHistoryBtn from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import searcher from "../../assets/icons/searchInput.svg";
import CreateBtn from "../../components/buttons/createBtn";
import SuperUserListTable from "./super-user-list-table";

const SuperUserList = () => {
  const [search, setSearch] = useState(null);
  return (
    <MainLayout breadCrumb="ADMIN / Super User">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 text-dark fw_400 px-2"
              type="text"
              placeholder="Search Super User here by Employee ID Name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className=" d-flex justify-content-between m-1 mb-2">
            <VersionHistoryBtn link="/super-user/user/version-list/1" />
            <CreateBtn link="/super-user/user/create" />
          </div>
        </div>
        <SuperUserListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default SuperUserList;
