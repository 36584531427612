import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import verifyIcon from "../../../assets/icons/verified.svg";
import "../modal.css";
import { useDispatch } from "react-redux";

const VerificationSuccessModal = ({ show, handleClose, data }) => {
  const dispatch = useDispatch();
  console.log(data);

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName=""
      contentClassName="link-modal col-5 mx-auto p-2 fn_Nunito"
    >
      <ModalBody>
        <div className="my-3 mt-4 text-center">
          <p className="fn_Nunito fw_700 fs_18 my-2 px-0">
            <img src={verifyIcon} alt="icon" /> {data?.invoice_id}
            <span className="fw_600 ms-1">Verified Successfully</span>
          </p>

          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {data?.invoice_id}
          </p>
          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {data?.orderHeirarchy?.user_type == "doctor"
              ? data?.doctor?.name
              : ""}
          </p>
          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {data?.distributor?.firm_name}
          </p>
          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {data?.vendor?.name}
          </p>
        </div>

        {/* <div className="d-flex justify-content-center align-items-center my-4">
          <button
            type="submit"
            className="modal-cancel-btn fs_14 text-white py-2 px-4 fw_700"
            style={{ width: "80%" }}
          >
            Generate Purchase Order
          </button>
        </div> */}
      </ModalBody>
    </Modal>
  );
};

export default VerificationSuccessModal;
