import React from "react";
import { useNavigate } from "react-router";
import AddIcon from "../../assets/icons/addIcon";
import { AdminButton } from "./versionHistoryBtn";

const CreateBtn = ({ link = "/" }) => {
  const navigate = useNavigate();
  return (
    <AdminButton
      background="#7065e4"
      color="white"
      onClick={() => navigate(link)}
    >
      <AddIcon />
      <span className="m-2">CREATE</span>
    </AdminButton>
  ); 
};

export default CreateBtn;
