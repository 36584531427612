import React, { useEffect, useState } from "react";
import DoctorView from "../../../components/customer-vew/doctor-view";
import PharmacyView from "../../../components/customer-vew/pharmacy-view";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { customerVerifyDetail } from "../../../redux/actions/customerVerificationActions";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import back from "../../../assets/icons/back.svg";
import { Spinner } from "react-bootstrap";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import ReportIssueModal from "../../../components/modals/support/reportIssueModal";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const CustomerVerificationView = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerViewData, setCustomerViewData] = useState([]);
  const [customerTypeData, setCustomerTypeData] = useState([]);
  const [customerDoctorData, setCustomerDoctordata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  useEffect(() => {
    fetchCustomerView();
  }, []);

  const fetchCustomerView = () => {
    console.log(state);
    dispatch(customerVerifyDetail(state, onSuccess, onError));
    setCustomerTypeData(state);
    setLoading(true);
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setCustomerViewData(data.data.data);
    setCustomerDoctordata(data.data.data.pharmacyDoctors);
    console.log(data.data.data.pharmacyDoctors.pharmacy_id, "fgd");
  };
  const onError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (customerViewData?.code
          ? ` / ${customerViewData?.code} - view`
          : " / view")}
    </span>
  );

  return (
    <>
      <MainLayout breadcrumbData={Breadcrumb}>
        <div className="search-container d-flex justify-content-between ">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => navigate(-1)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            <AdminButton
              background="#7065E4"
              color="white"
              width="170px"
              type="button"
              onClick={() => {
                setShowReportModal(true);
              }}
            >
              <span className="mx-2">REPORT AN ISSUE</span>
            </AdminButton>
          </div>
        </div>

        {loading == true ? (
          <div className="view-spinner-container">
            <LoadingSpinnerTable />
          </div>
        ) : (
          <>
            {customerTypeData?.user_type === "doctor" ? (
              <>
                <DoctorView customerViewData={customerViewData} />
              </>
            ) : (
              <PharmacyView customerViewData={customerViewData} />
            )}
          </>
        )}
      </MainLayout>
      <ReportIssueModal
        typedata={state}
        customerViewData={customerViewData}
        show={showReportModal}
        handleClose={() => {
          setShowReportModal(false);
        }}
      />
    </>
  );
};

export default CustomerVerificationView;
