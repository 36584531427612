import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  geFFReportList,
  gevFieldForceList,
} from "../../../../../redux/actions/godsEyeViewActions";
import TablePagination from "../../../../../components/Pagination/tablePagination";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import LoadingSpinner from "../../../../../components/utilites/loaderSpinningContainer";
import searcher from "../../../../../assets/icons/searchInput.svg";
import ErrorPopup from "../../../../../components/popups/ErrorPopup";
import exportFromJSON from "export-from-json";

const FFUserList = ({ activeFF, setActiveFF, setSelectedFfuser }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [ffUserData, setFfUserData] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [search, setSearch] = useState("");
  const vendor_id = params?.id.split("&")?.[0];
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  const activePath = params?.id.split("&")?.[1];
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    gevFFList();
  }, [page]);

  useEffect(() => {
    setPage(1);
    gevFFList();
  }, [search]);

  const gevFFList = () => {
    setLoading(true);
    if (activePath == "fieldForce") {
      dispatch(
        gevFieldForceList({ vendor_id, page, search }, onffSuccess, onffError)
      );
    }
  };

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data);
    setFfUserData(data?.data?.data);
  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };
  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(geFFReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };
  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "User ID": data?.user_id ? data?.user_id : "--",
          "Feild Force Code": data?.code ? data?.code : "--",
          "Company Employee ID": data?.employee_id ? data?.employee_id : "--",
          "Employee Name": data?.name ? data?.name : "--",
          "Email": data?.email ? data?.email : "--",
          "Mobile": data?.mobile ? data?.mobile : "--",
          Designation: data?.fieldForceDesignation?.name
            ? data?.fieldForceDesignation?.name
            : "--",
          "Designation Approval Level": data?.fieldForceDesignation
            ?.approval_level
            ? data?.fieldForceDesignation?.approval_level
            : "--",
          "Head Quarters": data?.fieldForceUserHeadQuaters?.map((ele) =>
            ele?.headQuater?.name ? ele?.headQuater?.name : "--"
          ),
          Division: data?.fieldForceDivisions?.map((ele) =>
          ele?.vendorDivision?.name ? ele?.vendorDivision?.name : "--"
        ),
        "Reporting Manager Name": data?.reportingManager?.name ? data?.reportingManager?.name : "--",
        "Reporting Manager Mobile": data?.reportingManager?.mobile ? data?.reportingManager?.mobile : "--",
        "status": data?.status ? data?.status : "--",
 
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setShowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };



  return (
    <div className=" mx-5 px- my-3 mt-4">
      <div className="d-flex justify-content-between">
        <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
          <img className="styled-SVG" src={searcher} color={""} />
          <input
            className="search-input fs_14 fw_400 text-dark px-2"
            type="text"
            placeholder="Search user here by user name , user id , employee Id , designation ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          <button
            className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
            type="button"
            onClick={() => exportTableCsv()}
          >
            download
          </button>
        </div>
      </div>
      <div
        className="manufacturer-product-table-container"
        style={{ height: "33.5rem" }}
      >
        <div style={{ height: "30rem" }}>
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads py-2" width={"2%"}>
                  #
                </th>
                <th className="table-heads" width={"8%"}>
                  User ID
                </th>
                <th className="table-heads" width={"8%"}>
                  Feild Force Code
                </th>
                <th className="table-heads" width={"6%"}>
                  Company Employee ID
                </th>
                <th className="table-heads" width={"6%"}>
                  Employee Name
                </th>
                <th className="table-heads" width={"6%"}>
                  Designation
                </th>
                <th className="table-heads text-nowrap" width={"10%"}>
                  Designation Approval level
                </th>
                <th className="table-heads" width={"6%"}>
                  Head Quarters
                </th>

                <th className="table-heads" width={"8%"}>
                  Division
                </th>

                <th className="table-heads" width={"12%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="gev-spinner-container">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {ffUserData?.fieldForceUsers?.length > 0 ? (
                    ffUserData?.fieldForceUsers?.map((item, index) => {
                      const renderHqTooltip = (props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {item?.fieldForceUserHeadQuaters?.map((ele, index) => (
                            <span key={index}>
                              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                              {ele?.headQuater?.name ? ele?.headQuater?.name : "--"}
                            </span>
                          ))}
                        </Tooltip>
                      );

                      const renderDivTooltip = (props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {item?.fieldForceDivisions?.map((ele, index) => (
                            <span key={index}>
                              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                              {ele?.vendorDivision?.name ? ele?.vendorDivision?.name : "--"}
                            </span>
                          ))}
                        </Tooltip>
                      );

                      return (
                        <tr className="table-rows1 color_262c3d" key={index}>
                          <td className="table-each-row-data">
                            {(ffUserData?.pagination?.currentPage - 1) * 10 +
                              (index + 1)}
                          </td>
                          <td className="table-each-row-data">{item?.user_id}</td>
                          <td className="table-each-row-data">{item?.code}</td>
                          <td className="table-each-row-data">
                            {item?.employee_id}
                          </td>
                          <td className="table-each-row-data text-capitalize">
                            {item?.name}
                          </td>
                          <td className="table-each-row-data text-capitalize">
                            {item?.fieldForceDesignation?.name}
                          </td>
                          <td className="table-each-row-data  text-center text-capitalize">
                            {item?.fieldForceDesignation?.approval_level}
                          </td>
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderHqTooltip}
                          >
                            <td className="table-each-row-data text-capitalize text-truncate" style={{ maxWidth: "200px" }}>
                              {item?.fieldForceUserHeadQuaters?.length > 0
                                ? item?.fieldForceUserHeadQuaters?.map(
                                  (ele, index) => {
                                    return (
                                      <span className="slice-word" key={index}>
                                        <span style={{ color: "#B0B3C7" }}>
                                          {index ? " | " : ""}
                                        </span>
                                        {ele?.headQuater?.name
                                          ? ele?.headQuater?.name
                                          : "--"}
                                      </span>
                                    );
                                  }
                                )
                                : "--"}
                            </td>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderDivTooltip}
                          >
                            <td className="table-each-row-data text-truncate" style={{ maxWidth: "200px" }}>
                              {item?.fieldForceDivisions?.length > 0
                                ? item?.fieldForceDivisions?.map(
                                  (ele, index) => {
                                    return (
                                      <span className="slice-word" key={index}>
                                        <span style={{ color: "#B0B3C7" }}>
                                          {index ? " | " : ""}
                                        </span>
                                        {ele?.vendorDivision?.name
                                          ? ele?.vendorDivision?.name
                                          : "--"}
                                      </span>
                                    );
                                  }
                                )
                                : item?.vendorDivision ? item?.vendorDivision?.name : "--"}
                            </td>
                          </OverlayTrigger>
                          <td className="table-each-row-data">
                            <div className="all-button d-flex align-items-center">
                              <button
                                className="button-view"
                                type="button"
                                onClick={() => {
                                  navigate(
                                    `/manufacturer/${pathType}/view/${vendor_id}&fieldForce&view&${item?.id}`
                                  );
                                }}
                              >
                                <span className=""> View</span>
                              </button>
                              <>
                                {(user_type == "super_admin" &&
                                  userData?.write) ||
                                  user_type == "admin" ? (
                                  <>
                                    <span className="vr vr_line mt-1"></span>
                                    <button
                                      className="button-edit1"
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/manufacturer/${pathType}/view/${vendor_id}&fieldForce&edit&${item?.id}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center no-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {ffUserData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={ffUserData?.pagination}
          nextFunc={() => {
            setPage(ffUserData?.pagination?.currentPage + 1);
          }}
          prevFunc={() => {
            setPage(ffUserData?.pagination?.currentPage - 1);
          }}
        />
      )}
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </div>
  );
};

export default FFUserList;