import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import back from "../../assets/icons/back.svg";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import InputField from "../../components/form/inputField/inputField";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import * as Yup from "yup";
import "./faq.css";
import ErrorMsg from "../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import { faqDetail, faqEdit } from "../../redux/actions/faqActions";
import ReactQuill from "react-quill";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const FaqEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [faqData, setFaqData] = useState("");
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("currentPage");

  const stateObej = {
    page :  currentPage
  }

  
  console.log(stateObej, "page");

  useEffect(() => {
    setLoading(true);
    dispatch(faqDetail(params.id, fetchFaqdetailSuccess, fetchFaqdetailError));
  }, []);

  const fetchFaqdetailSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setFaqData(data.data.data);
  };

  useEffect(() => {
    console.log(faqData?.faqUserTypes);
  }, [faqData]);

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const fetchFaqdetailError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const initialValues = {
    faq_id: faqData?.id,
    question: faqData?.question,
    answer: faqData?.answer,
    user_type: faqData?.faqUserTypes?.map((ele) => ele?.user_type),
  };
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Required"),
    answer: Yup.string().required("Required"),
    user_type: Yup.array().required("Required"),
  });
  const resetFunc = (setFieldValue) => {
    setFieldValue("question", faqData?.question);
    setFieldValue("createdFor", []);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ align: "" }],
    ],
  };
  const onSubmit = (values) => {
    console.log(values);
    dispatch(faqEdit(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate("/faq");
  };

  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };
  const onError = (err) => {
    console.log(err);
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        (faqData?.faq_id ? ` / ${faqData?.faq_id} - edit` : " / edit")}
    </span>
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate("/faq" , { state:  { page :  currentPage} })}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="view-spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : (
              <div className="faq-form-width ms-4 ps-3 py-1">
                <div className="my-2">
                  <label className=" Neutral_Color fs_14">FAQ ID</label>
                  <p className="fs_14">{faqData?.faq_id}</p>
                </div>
                <div className="my-2 mt-4 ">
                  <InputField
                    id={"question"}
                    value={values?.question}
                    label={"FAQ Questions"}
                    labelColor={"Neutral_Color"}
                    type={"text"}
                    placeholder={"Enter FAQ questions"}
                    name={"question"}
                  />
                </div>
                <div className="my-2 mb-4">
                  <label className="fs_14 Neutral_Color fw_400">
                    FAQ Answer
                  </label>
                  <div className="faq-answer-container">
                    <Field name="answer">
                      {({ field }) => (
                        <ReactQuill
                          value={field.value}
                          onChange={field.onChange(field.name)}
                          modules={modules}
                          style={{ maxHeight: "196px", height: "60vh" }}
                          className={"quill"}
                          placeholder={"Write FAQ answers"}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMsg name={"answer"} />
                </div>
                <div className="my-3">
                  <label className="fs_14 Neutral_Color fw_400">
                    Created for
                  </label>
                  {createdDummy?.map((ele, index) => (
                    <div
                      className="d-flex my-1 label-container align-items-center justify-content-start"
                      key={index}
                    >
                      <div className=" d-flex align-items-end">
                        <span className="fs_14 my-1">
                          <Field
                            type={"checkbox"}
                            className="CheckBox"
                            value={ele?.value}
                            name="user_type"
                            checked={values?.user_type?.find(
                              (ex) => ex == ele?.value
                            )}
                          />
                        </span>
                        <label className="fs_14 color_262c3d fw_400 ms-2 text-nowrap">
                          {ele.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <ErrorMsg name={"user_type"} />
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <SuccessPopup show={onShowSuccess} title={"FAQ Edited Successfully"} />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default FaqEdit;
const createdDummy = [
  { value: "customer", label: "Customers" },
  { value: "distributor", label: "Distributors" },
  { value: "vendor", label: "Manufactrer" },
  { value: "field_force_user", label: "Field Force" },
];
