import React from "react";
import { useNavigate } from "react-router-dom";
import verificationSentImage from "../../../assets/images/auth/emailVerificationImage1.png";
import "./forgot-reset-password.css";

const PasswordEmailSent = () => {
  const navigate = useNavigate()
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className=" verification-container  flex-column d-flex justify-content-center align-items-center">
        <div className="ms-5">
          <img
            src={verificationSentImage}
            alt="website logo"
            className="verification-image"
          />
        </div>
        <div>
          <div className="mt-4 pt-1 col-12 text-center">
            <span className="fs_30 fs_md_26 color_262c3d fn_Nunito fw_700">
              Check your email inbox
            </span>
          </div>
          <div className="col-10 text-center mx-auto px-sm-5">
            <span className="fs_20 fs_md_18 color_b0b3c7 fn_Nunito fw_500">
              We have sent a password reset link instruction to your email.
            </span>
          </div>
          <div className="w-50 mx-auto">
            <button
              className="verification-button fs_16 text-center mt-4 py-1 px-2"
              type="submit"
              onClick={() => navigate('/')}
            >
              Open EMail App
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordEmailSent;
