import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import verifyIcon from "../../../../assets/icons/verified.svg";
import "../../modal.css";
import { poCsvMail } from "../../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import MailSent from "../../../popups/MailSent";
import SuccessPopup from "../../../popups/SuccessPopup";
import ErrorPopup from "../../../popups/ErrorPopup";

const PurchaseOrderSendMail = ({
  show,
  handleClose,
  data,
  closeModal,
  emails,
  manufacturerViewData,
  purchaseOrderData,
  purchaseOrderMailData
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showmailSent, setShowMailSent] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const mailData = purchaseOrderData?.id;

  const onSubmitGenerate = () => {
    dispatch(
      poCsvMail(
        {
          vendor_id: params?.id.split("&")?.[0],
          purchase_order_id: mailData,
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setShowMailSent(false)
  }

  const timeOutFnc1 = () => {
    setShowSuccessPopup(false);
  }

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc1, 2000);
    setShowMailSent(true)
    setTimeout(timeOutFnc, 2000)
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="send-mail-modal"
        contentClassName="send-mail-modal col-5 mx-auto p-2 fn_Nunito"
      >
        <ModalBody>
          <div className="my-3 mt-4 text-center ">
            <div className="d-flex align-items-center justify-content-center">
              <img src={verifyIcon} alt="icon" className="my-2" />
              <div className="ms-2">
                <span className="fn_Nunito fw_600 fs_22 my-2 px-0">
                  Purchase Order Generated Successfully
                </span>
              </div>
            </div>
            <span className="color_747f9e fs_16">Mail will be sent To :</span>
            <div>
              <span>To :</span>
              <span className="color_747f9e fs_16">
                {manufacturerViewData?.vendor?.email}
              </span>
            </div>
            <div>
              <span>CC :</span>
              <span className="color_747f9e fs_16 ms-1">
                {purchaseOrderMailData?.emails?.map((item, index) => (
                  <span className="" key={index}>
                    <span style={{ color: "#B0B3C7" }}>
                      {index ? " , " : ""}
                    </span>
                    {item}
                  </span>
                ))}
              </span>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 gap-2">
            <button
              type="button"
              className="modal-cancel-btn fs_14 text-white py-2 px-4 mx-3 fw_700"
              style={{ width: "70%" }}
              onClick={() => {
                handleClose()
                onSubmitGenerate();
              }}
            >
              Send Mail
            </button>
          </div>
        </ModalBody>
      </Modal>
      <MailSent
        show={showmailSent}
      />
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default PurchaseOrderSendMail;
