import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import VersionHistoryBtn, {
  AdminButton,
} from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import DeleteModal from "../../../components/modals/deleteModal";
import { settingsPrivacyPolicyView } from "../../../redux/actions/settingsActions";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const PrivacyPolicyView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    dispatch(settingsPrivacyPolicyView(onSuccess, onError));
    setLoading(true);
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setPrivacyPolicyData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };
  return (
    <MainLayout>
      {(user_type == "super_admin" && userData?.write) ||
      user_type == "admin" ? (
        <>
          <div className="search-container d-flex justify-content-end">
            {/* <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div> */}
            <div className=" d-flex justify-content-between m-1 mb-2">
              <AdminButton
                background="#7065E4"
                color="white"
                width="90px"
                onClick={() => navigate(`/settings/privacy-policy/edit`)}
              >
                <span className="m-2">EDIT</span>
              </AdminButton>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="scrolling">
        <div className="mx-4 px-3 mt-3 faq-form-width">
          {loading == true ? (
            <div className="spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : (
            <>
              <div className="my-2">
                <label className="color_747f9e fs_14">
                  Privacy Policy Text
                </label>
                <p
                  className="fs_14"
                  dangerouslySetInnerHTML={{
                    __html: privacyPolicyData?.privacy,
                  }}
                ></p>
              </div>
            </>
          )}
        </div>
      </div>
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default PrivacyPolicyView;
