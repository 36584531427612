import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StateListTable from "../../../components/tables/stateListTable";
import {
  settingsStateCreate,
  settingsStateEdit,
  settingsStateList,
} from "../../../redux/actions/settingsActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import searcher from "../../../assets/icons/searchInput.svg";

const SettingsState = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const [stateListData, setStateListData] = useState("");
  const [stateId, setStateId] = useState("");
  const [page, setPage] = useState(1);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    fetchStateList();
  }, [search]);

  useEffect(() => {
    fetchStateList();
  }, [page, search]);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    if (!editEnable) {
      dispatch(settingsStateCreate(values, onSuccess, onError));
    } else {
      dispatch(
        settingsStateEdit(
          {
            state_id: stateId,
            name: values?.name,
          },
          onEditSuccess,
          onEditError
        )
      );
    }

    resetForm({
      values: {
        name: "",
      },
    });
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchStateList();
  };

  const onSuccess = (data) => {
    console.log(data);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setShowSuccessPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const onEditSuccess = (data) => {
    console.log(data);
    fetchStateList();
    setEditEnable(false);
  };

  const onEditError = (err) => {
    console.log(err);
  };

  const fetchStateList = () => {
    dispatch(
      settingsStateList({ page, search }, onGetStateSuccess, onGetStateError)
    );
  };

  const onGetStateSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setStateListData(data?.data?.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onGetStateError = (err) => {
    console.log(err);
    setLoading(false);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const FormEnableCheck = () => {
    const {
      values: { name },
    } = useFormikContext();
    useEffect(() => {
      if (name.trim() != "") {
        setDisable(false);
      } else setDisable(true);
    }, [name]);
    return null;
  };

  const editSetForm = (item, index, setFieldValue) => {
    console.log(item?.name, "dsfghfdsghfewasfghgfdsasfhg");
    setEditEnable(true);
    setStateId(item?.id);
    setFieldValue("name", item.name);
  };
  return (
    <>
      <MainLayout breadCrumb="ADMIN  /  settings  /  State">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, values }) => (
            <Form>
              <FormEnableCheck />
              {/* <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
              </div> */}
              {loading == true ? (
                <div className="spinner-container">
                  <Spinner show={loading} />
                </div>
              ) : (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">State</label>
                      </div>
                      <div className="form-width ms-4 ps-3 py-1">
                        <div className="my-2">
                          <InputField
                            id={"name"}
                            label={"State"}
                            labelColor={"color_262c3d"}
                            type={"text"}
                            placeholder={"Enter state name"}
                            name={"name"}
                            value={values.name}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-end"
                          style={{ maxWidth: "450px" }}
                        >
                          {!editEnable ? (
                            <button
                              type="submit"
                              className="add-button fs_18 fw_700 fs_sm_12 px-4 py-2 w-50"
                              style={{ maxWidth: "210px" }}
                              disabled={disable}
                            >
                              Create
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                              style={{ maxWidth: "210px" }}
                              // disabled={disable}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                    <label className="basic-title mx-2 mb-1">State List</label>
                  </div>
                  <div className="mt-4 inside-table-container mx-4">
                    <div className=" my-2 search-container ">
                      <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                        <img className="styled-SVG" src={searcher} color={""} />
                        <input
                          className="search-input fs_14 fw_400 px-2 text-dark"
                          type="text"
                          placeholder="Search state here by state name..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="px-1">
                      <StateListTable
                        stateListData={stateListData}
                        setFieldValue={setFieldValue}
                        editSetForm={editSetForm}
                        setPage={setPage}
                        page={page}
                      />
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </MainLayout>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default SettingsState;
