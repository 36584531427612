import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

const PdfView = ({ show, handleClose, filename, pdfUrl }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="image-viewer-container col-11 px-5 mx-auto"
    >
      <ModalHeader closeButton>{filename}</ModalHeader>
      <ModalBody>
        <iframe
          src={`${pdfUrl}#toolbar=1`}
          style={{ width: "100%", height: "45rem" }}
        />
      </ModalBody>
    </Modal>
  );
};

export default PdfView;
