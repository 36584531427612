import React, { useEffect, useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import { Spinner } from "react-bootstrap";

const ProductDivisionList = ({
  setActiveProduct,
  productDivisionData,
  loading,
  setSelectedProductId,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(productDivisionData);
  }, [productDivisionData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value.replace(/ /g, "");
    setSearchInput(value);

    if (trimmedValue === "") {
      setFilteredData(productDivisionData);
    } else {
      const filtered = productDivisionData.filter((data) => {
        const ffNameWithoutSpaces =
          data?.product_id
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase()) ||
          data?.product_name
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase());
        return ffNameWithoutSpaces;
      });
      setFilteredData(filtered);
    }
  };
  return (
    <>
      <div className="main-list-container my-2 py-1 d-flex justify-content-between flex-column">
        <main className="d-flex flex-column w-100 main-container">
          <div className="search-container d-flex justify-content-between ">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 text-dark px-2"
                type="text"
                placeholder="Search products here by Product ID, Company’s Product ID, Product Name, Di..."
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div style={{ height: "32rem", overflow: "auto" }}>
            <table className="main-table  text-nowrap">
              <thead
                style={{ position: "sticky", top: 0, zIndex: 55 }}
                className="bg-white"
              >
                <tr className="table-rows color_747f9e">
                  <th className="table-heads py-2" width={"4%"}>
                    SL. No
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    Product ID
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    Company’s Product ID
                  </th>
                  <th className="table-heads py-2" width={"15%"}>
                    Product Name
                  </th>
                  <th className="table-heads py-2" width={"15%"}>
                    Division Name
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    MRP
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    PTS
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    PTR
                  </th>
                  <th className="table-heads py-2" width={"9%"}>
                    Actions
                  </th>
                </tr>
              </thead>
              {loading == true ? (
                <div className="product-spinner-container">
                  <Spinner show={loading} />
                </div>
              ) : (
                <tbody style={{ position: "relative" }}>
                  {filteredData.length > 0 ? (
                    filteredData.map((data, index) => {
                      return (
                        <tr className="table-rows1 color_262c3d" key={index}>
                          <td className="table-each-row-data">{index + 1}</td>
                          <td className="table-each-row-data">
                            {data?.product_id ? data?.product_id : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.company_product_id
                              ? data?.company_product_id
                              : "--"}
                          </td>
                          <td className="table-each-row-data text-capitalize">
                            {data?.product_name ? data?.product_name : "--"}
                          </td>
                          <td className="table-each-row-data text-capitalize">
                            {data?.vendorDivision?.name
                              ? data?.vendorDivision?.name
                              : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.productStateOffers[0]?.offers?.length > 0 &&
                              data?.productStateOffers[0]?.offers?.map((ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.mrp ? ele?.mrp : "--"}
                                  </span>
                                );
                              })}
                          </td>
                          <td className="table-each-row-data">
                            {data?.productStateOffers[0]?.offers?.length > 0 &&
                              data?.productStateOffers[0]?.offers?.map((ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.ptr ? ele?.ptr : "--"}
                                  </span>
                                );
                              })}
                          </td>
                          <td className="table-each-row-data">
                            {data?.productStateOffers[0]?.offers?.length > 0 &&
                              data?.productStateOffers[0]?.offers?.map((ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.pts ? ele?.pts : "--"}
                                  </span>
                                );
                              })}
                          </td>
                          <td className="table-each-row-data">
                            <button
                              className="button-view"
                              type="button"
                              onClick={() => {
                                setActiveProduct(12);
                                setSelectedProductId(data.id);
                              }}
                            >
                              <span className=""> View</span>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="text-center no-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default ProductDivisionList;
