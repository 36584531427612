import { DASHBOARD_VIEW, GRAPH_CARD } from "./types";

export const dashboardView = (onSuccess, onError) => {
  return {
    type: DASHBOARD_VIEW,
    onSuccess,
    onError,
  };
};

export const graphCard = (params, onSuccess, onError) => {
  return {
    type: GRAPH_CARD,
    params,
    onSuccess,
    onError,
  };
};
