import { Field } from "formik";
import React from "react";
import ErrorMsg from "../errorMsg";

const InputFieldAmount = ({
  label,
  name,
  type,
  value,
  placeholder,
  color = "Neutral_Color",
  labelColor = "Neutral_Color",
  fontWeigth = "fw_400",
}) => {
  return (
    <div className={`${color} d-flex  flex-column mb-2`}>
      <label className={`${fontWeigth} ${labelColor}  fw_400 fs_14 my-1`}>
        {label}
      </label>
      <Field name={name}>
        {({ field, meta }) => (
          <div className="in-field d-flex align-items-center input-field-container">
            <span className="fn_Nunito fs_14 fw_700 Medium_Purple mx-2 ">
              ₹.
            </span>
            <input
              type={type}
              {...field}
              placeholder={placeholder}
              className={`input input-field-border`}
            />
          </div>
        )}
      </Field>
      <ErrorMsg name={name} />
    </div>
  );
};

export default InputFieldAmount;
