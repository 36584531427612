import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../assets/icons/back.svg";
import reupload from "../../assets/icons/reUpload.svg";
import upload from "../../assets/icons/uploadImg.svg";
import uploadDoc from "../../assets/icons/uploadDoc.svg";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ErrorMsg from "../../components/form/errorMsg";
import InputField from "../../components/form/inputField/inputField";
import ImageUploading from "react-images-uploading";
import { Document, Page } from "react-pdf";
import Select from "react-select";
import {
  distributorDetail,
  distributorEdit,
  logoUpload,
  manufacturerList,
  uploadDrugLicence,
  uploadGSTCertificate,
} from "../../redux/actions/distributorActions";
import {
  fetchCityList,
  fetchDistrictList,
  fetchPincodeAutoList,
  fetchStateList,
} from "../../redux/actions/commonActions";
import InputCapitalField from "../../components/form/inputField/inputCapitalField";
import PdfView from "../../components/modals/pdfView";
import eye from "../../assets/icons/viewEye.svg";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import ImageViewModal from "../../components/modals/customer/image-view-modal";
import { pdfChecker } from "../../components/utilites/helper";
import PincodeInputField from "../../components/form/inputField/pincodeInput";

const DistributorEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [adminViewData, setAdminViewData] = useState(null);
  const [logoImg, setLogoImg] = useState(null);
  const [gstimg, setGstImg] = useState(null);
  const [licenceImg, setLicenceImg] = useState(null);
  const [licence2Img, setLicence2Img] = useState(null);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [imageModal, setImageModal] = useState(false);
  const [imageTitleData, setImageTitleData] = useState("");
  const [imageUrlData, setImageUrlData] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  const [intValues, setintValues] = useState();
  const [pincodeSet, setPincodeSet] = useState(null);

  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const GSTregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  const FSSAIregex = /^[0-9]{1}[0-9]{2}[0-9]{2}[0-9]{3}[0-9]{6}$/;

  const logoimageUpdate = (file) => {
    setLoading(true);
    dispatch(
      logoUpload(file.target.files[0], onLogoUploadSuccess, onLogoUploadError)
    );
  };

  const onLogoUploadSuccess = (data) => {
    console.log(data);
    setLogoImg(data.data.data.filePath);
    setLoading(false);
  };
  const onLogoUploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
    setLoading(false);
  };

  const docTimeOutFnc = () => {
    setShowErrorPopup(false);
  };

  const docimageUpdateOne = (file) => {
    console.log(file.target.files[0]);
    dispatch(
      uploadGSTCertificate(
        file.target.files[0],
        ondoc1UploadSuccess,
        ondoc1UploadError
      )
    );
    setLoading(true);
    // setDocOneImages(imageList);
  };

  const ondoc1UploadSuccess = async (data) => {
    console.log(data.data.data);
    setGstImg(data.data.data.filePath);
    setLoading(false);
  };

  const ondoc1UploadError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
    setLoading(false);
  };

  const docimageUpdateTwo = (file) => {
    dispatch(
      uploadDrugLicence(
        file.target.files[0],
        ondoc2UploadSuccess,
        ondoc2UploadError
      )
    );
    setLoading(true);
    // setDocTwoImages(imageList);
  };

  const ondoc2UploadSuccess = (data) => {
    setLicenceImg(data.data.data.filePath);
    setLoading(false);
  };
  const ondoc2UploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
    setLoading(false);
  };
  const docimageUpdateThree = (file) => {
    dispatch(
      uploadDrugLicence(
        file.target.files[0],
        ondoc3UploadSuccess,
        ondoc3UploadError
      )
    );
    setLoading(true);
    // setDocTwoImages(imageList);
  };

  const ondoc3UploadSuccess = (data) => {
    setLicence2Img(data.data.data.filePath);
    setLoading(false);
  };
  const ondoc3UploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(distributorDetail(params?.id, onFetchSuccess, onFetchError));
    dispatch(manufacturerList(onFetchManuSuccess, onFetchManuError));
  }, []);

  const onFetchSuccess = (data) => {
    console.log(data.data.data);
    setLoading(false);
    setSelectedState(data?.data?.data?.distributor?.state_id);
    setSelectedCity(data?.data?.data?.distributor?.city_id);
    setSelectedDistrict(data?.data?.data?.distributor?.district_id);
    setAdminViewData(data?.data?.data?.distributor);
    // setLogoImages([{ data_url: data?.data?.data?.distributor?.logo }]);
    setGstImg(data?.data?.data?.distributor?.gst_certificate);
    setLicenceImg(data?.data?.data?.distributor?.drug_licence_certificate_20_a);
    setLicence2Img(
      data?.data?.data?.distributor?.drug_licence_certificate_20_b
    );
    setLogoImg(
      data?.data?.data?.distributor?.logo
        ? data?.data?.data?.distributor?.logo
        : null
    );
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onFetchError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const onFetchManuSuccess = (data) => {
    console.log(data.data.data);
    let newArr = data?.data?.data?.map((ele) => ({
      label: ele.name,
      value: ele.id,
    }));
    console.log(newArr);
    setManufacturerOptions(newArr);
  };
  const onFetchManuError = (err) => {
    console.log(err);
  };

  const handlePincode = (e, setFieldValue) => {
    console.log(e.target.value, "pin");
    setFieldValue("pincode", e.target.value);
    if (e.target.value.length === 6) {
      setPincodeSet(e.target.value);
    } else {
      setSelectedState(null);
      setSelectedDistrict(null);
      setSelectedCity(null);
    }
  };

  useEffect(() => {
    if (pincodeSet != null) {
      dispatch(
        fetchPincodeAutoList(
          { pincode: pincodeSet },
          onGetPincodeSuccess,
          onGetPincodeError
        )
      );
    }
  }, [pincodeSet]);

  const onGetPincodeSuccess = (data) => {
    console.log(data?.data?.data?.state_id, "pin");
    // setPincodeData(data?.data?.data);
    setSelectedState(data?.data?.data?.state_id);
    setSelectedDistrict(data?.data?.data?.district_id);
    setSelectedCity(data?.data?.data?.city_id);
  };

  console.log(selectedState, "pin");
  const onGetPincodeError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDistrictList(
        { state_id: selectedState },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
  }, [selectedState]);

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict !== null) {
      dispatch(
        fetchCityList(
          { state_id: selectedState, district_id: selectedDistrict },
          onGetCitySuccess,
          onGetCityError
        )
      );
    } else {
      setCityOptions([]);
    }
  }, [selectedState, selectedDistrict]);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log(data);
  };
  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const onGetCitySuccess = (data) => {
    setCityOptions(data);
    console.log(data);
  };
  const onGetCityError = (err) => {
    console.log(err);
  };

  const initialValues = {
    firm_name: intValues?.firm_name
      ? intValues?.firm_name
      : adminViewData?.firm_name,
    logo: logoImg,
    gst_number: intValues?.gst_number
      ? intValues?.gst_number
      : adminViewData?.gst_number,
    fssai_number: intValues?.fssai_number
      ? intValues?.fssai_number
      : adminViewData?.fssai_number,
    email: intValues?.email ? intValues?.email : adminViewData?.email,
    alternate_email: intValues?.alternate_email
      ? intValues?.alternate_email
      : adminViewData?.alternate_email,
    mobile: intValues?.mobile ? intValues?.mobile : adminViewData?.mobile,
    alternate_mobile: intValues?.alternate_mobile
      ? intValues?.alternate_mobile
      : adminViewData?.alternate_mobile,
    drug_licence_number_20_a: intValues?.drug_licence_number_20_a
      ? intValues?.drug_licence_number_20_a
      : adminViewData?.drug_licence_number_20_a,
    drug_licence_number_20_b: intValues?.drug_licence_number_20_b
      ? intValues?.drug_licence_number_20_b
      : adminViewData?.drug_licence_number_20_b,
    drug_licence_certificate_20_a: intValues?.drug_licence_certificate_20_a
      ? intValues?.drug_licence_certificate_20_a
      : adminViewData?.drug_licence_certificate_20_a,
    drug_licence_certificate_20_b: intValues?.drug_licence_certificate_20_b
      ? intValues?.drug_licence_certificate_20_b
      : adminViewData?.drug_licence_certificate_20_b,
    address_one: intValues?.address_one
      ? intValues?.address_one
      : adminViewData?.address?.address_one,
    address_two: intValues?.address_two
      ? intValues?.address_two
      : adminViewData?.address?.address_two,
    address_three: intValues?.address_three
      ? intValues?.address_three
      : adminViewData?.address?.address_three,
    landmark: intValues?.landmark
      ? intValues?.landmark
      : adminViewData?.address?.landmark,

    linked_manufacturers: adminViewData?.vendorDistributors?.map(
      (ele) => ele?.vendor?.id
    ),

    pincode: pincodeSet == null ? adminViewData?.pincode : pincodeSet,
    state_id: pincodeSet == null ? adminViewData?.state_id : selectedState,
    district_id:
      pincodeSet == null ? adminViewData?.district_id : selectedDistrict,
    city_id: pincodeSet == null ? adminViewData?.city_id : selectedCity,
    gst_certificate: intValues?.gst_certificate
      ? intValues?.gst_certificate
      : adminViewData?.gst_certificate,
  };
  const validationSchema = Yup.object().shape({
    firm_name: Yup.string().trim().required("Required"),
    gst_number: Yup.string()
      // .matches(GSTregex, "Invalid")
      .trim()
      .nullable()
      .required("Required"),
    drug_licence_number_20_a: Yup.string()
      .trim()
      .nullable()
      .required("Required"),
    drug_licence_number_20_b: Yup.string()
      .trim()
      .nullable()
      .required("Required"),
    fssai_number: Yup.string()
      .max(14, "Invalid!")
      // .matches(FSSAIregex, "Invalid FSSAI Number")
      .trim()
      .nullable()
      .required("Required"),
    email: Yup.string().email("Not a proper email").trim().required("Required"),
    alternate_email: Yup.string().email("Not a proper email").trim(),
    mobile: Yup.string()
      .trim()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    alternate_mobile: Yup.string()
      .trim()
      .nullable()
      .matches(phoneRegex, "Not a valid Number"),
    address_one: Yup.string().trim().required("Required"),
    address_two: Yup.string().trim().required("Required"),
    landmark: Yup.string().trim().required("Required"),
    pincode: Yup.string()
      .trim()
      .matches(pincoderegex, "Not a valid Number")
      .nullable()
      .required("Required"),
    state_id: Yup.string().trim().required("Required"),
    district_id: Yup.string().trim().required("Required"),
    city_id: Yup.string().trim().required("Required"),
  });
  const resetFunc = (setFieldValue) => {
    setFieldValue("firm_name", adminViewData?.firm_name);
    setFieldValue("gst_number", adminViewData?.gst_number);
    setFieldValue(
      "drug_licence_number_20_a",
      adminViewData?.drug_licence_number_20_a
    );
    setFieldValue(
      "drug_licence_number_20_b",
      adminViewData?.drug_licence_number_20_b
    );
    setFieldValue("fssai_number", adminViewData?.fssai_number);
    setFieldValue("mobile", adminViewData?.mobile);
    setFieldValue("email", adminViewData?.email);
    setFieldValue("alternate_email", adminViewData?.alternate_email);
    setFieldValue("alternate_mobile", adminViewData?.alternate_mobile);
    setFieldValue("address_one", adminViewData?.address?.address_one);
    setFieldValue("address_two", adminViewData?.address?.address_two);
    setFieldValue("address_three", adminViewData?.address?.address_three);
    setFieldValue("landmark", adminViewData?.address?.landmark);
    setFieldValue("state_id", adminViewData?.state_id);
    setFieldValue("district_id", adminViewData?.district_id);
    setFieldValue("city_id", adminViewData?.city_id);
    setFieldValue("pincode", adminViewData?.pincode);
    setGstImg(adminViewData?.gst_certificate);
    setLicenceImg(adminViewData?.drug_licence_certificate_20_a);
    setLicence2Img(adminViewData?.drug_licence_certificate_20_b);
    setLogoImg(adminViewData?.logo ? adminViewData?.logo : null);
  };

  const onSubmit = (values) => {
    console.log(values);
    values["logo"] = logoImg;
    values["gst_certificate"] = gstimg;
    values["drug_licence_certificate_20_a"] = licenceImg;
    values["drug_licence_certificate_20_b"] = licence2Img;
    values["distributor_id"] = params?.id;
    values["linked_manufacturers"] = [];
    values["address"] = {
      address_one: values.address_one,
      address_two: values.address_two,
      address_three: values.address_three,
      landmark: values.landmark,
    };
    dispatch(distributorEdit(values, onSubmitSuccess, onSubmitError));
  };

  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate("/distributors/distributor/1");
  };

  const onSubmitSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };
  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onSubmitError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const handleChange = (file) => {
    console.log(file.target.files[0]);
    // dispatch(logoUpload(file.target.files[0],onImgUpldSuccess,onImgUpldError))
  };

  const onImgUpldSuccess = (data) => {
    console.log(data.data.data);
    // form.setFieldValue("drug_licence_number_20_a","drewtrdfhgdzsarsdh")
  };
  const onImgUpldError = (err) => {
    console.log(err);
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        (adminViewData?.distributor_id
          ? ` / ${adminViewData?.distributor_id} - edit`
          : " / edit")}
    </span>
  );

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, values }) => {
          setintValues(values);
          return (
            <Form>
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    onClick={() => resetFunc(setFieldValue)}
                    type="button"
                  >
                    RESET
                  </AdminButton>
                  <AdminButton
                    background="#2AD3E7"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              {loading == true ? (
                <div className="view-spinner-container">
                  <LoadingSpinnerTable show={loading} />
                </div>
              ) : (
                <div className="fn_Nunito scrolling">
                  <>
                    <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                      <label className="basic-title mx-2 mb-1">
                        Basic Details
                      </label>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="mx-4 px-3">
                          <div className="mb-2">
                            <label className=" Neutral_Color fs_14">
                              Distributor ID
                            </label>
                            <p className="fs_14">
                              {adminViewData?.distributor_id}
                            </p>
                          </div>
                          <div className="my-2">
                            <label className=" Neutral_Color fs_14">
                              No. Of Orders
                            </label>
                            <p className="fs_14">
                              {adminViewData?.orders?.length}
                            </p>
                          </div>

                          <div className="my-2">
                            <div
                              className="d-flex justify-content-between align-items-end"
                              style={{ maxWidth: "450px" }}
                            >
                              <label className="color_747f9e">
                                Distributor Logo
                              </label>
                              {logoImg !== "" && logoImg !== null && (
                                <>
                                  <label
                                    htmlFor="logo"
                                    style={{ textTransform: "uppercase" }}
                                    className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                                  >
                                    <img src={reupload} className="mb-1" />
                                    Re-UPload
                                  </label>
                                </>
                              )}
                              <input
                                type="file"
                                name="upload"
                                id="logo"
                                accept="image/*"
                                onChange={(e) => logoimageUpdate(e)}
                                style={{ display: "none" }}
                              />
                            </div>
                            <div style={{ maxWidth: "450px" }}>
                              {logoImg !== "" && logoImg !== null ? (
                                <div className="manufacturer-doc-upload-container">
                                  <img
                                    src={logoImg}
                                    height={"100%"}
                                    width={"100%"}
                                  />
                                </div>
                              ) : (
                                <>
                                  <label
                                    htmlFor="logo"
                                    className="manufacturer-doc-upload-container w-100 text-center"
                                  >
                                    <div
                                      style={{ height: "12rem" }}
                                      className={`d-flex align-items-center justify-content-center `}
                                    >
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img
                                          src={upload}
                                          width="50px"
                                          height="50px"
                                        />
                                        <span className="color_#262C3D pt-2 fs_14 fs_lg_12">
                                          Drag your image here, or
                                          <span className="Medium_Purple fw_700 ms-1">
                                            Browse
                                          </span>
                                        </span>
                                        <label className="color_747f9e pt-1 fs_10">
                                          Supports: JPEG and PNG images only.{" "}
                                          <span className="color_black">
                                            (2MB Max, 540X540px Resolution)
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </label>
                                  <input
                                    type="file"
                                    name="upload"
                                    id="logo"
                                    // acceptType={["jpg", "png"]}
                                    style={{ display: "none" }}
                                    onChange={(e) => logoimageUpdate(e)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="my-2 mt-4">
                            <InputCapitalField
                              id={"firm_name"}
                              label={"Firm Name"}
                              type={"text"}
                              placeholder={"Enter firm name"}
                              name={"firm_name"}
                              value={values.firm_name}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mx-4 px-3">
                          <div className="my-2">
                            <InputCapitalField
                              id={"gst_number"}
                              setFieldValue={setFieldValue}
                              label={"GST Number"}
                              type={"text"}
                              placeholder={"Enter GST number"}
                              name={"gst_number"}
                              value={values.gst_number}
                              maxLength={15}
                            />
                          </div>
                          <div className="my-2">
                            <InputField
                              id={"drug_licence_number_20_a"}
                              label={"Drug license 1"}
                              type={"text"}
                              placeholder={"Enter drug license number 1"}
                              name={"drug_licence_number_20_a"}
                              value={values.drug_licence_number_20_a}
                            />
                          </div>

                          <div className="my-2">
                            <InputField
                              id={"drug_licence_number_20_b"}
                              label={"Drug license 2"}
                              type={"text"}
                              placeholder={"Enter drug license number 2"}
                              name={"drug_licence_number_20_b"}
                              value={values.drug_licence_number_20_b}
                            />
                          </div>
                          <div className="my-2">
                            <InputCapitalField
                              id={"fssai_number"}
                              label={"FSSAI Number"}
                              setFieldValue={setFieldValue}
                              type={"text"}
                              placeholder={"Enter FSSAI number"}
                              name={"fssai_number"}
                              maxLength={14}
                              value={values.fssai_number}
                            />
                          </div>
                          {/* <div className="select-container">
                      <label className="Neutral_Color fw_400 fs_14 my-1">
                        Linked Manufacturer
                      </label>
                      <Field>
                        {({ form, Field }) => (
                          <Select
                            isMulti
                            options={manufacturerOptions}
                            styles={customStyles}
                            {...Field}
                            placeholder={"Select manufecturer"}
                            value={manufacturerOptions.filter((o1) =>
                              values?.linked_manufacturers?.some(
                                (o2) => o2 == o1.value
                              )
                            )}
                            id="linked_manufacturers"
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "linked_manufacturers",
                                selectedOption
                                  ? selectedOption.map((item) => item.value)
                                  : []
                              );
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="linked_manufacturers" />
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                      <label className="basic-title mx-2 mb-1">
                        Contact Details
                      </label>
                    </div>
                    <div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"email"}
                                label={"Email Address"}
                                type={"text"}
                                placeholder={"Enter email address"}
                                name={"email"}
                                // disabled={true}
                                value={values.email}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"alternate_email"}
                                label={"Alternate Email Address"}
                                type={"text"}
                                placeholder={"Enter alternate email address"}
                                name={"alternate_email"}
                                value={values.alternate_email}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"mobile"}
                                label={"Mobile Number"}
                                type={"text"}
                                placeholder={"Enter mobile number"}
                                name={"mobile"}
                                maxLength={10}
                                // disabled={true}
                                value={values.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"alternate_mobile"}
                                maxLength={10}
                                value={values.alternate_mobile}
                              />
                            </div>
                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 Neutral_Color">
                                Address
                              </label>
                              <div>
                                <Field name={"address_one"}>
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={"address_one"}
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                    />
                                  )}
                                </Field>
                                <Field name={"address_two"}>
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={"address_two"}
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                    />
                                  )}
                                </Field>
                                <Field name={"address_three"}>
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 3"
                                      id={"address_three"}
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors.address_one && (
                                  <ErrorMsg name={"address_one"} />
                                )) ||
                                  (errors.address_two && (
                                    <ErrorMsg name={"address_two"} />
                                  )) ||
                                  (errors.address_three && (
                                    <ErrorMsg name={"address_three"} />
                                  ))}
                              </div>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"landmark"}
                                value={values.landmark}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <PincodeInputField
                                id={"pincode"}
                                label={"Pincode"}
                                type={"text"}
                                maxLength={6}
                                placeholder={"Enter pincode"}
                                name={"pincode"}
                                value={values.pincode}
                                onChange={(e) =>
                                  handlePincode(e, setFieldValue)
                                }
                              />
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 Neutral_Color">
                                  State
                                </label>
                                <Field name={"state_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={stateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={stateOptions.filter(
                                        (s) => s.value == values.state_id
                                      )}
                                      onChange={(selected) => {
                                        setSelectedState(selected?.value);
                                        setFieldValue(
                                          "state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 Neutral_Color">
                                  District
                                </label>
                                <Field name={"district_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={districtOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={districtOptions.filter(
                                        (s) => s.value == values.district_id
                                      )}
                                      onChange={(selected) => {
                                        setSelectedDistrict(selected?.value);
                                        setFieldValue(
                                          "district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 Neutral_Color">
                                  City
                                </label>
                                <Field name={"city_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={cityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={cityOptions.filter(
                                        (s) => s.value == values.city_id
                                      )}
                                      onChange={(selected) => {
                                        setSelectedCity(selected?.value);
                                        setFieldValue(
                                          "city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="city_id" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                      <label className="basic-title mx-2 mb-1">Documents</label>
                    </div>
                    <div className="row mt-3">
                      <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                        <div
                          className="mx-xxl-4 px-3 mx-auto my-2"
                          style={{ maxWidth: "480px" }}
                        >
                          <div className="my-2">
                            <div
                              className="d-flex justify-content-between align-items-end"
                              style={{ maxWidth: "450px" }}
                            >
                              <label>Drug License 1</label>
                              {licenceImg !== "" && licenceImg !== null && (
                                <>
                                  <label
                                    htmlFor="DrugLicense"
                                    style={{ textTransform: "uppercase" }}
                                    className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                                  >
                                    <img src={reupload} className="mb-1" />{" "}
                                    Re-UPload
                                  </label>
                                </>
                              )}
                              <input
                                type="file"
                                name="upload"
                                id="DrugLicense"
                                accept="image/*, application/pdf"
                                onChange={(e) => docimageUpdateTwo(e)}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                          {licenceImg !== "" && licenceImg !== null ? (
                            <div
                              className={` h-100 my-2`}
                              style={{
                                height: "25rem",
                                // width: "15rem",
                                // border: "1px solid black",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                scrollBehavior: "smooth",
                                position: "relative",
                              }}
                            >
                              <div className="manufacturer-doc-upload-container">
                                {pdfChecker(licenceImg) ? (
                                  <Document file={licenceImg}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={licenceImg}
                                  />
                                )}
                              </div>
                              <div
                                className="w-100"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "0",
                                  maxWidth: "450px",
                                }}
                              >
                                <div
                                  className="manufacturer-doc-upload-container"
                                  style={{
                                    backgroundColor: "rgb(38, 44, 61, 0.5)",
                                  }}
                                >
                                  <div
                                    className="align-items-center"
                                    style={{
                                      position: "absolute",
                                      zIndex: "55",
                                      top: "43%",
                                      left: "40%",
                                    }}
                                  >
                                    <img src={eye} className="mb-2" />
                                    {pdfChecker(licenceImg) ? (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setOpenPdf(true);
                                          setPdfFileUrl(licenceImg);
                                          setFileName(
                                            "Drug Manufacturing License"
                                          );
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setImageModal(true);
                                          setImageTitleData(
                                            "Drug Manufacturing License"
                                          );
                                          setImageUrlData(licenceImg);
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <label
                                htmlFor="DrugLicense"
                                className="manufacturer-doc-upload-container w-100 text-center"
                              >
                                <div className=" w-100 text-center">
                                  <img
                                    src={uploadDoc}
                                    alt="doc"
                                    className="mt-5"
                                  />
                                  <p className="Medium_Purple fw_700 my-1 fs_17">
                                    Choose File
                                  </p>
                                  <p className="color_b0b3c7 fs_12">
                                    Supports: PDF and image files
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                name="upload"
                                id="DrugLicense"
                                accept="image/*, application/pdf"
                                onChange={(e) => docimageUpdateTwo(e)}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                        <div
                          className="mx-xxl-4 px-3 mx-auto my-2"
                          style={{ maxWidth: "480px" }}
                        >
                          <div className="my-2">
                            <div
                              className="d-flex justify-content-between align-items-end"
                              style={{ maxWidth: "450px" }}
                            >
                              <label>Drug License 2</label>
                              {licence2Img !== "" && licence2Img !== null && (
                                <>
                                  <label
                                    htmlFor="DrugLicense2"
                                    style={{ textTransform: "uppercase" }}
                                    className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                                  >
                                    <img src={reupload} className="mb-1" />{" "}
                                    Re-UPload
                                  </label>
                                </>
                              )}
                              <input
                                type="file"
                                name="upload"
                                id="DrugLicense2"
                                accept="image/*, application/pdf"
                                onChange={(e) => docimageUpdateThree(e)}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                          {licence2Img !== "" && licence2Img !== null ? (
                            <div
                              className={` h-100 my-2`}
                              style={{
                                height: "25rem",
                                // width: "15rem",
                                // border: "1px solid black",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                scrollBehavior: "smooth",
                                position: "relative",
                              }}
                            >
                              <div className="manufacturer-doc-upload-container">
                                {pdfChecker(licence2Img) ? (
                                  <Document file={licence2Img}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={licence2Img}
                                  />
                                )}
                              </div>
                              <div
                                className="w-100"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "0",
                                  maxWidth: "450px",
                                }}
                              >
                                <div
                                  className="manufacturer-doc-upload-container"
                                  style={{
                                    backgroundColor: "rgb(38, 44, 61, 0.5)",
                                  }}
                                >
                                  <div
                                    className="align-items-center"
                                    style={{
                                      position: "absolute",
                                      zIndex: "55",
                                      top: "43%",
                                      left: "40%",
                                    }}
                                  >
                                    <img src={eye} className="mb-2" />
                                    {pdfChecker(licence2Img) ? (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setOpenPdf(true);
                                          setPdfFileUrl(licence2Img);
                                          setFileName(
                                            "Drug Manufacturing License"
                                          );
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setImageModal(true);
                                          setImageTitleData(
                                            "Drug Manufacturing License"
                                          );
                                          setImageUrlData(licence2Img);
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <label
                                htmlFor="DrugLicense2"
                                className="manufacturer-doc-upload-container w-100 text-center"
                              >
                                <div className=" w-100 text-center">
                                  <img
                                    src={uploadDoc}
                                    alt="doc"
                                    className="mt-5"
                                  />
                                  <p className="Medium_Purple fw_700 my-1 fs_17">
                                    Choose File
                                  </p>
                                  <p className="color_b0b3c7 fs_12">
                                    Supports: PDF and image files
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                name="upload"
                                id="DrugLicense2"
                                accept="image/*, application/pdf"
                                onChange={(e) => docimageUpdateThree(e)}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                        <div
                          className="mx-xxl-4 px-3 mx-auto my-2"
                          style={{ maxWidth: "480px" }}
                        >
                          <div className="my-2">
                            <div
                              className="d-flex justify-content-between align-items-end"
                              style={{ maxWidth: "450px" }}
                            >
                              <label>GST Certificate</label>
                              {gstimg !== "" && gstimg !== null && (
                                <>
                                  <label
                                    htmlFor="GSTCertificate"
                                    style={{ textTransform: "uppercase" }}
                                    className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                                  >
                                    <img src={reupload} className="mb-1" />{" "}
                                    Re-UPload
                                  </label>
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              name="upload"
                              id="GSTCertificate"
                              accept="image/*, application/pdf"
                              style={{ display: "none" }}
                              onChange={(e) => docimageUpdateOne(e)}
                            />
                          </div>
                          {gstimg !== "" && gstimg !== null ? (
                            <div
                              className={` h-100`}
                              style={{
                                height: "25rem",
                                // width: "15rem",
                                // border: "1px solid black",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                scrollBehavior: "smooth",
                                position: "relative",
                              }}
                            >
                              <div className="manufacturer-doc-upload-container">
                                {pdfChecker(gstimg) ? (
                                  <Document file={gstimg}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img className="image-upload" src={gstimg} />
                                )}
                              </div>
                              <div
                                className="w-100"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "0",
                                  maxWidth: "450px",
                                }}
                              >
                                <div
                                  className="manufacturer-doc-upload-container"
                                  style={{
                                    backgroundColor: "rgb(38, 44, 61, 0.5)",
                                  }}
                                >
                                  <div
                                    className="align-items-center"
                                    style={{
                                      position: "absolute",
                                      zIndex: "55",
                                      top: "43%",
                                      left: "40%",
                                    }}
                                  >
                                    <img src={eye} className="mb-2" />
                                    {pdfChecker(gstimg) ? (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setOpenPdf(true);
                                          setPdfFileUrl(gstimg);
                                          setFileName(
                                            "Drug Manufacturing License"
                                          );
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="fs_16 fw_600"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#E7E5FF",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          setImageModal(true);
                                          setImageTitleData(
                                            "Drug Manufacturing License"
                                          );
                                          setImageUrlData(gstimg);
                                        }}
                                      >
                                        VIEW
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <label
                                htmlFor="GSTCertificate"
                                className="manufacturer-doc-upload-container w-100 text-center"
                              >
                                <div className=" w-100 text-center">
                                  <img
                                    src={uploadDoc}
                                    alt="doc"
                                    className="mt-5"
                                  />
                                  <p className="Medium_Purple fw_700 my-1 fs_17">
                                    Choose File
                                  </p>
                                  <p className="color_b0b3c7 fs_12">
                                    Supports: PDF and image files
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                name="upload"
                                id="GSTCertificate"
                                accept="image/*, application/pdf"
                                style={{ display: "none" }}
                                onChange={(e) => docimageUpdateOne(e)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      <SuccessPopup
        show={onShowSuccess}
        title={"Distributor Edited Successfully"}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
      <PdfView
        show={openPdf}
        handleClose={() => setOpenPdf(false)}
        pdfUrl={pdfFileUrl}
        filename={filename}
      />
      <ImageViewModal
        show={imageModal}
        ShowClose={() => setImageModal(false)}
        imageTitleData={imageTitleData}
        imageUrlData={imageUrlData}
      />
    </MainLayout>
  );
};

export default DistributorEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
