import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import TableContainer from "../../layout/table-container/table-container";
import OrdersViewTable from "./orderTable";
import moment from "moment";
import { customerOrderCancel } from "../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";

const OrderTableModal = ({
  show,
  ShowClose,
  fileName,
  bulkUploadJSON,
  setBulkUploadJSON,
  data,
  index,
  orderdata,
  fetchCustomerView,
}) => {
  // console.log(data);
  const dispatch = useDispatch();
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  useEffect(() => {
    setBulkUploadData(bulkUploadJSON);
  }, [bulkUploadJSON]);
  const searchHandler = (e) => {
    console.log(e.target.value);
    let newArr = bulkUploadJSON.filter(
      (product) =>
        product.product_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        product.company_product_id
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    console.log(newArr);
    setBulkUploadData(newArr);
  };

  const cancelFunc = () => {
    dispatch(
      customerOrderCancel(
        {
          order_id: orderdata.id,
        },
        cancelSuccess,
        cancelError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    ShowClose()
    fetchCustomerView();
  }

  const cancelSuccess = (data) => {
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const cancelError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={ShowClose}
      dialogClassName="product-data-table-container col-10 px-5 mx-auto"
      size=""
    >
      <ModalHeader className="d-flex justify-content-between align-items-center">
        <div>
          <span className="fn_Nunito fw_600 fs_18">{orderdata?.order_id}</span>
          <span className="color_747f9e fs_14 ms-1">
            {moment(orderdata?.createdAt).format("DD MMM YYYY HH:mm A")}
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          <AdminButton
            background="#FF6F5B"
            color="white"
            width="150px"
            type="button"
            onClick={() => cancelFunc()}
          >
            <span className="m-2">CANCEL ORDER</span>
          </AdminButton>
          <AdminButton
            background="#E7E5FF"
            width="100px"
            type="button"
            onClick={() => ShowClose()}
          >
            CLOSE
          </AdminButton>
        </div>
      </ModalHeader>
      <ModalBody className="text-nowrap">
        <OrdersViewTable data={data} orderdata={orderdata} />
      </ModalBody>
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </Modal>

  );
};

export default OrderTableModal;
