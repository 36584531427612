import { call, takeLatest } from "redux-saga/effects";
import {
  CREATE_NEW_PASSWORD,
  FORGET_PASSWORD,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_EMAIL,
} from "../actions/types";
import * as authApis from "../apis/authApi";
export function* loginWorker(action) {
  try {
    const res = yield call(authApis.loginApi, {
      email: action.email,
      password: action.password,
    });
    if (res.status == "200") {
      console.log(res.data.data.superAdminPermissions);
      localStorage.setItem("accessToken", res.data.data.access_token);
      localStorage.setItem("email", res.data.data.adminData.email);
      localStorage.setItem("userType", res.data.data.adminData.user_type);
      localStorage.setItem("adminId", res.data.data.adminData.id);
      if (res.data.data.adminData.user_type.user_type !== "admin") {
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.data.adminData.superAdminPermissions)
        );
      }
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* forgetPasswordWatcher(action) {
  try {
    const res = yield call(authApis.forgetPasswordApi, { email: action.email });
    if (res.status == "200") {
      console.log(res);
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* verifyResetPasswordWorker(action) {
  try {
    const res = yield call(authApis.verifyEmail, {
      resetToken: action.resetToken,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* createNewPasswordWorker(action) {
  try {
    const res = yield call(authApis.createNewPasswordApi, action.data);
    console.log(res);
    if (res.status == "200") {
      localStorage.clear();
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* resetPasswordWorker(action) {
  try {
    const res = yield call(authApis.resetPasswordApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* logoutWorker(action) {
  try {
    const res = yield call(authApis.logoutApi);
    console.log(res);
    if (res.status == "200") {
      localStorage.clear();
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* loginWatcher() {
  yield takeLatest(LOGIN, loginWorker);
}

export function* forgetPasswordWorker() {
  yield takeLatest(FORGET_PASSWORD, forgetPasswordWatcher);
}

export function* verifyResetPasswordWatcher() {
  yield takeLatest(VERIFY_EMAIL, verifyResetPasswordWorker);
}

export function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}

export function* logoutWatcher() {
  yield takeLatest(LOGOUT, logoutWorker);
}
export function* createNewPasswordWatcher() {
  yield takeLatest(CREATE_NEW_PASSWORD, createNewPasswordWorker);
}
