import React from "react";

const ProductVerificationTableData = ({
  data,
  index,
  deleteModalOpener,
  checkedProduct,
  isChecked,
}) => {
  return (
    <tbody>
      <tr className="table-rows1" key={index}>
        <td className="table-each-row-data" width={"6%"}>
          <input
            type="checkbox"
            className="CheckBox my-1"
            id="index"
            name="prodcutsCheck"
            onChange={(e) => checkedProduct(e, data)}
            checked={isChecked}
          />
        </td>
        <td className="table-each-row-data">{data?.sl_no}</td>
        <td className="table-each-row-data">{data?.company_product_id}</td>
        <td className="table-each-row-data">{data?.product_name}</td>
        <td className="table-each-row-data">{`₹ ${data?.mrp}`}</td>
        <td className="table-each-row-data">{`₹ ${data?.ptr}`}</td>
        <td className="table-each-row-data">{`₹ ${data?.pts}`}</td>
        <td className="table-each-row-data">{`${data?.quantity}`}</td>
        <td className="table-each-row-data">{`${data?.offer_qty}`}</td>
        <td className="table-each-row-data ">
          <div className="all-button d-flex align-items-center">
            <button
              className="button-disable"
              type="button"
              color={"green"}
              // onClick={() => deleteModalOpener(index)}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ProductVerificationTableData;
