import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EnableDisableModal from "../../../../components/modals/enableDisableModal";
import { enableDisableHeadQuarter } from "../../../../redux/actions/manufacturerActions";
import "../../manufacturer.css";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  gevHQReportList,
  gevHeadQuaterViewList,
} from "../../../../redux/actions/godsEyeViewActions";
import searcher from "../../../../assets/icons/searchInput.svg";
import TablePagination from "../../../../components/Pagination/tablePagination";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import exportFromJSON from "export-from-json";

const HeadQuarterList = ({
  setSelectedHeadquarterData,
  selectedHeadquarterData,
  fetchManufacturerView,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [headQuarterData, setHeadQuarterData] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // CSV
  const [showErrorMsg, setshowErrorMsg] = useState(""); // CSV
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");
  const page_no = params?.id.split("&")?.[3];
  const [page, setPage] = useState(page_no);
  const vendor_id = params?.id.split("&")?.[0];
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];
  console.log(pathType, "sssss");

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setPage(page_no);
  }, [page, page_no]);

  const enableDisableFunc = (obj) => {
    console.log(obj);

    dispatch(
      enableDisableHeadQuarter(
        obj,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const onEnableDisableSuccess = (data) => {
    setOnEnableShow(false);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    hqList();
    fetchManufacturerView();
  };

  const onEnableDisableError = (err) => {
    console.log(err);
  };

  const hqList = () => {
    dispatch(
      gevHeadQuaterViewList({ vendor_id, page }, onFetchSuccess, onFetchError)
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      gevHeadQuaterViewList({ vendor_id, page }, onFetchSuccess, onFetchError)
    );
  }, [page]);

  useEffect(() => {
    setPage(1);
    dispatch(
      gevHeadQuaterViewList(
        { vendor_id, page, search },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [search]);

  const onFetchSuccess = (data) => {
    console.log(data);
    setHeadQuarterData(data?.data?.data);
    setLoading(false);
  };

  const onFetchError = (err) => {
    setLoading(false);
  };

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(gevHQReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "HeadQuarters Id": data?.code ? data?.code : "--",
          "HeadQuarters Name": data?.name ? data?.name : "--",
          State: data?.state?.name ? data?.state?.name : "--",
          District: data?.headQuaterDistricts?.map((ele) =>
            ele?.district?.name ? ele?.district?.name : "--"
          ),
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setshowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setshowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  return (
    <>
      <div className="ms-5 mx-3 my-3 mt-4">
        <div className="d-flex justify-content-between">
          <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search head quarter here by head quarter name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <button
              className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
              type="button"
              onClick={() => exportTableCsv()}
            >
              download
            </button>
          </div>
        </div>
        <div
          className="manufacturer-table-container"
          style={{ height: "33.5rem", maxWidth: "100%" }}
        >
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads py-2" width={"2%"}>
                  #
                </th>
                <th className="table-heads " width={"8%"}>
                  Head Quarters Id
                </th>
                <th className="table-heads " width={"8%"}>
                  Head Quarters Name
                </th>

                <th className="table-heads " width={"8%"}>
                  State
                </th>
                <th className="table-heads " width={"8%"}>
                  District
                </th>
                <th className="table-heads " width={"8%"}>
                  Status
                </th>
                <th className="table-heads " width={"15%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="division-spinner-container">
                  <LoadingSpinnerTable />
                </div>
              ) : (
                <>
                  {headQuarterData?.headQuarters?.length > 0 ? (
                    headQuarterData?.headQuarters?.map((data, index) => {
                      const renderDistrictsTooltip = (props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {data?.headQuaterDistricts?.map((ele, index) => (
                            <span key={index}>
                              {index ? (
                                <span className="mx-1 color_b0b3c7">|</span>
                              ) : (
                                ""
                              )}
                              {ele?.district?.name ? ele?.district?.name : "--"}
                            </span>
                          ))}
                        </Tooltip>
                      );
                      return (
                        <tr className="table-rows1 color_262c3d" key={index}>
                          <td className="table-each-row-data">
                            {(headQuarterData?.pagination?.currentPage - 1) *
                              10 +
                              (index + 1)}
                          </td>
                          <td className="table-each-row-data">
                            {data?.code ? data?.code : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.name ? data?.name : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.state?.name ? data?.state?.name : "--"}
                          </td>
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderDistrictsTooltip}
                          >
                            <td
                              className="table-each-row-data text-truncate"
                              style={{ maxWidth: "200px" }}
                            >
                              {data?.headQuaterDistricts?.length > 0 &&
                                data?.headQuaterDistricts?.map((ele, index) => {
                                  return (
                                    <span className="slice-word" key={index}>
                                      <span style={{ color: "#B0B3C7" }}>
                                        {index ? " | " : ""}
                                      </span>
                                      {ele?.district?.name
                                        ? ele?.district?.name
                                        : "--"}
                                    </span>
                                  );
                                })}
                            </td>
                          </OverlayTrigger>
                          {data?.status ? (
                            <td className="table-each-row-data text_green">
                              Active
                            </td>
                          ) : (
                            <td className="table-each-row-data text_red">
                              Inactive
                            </td>
                          )}

                          <td className="table-each-row-data ">
                            <div className="all-button d-flex align-items-center">
                              <button
                                className="button-view"
                                type="button"
                                onClick={() => {
                                  navigate(
                                    `/manufacturer/${pathType}/view/${vendor_id}&headQuarters&view&${data?.id}`
                                  );
                                }}
                              >
                                <span className=""> View</span>
                              </button>
                              <>
                                {(user_type == "super_admin" &&
                                  userData?.write) ||
                                user_type == "admin" ? (
                                  <>
                                    <span className="vr  vr_line mt-1 "></span>
                                    <button
                                      className="button-edit1"
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/manufacturer/${pathType}/view/${vendor_id}&headQuarters&edit&${data?.id}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <span className="vr vr_line mt-1"></span>
                                    <button
                                      className={
                                        data.status
                                          ? `button-disable`
                                          : `button-enable`
                                      }
                                      type="button"
                                      color={"green"}
                                      onClick={() => {
                                        setOnEnableShow(true);
                                        setSelectedHeadquarterData(data);
                                        console.log(selectedHeadquarterData);
                                      }}
                                    >
                                      {data.status ? "disable" : "enable"}
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center list-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          <EnableDisableModal
            enableDisableFunc={enableDisableFunc}
            handleClose={() => setOnEnableShow(false)}
            title={`Head Quarter ${selectedHeadquarterData?.name} ?`}
            id={{
              head_quater_id: selectedHeadquarterData?.id,
              vendor_id: params?.id,
            }}
            status={selectedHeadquarterData?.status}
            show={onEnableShow}
          />

          <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
        </div>
        {headQuarterData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={headQuarterData?.pagination}
            nextFunc={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendor_id}&headQuarters&list&${
                  headQuarterData?.pagination?.currentPage + 1
                }`
              );
              setPage(headQuarterData?.pagination?.currentPage + 1);
            }}
            prevFunc={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendor_id}&headQuarters&list&${
                  headQuarterData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeSuccessPopup}
      />
    </>
  );
};

export default HeadQuarterList;
