import { api } from "./api";

export const distributorListApi = (params) => {
  return api.get(`/admin/distributor/onboarded-distributor-list`, { params });
};
export const onboardingDistributorListApi = (params) => {
  return api.get(`/admin/distributor/not-onboarded-distributor-list`, {
    params,
  });
};

export const distributorDetailApi = (params) => {
  return api.get(`/admin/distributor/view`, { params });
};

export const distributorCreateApi = (params) => {
  return api.post(`admin/distributor/create`, JSON.stringify(params));
};

export const distributorEditApi = (params) => {
  return api.post(`admin/distributor/edit`, JSON.stringify(params));
};

export const enableDisableDistributorApi = (params) => {
  return api.post(`/admin/distributor/enable-disable`, JSON.stringify(params));
};

export const deleteDistributorApi = (params) => {
  return api.delete(`/admin/distributor/delete`, {
    params: params,
  });
};

export const distributorVersionList = (params) => {
  return api.get(`/admin/distributor/version-list`, { params });
};

export const distributorVersionDetailApi = (params) => {
  return api.get(`/admin/distributor/version-view`, { params });
};

export const loginPortalApi = (params) => {
  return api.post(`/admin/distributor/login-portal`, { params });
};

export const manufacturerOptionsApi = (params) => {
  return api.get(`/admin/distributor/manufacturers-list`);
};
export const divisionsOptionsApi = (params) => {
  return api.get(`/admin/distributor/division-list`, { params });
};

export const distributorLogoUploadApi = (params) => {
  return api.post(`/admin/distributor/upload-logo`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const distributorGstUpload = (params) => {
  return api.post(`admin/distributor/upload-gst-certificate`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const distributorDrugLicenceUpload = (params) => {
  return api.post(
    `/admin/distributor/upload-drug-licence-certificate`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const loginToPortalDistributorApi = (params) => {
  return api.get(`/admin/distributor/login-portal`, { params: params });
};

export const DistributorsendMailApi = (params) => {
  return api.post(`/admin/distributor/send-mail`, JSON.stringify(params));
};
