import React from "react";
import verifiedIcon from "../../assets/icons/verified.svg";
import { Modal, ModalBody } from "react-bootstrap";
import "../modals/modal.css";

const SuccessPopup = ({ show, title, handleClose }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName="my-modal"
    >
      <ModalBody className="m-4 p-4">
        <div className="p-2">
          <img
            src={verifiedIcon}
            alt="success"
            className=" mx-auto d-block"
            style={{ height: "30px" }}
          />
          <p
            className="fn_Nunito fw_600 fs_22 color_262c3d my-2 px-0"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            {title}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessPopup;