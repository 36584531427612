import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import "./poView.css";
import moment from "moment";
import LoadingSpinnerTable from "../../../utilites/loaderSpinningContainer";
import { PoView } from "../../../../redux/actions/manufacturerActions";

const PurchaseOrderViewModal = ({ poViewShow, showClose, id }) => {
    const dispatch = useDispatch();
    const [poData, setPoData] = useState([]);
    const [loading, setLoading] = useState(false);

    console.log(poViewShow);

    useEffect(() => {
        console.log("po id is", id);
        setLoading(true);
        dispatch(PoView({ purchase_order_id: id }, onViewSuccess, onViewError));
    }, []);

    const onViewSuccess = (data) => {
        console.log(data);
        setLoading(false);
        setPoData(data.data.data);
    };
    const onViewError = (err) => {
        setLoading(false);
        console.log(err);
    };

    const reimbursementOptions = {
        "forward_cfa": "Full Order Forward from CFA",
        "order_off_qty": "Order Offer Quantity",
        "full_order_qty": "Full Order Quantity",
        "credit_note": "Credit Note for Offer"
    }

    const purchaseOrderDetails = [
        {
            title: "Purchase Order ID",
            data: poData?.purchaseOrder?.code ? poData?.purchaseOrder?.code : "--",
        },
        {
            title: "Manufacturer Name",
            data: poData?.purchaseOrder?.vendor?.name
                ? poData?.purchaseOrder?.vendor?.name
                : "--",
        },
        {
            title: "Distributor Name",
            data: poData?.purchaseOrder?.distributor
                ? poData?.purchaseOrder?.distributor?.firm_name
                : "All",
        },
        {
            title: "Reimbursement Method",
            data: poData?.purchaseOrder?.reimbursement_method
                ? reimbursementOptions[poData?.purchaseOrder?.reimbursement_method]
                : "--",
        },
        {
            title: "Generated Date",
            data: poData?.purchaseOrder?.createdAt
                ? moment(poData?.purchaseOrder?.createdAt).format("DD MMM YYYY")
                : "--",
        },
    ];

    return (
        <>
            <Modal
                centered
                show={poViewShow}
                onHide={showClose}
                dialogClassName="po-table-modal"
                contentClassName="PO-table-container fn_Nunito p-3"
            >
                <ModalBody>
                    <div>
                        <span className="fw_700 fs_26 fn_Nunito">Purchase Order</span>
                    </div>
                    <div className="row mt-3 gap-xl-4">
                        {purchaseOrderDetails?.map((item) => (
                            <div className="mb-2 col-sm-6 col-lg-3 col-xl-2 col-xxl-1  me-xxl-5 ">
                                <h5 className="color_747f9e text-nowrap fs_15 fw_500 m-0">
                                    {item?.title}
                                </h5>
                                <p className={` fs_15 fn_Nunito fw_400`} style={{ width: '200px' }}>{item?.data}</p>
                            </div>
                        ))}
                    </div>
                    <div className="order-table-container text-nowrap">
                        {loading == true ? (
                            <div className="d-flex justify-content-center">
                                <LoadingSpinnerTable />
                            </div>
                        ) :
                            <table className="main-table">
                                <thead>
                                    <tr className="table-rows color_747f9e">
                                        <th className="table-heads " width={"3%"}>
                                            SL No.
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            PO Number
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            PO Date
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Comppany Product ID
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Jivini Product ID
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Product Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Division Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            MRP
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            PTR
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            PTS
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Doctor Code
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Doctor Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Pharmacy Code
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Pharmacy Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            City Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Order ID
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Order Date
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Ordered Qty
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Ordered Free Qty
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Order Qty@Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Order Free Qty@Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Order Qty@Pts
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Order Free Qty@Pts
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Jivini Id
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Location
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Invoice Id
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Invoice Date
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Invoiced Qty
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Distributors Invoiced Free Qty
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Supplied Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Qty@Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Free Qty@Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Qty@Pts
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Free Qty@Pts
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Qty@Supplied Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Gross Value Of Distributor Inv Free Qty@Supplied Ptr
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            CFA Jivini Id
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            CFA Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            CFA Location
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            CFA Inv No Reimbursement
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            CFA Inv Date Reimbursement
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 1 Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 1 Code
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 1 Mobile
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 2 Name
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 2 Code
                                        </th>
                                        <th className="table-heads " width={"8%"}>
                                            Approval Linked Level 2 Mobile
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {poData?.tableData?.map((item, index) => (
                                        <tr className="table-rows1">
                                            <td className="table-each-row-data">{index + 1}</td>
                                            <td className="table-each-row-data">
                                                {item?.po_number ? item?.po_number : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.po_date ? item?.po_date : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.company_product_id ? item?.company_product_id : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.product_id ? item?.product_id : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.product_name ? item?.product_name : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.division_name ? item?.division_name : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.mrp ? item?.mrp : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.ptr ? item?.ptr : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.pts ? item?.pts : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.doctor_code ? item?.doctor_code : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.doctor_name ? item?.doctor_name : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.pharmacy_code ? item?.pharmacy_code : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.pharmacy_name ? item?.pharmacy_name : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.city ? item?.city : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.order_id ? item?.order_id : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.order_date ? item?.order_date : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.order_quantity ? item?.order_quantity : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.order_free_quantity
                                                    ? item?.order_free_quantity
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.product_gross_value
                                                    ? item?.product_gross_value
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_ordered_free_qty_ptr
                                                    ? item?.gross_value_of_ordered_free_qty_ptr
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.product_gross_value_pts
                                                    ? item?.product_gross_value_pts
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_ordered_free_qty_pts
                                                    ? item?.gross_value_of_ordered_free_qty_pts
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_code ? item?.distributor_code : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_name ? item?.distributor_name : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_location
                                                    ? item?.distributor_location
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_invoice_id
                                                    ? item?.distributor_invoice_id
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_invoice_date
                                                    ? moment(item?.distributor_invoice_date).format(
                                                        "DD-MM-YYYY"
                                                    )
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.invoice_qty ? item?.invoice_qty : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.invoice_free_qty ? item?.invoice_free_qty : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.supplied_ptr ? item?.supplied_ptr : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_qty_ptr
                                                    ? item?.gross_value_of_dist_inv_qty_ptr
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_free_qty_ptr
                                                    ? item?.gross_value_of_dist_inv_free_qty_ptr
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_qty_pts
                                                    ? item?.gross_value_of_dist_inv_qty_pts
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_free_qty_pts
                                                    ? item?.gross_value_of_dist_inv_free_qty_pts
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_qty_supplied_ptr
                                                    ? item?.gross_value_of_dist_inv_qty_supplied_ptr
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.gross_value_of_dist_inv_free_qty_supplied_ptr
                                                    ? item?.gross_value_of_dist_inv_free_qty_supplied_ptr
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_cfa_code
                                                    ? item?.distributor_cfa_code
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_cfa_name
                                                    ? item?.distributor_cfa_name
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.distributor_cfa_location
                                                    ? item?.distributor_cfa_location
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.cfa_invoice_id
                                                    ? item?.cfa_invoice_id
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.cfa_invoice_date
                                                    ? moment(item?.cfa_invoice_date).format(
                                                        "DD-MM-YYYY"
                                                    )
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level1_name
                                                    ? item?.linked_level1_name
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level1_code
                                                    ? item?.linked_level1_code
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level1_mobile
                                                    ? item?.linked_level1_mobile
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level2_name
                                                    ? item?.linked_level2_name
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level2_code
                                                    ? item?.linked_level2_code
                                                    : "--"}
                                            </td>
                                            <td className="table-each-row-data">
                                                {item?.linked_level2_mobile
                                                    ? item?.linked_level2_mobile
                                                    : "--"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default PurchaseOrderViewModal;
