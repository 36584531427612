import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import ErrorMsg from "../../components/form/errorMsg";
import InputField from "../../components/form/inputField/inputField";
import back from "../../assets/icons/back.svg";
import * as Yup from "yup";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import "./superUser.css";
import { useDispatch } from "react-redux";
import {
  fetchRoleList,
  permissionsList,
  superAdminCreate,
} from "../../redux/actions/superAdminActions";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import Select from "react-select";

const SuperUserCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setPermission] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const nameregex = /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/;

  const initialValues = {
    employee_id: "",
    name: "",
    email: "",
    mobile: "",
    role_id: "",
  };

  useEffect(() => {
    dispatch(fetchRoleList(fetchPermissionSuccess, fetchPermissionError));
  }, []);

  const fetchPermissionSuccess = (data) => {
    console.log("permissions", data);
    setPermission(data);
  };

  const fetchPermissionError = (data) => {
    console.log(data);
  };
  const validationSchema = Yup.object().shape({
    employee_id: Yup.string().required("Required"),
    role_id: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email").required("Required"),
    mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
  });
  const clearFunc = (setFieldValue) => {
    setFieldValue("employee_id", "");
    setFieldValue("name", "");
    setFieldValue("email", "");
    setFieldValue("mobile", "");
    setFieldValue("role_id", "");
  };

  const onSubmit = (values) => {
    console.log(values);
    dispatch(superAdminCreate(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/super-user/user/1");
  };
  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <MainLayout breadCrumb="ADMIN / Super User - Create">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    type="button"
                    onClick={() => clearFunc(setFieldValue)}
                  >
                    CLEAR
                  </AdminButton>
                  <AdminButton
                    background="#7065e4"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="scrolling">
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="my-2 ">
                    <InputField
                      id={"employee_id"}
                      label={"Employee ID"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter employee id"}
                      name={"employee_id"}
                    />
                  </div>
                  <div className="my-2">
                    <InputField
                      id={"name"}
                      label={"Name"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter name"}
                      name={"name"}
                    />
                  </div>
                  <div className="select-container">
                    <label className="w-100 mx-1 fn_Nunito fs_14">Role</label>
                    <Field name={"role_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={permission}
                          styles={customStyles}
                          placeholder={"Enter role"}
                          value={permission.filter(
                            (s) => s.value == values.role_id
                          )}
                          onChange={(selected) => {
                            setFieldValue("role_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="role_id" />
                  </div>
                  <div className="my-2">
                    <InputField
                      id={"email"}
                      label={"Email Address"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter email address"}
                      name={"email"}
                    />
                  </div>
                  <div className="my-2">
                    <InputField
                      id={"mobile"}
                      label={"Phone Number"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter phone number"}
                      name={"mobile"}
                      maxLength={10}
                    />
                  </div>
                  {/* <div className="my-0">
                    <label className=" color_262c3d fs_14">Permission</label>
                  </div>
                  <div className="my-1 d-flex  label-container align-items-center justify-content-start ">
                    <div className="col-5">
                      <label className="color_b0b3c7 fs_14">Models</label>
                    </div>
                    <label className="color_b0b3c7 fs_14 ms-4 ps-2">Read</label>
                    <label className="color_b0b3c7 fs_14 ms-4 ps-2">
                      Write
                    </label>
                  </div>
                  {permission?.map((ele, index) => (
                    <div
                      className="d-flex my-1 label-container align-items-center justify-content-start"
                      key={index}
                    >
                      <div className="col-6">
                        <label className="fs_14">{ele.label}</label>
                      </div>
                      <div>
                        <span className="fs_14 my-1">
                          <input
                            type={"checkbox"}
                            className="CheckBox"
                            checked={values?.permissions?.find(
                              (ex) => ex == ele?.value
                            )}
                            onChange={(e) => {
                              console.log();
                              selectPermission(
                                e,
                                ele?.value,
                                setFieldValue,
                                "read",
                                values
                              );
                            }}
                          />
                        </span>
                      </div>
                      <div className="ms-5">
                        <span className="fs_14 my-1">
                          <input
                            type={"checkbox"}
                            className="CheckBox"
                            onChange={(e) => {
                              selectPermission(
                                e,
                                ele?.value,
                                setFieldValue,
                                "write",
                                values
                              );
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                  <ErrorMsg name={"permissions"} /> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
        <ErrorPopup
          show={showErrorPopup}
          title={showErrorMsg}
          handleClose={closeErrorPopup}
        />
      </div>
    </MainLayout>
  );
};

export default SuperUserCreate;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
