import React from "react";

const AnalyticsSvg = ({
  fill = "#B0B3C7",
  width = "14px",
  height = "14px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4548_15661)">
        <path
          d="M10.9175 6.99965H8.16822C7.52389 6.99965 7.00155 6.4773 7.00155 5.83297V3.07615C7.00316 2.53228 6.75179 2.01855 6.32137 1.68608C5.91351 1.36309 5.37872 1.24797 4.87412 1.37458C1.44439 2.22595 -0.64579 5.6965 0.205613 9.12626C0.805453 11.5427 2.75255 13.3917 5.19672 13.8661C8.53567 14.5052 11.7936 12.4292 12.6249 9.1329C12.752 8.62764 12.6371 8.09203 12.314 7.68333C11.9777 7.25367 11.4631 7.00173 10.9175 6.99965Z"
          fill={fill}
        />
        <path
          d="M13.7954 4.34428C13.6623 3.84931 13.464 3.37421 13.2057 2.93146C12.4289 1.59878 11.1599 0.624328 9.67186 0.217781C9.57384 0.19134 9.35451 0.175781 9.35451 0.175781C9.27615 0.175809 8.93861 0.175781 8.67144 0.392207C8.27875 0.701219 8.21701 1.05253 8.21119 1.07996C8.19082 1.16598 8.18023 1.25405 8.17969 1.34246V4.08413C8.17969 5.05063 8.9632 5.83413 9.92969 5.83413H12.6824C13.0476 5.83517 13.3914 5.66179 13.6076 5.36746C13.7675 5.15185 13.8498 4.88848 13.8409 4.62021C13.8363 4.5268 13.821 4.43422 13.7954 4.34428Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4548_15661">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnalyticsSvg;
