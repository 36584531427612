import { api } from "./api";

export const stateListApi = (params) => {
  return api.get(`/common/state-list`);
};
export const districtListApi = (params) => {
  return api.get(`/common/district-list?state_id=${params.state_id}`);
};
export const cityListApi = (params) => {
  return api.get(
    `/common/city-list?state_id=${params.state_id}&district_id=${params.district_id}`
  );
};
export const divisionListApi = (params) => {
  return api.get(`/common/division-list`);
};
export const pincodeAutoListApi = (params) => {
  return api.get(`/common/pincode-auto-fill`, { params: params });
};
