import React, { useEffect, useRef, useState } from "react";
import "./login.css";
import subLogo from "../../../assets/images/auth/subBgLogo.png";
import jiviniLogo from "../../../assets/images/auth/jiviniLogo.png";
import eyeOn from "../../../assets/icons/auth/eye-on.svg";
import eyeOff from "../../../assets/icons/auth/eye-off.svg";
import eyeDead from "../../../assets/icons/auth/eyedead.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import * as authActions from "../../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import ErrorMsg from "../../../components/form/errorMsg";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  // const formRef = useRef();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState();
  const [passwordDisable, setPasswordDisable] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setSubmitDisable(true);

    dispatch(
      authActions.login(values.email, values.password, onSuccess, onError)
    );
  };

  const onSuccess = (data) => {
    console.log(data, "login");
    if (data?.data?.data?.adminData.is_new === true) {
      navigate("/create-new-password", { state: data.data.access_token });
    } else {
      navigate("/dashboard");
    }
    setSubmitDisable(false);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    setSubmitDisable(false);
    // formRef?.current?.setFieldError("email", data?.errors?.email);
    // formRef?.current?.setFieldError("password", data?.errors?.password);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const handleChange1 = (e) => {
    if (e.target.value.length > 0) {
      setPasswordDisable(true);
    } else {
      setPasswordDisable(false);
    }
  };
  const FormEnableCheck = () => {
    const {
      values: { email, password },
    } = useFormikContext();
    useEffect(() => {
      if (email.trim() != "" && password.trim() != "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [email, password]);
    return null;
  };

  return (
    <div className="login-container fn_Nunito d-flex flex-column flex-lg-row justify-content-center ">
      <div className="mt-">
        <img
          src={jiviniLogo}
          alt="website logo"
          className="login-logo-image d-lg-none"
        />
      </div>
      <div className="left-view  d-flex flex-column  justify-content-center mt-3 mt-lg-0">
        <img
          src={subLogo}
          alt="Asscents Website"
          className="login-img align-self-center"
        />
      </div>
      <div className="right-view justify-content-center d-flex flex-column mt-5 pt-3 pt-lg-0 mt-lg-0">
        <div className="form-container col-12 col-lg-7 align-self-center d-flex justify-content-around  flex-column">
          <img
            src={jiviniLogo}
            alt="website logo"
            className="login-logo-image d-none d-lg-block"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
            // innerRef={formRef}
          >
            {({ setFieldValue, values, setFieldError }) => (
              <Form>
                <FormEnableCheck />
                <h1 className="fw_700 fs_22 text-center text-lg-start">
                  Login in your account
                </h1>
                <div className=" mt-3">
                  <label className="w-100 mx-1 label-input color_262c3d">
                    Email Address
                  </label>
                  <Field name={"email"}>
                    {({ field, meta }) => (
                      <input
                        {...field}
                        placeholder="Enter your email address"
                        id={"email"}
                        className={"col-12 input-field"}
                      />
                    )}
                  </Field>
                  <ErrorMsg name="email" />
                </div>
                <div className="w-100 mt-lg-0">
                  <label className="w-100 mx-1 label-input color_262c3d">
                    Password
                  </label>
                  <Field name={"password"}>
                    {({ field, meta }) => (
                      <div className="position-relative">
                        <input
                          {...field}
                          placeholder="Enter your password"
                          id={"password"}
                          type={passwordVisible ? "text" : "password"}
                          className={"w-100 input-field"}
                          onKeyUp={(e) => handleChange1(e)}
                        />
                        <img
                          className="login_password_eye__icon cursor_pointer"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          src={
                            passwordDisable
                              ? passwordVisible
                                ? eyeOn
                                : eyeOff
                              : eyeDead
                          }
                          alt="show password or not"
                        />
                      </div>
                    )}
                  </Field>
                  <div className="d-flex justify-content-between">
                    <div className="m-0 p-0">
                      <ErrorMsg name="password" />
                    </div>
                    <div
                      onClick={() => navigate("/forgot-password")}
                      className="span-support cursor_pointer text-nowrap fw_600 fs_12 pe-1"
                    >
                      Forget Password ?
                    </div>
                  </div>
                </div>
                <button
                  className={`login-button`}
                  type="submit"
                  disabled={disable || submitDisable}
                >
                  {submitDisable && <Spinner size="sm" />} Login
                </button>
              </Form>
            )}
          </Formik>
          <ErrorPopup
            show={showErrorPopup}
            title={showErrorMsg}
            handleClose={closeErrorPopup}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
