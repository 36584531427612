import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Modal, ModalBody } from "react-bootstrap";
import "../../../components/modals/modal.css";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import InputField from "../../form/inputField/inputField";
import InputCapitalField from "../../form/inputField/inputCapitalField";
import { useDispatch } from "react-redux";
import { createDoctorAction } from "../../../redux/actions/manufacturerActions";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopup from "../../popups/SuccessPopup";

const CreateDoctorModal = ({
  show,
  handleClose,
  vendor_Id,
  division_id,
  doctorDivisionView,
}) => {
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setshowSuccessMsg] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setshowErrorMsg] = useState("");

  useEffect(() => {
    createDoctorAction();
  }, []);

  const initialValues = {
    name: "",
    email_address: "",
    mobile: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email_address: Yup.string(),
    mobile: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    dispatch(
      createDoctorAction(
        {
          vendor_id: vendor_Id,
          division_id: division_id,
          name: values?.name,
          mobile: values?.mobile,
        },
        onCreateSuccess,
        onCreateError
      )
    );
  };
  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    doctorDivisionView();
  };

  const onCreateSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const modifiedStr = str[0].toUpperCase() + str.slice(1);
    setshowSuccessMsg(modifiedStr);
    setTimeout(timeOutFnc, 2000);
    handleClose();
  };

  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onCreateError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const str1 = err.data.message;
    const modifiedStr1 = str1[0].toUpperCase() + str1.slice(1);
    setshowErrorMsg(modifiedStr1);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName=""
        contentClassName=" col-5 mx-auto p-2 fn_Nunito"
      >
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form className="p-1">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label className="fs_20 fw_700">Create Doctor</label>
                    </div>
                    <div className="d-flex">
                      <AdminButton
                        background="#E7E5FF"
                        width="100px"
                        type="button"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <span className="m-2">CLOSE</span>
                      </AdminButton>
                      <AdminButton
                        background="#7065e4"
                        color="white"
                        width="100px"
                        type="submit"
                      >
                        <span className="m-2">SAVE</span>
                      </AdminButton>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2">
                    <InputCapitalField
                      id={"name"}
                      setFieldValue={setFieldValue}
                      label={"Name"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter Name"}
                      name={"name"}
                      value={values.name}
                    />
                  </div>
                  <div className="mt-2">
                    <InputField
                      id={"email_address"}
                      setFieldValue={setFieldValue}
                      label={"Email"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter Email Address"}
                      name={"email_address"}
                      value={values.email_address}
                    />
                  </div>
                  <div className="mt-2">
                    <InputField
                      id={"mobile"}
                      setFieldValue={setFieldValue}
                      label={"Mobile Number"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter Mobile Number"}
                      name={"mobile"}
                      value={values.mobile}
                      maxLength={10}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeSuccessPopup}
      />
    </>
  );
};

export default CreateDoctorModal;
