import React from "react";

const DistributorSvg = ({
  fill = "#B0B3C7",
  width = "14px",
  height = "14px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2906_23266)">
        <path
          d="M8.75 10.4997H2.33333C1.71449 10.4997 1.121 10.2538 0.683417 9.81626C0.245833 9.37867 0 8.78518 0 8.16634V3.49967C0 2.72613 0.307291 1.98426 0.854272 1.43728C1.40125 0.890299 2.14312 0.583008 2.91667 0.583008H5.83333C6.21636 0.583008 6.59563 0.65845 6.94949 0.805026C7.30336 0.951602 7.62489 1.16644 7.89573 1.43728C8.16656 1.70812 8.38141 2.02965 8.52798 2.38351C8.67456 2.73738 8.75 3.11665 8.75 3.49967V10.4997ZM14 6.41634V5.83301C14 5.44999 13.9246 5.07071 13.778 4.71685C13.6314 4.36298 13.4166 4.04145 13.1457 3.77061C12.8749 3.49978 12.5534 3.28494 12.1995 3.13836C11.8456 2.99178 11.4664 2.91634 11.0833 2.91634H9.91667V6.41634H14ZM9.91667 7.58301V10.4997H11.6667C12.2855 10.4997 12.879 10.2538 13.3166 9.81626C13.7542 9.37867 14 8.78518 14 8.16634V7.58301H9.91667ZM2.36717 11.6663C2.3458 11.7621 2.33446 11.8599 2.33333 11.958C2.33333 12.3448 2.48698 12.7157 2.76047 12.9892C3.03396 13.2627 3.40489 13.4163 3.79167 13.4163C4.17844 13.4163 4.54937 13.2627 4.82286 12.9892C5.09635 12.7157 5.25 12.3448 5.25 11.958C5.24887 11.8599 5.23754 11.7621 5.21617 11.6663H2.36717ZM8.78383 11.6663C8.76246 11.7621 8.75113 11.8599 8.75 11.958C8.75 12.3448 8.90365 12.7157 9.17714 12.9892C9.45063 13.2627 9.82156 13.4163 10.2083 13.4163C10.5951 13.4163 10.966 13.2627 11.2395 12.9892C11.513 12.7157 11.6667 12.3448 11.6667 11.958C11.6655 11.8599 11.6542 11.7621 11.6328 11.6663H8.78383Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2906_23266">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DistributorSvg;
