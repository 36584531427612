import React, { useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { AdminButton } from "../buttons/versionHistoryBtn";
import MailSent from "../popups/MailSent";
import "./modal.css";

const EnableDisableModal = ({
  show,
  handleClose,
  id,
  title,
  status,
  enableDisableFunc,
}) => {
  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="my-modal">
      <ModalBody>
        <div className="p-2">
          <p className="fn_Nunito fw_700 fs_18 color_262c3d my-2 px-0">
            Are you sure ?
          </p>
          <p className="fn_Nunito fw_400 fs_16 color_747f9e my-2 px-0">
            Do you want to
            <span className="mx-1">
              {status == "active" || status == true ? "disable" : "enable"}
            </span>
            this
            <span className="ms-1">{title}</span>
          </p>
          <div className="d-flex justify-content-between">
            <AdminButton
              width="150px"
              color={
                status == "active" || status == true ? "#FF6F5B" : "#24D626"
              }
              border={
                status == "active" || status == true
                  ? "1px solid #FF6F5B"
                  : "1px solid #24D626"
              }
              onClick={() => enableDisableFunc(id)}
            >
              <span className="m-2">
                {status == "active" || status == true ? "DISABLE" : "ENABLE"}
              </span>
            </AdminButton>
            <AdminButton
              width="150px"
              background="#7065e4"
              color="white"
              onClick={handleClose}
            >
              <span className="m-2">NO</span>
            </AdminButton>
          </div>
        </div>
        {/* <MailSent /> */}
      </ModalBody>
    </Modal>
  );
};

export default EnableDisableModal;
