export function pdfChecker(url) {
  return url?.match(/\.(pdf|PDF)$/) ? true : false;
}

export function getYears() {
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const yearsArray = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearsArray.push({ value: year, label: year });
  }
  return yearsArray;
}

export function getFileExtension(url) {
  // Get the part of the URL after the last "/"
  const fileName = url.split('/').pop();
  // Split the file name by "."
  const parts = fileName.split('.');
  // If there's more than one part, the last one is the extension
  if (parts.length > 1) {
    return parts.pop(); // The file extension (e.g., 'pdf')
  } else {
    return null; // No extension found
  }
}