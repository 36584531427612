import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { useDispatch } from "react-redux";
import { fetchStateList } from "../../../redux/actions/commonActions";
import ErrorMsg from "../../../components/form/errorMsg";
import Select from "react-select";
import InputField from "../../../components/form/inputField/inputField";
import DistrictListTable from "../../../components/tables/districtListTable";
import {
  settingsDistrictCreate,
  settingsDistrictEdit,
  settingsDistrictList,
} from "../../../redux/actions/settingsActions";
import searcher from "../../../assets/icons/searchInput.svg";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";

const SettingsDistrict = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [disable, setDisable] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [districtListData, setDistrictListData] = useState([]);
  const [districtId, setdistrictId] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
    fetchDistrictList();
  }, []);

  useEffect(() => {
    fetchDistrictList();
  }, [page, search]);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
    setLoading(false);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onGetStateError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const initialValues = {
    state_id: "",
    name: "",
  };

  const validationSchema = Yup.object().shape({
    state_id: Yup.string().trim().required("Required"),
    name: Yup.string().trim().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (!editEnable) {
      dispatch(settingsDistrictCreate(values, onSuccess, onError));
    } else {
      dispatch(
        settingsDistrictEdit(
          {
            district_id: districtId,
            state_id: values?.state_id,
            name: values?.name,
          },
          onEditSuccess,
          onEditError
        )
      );
    }

    resetForm({
      values: {
        name: "",
        state_id: "",
      },
    });
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchDistrictList();
  };

  const onSuccess = (data) => {
    console.log(data);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setShowSuccessPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const onEditSuccess = (data) => {
    console.log(data);
    fetchDistrictList();
    setEditEnable(false);
  };

  const onEditError = (err) => {
    console.log(err);
  };

  const fetchDistrictList = () => {
    dispatch(
      settingsDistrictList({ page, search }, onListSuccess, onListError)
    );
  };

  const onListSuccess = (data) => {
    console.log(data?.data);
    setDistrictListData(data?.data?.data);
  };

  const onListError = (err) => {
    console.log(err);
  };

  console.log(districtListData, "sdfhsfg");

  const FormEnableCheck = () => {
    const {
      values: { name },
    } = useFormikContext();
    useEffect(() => {
      if (name.trim() != "") {
        setDisable(false);
      } else setDisable(true);
    }, [name]);
    return null;
  };

  const editSetForm = (item, index, setFieldValue) => {
    setEditEnable(true);
    setEditIndex(index);
    setdistrictId(item?.id);
    setFieldValue("name", item.name);
    setFieldValue("state_id", item.state?.id);
  };
  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  Districts">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            {/* <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
            </div> */}
            {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">District</label>
                </div>
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="select-container mt-4 mb-2">
                    <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                      State
                    </label>
                    <Field name={"state_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={stateOptions}
                          styles={customStyles}
                          placeholder={"Enter your state"}
                          value={stateOptions.filter(
                            (s) => s.value == values.state_id
                          )}
                          onChange={(selected) => {
                            setSelectedState(selected?.value);
                            setFieldValue("state_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="state_id" />
                  </div>
                  <div className="my-2">
                    <InputField
                      id={"name"}
                      label={"District"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter new district name"}
                      name={"name"}
                      value={values.name}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ maxWidth: "450px" }}
                  >
                    {!editEnable ? (
                      <button
                        type="submit"
                        className="add-button fs_18 fw_700 fs_sm_12 px-4 py-2 w-50"
                        style={{ maxWidth: "210px" }}
                        disabled={disable}
                      >
                        Create
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="add-button fs_18 fw_700 fs_sm_12 px-4 py-2 w-50"
                        style={{ maxWidth: "210px" }}
                        // disabled={disable}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">District List</label>
              </div>
              <div className="mt-4 inside-table-container mx-4">
                <div className=" my-2 search-container ">
                  <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                    <img className="styled-SVG" src={searcher} color={""} />
                    <input
                      className="search-input fs_14 fw_400 px-2 text-dark"
                      type="text"
                      placeholder="Search district here by district name..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className=" mb-5">
                  <DistrictListTable
                    districtListData={districtListData}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                    setPage={setPage}
                    page={page}
                  />
                </div>               
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default SettingsDistrict;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
