import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import "./manufacturerModal.css";
import TableContainer from "../../layout/table-container/table-container";
import { useDispatch } from "react-redux";
import { useState } from "react";
import PharmacyVerificationTableData from "./pharmacy-verfication-table-data";
import { pharmacyBulkVerify } from "../../../redux/actions/manufacturerActions";
import PharmacyBulkErrorModal from "./bulkupload-error-modals/pharmacyBulkErrorModal";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import UnablePopup from "../../popups/UnablePopUp";

const PharmacyDataVerificationModal = ({
  show,
  ShowClose,
  fileName,
  pharmacyData,
  vendor_id,
  division_id,
  deleteModalOpener,
  setBulkUploadJSON,
}) => {
  const dispatch = useDispatch();
  const [verifiedList, setVerifiedList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [pharmacyBluckStatusData, setPharmacyBluckStatusData] = useState("");
  const [pharmacyErrorModal, setPharmacyErrorModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showUnablePopup, setShowUnablePopup] = useState(false);
  const [showUnableMsg, setShowUnableMsg] = useState();

  const checkedPharmacy = (e, data) => {
    const { checked } = e.target;
    let newArr = verifiedList;
    setVerifiedList([...newArr, data]);
    if (!checked) {
      setVerifiedList(
        verifiedList.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(data)
        )
      );
    }
  };

  const checkAll = (e) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (checked) {
      setVerifiedList(pharmacyData?.pharmacyData);
    } else {
      setVerifiedList([]);
    }
  };

  useEffect(() => {
    console.log(verifiedList);
    if (verifiedList?.length != pharmacyData?.pharmacyData?.length) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [verifiedList]);

  useEffect(() => {
    setBulkUploadData(pharmacyData?.pharmacyData);
  }, [pharmacyData]);

  const verifyPharmacyHandler = () => {
    console.log(pharmacyData, vendor_id, division_id);
    dispatch(
      pharmacyBulkVerify(
        {
          vendor_id,
          division_id,
          pharmacyData: verifiedList,
        },
        onVerifySuccess,
        onVerifyError
      )
    );
  };

  const searchHandler = (e) => {
    let newArr = pharmacyData.pharmacyData.filter(
      (pharmacy) =>
        pharmacy.pharmacy_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        pharmacy.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        pharmacy.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setBulkUploadData(newArr);
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setShowUnablePopup(false);
    setIsCheckAll(false);
    setPharmacyErrorModal(true);
  }

  const onVerifySuccess = (data) => {
    console.log(data);
    ShowClose();
    setPharmacyBluckStatusData(data?.data?.data);
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    if (data.data.message == "unable to create pharmacy" || data.data.message == "unable to create pharmacies") {
      setShowUnablePopup(true);
      setShowUnableMsg(str_modify);
    } else {
      setShowSuccessPopup(true);
      setShowSuccessMsg(str_modify);
    }
    setTimeout(timeOutFnc, 2000);
    setVerifiedList([])
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onVerifyError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setVerifiedList([]);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={ShowClose}
        dialogClassName="field-force-table-container col-10 px-5 mx-auto"
        size=""
      >
        <ModalHeader className="d-flex justify-content-between align-items-center">
          <div>
            <span className="fn_Nunito fw_600 fs_18">
              Pharmacy Data Verification -{" "}
              <span className="fw_500">
                {" "}
                {pharmacyData?.original_name?.length > 35
                  ? pharmacyData?.original_name?.substring(0, 35) + "..."
                  : pharmacyData?.original_name}
              </span>
            </span>
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            <AdminButton
              background="#E7E5FF"
              width="100px"
              type="button"
              onClick={() => ShowClose()}
            >
              CLOSE
            </AdminButton>
            <AdminButton
              background="#2AD3E7"
              color="white"
              width="100px"
              type="button"
              disabled={verifiedList?.length == 0}
              onClick={verifyPharmacyHandler}
            >
              <span className="m-2">VERIFY</span>
            </AdminButton>
          </div>
        </ModalHeader>
        <ModalBody className="text-nowrap">
          <div className="modal-table-container">
            <div className="search-container d-flex justify-content-between ">
              <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                <img className="styled-SVG" src={searcher} color={""} />
                <input
                  className="search-input fs_14 fw_400 px-2"
                  type="text"
                  placeholder="Search Pharmacy here by Pharmancy Name..."
                  onChange={searchHandler}
                />
              </div>
            </div>
            <div
              className="table-container text-nowrap"
              style={{ minHeight: "36rem", maxHeight: "36rem" }}
            >
              <table className="main-table">
                <thead>
                  <tr className="table-rows color_747f9e">
                    <th className="table-heads" width={"6%"}>
                      <input
                        type={"checkbox"}
                        className="CheckBox my-1"
                        value="Full order forward from CFA"
                        name="ReimbursementCheck"
                        checked={isCheckAll}
                        onChange={checkAll}
                      />
                    </th>
                    <th className="table-heads " width={"8%"}>
                      SL No.
                    </th>
                    <th className="table-heads " width={"15%"}>
                      Pharmacy Name
                    </th>
                    <th className="table-heads " width={"15%"}>
                      Mobile Number
                    </th>
                    <th className="table-heads " width={"15%"}>
                      Email Address
                    </th>
                    <th className="table-heads " width={"22%"}>
                      Actions
                    </th>
                  </tr>
                </thead>
                {bulkUploadData?.length > 0 &&
                  bulkUploadData?.map((eachRow, index) => (
                    <PharmacyVerificationTableData
                      data={eachRow}
                      key={index}
                      index={index}
                      checkedPharmacy={checkedPharmacy}
                      isChecked={verifiedList.includes(eachRow)}
                    />
                  ))}
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <PharmacyBulkErrorModal
        show={pharmacyErrorModal}
        ShowClose={() => setPharmacyErrorModal(false)}
        pharmacyBluckStatusData={pharmacyBluckStatusData}
      />
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />

      <UnablePopup
        show={showUnablePopup}
        title={showUnableMsg}
      />

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default PharmacyDataVerificationModal;
