import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { pharmacyDivView } from "../../../redux/actions/manufacturerActions";
import { Spinner } from "react-bootstrap";

const PharmacyDivisionView = ({ setActivePharmacy, selectedPharmacyID }) => {
  const dispatch = useDispatch();
  const [pharmacyData, setPharmacyData] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(pharmacyData);

  useEffect(() => {
    setLoading(true);
    dispatch(
      pharmacyDivView(
        { pharmacy_id: selectedPharmacyID },
        onPharmacySuccess,
        onPharmacyError
      )
    );
  }, []);

  const onPharmacySuccess = (data) => {
    console.log(data);
    setLoading(false);
    setPharmacyData(data.data.data);
  };

  const onPharmacyError = (err) => {
    setLoading(false);
    console.log(err);
  };

  return (
    <>
      <div
        className="main-list-container my-2 py-1 d-flex justify-content-between flex-column"
        style={{ position: "relative" }}
      >
        <main className="d-flex flex-column w-100 main-container">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => setActivePharmacy(41)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
          <div className="search-container d-flex justify-content-between "></div>
          {loading == true ? (
            <div className="product-view-spinner">
              <Spinner />
            </div>
          ) : (
            <div className="scroll-container">
              <div className="form-width px-4 mx-2">
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Pharmacy code</label>
                  <p className="fs_14">
                    {pharmacyData?.code ? pharmacyData?.code : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Pharmacy Name</label>
                  <p className="fs_14">
                    {pharmacyData?.pharmacy_name
                      ? pharmacyData?.pharmacy_name
                      : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Pharmacy Type</label>
                  <p className="fs_14 text-capitalize">
                    {pharmacyData?.pharmacy_type ? pharmacyData?.pharmacy_type: "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Phone Number</label>
                  <p className="fs_14">{`+91 ${
                    pharmacyData?.mobile ? pharmacyData?.mobile : "--"
                  }`}</p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    Alternate Phone Number
                  </label>
                  <p className="fs_14">{`+91 ${
                    pharmacyData?.alternate_mobile
                      ? pharmacyData?.alternate_mobile
                      : "--"
                  }`}</p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">Email Address</label>
                  <p className="fs_14">
                    {pharmacyData?.email ? pharmacyData?.email : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">City</label>
                  <p className="fs_14">
                    {pharmacyData?.city?.name ? pharmacyData?.city?.name : "--"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default PharmacyDivisionView;
