import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { invoiceVerifiedList } from "../../redux/actions/invoiceVerificationAction";
import InvoiceVerificationListTableData from "./invoice-verification-list-table-data";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const InvoiceVerifiedListTable = ({ search }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const page_no = parseInt(params?.page);
    const [invoiceListData, setInvoiceListData] = useState([]);
    const [page, setPage] = useState(page_no ? page_no : 1);
    const [loading, setLoading] = useState(false);
    const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
    const [catchedErrorMsg, setCatchedErrorMsg] = useState();

    useEffect(() => {
        if (search !== null) {
            setPage(1);
            fetchInvoiceList();
        }
    }, [search]);

    useEffect(() => {
        setLoading(true);
        fetchInvoiceList();
    }, [page, search]);

    const fetchInvoiceList = () => {
        setLoading(true);
        dispatch(invoiceVerifiedList({ page, search }, onSuccess, onError));
    };

    const onSuccess = (data) => {
        setLoading(false);
        console.log(data.data.data);
        setInvoiceListData(data.data.data);
    };

    const closeCatchedErrPopup = () => {
        setCatchedErrorPopup(false);
    };

    const onError = (err) => {
        setLoading(false);
        console.log(err);
        setCatchedErrorPopup(true);
        setCatchedErrorMsg(err.data.message);
    };

    return (
        <>
            <TableContainer>
                <thead>
                    <tr className="table-rows color_747f9e">
                        <th className="table-heads " width={"4%"}>
                            #
                        </th>
                        <th className="table-heads " width={"12%"}>
                            order ID
                        </th>
                        <th className="table-heads " width={"14%"}>
                            Customer name
                        </th>
                        <th className="table-heads " width={"12%"}>
                            Field Force
                        </th>
                        <th className="table-heads " width={"12%"}>
                            Distributor name
                        </th>
                        <th className="table-heads " width={"12%"}>
                            Invoiced Date
                        </th>
                        <th className="table-heads " width={"8%"}>
                            Order Value
                        </th>
                        <th className="table-heads " width={"10%"}>
                            Order Status
                        </th>
                        <th className="table-heads " width={"8%"}>
                            Verification
                        </th>
                        <th className="table-heads " width={"12%"}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                    {loading == true ? (
                        <div className="list-spinner-container">
                            <LoadingSpinnerTable show={loading} />
                        </div>
                    ) : invoiceListData?.invoices?.length > 0 ? (
                        <>
                            {invoiceListData?.invoices?.map((eachRow, index) => (
                                <InvoiceVerificationListTableData
                                    data={eachRow}
                                    key={index}
                                    index={index}
                                    fetchInvoiceList={fetchInvoiceList}
                                    currentPage={invoiceListData?.pagination?.currentPage}
                                    viewPath={"verified-invoices"}
                                />
                            ))}
                        </>
                    ) : (
                        <tr>
                            <td colSpan="1" className="text-center no-data-container fs_16">
                                <span>~ No Data Found ~</span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableContainer>
            <div className={loading == true ? `loading-event` : ""}>
                {invoiceListData?.pagination?.totalPages > 0 && (
                    <TablePagination
                        data={invoiceListData?.pagination}
                        nextFunc={() => {
                            navigate(
                                `/support/verified-invoices/${invoiceListData?.pagination?.currentPage + 1
                                }`
                            );
                            setPage(page + 1);
                        }}
                        prevFunc={() => {
                            navigate(
                                `/support/verified-invoices/${invoiceListData?.pagination?.currentPage - 1
                                }`
                            );
                            setPage(page - 1);
                        }}
                    />
                )}
            </div>
            <ErrorPopup
                show={catchedErrorPopup}
                title={catchedErrorMsg}
                handleClose={closeCatchedErrPopup}
            />
        </>
    );
};

export default InvoiceVerifiedListTable;
