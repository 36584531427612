import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  geOrdersReportList,
  gevOrdersList,
} from "../../../../redux/actions/godsEyeViewActions";
import TablePagination from "../../../../components/Pagination/tablePagination";
import LoadingSpinner from "../../../../components/utilites/loaderSpinningContainer";
import searcher from "../../../../assets/icons/searchInput.svg";
import UpArrow from "../../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../../assets/icons/filterArrowDown.svg";
import moment from "moment";
import OrderTableData from "./orderTableData";
import exportFromJSON from "export-from-json";
import ErrorPopup from "../../../../components/popups/ErrorPopup";

const OrdersList = ({ }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [ordersData, setordersData] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [order_value_from, setOrder_value_from] = useState(null);
  const [order_value_to, setOrder_value_to] = useState(null);
  const [order_date_from, setOrder_date_from] = useState(null);
  const [order_date_to, setOrder_date_to] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const [openInvStatus, setOpenInvStatus] = useState(false);
  const [invStatus, setInvStatus] = useState('');

  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState([]);
  const [statusCleared, setStatusCleared] = useState('')
  const [filteredStatusOptions, setFilteredStatusOptions] = useState([]);


  const vendor_id = params?.id.split("&")?.[0];
  const OrderValueSubmit = () => {
    setLoading(true);
    let invoice_verified = invStatus;
    let order_status = status.join(",");
    setPage(1);
    dispatch(
      gevOrdersList(
        { vendor_id, order_value_from, order_value_to, search, page, invoice_verified, order_status },
        onSuccess,
        onError
      )
    );
    setOpenOrder(false);
  };
  const OrderDateSubmit = () => {
    let invoice_verified = invStatus;
    let order_status = status.join(",");
    setPage(1);
    dispatch(
      gevOrdersList(
        { vendor_id, order_date_from, order_date_to, search, page, invoice_verified, order_status },
        onSuccess,
        onError
      )
    );
    setOpenDate(false);
  };
  useEffect(() => {
    setLoading(true);
    let invoice_verified = invStatus;
    let order_status = status.join(",");
    dispatch(
      gevOrdersList(
        {
          vendor_id,
          order_date_from,
          order_date_to,
          order_value_from,
          order_value_to,
          search,
          page,
          invoice_verified,
          order_status
        },
        onSuccess,
        onError
      )
    );
  }, [search, page, invStatus]);

  useEffect(() => {
    setLoading(true);
    setPage(1)
    let invoice_verified = invStatus;
    dispatch(
      gevOrdersList(
        {
          vendor_id,
          order_date_from,
          order_date_to,
          order_value_from,
          order_value_to,
          search,
          page,
          invoice_verified
        },
        onSuccess,
        onError
      )
    );
  }, [invStatus]);



  const onSuccess = (data) => {
    setLoading(false);
    console.log(data);
    setordersData(data.data.data, "bvcdrtyuikmnbvcxdsa");
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setShowErrorPopup(true);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
  };

  const orderValueClear = () => {
    setOrder_value_from(null);
    setOrder_value_to(null);
    dispatch(gevOrdersList({ vendor_id, search, page }, onSuccess, onError));
    setOpenOrder(false);
  };
  const orderDateClear = () => {
    setOrder_date_from(null);
    setOrder_date_to(null);
    dispatch(gevOrdersList({ vendor_id, search, page }, onSuccess, onError));
    setOpenDate(false);
  };

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(geOrdersReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "Order ID": data?.order_id ? data?.order_id : "--",
          "Customer Name":
            data?.customer_type === "doctor"
              ? data?.doctor?.name
              : data?.pharmacy_type == "dispensing" ||
                data?.customer_type !== "doctor"
                ? data?.pharmacy?.pharmacy_name
                : data?.pharmacy_type == "dispensing"
                  ? "Dispensing"
                  : data?.pharmacy_type == "self"
                    ? data?.doctorSelfPharmacy?.pharmacy_name
                    : data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name,
          "Order Date": data?.createdAt
            ? moment(data?.createdAt).format("DD MMM YYYY, HH:mm A")
            : "--",
          "Order Value": data?.total
            ? `₹ ${parseFloat(data?.total).toFixed(2)}`
            : "--",
          "Order Status": data?.status ? data?.status : "--",
          "Invoice Status": data?.invoice_status ? data?.invoice_status : "--",
          "PO Status": data?.po_status ? data?.po_status : "--",
          "Reason For Rejection": data?.rejectionReason?.title
            ? data?.rejectionReason?.title
            : "--",
          "Rejected By":
            (data?.rejected_by_user_type == "distributor" &&
              data?.rejectOrderbyDistributor?.firm_name) ||
            (data?.rejected_by_user_type == "field_force_user" &&
              data?.rejectOrderbyFieldForce?.firm_name) ||
            (data?.rejected_by_user_type == "vendor" &&
              data?.rejectOrderbyVendor?.firm_name) ||
            (data?.rejected_by_user_type == "doctor" &&
              data?.rejectOrderbyDoctor?.firm_name) ||
            "--",
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setShowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  useEffect(() => {
    if (statusCleared == "cleared") {
      console.log(statusCleared, "statusClearedstatusCleared");
      setPage(1);
      dispatch(
        gevOrdersList(
          {
            vendor_id,
            order_date_from,
            order_date_to,
            order_value_from,
            order_value_to,
            search,
            page
          },
          onSuccess,
          onError
        )
      );
      setStatusCleared('')
    }
  }, [statusCleared]);

  const handleStatusInputChange = (e) => {
    const inputValue = e.target.value;
    const filtered = orderStatusOptions.filter(option => option.label.toLowerCase().includes(inputValue));
    setFilteredStatusOptions(filtered);
  };

  const applyStatusFilter = () => {
    let order_status = status.join(",");
    setPage(1);
    dispatch(
      gevOrdersList(
        {
          vendor_id,
          order_date_from,
          order_date_to,
          order_value_from,
          order_value_to,
          search,
          page,
          order_status
        },
        onSuccess,
        onError
      )
    );
  };

  return (
    <div className="mx-5 px- my-3 mt-4">
      <div className="d-flex justify-content-between">
        <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
          <img className="styled-SVG" src={searcher} color={""} />
          <input
            className="search-input fs_14 fw_400 text-dark px-2"
            type="text"
            placeholder="Search orders here by order ID..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          <button
            className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
            type="button"
            onClick={() => exportTableCsv()}
          >
            download
          </button>
        </div>
      </div>
      <div
        className="manufacturer-product-table-container"
        style={{ height: "33.5rem", maxWidth: "100%" }}
      >
        <div style={{ height: "32rem" }}>
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads " width={"4%"}>
                  #
                </th>
                <th className="table-heads " width={"10%"}>
                  Order ID
                </th>
                <th className="table-heads " width={"10%"}>
                  Customer name
                </th>
                <th className="table-heads text-nowrap " width={"12%"}>
                  <span
                    onClick={() => {
                      setOpenDate(!openDate);
                      setOpenOrder(false);
                    }}
                    className="cursor_pointer"
                  >
                    Order Date
                    {openDate ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className=" mx-1" />
                    )}
                  </span>
                  {openDate ? (
                    <div className=" position-relative">
                      <div className=" position-absolute order-container">
                        <div className=" d-flex my-3">
                          <div className="mx-3">
                            <label className="fn_Nunito color_262c3d ">
                              From
                            </label>
                            <div className=" d-flex mt-1 ">
                              <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                <input
                                  type="date"
                                  className="  amount_input  py-1  ms-1"
                                  placeholder="Select Date"
                                  value={order_date_from && order_date_from}
                                  onfocus="(this.type = 'date')"
                                  id="date"
                                  onChange={(e) =>
                                    setOrder_date_from(e.target.value)
                                  }
                                />

                                {/* <img src={date} /> */}
                              </div>
                            </div>
                          </div>
                          <div className="me-3">
                            <label className="fn_Nunito color_262c3d ">
                              To
                            </label>
                            <div className=" d-flex mt-1">
                              <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                <input
                                  type="date"
                                  className="  amount_input  py-1  ms-1"
                                  placeholder=" Selct Date"
                                  value={order_date_to && order_date_to}
                                  onChange={(e) =>
                                    setOrder_date_to(e.target.value)
                                  }
                                />
                                {/* <img src={date} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-end mx-3 gap-3">
                            <button
                              className="filter-clear-btn px-3 py-1 fs_14"
                              onClick={orderDateClear}
                            >
                              clear
                            </button>
                            <button
                              className="filter-btn px-2 py-1 fs_14"
                              type="button"
                              onClick={OrderDateSubmit}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </th>
                <th className="table-heads text-nowrap" width={"8%"}>
                  <span
                    onClick={() => {
                      setOpenOrder(!openOrder);
                      setOpenDate(false);
                    }}
                    className="cursor_pointer"
                  >
                    Order Value
                    {openOrder ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className=" mx-1" />
                    )}
                  </span>

                  {openOrder ? (
                    <div className=" position-relative">
                      <div className=" position-absolute order-container">
                        <div className=" d-flex my-3">
                          <div className="mx-3">
                            <label className="fn_Nunito color_262c3d ">
                              From
                            </label>
                            <div className=" d-flex mt-1 ">
                              <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                <span className="fn_Nunito fs_14 fw_700 Medium_Purple ">
                                  ₹.
                                </span>
                                <input
                                  type="number"
                                  className="  amount_input  py-1  ms-1"
                                  placeholder="Enter amount"
                                  value={order_value_from && order_value_from}
                                  onChange={(e) =>
                                    setOrder_value_from(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="me-3">
                            <label className="fn_Nunito color_262c3d ">
                              To
                            </label>
                            <div className=" d-flex mt-1">
                              <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                <span className="fn_Nunito fs_14 fw_700 Medium_Purple ">
                                  ₹.
                                </span>
                                <input
                                  type="number"
                                  className="  amount_input  py-1  ms-1"
                                  placeholder="Enter amount"
                                  value={order_value_to && order_value_to}
                                  onChange={(e) =>
                                    setOrder_value_to(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-end mx-3 gap-3">
                            <button
                              className="filter-clear-btn px-3 py-1 fs_14"
                              onClick={orderValueClear}
                            >
                              clear
                            </button>
                            <button
                              className="filter-btn px-2 py-1 fs_14"
                              type="button"
                              onClick={OrderValueSubmit}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </th>
                <th className="table-heads text-nowrap" width={"10%"}>
                  Distributed by
                </th>
                <th className="table-heads text-nowrap" width={"12%"}>
                  <span
                    onClick={() => {
                      setOpenStatus(!openStatus);
                    }}
                  >
                    Order Status
                    {openStatus ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className="mx-1" />
                    )}
                  </span>
                  {openStatus ? (
                    <div className="position-relative">
                      <div className="city-main-container">
                        <div className="city-filter-form">
                          <div className="mx-2 my-1 pt-1">
                            <input
                              type={"text"}
                              placeholder={"search"}
                              className="filter-input w-100 py-1 px-2"
                              onChange={handleStatusInputChange}
                            />
                          </div>
                          <div
                            className="row"
                            style={{ height: "6.4rem", overflowY: "scroll" }}
                          >
                            {(filteredStatusOptions.length > 0 ? filteredStatusOptions : orderStatusOptions).map((item, index) => (
                              <div
                                className="col-5 d-flex align-items-center ms-4"
                                key={index}
                              >
                                <div>
                                  <span className="fs_14 my-1">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      checked={status.find(
                                        (ex) => ex == item.value
                                      )}
                                      className="ffCheck"
                                      onClick={() => {
                                        if (status.includes(item.value)) {
                                          setStatus((prev) =>
                                            prev.filter(
                                              (ex) => ex != item.value
                                            )
                                          );
                                        } else {
                                          setStatus((prev) => [
                                            ...prev,
                                            item.value,
                                          ]);
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                                <div className="mt-2 mx-2">
                                  <span className="color_747f9e fs_14">
                                    {item.label}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mx-3 gap-3">
                          <button
                            type="button"
                            className="filter-clear-btn px-3 py-1 fs_14"
                            // disabled={status.length < 1}
                            onClick={() => {
                              setStatus([]);
                              setOpenStatus(false);
                              setFilteredStatusOptions([]);
                              setStatusCleared("cleared")
                            }}
                          >
                            clear
                          </button>
                          <button
                            className="filter-btn px-2 py-1 fs_14"
                            onClick={() => {
                              setOpenStatus(!openStatus);
                              applyStatusFilter();
                            }}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  ) : null}
                </th>

                <th className="table-heads text-nowrap" width={"10%"}>
                  <span
                    onClick={() => setOpenInvStatus(!openInvStatus)}
                    className={openInvStatus ? "Medium_Purple" : ""}
                  >
                    Invoice Status
                    {openInvStatus ? (
                      <img src={UpArrow} className="mb-1 mx-1" />
                    ) : (
                      <img src={DownArrow} className=" mx-1" />
                    )}
                  </span>
                  {openInvStatus ? (
                    <div className=" position-relative">
                      <div className="position-absolute status-container d-flex gap-3 px-3">
                        <div className="d-flex align-items-center ">
                          <input
                            className="radio me-2"
                            type="radio"
                            id="State"
                            name="pending"
                            value={"pending"}
                            checked={invStatus == "pending"}
                            onClick={(e) => {
                              setOpenInvStatus(!openInvStatus);
                              setInvStatus(e.target.value);
                            }}
                          />
                          <span
                            for="State"
                            className={
                              invStatus === "pending"
                                ? "color_262c3d"
                                : "color_747f9e"
                            }
                          >
                            Pending
                          </span>
                        </div>
                        <div className="d-flex align-items-center ">
                          <input
                            className="radio me-2"
                            type="radio"
                            id="State"
                            name="verified"
                            value={"verified"}
                            checked={invStatus == "verified"}
                            onClick={(e) => {
                              setOpenInvStatus(!openInvStatus);
                              setInvStatus(e.target.value);

                            }}
                          />
                          <span
                            for="State"
                            className={
                              invStatus === "verified"
                                ? "color_262c3d"
                                : "color_747f9e"
                            }
                          >
                            Verified
                          </span>
                        </div>
                        <button
                          className="filter-btn px-2 py-0 my-2 fs_12"
                          style={{ height: "28px" }}
                          onClick={() => {
                            setInvStatus(null);
                            setOpenInvStatus(!openInvStatus);
                          }}
                        >
                          CLEAR
                        </button>
                      </div>
                    </div>
                  ) : null}
                </th>
                <th className="table-heads text-nowrap" width={"8%"}>
                  PO Status
                </th>
                <th className="table-heads text-nowrap" width={"15%"}>
                  Reason For Rejection
                </th>
                <th className="table-heads text-nowrap" width={"10%"}>
                  Rejected By
                </th>
                <th className="table-heads text-nowrap" width={"19%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="gev-spinner-container">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {ordersData?.orders?.length > 0 ? (
                    ordersData?.orders?.map((data, index) => (
                      <OrderTableData
                        data={data}
                        index={index}
                        currentPage={ordersData?.pagination?.currentPage}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center no-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {ordersData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={ordersData?.pagination}
          nextFunc={() => {
            setPage(ordersData?.pagination?.currentPage + 1);
          }}
          prevFunc={() => {
            setPage(ordersData?.pagination?.currentPage - 1);
          }}
        />
      )}
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </div>
  );
};

export default OrdersList;

export const orderStatusOptions = [
  { value: "acknowledged", label: "Acknowledged", color: "ack_status" },
  {
    value: "partially_acknowledged",
    label: "Partially Acknowledged",
    color: "color_2ad3e7 ",
  },
  { value: "fully_invoiced", label: "Fully Invoiced", color: "text_green" },
  {
    value: "partially_invoiced",
    label: "Partial Invoiced",
    color: "Medium_Purple",
  },
  { value: "pending", label: "Approval Pending", color: "Medium_Purple" },
  { value: "expired", label: "Expired", color: "text-danger" },
  { value: "cancelled", label: "Cancelled", color: "text-danger" },
  { value: "approval_failed", label: "Approval Failed", color: "text-danger" },
  { value: "approved", label: "Approved", color: "color_2ad3e7" },
  { value: "rejected", label: "Rejected", color: "text-danger" },
  { value: "hold", label: "Under Process", color: "text-warning" },
];
