import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import back from "../../../assets/icons/back.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import "../../super-admin/superUser.css";
import * as Yup from "yup";
import ErrorMsg from "../../../components/form/errorMsg";
import InputField from "../../../components/form/inputField/inputField";
import {
  permissionsRoleList,
  superAdminRoleDetail,
  superAdminRoleEdit,
} from "../../../redux/actions/superAdminActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";
import Select from "react-select";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const SuperUserRoleEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [adminViewData, setAdminViewData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [permission, setPermission] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [approvalLevel, setApprovalLevel] = useState('');

  useEffect(() => {
    setLoading(true);
    dispatch(
      superAdminRoleDetail(params.id, fetchDetailSuccess, fetchDetailError)
    );
    dispatch(permissionsRoleList(fetchPermissionSuccess, fetchPermissionError));
  }, []);

  const fetchDetailSuccess = (data) => {
    setLoading(false);
    setAdminViewData(data.data.data);
    console.log("sdwrgtwf", data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const fetchDetailError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const fetchPermissionSuccess = (data) => {
    console.log("permissions", data);
    const updatedPerissions = data.map((item) => ({
      ...item,
      read: false,
      write: false,
    }));
    setPermission(updatedPerissions);
  };

  const fetchPermissionError = (data) => {
    console.log(data);
  };

  const updatedData = permission.map((item) => {
    const matchingItem = adminViewData?.superAdminPermissions?.find(
      (d) => d.permission_id == item.id
    );
    console.log(matchingItem);
    if (matchingItem) {
      return {
        ...item,
        read: matchingItem.read,
        write: matchingItem.write,
      };
    }
    return item;
  });

  const initialValues = {
    role_id: adminViewData?.id || "",
    role_name: adminViewData?.role_name || "",
    role_level: adminViewData?.role_level || "",
    permissions: updatedData,
  };
  console.log(initialValues);
  console.log(updatedData);

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Required"),
    role_level: Yup.string().required("Required"),
    // permissions: Yup.array().min(1, `Required`).required("Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    dispatch(superAdminRoleEdit(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/super-user/role/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        (adminViewData?.role_id
          ? ` / ${adminViewData?.role_id} - edit`
          : " / edit")}
    </span>
  );

  const resetFunc = (setFieldValue) => {
    // setFieldValue("role_id", "");
    setFieldValue("role_name", adminViewData?.role_name);
    setFieldValue("role_level", adminViewData?.role_level);
    setFieldValue("permissions", updatedData);
  };
  const handleCheckboxChange = (key, field, setFieldValue) => {
    return (e) => {
      const isChecked = e.target.checked;
      setFieldValue(`permissions.${key}.${field}`, isChecked);
      if (field == "write" && isChecked) {
        setFieldValue(`permissions.${key}.read`, true);
      } else if (field == "write" && !isChecked) {
        setFieldValue(`permissions.${key}.read`, false);
      }
    };
  };
  return (
    <>
      <MainLayout breadcrumbData={Breadcrumb}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, values }) => (
            <Form>
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    onClick={() => resetFunc(setFieldValue)}
                    type="button"
                  >
                    RESET
                  </AdminButton>
                  <AdminButton
                    background="#2AD3E7"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              {loading == true ? (
                <div className="view-spinner-container">
                  <LoadingSpinnerTable show={loading} />
                </div>
              ) : (
                <div className="scrolling">
                  <div className="form-width ms-4 ps-3 py-1">
                    <div className="my-4 ">
                      <label className=" Neutral_Color fs_14">Role Id</label>
                      <p className="fs_14">{adminViewData?.role_id}</p>
                    </div>
                    <div className="my-4">
                      <InputCapitalField
                        id={"role_name"}
                        label={"Role Name"}
                        labelColor={"Neutral_Color"}
                        type={"text"}
                        placeholder={"Enter role name"}
                        name={"role_name"}
                        value={values.role_name}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {/* <div className="my-4">
                      <InputField
                        id={"role_level"}
                        label={"Role Level"}
                        labelColor={"Neutral_Color"}
                        type={"text"}
                        placeholder={"Enter role level"}
                        name={"role_level"}
                        value={values.role_level}
                      />
                    </div> */}
                    <div className="my-4">
                      <label className="color_262c3d fw_400 fs_14">Levels</label>
                      <Field>
                        {({ form, Field }) => (
                          <Select
                            options={options}
                            styles={customStyles}
                            {...Field}
                            placeholder={"Enter role level"}
                            id="role_level"
                            // value={values.role_level}
                            value={options.filter(
                              (s) => s.value == values.role_level
                            )}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "role_level",
                                selectedOption.value
                              );
                              setApprovalLevel(selectedOption.value);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="role_level" />
                    </div>

                    <div className="my-0">
                      <label className="Neutral_Color fs_14">Permission</label>
                    </div>
                    <div className="my-1 d-flex  label-container align-items-center justify-content-start ">
                      <div className="col-5">
                        <label className="color_b0b3c7 fs_14">Models</label>
                      </div>
                      <label className="color_b0b3c7 fs_14 ms-4 ps-2">
                        Read
                      </label>
                      <label className="color_b0b3c7 fs_14 ms-4 ps-2">
                        Write
                      </label>
                    </div>

                    {initialValues?.permissions.map((ele, id, index) => (
                      <div
                        key={ele.id}
                        className="d-flex my-1 label-container align-items-center justify-content-start"
                      >
                        <div className="col-6">
                          <label className="fs_14">{ele.label}</label>
                        </div>
                        <div>
                          <span className="fs_14 my-1">
                            <Field
                              className="CheckBox"
                              type="checkbox"
                              name={`permissions.${id}.read`}
                              disabled={values?.permissions[id]?.write}
                              onChange={handleCheckboxChange(
                                id,
                                "read",
                                setFieldValue
                              )}
                            />
                          </span>
                        </div>
                        <div className="ms-5">
                          <span className="fs_14 my-1">
                            <Field
                              className="CheckBox"
                              type="checkbox"
                              name={`permissions.${id}.write`}
                              onChange={handleCheckboxChange(
                                id,
                                "write",
                                setFieldValue
                              )}
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                    <ErrorMsg name={"permissions"} />
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </MainLayout>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default SuperUserRoleEdit;

const options = [
  { value: "L1", label: "L1" },
  { value: "L2", label: "L2" },
  { value: "L3", label: "L3" },
];

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    height: "43px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
