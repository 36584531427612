import React from "react";

const ManufacturerSvg = ({
  fill = "#B0B3C7",
  width = "14px",
  height = "14px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1055_21579)">
        <path
          d="M11.0834 9.91662C10.4689 9.9164 9.86526 9.75424 9.33337 9.44645C8.80149 9.75424 8.19789 9.9164 7.58337 9.91662H6.41671C5.80135 9.91584 5.1973 9.75113 4.66671 9.43945C4.13614 9.75118 3.53208 9.91589 2.91671 9.91662H2.33337C1.71832 9.91641 1.11437 9.75274 0.583374 9.44237V12.25C0.583374 12.7141 0.767748 13.1592 1.09594 13.4874C1.42413 13.8156 1.86925 14 2.33337 14H11.6667C12.1308 14 12.576 13.8156 12.9041 13.4874C13.2323 13.1592 13.4167 12.7141 13.4167 12.25V9.44295C12.8856 9.75289 12.2817 9.91634 11.6667 9.91662H11.0834Z"
          fill={fill}
        />
        <path
          d="M9.91667 0V3.5H8.75V0H5.25V3.5H4.08333V0H1.28333L0.014 5.70675L0 6.41667C0 7.03551 0.245833 7.629 0.683418 8.06658C1.121 8.50417 1.71449 8.75 2.33333 8.75H2.91667C3.2491 8.74893 3.57742 8.67639 3.87937 8.53729C4.18131 8.3982 4.44981 8.1958 4.66667 7.94383C4.88352 8.1958 5.15203 8.3982 5.45397 8.53729C5.75591 8.67639 6.08423 8.74893 6.41667 8.75H7.58333C7.91456 8.75017 8.24201 8.67965 8.54379 8.54314C8.84558 8.40663 9.11476 8.20728 9.33333 7.95842C9.55191 8.20728 9.82108 8.40663 10.1229 8.54314C10.4247 8.67965 10.7521 8.75017 11.0833 8.75H11.6667C12.2855 8.75 12.879 8.50417 13.3166 8.06658C13.7542 7.629 14 7.03551 14 6.41667V5.83333L12.7167 0H9.91667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1055_21579">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ManufacturerSvg;
