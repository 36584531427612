import React from "react";
import "../../modules/manufacturer/manufacturer.css";
import { useNavigate, useParams } from "react-router-dom";

const GevSideTopBar = ({ SubscriptionpathName = "subscibe-list" }) => {
  const navigate = useNavigate();
  const params = useParams();
  const vendorId = params?.id.split("&")?.[0];
  const activePath = params?.id.split("&")?.[1];
  const activeSubPath = params?.id.split("&")?.[2];
  const subId = params?.id.split("&")?.[3];
  return (
    <div>
      <div>
        {/* SIDEBAR */}
        <div className="w-100 view-sidebar-container d-none d-xxl-block">
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "manufacturer-details"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&manufacturer-details`
                );
                localStorage.removeItem("activeInvoice");
              }}
            >
              Manufacturer Details
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "designations"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&designations&list&1`
                );
              }}
            >
              Designations
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "headQuarters"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&headQuarters&list&1`
                );
              }}
            >
              Head Quarters
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "divisions"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&divisions&list&1`
                );
              }}
            >
              Divisions
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "products"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&products&list&1`
                );
              }}
            >
              Products
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "fieldForce"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&fieldForce&list&1`
                );
              }}
            >
              Field Force USERS
            </button>
          </div>

          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "orders"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&orders&list&1`
                );
              }}
            >
              orders
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "cfa"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&cfa&list&1`
                );
              }}
            >
              CFA
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "po"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&po&1`
                );
              }}
            >
              Purchase order
            </button>
          </div>
          <div className="text-nowrap my-1">
            <button
              className={
                activePath == "invoices"
                  ? "sidbarTabActive px-4 my-2 fw_500"
                  : "sidbarTab text-start color_747f9e px-4 my-2 fw_500"
              }
              onClick={() => {
                navigate(
                  `/manufacturer/${SubscriptionpathName}/view/${vendorId}&invoices&uninvoced&1`
                );
              }}
            >
              Invoices
            </button>
          </div>
        </div>
        {/* TOPBAR */}
        <div className="d-xxl-none">
          <div className="d-flex my-1 py-2 " style={{ overflowX: "scroll" }}>
            <div className="text-nowrap my-1">
              <button
                className={
                  activePath == "manufacturer-details"
                    ? "topbarTabActive mx-2"
                    : "topTab mx-2"
                }
                onClick={() => {
                  navigate(
                    `/manufacturer/${SubscriptionpathName}/view/${vendorId}&manufacturer-details`
                  );
                }}
              >
                Manufacturer Details
              </button>
            </div>
            <div className="text-nowrap my-1">
              <button
                className={
                  activePath == "designations"
                    ? "topbarTabActive mx-2"
                    : "topTab mx-2"
                }
                onClick={() => {
                  navigate(
                    `/manufacturer/${SubscriptionpathName}/view/${vendorId}&designations&list&1`
                  );
                  localStorage.removeItem("activeInvoice");
                }}
              >
                Designations
              </button>
            </div>
            <div className="text-nowrap my-1">
              <button
                className={
                  activePath == "headQuarters"
                    ? "topbarTabActive mx-2"
                    : "topTab mx-2"
                }
                onClick={() => {
                  navigate(
                    `/manufacturer/${SubscriptionpathName}/view/${vendorId}&headQuarters&list&1`
                  );
                }}
              >
                Head Quarters
              </button>
            </div>

            <div className="text-nowrap my-1">
              <button
                className={
                  activePath == "divisions"
                    ? "topbarTabActive mx-2"
                    : "topTab mx-2"
                }
                onClick={() => {
                  navigate(
                    `/manufacturer/${SubscriptionpathName}/view/${vendorId}&divisions&list&1`
                  );
                }}
              >
                Divisions
              </button>
            </div>
            <div className="text-nowrap my-1">
              <button
                className={
                  activePath == "products"
                    ? "topbarTabActive mx-2"
                    : "topTab mx-2"
                }
                onClick={() => {
                  navigate(
                    `/manufacturer/${SubscriptionpathName}/view/${vendorId}&products&list&1`
                  );
                }}
              >
                Products
              </button>
            </div>
            {/*       <div className="text-nowrap my-1">
                  <button
                    className={
                      activeTab == 5 ? "topbarTabActive mx-2" : "topTab mx-2"
                    }
                    onClick={() => {
                      setActiveTab(5);
                    }}
                  >
                    Purchase order
                  </button>
                </div>
                <div className="text-nowrap my-1">
                  <button
                    className={
                      activeTab == 6 ? "topbarTabActive mx-2" : "topTab mx-2"
                    }
                    onClick={() => {
                      setActiveTab(6);
                      setactiveInvoice(1);
                    }}
                  >
                    Invoices
                  </button>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GevSideTopBar;
