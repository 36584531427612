import React, { useEffect, useState } from "react";
import "./forgot-reset-password.css";
import subLogo from "../../../assets/images/auth/forgotPasswordIamge.png";
import jiviniLogo from "../../../assets/images/auth/jiviniLogo.png";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../redux/actions/authActions";
import { Spinner } from "react-bootstrap";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [userNotExist, setUserNotExist] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    email: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    dispatch(forgetPassword(values.email, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setLoading(false);
    navigate("/password-email-sent");
  };

  const onSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
    console.log(data);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
    setUserNotExist(true);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const FormEnableCheck = () => {
    const {
      values: { email },
    } = useFormikContext();
    useEffect(() => {
      if (email.trim() != "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [email]);
    return null;
  };

  return (
    <div className="forgot-password-container fn_Nunito d-flex flex-column flex-lg-row justify-content-center">
      <div className="">
        <img
          src={jiviniLogo}
          alt="website logo"
          className="asscents-logo-image d-lg-none"
        />
      </div>
      <div className="left-view pb-4 pb-lg-0  d-flex flex-column justify-content-center">
        <img
          src={subLogo}
          alt="Asscents Website"
          className="forgotpassword-img align-self-center"
        />
      </div>
      <div className="form-view justify-content-center d-flex flex-column mt-2 mt-lg-0">
        <div className=" forgot-password-form-container  col-10  col-lg-7 col-xl-7 mx-auto  d-flex text-start flex-column p-2">
          <img
            src={jiviniLogo}
            alt="website logo"
            className="asscents-logo-image d-none d-lg-block "
          />
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <FormEnableCheck />
              <h1 className="fw_700 fs_22">Forgot Password</h1>
              <div className="w-100 mt-3">
                <label className="w-100 mx-1 label-input fs_16">
                  Email Address
                </label>
                <Field name={"email"}>
                  {({ field, meta }) => (
                    <input
                      {...field}
                      placeholder="Enter your email address"
                      id={"email"}
                      className={"w-100 input-field"}
                    />
                  )}
                </Field>
                <ErrorMsg name="email" />
              </div>

              <button
                className="verification-button fs_16 fs_md_14 text-center mt-2 py-1 px-2"
                type="submit"
                disabled={loading || disable}
              >
                {loading && <Spinner size="sm" />} Get Reset link
              </button>
              {disable ? (
                <>
                  <p className="support-element">
                    Do you know your password?
                    <span
                      onClick={() => navigate("/")}
                      className="span-support cursor_pointer ms-1"
                    >
                      Login
                    </span>
                  </p>
                </>
              ) : (
                <>
                  {userNotExist && (
                    <p className="support-element">
                      Not Registered ?
                      <span
                        onClick={() => navigate("/signup")}
                        className="span-support cursor_pointer"
                      >
                        Signup
                      </span>
                    </p>
                  )}
                </>
              )}
            </Form>
          </Formik>
          <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
          <ErrorPopup
            show={showErrorPopup}
            title={showErrorMsg}
            handleClose={closeErrorPopup}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
