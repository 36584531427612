import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/deleteModal";
import EnableDisableModal from "../../../components/modals/enableDisableModal";
import {
  customerDelete,
  customerEnableDisable,
} from "../../../redux/actions/customerActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const CustomersListTableData = ({
  data,
  index,
  fetchCustomerList,
  currentPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [typeData, setTypeData] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Customer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  const enableDisableFunc = () => {
    dispatch(
      customerEnableDisable(
        typeData,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    fetchCustomerList();
  };

  const onEnableDisableSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const delFunc = () => {
    dispatch(customerDelete(typeData, onDelSuccess, onDeleteError));
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup1(false);
    fetchCustomerList();
    setTypeData(null);
    setDeleteShow(false);
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc1, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const onDeleteError = (err) => {
    console.log(err);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };

  return (
    <tr className="table-rows1 " key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.code
            ? data?.code
            : "--"
          : data?.pharmacyDoctors?.length > 0
            ? data?.pharmacyDoctors?.[0]?.doctor?.code
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.doctorAttachPharmacy?.pharmacy?.code
            ? data?.doctorAttachPharmacy?.pharmacy?.code
            : "--"
          : data?.code
            ? data?.code
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.name
            ? `DR. ${data?.name.toUpperCase()}`
            : "--"
          : data?.pharmacyDoctors?.length > 0
            ? `DR. ${data?.pharmacyDoctors?.[0]?.doctor?.name.toUpperCase()}`
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor" && data?.is_pharmacy
          ? data?.pharmacy_type == "self"
            ? data?.doctorSelfPharmacy?.pharmacy_name
              ? data?.doctorSelfPharmacy?.pharmacy_name.toUpperCase()
              : "--"
            : data?.pharmacy_type == "attach" &&
              data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name
              ? data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name.toUpperCase()
              : "--"
          : data?.customer_type == "pharmacy" && data?.pharmacy_name
            ? data?.pharmacy_name
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.vendorDivision?.name ? data?.vendorDivision?.name : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.city?.name ? data?.city?.name : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.pharmacy_type
          ? data?.pharmacy_type.charAt(0).toUpperCase() +
          data?.pharmacy_type.slice(1)
          : "--"}
      </td>
      {data?.status === true ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : (
        <td className="table-each-row-data text_red">Inactive</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/customers/customer/view/${data.id}`, {
                state: {
                  user_type: data?.customer_type,
                  pharmacy_id: data?.customer_type !== "doctor" && data.id,
                  doctor_id: data?.customer_type == "doctor" && data.id,
                },
              })
            }
          >
            <span className=""> View</span>
          </button>
          {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
            <>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className="button-edit1"
                type="button"
                disabled={!data?.pharmacy_type}
                onClick={() =>
                  navigate(`/customers/customer/edit/${data.id}`, {
                    state: {
                      user_type: data?.customer_type,
                      pharmacy_id: data?.customer_type !== "doctor" && data.id,
                      doctor_id: data?.customer_type == "doctor" && data.id,
                      pharmacy_type: data?.pharmacy_type,
                    },
                  })
                }
              >
                Edit
              </button>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className={
                  data.status === true ? `button-disable` : `button-enable`
                }
                type="button"
                color={"green"}
                onClick={() => {
                  setOnEnableShow(true);
                  setTypeData({
                    user_type: data?.customer_type,
                    pharmacy_id: data?.customer_type !== "doctor" && data.id,
                    doctor_id: data?.customer_type == "doctor" && data.id,
                  });
                }}
              >
                {data.status === true ? "disable" : "enable"}
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      </td>
      <EnableDisableModal
        enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={""}
        title={data.code !== null ? `customer- ${data.code} ?` : "customer ?"}
        status={data.status}
        show={onEnableShow}
      />
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </tr>
  );
};

export default CustomersListTableData;
