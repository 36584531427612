import React from "react";
import InvoicedPOList from "./invoicedPOList";
import UninvoicedList from "./uninvoicedList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoicedPOView from "./invoicedPOView";

const InvoiceList = ({
  setactiveInvoice,
  setActiveInvoiceId,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const activeSubPath = params?.id.split("&")?.[2];
  const activeInvoicePath = params?.id.split("&")?.[3];
  const vendorId = params?.id.split("&")?.[0];
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  console.log(activeSubPath, "sss");
  return (
    <div className="m-3 px-3 py-2">
      <div className="d-fex">
        <div className="text-nowrap my-1">
          <button
            className={
              activeSubPath == "uninvoced"
                ? "topbarTabActive mx-2 fw_600"
                : "topTab mx-2"
            }
            onClick={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendorId}&invoices&uninvoced&1`
              );
            }}
          >
            Uninvoiced PO
          </button>
          <button
            className={
              activeSubPath == "invoced"
                ? "topbarTabActive mx-2 fw_600"
                : "topTab mx-2"
            }
            onClick={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendorId}&invoices&invoced&list&1`
              );
            }}
          >
            Invoiced PO
          </button>
        </div>
        <div>{activeSubPath == "uninvoced" && <UninvoicedList />}</div>
        <div>
          {activeSubPath == "invoced" && activeInvoicePath == "list" && (
            <InvoicedPOList
              setactiveInvoice={setactiveInvoice}
              setActiveInvoiceId={setActiveInvoiceId}
            />
          )}
          {activeSubPath == "invoced" && activeInvoicePath == "view" && (
            <InvoicedPOView />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceList;
