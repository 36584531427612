import React from "react";
import "./table.css";

const TableContainer = ({ children }) => {
  return (
    <div className="table-container text-nowrap">
      <table className="main-table">
         {children}
      </table>
    </div>
  );
};

export default TableContainer;
