import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import mail from "../../assets/icons/mail.png";
import "../modals/modal.css";

const MailSent = ({ show }) => {
  return (
    <Modal
      centered
      show={show}
      // onHide={handleClose}
      dialogClassName="my-modal"
    >
      <ModalBody className="m-4 p-4">
        <div className="p-2">
          <img
            src={mail}
            alt="mail"
            className=" mx-auto d-block"
            style={{ height: "30px" }}
          />
          <p
            className="fn_Nunito fw_600 fs_22 color_262c3d my-2 px-0"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            Mail Sent Successfully
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MailSent;
