import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import TableContainer from "../../../layout/table-container/table-container";
import "../manufacturerModal.css";
import InvoiceMailSent from "./invoiceMailSent";
import { invoiceMail } from "../../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SuccessPopup from "../../../popups/SuccessPopup";
import ErrorPopup from "../../../popups/ErrorPopup";

const GenerateInvoiceMailSendModal = ({
  show,
  handleClose,
  generatedData,
  manufacturerViewData,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [mailSentShow, setMailSentShow] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const vendorId = params?.id.split("&")?.[0];

  // const mailData = generatedData?.invoiceProducts?.map(
  //   (item) => item?.order_id
  // );

  const onSubmitGenerate = () => {
    dispatch(
      invoiceMail(
        {
          vendor_id: vendorId,
          vendor_invoice_po_id: generatedData?.invoice?.id,
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
  }

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="invoice-table-modal"
        contentClassName="PO-table-container fn_Nunito p-2"
        size="xl"
      >
        <ModalBody>
          <label className="fn_Nunito fw_700 fs_24 color_262c3d">
            Generate Invoice
          </label>
          <br />
          <span className="fs_14 color_747f9e fn_Nunito">
            Lorem ipsum dolor sit amet consectetur. Nisi lectus mattis elit
            quam.
          </span>

          <div className="modal-table-container mt-4">
            <TableContainer>
              <thead>
                <tr className="table-rows color_747f9e">
                  <th className="table-heads " width={"3%"}>
                    SL No.
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Product ID
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Product Name
                  </th>
                  <th className="table-heads " width={"10%"}>
                    Qty
                  </th>
                  <th className="table-heads " width={"10%"}>
                    PTR
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Product Total Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {generatedData?.invoiceProducts?.map((item, index) => (
                  <tr className="table-rows1 ">
                    <td className="table-each-row-data">{index + 1}</td>
                    <td className="table-each-row-data">
                      {item?.product?.product_id}
                    </td>
                    <td className="table-each-row-data">
                      {item?.product?.product_name}
                    </td>
                    <td className="table-each-row-data">{item?.quantity}</td>
                    <td className="table-each-row-data">{item?.ptr}</td>
                    <td className="table-each-row-data">₹ {item?.price}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
            <div
              style={{ backgroundColor: " #2AD3E7", borderRadius: "7px" }}
              className="py-2 d-flex justify-content-between"
            >
              <label className="mx-sm-5 mx-2 text-white fw_500">
                Total Invoice Value
              </label>
              <label className="mx-sm-5 mx-2 text-white fw_500 pe-lg-5">
                ₹ {generatedData?.invoice?.invoiced_value}
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="close-btn px-5 py-2 fs_14 fs_14 fs_sm_12  me-2"
              onClick={handleClose}
            >
              <span className="mx-4">CLOSE</span>
            </button>
            <button
              type="button"
              className="filter-btn px-5 py-2 fs_14 fs_sm_12 ms-2 fw_500"
              onClick={() => {
                handleClose();
                setMailSentShow(true);
                onSubmitGenerate();
              }}
            >
              DOWNLOAD & SEND
            </button>
          </div>
        </ModalBody>
      </Modal>
      <InvoiceMailSent
        manufacturerViewData={manufacturerViewData}
        show={mailSentShow}
        handleClose={() => setMailSentShow(false)}
      />
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default GenerateInvoiceMailSendModal;
