import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { gevFieldForceView } from "../../../redux/actions/godsEyeViewActions";
import { Spinner } from "react-bootstrap";

const FieldforceDivisionView = ({ setActiveFF, data }) => {
  const dispatch = useDispatch();
  const [ffUserData, setFfUserData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(gevFieldForceView(data, onffSuccess, onffError));
  }, []);

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data);
    setFfUserData(data.data.data);
  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };
  return (
    <>
      <div
        className="main-list-container my-2 py-1 d-flex justify-content-between flex-column"
        style={{ position: "relative" }}
      >
        <main className="d-flex flex-column w-100 main-container">
          <div
            className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
            onClick={() => setActiveFF(21)}
          >
            <img src={back} alt="back_btn" />
            <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
              BACK
            </span>
          </div>
          <div className="search-container d-flex justify-content-between "></div>
          {loading == true ? (
            <div className="product-view-spinner">
              <Spinner />
            </div>
          ) : (
            <div className="scroll-container">
              {" "}
              <div className="form-width px-4 mx-2">
                <div className="my-4">
                  <label className=" color_747F9E fs_14">User ID</label>
                  <p className="fs_14">
                    {ffUserData?.user_id ? ffUserData?.user_id : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Field Force Id</label>
                  <p className="fs_14">
                    {ffUserData?.code ? ffUserData?.code : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    Company Employee ID
                  </label>
                  <p className="fs_14">
                    {ffUserData?.employee_id ? ffUserData?.employee_id : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14 text-capitalize">
                    Employee Name
                  </label>
                  <p className="fs_14">
                    {ffUserData?.name ? ffUserData?.name : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Phone Number</label>
                  <p className="fs_14">{`+91 ${ffUserData?.mobile ? ffUserData?.mobile : "--"
                    }`}</p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">Email Address</label>
                  <p className="fs_14">
                    {ffUserData?.email ? ffUserData?.email : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Designation</label>
                  <p className="fs_14 text-capitalize">
                    {ffUserData?.fieldForceDesignation?.name
                      ? ffUserData?.fieldForceDesignation?.name
                      : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" Neutral_Color fs_14">
                    {" "}
                    Designation Approval level
                  </label>
                  <p className="fs_14 text-capitalize">
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L1" && "Level 1"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L2" && "Level 2"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L3" && "Level 3"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L4" && "Level 4"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L5" && "Level 5"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L6" && "Level 6"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L7" && "Level 7"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L8" && "Level 8"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "L9" && "Level 9"}
                    {ffUserData?.fieldForceDesignation?.approval_level ==
                      "admin" && "Admin"}
                  </p>
                </div>
                {ffUserData?.fieldForceDesignation?.approval_level !=
                  "admin" && (
                    <>
                      <div className="my-4">
                        <label className=" color_747F9E fs_14">
                          Division Name
                        </label>
                        <p className="fs_14">
                          {ffUserData?.fieldForceDivisions?.length > 0 &&
                            ffUserData?.fieldForceDivisions?.map(
                              (ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.vendorDivision?.name}
                                  </span>
                                );
                              }
                            )}
                        </p>
                      </div>
                      <div className="my-4">
                        <label className=" color_747F9E fs_14">
                          Head Quaters
                        </label>
                        <p className="fs_14">
                          {ffUserData?.fieldForceUserHeadQuaters?.length > 0 &&
                            ffUserData?.fieldForceUserHeadQuaters?.map(
                              (ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.headQuater?.name}
                                  </span>
                                );
                              }
                            )}
                        </p>
                      </div>
                      <div className="my-4">
                        <label className=" color_747F9E fs_14">
                          Reporting Manager
                        </label>
                        <p className="fs_14 text-capitalize">
                          {ffUserData?.reportingManager?.name
                            ? ffUserData?.reportingManager?.name
                            : "--"}
                        </p>
                      </div>
                    </>
                  )}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default FieldforceDivisionView;
