import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../../components/layout/table-container/table-container";
import TablePagination from "../../../components/Pagination/tablePagination";
import { superAdminRoleVersionList } from "../../../redux/actions/superAdminActions";
import SuperUserRoleVersionTableData from "../../../modules/super-admin/superUserRole/super-user-role-version-table-data";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const SuperUserRoleVersionTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [superVersionRoleListData, setSuperVersionRoleListData] = useState([]);
  const [page, setPage] = useState(page_no);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(superAdminRoleVersionList({ search, page }, onSuccess, onError));
      setLoading(true);
    }
  }, [search]);

  useEffect(() => {
    dispatch(superAdminRoleVersionList({ search, page }, onSuccess, onError));
  }, [page, search]);

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setSuperVersionRoleListData(data.data.data);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Role ID
            </th>
            <th className="table-heads " width={"10%"}>
              Role Name
            </th>
            <th className="table-heads " width={"10%"}>
              Updated By
            </th>
            <th className="table-heads " width={"10%"}>
              Updated Date
            </th>
            <th className="table-heads " width={"15%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : superVersionRoleListData?.roles?.length > 0 ? (
            <>
              {superVersionRoleListData?.roles?.map((eachRow, index) => (
                <SuperUserRoleVersionTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  currentPage={
                    superVersionRoleListData?.pagination?.currentPage
                  }
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      {superVersionRoleListData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={superVersionRoleListData?.pagination}
          nextFunc={() => {
            navigate(
              `/super-user/role/version-list/${
                superVersionRoleListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/super-user/role/version-list/${
                superVersionRoleListData?.pagination?.currentPage - 1
              }`
            );
            setPage(page - 1);
          }}
        />
      )}
    </>
  );
};

export default SuperUserRoleVersionTable;
