import moment from "moment";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import TableContainer from "../../components/layout/table-container/table-container";
import eye from "../../assets/icons/viewEye.svg";
import ImageViewModal from "../modals/customer/image-view-modal";
import PdfView from "../modals/pdfView";
import { pdfChecker } from "../utilites/helper";

const DoctorView = ({ customerViewData }) => {
  const [imageModal, setImageModal] = useState(false);
  const [imageTitleData, setImageTitleData] = useState("");
  const [imageUrlData, setImageUrlData] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);

  const DoctorContactData = [
    {
      heading: "Mobile Number",
      title: `+${customerViewData?.country_code ? customerViewData?.country_code : ""
        } ${customerViewData?.mobile ? customerViewData?.mobile : "--"}`,
    },
    {
      heading: "Alternate Mobile Number",
      title: `+${customerViewData?.country_code ? customerViewData?.country_code : ""
        } ${customerViewData?.alternate_mobile
          ? customerViewData?.alternate_mobile
          : "--"
        }`,
    },
    {
      heading: "Email Address",
      title: customerViewData?.email ? customerViewData?.email : "--",
    },
    {
      heading: "Address",
      title: `${customerViewData?.address?.address_one
          ? customerViewData?.address?.address_one
          : "--"
        } ${customerViewData?.address?.address_two ? "," : ""} ${customerViewData?.address?.address_two
          ? customerViewData?.address?.address_two
          : ""
        }${customerViewData?.address?.address_three ? "," : ""} ${customerViewData?.address?.address_three
          ? customerViewData?.address?.address_three
          : ""
        }`,
    },
    {
      heading: "Landmark",
      title: customerViewData?.address?.landmark
        ? customerViewData?.address?.landmark
        : "--",
    }, // title: customerViewData?.address?.landmark,
    {
      heading: "State",
      title: customerViewData?.state?.name
        ? customerViewData?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: customerViewData?.district?.name
        ? customerViewData?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: customerViewData?.city?.name ? customerViewData?.city?.name : "--",
    },
    {
      heading: "Pincode",
      title: customerViewData?.pincode ? customerViewData?.pincode : "--",
    },
  ];
  const selfPharmacyBasicData = [
    {
      heading: "Pharmacy Name",
      title: customerViewData?.doctorSelfPharmacy?.pharmacy_name
        ? customerViewData?.doctorSelfPharmacy?.pharmacy_name.toUpperCase()
        : "--",
    },
    {
      heading: "Drug License Number - 20A",
      title: customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_a
        ? customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug License Number - 20B",
      title: customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_b
        ? customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "GST Number",
      title: customerViewData?.doctorSelfPharmacy?.gst_number
        ? customerViewData?.doctorSelfPharmacy?.gst_number
        : "--",
    },
  ];
  const attachPharmacyBasicData = [
    {
      heading: "Jivini’s Phramcy Code",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.code
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.code
        : "--",
    },
    {
      heading: "Pharmacy Name",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.pharmacy_name
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.pharmacy_name.toUpperCase()
        : "--",
    },
    {
      heading: "Drug License Number - 20A",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy
        ?.drug_licence_number_20_a
        ? customerViewData?.doctorAttachPharmacy?.pharmacy
          ?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug License Number - 20B",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy
        ?.drug_licence_number_20_b
        ? customerViewData?.doctorAttachPharmacy?.pharmacy
          ?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "GST Number",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.gst_number
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.gst_number
        : "--",
    },
    {
      heading: "Onboarded By",
      title: customerViewData?.onboardingFieldforce?.fieldForceUser?.name
        ? customerViewData?.onboardingFieldforce?.fieldForceUser?.name
        : "--",
    },
    {
      heading: "Onboarded Date & Time",
      title: customerViewData?.onboardingFieldforce?.updatedAt
        ? moment(customerViewData?.onboardingFieldforce?.updatedAt).format(
          "DD MMM YYYY HH:mm A"
        )
        : "--",
    },
    {
      heading: "Linked Manufacturer",
      title:
        customerViewData?.doctorSubscribedVendors?.length > 0 &&
        customerViewData?.doctorSubscribedVendors?.map((ele, index) => {
          return (
            <span key={index}>
              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
              {ele?.vendor?.name}
            </span>
          );
        }),
    },
  ];

  const PharmacyContactData = [
    {
      heading: "Mobile Number",
      title: `+${customerViewData?.doctorAttachPharmacy?.pharmacy?.country_code
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.country_code
          : ""
        } ${customerViewData?.doctorAttachPharmacy?.pharmacy?.mobile
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.mobile
          : "--"
        }`,
    },
    {
      heading: "Alternate Mobile Number",
      title: `+${customerViewData?.doctorAttachPharmacy?.pharmacy?.country_code
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.country_code
          : ""
        } ${customerViewData?.doctorAttachPharmacy?.pharmacy?.alternate_mobile
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.alternate_mobile
          : "--"
        }`,
    },
    {
      heading: "Email Address",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.email
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.email
        : "--",
    },
    {
      heading: "Address",
      title: `${customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.address_one
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.address
            ?.address_one
          : "--"
        }${customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.address_two
          ? ","
          : ""
        } ${customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.address_two
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.address
            ?.address_two
          : ""
        }${customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.address_three
          ? ","
          : ""
        } ${customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.address_three
          ? customerViewData?.doctorAttachPharmacy?.pharmacy?.address
            ?.address_three
          : ""
        } `,
    },
    {
      heading: "Landmark",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.landmark
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.state?.name
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.district?.name
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.city?.name
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.city?.name
        : "--",
    },
    {
      heading: "Pincode",
      title: customerViewData?.doctorAttachPharmacy?.pharmacy?.pincode
        ? customerViewData?.doctorAttachPharmacy?.pharmacy?.pincode
        : "--",
    },
  ];

  return (
    <div>
      {customerViewData?.is_basic_details && (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">
              Doctor’s Basic details
            </label>
          </div>
          <div className="row mt-3 pt-3 ps-5 ">
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Jivini’s Doctor Code
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.code}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Doctor Name
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                DR. {customerViewData?.name.toUpperCase()}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Pharmacy Type
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.pharmacy_type
                  ? customerViewData?.pharmacy_type.charAt(0).toUpperCase() +
                  customerViewData?.pharmacy_type.slice(1)
                  : "--"}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Qualification
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.qualification}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Specialization
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.specialization?.name}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                MCI Number
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.medical_council_registration_number}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Onboarded By
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.onboardingFieldforce?.fieldForceUser?.name}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Onboarded Date & Time
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {moment(
                  customerViewData?.onboardingFieldforce?.updatedAt
                ).format("DD MMM YYYY HH:mm A")}
              </p>
            </div>
            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                Linked Manufacturer
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {customerViewData?.doctorSubscribedVendors?.length > 0 &&
                  customerViewData?.doctorSubscribedVendors?.map(
                    (ele, index) => {
                      return (
                        <span key={index}>
                          {index ? (
                            <span className="mx-1 color_b0b3c7">|</span>
                          ) : (
                            ""
                          )}
                          {ele?.vendor?.name}
                        </span>
                      );
                    }
                  )}
              </p>
            </div>
          </div>
        </>
      )}

      {customerViewData?.doctorSelfPharmacy != null &&
        customerViewData?.pharmacy_type != "dispensing" && (
          <>
            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
              <label className="basic-title mx-2 mb-1">
                Pharmacy’s Basic Details
              </label>
            </div>
            <div className="row mt-3 pt-3 ps-5 ">
              {selfPharmacyBasicData.map((item, index) => (
                <div
                  key={index}
                  className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                >
                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      {customerViewData?.is_contact_details && (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">
              Doctor’s Contact Details
            </label>
          </div>
          <div>
            <div className="row mt-3">
              <div className="pt-3 ps-5 row">
                {DoctorContactData.map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 col-10 col-sm-6 col-md-4 col-xxl-2 mx-3 px-3 "
                  >
                    <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
          <label className="basic-title mx-2 mb-1">Onboarding Photos</label>
        </div>
        <div className="row">
          {customerViewData?.hospital_photo && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color fs_sm_14">
                      Clinic/Hospital
                    </label>
                  </div>

                  <div
                    className="manufacturer-doc-upload-container"
                    style={{ position: "relative" }}
                  >
                    <img
                      className="image-upload"
                      src={customerViewData?.hospital_photo}
                    />
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{
                          backgroundColor: "rgb(38, 44, 61, 0.6 )",
                        }}
                      >
                        <div
                          className="align-items-center px-2 mx-2 my-2"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fs_11 Medium_Purple fw_500">
                            {customerViewData?.hospital_photo_lat} ,
                            <span className="ms-1">
                              {customerViewData?.hospital_photo_long}
                            </span>
                          </span>
                        </div>
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              // position: "absolute",
                              // zIndex: "55",
                              // top: "80px",
                              // left: "180px",
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setImageModal(true);
                              setImageTitleData("Clinic/Hospital");
                              setImageUrlData(customerViewData?.hospital_photo);
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {customerViewData?.hospital_nameboard_photo && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color fs_sm_14">
                      Photo of Clinic/Hospital’s Nameboard
                    </label>
                  </div>

                  <div
                    className="manufacturer-doc-upload-container"
                    style={{ position: "relative" }}
                  >
                    <img
                      className="image-upload"
                      src={customerViewData?.hospital_nameboard_photo}
                    />
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{
                          backgroundColor: "rgb(38, 44, 61, 0.6 )",
                        }}
                      >
                        <div
                          className="align-items-center px-2 mx-2 my-2"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fs_11 Medium_Purple fw_500">
                            {customerViewData?.hospital_nameboard_photo_lat} ,
                            <span className="ms-1">
                              {customerViewData?.hospital_nameboard_photo_long}
                            </span>
                          </span>
                        </div>
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              // position: "absolute",
                              // zIndex: "55",
                              // top: "80px",
                              // left: "180px",
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setImageModal(true);
                              setImageTitleData(
                                "Photo of Clinic/Hospital’s Nameboard"
                              );
                              setImageUrlData(
                                customerViewData?.hospital_nameboard_photo
                              );
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {customerViewData?.doctorSelfPharmacy != null && (
            <>
              {customerViewData?.doctorSelfPharmacy?.pharmacy_photo && (
                <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                  <div className="mx-4 px-3">
                    <div className="my-2">
                      <div className="upload-image-label-container d-flex justify-content-between">
                        <label className="my-1 Neutral_Color">Pharmacy</label>
                      </div>

                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="image-upload"
                          src={
                            customerViewData?.doctorSelfPharmacy?.pharmacy_photo
                          }
                        />
                        <div
                          className="w-100"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "0",
                            maxWidth: "450px",
                          }}
                        >
                          <div
                            className="manufacturer-doc-upload-container"
                            style={{
                              backgroundColor: "rgb(38, 44, 61, 0.6 )",
                            }}
                          >
                            <div
                              className="align-items-center px-2 mx-2 my-2"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                              }}
                            >
                              <span className="fs_11 Medium_Purple fw_500">
                                {
                                  customerViewData?.doctorSelfPharmacy
                                    ?.pharmacy_photo_lat
                                }
                                ,
                                <span className="ms-1">
                                  {
                                    customerViewData?.doctorSelfPharmacy
                                      ?.pharmacy_photo_long
                                  }
                                </span>
                              </span>
                            </div>
                            <div
                              className="align-items-center"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                top: "43%",
                                left: "40%",
                              }}
                            >
                              <img src={eye} className="mb-2" />
                              <button
                                className="fs_16 fw_600"
                                style={{
                                  // position: "absolute",
                                  // zIndex: "55",
                                  // top: "80px",
                                  // left: "180px",
                                  backgroundColor: "transparent",
                                  color: "#E7E5FF",
                                  border: "none",
                                }}
                                onClick={() => {
                                  setImageModal(true);
                                  setImageTitleData("Pharmacy");
                                  setImageUrlData(
                                    customerViewData?.doctorSelfPharmacy
                                      ?.pharmacy_photo
                                  );
                                }}
                              >
                                VIEW
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {customerViewData?.doctorSelfPharmacy
                ?.pharmacy_nameboard_photo && (
                  <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                    <div className="mx-4 px-3">
                      <div className="my-2">
                        <div className="upload-image-label-container d-flex justify-content-between">
                          <label className="my-1 Neutral_Color">
                            Photo of Pharmacy’s Nameboard
                          </label>
                        </div>

                        <div
                          className="manufacturer-doc-upload-container"
                          style={{ position: "relative" }}
                        >
                          <img
                            className="image-upload"
                            src={
                              customerViewData?.doctorSelfPharmacy
                                ?.pharmacy_nameboard_photo
                            }
                          />
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.6 )",
                              }}
                            >
                              <div
                                className="align-items-center px-2 mx-2 my-2"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  backgroundColor: "#fff",
                                  borderRadius: "5px",
                                }}
                              >
                                <span className="fs_11 Medium_Purple fw_500">
                                  {
                                    customerViewData?.doctorSelfPharmacy
                                      ?.pharmacy_nameboard_lat
                                  }
                                  ,
                                  <span className="ms-1">
                                    {
                                      customerViewData?.doctorSelfPharmacy
                                        ?.pharmacy_nameboard_long
                                    }
                                  </span>
                                </span>
                              </div>
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setImageModal(true);
                                    setImageTitleData(
                                      "Photo of Pharmacy’s Nameboard"
                                    );
                                    setImageUrlData(
                                      customerViewData?.doctorSelfPharmacy
                                        ?.pharmacy_nameboard_photo
                                    );
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      {customerViewData?.doctorAttachPharmacy?.pharmacy?.is_details && (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">
              Pharmacy’s Basic Details
            </label>
          </div>
          <div className="row mt-3 pt-3 ps-5 ">
            {attachPharmacyBasicData.map((item, index) => (
              <div
                key={index}
                className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
              >
                <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                  {item.heading}
                </h5>
                <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
      {customerViewData?.doctorAttachPharmacy?.pharmacy?.is_contact_details && (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">
              Pharmacy’s Contact Details
            </label>
          </div>
          <div>
            <div className="row mt-3">
              <div className="pt-3 ps-5 row">
                {PharmacyContactData.map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 col-10 col-sm-6 col-md-4 col-xxl-2 mx-3 px-3 "
                  >
                    <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      {customerViewData?.doctorAttachPharmacy?.pharmacy && (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">Onboarding Photos</label>
          </div>
          <div className="row">
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">Pharmacy</label>
                  </div>

                  <div
                    className="manufacturer-doc-upload-container"
                    style={{ position: "relative" }}
                  >
                    <img
                      className="image-upload"
                      src={
                        customerViewData?.doctorAttachPharmacy?.pharmacy
                          ?.pharmacy_photo
                      }
                      alt="image"
                    />
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{
                          backgroundColor: "rgb(38, 44, 61, 0.6 )",
                        }}
                      >
                        <div
                          className="align-items-center px-2 mx-2 my-2"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fs_11 Medium_Purple fw_500">
                            {
                              customerViewData?.doctorAttachPharmacy?.pharmacy
                                ?.pharmacy_photo_lat
                            }
                            ,
                            <span className="ms-1">
                              {
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.pharmacy_photo_long
                              }
                            </span>
                          </span>
                        </div>
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setImageModal(true);
                              setImageTitleData("Pharmacy");
                              setImageUrlData(
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.pharmacy_photo
                              );
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">
                      Photo of Pharmacy’s Nameboard
                    </label>
                  </div>

                  <div
                    className="manufacturer-doc-upload-container"
                    style={{ position: "relative" }}
                  >
                    <img
                      className="image-upload"
                      src={
                        customerViewData?.doctorAttachPharmacy?.pharmacy
                          ?.pharmacy_nameboard_photo
                      }
                    />
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{
                          backgroundColor: "rgb(38, 44, 61, 0.6 )",
                        }}
                      >
                        <div
                          className="align-items-center px-2 mx-2 my-2"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fs_11 Medium_Purple fw_500">
                            {
                              customerViewData?.doctorAttachPharmacy?.pharmacy
                                ?.pharmacy_nameboard_lat
                            }
                            ,
                            <span className="ms-1">
                              {
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.pharmacy_nameboard_long
                              }
                            </span>
                          </span>
                        </div>
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          <button
                            className="fs_16 fw_600"
                            style={{
                              backgroundColor: "transparent",
                              color: "#E7E5FF",
                              border: "none",
                            }}
                            onClick={() => {
                              setImageModal(true);
                              setImageTitleData(
                                "Photo of Pharmacy’s Nameboard"
                              );
                              setImageUrlData(
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.pharmacy_nameboard_photo
                              );
                            }}
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <>
        {customerViewData?.is_mci_certificate && (
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">Documents</label>
          </div>
        )}
        <div className="row">
          {customerViewData?.is_mci_certificate && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">
                      MCI Certificate
                    </label>
                  </div>

                  <div
                    className="manufacturer-doc-upload-container"
                    style={{ position: "relative" }}
                  >
                    {pdfChecker(customerViewData?.mci_certificate) ? (
                      <Document file={customerViewData?.mci_certificate}>
                        <Page pageNumber={1} width={415} />
                      </Document>
                    ) : (
                      <img
                        className="image-upload"
                        src={customerViewData?.mci_certificate}
                      />
                    )}
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{
                          backgroundColor: "rgb(38, 44, 61, 0.6 )",
                        }}
                      >
                        {/* <div
                          className="align-items-center px-2 mx-2 my-2"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fs_11 Medium_Purple fw_500">
                            {customerViewData?.latitude} ,
                            <span className="mx-1">
                              {" "}
                              {customerViewData?.longitude}
                            </span>
                          </span>
                        </div> */}
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          {pdfChecker(customerViewData?.mci_certificate) ? (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setOpenPdf(true);
                                setPdfFileUrl(
                                  customerViewData?.mci_certificate
                                );
                                setFileName("MCI Certificate");
                              }}
                            >
                              VIEW
                            </button>
                          ) : (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData("MCI Certificate");
                                setImageUrlData(
                                  customerViewData?.mci_certificate
                                );
                              }}
                            >
                              VIEW
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {customerViewData?.doctorAttachPharmacy != null &&
            customerViewData?.pharmacy_type != "dispensing" && (
              <>
                {customerViewData?.doctorAttachPharmacy?.pharmacy
                  ?.drug_licence_certificate_20_a && (
                    <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                      <div className="mx-4 px-3">
                        <div className="my-2">
                          <div className="upload-image-label-container d-flex justify-content-between">
                            <label className="my-1 Neutral_Color">
                              Drug License Certificate - 20A
                            </label>
                          </div>

                          <div
                            className={` h-100`}
                            style={{
                              height: "25rem",
                              overflowY: "scroll",
                              overflowX: "hidden",
                              scrollBehavior: "smooth",
                              position: "relative",
                            }}
                          >
                            <div className="manufacturer-doc-upload-container">
                              {pdfChecker(
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.drug_licence_certificate_20_a
                              ) ? (
                                <Document
                                  file={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_a
                                  }
                                >
                                  <Page pageNumber={1} width={415} />
                                </Document>
                              ) : (
                                <img
                                  className="image-upload"
                                  src={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_a
                                  }
                                />
                              )}
                            </div>
                            <div
                              className="w-100"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                top: "0",
                                maxWidth: "450px",
                              }}
                            >
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{
                                  backgroundColor: "rgb(38, 44, 61, 0.5)",
                                }}
                              >
                                <div
                                  className="align-items-center"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "43%",
                                    left: "40%",
                                  }}
                                >
                                  <img src={eye} className="mb-2" />

                                  {pdfChecker(
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_a
                                  ) ? (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setOpenPdf(true);
                                        setPdfFileUrl(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy
                                            ?.drug_licence_certificate_20_a
                                        );
                                        setFileName(
                                          "Drug License Certificate - 20A"
                                        );
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  ) : (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setImageModal(true);
                                        setImageTitleData(
                                          " Drug License Certificate - 20A"
                                        );
                                        setImageUrlData(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy
                                            ?.drug_licence_certificate_20_a
                                        );
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {customerViewData?.doctorAttachPharmacy?.pharmacy
                  ?.drug_licence_certificate_20_b && (
                    <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                      <div className="mx-4 px-3">
                        <div className="my-2">
                          <div className="upload-image-label-container d-flex justify-content-between">
                            <label className="my-1 Neutral_Color">
                              Drug License Certificate - 20B
                            </label>
                          </div>
                          <div
                            className={` h-100`}
                            style={{
                              height: "25rem",
                              overflowY: "scroll",
                              overflowX: "hidden",
                              scrollBehavior: "smooth",
                              position: "relative",
                            }}
                          >
                            <div className="manufacturer-doc-upload-container">
                              {pdfChecker(
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.drug_licence_certificate_20_b
                              ) ? (
                                <Document
                                  file={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_b
                                  }
                                >
                                  <Page pageNumber={1} width={415} />
                                </Document>
                              ) : (
                                <img
                                  className="image-upload"
                                  src={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_b
                                  }
                                />
                              )}
                            </div>
                            <div
                              className="w-100"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                top: "0",
                                maxWidth: "450px",
                              }}
                            >
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{
                                  backgroundColor: "rgb(38, 44, 61, 0.5)",
                                }}
                              >
                                <div
                                  className="align-items-center"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "43%",
                                    left: "40%",
                                  }}
                                >
                                  <img src={eye} className="mb-2" />
                                  {pdfChecker(
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.drug_licence_certificate_20_b
                                  ) ? (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setOpenPdf(true);
                                        setPdfFileUrl(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy
                                            ?.drug_licence_certificate_20_b
                                        );
                                        setFileName(
                                          "Drug License Certificate - 20B"
                                        );
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  ) : (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setImageModal(true);
                                        setImageTitleData(
                                          " Drug License Certificate - 20B"
                                        );
                                        setImageUrlData(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy
                                            ?.drug_licence_certificate_20_b
                                        );
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {customerViewData?.doctorAttachPharmacy?.pharmacy
                  ?.gst_certificate && (
                    <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                      <div className="mx-4 px-3">
                        <div className="my-2">
                          <div className="upload-image-label-container d-flex justify-content-between">
                            <label className="my-1 Neutral_Color">
                              GST Certificate
                            </label>
                          </div>
                          <div
                            className={` h-100`}
                            style={{
                              height: "25rem",
                              overflowY: "scroll",
                              overflowX: "hidden",
                              scrollBehavior: "smooth",
                              position: "relative",
                            }}
                          >
                            <div className="manufacturer-doc-upload-container">
                              {pdfChecker(
                                customerViewData?.doctorAttachPharmacy?.pharmacy
                                  ?.gst_certificate
                              ) ? (
                                <Document
                                  file={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.gst_certificate
                                  }
                                >
                                  <Page pageNumber={1} width={415} />
                                </Document>
                              ) : (
                                <img
                                  className="image-upload"
                                  src={
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.gst_certificate
                                  }
                                />
                              )}
                            </div>
                            <div
                              className="w-100"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                top: "0",
                                maxWidth: "450px",
                              }}
                            >
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{
                                  backgroundColor: "rgb(38, 44, 61, 0.5)",
                                }}
                              >
                                <div
                                  className="align-items-center"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "43%",
                                    left: "40%",
                                  }}
                                >
                                  <img src={eye} className="mb-2" />
                                  {pdfChecker(
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.gst_certificate
                                  ) ? (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setOpenPdf(true);
                                        setPdfFileUrl(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy?.gst_certificate
                                        );
                                        setFileName("GST Certificate");
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  ) : (
                                    <button
                                      className="fs_16 fw_600"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#E7E5FF",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setImageModal(true);
                                        setImageTitleData("GST Certificate");
                                        setImageUrlData(
                                          customerViewData?.doctorAttachPharmacy
                                            ?.pharmacy?.gst_certificate
                                        );
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            )}

          {customerViewData?.doctorSelfPharmacy != null && (
            <>
              {/* ----------------- SEFL PHARMACY DL 20A -----------------*/}
              {customerViewData?.doctorSelfPharmacy
                ?.drug_licence_certificate_20_a && (
                  <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                    <div className="mx-4 px-3">
                      <div className="my-2">
                        <div className="upload-image-label-container d-flex justify-content-between">
                          <label className="my-1 Neutral_Color">
                            Drug License Certificate - 20A
                          </label>
                        </div>
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            {pdfChecker(
                              customerViewData?.doctorSelfPharmacy
                                ?.drug_licence_certificate_20_a
                            ) ? (
                              <Document
                                file={
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_a
                                }
                              >
                                <Page pageNumber={1} width={415} />
                              </Document>
                            ) : (
                              <img
                                className="image-upload"
                                src={
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_a
                                }
                              />
                            )}
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                {pdfChecker(
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_a
                                ) ? (
                                  <button
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setOpenPdf(true);
                                      setPdfFileUrl(
                                        customerViewData?.doctorSelfPharmacy
                                          ?.drug_licence_certificate_20_a
                                      );
                                      setFileName(
                                        "Drug License Certificate - 20A"
                                      );
                                    }}
                                  >
                                    VIEW
                                  </button>
                                ) : (
                                  <button
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setImageModal(true);
                                      setImageTitleData(
                                        " Drug License Certificate - 20A"
                                      );
                                      setImageUrlData(
                                        customerViewData?.doctorSelfPharmacy
                                          ?.drug_licence_certificate_20_a
                                      );
                                    }}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* ----------------- SEFL PHARMACY DL 20B -----------------*/}
              {customerViewData?.doctorSelfPharmacy
                ?.drug_licence_certificate_20_b && (
                  <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                    <div className="mx-4 px-3">
                      <div className="my-2">
                        <div className="upload-image-label-container d-flex justify-content-between">
                          <label className="my-1 Neutral_Color">
                            Drug License Certificate - 20B
                          </label>
                        </div>
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            {pdfChecker(
                              customerViewData?.doctorSelfPharmacy
                                ?.drug_licence_certificate_20_b
                            ) ? (
                              <Document
                                file={
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_b
                                }
                              >
                                <Page pageNumber={1} width={415} />
                              </Document>
                            ) : (
                              <img
                                className="image-upload"
                                src={
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_b
                                }
                              />
                            )}
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                {pdfChecker(
                                  customerViewData?.doctorSelfPharmacy
                                    ?.drug_licence_certificate_20_b
                                ) ? (
                                  <button
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setOpenPdf(true);
                                      setPdfFileUrl(
                                        customerViewData?.doctorSelfPharmacy
                                          ?.drug_licence_certificate_20_b
                                      );
                                      setFileName(
                                        "Drug License Certificate - 20B"
                                      );
                                    }}
                                  >
                                    VIEW
                                  </button>
                                ) : (
                                  <button
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setImageModal(true);
                                      setImageTitleData(
                                        " Drug License Certificate - 20B"
                                      );
                                      setImageUrlData(
                                        customerViewData?.doctorSelfPharmacy
                                          ?.drug_licence_certificate_20_b
                                      );
                                    }}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* ----------------- SEFL PHARMACY GST -----------------*/}
              {customerViewData?.doctorSelfPharmacy?.gst_certificate && (
                <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{ minWidth: "480px" }}>
                  <div className="mx-4 px-3">
                    <div className="my-2">
                      <div className="upload-image-label-container d-flex justify-content-between">
                        <label className="my-1 Neutral_Color">
                          GST Certificate
                        </label>
                      </div>

                      <div
                        className={` h-100`}
                        style={{
                          height: "25rem",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          scrollBehavior: "smooth",
                          position: "relative",
                        }}
                      >
                        <div className="manufacturer-doc-upload-container">
                          {pdfChecker(
                            customerViewData?.doctorSelfPharmacy
                              ?.gst_certificate
                          ) ? (
                            <Document
                              file={
                                customerViewData?.doctorSelfPharmacy
                                  ?.gst_certificate
                              }
                            >
                              <Page pageNumber={1} width={415} />
                            </Document>
                          ) : (
                            <img
                              className="image-upload"
                              src={
                                customerViewData?.doctorSelfPharmacy
                                  ?.gst_certificate
                              }
                            />
                          )}
                        </div>
                        <div
                          className="w-100"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "0",
                            maxWidth: "450px",
                          }}
                        >
                          <div
                            className="manufacturer-doc-upload-container"
                            style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                          >
                            <div
                              className="align-items-center"
                              style={{
                                position: "absolute",
                                zIndex: "55",
                                top: "43%",
                                left: "40%",
                              }}
                            >
                              <img src={eye} className="mb-2" />
                              {pdfChecker(
                                customerViewData?.doctorSelfPharmacy
                                  ?.gst_certificate
                              ) ? (
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(
                                      customerViewData?.doctorSelfPharmacy
                                        ?.gst_certificate
                                    );
                                    setFileName("GST Certificate");
                                  }}
                                >
                                  VIEW
                                </button>
                              ) : (
                                <button
                                  className="fs_16 fw_600"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setImageModal(true);
                                    setImageTitleData("GST Certificate");
                                    setImageUrlData(
                                      customerViewData?.doctorSelfPharmacy
                                        ?.gst_certificate
                                    );
                                  }}
                                >
                                  VIEW
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
      <ImageViewModal
        show={imageModal}
        ShowClose={() => setImageModal(false)}
        imageTitleData={imageTitleData}
        imageUrlData={imageUrlData}
      />
      <PdfView
        show={openPdf}
        handleClose={() => setOpenPdf(false)}
        pdfUrl={pdfFileUrl}
        filename={filename}
      />
    </div>
  );
};

export default DoctorView;
