import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import edit from "../../../assets/icons/edit.svg";
import delet from "../../../assets/icons/delete-bin.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import TableContainer from "../../../components/layout/table-container/table-container";
import "./customer.css";
import CustomersViewTableData from "./customer-view-table-data";
import { useDispatch } from "react-redux";
import {
  customerDelete,
  customerDetail,
  customerEnableDisable,
} from "../../../redux/actions/customerActions";
import DoctorView from "../../../components/customer-vew/doctor-view";
import PharmacyView from "../../../components/customer-vew/pharmacy-view";
import DeleteModal from "../../../components/modals/deleteModal";
import EnableDisableModal from "../../../components/modals/enableDisableModal";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const CustomerView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [customerViewData, setCustomerViewData] = useState([]);
  const [customerTypeData, setCustomerTypeData] = useState([]);
  const [customerDoctorData, setCustomerDoctordata] = useState([]);
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [order_value_from, setOrder_value_from] = useState(null);
  const [order_value_to, setOrder_value_to] = useState(null);
  const [order_date_from, setOrder_date_from] = useState(null);
  const [order_date_to, setOrder_date_to] = useState(null);
  const [datafilter, setDatafilter] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Customer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  // const OrderValueSubmit = () => {
  //   dispatch(
  //     customerDetail(
  //       {
  //         order_value_from,
  //         order_value_to,
  //         user_type,
  //         pharmacy_id,
  //         doctor_id,
  //       },

  //       onSuccess,
  //       onError
  //     )
  //   );
  //   setOpenOrder(false);
  // };

  // const OrderDateSubmit = () => {
  //   dispatch(
  //     customerDetail(
  //       {
  //         order_date_from,
  //         order_date_to,
  //         user_type,
  //         pharmacy_id,
  //         doctor_id,
  //       },
  //       onSuccess,
  //       onError
  //     )
  //   );
  //   setOpenDate(false);
  // };
  // const orderValueClear = () => {
  //   setOrder_value_from(null);
  //   setOrder_value_to(null);
  //   dispatch(customerDetail(onSuccess, onError));
  //   setOpenOrder(false);
  // };
  // const orderDateClear = () => {
  //   setOrder_date_from(null);
  //   setOrder_date_to(null);
  //   dispatch(customerDetail(onSuccess, onError));
  //   setOpenDate(false);
  // };

  const enableDisableFunc = () => {
    dispatch(
      customerEnableDisable(
        customerTypeData,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    fetchCustomerView();
  };

  const onEnableDisableSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  useEffect(() => {
    fetchCustomerView();
  }, []);

  const fetchCustomerView = () => {
    console.log(state);
    dispatch(customerDetail(state, onSuccess, onError));
    setCustomerTypeData(state);
    setLoading(true);
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setCustomerViewData(data.data.data);
    setCustomerDoctordata(data.data.data.pharmacyDoctors);
    console.log(data.data.data.pharmacyDoctors.pharmacy_id, "fgd");
  };
  const onError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const delFunc = () => {
    dispatch(customerDelete(customerViewData.id, deleteSuccess, deleteError));
  };

  const timeOutFnc2 = () => {
    setShowSuccessPopup1(false);
    setDeleteShow(false);
    navigate("/customer-list");
  };

  const deleteSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc2, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
    setDeleteShow(false);
  };

  const deleteError = (err) => {
    console.log(err);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };

  console.log(customerViewData.code);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  useEffect(() => {
    setDatafilter(customerViewData?.orders);
  }, [customerViewData?.orders]);

  const orderValueFilter = () => {
    const filtered = customerViewData?.orders?.filter((item) => {
      return (
        item.total >= parseInt(order_value_from) &&
        item.total <= parseInt(order_value_to)
      );
    });
    setDatafilter(filtered);
    setOpenOrder(false);
  };
  const orderDateFilter = () => {
    const filtered = customerViewData?.orders?.filter((item) => {
      return (
        item.createdAt >= order_date_from && item.createdAt <= order_date_to
      );
    });
    setDatafilter(filtered);
    setOpenDate(false);
  };

  const orderValueClear = () => {
    setDatafilter(customerViewData?.orders);
    setOrder_value_to(null);
    setOrder_value_from(null);
    setOpenOrder(false);
  };
  const orderDateClear = () => {
    setDatafilter(customerViewData?.orders);
    setOrder_date_to(null);
    setOrder_date_from(null);
    setOpenDate(false);
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (customerViewData.code
          ? ` / ${customerViewData.code} - view`
          : " / view")}
    </span>
  );

  console.log(
    customerTypeData?.user_type == "doctor" ? customerViewData.code : ""
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          {/* <VersionHistoryBtn
            link={`/customer-version-view/${customerViewData?.id}`}
          /> */}
          <AdminButton
            color={"#7065e4"}
            onClick={() =>
              navigate(
                `/customers/customer/version-view/${customerViewData?.id}`,
                {
                  state,
                }
              )
            }
          >
            Version History
          </AdminButton>
          {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
            <>
              <AdminButton
                className="d-none d-xl-block"
                background="#7065E4"
                color="white"
                width="90px"
                disabled={!customerViewData?.pharmacy_type}
                onClick={() =>
                  navigate(`/customers/customer/edit/${customerViewData.id}`, {
                    state: {
                      user_type: customerViewData?.customer_type,
                      pharmacy_id:
                        customerViewData?.customer_type !== "doctor" &&
                        customerViewData.id,
                      doctor_id:
                        customerViewData?.customer_type == "doctor" &&
                        customerViewData.id,
                      pharmacy_type: customerViewData?.pharmacy_type,
                    },
                  })
                }
              >
                <span className="m-2">EDIT</span>
              </AdminButton>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  className="edit-small-btn py-1 px-3 mx-2 d-xl-none"
                  onClick={() =>
                    navigate(`/customer/edit/${customerViewData?.id}`)
                  }
                >
                  <img src={edit} alt="edit" className="py-1" />
                </button>
              </OverlayTrigger>
              <button
                className="delete-small-btn py-2 px-3 me-2 d-xl-none"
              // onClick={() => setDelShow(true)}
              >
                <img src={delet} alt="delete" />
              </button>

              <AdminButton
                color={customerViewData?.status ? "#FF6F5B" : "#24D626"}
                border={
                  customerViewData?.status
                    ? "1px solid #FF6F5B"
                    : "1px solid #24D626"
                }
                width="110px"
                onClick={() => setOnEnableShow(true)}
              >
                <span className="m-2">
                  {customerViewData?.status ? "DISABLE" : "ENABLE"}
                </span>
              </AdminButton>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="fn_Nunito scrolling">
        {loading == true ? (
          <div className="view-spinner-container">
            <LoadingSpinnerTable show={loading} />
          </div>
        ) : (
          <>
            {customerTypeData?.user_type === "doctor" ? (
              <>
                <DoctorView customerViewData={customerViewData} />
              </>
            ) : (
              <PharmacyView customerViewData={customerViewData} />
            )}
            <>
              <div className="basic-details-container my-3 mt-5 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">Orders</label>
              </div>
              <div className="row mt-3 mb-4">
                <div className="customer-detail-table-container col-11 mx-auto">
                  <TableContainer>
                    <thead>
                      <tr className="table-rows ">
                        <th className="table-heads" width={"4%"}>
                          <span className="fw_600">#</span>
                        </th>
                        <th className="table-heads fw_700 " width={"10%"}>
                          <span className="fw_600"> Order ID </span>
                        </th>
                        <th className="table-heads text-nowrap " width={"12%"}>
                          <span
                            onClick={() => {
                              setOpenDate(!openDate);
                              setOpenOrder(false);
                            }}
                            className="cursor_pointer"
                          >
                            Order Date
                            {openDate ? (
                              <img src={UpArrow} className="mb-1 mx-1" />
                            ) : (
                              <img src={DownArrow} className=" mx-1" />
                            )}
                          </span>
                          {openDate ? (
                            <div className=" position-relative">
                              <div className=" position-absolute order-container">
                                <div className=" d-flex my-3">
                                  <div className="mx-3">
                                    <label className="fn_Nunito color_262c3d ">
                                      From
                                    </label>
                                    <div className=" d-flex mt-1 ">
                                      <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                        <input
                                          type="date"
                                          className="  amount_input  py-1  ms-1"
                                          placeholder="Select Date"
                                          value={
                                            order_date_from && order_date_from
                                          }
                                          onfocus="(this.type = 'date')"
                                          id="date"
                                          onChange={(e) =>
                                            setOrder_date_from(e.target.value)
                                          }
                                        />

                                        {/* <img src={date} /> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <label className="fn_Nunito color_262c3d ">
                                      To
                                    </label>
                                    <div className=" d-flex mt-1">
                                      <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                        <input
                                          type="date"
                                          className="  amount_input  py-1  ms-1"
                                          placeholder=" Selct Date"
                                          value={order_date_to && order_date_to}
                                          onChange={(e) =>
                                            setOrder_date_to(e.target.value)
                                          }
                                        />
                                        {/* <img src={date} /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex justify-content-end mx-3 gap-3">
                                    <button
                                      type="button"
                                      className="filter-clear-btn px-3 py-1 fs_14"
                                      onClick={orderDateClear}
                                    >
                                      clear
                                    </button>
                                    <button
                                      className="filter-btn px-2 py-1 fs_14"
                                      type="button"
                                      onClick={orderDateFilter}
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </th>
                        <th className="table-heads text-nowrap" width={"12%"}>
                          <span
                            onClick={() => {
                              setOpenOrder(!openOrder);
                              setOpenDate(false);
                            }}
                            className="cursor_pointer"
                          >
                            Order Value
                            {openOrder ? (
                              <img src={UpArrow} className="mb-1 mx-1" />
                            ) : (
                              <img src={DownArrow} className=" mx-1" />
                            )}
                          </span>

                          {openOrder ? (
                            <div className=" position-relative">
                              <div className=" position-absolute order-container">
                                <div className=" d-flex my-3">
                                  <div className="mx-3">
                                    <label className="fn_Nunito color_262c3d ">
                                      From
                                    </label>
                                    <div className=" d-flex mt-1 ">
                                      <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                        <span className="fn_Nunito fs_14 fw_700 Medium_Purple ">
                                          ₹.
                                        </span>
                                        <input
                                          type="number"
                                          className="  amount_input  py-1  ms-1"
                                          placeholder="Enter amount"
                                          value={
                                            order_value_from && order_value_from
                                          }
                                          onChange={(e) =>
                                            setOrder_value_from(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <label className="fn_Nunito color_262c3d ">
                                      To
                                    </label>
                                    <div className=" d-flex mt-1">
                                      <div className=" d-flex align-items-center  py-1 px-3 amount_input_container ">
                                        <span className="fn_Nunito fs_14 fw_700 Medium_Purple ">
                                          ₹.
                                        </span>
                                        <input
                                          type="number"
                                          className="  amount_input  py-1  ms-1"
                                          placeholder="Enter amount"
                                          value={
                                            order_value_to && order_value_to
                                          }
                                          onChange={(e) =>
                                            setOrder_value_to(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex justify-content-end mx-3 gap-3">
                                    <button
                                      type="button"
                                      className="filter-clear-btn px-3 py-1 fs_14"
                                      onClick={orderValueClear}
                                    >
                                      clear
                                    </button>
                                    <button
                                      className="filter-btn px-2 py-1 fs_14"
                                      type="button"
                                      onClick={orderValueFilter}
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </th>
                        <th className="table-heads " width={"10%"}>
                          <span className="fw_600"> Manufacturer </span>
                        </th>
                        <th className="table-heads " width={"10%"}>
                          <span className="fw_600"> Distributor</span>
                        </th>
                        <th className="table-heads " width={"8%"}>
                          <span className="fw_600"> Order Status </span>
                        </th>
                        <th className="table-heads " width={"10%"}>
                          <span className="fw_600"> Reason for Rejection </span>
                        </th>
                        <th className="table-heads " width={"10%"}>
                          <span className="fw_600"> Rejected By </span>
                        </th>
                        <th className="table-heads " width={"22%"}>
                          <span className="fw_600">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ position: "relative" }}>
                      {datafilter?.length > 0 ? (
                        datafilter?.map((ele, index) => {
                          return (
                            <CustomersViewTableData
                              data={ele}
                              key={ele.id}
                              index={index}
                              fetchCustomerView={fetchCustomerView}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="1"
                            className="text-center no-data-container fs_16"
                          >
                            <span>~ No Data Found ~</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </TableContainer>
                </div>
              </div>
            </>
          </>
        )}
      </div>
      <EnableDisableModal
        enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={""}
        // title={`customer- ${data.code} ?`}
        status={customerViewData?.status}
        show={onEnableShow}
      />
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </MainLayout>
  );
};

export default CustomerView;
