import {
  ANALYTICS_CARD,
  ANALYTICS_DISTRIBUTOR,
  ANALYTICS_DIVISION,
  ANALYTICS_DOCTOR,
  ANALYTICS_FIELD_FORCE,
  ANALYTICS_HEADQUATER,
  ANALYTICS_MANUFACTURER,
  ANALYTICS_PHARMACY,
  ANALYTICS_PRODUCT,
  ANALYTICS_STATUS,
} from "./types";

export const analyticsList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_CARD,
    data,
    onSuccess,
    onError,
  };
};

export const analyticsStatusAction = (onSuccess, onError) => {
  return {
    type: ANALYTICS_STATUS,
    onSuccess,
    onError,
  };
};

export const manufacturerList = (onSuccess, onError) => {
  return {
    type: ANALYTICS_MANUFACTURER,
    onSuccess,
    onError,
  };
};

export const divisionList = (data,onSuccess, onError) => {
  return {
    type: ANALYTICS_DIVISION,
    data,
    onSuccess,
    onError,
  };
};

export const productList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_PRODUCT,
    data,
    onSuccess,
    onError,
  };
};

export const fieldForceUserList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_FIELD_FORCE,
    data,
    onSuccess,
    onError,
  };
};

export const distributorList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_DISTRIBUTOR,
    data,
    onSuccess,
    onError,
  };
};

export const pharmacyList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_PHARMACY,
    data,
    onSuccess,
    onError,
  };
};

export const doctorList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_DOCTOR,
    data,
    onSuccess,
    onError,
  };
};

export const headQuaterList = (data, onSuccess, onError) => {
  return {
    type: ANALYTICS_HEADQUATER,
    data,
    onSuccess,
    onError,
  };
};
