import {
  DESIGNATION_LIST,
  FETCH_DOCTOR_LIST,
  FETCH_PHARMACY_LIST,
  GEV_CFA_CREATE,
  GEV_CFA_EDIT,
  GEV_CFA_LIST,
  GEV_CFA_REPORT_LIST,
  GEV_CFA_SEND_MAIL,
  GEV_CFA_VIEW,
  GEV_DESIGNATION_LIST,
  GEV_DESIGNATION_REPORT_LIST,
  GEV_DESIGNATION_VIEW,
  GEV_DIVISION_LIST,
  GEV_DIVISION_REPORT_LIST,
  GEV_DIVISION_VIEW,
  GEV_DIVISION_VIEW_LIST,
  GEV_FF_REPORT_LIST,
  GEV_FIELDFORCE_EDIT,
  GEV_FIELDFORCE_LIST,
  GEV_FIELDFORCE_VIEW,
  GEV_HEADQUATER_LIST,
  GEV_HEADQUATER_VIEW,
  GEV_HEADQUATER_VIEW_LIST,
  GEV_HQ_REPORT_LIST,
  GEV_ORDERS_LIST,
  GEV_ORDERS_REPORT_LIST,
  GEV_ORDERS_VIEW,
  GEV_PRODUCT_CREATE,
  GEV_PRODUCT_DELETE,
  GEV_PRODUCT_EDIT,
  GEV_PRODUCT_LIST,
  GEV_PRODUCT_REPORT_LIST,
  GEV_PRODUCT_VIEW,
  GEV_REP_MANAGER_LIST,
  PACKING_TYPE,
  PRODUCT_HEADQUATER_LIST,
  PRODUCT_STATE_LIST,
  VENDOR_DIVISION_LIST,
} from "./types";

export const gevDesignationList = (data, onSuccess, onError) => {
  return {
    type: GEV_DESIGNATION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevDesignationReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_DESIGNATION_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const gevHQReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_HQ_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const geDivisionReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_DIVISION_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const geProductReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const geFFReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_FF_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const geOrdersReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_ORDERS_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const geCfaReportList = (id, onSuccess, onError) => {
  return {
    type: GEV_CFA_REPORT_LIST,
    id,
    onSuccess,
    onError,
  };
};

export const gevDesignationView = (id, onSuccess, onError) => {
  return {
    type: GEV_DESIGNATION_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const gevHeadQuaterViewList = (data, onSuccess, onError) => {
  return {
    type: GEV_HEADQUATER_VIEW_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const gevHeadQuaterView = (data, onSuccess, onError) => {
  return {
    type: GEV_HEADQUATER_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const gevDivisionView = (data, onSuccess, onError) => {
  return {
    type: GEV_DIVISION_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const gevFieldForceList = (data, onSuccess, onError) => {
  return {
    type: GEV_FIELDFORCE_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevFieldForceView = (id, onSuccess, onError) => {
  return {
    type: GEV_FIELDFORCE_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const gevFieldForceEdit = (data, onSuccess, onError) => {
  return {
    type: GEV_FIELDFORCE_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const designationList = (id, onSuccess, onError) => {
  return {
    type: DESIGNATION_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const gevDivisionViewList = (data, onSuccess, onError) => {
  return {
    type: GEV_DIVISION_VIEW_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const gevDivisionList = (id, onSuccess, onError) => {
  return {
    type: GEV_DIVISION_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const gevHeadQuaterList = (id, onSuccess, onError) => {
  return {
    type: GEV_HEADQUATER_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const gevReportingManagerList = (data, onSuccess, onError) => {
  return {
    type: GEV_REP_MANAGER_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevOrdersList = (data, onSuccess, onError) => {
  return {
    type: GEV_ORDERS_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevOrdersView = (id, onSuccess, onError) => {
  return {
    type: GEV_ORDERS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const gevCfaList = (data, onSuccess, onError) => {
  return {
    type: GEV_CFA_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevCfaView = (id, onSuccess, onError) => {
  return {
    type: GEV_CFA_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const gevCfaEdit = (data, onSuccess, onError) => {
  return {
    type: GEV_CFA_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const gevCfaCreate = (data, onSuccess, onError) => {
  return {
    type: GEV_CFA_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const GEVSendCfaMail = (data, onSuccess, onError) => {
  return {
    type: GEV_CFA_SEND_MAIL,
    data,
    onSuccess,
    onError,
  };
};
export const gevProductList = (data, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const gevProductView = (id, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const productStateList = (onSuccess, onError) => {
  return {
    type: PRODUCT_STATE_LIST,
    onSuccess,
    onError,
  };
};
export const packingTypeList = (onSuccess, onError) => {
  return {
    type: PACKING_TYPE,
    onSuccess,
    onError,
  };
};
export const gevProductEdit = (data, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const gevProductCreate = (data, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const gevProductDelete = (id, onSuccess, onError) => {
  return {
    type: GEV_PRODUCT_DELETE,
    id,
    onSuccess,
    onError,
  };
};
export const vendorDivisionList = (id, onSuccess, onError) => {
  return {
    type: VENDOR_DIVISION_LIST,
    id,
    onSuccess,
    onError,
  };
};

export const productHeadQuaterList = (id, onSuccess, onError) => {
  return {
    type: PRODUCT_HEADQUATER_LIST,
    id,
    onSuccess,
    onError,
  };
};

export const fetchDoctorList = (id, onSuccess, onError) => {
  return {
    type: FETCH_DOCTOR_LIST,
    id,
    onSuccess,
    onError,
  };
};
export const fetchPharmacyList = (id, onSuccess, onError) => {
  return {
    type: FETCH_PHARMACY_LIST,
    id,
    onSuccess,
    onError,
  };
};
