import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import jiviniLogo from "../../../assets/images/auth/jiviniLogo.png";
import "./topnav.css";
import menuIcon from "../../../assets/icons/layout/menu.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../modals/logoutModal.js";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";

const Topnav = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOptionOpen, setMenuOptionOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setMenuOptionOpen(false);
    }
  };

  useEffect(() => {
    if (menuOptionOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOptionOpen]);

  const logoutHandler = () => {
    dispatch(logout(logoutSuccess, logoutError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/");
  };

  const logoutSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const logoutError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <div className="header d-flex justify-content-between align-items-center mb-1">
      <div
        onClick={() => setOpen(!open)}
        className={`d-inline-block d-lg-none mx-3 `}
      >
        <img src={menuIcon} alt="" className="menuicon my-1 py-2 " />
      </div>
      <div className=" d-flex flex-row justify-content-center justify-content-lg-between align-items-center   w-100 me-4">
        <img
          src={jiviniLogo}
          alt="Asscents Logo"
          className="jivini-logo"
          onClick={() => {
            localStorage.setItem("activeTab", 1);
            navigate("/");
          }}
        />
      </div>
      <div className=" d-flex align-items-center justify-content-center p-3">
        <span className="mail-address fw_400 fs_14 d-none d-lg-block">
          {localStorage.getItem("email")}
        </span>
        <div ref={componentRef}>
          <button
            type="button"
            className="dots-button"
            onClick={() => {
              setMenuOptionOpen(!menuOptionOpen);
            }}
          >
            <BsThreeDotsVertical size={15} color="#262C3D" />
          </button>
          {menuOptionOpen && (
            <div
              className="position-relative  cursor_pointer"
              onClick={() => setShowModal(true)}
            >
              <div className="menu_option_contianer  py-2 px-5">
                <span className=" fw_500 fs_14 text-left">
                  Logout
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <LogoutModal
        logoutShow={showModal}
        handleLogoutClose={() => setShowModal(false)}
        logoutFunc={logoutHandler}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </div>
  );
};

export default Topnav;
