import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/deleteModal";
import EnableDisableModal from "../../../components/modals/enableDisableModal";

const CustomersVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data.code ? data.code : "--"}</td>
      <td className="table-each-row-data">
        {data.pharmacyCode ? data.pharmacyCode : "--"}
      </td>
      <td className="table-each-row-data">{data.name ? data.name : "--"}</td>
      <td className="table-each-row-data">
        {data?.pharmacy_type === "dispensing" ? null : data?.pharmacy_type ===
          "self" ? (
          <>
            {data?.doctorSelfPharmacy?.pharmacy_name
              ? data?.doctorSelfPharmacy?.pharmacy_name
              : "--"}
          </>
        ) : (
          <>
            {data?.doctorAttachPharmacy?.pharmacy_name
              ? data?.doctorAttachPharmacy?.pharmacy_name
              : "--"}
          </>
        )}
      </td>
      <td className="table-each-row-data">
        {data?.city?.name ? data?.city?.name : "--"}
      </td>
      <td className="table-each-row-data">
        {data.pharmacy_type
          ? data.pharmacy_type.charAt(0).toUpperCase() +
            data.pharmacy_type.slice(1)
          : "--"}
      </td>
      {data.status === true ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : (
        <td className="table-each-row-data text_red">Inactive</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/customers/customer/version-view/${data?.id} `, {
                state: {
                  user_type: data?.customer_type,
                  pharmacy_id: data?.customer_type !== "doctor" && data.id,
                  doctor_id: data?.customer_type == "doctor" && data.id,
                },
              })
            }
          >
            <span className=""> View</span>
          </button>
        </div>
      </td>
      <EnableDisableModal
        // enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={data.id}
        status={data.status}
        show={onEnableShow}
      />
      <DeleteModal
        // delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
    </tr>
  );
};

export default CustomersVersionTableData;
