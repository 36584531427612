import React from "react";
import "./invoice-verify.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    maxWidth: "45rem",
    height: "43rem",
    // maxHeight:"46rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

export const TableFooter = styled("div")(
  {
    background: "#2AD3E7",
    border: "1px solid #e7e5ff",
    borderBottomLeftRadius: " 15px",
    borderBottomRightRadius: "15px",
    maxWidth: "45rem",
    height: "3rem",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

const ProductsTable = ({
  maxWidth = "58rem",
  cfaTbaledata,
  total,
  distributorProductTable,
  cfaUser,
}) => {
  console.log("table data", distributorProductTable, total);

  return (
    <div className="h-100">
      <TableContainer maxWidth={maxWidth}>
        {cfaUser == true ? (
          <>
            <table className="main-table text-nowrap">
              <tr className="table-rows">
                <th className="order-table-heads color_262c3d" width={"8%"}>
                  Product Name
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Free Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Supllied Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Supplied Free Qty
                </th>
              </tr>
              {cfaTbaledata?.length > 0 &&
                cfaTbaledata?.map((ele, index) => (
                  <tr className="table-rows1" key={index}>
                    <td className="order-table-each-row-data">
                      {ele?.product_name}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.quantity}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.offer_qty}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.supplying_qty}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.supplying_free_qty}
                    </td>
                  </tr>
                ))}
            </table>
          </>
        ) : (
          <>
            <table className="main-table text-nowrap">
              <tr className="table-rows">
                <th className="order-table-heads color_262c3d" width={"8%"}>
                  Product Name
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Free Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Invoice Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"7%"}>
                  Invoice Free Qty
                </th>
                <th className="order-table-heads color_262c3d" width={"15%"}>
                  Invoice Ptr
                </th>
              </tr>
              {distributorProductTable?.length > 0 &&
                distributorProductTable?.map((ele, index) => (
                  <tr className="table-rows1" key={index}>
                    <td className="order-table-each-row-data">
                      {ele?.product_name}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.quantity}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.offer_qty}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.invoice_qty}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.invoice_free_qty}
                    </td>
                    <td className="order-table-each-row-data">
                      {ele?.supplied_ptr ?? "--"}
                    </td>
                  </tr>
                ))}
            </table>
          </>
        )}
      </TableContainer>
      <TableFooter
        className="d-flex justify-content-between align-items-center px-4 table-footer"
        maxWidth={maxWidth}
      >
        <div>
          <label className="text-white ">Total</label>
        </div>
        <div>
          <label className="text-white fw_600 mx-5">₹ {total}</label>
        </div>
      </TableFooter>
    </div>
  );
};

export default ProductsTable;
