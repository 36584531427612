import React from "react";

const PharmacyVerificationTableData = ({
  data,
  index,
  deleteModalOpener,
  checkedPharmacy,
  isChecked,
}) => {
  return (
    <tbody>
      <tr className="table-rows1" key={index}>
        <td className="table-each-row-data" width={"6%"}>
          <input
            type="checkbox"
            className="CheckBox my-1"
            id="index"
            name="prodcutsCheck"
            onChange={(e) => checkedPharmacy(e, data)}
            checked={isChecked}
          />
        </td>
        <td className="table-each-row-data">{data?.sl_no}</td>
        <td className="table-each-row-data">{data?.pharmacy_name}</td>
        <td className="table-each-row-data">{data?.mobile}</td>
        <td className="table-each-row-data">{data?.email}</td>
        <td className="table-each-row-data ">
          <div className="all-button d-flex align-items-center">
            <button
              className="button-disable"
              type="button"
              color={"green"}
            // onClick={() => deleteModalOpener(index)}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default PharmacyVerificationTableData;
