import React from "react";
import loader from "../../assets/icons/loader.svg";

const LoadingSpinnerTable = ({}) => {
  return (
    <div>
      <img src={loader} width={100} />
    </div>
  );
};

export default LoadingSpinnerTable;
