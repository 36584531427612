import { api } from "./api";

export const settingsStateListApi = (params) => {
  return api.get(`/admin/settings/state/list`, { params });
};
export const settingsStateListCreateApi = (params) => {
  return api.post(`/admin/settings/state/create`, JSON.stringify(params));
};
export const settingsStateListEditApi = (params) => {
  return api.post(`/admin/settings/state/edit`, JSON.stringify(params));
};

export const settingsDistrictListApi = (params) => {
  return api.get(`/admin/settings/district/list`, { params });
};
export const settingsDistrictListCreateApi = (params) => {
  return api.post(`/admin/settings/district/create`, JSON.stringify(params));
};
export const settingsDistrictViewApi = (params) => {
  return api.get(`/admin/settings/district/view`, { params: params });
};
export const settingsDistrictListEditApi = (params) => {
  return api.post(`/admin/settings/district/edit`, JSON.stringify(params));
};

export const settingsCityListApi = (params) => {
  return api.get(`/admin/settings/city/list`, { params });
};
export const settingsCityCreateApi = (params) => {
  return api.post(`/admin/settings/city/create`, JSON.stringify(params));
};
export const settingsCityEditApi = (params) => {
  return api.post(`/admin/settings/city/edit`, JSON.stringify(params));
};

export const settingsPincodeListApi = (params) => {
  return api.get(`/admin/settings/pincode/list`, { params });
};
export const settingsPincodeCreateApi = (params) => {
  return api.post(`/admin/settings/pincode/create`, JSON.stringify(params));
};
export const settingsPincodeEditApi = (params) => {
  return api.post(`/admin/settings/pincode/edit`, JSON.stringify(params));
};

export const settingsRejectReasonListApi = (params) => {
  return api.get(`/admin/settings/rejection-reason/list`, { params });
};
export const settingsRejectReasonViewApi = (params) => {
  return api.get(`/admin/settings/rejection-reason/view`, { params: params });
};
export const settingsRejectReasonCreateApi = (params) => {
  return api.post(
    `/admin/settings/rejection-reason/create`,
    JSON.stringify(params)
  );
};
export const settingsPrivacyPolicyViewApi = (params) => {
  return api.get(`/admin/settings/privacy/view`, { params });
};
export const settingsPrivacyPolicyEditApi = (params) => {
  return api.post(`/admin/settings/privacy/edit`, JSON.stringify(params));
};
export const settingsTermsConditionViewApi = (params) => {
  return api.get(`/admin/settings/terms/view`, { params });
};
export const settingsTermsConditionEditApi = (params) => {
  return api.post(`/admin/settings/terms/edit`, JSON.stringify(params));
};
export const settingsImageUploadApi = (params) => {
  return api.post(
    `/admin/settings/advertisement/upload-image`,
    JSON.stringify(params)
  );
};

export const settingsPincodeFilter = (params) => {
  return api.get(`/admin/settings/pincode/list`, { params });
};
export const settingsAdvertisementCreateApi = (params) => {
  return api.post(
    `/admin/settings/advertisement/create`,
    JSON.stringify(params)
  );
};

export const settingsAdvertisementDeleteApi = (params) => {
  return api.delete(`/admin/settings/advertisement/delete`, { data: params });
};
export const settingsAdvertisementListApi = (params) => {
  return api.get(`/admin/settings/advertisement/list`, { params });
};
