import React, { useEffect, useState } from "react";
import ProductDataVerificationModal from "../../../../components/modals/manufacturer/productDataVerification";
import { useDispatch } from "react-redux";
import {
  distributorCsvUpload,
  doctorCsvUpload,
  fieldForceCsvUpload,
  pharmacyCsvUpload,
  productCsvUpload,
  productDivisionView,
  fieldForceDivisionView,
  doctorDivisionView,
  pharmacyDivisionView,
} from "../../../../redux/actions/manufacturerActions";
import DistributorDataVerificationModal from "../../../../components/modals/manufacturer/distributor-verification-modal";
import FieldForceDataVerificationModal from "../../../../components/modals/manufacturer/field-force-verification-modal";
import DoctorDataVerificationModal from "../../../../components/modals/manufacturer/doctor-verification-table";
import PharmacyVerificationModal from "../../../../components/modals/manufacturer/PharmacyVerificationModal";
import info from "../../../../assets/icons/info.svg";
import downlaod from "../../../../assets/icons/downloadLogo.svg";
import ProductDivisionView from "../../../../components/division/product/ProductDivisionView";
import ProductDivisionList from "../../../../components/division/product/ProductDivisionList";
import FieldForceDivisionView from "../../../../components/division/fieldForce/FieldForceDivisionView";
import FieldForceDivisionList from "../../../../components/division/fieldForce/FieldForceDivisionList";
import PharmacyDivisionView from "../../../../components/division/pharmacy/PharmacyDivisionView";
import PharmacyDivisionList from "../../../../components/division/pharmacy/PharmacyDivisionList";
import DoctorDivisionView from "../../../../components/division/doctors/DoctorDivisionView";
import DoctorDivisionList from "../../../../components/division/doctors/DoctorDivisionList";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { useParams } from "react-router-dom";
import { gevDivisionView } from "../../../../redux/actions/godsEyeViewActions";

const DivisionView = ({ vendor_id }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showProductDetailModal, setShowProductDetailModal] = useState(false);
  const [showFieldForceModal, setFieldForceModal] = useState(false);
  const [showDistributorModal, setShowDistributorModal] = useState(false);
  const [showDoctorModal, setshowDoctorModal] = useState(false);
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [productData, setProductData] = useState(null);
  const [fieldForceData, setFieldForceData] = useState(null);
  const [distributorData, setDistributorData] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [pharmacyData, setPharmacyData] = useState(null);
  const [activeTab, setActiveTab] = useState(1); // ACTIVE TAB
  const [activeProduct, setActiveProduct] = useState(11); // ACTIVE PRODUCT TAB
  const [activeFF, setActiveFF] = useState(21); // ACTIVE FIELD FORCE TAB
  const [activeDoctor, setActiveDoctor] = useState(31); // ACTIVE DOCTOR TAB
  const [activePharmacy, setActivePharmacy] = useState(41); // ACTIVE PHARMACY TAB
  const [productDivisionData, setProductDivisionData] = useState([]);
  const [ffDivisionData, setFfDivisionData] = useState([]);
  const [doctorDivisionData, setDoctorDivisionData] = useState([]);
  const [pharmacyDivisionData, setPharmacyDivisionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedFfID, setselectedFfID] = useState("");
  const [selectedDoctorID, setSelectedDoctorID] = useState("");
  const [selectedPharmacyID, setSelectedPharmacyID] = useState("");
  const [selectedDivisionData, setSelectedDivisionData] = useState([]);
  const div_id = params?.id.split("&")?.[3];

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }

  useEffect(() => {
    dispatch(
      gevDivisionView(
        { vendor_id: vendor_id, division_id: div_id },
        onSuccess,
        onError
      )
    );
  }, []);

  const onSuccess = (data) => {
    console.log(data?.data?.data, "ssss");
    setSelectedDivisionData(data?.data?.data);
  };
  const onError = (err) => { };

  useEffect(() => {
    setLoading(true);
    dispatch(
      productDivisionView(
        { vendor_id, division_id: div_id },
        onProductDivViewSuccess,
        onProductDivViewError
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      fieldForceDivisionView(
        { vendor_id, division_id: div_id },
        onFForceDivViewSuccess,
        onFForceDivViewError
      )
    );

    dispatch(
      doctorDivisionView(
        { vendor_id, division_id: div_id },
        onDoctorDivViewSuccess,
        onDoctorDivViewError
      )
    );

    dispatch(
      pharmacyDivisionView(
        { vendor_id, division_id: div_id },
        onPharmacyDivViewSuccess,
        onPharmacyDivViewError
      )
    );
  }, []);

  const onProductDivViewSuccess = (data) => {
    setProductDivisionData(data.data.data);
    console.log(data);
    setLoading(false);
  };

  const onProductDivViewError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const onFForceDivViewSuccess = (data) => {
    console.log(data.data.data);
    setFfDivisionData(data.data.data);
  };

  const onFForceDivViewError = (err) => {
    console.log(err);
  };

  const onDoctorDivViewSuccess = (data) => {
    console.log(data);
    setDoctorDivisionData(data.data.data);
  };

  const onDoctorDivViewError = (err) => {
    console.log(err);
  };

  const onPharmacyDivViewSuccess = (data) => {
    console.log(data);
    setPharmacyDivisionData(data.data.data);
  };

  const onPharmacyDivViewError = (err) => {
    console.log(err);
  };

  const productHandleChange = (file) => {
    dispatch(
      productCsvUpload(
        { vendor_id, division_id: div_id },
        file,
        onCSVProductUploadSuccess,
        onCSVProductUploadError
      )
    );
  };

  const onCSVProductUploadSuccess = (data) => {
    console.log(data);
    setShowProductDetailModal(true);
    setProductData(data.data.data);
  };

  const onCSVProductUploadError = (err) => {
    console.log(err);
  };

  const ffHandleChange = (file) => {
    dispatch(
      fieldForceCsvUpload(
        { vendor_id, division_id: div_id },
        file,
        onCSVFieldForceUploadSuccess,
        onCSVFieldForceUploadError
      )
    );
  };

  const onCSVFieldForceUploadSuccess = (data) => {
    console.log(data);
    setFieldForceModal(true);
    setFieldForceData(data.data.data);
  };

  const onCSVFieldForceUploadError = (err) => {
    console.log(err);
  };

  const distributorHandleChange = (file) => {
    dispatch(
      distributorCsvUpload(
        { vendor_id, division_id: div_id },
        file,
        onCSVDistributorUploadSuccess,
        onCSVDistributorUploadError
      )
    );
  };

  const onCSVDistributorUploadSuccess = (data) => {
    console.log(data);
    setShowDistributorModal(true);
    setDistributorData(data.data.data);
  };

  const onCSVDistributorUploadError = (err) => {
    console.log(err);
  };

  const doctorHandleChange = (file) => {
    dispatch(
      doctorCsvUpload(
        { vendor_id, division_id: div_id },
        file,
        onCSVDoctorUploadSuccess,
        onCSVDoctorUploadError
      )
    );
  };

  const onCSVDoctorUploadSuccess = (data) => {
    console.log(data);
    setshowDoctorModal(true);
    setDoctorData(data.data.data);
  };

  const onCSVDoctorUploadError = (err) => {
    console.log(err);
  };

  const pharmacyHandleChange = (file) => {
    dispatch(
      pharmacyCsvUpload(
        { vendor_id, division_id: div_id },
        file,
        onPharmacyUploadSuccess,
        onPharmacyUploadError
      )
    );
  };

  const onPharmacyUploadSuccess = (data) => {
    console.log(data.data.data);
    setShowPharmacyModal(true);
    setPharmacyData(data.data.data);
  };

  const onPharmacyUploadError = (err) => {
    console.log(err);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {loading == true ? (
          <div className="list-spinner-container">
            <LoadingSpinnerTable />
          </div>
        ) : (
          <>
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">
                  Divisions - {selectedDivisionData?.code}
                </label>
              </div>
              <div className="col-6">
                <div className="mx-4 px-3 py-2 pt-4">
                  <div className="my-2">
                    <label className="color_747f9e fs_16">Division Name</label>
                    <p className="fs_16 color_262c3d">
                      {selectedDivisionData?.name
                        ? selectedDivisionData?.name
                        : "--"}
                    </p>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e fs_16">Offer Type</label>
                    <p className="fs_16 color_262c3d">
                      {selectedDivisionData?.offer_type
                        ? selectedDivisionData?.offer_type
                          .charAt(0)
                          .toUpperCase() +
                        selectedDivisionData?.offer_type.slice(1)
                        : "--"}
                    </p>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e fs_16">Approval Level</label>
                    <p className="fs_16 color_262c3d">
                      {selectedDivisionData?.approval_level ? selectedDivisionData?.approval_level : "--"}
                    </p>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e fs_16">
                      Reimbursement Method
                    </label>
                    <p className="fs_16 color_262c3d">
                      {selectedDivisionData?.reimbursement_method ? reimbursementOptions[selectedDivisionData?.reimbursement_method] : "--"}
                    </p>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e fs_16">
                      Order limit per customer
                    </label>
                    <p className="fs_16 color_262c3d">
                      {selectedDivisionData?.order_limit
                        ? selectedDivisionData?.order_limit
                          .charAt(0)
                          .toUpperCase() +
                        selectedDivisionData?.order_limit.slice(1)
                        : "--"}{" "}
                      - ₹{" "}
                      {selectedDivisionData?.order_amount
                        ? selectedDivisionData?.order_amount
                        : "--"}
                    </p>
                  </div>
                  <div className="my-2">
                    <label className="color_747f9e fs_16">Status</label>
                    {selectedDivisionData?.status == true ? (
                      <p className="fs_16 text_green">Active</p>
                    ) : (
                      <p className="fs_16 text_red">Inactive</p>
                    )}
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">
                  Upload Bulk Data
                </label>
              </div>
              <div className="mx-4 px-3">
                <div className="data-upload-container justify-content-between px-3 py-4 my-3 w-100 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <label className="fw_600 fs_18">Product Data</label>
                    <label className="fs_12">
                      {/* {file1 ? `File name: ${file1[0].name}` : ""} */}
                    </label>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      style={{
                        backgroundColor: "#D4FAFF",
                        borderRadius: "5px",
                      }}
                      className="d-flex align-items-center fs_15 p-1 px-2"
                    >
                      <img src={info} />
                      <span className="fw_600 ms-1">Download Sample</span>
                      <button
                        style={{ border: "none", backgroundColor: "#D4FAFF" }}
                      >
                        <a
                          href="https://jivini-live.s3.ap-south-1.amazonaws.com/Product-Data.csv"
                          target="_blank"
                        >
                          <img src={downlaod} />
                        </a>
                      </button>
                    </div>
                    <label className="upload-btn text-white px-4 fs_16 ">
                      Upload
                      <input
                        className="upload-document-input"
                        type="file"
                        accept=".csv"
                        onChange={(e) => productHandleChange(e.target.files[0])}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-4 px-3">
                <div className="data-upload-container justify-content-between px-3 py-4 my-3 w-100 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <label className="fw_600 fs_18">
                      Field Force User Data
                    </label>
                    <label className="fs_12">
                      {/* {file1 ? `File name: ${file1[0].name}` : ""} */}
                    </label>
                  </div>

                  <div className="d-flex gap-2">
                    <div
                      style={{
                        backgroundColor: "#D4FAFF",
                        borderRadius: "5px",
                      }}
                      className="d-flex align-items-center fs_15 p-1 px-2"
                    >
                      <img src={info} />
                      <span className="fw_600 ms-1">Download Sample</span>
                      <button
                        style={{ border: "none", backgroundColor: "#D4FAFF" }}
                      >
                        <a
                          href="https://jivini-live.s3.ap-south-1.amazonaws.com/Fieldforce-Data-Upload.csv"
                          target="_blank"
                        >
                          <img src={downlaod} />
                        </a>
                      </button>
                    </div>

                    <label className="upload-btn text-white px-4 fs_16 ">
                      Upload
                      <input
                        className="upload-document-input"
                        type="file"
                        accept=".csv"
                        onChange={(e) => ffHandleChange(e.target.files[0])}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-4 px-3">
                <div className="data-upload-container justify-content-between px-3 py-4 my-3 w-100 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <label className="fw_600 fs_18">Distributor Data</label>
                    <label className="fs_12">
                      {/* {file1 ? `File name: ${file1[0].name}` : ""} */}
                    </label>
                  </div>

                  <div className="d-flex gap-2">
                    <div
                      style={{
                        backgroundColor: "#D4FAFF",
                        borderRadius: "5px",
                      }}
                      className="d-flex align-items-center fs_15 p-1 px-2"
                    >
                      <img src={info} />
                      <span className="fw_600 ms-1">Download Sample</span>
                      <button
                        style={{ border: "none", backgroundColor: "#D4FAFF" }}
                      >
                        <a
                          href="https://jivini-live.s3.ap-south-1.amazonaws.com/Distributor-Data-Upload.csv"
                          target="_blank"
                        >
                          <img src={downlaod} />
                        </a>
                      </button>
                    </div>
                    <label className="upload-btn text-white px-4 fs_16 ">
                      Upload
                      <input
                        className="upload-document-input"
                        type="file"
                        onChange={(e) =>
                          distributorHandleChange(e.target.files[0])
                        }
                        onClick={(e) => (e.target.value = null)}
                        accept=".csv"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-4 px-3">
                <div className="data-upload-container justify-content-between px-3 py-4 my-3 w-100 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <label className="fw_600 fs_18">Doctor Data</label>
                    <label className="fs_12">
                      {/* {file1 ? `File name: ${file1[0].name}` : ""} */}
                    </label>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      style={{
                        backgroundColor: "#D4FAFF",
                        borderRadius: "5px",
                      }}
                      className="d-flex align-items-center fs_15 p-1 px-2"
                    >
                      <img src={info} />
                      <span className="fw_600 ms-1">Download Sample</span>
                      <button
                        style={{ border: "none", backgroundColor: "#D4FAFF" }}
                      >
                        <a
                          href="https://jivini-live.s3.ap-south-1.amazonaws.com/Doctor-Data-Upload.csv"
                          target="_blank"
                        >
                          <img src={downlaod} />
                        </a>
                      </button>
                    </div>
                    <label className="upload-btn text-white px-4 fs_16 ">
                      Upload
                      <input
                        className="upload-document-input"
                        type="file"
                        onChange={(e) => doctorHandleChange(e.target.files[0])}
                        onClick={(e) => (e.target.value = null)}
                        accept=".csv"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-4 px-3">
                <div className="data-upload-container justify-content-between px-3 py-4 my-3 w-100 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <label className="fw_600 fs_18">Pharmacy Data</label>
                    <label className="fs_12">
                      {/* {file1 ? `File name: ${file1[0].name}` : ""} */}
                    </label>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      style={{
                        backgroundColor: "#D4FAFF",
                        borderRadius: "5px",
                      }}
                      className="d-flex align-items-center fs_15 p-1 px-2"
                    >
                      <img src={info} />
                      <span className="fw_600 ms-1">Download Sample</span>
                      <button
                        style={{ border: "none", backgroundColor: "#D4FAFF" }}
                      >
                        <a
                          href="https://jivini-live.s3.ap-south-1.amazonaws.com/Pharmacy-Data-Upload.csv"
                          target="_blank"
                        >
                          <img src={downlaod} />
                        </a>
                      </button>
                    </div>
                    <label className="upload-btn text-white px-4 fs_16 ">
                      Upload
                      <input
                        className="upload-document-input"
                        type="file"
                        onChange={(e) =>
                          pharmacyHandleChange(e.target.files[0])
                        }
                        onClick={(e) => (e.target.value = null)}
                        accept=".csv"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="d-xl-block mx-4">
                <div
                  className="d-flex my-1 py-2 "
                  style={{ overflowX: "scroll" }}
                >
                  <div className="text-nowrap my-1">
                    <button
                      className={
                        activeTab == 1 ? "topbarTabActive mx-2" : "topTab mx-2"
                      }
                      onClick={() => {
                        setActiveTab(1);
                      }}
                    >
                      Products
                    </button>
                  </div>
                  <div className="text-nowrap my-1">
                    <button
                      className={
                        activeTab == 2 ? "topbarTabActive mx-2" : "topTab mx-2"
                      }
                      onClick={() => {
                        setActiveTab(2);
                      }}
                    >
                      Field force
                    </button>
                  </div>
                  <div className="text-nowrap my-1">
                    <button
                      className={
                        activeTab == 3 ? "topbarTabActive mx-2" : "topTab mx-2"
                      }
                      onClick={() => {
                        setActiveTab(3);
                      }}
                    >
                      Doctors
                    </button>
                  </div>
                  <div className="text-nowrap my-1">
                    <button
                      className={
                        activeTab == 4 ? "topbarTabActive mx-2" : "topTab mx-2"
                      }
                      onClick={() => {
                        setActiveTab(4);
                      }}
                    >
                      Pharmacies
                    </button>
                  </div>
                </div>
              </div>

              <div className="view-main-container mx-4">
                {activeTab == 1 && (
                  <>
                    {activeProduct == 11 && (
                      <ProductDivisionList
                        setActiveProduct={setActiveProduct}
                        productDivisionData={productDivisionData}
                        setSelectedProductId={setSelectedProductId}
                        loading={loading}
                      />
                    )}
                    {activeProduct == 12 && (
                      <ProductDivisionView
                        selectedProductId={selectedProductId}
                        setActiveProduct={setActiveProduct}
                      />
                    )}
                  </>
                )}
                {activeTab === 2 && (
                  <>
                    {activeFF == 21 && (
                      <FieldForceDivisionList
                        setActiveFF={setActiveFF}
                        ffDivisionData={ffDivisionData}
                        setselectedFfID={setselectedFfID}
                      />
                    )}
                    {activeFF == 22 && (
                      <FieldForceDivisionView
                        setActiveFF={setActiveFF}
                        data={selectedFfID}
                      />
                    )}
                  </>
                )}
                {activeTab === 3 && (
                  <>
                    {activeDoctor == 31 && (
                      <DoctorDivisionList
                        setActiveDoctor={setActiveDoctor}
                        doctorDivisionData={doctorDivisionData}
                        setSelectedDoctorID={setSelectedDoctorID}
                        vendor_Id={vendor_id}
                        division_id={div_id}
                        doctorDivisionView={doctorDivisionView}
                      />
                    )}
                    {activeDoctor == 32 && (
                      <DoctorDivisionView
                        setActiveDoctor={setActiveDoctor}
                        selectedDoctorID={selectedDoctorID}
                      />
                    )}
                  </>
                )}
                {activeTab === 4 && (
                  <>
                    {activePharmacy == 41 && (
                      <PharmacyDivisionList
                        setActivePharmacy={setActivePharmacy}
                        pharmacyDivisionData={pharmacyDivisionData}
                        setSelectedPharmacyID={setSelectedPharmacyID}
                        pharmacyDivisionView={pharmacyDivisionView}
                        vendor_Id={vendor_id}
                        division_id={div_id}
                      />
                    )}
                    {activePharmacy == 42 && (
                      <PharmacyDivisionView
                        setActivePharmacy={setActivePharmacy}
                        selectedPharmacyID={selectedPharmacyID}
                      />
                    )}
                  </>
                )}
              </div>

              <ProductDataVerificationModal
                show={showProductDetailModal}
                vendor_id={vendor_id}
                division_id={div_id}
                productData={productData}
                // deleteModalOpener={deleteModalOpener}
                ShowClose={() => setShowProductDetailModal(false)}
              />
              <FieldForceDataVerificationModal
                show={showFieldForceModal}
                vendor_id={vendor_id}
                division_id={div_id}
                fieldForceData={fieldForceData}
                ShowClose={() => setFieldForceModal(false)}
              />
              <DistributorDataVerificationModal
                show={showDistributorModal}
                vendor_id={vendor_id}
                division_id={div_id}
                distributorData={distributorData}
                ShowClose={() => setShowDistributorModal(false)}
              />
              <DoctorDataVerificationModal
                show={showDoctorModal}
                vendor_id={vendor_id}
                division_id={div_id}
                doctorData={doctorData}
                ShowClose={() => setshowDoctorModal(false)}
              />
              <PharmacyVerificationModal
                vendor_id={vendor_id}
                division_id={div_id}
                show={showPharmacyModal}
                ShowClose={() => setShowPharmacyModal(false)}
                pharmacyData={pharmacyData}
              />
            </>
          </>
        )}
      </div>
    </>
  );
};

export default DivisionView;
