import React from "react";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const OrderTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  const params = useParams();
  const vendor_id = params?.id.split("&")?.[0];
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  const getStatusValue = (status) => {
    if (status === "pending") {
      return `Approval Pending in level ${data?.current_level}`;
    } else if (
      status === "rejected" &&
      data?.rejected_by_user_type != null &&
      data?.rejected_by_user_type == "field_force_user"
    ) {
      return `Rejected in level ${data?.current_level}`;
    }
    return orderStatus?.find((e) => e?.status === status)?.value;
  };

  const getStatusColor = (status) => {
    if (status === "pending") {
      return "Medium_Purple";
    } else if (status === "rejected") {
      return "text-danger";
    }
    return orderStatus?.find((e) => e?.status === status)?.color;
  };

  return (
    <tr className="table-rows1" key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
        {/* {index + 1} */}
      </td>
      <td className="table-each-row-data">{data?.order_id}</td>
      <td className="table-each-row-data">
        {data?.customer_type === "doctor"
          ? `DR. ${data?.doctor?.name.toUpperCase()}`
          : data?.pharmacy_type == "dispensing" ||
            data?.customer_type !== "doctor"
            ? data?.pharmacy?.pharmacy_name.toUpperCase()
            : data?.pharmacy_type == "dispensing"
              ? "Dispensing"
              : data?.pharmacy_type == "self"
                ? data?.doctorSelfPharmacy?.pharmacy_name.toUpperCase()
                : data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name.toUpperCase()}
      </td>
      <td className="table-each-row-data">
        {moment(data?.createdAt).format("DD MMM YYYY, HH:mm A")}
      </td>
      <td className="table-each-row-data">
        ₹ {parseFloat(data?.total).toFixed(2)}
      </td>
      <td className="table-each-row-data">
        {data?.distributor?.firm_name}
      </td>
      <td className={`table-each-row-data`}>
        <span className={`${getStatusColor(data?.status)}`}>
          {getStatusValue(data?.status)}
        </span>
      </td>
      <td className="table-each-row-data">
        <span
          className={`${invoiceStatus.find((e) => e?.status == data?.invoice_status)?.color
            }`}
        >
          {invoiceStatus.find((e) => e?.status == data?.invoice_status)?.value}
        </span>
      </td>
      <td className="table-each-row-data">
        <span
          className={`${invoiceStatus.find((e) => e?.status == data?.po_status)?.color
            }`}
        >
          {invoiceStatus.find((e) => e?.status == data?.po_status)?.value}
        </span>
      </td>

      <td className="table-each-row-data">
        {data?.rejectionReason?.title ? data?.rejectionReason?.title : "--"}
      </td>
      <td className="table-each-row-data text-capitalize">
        {(data?.rejected_by_user_type == "distributor" &&
          data?.rejectOrderbyDistributor?.firm_name) ||
          (data?.rejected_by_user_type == "field_force_user" &&
            data?.rejectOrderbyFieldForce?.firm_name) ||
          (data?.rejected_by_user_type == "vendor" &&
            data?.rejectOrderbyVendor?.firm_name) ||
          (data?.rejected_by_user_type == "doctor" &&
            data?.rejectOrderbyDoctor?.firm_name) ||
          "--"}
      </td>
      <td className="table-each-row-data">
        <div className="all-button">
          <button
            className="button-view"
            type="button"
            onClick={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendor_id}&orders&view&${data?.id}`
              );
            }}
          >
            {"view"}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default OrderTableData;

export const orderStatus = [
  { status: "acknowledged", value: "Acknowledged", color: "ack_status " },
  {
    status: "partially_acknowledged",
    value: "Partially Acknowledged",
    color: "color_2ad3e7 ",
  },
  { status: "fully_invoiced", value: "Fully Invoiced", color: "text_green" },
  {
    status: "partially_invoiced",
    value: "Partial Invoiced",
    color: "Medium_Purple",
  },
  { status: "pending", value: "Approval Pending", color: "Medium_Purple" },
  { status: "expired", value: "Expired", color: "text-danger" },
  { status: "cancelled", value: "Cancelled", color: "text-danger" },
  { status: "approval_failed", value: "Approval Failed", color: "text-danger" },
  { status: "approved", value: "Approved", color: "color_2ad3e7" },
  { status: "rejected", value: "Rejected", color: "text-danger" },
  { status: "hold", value: "Under Process", color: "text-warning" },
];

const invoiceStatus = [
  { status: "pending", value: "Pending", color: "text-warning" },
  { status: "verified", value: "Verified", color: "text_green" },
  {
    status: "fully_reimbursement",
    value: "Partially Reimbursement",
    color: "Medium_Purple",
  },
];
