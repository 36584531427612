import { api } from "./api";

export const analyticsApi = (params) => {
  return api.get(`/admin/analytics`, {
    params,
  });
};

export const analyticsCheckerApi = (params) => {
  return api.get(`/admin/analytics/analytics-checker`, {
    params,
  });
};

export const manufacturerListApi = () => {
  return api.get(`/admin/analytics/vendor-list`);
};

export const divisionListApi = (params) => {
  return api.get(`/admin/analytics/division-list`,{ params });
};

export const productListApi = (params) => {
  return api.get(`/admin/analytics/product-list`, { params });
};

export const fieldForceListApi = (params) => {
  return api.get(`/admin/analytics/field-force-user-list`, { params });
};

export const distributorListApi = (params) => {
  return api.get(`/admin/analytics/distributor-list`, { params });
};

export const pharmacyListApi = (params) => {
  return api.get(`/admin/analytics/pharmacy-list`, { params });
};

export const doctorListApi = (params) => {
  return api.get(`/admin/analytics/doctor-list`, { params });
};

export const headquaterListApi = (params) => {
  return api.get(`/admin/analytics/head-quater-list`, { params });
};
