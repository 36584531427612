import React, { useEffect, useState } from "react";
import TableContainer from "../../../components/layout/table-container/table-container";
import UpArrow from "../../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../../assets/icons/filterArrowDown.svg";
import ReasonRejectionListTableData from "./reasonRejectionListTableData";
import { settingsRejectReasonList } from "../../../redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import TablePagination from "../../../components/Pagination/tablePagination";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const ReasonRejectionListTable = ({ search }) => {
  const dispatch = useDispatch();
  const [rejectReasonData, setRejectReasonData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    setPage(1);
    fetchRejectReasonList();
  }, [search]);

  useEffect(() => {
    fetchRejectReasonList();
  }, [page]);

  const fetchRejectReasonList = () => {
    dispatch(settingsRejectReasonList({ search, page }, onSuccess, onError));
    setLoading(true);
  };

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setRejectReasonData(data?.data?.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"15%"}>
              Reasons for Rejection
            </th>
            <th className="table-heads " width={"15%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : rejectReasonData?.rejectionReasons?.length > 0 ? (
            <>
              {rejectReasonData?.rejectionReasons?.map((eachRow, index) => (
                <ReasonRejectionListTableData
                  data={eachRow}
                  key={eachRow.id}
                  index={index}
                  fetchRejectReasonList={fetchRejectReasonList}
                  currentPage={rejectReasonData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {rejectReasonData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={rejectReasonData?.pagination}
            nextFunc={() => {
              setPage(page + 1);
            }}
            prevFunc={() => {
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default ReasonRejectionListTable;
