import React from "react";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
    {
        background: "#ffffff",
        border: "1px solid #e7e5ff",
        borderRadius: "15px",

        maxWidth: "50rem",
        height: "32rem",
        overflowY: "scroll",
    },
    (props) => ({
        maxWidth: props.maxWidth,
    })
);

export const TableFooter = styled("div")(
    {
        background: "#2AD3E7",
        border: "1px solid #e7e5ff",
        borderBottomLeftRadius: " 15px",
        borderBottomRightRadius: "15px",
        maxWidth: "45rem",
        height: "3rem",
    },
    (props) => ({
        maxWidth: props.maxWidth,
    })
);

const UploadInvoiceTable = ({
    maxWidth = "70rem",
    invoices,
    updateInvoice,
}) => {
    return (
        <div className="">
            <TableContainer maxWidth={maxWidth}>
                <table className="main-table">
                    <tr className="table-rows">
                        <th className="table-heads text-dark " width={"2%"}>
                            #
                        </th>
                        <th className="table-heads text-dark" width={"11%"}>
                            Product Name
                        </th>
                        <th className="table-heads text-dark" width={"4%"}>
                            Qty
                        </th>
                        <th className="table-heads text-dark" width={"4%"}>
                            Free Qty
                        </th>
                        <th className="table-heads text-dark" width={"8%"}>
                            Invoice Qty
                        </th>
                        <th className="table-heads text-dark" width={"8%"}>
                            Invoice Free Qty
                        </th>
                        <th className="table-heads text-dark" width={"8%"}>
                            Supplied PTR
                        </th>
                    </tr>
                    {invoices &&
                        invoices.length > 0 &&
                        invoices.map((ele, index) => (
                            <tr className="table-rows1" key={ele.id}>
                                <td className="table-each-row-data color_747f9e">
                                    {index + 1}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    {ele?.product_name}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    {ele?.quantity}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    {ele?.offer_qty}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    {ele?.invoice_qty}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    {ele?.invoice_free_qty}
                                </td>
                                <td className="table-each-row-data color_747f9e">
                                    <input
                                        type="number"
                                        min={0}
                                        className="table-input px-2 py-1"
                                        placeholder="Enter ptr"
                                        name="supplied_ptr"
                                        // value={ele?.supplied_ptr}
                                        onChange={(e) => updateInvoice(e, ele)}
                                    />
                                </td>
                            </tr>
                        ))}

                </table>
            </TableContainer>
        </div>
    );
};

export default UploadInvoiceTable;