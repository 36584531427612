import { call, takeLatest } from "redux-saga/effects";
import {
  CREATE_FAQ,
  DELETE_FAQ,
  EDIT_FAQ,
  FAQ_DETAIL,
  FAQ_LIST,
  FAQ_VERSION_DETAIL,
  FAQ_VERSION_LIST,
} from "../actions/types";
import {
  faqCreateApi,
  faqDeleteApi,
  faqEditApi,
  faqListApi,
  faqVersionDetailApi,
  faqVersionListApi,
  faqViewApi,
} from "../apis/faqApi";

export function* faqListWorker(action) {
  try {
    const res = yield call(faqListApi, action.data);
    console.log(res);

    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqCreateWorker(action) {
  try {
    const res = yield call(faqCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqEditWorker(action) {
  try {
    const res = yield call(faqEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqDetailWorker(action) {
  try {
    const res = yield call(faqViewApi, {
      faq_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqVersionWorker(action) {
  try {
    const res = yield call(faqVersionListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqVersionDetailWorker(action) {
  try {
    const res = yield call(faqVersionDetailApi, {
      faq_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqDeleteWorker(action) {
  try {
    const res = yield call(faqDeleteApi, {
      faq_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* faqListWatcher() {
  yield takeLatest(FAQ_LIST, faqListWorker);
}

export function* faqCreateWatcher() {
  yield takeLatest(CREATE_FAQ, faqCreateWorker);
}

export function* faqEditWatcher() {
  yield takeLatest(EDIT_FAQ, faqEditWorker);
}

export function* faqDetailWatcher() {
  yield takeLatest(FAQ_DETAIL, faqDetailWorker);
}

export function* faqVersionWatcher() {
  yield takeLatest(FAQ_VERSION_LIST, faqVersionWorker);
}

export function* faqVersionDetailWatcher() {
  yield takeLatest(FAQ_VERSION_DETAIL, faqVersionDetailWorker);
}

export function* faqDeleteWatcher() {
  yield takeLatest(DELETE_FAQ, faqDeleteWorker);
}
