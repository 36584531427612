import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import ValueInputField from "../../../components/form/inputField/ValueInputField";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import "../settings.css";
import { useDispatch } from "react-redux";
import { manufacturerLogoUpload } from "../../../redux/actions/manufacturerActions";
import upload from "../../../assets/icons/uploadImg.svg";
import closeIcon from "../../../assets/icons/close.png";
import deleteIcon from "../../../assets/icons/image-tools/banner-delete.png";
import removeIcon from "../../../assets/icons/image-tools/remove-banner.svg";
import {
  settingsAdvertisementCreate,
  settingsAdvertisementDelete,
  settingsAdvertisementList,
} from "../../../redux/actions/settingsActions";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import DeleteModal from "../../../components/modals/deleteModal";
import BannerDeleteModal from "../../../components/modals/banner-delete";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const Advertisement = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [addListData, setAddListData] = useState([]);
  const [FillUpArray, setFillUpArray] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    ViewAddList();
  }, []);

  const ViewAddList = () => {
    dispatch(settingsAdvertisementList(onListSuccess, onListError));
  };

  const onListSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setAddListData(data?.data?.data);
  };

  const onListError = (err) => {
    console.log(err);
  };

  function handleUploadOverviewFile(e, setFieldValue, index) {
    if (e.target?.files.length !== 0) {
      const file = e.target.files[0];
      // setFile(file);
      setImageUploadLoading(true)
      dispatch(
        manufacturerLogoUpload(
          file,
          (data) => onLogoUploadSuccess(data, setFieldValue, index),
          onLogoUploadError
        )
      );
    }
    e.target.value = null;
  }
  function handleImgClose(setFieldValue, index) {
    const imgEmpty = "";
    setFieldValue(`advertisements[${index}].img_url`, imgEmpty);
  }
  const onLogoUploadSuccess = (data, setFieldValue, index) => {
    console.log(data);
    const imgUrl = data.data.data.filePath; // Assuming the file path is provided in the data response
    setFieldValue(`advertisements[${index}].img_url`, imgUrl); // Set the img_url field for the specified index in Formik values
    setImageUploadLoading(false)
  };
  const onLogoUploadError = (err) => {
    console.log(err);
  };

  const handleAddCard = (setFieldValue, values) => {
    const previousRowFilled = values.advertisements?.every(
      (item) =>
        item?.validity !== "" && item?.url !== "" && item?.img_url !== ""
    );
    if (previousRowFilled == false) {
      setShowErrorMsg("Fill all banners before adding new banner");
      setShowErrorPopup(true);
    }
    console.log(previousRowFilled);
    if (previousRowFilled) {
      const newCard = {
        img_url: "",
        validity: "",
        url: "",
        is_created: false,
      };
      const updatedCards = [...values.advertisements, newCard];
      setFieldValue("advertisements", updatedCards);
    }
  };
  const handleRemoveCard = (setFieldValue, values, index) => {
    const updatedCards = [...values.advertisements];
    updatedCards.splice(index, 1);
    setFieldValue("advertisements", updatedCards);
    console.log(values);
  };

  const initialValues = {
    advertisements: [
      {
        img_url: "",
        validity: "",
        url: "",
        is_created: false,
      },
      {
        img_url: "",
        validity: "",
        url: "",
        is_created: false,
      },
      {
        img_url: "",
        validity: "",
        url: "",
        is_created: false,
      },
    ],
  };

  useEffect(() => {
    let fillUp = [];
    if (addListData.length == 0) {
      fillUp = [
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
      ];
    } else if (addListData.length == 1) {
      fillUp = [
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
      ];
    } else if (addListData.length == 2) {
      fillUp = [
        {
          img_url: "",
          validity: "",
          url: "",
          is_created: false,
        },
      ];
    }
    setFillUpArray(fillUp);
  }, [addListData]);

  const modifiedResponse = addListData.map((item) => {
    return {
      ...item,
      validity: item.validity_day, // Rename 'validity' to 'validity_day'
    };
  });

  const finalArray = [...modifiedResponse, ...FillUpArray];
  initialValues["advertisements"] = [...finalArray];

  const validationSchema = Yup.object().shape({
    timeLimit: Yup.string(),
    card: Yup.array().of(
      Yup.object().shape({
        validity: Yup.string(),
      })
    ),
  });

  const onSubmit = (values) => {
    console.log(values);
    const finalValues = values.advertisements?.filter(
      (item) => item?.is_created == false && item.validity !== ""
    );
    const filledValues = {
      advertisements: finalValues,
    };

    if (filledValues?.advertisements?.length != 0) {
      dispatch(settingsAdvertisementCreate(filledValues, onSuccess, onError));
    } else {
      setShowErrorMsg("You don't have any new banners to save");
      setShowErrorPopup(true);
    }
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    ViewAddList();
  };

  const onSuccess = (data) => {
    console.log(data);
    console.log(data.data.message);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  console.log(initialValues.advertisements?.[0]?.img_url == "" ? true : false);

  const handleDrop = (e, setFieldValue, index) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]; // Get the first dropped file
    if (file) {
      handleUploadOverviewFile(
        { target: { files: [file] } }, // Simulate the input's onChange event
        setFieldValue,
        index
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow the drop event to fire
  };

  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  advertisement">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <div className="search-container d-flex justify-content-end ">
                <div className="  m-1 mb-2">
                  <AdminButton
                    background="#7065E4"
                    color="white"
                    width="90px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                  <button
                    className="add-more-btn py-2"
                    background="#7065E4"
                    color="white"
                    width="185px"
                    type="button"
                    onClick={() => handleAddCard(setFieldValue, values)}
                  >
                    <span className="m-2">ADD MORE BANNERS</span>
                  </button>
                </div>
              </div>
              {loading == true ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <LoadingSpinnerTable show={loading} />
                </div>
              ) : (
                <div className="row m-4 my-5">
                  {values.advertisements.map((data, index) => (
                    <div
                      key={index}
                      className="col-xxl-4 col-xl-6 ad-banner-container mx-auto mx-xl-0"
                    >
                      {data?.is_created ? (
                        <div className="d-flex justify-content-end m-1">
                          <button
                            className="d-flex align-items-center justify-content-center delete-banner-btn px-3 text-uppercase"
                            type="button"
                            onClick={() => {
                              setDeleteShow(true);
                              setDeletedId(data?.id);
                            }}
                          >
                            <img src={deleteIcon} />
                            <span className="mt-1 ms-1">Delete Banner</span>
                          </button>
                        </div>
                      ) : (
                        <div>
                          {index < 3 && <div className="mt-4 pt-3"></div>}
                        </div>
                      )}
                      {data?.is_created == false && index > 2 && (
                        <div className="d-flex justify-content-end m-1">
                          <button
                            className="d-flex align-items-center justify-content-center remove-banner-btn px-2 text-uppercase"
                            onClick={() =>
                              handleRemoveCard(setFieldValue, values, index)
                            }
                          >
                            <img src={removeIcon} />
                            <span className="ms-2 mt-1">remove banner</span>
                          </button>
                        </div>
                      )}
                      <div className="uploadAdd-image-container mx-auto"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, setFieldValue, index)}
                      >
                        {data?.img_url ? (
                          <>
                            <div className="preview-container position-relative">
                              <img
                                src={data?.img_url}
                                alt="Selected file preview"
                                className="preview-img"
                              />
                              {data?.is_created == false && (
                                <button
                                  style={{ right: 0 }}
                                  className="position-absolute bg-transparent img-close-btn"
                                  type="button"
                                  onClick={() =>
                                    handleImgClose(setFieldValue, index)
                                  }
                                >
                                  <img src={closeIcon} />
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={`h-100 d-flex flex-column align-items-center justify-content-around p-2`}
                          >
                            <label
                              htmlFor={`advertisements[${index}].img_url`}
                              className="choose-file-label"
                            >
                              {imageUploadLoading ? "Uploading..." :
                                <div
                                  className={`d-flex flex-column align-items-center justify-content-center `}
                                >
                                  <img src={upload} width="50px" height="50px" />
                                  <span className="color_747f9e pt-2 fs_14 fs_lg_12">
                                    Drag your image here, or
                                    <span className="Medium_Purple fw_700 ms-1">
                                      Browse
                                    </span>
                                  </span>
                                  <label className="color_747f9e pt-1 fs_10">
                                    Supports: JPEG and PNG images only.
                                  </label>
                                </div>
                              }
                            </label>
                          </div>
                        )}

                        <input
                          type="file"
                          style={{ display: "none" }}
                          id={`advertisements[${index}].img_url`}
                          name={`advertisements[${index}].img_url`}
                          onChange={(e) =>
                            handleUploadOverviewFile(e, setFieldValue, index)
                          }
                          disabled={
                            index > 0 &&
                            (values.advertisements[index - 1].img_url === "" ||
                              values.advertisements[index - 1].validity ===
                              "" ||
                              values.advertisements[index - 1].url === "")
                          }
                        />
                      </div>

                      <div className="my-2">
                        <ValueInputField
                          id={`advertisements[${index}].validity`}
                          label={`Validity`}
                          labelColor={"color_262c3d"}
                          type={"number"}
                          placeholder={"Enter validity of above banner"}
                          name={`advertisements[${index}].validity`}
                          valueText="Days"
                          InputMaxWidth="460px"
                          value={data?.validity}
                          disabled={
                            data?.is_created == true
                              ? true
                              : index > 0 &&
                              (values.advertisements[index - 1].img_url ===
                                "" ||
                                values.advertisements[index - 1].validity ===
                                "" ||
                                values.advertisements[index - 1].url === "")
                          }
                        />
                        <ValueInputField
                          id={`advertisements[${index}].url`}
                          label={`URL`}
                          labelColor={"color_262c3d"}
                          type={"text"}
                          placeholder={"Enter url of above banner"}
                          name={`advertisements[${index}].url`}
                          value={data?.url}
                          InputMaxWidth="460px"
                          disabled={
                            data?.is_created == true
                              ? true
                              : index > 0 &&
                              (values.advertisements[index - 1].img_url ===
                                "" ||
                                values.advertisements[index - 1].validity ===
                                "" ||
                                values.advertisements[index - 1].url === "")
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <BannerDeleteModal
        setLoading={setLoading}
        deletedId={deletedId}
        ViewAddList={ViewAddList}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
    </MainLayout>
  );
};

export default Advertisement;
