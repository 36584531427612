import React, { useEffect, useState } from "react";
import OrdersTable from "../../../../components/manufacturer/ordersTable";
import { useDispatch } from "react-redux";
import { gevOrdersView } from "../../../../redux/actions/godsEyeViewActions";
import moment from "moment";
import back from "../../../../assets/icons/back.svg";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { useParams } from "react-router-dom";

const OrderView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [ordersData, setOrdersData] = useState("");
  const [loading, setLoading] = useState(false);
  const activePath = params?.id.split("&")?.[1];
  const orderId = params?.id.split("&")?.[3];

  useEffect(() => {
    gevOrderView();
  }, []);

  const gevOrderView = () => {
    if (activePath == "orders") {
      dispatch(gevOrdersView(orderId, onOrdersSuccess, onOrdersError));
      setLoading(true);
    }
  };

  const onOrdersSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data);
    setOrdersData(data?.data?.data);
  };

  const onOrdersError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const doctorDetails = [
    {
      heading: "Jivini’s Doctor Code",
      title: ordersData?.doctor?.code ? ordersData?.doctor?.code : "--",
    },
    {
      heading: "Doctor Name",
      title: ordersData?.doctor?.name ? ordersData?.doctor?.name : "--",
    },
    {
      heading: "Pharmacy Type",
      title: ordersData?.doctor?.pharmacy_type
        ? ordersData?.doctor?.pharmacy_type
        : "--",
    },
    {
      heading: "Qualification",
      title: ordersData?.doctor?.qualification
        ? ordersData?.doctor?.qualification
        : "--",
    },
    {
      heading: "Specialization",
      title: ordersData?.doctor?.specialization?.name
        ? ordersData?.doctor?.specialization?.name
        : "--",
    },
    {
      heading: "MCI Number",
      title: ordersData?.doctor?.medical_council_registration_number
        ? ordersData?.doctor?.medical_council_registration_number
        : "--",
    },
    {
      heading: "Onboarded By",
      title: ordersData?.doctor?.onboardingFieldforce?.fieldForceUser?.name
        ? ordersData?.doctor?.onboardingFieldforce?.fieldForceUser?.name
        : "--",
    },
    {
      heading: "Onboarded Date & Time",
      title: ordersData?.doctor?.onboardingFieldforce?.createdAt
        ? moment(ordersData?.doctor?.onboardingFieldforce?.createdAt).format(
          "DD MMM YYYY HH:mm A"
        )
        : "--",
    },
  ];

  const contactDetails = [
    {
      heading: "Mobile Number",
      title: ordersData?.doctor?.mobile ? ordersData?.doctor?.mobile : "--",
    },
    {
      heading: "Alternate Mobile Number",
      title: ordersData?.doctor?.alternate_mobile
        ? ordersData?.doctor?.alternate_mobile
        : "--",
    },
    {
      heading: "Email Address",
      title: ordersData?.doctor?.email ? ordersData?.doctor?.email : "--",
    },
    {
      heading: "Address",
      title: `${ordersData?.doctor?.address?.address_one
        ? ordersData?.doctor?.address?.address_one
        : "--"
        } | ${ordersData?.doctor?.address?.address_two
          ? ordersData?.doctor?.address?.address_two
          : "--"
        } | ${ordersData?.doctor?.address?.address_three
          ? ordersData?.doctor?.address?.address_three
          : "--"
        }`,
    },
    {
      heading: "Landmark",
      title: ordersData?.doctor?.address?.landmark
        ? ordersData?.doctor?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: ordersData?.doctor?.state?.name
        ? ordersData?.doctor?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: ordersData?.doctor?.district?.name
        ? ordersData?.doctor?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: ordersData?.doctor?.city?.name
        ? ordersData?.doctor?.city?.name
        : "--",
    },
    {
      heading: "Pincode",
      title: ordersData?.doctor?.pincode ? ordersData?.doctor?.pincode : "--",
    },
  ];

  const PharmacyBasicData = [
    {
      heading: "Jivini’s Pharmacy Code",
      title: ordersData?.pharmacy?.code ? ordersData?.pharmacy?.code : "--",
    },
    {
      heading: "Pharmacy Name",
      title: ordersData?.pharmacy?.pharmacy_name
        ? ordersData?.pharmacy?.pharmacy_name
        : "--",
    },
    {
      heading: "Pharmacy Type",
      title: ordersData?.pharmacy?.pharmacy_type
        ? ordersData?.pharmacy?.pharmacy_type
        : "--",
    },
    {
      heading: "Drug License Number - 20A",
      title: ordersData?.pharmacy?.drug_licence_number_20_a
        ? ordersData?.pharmacy?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug License Number - 20B",
      title: ordersData?.pharmacy?.drug_licence_number_20_b
        ? ordersData?.pharmacy?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "GST Number",
      title: ordersData?.pharmacy?.gst_number
        ? ordersData?.pharmacy?.gst_number
        : "--",
    },
  ];

  const PharmacyContactData = [
    {
      heading: "Mobile Number",
      title: ordersData?.pharmacy?.mobile ? ordersData?.pharmacy?.mobile : "--",
    },
    {
      heading: "Alternate Mobile Number",
      title: ordersData?.pharmacy?.alternate_mobile
        ? ordersData?.pharmacy?.alternate_mobile
        : "--",
    },
    {
      heading: "Email Address",
      title: ordersData?.pharmacy?.email ? ordersData?.pharmacy?.email : "--",
    },
    {
      heading: "Address",
      title: `${ordersData?.pharmacy?.address?.address_one
        ? ordersData?.pharmacy?.address?.address_one
        : "--"
        }${ordersData?.pharmacy?.address?.address_two ? "," : ""} ${ordersData?.pharmacy?.address?.address_two
          ? ordersData?.pharmacy?.address?.address_two
          : ""
        }${ordersData?.pharmacy?.address?.address_three ? "," : ""} ${ordersData?.pharmacy?.address?.address_three
          ? ordersData?.pharmacy?.address?.address_three
          : ""
        }`,
    },
    {
      heading: "Landmark",
      title: ordersData?.pharmacy?.address?.landmark
        ? ordersData?.pharmacy?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: ordersData?.pharmacy?.state?.name,
    },
    {
      heading: "District",
      title: ordersData?.pharmacy?.district?.name,
    },
    {
      heading: "City",
      title: ordersData?.pharmacy?.city?.name,
    },
    {
      heading: "Pincode",
      title: ordersData?.pharmacy?.pincode,
    },
  ];

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="scroll-container">
          {loading == true ? (
            <div className="view-spinner-container">
              <LoadingSpinnerTable />
            </div>
          ) : (
            <>
              <div className="px-3">
                {ordersData?.customer_type === "doctor" && (
                  <>
                    <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                      Doctor’s Basic details
                    </div>
                    <div className="pt-3 ps-3 ms-2 row">
                      {doctorDetails?.map((item, index) => (
                        <div
                          className="mb-2 col-md-5 col-lg col-xl-2 mx-lg-5 mx-xxl-0"
                          style={{ minWidth: "150px" }}
                        >
                          <h5 className="color_b0b3c7 text-nowrap fs_15 fs_xs_10 fs_sm_12 fw_500 m-0">
                            {item.heading}
                          </h5>

                          <p
                            className={`fs_16 fs_xs_12 fs_sm_14 fn_Nunito  fw_600 text-capitalize`}
                          >
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                    <>
                      <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                        Doctor’s Contact Details
                      </div>
                      <div className="pt-3 ps-3 ms-2 row">
                        {contactDetails?.map((item, index) => (
                          <div
                            className="mb-2 col-md-5 col-lg col-xl-2 mx-lg-4 mx-xxl-0"
                            style={{ minWidth: "180px" }}
                          >
                            <h5 className="color_b0b3c7 text-nowrap fs_15 fs_xs_10 fs_sm_12 fw_500 m-0">
                              {item.heading}
                            </h5>

                            {
                              item.heading == "Email Address" ? (
                                <p
                                  className={`fs_16 fs_xs_12 fs_sm_14 fn_Nunito text-nowrap fw_600 email-slice text-truncate`}
                                  title={item.title}
                                >
                                  {item.title}
                                </p>
                              ) :
                                item.heading == "Address" ? (
                                  <p
                                    className={`fs_16 fs_xs_12 fs_sm_14 fn_Nunito fw_600`}
                                  >
                                    {item.title}
                                  </p>
                                ) : (
                                  <p
                                    className={`fs_16 fs_xs_12 fs_sm_14 fn_Nunito text-nowrap fw_600`}
                                  >
                                    {item.title}
                                  </p>
                                )}
                          </div>
                        ))}
                      </div>
                    </>
                  </>
                )}

                <>
                  {ordersData?.customer_type === "pharmacy" && (
                    <>
                      <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                        Pharmacy’s Basic details
                      </div>
                      <div className="pt-3 ps-3 ms-2 row">
                        {PharmacyBasicData?.map((item, index) => (
                          <div
                            className="mb-2 col-md-5 col-lg col-xl-2 mx-lg-5 mx-xxl-0"
                            style={{ minWidth: "150px" }}
                          >
                            <h5 className="color_b0b3c7 text-nowrap fs_15 fs_xs_10 fs_sm_12 fw_500 m-0">
                              {item.heading}
                            </h5>

                            <p
                              className={`fs_16 fs_xs_12 fs_sm_14 fn_Nunito text-nowrap fw_600 text-capitalize`}
                            >
                              {item.title}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                        Pharmacy’s Contact details
                      </div>
                      <div className="pt-3 ps-3 ms-2 row">
                        {PharmacyContactData?.map((item, index) => (
                          <div
                            key={index}
                            className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                            style={{ minWidth: "150px" }}
                          >
                            <h5 className="color_b0b3c7 text-nowrap fs_15 fs_xs_10 fs_sm_12 fw_500 m-0">
                              {item.heading}
                            </h5>
                            <p
                              className="fs_16 fs_xs_12 fs_sm_14 fn_Nunito fw_600 text-capitalize text-break"
                              style={{ whiteSpace: "normal", overflowWrap: "break-word" }}
                            >
                              {item.title}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
                <>
                  <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                    Order Details
                  </div>
                  <div className="mx-4">
                    <OrdersTable data={ordersData} total={ordersData?.total} />
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderView;
