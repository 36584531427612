import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";
import { settingsRejectReasonCreate } from "../../../redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const ReasonRejectionCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState()
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    title: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
  });
  const clearFunc = (setFieldValue) => {
    setFieldValue("title", "");
  };

  const onSubmit = (values) => {
    console.log(values);
    dispatch(settingsRejectReasonCreate(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/settings/reason-for-rejection");
  }
  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessMsg(data.data.message)
    setShowSuccessPopup(true)
    setTimeout(timeOutFnc, 2000)
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <MainLayout breadCrumb="ADMIN  /  settings  /  Reasons for rejection  / Create">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className="">
                <div className="search-container d-flex justify-content-between ">
                  <div
                    className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                    onClick={() => navigate(-1)}
                  >
                    <img src={back} alt="back_btn" />
                    <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                      BACK
                    </span>
                  </div>
                  <div className=" d-flex justify-content-between m-1 mb-2">
                    <AdminButton
                      background="#E7E5FF"
                      width="100px"
                      type="button"
                      onClick={() => clearFunc(setFieldValue)}
                    >
                      CLEAR
                    </AdminButton>
                    <AdminButton
                      background="#7065e4"
                      color="white"
                      width="100px"
                      type="submit"
                    >
                      <span className="m-2">CREATE</span>
                    </AdminButton>
                  </div>
                </div>
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="my-4 ">
                    <InputField
                      id={"title"}
                      label={"Reasons for Rejection"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter reason for rejection"}
                      name={"title"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </MainLayout>

      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ReasonRejectionCreate;
