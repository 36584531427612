import React, { useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import CustomerDistriReqListTable from "./CustomerDistriReqListTable";

const CustomerDistributorReqList = () => {
  const [search, setSearch] = useState(null);
  return (
    <MainLayout breadCrumb="ADMIN / Customers / Change Distributor Request">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search customers here by Jivini’s Doctor Code, Jivini’s Pharmacy Code, Doctor Name, Pharmacy Name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <CustomerDistriReqListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default CustomerDistributorReqList;
