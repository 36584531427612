import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../../buttons/versionHistoryBtn";

const FieldForceBulkErrorModal = ({ show, ShowClose, ffBluckStatusData }) => {
  console.log(ffBluckStatusData, "data");
  return (
    <Modal
      centered
      show={show}
      onHide={ShowClose}
      dialogClassName="field-force-table-container col-10 px-5 mx-auto"
      size=""
    >
      <ModalHeader className="d-flex justify-content-between align-items-center">
        <div>
          <span className="fn_Nunito fw_600 fs_18">
            Field Force User data upload report
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          <AdminButton
            background="#E7E5FF"
            width="100px"
            type="button"
            onClick={() => ShowClose()}
          >
            CLOSE
          </AdminButton>
        </div>
      </ModalHeader>
      <ModalBody className="text-nowrap">
        <div className="mx-3">
          <span>
            Field Force User created successfully
            <span className="color_2ad3e7 ms-1">
              {ffBluckStatusData?.createdUsers?.length}
            </span>
          </span>
        </div>

        {/* CREATED */}
        {ffBluckStatusData?.createdUsers?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#24d626", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : Created
              <span className="mx-1">
                ( {ffBluckStatusData?.createdUsers?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.createdUsers?.map((item, index) => (
                      <tr className="table-rows1 ">
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data">
                          {item?.employee_id}
                        </td>
                        <td className="table-each-row-data">{item?.name}</td>
                        <td className="table-each-row-data">{item?.email}</td>
                        <td className="table-each-row-data">{item?.mobile}</td>
                        <td className="table-each-row-data">
                          {item?.fieldForceDesignation?.name}
                        </td>
                        <td className="table-each-row-data">
                          {item?.vendorDivision?.name}
                        </td>

                        <td className="table-each-row-data">
                          {item?.fieldForceUserHeadQuaters?.length > 0 &&
                            item?.fieldForceUserHeadQuaters?.map(
                              (ele, index) => {
                                return (
                                  <span className="slice-word" key={index}>
                                    <span style={{ color: "#B0B3C7" }}>
                                      {index ? " | " : ""}
                                    </span>
                                    {ele?.headQuater?.name}
                                  </span>
                                );
                              }
                            )}
                        </td>
                        <td className="table-each-row-data">
                          {item?.state?.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* EMPLOYEE ID */}
        {ffBluckStatusData?.employeeIdExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : Employee id already exists
              <span className="mx-1">
                ( {ffBluckStatusData?.employeeIdExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.employeeIdExists?.map((item, index) => (
                      <tr className="table-rows1 ">
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data">
                          {item?.employee_id}
                        </td>
                        <td className="table-each-row-data">{item?.name}</td>
                        <td className="table-each-row-data">{item?.email}</td>
                        <td className="table-each-row-data">{item?.mobile}</td>
                        <td className="table-each-row-data">
                          {item?.designation}
                        </td>
                        <td className="table-each-row-data">
                          {item?.division_name}
                        </td>
                        <td className="table-each-row-data">
                          {item?.headQuarters}
                        </td>
                        <td className="table-each-row-data">{item?.state}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* DESIGNATION */}
        {ffBluckStatusData?.designationNotExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : Designation not exists
              <span className="mx-1">
                ( {ffBluckStatusData?.designationNotExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.designationNotExists?.map(
                      (item, index) => (
                        <tr className="table-rows1 ">
                          <td className="table-each-row-data">{index + 1}</td>
                          <td className="table-each-row-data">
                            {item?.employee_id}
                          </td>
                          <td className="table-each-row-data">{item?.name}</td>
                          <td className="table-each-row-data">{item?.email}</td>
                          <td className="table-each-row-data">
                            {item?.mobile}
                          </td>
                          <td className="table-each-row-data">
                            {item?.designation}
                          </td>
                          <td className="table-each-row-data">
                            {item?.division_name}
                          </td>
                          <td className="table-each-row-data">
                            {item?.headQuarters}
                          </td>
                          <td className="table-each-row-data">{item?.state}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* EMAIL */}
        {ffBluckStatusData?.emailAlreadyExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : E-mail already exists
              <span className="mx-1">
                ( {ffBluckStatusData?.emailAlreadyExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.emailAlreadyExists?.map(
                      (item, index) => (
                        <tr className="table-rows1 ">
                          <td className="table-each-row-data">{index + 1}</td>
                          <td className="table-each-row-data">
                            {item?.employee_id}
                          </td>
                          <td className="table-each-row-data">{item?.name}</td>
                          <td className="table-each-row-data">{item?.email}</td>
                          <td className="table-each-row-data">
                            {item?.mobile}
                          </td>
                          <td className="table-each-row-data">
                            {item?.designation}
                          </td>
                          <td className="table-each-row-data">
                            {item?.division_name}
                          </td>
                          <td className="table-each-row-data">
                            {item?.headQuarters}
                          </td>
                          <td className="table-each-row-data">{item?.state}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* HEAD QUARTER */}
        {ffBluckStatusData?.headQuarterNotExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : Head Quarter already exists
              <span className="mx-1">
                ( {ffBluckStatusData?.headQuarterNotExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.headQuarterNotExists?.map(
                      (item, index) => (
                        <tr className="table-rows1 ">
                          <td className="table-each-row-data">{index + 1}</td>
                          <td className="table-each-row-data">
                            {item?.employee_id}
                          </td>
                          <td className="table-each-row-data">{item?.name}</td>
                          <td className="table-each-row-data">{item?.email}</td>
                          <td className="table-each-row-data">
                            {item?.mobile}
                          </td>
                          <td className="table-each-row-data">
                            {item?.designation}
                          </td>
                          <td className="table-each-row-data">
                            {item?.division_name}
                          </td>
                          <td className="table-each-row-data">
                            {item?.headQuarters}
                          </td>
                          <td className="table-each-row-data">{item?.state}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* MOBILE */}
        {ffBluckStatusData?.mobileAlreadyExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : Mobile already exists
              <span className="mx-1">
                ( {ffBluckStatusData?.mobileAlreadyExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.mobileAlreadyExists?.map(
                      (item, index) => (
                        <tr className="table-rows1 ">
                          <td className="table-each-row-data">{index + 1}</td>
                          <td className="table-each-row-data">
                            {item?.employee_id}
                          </td>
                          <td className="table-each-row-data">{item?.name}</td>
                          <td className="table-each-row-data">{item?.email}</td>
                          <td className="table-each-row-data">
                            {item?.mobile}
                          </td>
                          <td className="table-each-row-data">
                            {item?.designation}
                          </td>
                          <td className="table-each-row-data">
                            {item?.division_name}
                          </td>
                          <td className="table-each-row-data">
                            {item?.headQuarters}
                          </td>
                          <td className="table-each-row-data">{item?.state}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* STATE */}
        {ffBluckStatusData?.stateNotExists?.length > 0 && (
          <div className="my-2 mt-4">
            <div
              style={{ background: "#FF6F5B", borderRadius: "5px" }}
              className="p-2 text-white"
            >
              Field Force User : state already exists
              <span className="mx-1">
                ( {ffBluckStatusData?.stateNotExists?.length} )
              </span>
            </div>
            <div className="modal-table-container my-2">
              <div
                className="table-container text-nowrap"
                style={{ minHeight: "13.5rem", maxHeight: "13.5rem" }}
              >
                <table className="main-table">
                  <thead>
                    <tr className="table-rows color_747f9e">
                      <th className="table-heads " width={"3%"}>
                        SL No.
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Company's Employee ID
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Employee Name
                      </th>
                      <th className="table-heads " width={"14%"}>
                        Email Address
                      </th>
                      <th className="table-heads " width={"8%"}>
                        Mobile
                      </th>
                      <th className="table-heads " width={"12%"}>
                        Designation
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Division
                      </th>
                      <th className="table-heads " width={"10%"}>
                        Head Quaters
                      </th>
                      <th className="table-heads " width={"10%"}>
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ffBluckStatusData?.stateNotExists?.map((item, index) => (
                      <tr className="table-rows1 ">
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data">
                          {item?.employee_id}
                        </td>
                        <td className="table-each-row-data">{item?.name}</td>
                        <td className="table-each-row-data">{item?.email}</td>
                        <td className="table-each-row-data">{item?.mobile}</td>
                        <td className="table-each-row-data">
                          {item?.designation}
                        </td>
                        <td className="table-each-row-data">
                          {item?.division_name}
                        </td>
                        <td className="table-each-row-data">
                          {item?.headQuarters}
                        </td>
                        <td className="table-each-row-data">{item?.state}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FieldForceBulkErrorModal;
