import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { faqList } from "../../redux/actions/faqActions";
import FaqListTableData from "./faq-list-table-data";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import "./faq.css";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const FaqListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [faqListData, setfaqListData] = useState([]);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  // parseInt(location?.state?.page)  --------------- pagination ---------------

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      fetchFaqList();
    }
  }, [search]);

  useEffect(() => {
    fetchFaqList();
  }, [page, search]);

  const fetchFaqList = () => {
    setLoading(true);
    dispatch(faqList({ search, page }, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data.pagination);
    setfaqListData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead style={{ position: "relative" }}>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              FAQ ID
            </th>
            <th className="table-heads " width={"10%"}>
              FAQ Questions
            </th>
            <th className="table-heads " width={"10%"}>
              Created for
            </th>
            <th className="table-heads " width={"10%"}>
              Created By
            </th>
            <th className="table-heads " width={"22%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : faqListData?.faqs?.length > 0 ? (
            <>
              {faqListData?.faqs?.map((eachRow, index) => (
                <FaqListTableData
                  data={eachRow}
                  key={eachRow.id}
                  index={index}
                  fetchFaqList={fetchFaqList}
                  currentPage={faqListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {faqListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={faqListData?.pagination}
            nextFunc={() => {
              navigate(`/faq/${faqListData?.pagination?.currentPage + 1}`);
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(`/faq/${faqListData?.pagination?.currentPage - 1}`);
              setPage(page - 1);
            }}
          />
        )}{" "}
      </div>

      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default FaqListTable;
