import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const SuperUserVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data.employee_id}</td>
      <td className="table-each-row-data">{data.name}</td>
      <td className="table-each-row-data">{data.email}</td>
      <td className="table-each-row-data">{data.mobile}</td>
      <td className="table-each-row-data">
        {data?.adminVersion?.updatedBy?.name
          ? data?.adminVersion?.updatedBy?.name
          : "--"}
      </td>
      <td className="table-each-row-data">
        {moment(data?.adminVersion?.updatedAt).format("DD MMM YYYY HH:mm A")}
      </td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/super-user/user/version-view/${data.id}`)}
          >
            <span className=""> View</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default SuperUserVersionTableData;
