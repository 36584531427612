import React from "react";

const SupportSvg = ({ fill = "#B0B3C7", width = "14px", height = "14px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4386_23499)">
        <path
          d="M4.0833 6.99996C4.08341 6.42846 4.25247 5.86974 4.56921 5.39404L1.65955 2.48438C0.588212 3.74505 0 5.34556 0 6.99996C0 8.65436 0.588212 10.2549 1.65955 11.5155L4.56921 8.60588C4.25247 8.13018 4.08341 7.57146 4.0833 6.99996Z"
          fill={fill}
        />
        <path
          d="M12.3394 2.48438L9.42969 5.39404C9.74652 5.8697 9.91557 6.42844 9.91557 6.99996C9.91557 7.57147 9.74652 8.13022 9.42969 8.60588L12.3394 11.5155C13.4107 10.2549 13.9989 8.65436 13.9989 6.99996C13.9989 5.34556 13.4107 3.74505 12.3394 2.48438Z"
          fill={fill}
        />
        <path
          d="M6.99996 9.91756C6.42846 9.91744 5.86974 9.74839 5.39404 9.43164L2.48438 12.3413C3.74505 13.4126 5.34556 14.0009 6.99996 14.0009C8.65436 14.0009 10.2549 13.4126 11.5155 12.3413L8.60588 9.43164C8.13018 9.74839 7.57146 9.91744 6.99996 9.91756Z"
          fill={fill}
        />
        <path
          d="M6.99996 4.0833C7.57146 4.08341 8.13018 4.25247 8.60588 4.56921L11.5155 1.65955C10.2549 0.588212 8.65436 0 6.99996 0C5.34556 0 3.74505 0.588212 2.48438 1.65955L5.39404 4.56921C5.86974 4.25247 6.42846 4.08341 6.99996 4.0833Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4386_23499">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SupportSvg;
