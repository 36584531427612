import React, { useEffect, useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FieldForceDivisionList = ({
  setActiveFF,
  ffDivisionData,
  setselectedFfID,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(ffDivisionData);
  }, [ffDivisionData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value.replace(/ /g, "");
    setSearchInput(value);

    if (trimmedValue === "") {
      setFilteredData(ffDivisionData);
    } else {
      const filtered = ffDivisionData.filter((data) => {
        const ffWithoutSpaces =
          data?.code
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase()) ||
          data?.name
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase());
        return ffWithoutSpaces;
      });
      setFilteredData(filtered);
    }
  };

  return (
    <>
      <div className="main-list-container my-2 py-1 d-flex justify-content-between flex-column">
        <main className="d-flex flex-column w-100 main-container">
          <div className="search-container d-flex justify-content-between ">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 text-dark px-2"
                type="text"
                placeholder="Search fieldforce here by Company’s Employee ID, Employee Name..."
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div style={{ height: "32rem", overflow: "auto" }}>
            <table className="main-table  text-nowrap">
              <thead
                style={{ position: "sticky", top: 0, zIndex: 55 }}
                className="bg-white"
              >
                <tr className="table-rows color_747f9e">
                  <th className="table-heads py-2" width={"4%"}>
                    SL. No
                  </th>
                  <th className="table-heads py-2" width={"10%"}>
                    User ID
                  </th>
                  <th className="table-heads py-2" width={"10%"}>
                    Field Force ID
                  </th>
                  <th className="table-heads py-2" width={"10%"}>
                    Company’s Employee ID
                  </th>
                  <th className="table-heads py-2" width={"12%"}>
                    Employee Name
                  </th>
                  <th className="table-heads py-2" width={"15%"}>
                    Designation
                  </th>
                  <th className="table-heads py-2" width={"9%"}>
                    Division
                  </th>
                  <th className="table-heads py-2" width={"9%"}>
                    Head Quarters
                  </th>
                  {/* <th className="table-heads py-2" width={"9%"}>
                    State
                  </th> */}
                  <th className="table-heads py-2" width={"9%"}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {filteredData?.length > 0 ? (
                  filteredData?.map((data, index) => {
                    const renderHqTooltip = (props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {data?.fieldForceUserHeadQuaters?.map((ele, index) => (
                          <span key={index}>
                            {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                            {ele?.headQuater?.name ? ele?.headQuater?.name : "--"}
                          </span>
                        ))}
                      </Tooltip>
                    );

                    const renderDivTooltip = (props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {data?.fieldForceDivisions?.map((ele, index) => (
                          <span key={index}>
                            {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                            {ele?.vendorDivision?.name ? ele?.vendorDivision?.name : "--"}
                          </span>
                        ))}
                      </Tooltip>
                    );

                    return (
                      <tr className="table-rows1 color_262c3d" key={index}>
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.user_id ? data?.user_id : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.code ? data?.code : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.employee_id ? data?.employee_id : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.name ? data?.name : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.fieldForceDesignation?.name
                            ? data?.fieldForceDesignation?.name
                            : "--"}
                        </td>

                        {/* <td className="table-each-row-data text-capitalize">
                          {data?.vendorDivision?.name
                            ? data?.vendorDivision?.name
                            : "--"}
                        </td> */}
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderDivTooltip}
                        >
                          <td className="table-each-row-data text-capitalize text-truncate" style={{ maxWidth: "200px" }}>
                            {data?.fieldForceDivisions?.length > 0
                              ? data?.fieldForceDivisions?.map(
                                (ele, index) => {
                                  return (
                                    <span className="slice-word" key={index}>
                                      <span style={{ color: "#B0B3C7" }}>
                                        {index ? " | " : ""}
                                      </span>
                                      {ele?.vendorDivision?.name
                                        ? ele?.vendorDivision?.name
                                        : "--"}
                                    </span>
                                  );
                                }
                              )
                              : "--"}
                          </td>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderHqTooltip}
                        >
                          <td className="table-each-row-data text-capitalize text-truncate" style={{ maxWidth: "200px" }}>
                            {data?.fieldForceUserHeadQuaters?.length > 0
                              ? data?.fieldForceUserHeadQuaters?.map(
                                (ele, index) => {
                                  return (
                                    <span className="slice-word" key={index}>
                                      <span style={{ color: "#B0B3C7" }}>
                                        {index ? " | " : ""}
                                      </span>
                                      {ele?.headQuater?.name
                                        ? ele?.headQuater?.name
                                        : "--"}
                                    </span>
                                  );
                                }
                              )
                              : "--"}
                          </td>
                        </OverlayTrigger>
                        {/* <td className="table-each-row-data text-capitalize">
                          {data?.state?.name ? data?.state?.name : "--"}
                        </td> */}
                        <td className="table-each-row-data">
                          <button
                            className="button-view"
                            type="button"
                            onClick={() => {
                              setActiveFF(22);
                              setselectedFfID(data?.id);
                            }}
                          >
                            <span className=""> View</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default FieldForceDivisionList;
