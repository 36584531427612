import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import "./manufacturerModal.css";
import TableContainer from "../../layout/table-container/table-container";
import DistributorVerificationTableData from "./distributor-verification-table-data";
import { useDispatch } from "react-redux";
import { distributorBulkVerify } from "../../../redux/actions/manufacturerActions";
import DistributorBulkErrorModal from "./bulkupload-error-modals/distributorBulkErrorModal";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopup from "../../popups/SuccessPopup";
import UnablePopup from "../../popups/UnablePopUp";

const DistributorDataVerificationModal = ({
  show,
  ShowClose,
  distributorData,
  vendor_id,
  division_id,
}) => {
  const dispatch = useDispatch();
  const [verifiedList, setVerifiedList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [distributorBluckStatusData, setDistributorBluckStatusData] = useState("");
  const [distributorErrorModal, setDistributorErrorModal] = useState(false);
  const [showUnablePopup, setShowUnablePopup] = useState(false);
  const [showUnableMsg, setShowUnableMsg] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");

  const checkedDistributor = (e, data) => {
    const { checked } = e.target;
    let newArr = verifiedList;
    setVerifiedList([...newArr, data]);
    if (!checked) {
      setVerifiedList(
        verifiedList.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(data)
        )
      );
    }
  };

  const checkAll = (e) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (checked) {
      setVerifiedList(distributorData?.distributorData);
    } else {
      setVerifiedList([]);
    }
  };

  useEffect(() => {
    console.log(verifiedList);
    if (verifiedList?.length != distributorData?.distributorData?.length) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [verifiedList]);

  useEffect(() => {
    setBulkUploadData(distributorData?.distributorData);
  }, [distributorData]);

  const verifyDistributorHandler = () => {
    console.log(vendor_id, division_id, distributorData);
    dispatch(
      distributorBulkVerify(
        {
          vendor_id,
          division_id,
          distributorData: verifiedList,
        },
        onVerifySuccess,
        onVerifyError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setShowUnablePopup(false);
    setDistributorErrorModal(true);
    setIsCheckAll(false);
  };

  const onVerifySuccess = (data) => {
    console.log(data);
    ShowClose();
    setDistributorBluckStatusData(data?.data?.data);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    if (data.data.message == "unable to create distributor" || data.data.message == "unable to create distributors") {
      setShowUnablePopup(true);
      setShowUnableMsg(str_modify);
    } else {
      setShowSuccessPopup(true);
      setShowSuccessMsg(str_modify);
    }
    setTimeout(timeOutFnc, 2000);
    setVerifiedList([]);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onVerifyError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setVerifiedList([]);
  };

  const searchHandler = (e) => {
    let newArr = distributorData.distributorData.filter(
      (distributor) =>
        distributor.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        distributor.email
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        distributor.mobile.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setBulkUploadData(newArr);
  };
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={ShowClose}
        dialogClassName="distributor-table-container col-10 px-5 mx-auto"
        size=""
      >
        <ModalHeader className="d-flex justify-content-between align-items-center">
          <div>
            <span className="fn_Nunito fw_600 fs_18">
              Distributor Data -
              <span className="fw_500">
                {distributorData?.original_name?.length > 35
                  ? distributorData?.original_name?.substring(0, 35) + "..."
                  : distributorData?.original_name}
              </span>
            </span>
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            {/* <AdminButton
            background="#FF6F5B"
            color="white"
            width="100px"
            type="submit"
          >
            <span className="m-2">DELETE</span>
          </AdminButton> */}
            <AdminButton
              background="#E7E5FF"
              width="100px"
              type="button"
              onClick={() => {
                ShowClose();
              }}
            >
              CLOSE
            </AdminButton>
            <AdminButton
              background="#2AD3E7"
              color="white"
              width="100px"
              type="button"
              disabled={verifiedList?.length == 0}
              onClick={verifyDistributorHandler}
            >
              <span className="m-2">VERIFY</span>
            </AdminButton>
          </div>
        </ModalHeader>
        <ModalBody className="text-nowrap">
          <div className="modal-table-container">
            <div className="search-container d-flex justify-content-between ">
              <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                <img className="styled-SVG" src={searcher} color={""} />
                <input
                  className="search-input fs_14 fw_400 px-2"
                  type="text"
                  placeholder="Search distributor here by Distrtibutor Name..."
                  onChange={searchHandler}
                />
              </div>
            </div>
            <div
              className="table-container text-nowrap"
              style={{ minHeight: "36rem", maxHeight: "36rem" }}
            >
              <table className="main-table">
                <tr className="table-rows color_747f9e">
                  <th className="table-heads " width={"4%"}>
                    <input
                      type={"checkbox"}
                      className="CheckBox my-1"
                      value="Full order forward from CFA"
                      name="ReimbursementCheck"
                      checked={isCheckAll}
                      onChange={checkAll}
                    />
                  </th>
                  <th className="table-heads " width={"4%"}>
                    SL. No
                  </th>
                  <th className="table-heads " width={"14%"}>
                    Firm Name
                  </th>
                  <th className="table-heads " width={"14%"}>
                    Phone Number
                  </th>
                  <th className="table-heads " width={"18%"}>
                    Email Adrress
                  </th>
                  <th className="table-heads " width={"22%"}>
                    Actions
                  </th>
                </tr>
                {bulkUploadData?.length > 0 &&
                  bulkUploadData?.map((eachRow, index) => (
                    <DistributorVerificationTableData
                      data={eachRow}
                      key={index}
                      index={index}
                      checkedDistributor={checkedDistributor}
                      isChecked={verifiedList.includes(eachRow)}
                    />
                  ))}
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <DistributorBulkErrorModal
        show={distributorErrorModal}
        ShowClose={() => setDistributorErrorModal(false)}
        distributorBluckStatusData={distributorBluckStatusData}
      />

      <UnablePopup
        show={showUnablePopup}
        title={showUnableMsg} />

      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default DistributorDataVerificationModal;
