import { call, takeLatest } from "redux-saga/effects";
import {
  gevCfaCreateApi,
  gevCfaEditApi,
  gevCfaListApi,
  gevCfaReportViewListApi,
  gevCfaViewApi,
  gevDesignationListApi,
  gevDesignationReportViewListApi,
  gevDesignationViewApi,
  gevDesignationViewListApi,
  gevDivisionListApi,
  gevDivisionReportViewListApi,
  gevDivisonViewListApi,
  gevFFReportViewListApi,
  gevFieldForceEditApi,
  gevFieldForceListApi,
  gevFieldForceViewApi,
  gevHeadQuaterViewListApi,
  gevHeadquaterListApi,
  gevHqReportViewListApi,
  gevHqViewApi,
  gevOrdersListApi,
  gevOrdersReportViewListApi,
  gevOrdersViewApi,
  gevProductCreateApi,
  gevProductEditApi,
  gevProductListApi,
  gevProductReportViewListApi,
  gevProductViewApi,
  gevRepManagerListApi,
  gevdivisionViewViewApi,
  headQuaterListApi,
  packingTypeApi,
  productDeleteApi,
  productDoctorListApi,
  productPharmacyListApi,
  productStateListApi,
  sendCfaMailApi,
  vendorDivisionListApi,
} from "../apis/godsEyeViewApis";
import {
  DESIGNATION_LIST,
  FETCH_DOCTOR_LIST,
  FETCH_PHARMACY_LIST,
  GEV_CFA_CREATE,
  GEV_CFA_EDIT,
  GEV_CFA_LIST,
  GEV_CFA_REPORT_LIST,
  GEV_CFA_SEND_MAIL,
  GEV_CFA_VIEW,
  GEV_DESIGNATION_LIST,
  GEV_DESIGNATION_REPORT_LIST,
  GEV_DESIGNATION_VIEW,
  GEV_DIVISION_LIST,
  GEV_DIVISION_REPORT_LIST,
  GEV_DIVISION_VIEW,
  GEV_DIVISION_VIEW_LIST,
  GEV_FF_REPORT_LIST,
  GEV_FIELDFORCE_EDIT,
  GEV_FIELDFORCE_LIST,
  GEV_FIELDFORCE_VIEW,
  GEV_HEADQUATER_LIST,
  GEV_HEADQUATER_VIEW,
  GEV_HEADQUATER_VIEW_LIST,
  GEV_HQ_REPORT_LIST,
  GEV_ORDERS_LIST,
  GEV_ORDERS_REPORT_LIST,
  GEV_ORDERS_VIEW,
  GEV_PRODUCT_CREATE,
  GEV_PRODUCT_DELETE,
  GEV_PRODUCT_EDIT,
  GEV_PRODUCT_LIST,
  GEV_PRODUCT_REPORT_LIST,
  GEV_PRODUCT_VIEW,
  GEV_REP_MANAGER_LIST,
  PRODUCT_HEADQUATER_LIST,
  PRODUCT_STATE_LIST,
  VENDOR_DIVISION_LIST,
} from "../actions/types";
import { PACKING_TYPE } from "../actions/types";

export function* gevDesignationListWorker(action) {
  try {
    const res = yield call(gevDesignationViewListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevDesignationReportListWorker(action) {
  try {
    const res = yield call(gevDesignationReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevHqReportListWorker(action) {
  try {
    const res = yield call(gevHqReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevDivisionReportListWorker(action) {
  try {
    const res = yield call(gevDivisionReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevProductReportListWorker(action) {
  try {
    const res = yield call(gevProductReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevFFReportListWorker(action) {
  try {
    const res = yield call(gevFFReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevOrdersReportListWorker(action) {
  try {
    const res = yield call(gevOrdersReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevCfaReportListWorker(action) {
  try {
    const res = yield call(gevCfaReportViewListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDesignationViewWorker(action) {
  try {
    const res = yield call(gevDesignationViewApi, {
      designation_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevHeadQuaterViewListWorker(action) {
  try {
    const res = yield call(gevHeadQuaterViewListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevHeadQuaterViewWorker(action) {
  try {
    const res = yield call(gevHqViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDivisionViewWorker(action) {
  try {
    const res = yield call(gevDivisonViewListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDivisionViewViewWorker(action) {
  try {
    const res = yield call(gevdivisionViewViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevFieldForceWorker(action) {
  try {
    const res = yield call(gevFieldForceListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevFieldForceViewWorker(action) {
  try {
    const res = yield call(gevFieldForceViewApi, {
      user_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevFieldForceEditWorker(action) {
  try {
    const res = yield call(gevFieldForceEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevOrdersWorker(action) {
  try {
    const res = yield call(gevOrdersListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDesignationWorker(action) {
  try {
    const res = yield call(gevDesignationListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
        approval_level: ele.approval_level,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDivisionWorker(action) {
  try {
    const res = yield call(gevDivisionListApi, {
      vendor_id: action.id,
    });
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
        offer_type: ele.offer_type,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevHeadQuaterWorker(action) {
  try {
    const res = yield call(gevHeadquaterListApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevRepManagerWorker(action) {
  try {
    const res = yield call(gevRepManagerListApi, action.data);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevOrdersViewWorker(action) {
  try {
    const res = yield call(gevOrdersViewApi, {
      order_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevCfaListWorker(action) {
  try {
    const res = yield call(gevCfaListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevCfaViewWorker(action) {
  try {
    const res = yield call(gevCfaViewApi, {
      cfa_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevCfaEditWorker(action) {
  try {
    const res = yield call(gevCfaEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevCfacCreateWorker(action) {
  try {
    const res = yield call(gevCfaCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevProductListWorker(action) {
  try {
    const res = yield call(gevProductListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevProductViewWorker(action) {
  try {
    const res = yield call(gevProductViewApi, {
      product_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productStateListWorker(action) {
  try {
    const res = yield call(productStateListApi, {});
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevProductEditWorker(action) {
  try {
    const res = yield call(gevProductEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevProductCreateWorker(action) {
  try {
    const res = yield call(gevProductCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* packingTypeWorker(action) {
  try {
    const res = yield call(packingTypeApi, action.data);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* vendorDivisionListWorker(action) {
  try {
    const res = yield call(vendorDivisionListApi, {
      vendor_id: action.id,
    });
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
        offer_type: ele.offer_type,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productHeadQuaterListWorker(action) {
  try {
    const res = yield call(headQuaterListApi, {
      vendor_id: action.id,
    });
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productDoctorListWorker(action) {
  try {
    const res = yield call(productDoctorListApi, {
      vendor_id: action.id,
    });
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        code: ele.code,
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productPharmacyListWorker(action) {
  try {
    const res = yield call(productPharmacyListApi, {
      vendor_id: action.id,
    });
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        code: ele.code,
        value: ele.id,
        label: ele.pharmacy_name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* gevproductDeleteWorker(action) {
  try {
    const res = yield call(productDeleteApi, {
      product_id: action.id,
    });
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* GevCfasendMailWorker(action) {
  try {
    const res = yield call(sendCfaMailApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* gevDesignationListWatcher() {
  yield takeLatest(GEV_DESIGNATION_LIST, gevDesignationListWorker);
}

export function* gevHeadQuaterViewListWatcher() {
  yield takeLatest(GEV_HEADQUATER_VIEW_LIST, gevHeadQuaterViewListWorker);
}
export function* gevDivisionViewWatcher() {
  yield takeLatest(GEV_DIVISION_VIEW_LIST, gevDivisionViewWorker);
}
export function* gevFieldForceWatcher() {
  yield takeLatest(GEV_FIELDFORCE_LIST, gevFieldForceWorker);
}

export function* gevFieldForceViewWatcher() {
  yield takeLatest(GEV_FIELDFORCE_VIEW, gevFieldForceViewWorker);
}

export function* gevFieldForceEditWatcher() {
  yield takeLatest(GEV_FIELDFORCE_EDIT, gevFieldForceEditWorker);
}

export function* gevOrdersWatcher() {
  yield takeLatest(GEV_ORDERS_LIST, gevOrdersWorker);
}

export function* gevDesignationWatcher() {
  yield takeLatest(DESIGNATION_LIST, gevDesignationWorker);
}

export function* gevDivisionWatcher() {
  yield takeLatest(GEV_DIVISION_LIST, gevDivisionWorker);
}

export function* gevHeadQuaterWatcher() {
  yield takeLatest(GEV_HEADQUATER_LIST, gevHeadQuaterWorker);
}

export function* gevRepManagerWatcher() {
  yield takeLatest(GEV_REP_MANAGER_LIST, gevRepManagerWorker);
}

export function* gevOrdersViewWatcher() {
  yield takeLatest(GEV_ORDERS_VIEW, gevOrdersViewWorker);
}

export function* gevCfaListWatcher() {
  yield takeLatest(GEV_CFA_LIST, gevCfaListWorker);
}

export function* gevCfaViewWatcher() {
  yield takeLatest(GEV_CFA_VIEW, gevCfaViewWorker);
}

export function* gevCfaEditWatcher() {
  yield takeLatest(GEV_CFA_EDIT, gevCfaEditWorker);
}

export function* gevProductListWatcher() {
  yield takeLatest(GEV_PRODUCT_LIST, gevProductListWorker);
}

export function* gevProductViewWatcher() {
  yield takeLatest(GEV_PRODUCT_VIEW, gevProductViewWorker);
}

export function* productStateListWatcher() {
  yield takeLatest(PRODUCT_STATE_LIST, productStateListWorker);
}

export function* packingTypeWatcher() {
  yield takeLatest(PACKING_TYPE, packingTypeWorker);
}

export function* gevProductEditWatcher() {
  yield takeLatest(GEV_PRODUCT_EDIT, gevProductEditWorker);
}

export function* vendorDivisionListWatcher() {
  yield takeLatest(VENDOR_DIVISION_LIST, vendorDivisionListWorker);
}

export function* productHeadQuaterListWatcher() {
  yield takeLatest(PRODUCT_HEADQUATER_LIST, productHeadQuaterListWorker);
}
export function* gevProductCreateWatcher() {
  yield takeLatest(GEV_PRODUCT_CREATE, gevProductCreateWorker);
}
export function* productDoctorListWatcher() {
  yield takeLatest(FETCH_DOCTOR_LIST, productDoctorListWorker);
}
export function* productPharmacyListWatcher() {
  yield takeLatest(FETCH_PHARMACY_LIST, productPharmacyListWorker);
}
export function* gevproductDeleteWatcher() {
  yield takeLatest(GEV_PRODUCT_DELETE, gevproductDeleteWorker);
}
export function* gevCfacCreateWatcher() {
  yield takeLatest(GEV_CFA_CREATE, gevCfacCreateWorker);
}
export function* GevCfasendMailWatcher() {
  yield takeLatest(GEV_CFA_SEND_MAIL, GevCfasendMailWorker);
}
export function* gevDesignationViewWatcher() {
  yield takeLatest(GEV_DESIGNATION_VIEW, gevDesignationViewWorker);
}
export function* gevHeadQuaterViewWatcher() {
  yield takeLatest(GEV_HEADQUATER_VIEW, gevHeadQuaterViewWorker);
}
export function* gevDivisionViewViewWatcher() {
  yield takeLatest(GEV_DIVISION_VIEW, gevDivisionViewViewWorker);
}
export function* gevDesignationReportListWatcher() {
  yield takeLatest(GEV_DESIGNATION_REPORT_LIST, gevDesignationReportListWorker);
}
export function* gevHqReportListWatcher() {
  yield takeLatest(GEV_HQ_REPORT_LIST, gevHqReportListWorker);
}
export function* gevDivisionReportListWatcher() {
  yield takeLatest(GEV_DIVISION_REPORT_LIST, gevDivisionReportListWorker);
}
export function* gevProductReportListWatcher() {
  yield takeLatest(GEV_PRODUCT_REPORT_LIST, gevProductReportListWorker);
}
export function* gevFFReportListWatcher() {
  yield takeLatest(GEV_FF_REPORT_LIST, gevFFReportListWorker);
}
export function* gevOrdersReportListWatcher() {
  yield takeLatest(GEV_ORDERS_REPORT_LIST, gevOrdersReportListWorker);
}
export function* gevCfaReportListWatcher() {
  yield takeLatest(GEV_CFA_REPORT_LIST, gevCfaReportListWorker);
}