import { api } from "./api";

export const gevDesignationViewListApi = (params) => {
  return api.get("/admin/manufacturer/field-force/designation/list", {
    params: params,
  });
};
export const gevDesignationReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/designation", {
    params: params,
  });
};
export const gevDivisionReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/division", {
    params: params,
  });
};
export const gevProductReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/products", {
    params: params,
  });
};
export const gevFFReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/field-force/user", {
    params: params,
  });
};
export const gevOrdersReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/manage-orders", {
    params: params,
  });
};
export const gevCfaReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/cfa", {
    params: params,
  });
};
export const gevHqReportViewListApi = (params) => {
  return api.get("/admin/manufacturer/exports/head-quater", {
    params: params,
  });
};
export const gevDesignationViewApi = (params) => {
  return api.get("/admin/manufacturer/field-force/designation/view", {
    params: params,
  });
};
export const gevHqViewApi = (params) => {
  return api.get("/admin/manufacturer/head-quarter/view", {
    params: params,
  });
};
export const gevdivisionViewViewApi = (params) => {
  return api.get("/admin/manufacturer/division/view", {
    params: params,
  });
};
export const gevHeadQuaterViewListApi = (params) => {
  return api.get("/admin/manufacturer/head-quarter/list", {
    params: params,
  });
};

export const gevDivisonViewListApi = (params) => {
  return api.get("/admin/manufacturer/division/list", {
    params: params,
  });
};

export const gevFieldForceListApi = (params) => {
  return api.get(`/admin/manufacturer/field-force/user/list`, {
    params: params,
  });
};
export const gevFieldForceViewApi = (params) => {
  return api.get(`/admin/manufacturer/field-force/user/view`, {
    params: params,
  });
};
export const gevFieldForceEditApi = (params) => {
  return api.post(
    `/admin/manufacturer/field-force/user/edit`,
    JSON.stringify(params)
  );
};
export const gevDesignationListApi = (params) => {
  return api.get(`/admin/manufacturer/field-force/user/designation/list`, {
    params: params,
  });
};
export const gevDivisionListApi = (params) => {
  return api.get(`/admin/manufacturer/field-force/user/division/list`, {
    params: params,
  });
};
export const gevHeadquaterListApi = (params) => {
  return api.get(`/admin/manufacturer/field-force/user/head-quater/list`, {
    params: params,
  });
};
export const gevRepManagerListApi = (params) => {
  return api.get(
    `/admin/manufacturer/field-force/user/reporting-manager/list`,
    {
      params: params,
    }
  );
};
export const gevOrdersListApi = (params) => {
  console.log(params, "datahhdhdhd");
  return api.get(`/admin/manufacturer/orders/list`, {
    params: params,
  });
};
export const gevOrdersViewApi = (params) => {
  return api.get(`/admin/manufacturer/order/view`, {
    params: params,
  });
};
export const gevCfaListApi = (params) => {
  return api.get(`/admin/manufacturer/cfa/list`, {
    params: params,
  });
};
export const gevCfaViewApi = (params) => {
  return api.get(`/admin/manufacturer/cfa/view`, {
    params: params,
  });
};
export const gevCfaEditApi = (params) => {
  return api.post(`/admin/manufacturer/cfa/edit`, JSON.stringify(params));
};
export const gevCfaCreateApi = (params) => {
  return api.post(`/admin/manufacturer/cfa/create`, JSON.stringify(params));
};
export const sendCfaMailApi = (params) => {
  return api.post(`/admin/manufacturer/cfa/send-mail`, JSON.stringify(params));
};

export const gevProductListApi = (params) => {
  return api.get(`/admin/manufacturer/product/list`, {
    params: params,
  });
};
export const gevProductViewApi = (params) => {
  return api.get(`/admin/manufacturer/product/view`, {
    params: params,
  });
};
export const productStateListApi = (params) => {
  return api.get(`/common/state-list`);
};
export const packingTypeApi = (params) => {
  return api.get(
    "/admin/manufacturer/product/packing-type/list",
    JSON.stringify(params)
  );
};
export const gevProductEditApi = (params) => {
  return api.post(`/admin/manufacturer/product/edit`, JSON.stringify(params));
};
export const gevProductCreateApi = (params) => {
  return api.post(`/admin/manufacturer/product/create`, JSON.stringify(params));
};

export const vendorDivisionListApi = (params) => {
  return api.get(`/admin/manufacturer/product/division/list`, {
    params: params,
  });
};
export const headQuaterListApi = (params) => {
  return api.get("/admin/manufacturer/product/head-quater/list", {
    params: params,
  });
};
export const productDoctorListApi = (params) => {
  return api.get("/admin/manufacturer/product/doctors/list", {
    params: params,
  });
};
export const productPharmacyListApi = (params) => {
  return api.get("/admin/manufacturer/product/pharmacy/list", {
    params: params,
  });
};
export const productDeleteApi = (params) => {
  return api.delete(`/admin/manufacturer/product/delete`, { data: params });
};
