import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import "./manufacturerModal.css";
import TableContainer from "../../layout/table-container/table-container";
import ProductVerificationTableData from "./product-verification-table-data";
import { useDispatch } from "react-redux";
import { productBulkVerify } from "../../../redux/actions/manufacturerActions";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";

const ProductDataVerificationModal = ({
  show,
  ShowClose,
  productData,
  vendor_id,
  division_id,
  deleteModalOpener,
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [productVerifiedList, setProductVerifiedList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const checkedProduct = (e, data) => {
    const { checked } = e.target;
    let newArr = productVerifiedList;
    setProductVerifiedList([...newArr, data]);
    if (!checked) {
      setProductVerifiedList(
        productVerifiedList.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(data)
        )
      );
    }
  };

  useEffect(() => {
    console.log(productVerifiedList);
    if (productVerifiedList?.length != productData?.productData?.length) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [productVerifiedList]);

  useEffect(() => {
    setBulkUploadData(productData?.productData);
  }, [productData]);

  const searchHandler = (e) => {
    let newArr = productData.productData.filter(
      (product) =>
        product.product_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        product.company_product_id
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setBulkUploadData(newArr);
  };

  const verifyProductHandler = () => {
    dispatch(
      productBulkVerify(
        { productData: productVerifiedList, vendor_id, division_id },
        onVerifySuccess,
        onVerifyError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    ShowClose();
  }

  const onVerifySuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
    setProductVerifiedList([]);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onVerifyError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setProductVerifiedList([]);
  };

  const checkAll = (e) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (checked) {
      setProductVerifiedList(productData?.productData);
    } else {
      setProductVerifiedList([]);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={ShowClose}
      dialogClassName="product-data-table-container col-10 px-5 mx-auto"
      size=""
    >
      <ModalHeader className="d-flex justify-content-between align-items-center">
        <div>
          <span className="fn_Nunito fw_600 fs_18">
            Product Data Verification -
            <span className="fw_500">
              {productData?.original_name?.length > 35
                ? productData?.original_name?.substring(0, 35) + "..."
                : productData?.original_name}
            </span>
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          <AdminButton
            background="#E7E5FF"
            width="100px"
            type="button"
            onClick={() => ShowClose()}
          >
            CLOSE
          </AdminButton>
          <AdminButton
            background="#2AD3E7"
            color="white"
            width="100px"
            type="submit"
            disabled={productVerifiedList?.length == 0}
            onClick={verifyProductHandler}
          >
            <span className="m-2">VERIFY</span>
          </AdminButton>
        </div>
      </ModalHeader>
      <ModalBody className="text-nowrap">
        <div className="modal-table-container">
          <div className="search-container d-flex justify-content-between ">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 px-2"
                type="text"
                placeholder="Search Product here by Product Name..."
                onChange={searchHandler}
              />
            </div>
          </div>
          <div style={{overflow:"auto"}}>         
          <TableContainer>
            <thead>
              <tr className="table-rows color_747f9e">
                <th className="table-heads" width={"6%"}>
                  <input
                    type={"checkbox"}
                    className="CheckBox my-1"
                    value="Full order forward from CFA"
                    name="ReimbursementCheck"
                    checked={isCheckAll}
                    onChange={checkAll}
                  />
                </th>
                <th className="table-heads " width={"8%"}>
                  SL. No
                </th>
                <th className="table-heads " width={"15%"}>
                  Company’s Product ID
                </th>
                <th className="table-heads " width={"15%"}>
                  Product Name
                </th>
                <th className="table-heads " width={"15%"}>
                  MRP
                </th>
                <th className="table-heads " width={"15%"}>
                  PTR
                </th>
                <th className="table-heads " width={"15%"}>
                  PTS
                </th>
                <th className="table-heads " width={"15%"}>
                  Quantity
                </th>
                <th className="table-heads " width={"15%"}>
                  Offer Qty
                </th>
                <th className="table-heads " width={"22%"}>
                  Actions
                </th>
              </tr>
            </thead>
            {bulkUploadData?.length > 0 &&
              bulkUploadData?.map((eachRow, index) => (
                <ProductVerificationTableData
                  data={eachRow}
                  index={index}
                  key={index}
                  deleteModalOpener={deleteModalOpener}
                  checkedProduct={checkedProduct}
                  isChecked={productVerifiedList.includes(eachRow)}
                />
              ))}
          </TableContainer>
          </div>
        </div>
      </ModalBody>
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </Modal>
  );
};

export default ProductDataVerificationModal;
