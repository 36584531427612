import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "../../../../src/components/buttons/versionHistoryBtn";
import ErrorMsg from "../../../components/form/errorMsg";
import InputField from "../../../components/form/inputField/inputField";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";
import back from "../../../assets/icons/back.svg";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import "../../super-admin/superUser.css";
import { useDispatch } from "react-redux";
import {
  permissionsList,
  superAdminRoleCreate,
} from "../../../redux/actions/superAdminActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import Select from "react-select";

const SuperUserRoleCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setPermission] = useState([]);
  const [approvalLevel, setApprovalLevel] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    role_name: "",
    role_level: "",
    permissions: [],
  };

  useEffect(() => {
    dispatch(permissionsList(fetchPermissionSuccess, fetchPermissionError));
  }, []);

  const fetchPermissionSuccess = (data) => {
    console.log("permissions", data);
    setPermission(data);
  };

  const fetchPermissionError = (data) => {
    console.log(data);
  };
  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Required"),
    role_level: Yup.string().required("Required"),
    permissions: Yup.array().min(1, `Required`).required("Required"),
  });

  const clearFunc = (setFieldValue) => {
    setFieldValue("role_name", "");
    setFieldValue("role_level", "");

    const clearedPermissions = permission.map((ele) => ({
      id: ele.value,
      read: false,
      write: false,
    }));

    console.log(clearedPermissions);

    setFieldValue("permissions", clearedPermissions);
  };
  const onSubmit = (values) => {
    console.log(values);
    dispatch(superAdminRoleCreate(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/super-user/role/1");
  };
  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const selectPermission = (e, index, setFieldValue, access, values) => {
    let newArr = values.permissions;
    const i = newArr.findIndex((ex) => ex.id == index);
    if (access == "read") {
      if (i > -1)
        newArr[i] = {
          id: index,
          read: !newArr[i].read,
          write: newArr[i].write,
        };
      else newArr.push({ id: index, read: true, write: false });
    } else if (access == "write") {
      if (i > -1)
        newArr[i] = {
          id: index,
          read: !newArr[i].write,
          write: !newArr[i].write,
        };
      else newArr.push({ id: index, read: true, write: true });
    }
    setFieldValue("permissions", newArr);
  };

  return (
    <MainLayout breadCrumb="ADMIN / Super User / Roles - Create">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, resetForm }) => (
            <Form className="">
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    type="button"
                    onClick={() => clearFunc(setFieldValue)}
                  >
                    CLEAR
                  </AdminButton>
                  <AdminButton
                    background="#7065e4"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="scrolling">
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="my-4">
                    <InputCapitalField
                      id={"role_name"}
                      label={"Role Name"}
                      labelColor={"color_262c3d"}
                      type={"text"}
                      placeholder={"Enter role name"}
                      name={"role_name"}
                      value={values?.role_name}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="my-2">
                    <label className="color_262c3d fw_400 fs_14">Levels</label>
                    <Field>
                      {({ form, Field }) => (
                        <Select
                          options={options}
                          styles={customStyles}
                          {...Field}
                          placeholder={"Select level"}
                          id="role_level"
                          value={options.filter(
                            (c) => c.value == values?.role_level
                          )}
                          onChange={(selectedOption) => {
                            form.setFieldValue(
                              "role_level",
                              selectedOption.value
                            );
                            setApprovalLevel(selectedOption.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="role_level" />
                  </div>
                  <div className="my-0">
                    <label className=" color_262c3d fs_14">Permission</label>
                  </div>
                  <div className="my-1 d-flex  label-container align-items-center justify-content-start ">
                    <div className="col-5">
                      <label className="color_b0b3c7 fs_14">Models</label>
                    </div>
                    <label className="color_b0b3c7 fs_14 ms-4 ps-2">Read</label>
                    <label className="color_b0b3c7 fs_14 ms-4 ps-2">
                      Write
                    </label>
                  </div>
                  {permission?.map((ele, index) => {
                    const permissionItem = values?.permissions.find(
                      (item) => item.id === ele.value
                    ) || { read: false, write: false };
                    return (
                      <div
                        className="d-flex my-1 label-container align-items-center justify-content-start"
                        key={index}
                      >
                        <div className="col-6">
                          <label className="fs_14">{ele.label}</label>
                        </div>
                        <div>
                          <span className="fs_14 my-1">
                            <input
                              type={"checkbox"}
                              className="CheckBox"
                              checked={values?.permissions?.some(
                                (ex) => ex.id === ele?.value && ex.read
                              )}
                              onChange={(e) => {
                                console.log();
                                selectPermission(
                                  e,
                                  ele?.value,
                                  setFieldValue,
                                  "read",
                                  values
                                );
                              }}
                              disabled={values?.permissions?.some(
                                (ex) => ex.id === ele?.value && ex.write
                              )}
                            />
                          </span>
                        </div>
                        <div className="ms-5">
                          <span className="fs_14 my-1">
                            <input
                              type={"checkbox"}
                              className="CheckBox"
                              checked={permissionItem.write}
                              onChange={(e) => {
                                selectPermission(
                                  e,
                                  ele?.value,
                                  setFieldValue,
                                  "write",
                                  values
                                );
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  <ErrorMsg name={"permissions"} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
        <ErrorPopup
          show={showErrorPopup}
          title={showErrorMsg}
          handleClose={closeErrorPopup}
        />
      </div>
    </MainLayout>
  );
};

export default SuperUserRoleCreate;

const options = [
  { value: "L1", label: "Level 1" },
  { value: "L2", label: "Level 2" },
  { value: "L3", label: "Level 3" },
];

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    height: "43px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
