import { api } from "./api";

export const invoiceVerifyListApi = (params) => {
  return api.get(`/admin/invoice/list`, { params });
};
export const invoiceVerifiedListApi = (params) => {
  return api.get(`/admin/invoice/verified-list`, { params });
};
export const invoiceVerifyViewApi = (params) => {
  return api.get(`/admin/invoice/view`, { params: params });
};
export const remarkListApi = (params) => {
  return api.get(`/admin/invoice/remark-list`, { params: params });
};
export const invoiceReuploadApi = (params) => {
  return api.post(`/admin/invoice/re-upload`, JSON.stringify(params));
};
export const invoiceVerifyApi = (params) => {
  return api.post(`/admin/invoice/verify`, JSON.stringify(params));
};
export const invoicePtrUpdateApi = (params) => {
  return api.post(`/admin/invoice/update-ptr`, JSON.stringify(params));
};