import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import manufacturericon from "../../assets/icons/Manufacturers.svg";
import customericon from "../../assets/icons/Customers.svg";
import distributoricon from "../../assets/icons/Distributors.svg";
import totalordericon from "../../assets/icons/totalorders.svg";
import invoiceicon from "../../assets/icons/invoice.svg";
import { useDispatch } from "react-redux";
import {
  dashboardView,
  graphCard,
} from "../../redux/actions/dashboardAction.js";
import DashBoardGraph from "./DashboardGraph";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { socket } from "../../redux/config";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { motion } from "framer-motion";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(null);
  const [showMonthSelect, setShowMonthSelect] = useState("Annually");
  const [graphData, setGraphData] = useState(null);
  const [urlParams, setUrlParams] = useState("completed-orders");
  const [label, setLabel] = useState("Completed Orders");
  const [dashboardData, setDashboardData] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [dashboardCounter, setDashboardCounters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    setLoading(true);
    dispatch(dashboardView(onSuccess, onError));
  }, []);

  useEffect(() => {
    socket.on("admin_dashboard", (counters) => {
      console.log("Socket Data", counters);
      setDashboardCounters({
        doctors: counters?.doctors,
        distributors: counters?.distributors,
        orders: counters?.totalOrdyearers,
        completedOrders: counters?.completedOrders,
        manufacturers: counters?.vendors,
        pharmacies: counters?.pharmacies,
        dashboardCounter: counters?.dashboardCounter,
        invoiceByCustomer: counters?.invoiceByCustomer,
        invoiceByDistributor: counters?.invoiceByDistributor,
      });
    });
    return () => {
      socket.off("admin_dashboard");
    };
  }, []);

  const onSuccess = (data) => {
    const counters = data?.data?.data;
    setLoading(false);
    setDashboardData(data.data.data);
    setDashboardCounters({
      doctors: counters?.doctors,
      distributors: counters?.distributors,
      orders: counters?.totalOrders,
      completedOrders: counters?.completedOrders,
      manufacturers: counters?.vendors,
      pharmacies: counters?.pharmacies,
      dashboardCounter: counters?.dashboardCounter,
      invoiceByCustomer: counters?.invoiceByCustomer,
      invoiceByDistributor: counters?.invoiceByDistributor,
    });
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setLoading(false);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  useEffect(() => {
    if (month !== new Date().getMonth() + 1 || month !== null) {
      dispatch(
        graphCard({ year, month, urlParams }, onGraphSuccess, onGraphError)
      );
    } else {
      dispatch(graphCard({ year, urlParams }, onGraphSuccess, onGraphError));
    }
  }, [year, month, urlParams]);

  useEffect(() => {
    setYear(new Date().getFullYear());
    setMonth(null);
    setShowMonthSelect("Annually");
  }, [urlParams]);

  const onGraphSuccess = (data) => {
    console.log(data?.data?.data);
    setGraphData(data?.data?.data);
  };

  const onGraphError = (err) => {
    console.log(err);
  };

  const cardDetails = [
    {
      icon: totalordericon,
      label: "Completed Orders",
      count: dashboardCounter?.completedOrders,
      id: "completed-orders",
    },
    {
      icon: manufacturericon,
      label: "Manufacturers",
      count: dashboardCounter?.manufacturers,
      id: "vendors",
    },
    {
      icon: distributoricon,
      label: "Distributors",
      count: dashboardCounter?.distributors,
      id: "distributors",
    },
    {
      icon: customericon,
      label: "Doctors",
      count: dashboardCounter?.doctors,
      id: "doctors",
    },
    {
      icon: customericon,
      label: "Pharmacies",
      count: dashboardCounter?.pharmacies,
      id: "pharmacies",
    },
    {
      icon: invoiceicon,
      label: "Invoices Uploaded By Customer",
      count: dashboardCounter?.invoiceByCustomer,
      id: "invoice-by-customers",
    },
    {
      icon: invoiceicon,
      label: "Invoices Uploaded By Distributor",
      count: dashboardCounter?.invoiceByDistributor,
      id: "invoice-by-distributors",
    },
    // {
    //   icon: invoiceicon,
    //   label: "Reimbursed Invoices",
    //   count: "30,165",
    //   id: "reimbursed-invoices",
    // },
    {
      icon: totalordericon,
      label: "Total Orders",
      count: dashboardCounter?.orders,
      id: "total-orders",
    },
  ];

  return (
    <MainLayout>
      <div className="main-page">
        <div className="container-main-div bg-white">
          {loading == true ? (
            <div className="view-spinner-container">
              <LoadingSpinnerTable />
            </div>
          ) : (
            <>
              <div className="row gap-lg-4 mx-2 mx-lg-4 my-2 d-flex justify-content-center justify-content-xl-start">
                {cardDetails.map((item, index) => (
                  <motion.div
                    viewport={{ once: true }}
                    initial={{ opacity: 0 }}
                    animate="visible"
                    whileInView={{
                      opacity: 1,
                      transition: { delay: index * 0.1 },
                    }}
                    className={`card-container col-sm-12 col-xxl-3 col-xl-6 mx-1 cursor_pointer hoverpop ${
                      urlParams == item.id && `activepop`
                    }`}
                    key={index}
                    style={{ maxWidth: "240px" }}
                    onClick={() => {
                      setUrlParams(item.id);
                      setLabel(item.label);
                    }}
                  >
                    <label className="mt-1 pt-1 d-flex align-align-items-center">
                      <img src={item.icon} width={22} height={22} />
                      <span className="fs_14 ms-1  Medium_Purple fw_400 text-uppercase ">
                        {item.label}
                      </span>
                    </label>
                    <p className=" mx-3 ms-4 ps-3 fw_700 fs_20">{item.count}</p>
                  </motion.div>
                ))}
              </div>
              <div className="mt-5">
                <DashBoardGraph
                  year={year}
                  month={month}
                  setYear={setYear}
                  setMonth={setMonth}
                  setShowMonthSelect={setShowMonthSelect}
                  showMonthSelect={showMonthSelect}
                  graphData={graphData}
                  urlParams={urlParams}
                  label={label}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default Dashboard;
