import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const InvoiceVerificationListTableData = ({ data, index, currentPage, viewPath }) => {
  const navigate = useNavigate();
  return (
    <tr className="table-rows1" key={index}>
      <td className="table-each-row-data">{(currentPage - 1) * 10 + (index + 1)}</td>
      <td className="table-each-row-data">{data?.order_id ? data?.order_id : '--'}</td>
      <td className="table-each-row-data ">
        {data?.orderHeirarchy?.user_type == "doctor"
          ? data?.doctor?.name
          : data?.pharmacy?.pharmacy_name}
      </td>
      <td className="table-each-row-data">
        {data?.orderHeirarchy?.fieldForceUser?.name ? data?.orderHeirarchy?.fieldForceUser?.name : '--'}
      </td>
      <td className="table-each-row-data text-capitalize">{data?.distributor?.firm_name ? data?.distributor?.firm_name : '--'}</td>
      <td className="table-each-row-data">
        {moment(data?.createdAt).format("DD MMM YYYY HH:mm A")}
      </td>
      <td className="table-each-row-data">{data?.total ? `₹ ${data?.total}` : '--'}</td>
      <td className="table-each-row-data">
        <span
          className={`${orderStatus?.find((e) => e?.status == data?.status)?.color
            }`}
        >
          {orderStatus?.find((e) => e?.status == data?.status)?.value
            ? orderStatus?.find((e) => e?.status == data?.status)?.value
            : '--'}
        </span>
      </td>
      <td className="table-each-row-data">
        <span
          className={`${data?.invoice_verified ? 'text_green' : 'text-warning'}`}
        >
          {data?.invoice_verified ? "Verified" : 'Pending'}
        </span>
      </td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/support/${viewPath}/view/${data?.id}`)}
          >
            <span className=""> View</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default InvoiceVerificationListTableData;

export const orderStatus = [
  { status: "pending", value: "Approval Pending", color: "text-warning" },
  { status: "approved", value: "Approved", color: "color_2ad3e7" },
  { status: "fully_invoiced", value: "Fully Invoiced", color: "text_green" },
  { status: "partially_invoiced", value: "Partially Invoiced", color: "Medium_Purple" },
  { status: "rejected", value: "Rejected", color: "text-danger" },
  { status: "approval_failed", value: "Approval Failed", color: "text-danger" },
  { status: "cancelled", value: "Cancelled", color: "text-danger" },
  { status: "expired", value: "Expired", color: "text-danger" },
  { status: "acknowledged", value: "Acknowledged", color: "ack_status" },
  {
    status: "partially_acknowledged",
    value: "Partially acknowledged",
    color: "Medium_Purple",
  },
  { status: "hold", value: "Hold", color: "text-danger" },
];
