import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  geCfaReportList,
  gevCfaList,
} from "../../../../redux/actions/godsEyeViewActions";
import TablePagination from "../../../../components/Pagination/tablePagination";
import searcher from "../../../../assets/icons/searchInput.svg";
import LoadingSpinner from "../../../../components/utilites/loaderSpinningContainer";
import CreateBtn from "../../../../components/buttons/createBtn";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import TableCfaSendMailModal from "../../../../components/modals/manufacturer/send-Mail-cfa-table-modal";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import exportFromJSON from "export-from-json";

const CfaList = ({ setActiveCfaTab, setSelectedCfa, selectedCfa }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [cfaData, setCfaData] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const vendor_id = params?.id.split("&")?.[0];
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");
  const [search, setSearch] = useState("");
  const [sendMailShow, setSendMailShowShow] = useState(false);
  const [cfaset, setCfaset] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  console.log(cfaset, "fdff");

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  console.log(selectedCfa, "hdyhdy");

  useEffect(() => {
    gevCfaListFnc();
  }, [page]);

  useEffect(() => {
    setPage(1);
    gevCfaListFnc();
  }, [search]);

  const gevCfaListFnc = () => {
    setLoading(true);
    dispatch(gevCfaList({ vendor_id, page, search }, onffSuccess, onffError));
  };

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data);
    setCfaData(data?.data?.data);
  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(geCfaReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "CFA ID ": data?.cfa_id ? data?.cfa_id : "--",
          "CFA Name": data?.cfa_name
            ? data?.cfa_name.charAt(0).toUpperCase() + data?.cfa_name.slice(1)
            : "--",
          "Phone Number": data?.mobile ? data?.mobile : "--",
          "Order Email Address": data?.email ? data?.email : "--",
          "Email Status": data?.mail_sent == true ? "Yes" : "No",
          status: data?.status == true ? "Yes" : "No",
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setShowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  return (
    <div className="mx-5 px- my-3 mt-4">
      <div className="search-container d-flex justify-content-between align-items-center">
        <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
          <img className="styled-SVG" src={searcher} color={""} />
          <input
            className="search-input fs_14 fw_400 text-dark px-2"
            type="text"
            placeholder="Search cfa here by id , name , email..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          <button
            className="verify-btn text-uppercase px-2 py-2 mx-2 my-2 fs_12"
            type="button"
            onClick={() => exportTableCsv()}
          >
            download
          </button>
          {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
            <AdminButton
              background="#7065E4"
              type="button"
              color="white"
              width="110px"
              onClick={() => {
                navigate(`/manufacturer/${pathType}/view/${vendor_id}&cfa&add`);
              }}
            >
              <span className="m-2">CREATE</span>
            </AdminButton>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="manufacturer-product-table-container"
        style={{ height: "33.5rem" }}
      >
        <div style={{ height: "30rem" }}>
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads py-2" width={"2%"}>
                  #
                </th>
                <th className="table-heads" width={"8%"}>
                  CFA ID
                </th>
                <th className="table-heads" width={"6%"}>
                  CFA Name
                </th>
                {/* <th className="table-heads" width={"6%"}>
                        Firm Name
                      </th> */}
                <th className="table-heads" width={"6%"}>
                  Phone Number
                </th>
                <th className="table-heads" width={"6%"}>
                  Order Email Address
                </th>
                <th className="table-heads" width={"6%"}>
                  Email Status
                </th>
                <th className="table-heads" width={"6%"}>
                  Status
                </th>

                <th className="table-heads" width={"8%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="gev-spinner-container">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {cfaData?.cfa?.length > 0 ? (
                    cfaData?.cfa?.map((data, index) => (
                      <tr className="table-rows1 color_262c3d" key={index}>
                        <td className="table-each-row-data">
                          {(cfaData?.pagination?.currentPage - 1) * 10 +
                            (index + 1)}
                        </td>
                        <td className="table-each-row-data">
                          {data?.cfa_id ? data?.cfa_id : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.cfa_name
                            ? data?.cfa_name.charAt(0).toUpperCase() +
                            data?.cfa_name.slice(1)
                            : "--"}
                        </td>
                        {/* <td className="table-each-row-data">{data?.firm_name ? data?.firm_name : '--'}</td> */}
                        <td className="table-each-row-data">
                          {data?.mobile ? data?.mobile : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.email ? data?.email : "--"}
                        </td>
                        {data?.mail_sent == true ? (
                          <td className="table-each-row-data text_green">
                            <span className="fw_700 px-2">SENT</span>
                          </td>
                        ) : (
                          <td className="table-each-row-data text_red">
                            <button
                              className="send-mail-btn"
                              onClick={() => {
                                setSendMailShowShow(true);
                                setCfaset(data);
                              }}
                            >
                              Send Mail
                            </button>
                          </td>
                        )}
                        {data?.status ? (
                          <td className="table-each-row-data text_green">
                            Active
                          </td>
                        ) : (
                          <td className="table-each-row-data text_red">
                            Inactive
                          </td>
                        )}

                        <td className="table-each-row-data">
                          <div className="all-button d-flex align-items-center">
                            <button
                              className="button-view"
                              type="button"
                              onClick={() => {
                                navigate(
                                  `/manufacturer/${pathType}/view/${vendor_id}&cfa&view&${data?.id}`
                                );
                              }}
                            >
                              <span className=""> View</span>
                            </button>
                            {(user_type == "super_admin" && userData?.write) ||
                              user_type == "admin" ? (
                              <>
                                <span className="vr vr_line mt-1"></span>
                                <button
                                  className="button-edit1"
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/manufacturer/${pathType}/view/${vendor_id}&cfa&edit&${data?.id}`
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center no-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} /> */}
      </div>
      {cfaData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={cfaData?.pagination}
          nextFunc={() => {
            setPage(cfaData?.pagination?.currentPage + 1);
          }}
          prevFunc={() => {
            setPage(cfaData?.pagination?.currentPage - 1);
          }}
        />
      )}
      <TableCfaSendMailModal
        data={cfaset}
        show={sendMailShow}
        gevCfaListFnc={gevCfaListFnc}
        // closeModal={distributorListFunc}
        handleClose={() => setSendMailShowShow(false)}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </div>
  );
};

export default CfaList;

const data = [{}];
