import React, { useState } from "react";
import TableContainer from "../layout/table-container/table-container";
import eye from "../../assets/icons/viewEye.svg";
import ImageViewModal from "../modals/customer/image-view-modal";
import { Document, Page } from "react-pdf";
import PdfView from "../modals/pdfView";
import { pdfChecker } from "../utilites/helper";

const PharmacyView = ({ customerViewData }) => {
  const [imageModal, setImageModal] = useState(false);
  const [imageTitleData, setImageTitleData] = useState("");
  const [imageUrlData, setImageUrlData] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  //   console.log(doctorArr);

  const independentPharmacyBasicData = [
    {
      heading: "Jivini’s Phramcy Code",
      title: customerViewData?.code ? customerViewData?.code : "--",
    },
    {
      heading: "Pharmacy Name",
      title: customerViewData?.pharmacy_name
        ? customerViewData?.pharmacy_name
        : "--",
    },
    {
      heading: "Drug License Number - 20A",
      title: customerViewData?.drug_licence_number_20_a
        ? customerViewData?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug License Number - 20B",
      title: customerViewData?.drug_licence_number_20_b
        ? customerViewData?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "GST Number",
      title: customerViewData?.gst_number ? customerViewData?.gst_number : "--",
    },
    // {
    //   heading: "Onboarded By",
    //   title: "",
    // },
    // {
    //   heading: "Onboarded Date & Time",
    //   title: "12 sep 2022, 7:33 PM ",
    // },
    {
      heading: "Linked Manufacturer",
      title:
        customerViewData?.pharmacySubscribedVendors?.length > 0 &&
        customerViewData?.pharmacySubscribedVendors?.map((ele, index) => {
          return (
            <span key={index}>
              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
              {ele?.vendor?.name}
            </span>
          );
        }),
    },
  ];

  const independentPharmacyContactData = [
    {
      heading: "Mobile Number",
      title: `+${
        customerViewData?.country_code ? customerViewData?.country_code : ""
      } ${customerViewData?.mobile ? customerViewData?.mobile : "--"}`,
    },
    {
      heading: "Alternate Mobile Number",
      title: `+${
        customerViewData?.country_code ? customerViewData?.country_code : ""
      } ${
        customerViewData?.alternate_mobile
          ? customerViewData?.alternate_mobile
          : "--"
      }`,
    },
    {
      heading: "Email Address",
      title: customerViewData?.email ? customerViewData?.email : "--",
    },
    {
      heading: "Address",
      title: `${
        customerViewData?.address?.address_one
          ? customerViewData?.address?.address_one
          : "--"
      }${customerViewData?.address?.address_two ? "," : ""} ${
        customerViewData?.address?.address_two
          ? customerViewData?.address?.address_two
          : ""
      }${customerViewData?.address?.address_three ? "," : ""} ${
        customerViewData?.address?.address_three
          ? customerViewData?.address?.address_three
          : ""
      }`,
    },
    {
      heading: "Landmark",
      title: customerViewData?.address?.landmark
        ? customerViewData?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: customerViewData?.state?.name
        ? customerViewData?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: customerViewData?.district?.name
        ? customerViewData?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: customerViewData?.city?.name ? customerViewData?.city?.name : "--",
    },
    {
      heading: "Pincode",
      title: customerViewData?.pincode ? customerViewData?.pincode : "--",
    },
  ];
  return (
    <>
      <>
        <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
          <label className="basic-title mx-2 mb-1">
            Pharmacy’s Basic Details
          </label>
        </div>
        <div className="row mt-3 pt-3 ps-5 ">
          {independentPharmacyBasicData.map((item, index) => (
            <div
              key={index}
              className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
            >
              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                {item.heading}
              </h5>
              <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                {item.title}
              </p>
            </div>
          ))}
        </div>
        <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
          <label className="basic-title mx-2 mb-1">
            Pharmacy’s Contact Details
          </label>
        </div>
        <div>
          <div className="row mt-3">
            <div className="pt-3 ps-5 row">
              {independentPharmacyContactData.map((item, index) => (
                <div
                  key={index}
                  className="mb-2 col-10 col-sm-6 col-md-4 col-xxl-2 mx-3 px-3 "
                >
                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
      <>
        <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
          <label className="basic-title mx-2 mb-1">Documents</label>
        </div>
        <div className="row">
          {customerViewData?.drug_licence_certificate_20_a && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">
                      Drug License Certificate - 20A
                    </label>
                  </div>
                  <div
                    className={` h-100`}
                    style={{
                      height: "25rem",
                      // width: "15rem",
                      // border: "1px solid black",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      position: "relative",
                    }}
                  >
                    <div className="manufacturer-doc-upload-container">
                      {pdfChecker(
                        customerViewData?.drug_licence_certificate_20_a
                      ) ? (
                        <Document
                          file={customerViewData?.drug_licence_certificate_20_a}
                        >
                          <Page pageNumber={1} width={415} />
                        </Document>
                      ) : (
                        <img
                          className="image-upload"
                          src={customerViewData?.drug_licence_certificate_20_a}
                        />
                      )}
                    </div>
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                      >
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          {pdfChecker(
                            customerViewData?.drug_licence_certificate_20_a
                          ) ? (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setOpenPdf(true);
                                setPdfFileUrl(
                                  customerViewData?.drug_licence_certificate_20_a
                                );
                                setFileName("Drug License Certificate - 20A");
                              }}
                            >
                              VIEW
                            </button>
                          ) : (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData(
                                  " Drug License Certificate - 20A"
                                );
                                setImageUrlData(
                                  customerViewData?.drug_licence_certificate_20_a
                                );
                              }}
                            >
                              VIEW
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {customerViewData?.drug_licence_certificate_20_b && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">
                      Drug License Certificate - 20B
                    </label>
                  </div>

                  <div
                    className={` h-100`}
                    style={{
                      height: "25rem",
                      // width: "15rem",
                      // border: "1px solid black",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      position: "relative",
                    }}
                  >
                    <div className="manufacturer-doc-upload-container">
                      {pdfChecker(
                        customerViewData?.drug_licence_certificate_20_b
                      ) ? (
                        <Document
                          file={customerViewData?.drug_licence_certificate_20_b}
                        >
                          <Page pageNumber={1} width={415} />
                        </Document>
                      ) : (
                        <img
                          className="image-upload"
                          src={customerViewData?.drug_licence_certificate_20_b}
                        />
                      )}
                    </div>
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                      >
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          {pdfChecker(
                            customerViewData?.drug_licence_certificate_20_b
                          ) ? (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setOpenPdf(true);
                                setPdfFileUrl(
                                  customerViewData?.drug_licence_certificate_20_b
                                );
                                setFileName("Drug License Certificate - 20B");
                              }}
                            >
                              VIEW
                            </button>
                          ) : (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData(
                                  " Drug License Certificate - 20B"
                                );
                                setImageUrlData(
                                  customerViewData?.drug_licence_certificate_20_b
                                );
                              }}
                            >
                              VIEW
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {customerViewData?.gst_certificate && (
            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
              <div className="mx-4 px-3">
                <div className="my-2">
                  <div className="upload-image-label-container d-flex justify-content-between">
                    <label className="my-1 Neutral_Color">
                      GST Certificate
                    </label>
                  </div>

                  <div
                    className={` h-100`}
                    style={{
                      height: "25rem",
                      // width: "15rem",
                      // border: "1px solid black",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      position: "relative",
                    }}
                  >
                    <div className="manufacturer-doc-upload-container">
                      {pdfChecker(customerViewData?.gst_certificate) ? (
                        <Document file={customerViewData?.gst_certificate}>
                          <Page pageNumber={1} width={415} />
                        </Document>
                      ) : (
                        <img
                          className="image-upload"
                          src={customerViewData?.gst_certificate}
                        />
                      )}
                    </div>
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        zIndex: "55",
                        top: "0",
                        maxWidth: "450px",
                      }}
                    >
                      <div
                        className="manufacturer-doc-upload-container"
                        style={{ backgroundColor: "rgb(38, 44, 61, 0.5)" }}
                      >
                        <div
                          className="align-items-center"
                          style={{
                            position: "absolute",
                            zIndex: "55",
                            top: "43%",
                            left: "40%",
                          }}
                        >
                          <img src={eye} className="mb-2" />
                          {pdfChecker(customerViewData?.gst_certificate) ? (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setOpenPdf(true);
                                setPdfFileUrl(
                                  customerViewData?.gst_certificate
                                );
                                setFileName("GST Certificate");
                              }}
                            >
                              VIEW
                            </button>
                          ) : (
                            <button
                              className="fs_16 fw_600"
                              style={{
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData("GST Certificate");
                                setImageUrlData(
                                  customerViewData?.gst_certificate
                                );
                              }}
                            >
                              VIEW
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">Onboarding Photos</label>
          </div>
          <div className="row">
            {customerViewData?.pharmacy_photo && (
              <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
                <div className="mx-4 px-3">
                  <div className="my-2">
                    <div className="upload-image-label-container d-flex justify-content-between">
                      <label className="my-1 Neutral_Color">Pharmacy</label>
                    </div>

                    <div
                      className="manufacturer-doc-upload-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        className="image-upload"
                        src={customerViewData?.pharmacy_photo}
                      />
                      <div
                        className="w-100"
                        style={{
                          position: "absolute",
                          zIndex: "55",
                          top: "0",
                          maxWidth: "450px",
                        }}
                      >
                        <div
                          className="manufacturer-doc-upload-container"
                          style={{
                            backgroundColor: "rgb(38, 44, 61, 0.6 )",
                          }}
                        >
                          <div
                            className="align-items-center px-2 mx-2 my-2"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            <span className="fs_11 Medium_Purple fw_500">
                              {customerViewData?.pharmacy_nameboard_lat} ,
                              <span className="ms-1">
                                {customerViewData?.pharmacy_nameboard_long}
                              </span>
                            </span>
                          </div>
                          <div
                            className="align-items-center"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "43%",
                              left: "40%",
                            }}
                          >
                            <img src={eye} className="mb-2" />
                            <button
                              className="fs_16 fw_600"
                              style={{
                                // position: "absolute",
                                // zIndex: "55",
                                // top: "80px",
                                // left: "180px",
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData("Pharmacy");
                                setImageUrlData(
                                  customerViewData?.pharmacy_photo
                                );
                              }}
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {customerViewData?.pharmacy_nameboard_photo && (
              <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
                <div className="mx-4 px-3">
                  <div className="my-2">
                    <div className="upload-image-label-container d-flex justify-content-between">
                      <label className="my-1 Neutral_Color">
                        Photo of Pharmacy’s Nameboard
                      </label>
                    </div>

                    <div
                      className="manufacturer-doc-upload-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        className="image-upload"
                        src={customerViewData?.pharmacy_nameboard_photo}
                      />
                      <div
                        className="w-100"
                        style={{
                          position: "absolute",
                          zIndex: "55",
                          top: "0",
                          maxWidth: "450px",
                        }}
                      >
                        <div
                          className="manufacturer-doc-upload-container"
                          style={{
                            backgroundColor: "rgb(38, 44, 61, 0.6 )",
                          }}
                        >
                          <div
                            className="align-items-center px-2 mx-2 my-2"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            <span className="fs_11 Medium_Purple fw_500">
                              {customerViewData?.pharmacy_nameboard_lat} ,
                              <span className="ms-1">
                                {customerViewData?.pharmacy_nameboard_long}
                              </span>
                            </span>
                          </div>
                          <div
                            className="align-items-center"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "43%",
                              left: "40%",
                            }}
                          >
                            <img src={eye} className="mb-2" />
                            <button
                              className="fs_16 fw_600"
                              style={{
                                // position: "absolute",
                                // zIndex: "55",
                                // top: "80px",
                                // left: "180px",
                                backgroundColor: "transparent",
                                color: "#E7E5FF",
                                border: "none",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageTitleData(
                                  "Photo of Pharmacy’s Nameboard"
                                );
                                setImageUrlData(
                                  customerViewData?.pharmacy_nameboard_photo
                                );
                              }}
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>

      <>
        {customerViewData?.pharmacyDoctors?.length > 0 &&
          customerViewData?.pharmacyDoctors?.map((ele, index) => {
            return (
              <>
                <div key={index}>
                  {customerViewData?.is_doctor && (
                    <>
                      {ele?.doctor?.is_basic_details && (
                        <>
                          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                            <label className="basic-title mx-2 mb-1">
                              Doctor’s Basic details
                            </label>
                          </div>
                          <div className="row mt-3 pt-3 ps-5 ">
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Jivini’s Doctor Code
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele.doctor.code}
                              </p>
                            </div>
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Doctor Name
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele.doctor.name}
                              </p>
                            </div>
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Pharmacy Type
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele.doctor.pharmacy_type
                                  ? ele.doctor.pharmacy_type
                                      .charAt(0)
                                      .toUpperCase() +
                                    ele.doctor.pharmacy_type.slice(1)
                                  : "--"}
                              </p>
                            </div>
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Qualification
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele.doctor.qualification}
                              </p>
                            </div>
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Specialization
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele?.doctor?.specialization?.name}
                              </p>
                            </div>
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                MCI Number
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {ele.doctor.medical_council_registration_number}
                              </p>
                            </div>
                            {/* <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                          <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                            Onboarded By
                          </h5>
                          <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                            Onboarded By
                          </p>
                        </div> */}
                            {/* <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                          <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                            Onboarded Date & Time
                          </h5>
                          <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                            Onboarded Date & Time
                          </p>
                        </div> */}
                            <div className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-0 px-3 ">
                              <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                Linked Manufacturer
                              </h5>
                              <p
                                className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                              >
                                {customerViewData?.pharmacySubscribedVendors
                                  ?.length > 0 &&
                                  customerViewData?.pharmacySubscribedVendors?.map(
                                    (ele, index) => {
                                      return (
                                        <span key={index}>
                                          {index ? (
                                            <span className="mx-1 color_b0b3c7">
                                              |
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {ele?.vendor?.name}
                                        </span>
                                      );
                                    }
                                  )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {ele?.doctor?.is_contact_details && (
                        <>
                          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                            <label className="basic-title mx-2 mb-1">
                              Doctor’s Contact Details
                            </label>
                          </div>
                          <div>
                            <div className="row mt-3">
                              <div className="pt-3 ps-5 row">
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Mobile Number
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.mobile}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Alternate Mobile Number
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.alternate_mobile}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Email Address
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.email}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Address
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.address?.address_one},
                                    <span className="mx-1">
                                      {ele?.doctor?.address?.address_two},
                                    </span>
                                    {ele?.doctor?.address?.address_three}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Landmark
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.address?.landmark}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    State
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.state?.name}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    District
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.district?.name}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    City
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.city?.name}
                                  </p>
                                </div>
                                <div className="mb-2 col-10 col-sm-6 col-md-6 col-lg-5  col-xxl-2 mx-3 px-3 ">
                                  <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                                    Pincode
                                  </h5>
                                  <p
                                    className={`fs_15 fn_Nunito  fw_500 color_262c3d`}
                                  >
                                    {ele?.doctor?.pincode}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <>
                  {customerViewData?.is_doctor &&
                    customerViewData?.pharmacyDoctors?.length > 0 && (
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                    )}
                  <div className="row my-2">
                    {customerViewData?.is_doctor && (
                      <>
                        <>
                          {ele?.doctor?.is_mci_certificate && (
                            <>
                              <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
                                <div className="mx-4 px-3">
                                  <div className="my-2">
                                    <div className="upload-image-label-container d-flex justify-content-between">
                                      <label className="my-1 Neutral_Color">
                                        MCI Certificate
                                      </label>
                                    </div>

                                    <div
                                      className="manufacturer-doc-upload-container"
                                      style={{ position: "relative" }}
                                    >
                                      {pdfChecker(
                                        ele?.doctor?.mci_certificate
                                      ) ? (
                                        <Document
                                          file={ele?.doctor?.mci_certificate}
                                        >
                                          <Page pageNumber={1} width={415} />
                                        </Document>
                                      ) : (
                                        <img
                                          className="image-upload"
                                          src={ele?.doctor?.mci_certificate}
                                        />
                                      )}
                                      <div
                                        className="w-100"
                                        style={{
                                          position: "absolute",
                                          zIndex: "55",
                                          top: "0",
                                          maxWidth: "450px",
                                        }}
                                      >
                                        <div
                                          className="manufacturer-doc-upload-container"
                                          style={{
                                            backgroundColor:
                                              "rgb(38, 44, 61, 0.6 )",
                                          }}
                                        >
                                          {/* <div
                                            className="align-items-center px-2 mx-2 my-2"
                                            style={{
                                              position: "absolute",
                                              zIndex: "55",
                                              backgroundColor: "#fff",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <span className="fs_11 Medium_Purple fw_500">
                                              {ele?.doctor?.latitude} ,
                                              <span className="ms-1">
                                                {ele?.doctor?.longitude}
                                              </span>
                                            </span>
                                          </div> */}
                                          <div
                                            className="align-items-center"
                                            style={{
                                              position: "absolute",
                                              zIndex: "55",
                                              top: "43%",
                                              left: "40%",
                                            }}
                                          >
                                            <img src={eye} className="mb-2" />

                                            {pdfChecker(
                                              ele?.doctor?.mci_certificate
                                            ) ? (
                                              <button
                                                className="fs_16 fw_600"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  color: "#E7E5FF",
                                                  border: "none",
                                                }}
                                                onClick={() => {
                                                  setOpenPdf(true);
                                                  setPdfFileUrl(
                                                    ele?.doctor?.mci_certificate
                                                  );
                                                  setFileName(
                                                    "MCI Certificate"
                                                  );
                                                }}
                                              >
                                                VIEW
                                              </button>
                                            ) : (
                                              <button
                                                className="fs_16 fw_600"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  color: "#E7E5FF",
                                                  border: "none",
                                                }}
                                                onClick={() => {
                                                  setImageModal(true);
                                                  setImageTitleData(
                                                    "MCI Certificate"
                                                  );
                                                  setImageUrlData(
                                                    ele?.doctor?.mci_certificate
                                                  );
                                                }}
                                              >
                                                VIEW
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                        <div>
                          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                            <label className="basic-title mx-2 mb-1">
                              Onboarding Photos
                            </label>
                          </div>
                          <div className="row">
                            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
                              <div className="mx-4 px-3">
                                <div className="my-2">
                                  <div className="upload-image-label-container d-flex justify-content-between">
                                    <label className="my-1 Neutral_Color">
                                      Clinic/Hospital
                                    </label>
                                  </div>

                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      className="image-upload"
                                      src={ele?.doctor?.hospital_photo}
                                    />
                                    <div
                                      className="w-100"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "0",
                                        maxWidth: "450px",
                                      }}
                                    >
                                      <div
                                        className="manufacturer-doc-upload-container"
                                        style={{
                                          backgroundColor:
                                            "rgb(38, 44, 61, 0.6 )",
                                        }}
                                      >
                                        <div
                                          className="align-items-center px-2 mx-2 my-2"
                                          style={{
                                            position: "absolute",
                                            zIndex: "55",
                                            backgroundColor: "#fff",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <span className="fs_11 Medium_Purple fw_500">
                                            {ele?.doctor?.hospital_photo_lat},
                                            <span className="ms-1">
                                              {ele?.doctor?.hospital_photo_lat}
                                            </span>
                                          </span>
                                        </div>
                                        <div
                                          className="align-items-center"
                                          style={{
                                            position: "absolute",
                                            zIndex: "55",
                                            top: "43%",
                                            left: "40%",
                                          }}
                                        >
                                          <img src={eye} className="mb-2" />
                                          <button
                                            className="fs_16 fw_600"
                                            style={{
                                              // position: "absolute",
                                              // zIndex: "55",
                                              // top: "80px",
                                              // left: "180px",
                                              backgroundColor: "transparent",
                                              color: "#E7E5FF",
                                              border: "none",
                                            }}
                                            onClick={() => {
                                              setImageModal(true);
                                              setImageTitleData(
                                                "Clinic/Hospital"
                                              );
                                              setImageUrlData(
                                                ele?.doctor?.hospital_photo
                                              );
                                            }}
                                          >
                                            VIEW
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0" style={{minWidth:"480px"}}>
                              <div className="mx-4 px-3">
                                <div className="my-2">
                                  <div className="upload-image-label-container d-flex justify-content-between">
                                    <label className="my-1 Neutral_Color">
                                      Photo of Clinic/Hospital’s Nameboard
                                    </label>
                                  </div>

                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      className="image-upload"
                                      src={
                                        ele?.doctor?.hospital_nameboard_photo
                                      }
                                    />
                                    <div
                                      className="w-100"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "0",
                                        maxWidth: "450px",
                                      }}
                                    >
                                      <div
                                        className="manufacturer-doc-upload-container"
                                        style={{
                                          backgroundColor:
                                            "rgb(38, 44, 61, 0.6 )",
                                        }}
                                      >
                                        <div
                                          className="align-items-center px-2 mx-2 my-2"
                                          style={{
                                            position: "absolute",
                                            zIndex: "55",
                                            backgroundColor: "#fff",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <span className="fs_11 Medium_Purple fw_500">
                                            {
                                              ele?.doctor
                                                ?.hospital_nameboard_photo_lat
                                            }
                                            ,
                                            <span className="ms-1">
                                              {
                                                ele?.doctor
                                                  ?.hospital_nameboard_photo_lat
                                              }
                                            </span>
                                          </span>
                                        </div>
                                        <div
                                          className="align-items-center"
                                          style={{
                                            position: "absolute",
                                            zIndex: "55",
                                            top: "43%",
                                            left: "40%",
                                          }}
                                        >
                                          <img src={eye} className="mb-2" />
                                          <button
                                            className="fs_16 fw_600"
                                            style={{
                                              // position: "absolute",
                                              // zIndex: "55",
                                              // top: "80px",
                                              // left: "180px",
                                              backgroundColor: "transparent",
                                              color: "#E7E5FF",
                                              border: "none",
                                            }}
                                            onClick={() => {
                                              setImageModal(true);
                                              setImageTitleData(
                                                "Photo of Clinic/Hospital’s Nameboard"
                                              );
                                              setImageUrlData(
                                                ele?.doctor
                                                  ?.hospital_nameboard_photo
                                              );
                                            }}
                                          >
                                            VIEW
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              </>
            );
          })}
      </>
      <ImageViewModal
        show={imageModal}
        ShowClose={() => setImageModal(false)}
        imageTitleData={imageTitleData}
        imageUrlData={imageUrlData}
      />
      <PdfView
        show={openPdf}
        handleClose={() => setOpenPdf(false)}
        pdfUrl={pdfFileUrl}
        filename={filename}
      />
    </>
  );
};

export default PharmacyView;
