import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import InputCapitalField from '../../../components/form/inputField/inputCapitalField';
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../manufacturer.css";
import Select from "react-select";
import HeadQuaterListTable from "../../../components/tables/headQuatersListTable";
import {
  fetchDistrictList,
  fetchStateList,
} from "../../../redux/actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMsg from "../../../components/form/errorMsg";
import { manufacturerCreateDesignation } from "../../../redux/actions/manufacturerActions";

const ManufacturerHeadQuaterCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const reducerData = useSelector((state) => state.manufacturerCreateReducer);
  const [headQuatersList, setHeadQuaterList] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [disable, setDisable] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const [editIndex, setEditIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  useEffect(() => {
    if (reducerData?.headquaters?.length > 0) {
      setHeadQuaterList(reducerData?.headquaters);
    }
  }, []);

  window.onload = () => {
    navigate('/manufacturer-list')
  };


  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch(
      fetchDistrictList(
        { state_id: selectedState },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
  }, [selectedState]);

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log("District", data);
  };
  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const initialValues = {
    name: "",
    state: {},
    district: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    state: Yup.object().required("This field is required."),
    district: Yup.array()
      .min(1, "Select at least one district")
      .required("Required"),
  });
  const clearFunc = (setFieldValue) => {
    setFieldValue("name", "");
    setFieldValue("state", {});
    setFieldValue("district", []);
  };

  const onSubmit = () => {
    console.log(headQuatersList);
    dispatch(
      manufacturerCreateDesignation(
        { headquaters: headQuatersList },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    console.log("added headquaters");
    navigate("/manufacturer/subscibe-list/create/division");
  };
  const onError = (err) => {
    console.log(err);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, state, district },
    } = useFormikContext();
    useEffect(() => {
      console.log(name, state, district);
      if (name.trim() != "" && state.value != "" && district.length != 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [name, state, district]);
    return null;
  };

  const addHeadQuaters = (values, { resetForm }) => {
    if (!editEnable) {
      setHeadQuaterList([...headQuatersList, values]);
    } else {
      headQuatersList[editIndex] = values;
      setHeadQuaterList([...headQuatersList]);
    }
    resetForm({
      values: {
        name: "",
        state: { label: "", value: "" },
        district: [],
      },
    });
    setEditEnable(false);
  };

  const editSetForm = (item, index, setFieldValue) => {
    setEditEnable(true);
    setEditIndex(index);
    setFieldValue("name", item.name);

    setFieldValue("state", item?.state);
    setFieldValue("district", item.district);
  };
  return (
    <MainLayout breadCrumb="ADMIN  /  Manufacturer / Create / Head Quater">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addHeadQuaters}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  type="button"
                  onClick={() => clearFunc(setFieldValue)}
                >
                  CLEAR
                </AdminButton>
                <AdminButton
                  background="#7065e4"
                  color="white"
                  width="100px"
                  type="button"
                  onClick={onSubmit}
                >
                  <span className="m-2">NEXT</span>
                </AdminButton>
              </div>
            </div>
            <div className="scrolling">
              <div className="fn_Nunito ">
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Head Quarters</label>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xl-6 col-md-9">
                  <div className="mx-4 px-3">
                    <div className="my-2">
                      <InputCapitalField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Head quarters Name"}
                        labelColor="color_262c3d"
                        type={"text"}
                        placeholder={"Enter head quarters name"}
                        name={"name"}
                        value={values?.name}
                      />
                      <div className="select-container">
                        <label className="w-100 mx-1 fn_Nunito fs_14">
                          State
                        </label>
                        <Field name={"state"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={stateOptions}
                              styles={customStyles}
                              placeholder={"Enter your state"}
                              value={stateOptions.filter(
                                (s) => s.value == values?.state?.value
                              )}
                              onChange={(selected) => {
                                setSelectedState(selected?.value);
                                setFieldValue("district", []);
                                setFieldValue("state", selected);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="state" />
                      </div>
                      <div className="select-container">
                        <label className="w-100 mx-1 fn_Nunito fs_14">
                          District
                        </label>
                        <Field name={"district"}>
                          {({ field, meta }) => (
                            <Select
                              isMulti
                              {...field}
                              options={districtOptions}
                              styles={customStyles}
                              placeholder={"Enter your district"}
                              // value={districtOptions.find((s) => {
                              //   console.log(s?.value,"ffs",values?.district);
                              //   return s.value == values?.district?.value;
                              // })}
                              onChange={(selected) => {
                                setFieldValue("district", selected);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="district" />
                      </div>
                      <div
                        className="d-flex justify-content-end"
                        style={{ maxWidth: "450px" }}
                      >
                        {!editEnable ? (
                          <button
                            type="submit"
                            className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                            style={{ maxWidth: "210px" }}
                            disabled={disable}
                          >
                            Add More
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                            style={{ maxWidth: "210px" }}
                            disabled={disable}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Head Quarters List
                  </label>
                </div>
                <div className="mx-4 px-3 my-3 mb-5">
                  <HeadQuaterListTable
                    headQuatersList={headQuatersList}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default ManufacturerHeadQuaterCreate;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    // height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
