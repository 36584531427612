import React, { useEffect, useState } from "react";

import logoutLogo from "../../../assets/icons/sidebar/signOut.svg";
import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardSVG from "../../../assets/icons/sidebar/dashboardSVG";
import ManufacturerSvg from "../../../assets/icons/sidebar/manufacturerSvg";
import DistributorSvg from "../../../assets/icons/sidebar/distributorSvg";

import CustomersSvg from "../../../assets/icons/sidebar/customersSvg";
import SuperUserSvg from "../../../assets/icons/sidebar/superUserSvg";
import FaqSvg from "../../../assets/icons/sidebar/FaqSvg";
import LogoutModal from "../modals/logoutModal";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";

import SettingsSvg from "../../../assets/icons/sidebar/settingsSvg";

import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import SupportSvg from "../../../assets/icons/sidebar/supportSvg";
import arrRig from "../../../assets/icons/sidebar/arrow-right-line.svg";
import SidebarComponent from "./sidebarComponent";
import AnalyticsSvg from "../../../assets/icons/sidebar/AnalyticsSvg";

const SideBar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const [logoutShow, setLogoutShow] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [userPermissionList, setUserPermissionList] = useState(null); // PERMISSION START
  const user_type = localStorage.getItem("userType");

  // console.log(userPermissionList.includes("Manufacturer"), "permission");

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      console.log(
        permissionData?.map((item) => item?.module_name),
        "permission"
      );

      setUserPermissionList(permissionData?.map((item) => item?.module_name));
    }
  }, []); // PERMISSION END

  const logoutHandler = () => {
    dispatch(logout(logoutSuccess, logoutError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/");
  };

  const logoutSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const logoutError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const location = useLocation();
  const url = location.pathname;
  const pathArray = url.split("/");
  const activePath = "/" + pathArray[1];
  const activeSubPath = "/" + pathArray[2];

  console.log(location.pathname, "path");
  console.log(activePath, "acpath");
  console.log(activeSubPath, "subpath");

  return (
    <>
      <>
        <div
          className={`${open
            ? "d-block mainlayout_sidebar_small_display"
            : "mainlayout_sidebar_display d-none d-lg-block"
            }   mainlayout_sidebar_container ms-1 position-relative`}
        >
          <div className="d-none d-lg-block" onClick={() => setOpen(!open)}>
            <div className="position-absolute arrow-container py-2 cursor_pointer">
              <div className={open ? "arrow-open-icon" : "arrow-icon me-1"}>
                <img src={arrRig} alt={"right"} />
              </div>
            </div>
          </div>
          {open ? (
            <div>
              <div className="d-none d-lg-block" style={{ height: "88vh" }}>
                <div className="d-flex flex-column justify-content-between align-items-between h-100 w-100">
                  <div
                    className="d-flex flex-column justify-content-center align-itmes-center py-5 text-uppercase"
                    onClick={() => setOpen(false)}
                  >
                    <ul className="nav-options w-100">
                      <div className="w-100 d-flex flex-column justify-content-center main position-relative">
                        <Link
                          to={"/dashboard"}
                          className="text-decoration-none mx-auto"
                        >
                          {activePath == "/dashboard" ? (
                            <DashboardSVG
                              height="24px"
                              width="24px"
                              fill="#7065E4"
                            />
                          ) : (
                            <DashboardSVG
                              height="24px"
                              width="24px"
                              fill="#B0B3C7"
                            />
                          )}
                        </Link>
                        <div className="title position-absolute fs_12 px-1">
                          Dashboard
                        </div>
                      </div>
                      {user_type == "super_admin" ? (
                        ""
                      ) : (
                        <div className="w-100 my-3 pt-1 d-flex flex-column justify-content-center main position-relative">
                          <Link
                            to={"/analytics"}
                            className="text-decoration-none mx-auto"
                          >
                            {activePath == "/analytics" ? (
                              <AnalyticsSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <AnalyticsSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </Link>
                          <div className="title position-absolute fs_12 px-1">
                            analytics
                          </div>
                        </div>
                      )}

                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("Manufacturer") ? (
                          <div className="w-100 d-flex justify-content-center my-3 cursor_pointer main position-relative">
                            <label
                              className="cursor_pointer mt-2"
                              onClick={() => {
                                navigate("/manufacturer/subscibe-list/1");
                              }}
                            >
                              {activePath == "/manufacturer" ? (
                                <ManufacturerSvg
                                  height="24px"
                                  width="24px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <ManufacturerSvg
                                  height="24px"
                                  width="24px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div
                              className="title position-absolute text-end fs_12 px-1"
                              style={{ right: "-5rem" }}
                            >
                              Manufacturers
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="w-100 d-flex justify-content-center my-3 cursor_pointer main position-relative">
                          <label
                            className="cursor_pointer mt-1"
                            onClick={() => {
                              navigate("/manufacturer/subscibe-list/1");
                            }}
                          >
                            {activePath == "/manufacturer" ? (
                              <ManufacturerSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <ManufacturerSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div
                            className="title position-absolute text-end fs_12 px-1"
                            style={{ right: "-5rem" }}
                          >
                            Manufacturers
                          </div>
                        </div>
                      )}

                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("Distributor") ? (
                          <div className="w-100 d-flex justify-content-center my-3 pt-1 cursor_pointer main position-relative">
                            <label
                              className="cursor_pointer"
                              onClick={() => {
                                navigate("/distributors/distributor/1");
                              }}
                            >
                              {activePath == "/distributors" ? (
                                <DistributorSvg
                                  height="24px"
                                  width="24px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <DistributorSvg
                                  height="24px"
                                  width="24px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div className="title position-absolute fs_12">
                              Distributors
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="w-100 d-flex justify-content-center my-3 pt-1 cursor_pointer main position-relative">
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              navigate("/distributors/distributor/1");
                            }}
                          >
                            {activePath == "/distributors" ? (
                              <DistributorSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <DistributorSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div className="title position-absolute fs_12">
                            Distributors
                          </div>
                        </div>
                      )}

                      {/* CUSTOMER */}
                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("Customer") ? (
                          <div className="w-100 d-flex justify-content-center my-3 pt-1 cursor_pointer cursor_pointer main position-relative">
                            <label
                              className="cursor_pointer"
                              onClick={() => {
                                navigate("/customers/customer/1");
                              }}
                            >
                              {activePath == "/customers" ? (
                                <CustomersSvg
                                  height="24px"
                                  width="24px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <CustomersSvg
                                  height="24px"
                                  width="24px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div className="title position-absolute fs_12">
                              Customers
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="w-100 d-flex justify-content-center my-3 pt-1 cursor_pointer cursor_pointer main position-relative">
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              navigate("/customers/customer/1");
                            }}
                          >
                            {activePath == "/customers" ? (
                              <CustomersSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <CustomersSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div className="title position-absolute fs_12">
                            Customers
                          </div>
                        </div>
                      )}
                      {/* SUPERUSER */}
                      {user_type == "super_admin" ? (
                        ""
                      ) : (
                        <div className="w-100 d-flex justify-content-center my-3 pt-1 cursor_pointer  main position-relative">
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              navigate("/super-user/user/1");
                            }}
                          >
                            {activePath == "/super-user" ? (
                              <SuperUserSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <SuperUserSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div className="title position-absolute fs_12">
                            Super users
                          </div>
                        </div>
                      )}
                      {/* FAQ */}
                      {user_type == "super_admin" ? (
                        ""
                      ) : (
                        <div className="w-100 d-flex flex-column align-items-center justify-content-center my-3 cursor_pointer main position-relative">
                          <Link
                            to={"/faq/1"}
                            className="text-decoration-none cursor_pointer"
                          >
                            {activePath == "/faq" ? (
                              <FaqSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <FaqSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </Link>
                          <div
                            className="title position-absolute fs_12"
                            style={{ right: "0rem" }}
                          >
                            FAQs
                          </div>
                        </div>
                      )}
                      {/* Settings */}
                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("Settings") ? (
                          <div
                            onClick={() => navigate("/settings/state")}
                            className="w-100 d-flex justify-content-center my-3  main position-relative"
                            title=""
                          >
                            <label className="cursor_pointer">
                              {activePath == "/settings" ? (
                                <SettingsSvg
                                  height="26px"
                                  width="26px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <SettingsSvg
                                  height="26px"
                                  width="26px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div
                              className="title position-absolute fs_12"
                              style={{ right: "-1.5rem" }}
                            >
                              Settings
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          onClick={() => navigate("/settings/state")}
                          className="w-100 d-flex justify-content-center my-3  main position-relative"
                          title=""
                        >
                          <label className="cursor_pointer">
                            {activePath == "/settings" ? (
                              <SettingsSvg
                                height="26px"
                                width="26px"
                                fill="#7065E4"
                              />
                            ) : (
                              <SettingsSvg
                                height="26px"
                                width="26px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div
                            className="title position-absolute fs_12"
                            style={{ right: "-1.5rem" }}
                          >
                            Settings
                          </div>
                        </div>
                      )}
                      {/* support */}
                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("Support") ? (
                          <div
                            className="w-100 d-flex justify-content-center my-3 main position-relative"
                            title=""
                          >
                            <label
                              className="cursor_pointer"
                              onClick={() => {
                                navigate("/support/invoice-verification/1");
                              }}
                            >
                              {activePath == "/support" ? (
                                <SupportSvg
                                  height="24px"
                                  width="24px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <SupportSvg
                                  height="24px"
                                  width="24px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div
                              className="title position-absolute fs_12"
                              style={{ right: "-1.5rem" }}
                            >
                              Support
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          className="w-100 d-flex justify-content-center my-3 main position-relative"
                          title=""
                        >
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              navigate("/support/invoice-verification/1");
                            }}
                          >
                            {activePath == "/support" ? (
                              <SupportSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <SupportSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div
                            className="title position-absolute fs_12"
                            style={{ right: "-1.5rem" }}
                          >
                            Support
                          </div>
                        </div>
                      )}

                      {user_type == "super_admin" ? (
                        userPermissionList?.length > 0 &&
                          userPermissionList.includes("ff-training") ? (
                          <div
                            className="w-100 d-flex justify-content-center my-3 main position-relative"
                            title=""
                          >
                            <label
                              className="cursor_pointer"
                              onClick={() => {
                                navigate("/ff-training/chapters/1");
                              }}
                            >
                              {activePath == "/ff-training" ? (
                                <SupportSvg
                                  height="24px"
                                  width="24px"
                                  fill="#7065E4"
                                />
                              ) : (
                                <SupportSvg
                                  height="24px"
                                  width="24px"
                                  fill="#B0B3C7"
                                />
                              )}
                            </label>
                            <div
                              className="title position-absolute fs_12"
                              style={{ right: "-1.5rem" }}
                            >
                              Field Force Training
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          className="w-100 d-flex justify-content-center my-3 main position-relative"
                          title=""
                        >
                          <label
                            className="cursor_pointer"
                            onClick={() => {
                              navigate("/ff-training/chapters/1");
                            }}
                          >
                            {activePath == "/ff-training" ? (
                              <SupportSvg
                                height="24px"
                                width="24px"
                                fill="#7065E4"
                              />
                            ) : (
                              <SupportSvg
                                height="24px"
                                width="24px"
                                fill="#B0B3C7"
                              />
                            )}
                          </label>
                          <div
                            className="title position-absolute fs_12"
                            style={{ right: "-1.5rem" }}
                          >
                            Field Force Training
                          </div>
                        </div>
                      )}
                    </ul>
                  </div>
                  <div className="w-100">
                    <div
                      className="logout-div d-flex justify-content-center cursor_pointer"
                      title="Logout"
                      onClick={() => {
                        setLogoutShow(true);
                      }}
                    >
                      <img src={logoutLogo} width={"20px"} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-lg-none position-absolute"
                onClick={() => setOpen(!open)}
              >
                <SidebarComponent
                  open={open}
                  setOpen={setOpen}
                  userPermissionList={userPermissionList}
                />
              </div>
            </div>
          ) : (
            <div style={{ maxWidth: "295px", overflow: "hidden" }}>
              <SidebarComponent
                open={open}
                setOpen={setOpen}
                userPermissionList={userPermissionList}
              />
            </div>
          )}
        </div>
      </>

      {/* {userType != "admin" && <SuperAdminView />} */}
      <LogoutModal
        logoutShow={logoutShow}
        handleLogoutClose={() => setLogoutShow(false)}
        logoutFunc={logoutHandler}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default SideBar;
