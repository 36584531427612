import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/modals/deleteModal";
import EnableDisableModal from "../../components/modals/enableDisableModal";
import SendMailModal from "../../components/modals/manufacturer/send-mail-modal";
import {
  manufacturerCreateDesignation,
  manufacturerDelete,
  manufacturerEnableDisable,
} from "../../redux/actions/manufacturerActions";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import TableSendMailModal from "../../components/modals/manufacturer/send-mail-table-modal";

const ManufacturerListTableData = ({
  data,
  index,
  fetchManufacturerList,
  currentPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [sendMailShow, setSendMailShowShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [userData, setUserData] = useState(null); // PERMISSION START
  const user_type = localStorage.getItem("userType");

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []); // PERMISSION END

  const enableDisableFunc = (id) => {
    dispatch(
      manufacturerEnableDisable(
        id,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    fetchManufacturerList();
  };

  const onEnableDisableSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc1, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const delFunc = () => {
    dispatch(manufacturerDelete(deleteId, onDelSuccess, onDeleteError));
  };

  const timeOutFnc2 = () => {
    setShowSuccessPopup1(false);
    fetchManufacturerList();
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc2, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const onDeleteError = (err) => {
    console.log(err);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };

  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">
        {" "}
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data.employee_id}</td>
      <td className="table-each-row-data text-capitalize">{data.name}</td>
      <td className="table-each-row-data text-center">
        {data?.orders?.length}
      </td>
      <td className="table-each-row-data text-center">
        {data?.vendorDistributors?.length}
      </td>
      {data?.mail_sent == true ? (
        <td className="table-each-row-data text_green">
          <span className="fw_700 px-2">SENT</span>
        </td>
      ) : (
        <td className="table-each-row-data text_red">
          <button
            className="send-mail-btn"
            onClick={() => setSendMailShowShow(true)}
            disabled={data?.status == "draft"}
          >
            Send Mail
          </button>
        </td>
      )}

      {data?.status == "active" ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : data?.status == "inactive" ? (
        <td className="table-each-row-data text_red">Inactive</td>
      ) : (
        <td className="table-each-row-data Medium_Purple">Draft</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(
                `/manufacturer/subscibe-list/view/${data.id}&manufacturer-details`
              )
            }
          >
            <span className=""> View</span>
          </button>
          {(user_type == "super_admin" && userData?.write) ||
          user_type == "admin" ? (
            <>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className="button-edit1"
                type="button"
                onClick={() => {
                  if (data.status == "draft") {
                    dispatch(
                      manufacturerCreateDesignation(
                        { vendor_id: data?.id },
                        () => {},
                        () => {}
                      )
                    );
                    navigate("/manufacturer/subscibe-list/create/designation", {
                      state: true,
                    });
                  } else
                    navigate(`/manufacturer/subscibe-list/edit/${data.id}`);
                }}
              >
                Edit
              </button>
              <span className="vr  vr_line mt-1 "></span>
              <button
                className={
                  data?.status == "active" ? `button-disable` : `button-enable`
                }
                type="button"
                color={"green"}
                onClick={() => setOnEnableShow(true)}
                disabled={data?.status == "draft"}
              >
                {data?.status == "active" ? "disable" : "enable"}
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      </td>
      <EnableDisableModal
        enableDisableFunc={enableDisableFunc}
        handleClose={() => setOnEnableShow(false)}
        id={data.id}
        title={`manufacturer ${data.employee_id} ?`}
        status={data?.status}
        show={onEnableShow}
      />
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      <TableSendMailModal
        data={data}
        show={sendMailShow}
        fetchManufacturerList={fetchManufacturerList}
        handleClose={() => setSendMailShowShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </tr>
  );
};

export default ManufacturerListTableData;
