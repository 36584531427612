import { api } from "./api";

export const ffTraingChapterListApi = (params) => {
    return api.get(`/admin/ff-training/chapter-list`, { params });
};
export const ffTraingChapterCreateApi = (params) => {
    return api.post(`/admin/ff-training/chapter-create`, JSON.stringify(params));
};
export const ffTraingChapterEditApi = (params) => {
    return api.post(`/admin/ff-training/chapter-edit`, JSON.stringify(params));
};
export const ffTraingChapterViewApi = (params) => {
    return api.get(`/admin/ff-training/chapter-view`, { params });
};

export const ffTraingChapterDeleteApi = (params) => {
    return api.post(`/admin/ff-training/chapter-delete`, JSON.stringify(params));
};



export const ffTrainingQaListApi = (params) => {
    return api.get(`/admin/ff-training/qa-list`, { params });
};
export const ffTrainingQaCreateApi = (params) => {
    return api.post(`/admin/ff-training/qa-create`, JSON.stringify(params));
};
export const ffTrainingQaEditApi = (params) => {
    return api.post(`/admin/ff-training/qa-edit`, JSON.stringify(params));
};
export const ffTrainingQaViewApi = (params) => {
    return api.get(`/admin/ff-training/qa-view`, { params });
};
export const ffTrainingQaDeleteApi = (params) => {
    return api.post(`/admin/ff-training/qa-delete`, JSON.stringify(params));
};
export const ffTrainingChaptersDropDownApi = (params) => {
    return api.get(`/admin/ff-training/chapters-dropdown`, { params });
};