import React from "react";

const DashboardSVG = ({ fill = "#B0B3C7", width = "14px", height = "14px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_753_8634)">
        <path
          d="M2.33332 0H4.08332C5.37198 0 6.41665 1.04467 6.41665 2.33332V4.08332C6.41665 5.37198 5.37198 6.41665 4.08332 6.41665H2.33332C1.04467 6.41668 0 5.37201 0 4.08332V2.33332C0 1.04467 1.04467 0 2.33332 0Z"
          fill={fill}
        />
        <path
          d="M9.91682 0H11.6668C12.9555 0 14.0001 1.04467 14.0001 2.33332V4.08332C14.0001 5.37198 12.9555 6.41665 11.6668 6.41665H9.91682C8.62816 6.41665 7.5835 5.37198 7.5835 4.08332V2.33332C7.58347 1.04467 8.62814 0 9.91682 0Z"
          fill={fill}
        />
        <path
          d="M2.33332 7.5835H4.08332C5.37198 7.5835 6.41665 8.62816 6.41665 9.91682V11.6668C6.41665 12.9555 5.37198 14.0001 4.08332 14.0001H2.33332C1.04467 14.0002 0 12.9555 0 11.6668V9.91685C0 8.62816 1.04467 7.5835 2.33332 7.5835Z"
          fill={fill}
        />
        <path
          d="M9.91682 7.5835H11.6668C12.9555 7.5835 14.0001 8.62816 14.0001 9.91682V11.6668C14.0001 12.9555 12.9555 14.0002 11.6668 14.0002H9.91682C8.62816 14.0002 7.5835 12.9555 7.5835 11.6668V9.91685C7.58347 8.62816 8.62814 7.5835 9.91682 7.5835Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_753_8634">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardSVG;
