import React, { useEffect, useState } from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";
import TablePagination from "../Pagination/tablePagination";
import { Table } from "react-bootstrap";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    // border: "1px solid #e7e5ff",

    maxWidth: "30rem",
    height: "25rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);
const DistrictListTable = ({
  maxWidth = "60rem",
  districtListData,
  editSetForm,
  setFieldValue,
  setPage,
  page,
}) => {
  const currentPage = districtListData?.pagination?.currentPage;

  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table>
          <thead>
            <tr className="table-rows color_747f9e text-nowrap bg-white">
              <th className="table-heads py-2 " width={"1%"}>
                SL. No
              </th>
              <th className="table-heads py-2" width={"5%"}>
                District Name
              </th>
              <th className="table-heads " width={"4%"}>
                State
              </th>
              {(user_type == "super_admin" && userData?.write) ||
              user_type == "admin" ? (
                <th className="table-heads " width={"4%"}>
                  Actions
                </th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {districtListData?.districts?.length > 0 &&
              districtListData?.districts?.map((item, index) => (
                <tr
                  className="table-rows1 color_262c3d text-nowrap"
                  key={index}
                >
                  <td className="table-each-row-data">
                    {(currentPage - 1) * 10 + (index + 1)}
                  </td>
                  <td className="table-each-row-data">{item?.name}</td>
                  <td className="table-each-row-data">{item?.state?.name}</td>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <td className="table-each-row-data">
                      <button
                        className="button-edit1"
                        type="button"
                        onClick={() => editSetForm(item, index, setFieldValue)}
                      >
                        Edit
                      </button>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </TableContainer>{" "}
      <div style={{ maxWidth: "62rem" }} className="">
        {districtListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={districtListData?.pagination}
            nextFunc={() => {
              setPage(page + 1);
            }}
            prevFunc={() => {
              setPage(page - 1);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DistrictListTable;
