import { api } from "./api";

export const customerVerifyListApi = (params) => {
  return api.get(`/admin/customer-verification/list`, { params });
};
export const customerVerifyViewApi = (params) => {
  return api.get(`/admin/customer-verification/view`, { params: params });
};
export const verifyReportApi = (params) => {
  return api.post(`/admin/customer-verification/report-issue`, JSON.stringify(params));
};