import React, { useEffect, useState } from "react";
import ChangeStatusModal from "../../../../components/modals/manufacturer/invoice/changeStatusModal";
import { PoInvoicedList } from "../../../../redux/actions/manufacturerActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import searcher from "../../../../assets/icons/searchInput.svg";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";

const InvoicedPOList = ({ setactiveInvoice, setActiveInvoiceId }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [poInvoicedData, setPoInvoicedData] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");
  const user_type = localStorage.getItem("userType");
  const vendorId = params?.id.split("&")?.[0];
  const navigate = useNavigate();

  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    poInvoicedList();
  }, []);

  const poInvoicedList = () => {
    setLoading(true);
    dispatch(
      PoInvoicedList({ vendor_id: vendorId, search }, onSuccess, onError)
    );
  };

  useEffect(() => {
    poInvoicedList();
  }, [search]);

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setPoInvoicedData(data?.data?.data);
  };
  const onError = (err) => {
    setLoading(false);
    console.log(err);
  };

  return (
    <div className="my-3">
      <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
        <img className="styled-SVG" src={searcher} color={""} />
        <input
          className="search-input fs_14 fw_400 text-dark px-2"
          type="text"
          placeholder="Search invoice here by id ..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="manufacturer-product-table-container">
        <table className="main-table text-nowrap">
          <thead
            style={{ position: "sticky", top: 0, zIndex: 55 }}
            className="bg-white"
          >
            <tr className="table-rows color_747f9e">
              <th className="table-heads py-2" width={"4%"}>
                SL No
              </th>
              <th className="table-heads" width={"10%"}>
                Invoice ID
              </th>
              <th className="table-heads " width={"8%"}>
                Invoiced Date & Time
              </th>
              <th className="table-heads" width={"10%"}>
                Total Invoice Value
              </th>
              <th className="table-heads" width={"10%"}>
                No of POs
              </th>
              <th className="table-heads" width={"10%"}>
                Taxes
              </th>
              <th className="table-heads" width={"10%"}>
                Status
              </th>
              <th className="table-heads" width={"15%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ position: "relative" }}>
            {loading == true ? (
              <div className="gev-spinner-container">
                <LoadingSpinnerTable />
              </div>
            ) : (
              <>
                {poInvoicedData?.length > 0 ? (
                  poInvoicedData?.map((item, index) => (
                    <tr className="table-rows1 ">
                      <td className="table-each-row-data">{index + 1}</td>
                      <td className="table-each-row-data">
                        {item?.code ? item?.code : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.updatedAt
                          ? moment(item?.updatedAt).format(
                              "DD MMM YYYY HH:mm A"
                            )
                          : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.invoiced_value
                          ? `₹ ${item?.invoiced_value}`
                          : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.no_of_po ? item?.no_of_po : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.taxes ? `₹ ${item?.taxes}` : "--"}
                      </td>
                      {item?.status !== "payment_pending" ? (
                        <td className="table-each-row-data text_green">Paid</td>
                      ) : (
                        <td className="table-each-row-data text_red">
                          Payment Pending
                        </td>
                      )}
                      <td className="table-each-row-data">
                        <div className="all-button d-flex align-items-center">
                          <button
                            className="button-view"
                            type="button"
                            onClick={() => {
                              navigate(
                                `/manufacturer/${pathType}/view/${vendorId}&invoices&invoced&view&${item?.id}`
                              );
                            }}
                          >
                            <span className=""> View</span>
                          </button>
                          {(user_type == "super_admin" && userData?.write) ||
                          user_type == "admin" ? (
                            <>
                              <span className="vr  vr_line mt-1 "></span>
                              <button
                                className="button-edit1"
                                type="button"
                                onClick={() => {
                                  setChangeStatus(true);
                                  setModalData(item);
                                }}
                              >
                                Change Status
                              </button>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="1"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </>
            )}
            {/* {poInvoicedData?.length > 0 ? (
                poInvoicedData?.map((item, index) => (
                  <tr className="table-rows1 ">
                    <td className="table-each-row-data">{index + 1}</td>
                    <td className="table-each-row-data">
                      {item?.code ? item?.code : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.updatedAt
                        ? moment(item?.updatedAt).format("DD MMM YYYY HH:mm A")
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.invoiced_value ? item?.invoiced_value : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.no_of_po ? item?.no_of_po : "--"}
                    </td>
                    {item?.status !== "payment_pending" ? (
                      <td className="table-each-row-data text_green">Paid</td>
                    ) : (
                      <td className="table-each-row-data text_red">
                        Payment Pending
                      </td>
                    )}
                    <td className="table-each-row-data">
                      <div className="all-button d-flex align-items-center">
                        <button
                          className="button-view"
                          type="button"
                          onClick={() => {
                            setactiveInvoice(21);
                            setActiveInvoiceId(item?.id);
                          }}
                        >
                          <span className=""> View</span>
                        </button>
                        {(user_type == "super_admin" && userData?.write) ||
                        user_type == "admin" ? (
                          <>
                            <span className="vr  vr_line mt-1 "></span>
                            <button
                              className="button-edit1"
                              type="button"
                              onClick={() => {
                                setChangeStatus(true);
                                setModalData(item);
                              }}
                            >
                              Change Status
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="1"
                    className="text-center no-data-container fs_16"
                  >
                    <span>~ No Data Found ~</span>
                  </td>
                </tr>
              )} */}
          </tbody>
        </table>
      </div>

      <ChangeStatusModal
        refreshFnc={poInvoicedList}
        code={modalData?.code}
        id={modalData?.id}
        status={modalData?.status}
        show={changeStatus}
        handleClose={() => setChangeStatus(false)}
      />
    </div>
  );
};

export default InvoicedPOList;

const dummyData = [
  {
    slno: "1",
    id: "INID1324567",
    dt: "12 sep 2022, 7:33 PM",
    value: "1200",
    po: "12",
    status: true,
  },
  {
    slno: "2",
    id: "INID1324567",
    dt: "12 sep 2022, 7:33 PM",
    value: "1200",
    po: "12",
    status: false,
  },
];
