import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../manufacturer.css";
import TablePagination from "../../../../components/Pagination/tablePagination";
import DownArrow from "../../../../assets/icons/filterArrowDown.svg";
import UpArrow from "../../../../assets/icons/filterArrowUp.svg";
import { Spinner } from "react-bootstrap";
import {
  geProductReportList,
  gevProductDelete,
  gevProductList,
} from "../../../../redux/actions/godsEyeViewActions";
import LoadingSpinner from "../../../../components/utilites/loaderSpinningContainer";
import searcher from "../../../../assets/icons/searchInput.svg";
import DeleteModal from "../../../../components/modals/deleteModal";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import exportFromJSON from "export-from-json";

const ProductList = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const vendor_id = params?.id.split("&")?.[0];
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [productId, setProductId] = useState("");
  const [delproductId, setDelProductId] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    gevOrdersdList();
  }, [page, status, search]);

  useEffect(() => {
    setPage(1);
    gevOrdersdList();
  }, [search]);

  const gevOrdersdList = () => {
    setLoading(true);
    dispatch(
      gevProductList(
        { page, status, search, vendor_id },
        onprodSuccess,
        onprodError
      )
    );
  };

  const onprodSuccess = (data) => {
    // console.log(data);
    setProductData(data?.data?.data);
    setLoading(false);
  };

  const onprodError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const delFunc = () => {
    dispatch(gevProductDelete(delproductId, onDelSuccess, onDeleteError));
  };

  const onDelSuccess = (data) => {
    console.log(data);
    setDeleteShow(false);
    setShowSuccessPopup(true);
    setShowSuccessMsg("Product Deleted Successfully");

    const timeOutFnc = () => {
      gevOrdersdList();
      setShowSuccessPopup(false);
    };
    setTimeout(timeOutFnc, 2000);
  };

  const onDeleteError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
  };
  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(geProductReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "Product ID": data?.product_id ? data?.product_id : "--",
          "Companys Product ID": data?.company_product_id
            ? data?.company_product_id
            : "--",
          "Product Name": data?.product_name
            ? data?.product_name.charAt(0).toUpperCase() +
            data?.product_name.slice(1)
            : "--",
          "Division Name": data?.vendorDivision?.name
            ? data?.vendorDivision?.name
            : "--",
          status: data?.status == true ? "Yes" : "No",
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setShowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  return (
    <>
      <div className="mx-5 px- my-3 mt-4">
        <div className="d-flex justify-content-between">
          <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search product here by product name , id , company’s product ID ..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <button
              className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
              type="button"
              onClick={() => exportTableCsv()}
            >
              download
            </button>
          </div>
        </div>
        <div
          className="manufacturer-product-table-container "
          style={{ height: "33.5rem" }}
        >
          <div style={{ height: "30rem" }}>
            <table className="main-table text-nowrap">
              <thead
                style={{ position: "sticky", top: 0, zIndex: 55 }}
                className="bg-white"
              >
                <tr className="table-rows color_747f9e">
                  <th className="table-heads py-2" width={"2%"}>
                    #
                  </th>
                  <th className="table-heads" width={"8%"}>
                    Product ID
                  </th>
                  <th className="table-heads" width={"6%"}>
                    Company’s Product ID
                  </th>
                  <th className="table-heads" width={"6%"}>
                    Product Name
                  </th>
                  <th className="table-heads" width={"8%"}>
                    Division Name
                  </th>
                  <th className="table-heads text-nowrap" width={"8%"}>
                    <span
                      onClick={() => {
                        setOpenStatus(!openStatus);
                      }}
                    >
                      Status
                      {openStatus ? (
                        <img src={UpArrow} className="mb-1 mx-1" />
                      ) : (
                        <img src={DownArrow} className=" mx-1" />
                      )}
                    </span>
                    {openStatus ? (
                      <div className=" position-relative">
                        <div className="position-absolute status-container d-flex gap-3 px-3">
                          <div className="d-flex align-items-center ">
                            <input
                              className="radio me-2"
                              type="radio"
                              id="State"
                              name="status"
                              value={true}
                              checked={status == "true"}
                              onClick={(e) => {
                                setOpenStatus(!openStatus);
                                setStatus(e.target.value);
                                setActive("active");
                              }}
                            />
                            <span
                              for="State"
                              className={
                                active === "active"
                                  ? "color_262c3d"
                                  : "color_747f9e"
                              }
                            >
                              Active
                            </span>
                          </div>
                          <div className="d-flex align-items-center ">
                            <input
                              className="radio me-2"
                              type="radio"
                              id="State"
                              name="status"
                              value={false}
                              checked={status == "false"}
                              onClick={(e) => {
                                setOpenStatus(!openStatus);
                                setStatus(e.target.value);
                                setActive("inactive");
                              }}
                            />
                            <span
                              for="State"
                              className={
                                active === "inactive"
                                  ? "color_262c3d"
                                  : "color_747f9e"
                              }
                            >
                              Inactive
                            </span>
                          </div>
                          <button
                            className="filter-btn px-2 py-0 my-2 fs_12"
                            style={{ height: "28px" }}
                            onClick={() => {
                              setStatus(null);
                              setOpenStatus(!openStatus);
                            }}
                          >
                            CLEAR
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </th>
                  <th className="table-heads" width={"12%"}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="mt-3" style={{ position: "relative" }}>
                {loading == true ? (
                  <div className="gev-spinner-container">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {productData?.products?.length > 0 ? (
                      productData?.products?.map((data, index) => (
                        <tr className="table-rows1 color_262c3d" key={index}>
                          <td className="table-each-row-data">
                            {(productData?.pagination?.currentPage - 1) * 10 +
                              (index + 1)}
                          </td>
                          <td className="table-each-row-data">
                            {data?.product_id ? data?.product_id : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.company_product_id
                              ? data?.company_product_id
                                .charAt(0)
                                .toUpperCase() +
                              data?.company_product_id.slice(1)
                              : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.product_name
                              ? data?.product_name.charAt(0).toUpperCase() +
                              data?.product_name.slice(1)
                              : "--"}
                          </td>
                          <td className="table-each-row-data">
                            {data?.vendorDivision?.name
                              ? data?.vendorDivision?.name
                              : "--"}
                          </td>
                          {data?.status == true ? (
                            <td className="table-each-row-data text_green">
                              Active
                            </td>
                          ) : (
                            <td className="table-each-row-data text_red">
                              Inactive
                            </td>
                          )}
                          <td className="table-each-row-data">
                            <div className="all-button d-flex align-items-center">
                              <button
                                className="button-view"
                                type="button"
                                onClick={() => {
                                  navigate(
                                    `/manufacturer/${pathType}/view/${vendor_id}&products&view&${data?.id}`
                                  );
                                }}
                              >
                                <span className=""> View</span>
                              </button>
                              <>
                                {(user_type == "super_admin" &&
                                  userData?.write) ||
                                  user_type == "admin" ? (
                                  <>
                                    <span className="vr vr_line mt-1"></span>
                                    <button
                                      className="button-edit1"
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/manufacturer/${pathType}/view/${vendor_id}&products&edit&${data?.id}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <span className="vr vr_line mt-1"></span>
                                    <button
                                      className="button-disable"
                                      type="button"
                                      onClick={() => {
                                        setDeleteShow(true);
                                        setDelProductId(data?.id);
                                        setProductId(data?.product_name);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="1"
                          className="text-center no-data-container fs_16"
                        >
                          <span>~ No Data Found ~</span>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {/* <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} /> */}
        </div>
        {productData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={productData?.pagination}
            nextFunc={() => {
              setPage(productData?.pagination?.currentPage + 1);
            }}
            prevFunc={() => {
              setPage(productData?.pagination?.currentPage - 1);
            }}
          />
        )}
      </div>
      <DeleteModal
        title={`product - ${productId}`}
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ProductList;

const data = [{}];
