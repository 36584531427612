import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/deleteModal";
import { customerDelete } from "../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const RequestListTableData = ({ data, index, currentPage, customerList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false);
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();

  const delFunc = () => {
    dispatch(
      customerDelete(
        {
          user_type: data?.customer_type,
          pharmacy_id: data?.customer_type !== "doctor" && data.id,
          doctor_id: data?.customer_type == "doctor" && data.id,
        },
        deleteSuccess,
        deleteError
      )
    );
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup1(false);
    customerList();
  };

  const deleteSuccess = (data) => {
    console.log(data);
    setDeleteShow(false);
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc1, 2000);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const deleteError = (err) => {
    console.log(err);
    setDeleteShow(false);
    const str1 = err.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowErrorMsg1(str_modify1);
    setShowErrorPopup1(true);
  };
  return (
    <tr className="table-rows1" key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>

      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.name
            ? `DR. ${data?.name.toUpperCase()}`
            : "--"
          : data?.pharmacyDoctors?.length !== 0
            ? `DR. ${data?.pharmacyDoctors?.[0]?.doctor?.name}`
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name
            ? data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name.toUpperCase()
            : "--"
          : data?.pharmacy_name
            ? data?.pharmacy_name.toUpperCase()
            : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.vendor?.name ? data?.vendor?.name : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.vendorDivision?.name ? data?.vendorDivision?.name : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.city?.name ? data?.city?.name : "--"}
      </td>
      <td className="table-each-row-data text-capitalize">
        {data?.mobile ? data?.mobile : "--"}
      </td>
      {data?.approval === "pending" ? (
        <td className="table-each-row-data text-warning">Pending</td>
      ) : (
        <td className="table-each-row-data text_red">Rejected</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/customers/request/view/${data.id}`, {
                state: {
                  user_type: data?.customer_type,
                  pharmacy_id: data?.customer_type !== "doctor" && data.id,
                  doctor_id: data?.customer_type == "doctor" && data.id,
                },
              })
            }
          >
            <span className=""> View</span>
          </button>
          <span className="vr vr_line mt-1 "></span>
          <button
            className="button-disable"
            type="button"
            onClick={() => {
              setDeleteShow(true);
            }}
          >
            Delete
          </button>
        </div>
      </td>
      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        title={`customer - ${data?.customer_type == "doctor" ? data?.name : data?.pharmacy_name
          } ?`}
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        handleClose={closeErrorPopup1}
      />
    </tr>
  );
};

export default RequestListTableData;
