import React, { useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import InputFieldAmount from "../../../../components/form/inputField/inputFieldAmount";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { designationEdit } from "../../../../redux/actions/manufacturerActions";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMsg from "../../../../components/form/errorMsg";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import { gevDesignationView } from "../../../../redux/actions/godsEyeViewActions";

const DesignationEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [designationData, setDesignationData] = useState("");
  const params = useParams();
  const vendorId = params?.id.split("&")?.[0];
  const desig_id = params?.id.split("&")?.[3];

  useEffect(() => {
    dispatch(gevDesignationView(desig_id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data?.data?.data, "ssss");
    setDesignationData(data?.data?.data);
  };
  const onError = (err) => { };

  const initialValues = {
    name: designationData?.name,
    order_approval_from:designationData?.order_approval_from,
    order_approval_upto: designationData?.order_approval_upto,
    approval_level: designationData?.approval_level,
    approval_authority: designationData?.approval_authority,
    customer_verification: designationData?.customer_verification,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").trim(),
    order_approval_from: Yup.number()
    .nullable()
    .when(["approval_authority"], {
      is: (approvalAuthority) => {
        return approvalAuthority === true;
      },
      then: Yup.number()
        .integer("Invalid")
        .typeError("invalid value")
        .required("Required"),
    }),
    order_approval_upto: Yup.number()
      .nullable()
      .when(["approval_authority"], {
        is: (approvalAuthority) => {
          return approvalAuthority === true;
        },
        then: Yup.number()
          .integer("Invalid")
          .typeError("invalid value")
          .required("Required"),
      }),
    approval_authority: Yup.boolean(),
    approval_level: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    values["vendor_id"] = vendorId;
    values["designation_id"] = desig_id;
    dispatch(designationEdit(values, onAddSuccess, onAddError));
  };

  const resetFunc = (setFieldValue) => {
    setFieldValue("name", designationData?.name);
    setFieldValue("order_approval_upto", designationData?.order_approval_upto);
    setFieldValue("order_approval_from", designationData?.order_approval_from);
    setFieldValue("approval_level", designationData?.approval_level);
    setFieldValue("approval_authority", designationData?.approval_authority);
    setFieldValue(
      "customer_verification",
      designationData?.customer_verification
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate(`/manufacturer/subscibe-list/view/${vendorId}&designations&list`);
  };

  const onAddSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    setShowSuccessMsg(data.data.message);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onAddError = (err) => {
    console.log(err.data);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, approval_level },
    } = useFormikContext();
    useEffect(() => {
      if (name != "" && approval_level?.value != "") {
        setDisable(false);
      } else setDisable(true);
    }, [name, approval_level]);
    return null;
  };

  const options = [
    { value: "L1", label: "Level 1" },
    { value: "L2", label: "Level 2" },
    { value: "L3", label: "Level 3" },
    { value: "L4", label: "Level 4" },
    { value: "L5", label: "Level 5" },
    { value: "L6", label: "Level 6" },
    { value: "L7", label: "Level 7" },
    { value: "L8", label: "Level 8" },
    { value: "L9", label: "Level 9" },
    { value: "L10", label: "Level 10" },
    { value: "L11", label: "Level 11" },
    { value: "L12", label: "Level 12" },
    { value: "L13", label: "Level 13" },
    { value: "L14", label: "Level 14" },
    { value: "L15", label: "Level 15" },
    { value: "admin", label: "Admin" },
  ];

  return (
    <>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">Add Designations</label>
      </div>
      <div className="col-xl-6 col-md-9">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors, setFieldValue, values }) => (
            <Form>
              <FormEnableCheck />
              <div className="mx-4 px-3">
                <div className="select-container">
                  <label className={`fw_400 fs_14 my-1`}>Approval Level</label>
                  <Field name={"approval_level"}>
                    {({ form, field, meta }) => (
                      <div className="d-flex">
                        <Select
                          {...field}
                          value={options.find(
                            (s) => s.value == values?.approval_level
                          )}
                          cacheOptions
                          defaultOptions
                          placeholder={"Select level"}
                          options={options}
                          onChange={(selected) => {
                            form.setFieldValue(
                              "approval_level",
                              selected.value
                            );
                          }}
                          className={`w-100`}
                          styles={customStyles}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMsg name={"approval_level"} />
                </div>
                <div className="my-2">
                  <InputCapitalField
                    setFieldValue={setFieldValue}
                    id={"name"}
                    label={"Designation Name"}
                    labelColor="color_262c3d"
                    type={"text"}
                    placeholder={"Enter Designation Name"}
                    name={"name"}
                    value={values?.name}
                  />
                </div>

                {values?.approval_level != "admin" && (
                  <>
                    <div className="d-flex my-4 align-items-center color_747f9e fw_400 fs_14">
                      <label>Customer Verification</label>
                      <Switch
                        checked={values?.customer_verification}
                        onChange={() => {
                          if (!values.customer_verification) {
                            setFieldValue("customer_verification", true);
                          } else {
                            setFieldValue("customer_verification", false);
                            // setFieldValue("order_approval_upto", "");
                          }
                        }}
                        className="mx-3"
                        onColor="#d3d6eb"
                        offColor="#d3d6eb"
                        onHandleColor="#7065E4"
                        offHandleColor="#747F9E"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={40}
                      />
                    </div>
                    <div className="d-flex my-2 align-items-center color_747f9e fw_400 fs_14">
                      <label>Approval Authority</label>
                      <Switch
                        checked={values?.approval_authority}
                        onChange={() => {
                          if (!values.approval_authority) {
                            setFieldValue("approval_authority", true);
                          } else {
                            setFieldValue("approval_authority", false);
                            setFieldValue("order_approval_upto", "");
                            setFieldValue("order_approval_from", "");
                          }
                        }}
                        className="mx-3"
                        onColor="#d3d6eb"
                        offColor="#d3d6eb"
                        onHandleColor="#7065E4"
                        offHandleColor="#747F9E"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={40}
                      />
                    </div>
                    {values?.approval_authority && (
                      <>
                        <div className="my-3 mt-4">
                          <label className="color_747f9e">
                            Order Approval Limit
                          </label>
                          <div>
                          <InputFieldAmount
                              type="number"
                              label={"From"}
                              labelColor="color_747f9e"
                              className="amount_input  py-1  ms-1"
                              id="order_approval_from"
                              name="order_approval_from"
                              placeholder="Enter amount"
                            />
                            <InputFieldAmount
                              type="number"
                              label={"Upto"}
                              labelColor="color_747f9e"
                              className="amount_input  py-1  ms-1"
                              id="order_approval_upto"
                              name="order_approval_upto"
                              placeholder="Enter amount"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div
                  className="d-flex justify-content-end mt-5"
                  style={{ maxWidth: "450px" }}
                >
                  <button
                    type="button"
                    className="reset-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                    style={{ maxWidth: "210px" }}
                    onClick={() => resetFunc(setFieldValue)}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                    style={{ maxWidth: "210px" }}
                    disabled={disable}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default DesignationEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "black",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#262C3D",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
