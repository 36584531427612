import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerDistriReqListTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();

  return (
    <>
      <tr className="table-rows1" key={index}>
        <td className="table-each-row-data">
          {(currentPage - 1) * 10 + (index + 1)}
        </td>
        <td className="table-each-row-data">
          {data?.doctor?.code ? data?.doctor?.code : "--"}
        </td>
        <td className="table-each-row-data">
          {data?.pharmacy?.code ? data?.pharmacy?.code : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.doctor?.name ? data?.doctor?.name.toUpperCase() : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.pharmacy?.pharmacy_name ? data?.pharmacy?.pharmacy_name.toUpperCase() : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.doctor?.city?.name ? data?.doctor?.city?.name : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.vendor?.name ? data?.vendor?.name : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.vendorDivision?.name ? data?.vendorDivision?.name : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.distributor?.firm_name ? data?.distributor?.firm_name : "--"}
        </td>
        <td className="table-each-row-data text-capitalize">
          {data?.approval ? (
            data?.approval == "approved" ? (
              <span className="text_green">Approved</span>
            ) : data?.approval == "rejected" ? (
              <span className="text_red">Rejected</span>
            ) : (
              data?.approval == "pending" && (
                <span className="Medium_Purple">Pending</span>
              )
            )
          ) : (
            "--"
          )}
        </td>
        <td className="table-each-row-data">
          <div className="all-button d-flex align-items-center">
            <button
              className="button-view"
              type="button"
              onClick={() =>
                navigate(`/customers/distributor-request/view/${data.id}`)
              }
            >
              <span className=""> View</span>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CustomerDistriReqListTableData;
