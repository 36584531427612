import {
  INVOICE_PTR_UPDATE,
  INVOICE_REUPLOAD,
  INVOICE_VERIFIED_LIST,
  INVOICE_VERIFY,
  INVOICE_VERIFY_DETAIL,
  INVOICE_VERIFY_LIST,
  REMARK_LIST,
} from "./types";

export const invoiceVerifyList = (data, onSuccess, onError) => {
  return {
    type: INVOICE_VERIFY_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const invoiceVerifiedList = (data, onSuccess, onError) => {
  return {
    type: INVOICE_VERIFIED_LIST,
    data,
    onSuccess,
    onError,
  };
};


export const invoiceVerifyDetail = (id, onSuccess, onError) => {
  return {
    type: INVOICE_VERIFY_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const remarkList = (onSuccess, onError) => {
  return {
    type: REMARK_LIST,
    onSuccess,
    onError,
  };
};

export const invoiceReupload = (data, onSuccess, onError) => {
  return {
    type: INVOICE_REUPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceVerify = (data, onSuccess, onError) => {
  return {
    type: INVOICE_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

export const invoiceUpdatePtr = (data, onSuccess, onError) => {
  return {
    type: INVOICE_PTR_UPDATE,
    data,
    onSuccess,
    onError,
  };
};