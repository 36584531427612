import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { useNavigate } from "react-router";

export const AdminButton = styled("button")(
  {
    backgroundColor: "transparent",
    outline: "0px",
    marginRight: "8px",
    width: "140px",
    height: "42px",
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "5px",
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#7065e4",

    // "@media (max-width: 768px)": {
    //   height: "35px",
    // },
  },
  (props) => ({
    background:
      props.disabled == true ? `${props.background}99` : props.background,
    width: props.width,
    border: props.border,
    color: props.color,
    disabled: props.disabled,
  })
);

const VersionHistoryBtn = ({ link = "/" }) => {
  const navigate = useNavigate();
  return (
    <AdminButton onClick={() => navigate(link)}> Version History</AdminButton>
  );
};

export default VersionHistoryBtn;
