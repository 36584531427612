import { call, takeLatest } from "redux-saga/effects";
import { DASHBOARD_VIEW, GRAPH_CARD } from "../actions/types";
import { dashboardApi, graphCardApi } from "../apis/dashboardApi";

export function* dashboardWorker(action) {
  try {
    const res = yield call(dashboardApi);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* graphCardWorker(action) {
  try {
    const res = yield call(graphCardApi, action.params);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* dashboardWatcher() {
  yield takeLatest(DASHBOARD_VIEW, dashboardWorker);
}

export function* graphCardWatcher() {
  yield takeLatest(GRAPH_CARD, graphCardWorker)
}




