import React from "react";
import { useNavigate } from "react-router-dom";

const DistributorVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  return (
    <tr className="table-rows1" key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">
        {data?.distributor_id ? data?.distributor_id : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.firm_name ? data?.firm_name : "--"}
      </td>
      <td className="table-each-row-data text-center">
        {data?.orders?.length ? data?.orders?.length : "--"}
      </td>
      <td className="table-each-row-data text-center">
        {data?.vendorDistributors?.length
          ? data?.vendorDistributors?.length
          : "--"}
      </td>
      {data?.status ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : (
        <td className="table-each-row-data text_red">Inactive</td>
      )}
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/distributors/distributor/version-view/${data?.id}`)
            }
          >
            <span className="">View</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default DistributorVersionTableData;
