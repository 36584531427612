import React from "react";
import verifyIcon from "../../../assets/icons/verified.svg";
import "../modal.css";
import { Modal, ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ReportIssueSuccessModal = ({ show, handleClose, mailData }) => {
 
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName="report-verify-modal"
    >
      <ModalBody>
        <div className="my-4 mt-4 text-center">
          <p className="fn_Nunito fw_700 fs_18 my-2 px-0">
            <img src={verifyIcon} alt="icon" />
            <span className="fw_600 ms-1">Issue Reported Successfully</span>
          </p>

          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">Mail sent</p>
          <div className="">
            <span>To :</span>
            <span className="color_747f9e fs_16 ms-1">
              {mailData?.to?.map((item, index) => (
                <span className="" key={index}>
                  <span style={{ color: "#B0B3C7" }}>{index ? " , " : ""}</span>
                  {item}
                </span>
              ))}
            </span>
          </div>
          <div className="">
            <span>CC :</span>
            <span className="color_747f9e fs_16 ms-1">
              {mailData?.cc?.map((item, index) => (
                <span className="" key={index}>
                  <span style={{ color: "#B0B3C7" }}>{index ? " , " : ""}</span>
                  {item}
                </span>
              ))}
            </span>
          </div>
          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {/* {data?.distributor?.firm_name} */}
          </p>
          <p className="color_747f9e text-nowrap fs_16 fw_500 m-0">
            {/* {data?.vendor?.name} */}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReportIssueSuccessModal;
