import React, { useEffect } from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "15px",
    maxWidth: "30rem",
    height: "15rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);
const DesignationListTable = ({
  maxWidth = "60rem",
  designationList,
  editSetForm,
  setFieldValue,
}) => {
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table className="main-table">
          <thead>
            <tr className="table-rows color_747f9e text-nowrap">
              <th className="table-heads " width={"1%"}>
                #
              </th>
              <th className="table-heads " width={"5%"}>
                Designation Name
              </th>
              <th className="table-heads " width={"4%"}>
                Order Approval Limit
              </th>
              <th className="table-heads" width={"2%"}>
                Approval Level
              </th>
              <th className="table-heads" width={"3%"}>
                Approval Authority
              </th>
              <th className="table-heads " width={"4%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {designationList.length > 0 &&
              designationList.map((item, index) => (
                <tr
                  className="table-rows1 color_262c3d text-nowrap"
                  key={index}
                >
                  <td className="table-each-row-data">{index + 1}</td>
                  <td className="table-each-row-data">
                    {item.name ? item.name : "--"}
                  </td>
                  <td className="table-each-row-data">
                    {item.order_approval_upto ? item.order_approval_upto : "--"}
                  </td>
                  <td className="table-each-row-data">{item.approval_level}</td>

                  {item.approval_level ? (
                    <td className="table-each-row-data text_green">
                      <span className="fw_600">Enabled</span>
                    </td>
                  ) : item.approval_authority ? (
                    <td className="table-each-row-data text_green">
                      <span className="fw_600">Enabled</span>
                    </td>
                  ) : (
                    <td className="table-each-row-data text_red">
                      <span className="fw_600">Disabled</span>
                    </td>
                  )}
                  <td className="table-each-row-data">
                    <button
                      className="button-edit1"
                      type="button"
                      onClick={() => editSetForm(item, index, setFieldValue)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default DesignationListTable;
