import {
  CREATE_MANUFACTURER_CLEAR,
  CREATE_MANUFACTURER_ERROR,
  CREATE_MANUFACTURER_LOADING,
  CREATE_MANUFACTURER_SUCCESS,
  CREATE_MANUFACTURER_UPDATE,
} from "../actions/returnType";
import { manufacturerCreateData } from "../constants/manufacturerCreateData";

export const manufacturerCreateReducer = (
  state = manufacturerCreateData,
  action
) => {
  switch (action.type) {
    case CREATE_MANUFACTURER_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case CREATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        vendor_id: action?.vendor_id,
      };
    case CREATE_MANUFACTURER_UPDATE:
      let newData = { ...state };

      if (action.vendor_id) {
        newData.vendor_id = action?.vendor_id;
      }
      if (action.headquaters) {
        newData.headquaters = action?.headquaters;
      }
      if (action.designations) {
        newData.designations = action?.designations;
      }
      if (action.divisions) {
        newData.divisions = action?.divisions;
      }
      return {
        ...newData,
      };
    case CREATE_MANUFACTURER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        vendor_id: null,
        data: null,
      };
    case CREATE_MANUFACTURER_CLEAR:
      return {
        state,
      };
    default:
      return state;
  }
};
