import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../../../assets/icons/back.svg";
import InputField from "../../../../components/form/inputField/inputField";
import MainLayout from "../../../../components/layout/mainLayout/mainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import {
  customerDetail,
  customerEdit,
  documentPhoto1,
} from "../../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField";
import ErrorMsg from "../../../../components/form/errorMsg";
import moment from "moment";
import {
  fetchSecondCityList,
  fetchSecondDistrictList,
  fetchSecondStateList,
  fetchStateList,
} from "../../../../redux/actions/commonActions";
import { fetchDistrictList } from "../../../../redux/actions/commonActions";
import { fetchCityList } from "../../../../redux/actions/commonActions";
import reupload from "../../../../assets/icons/reUpload.svg";
import { Document, Page } from "react-pdf";
import eye from "../../../../assets/icons/viewEye.svg";
import PdfView from "../../../../components/modals/pdfView";
import ImageViewModal from "../../../../components/modals/customer/image-view-modal";
import { Spinner } from "react-bootstrap";
import {
  documentUpld1,
  manufacturerLogoUpload,
} from "../../../../redux/actions/manufacturerActions";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { secondStateListWatcher } from "../../../../redux/sagas/commonSaga";
import { pdfChecker } from "../../../../components/utilites/helper";

const CustomerDoctorEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [customerViewData, setCustomerViewData] = useState(null);
  const [doctorStateOptions, setDoctorStateOptions] = useState([]);
  const [doctorDistrictOptions, setDoctorDistrictOptions] = useState([]);
  const [doctorCityOptions, setDoctorCityOptions] = useState([]);
  const [doctorSelectedState, setDoctorSelectedState] = useState(null);
  const [doctorSelectedDistrict, setDoctorSelectedDistrict] = useState(null);
  const [doctorSelectedCity, setDoctorSelectedCity] = useState(null);
  //PHARMACY STATE ,DISTRICT , CITY
  const [pharmacyStateOptions, setPharmacyStateOptions] = useState([]);
  const [pharmacyDistrictOptions, setPharmacyDistrictOptions] = useState([]);
  const [pharmacyCityOptions, setPharmacyCityOptions] = useState([]);
  const [pharmacySelectedState, setPharmacySelectedState] = useState(null);
  const [pharmacySelectedDistrict, setPharmacySelectedDistrict] =
    useState(null);
  const [pharmacySelectedCity, setPharmacySelectedCity] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageTitleData, setImageTitleData] = useState("");
  const [imageUrlData, setImageUrlData] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  const [drugLicense1, setDrugLicense1] = useState("");
  const [drugLicense2, setDrugLicense2] = useState("");
  const [gstCertificate, setGstCertificate] = useState("");
  const [mciCertificate, setMciCertificate] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess1, onGetStateError1));
  }, []);

  const onGetStateSuccess1 = (data) => {
    setDoctorStateOptions(data);
    // setPharmacyStateOptions(data);
  };

  const onGetStateError1 = (err) => {
    console.log("dataa", err);
  };

  useEffect(() => {
    if (doctorSelectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: doctorSelectedState },
          onGetDistrictSuccess1,
          onGetDistrictError1
        )
      );
    }
  }, [doctorSelectedState]);

  useEffect(() => {
    if (doctorSelectedState !== null && doctorSelectedDistrict !== null) {
      dispatch(
        fetchCityList(
          {
            state_id: doctorSelectedState,
            district_id: doctorSelectedDistrict,
          },
          onGetCitySuccess1,
          onGetCityError1
        )
      );
    }
  }, [doctorSelectedState, doctorSelectedDistrict]);

  const onGetDistrictSuccess1 = (data) => {
    console.log(data);
    setDoctorDistrictOptions(data);
  };
  const onGetDistrictError1 = (err) => {
    console.log(err);
  };
  const onGetCitySuccess1 = (data) => {
    console.log(data);
    setDoctorCityOptions(data);
  };
  const onGetCityError1 = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch(fetchSecondStateList(onGetStateSuccess2, onGetStateError2));
  }, []);

  const onGetStateSuccess2 = (data) => {
    console.log("dataa", data);
    setPharmacyStateOptions(data);
  };

  const onGetStateError2 = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (pharmacySelectedState !== null) {
      dispatch(
        fetchSecondDistrictList(
          { state_id: pharmacySelectedState },
          onGetDistrictSuccess2,
          onGetDistrictError2
        )
      );
    }
  }, [pharmacySelectedState]);

  useEffect(() => {
    if (pharmacySelectedState !== null && pharmacySelectedDistrict !== null) {
      dispatch(
        fetchSecondCityList(
          {
            state_id: pharmacySelectedState,
            district_id: pharmacySelectedDistrict,
          },
          onGetCitySuccess2,
          onGetCityError2
        )
      );
    }
  }, [pharmacySelectedState, pharmacySelectedDistrict]);

  const onGetDistrictSuccess2 = (data) => {
    console.log(data);
    setPharmacyDistrictOptions(data);
  };
  const onGetDistrictError2 = (err) => {
    console.log(err);
  };
  const onGetCitySuccess2 = (data) => {
    console.log(data);
    setPharmacyCityOptions(data);
  };
  const onGetCityError2 = (err) => {
    console.log(err);
  };

  const initialValues = () => {
    if (state.user_type == "doctor" && state.pharmacy_type == "attach") {
      return {
        doctor_basic_details: {
          qualification: customerViewData?.qualification,
          specialization_name: customerViewData?.specialization?.name,
          name: customerViewData?.name
        },
        doctor_contact_details: {
          mobile: customerViewData?.mobile,
          alternate_mobile: customerViewData?.alternate_mobile,
          email: customerViewData?.email,
          address: customerViewData?.address,
          state_id: customerViewData?.state_id,
          district_id: customerViewData?.district_id,
          city_id: customerViewData?.city_id,
          pincode: customerViewData?.pincode,
        },
        pharmacy_basic_details: {
          pharmacy_name:
            customerViewData?.doctorAttachPharmacy?.pharmacy?.pharmacy_name,
          drug_licence_number_20_a:
            customerViewData?.doctorAttachPharmacy?.pharmacy
              ?.drug_licence_number_20_a,
          drug_licence_number_20_b:
            customerViewData?.doctorAttachPharmacy?.pharmacy
              ?.drug_licence_number_20_b,
          gst_number:
            customerViewData?.doctorAttachPharmacy?.pharmacy?.gst_number,
        },
        pharmacy_contact_details: {
          mobile: customerViewData?.doctorAttachPharmacy?.pharmacy?.mobile,
          alternate_mobile:
            customerViewData?.doctorAttachPharmacy?.pharmacy?.alternate_mobile,
          email: customerViewData?.doctorAttachPharmacy?.pharmacy?.email,
          address: customerViewData?.doctorAttachPharmacy?.pharmacy?.address,
          state_id: customerViewData?.doctorAttachPharmacy?.pharmacy?.state_id,
          district_id:
            customerViewData?.doctorAttachPharmacy?.pharmacy?.district_id,
          city_id: customerViewData?.doctorAttachPharmacy?.pharmacy?.city_id,
          pincode: customerViewData?.doctorAttachPharmacy?.pharmacy?.pincode,
        },
        documents: {
          mci_certificate: customerViewData?.mci_certificate,
          drug_licence_certificate_20_a:
            customerViewData?.doctorAttachPharmacy?.pharmacy
              ?.drug_licence_certificate_20_a,
          drug_licence_certificate_20_b:
            customerViewData?.doctorAttachPharmacy?.pharmacy
              ?.drug_licence_certificate_20_b,
          gst_certificate:
            customerViewData?.doctorAttachPharmacy?.pharmacy?.gst_certificate,
        },
      };
    }

    if (state.user_type == "doctor" && state.pharmacy_type == "dispensing") {
      return {
        doctor_basic_details: {
          qualification: customerViewData?.qualification,
          specialization_name: customerViewData?.specialization?.name,
          name: customerViewData?.name
        },
        doctor_contact_details: {
          mobile: customerViewData?.mobile,
          alternate_mobile: customerViewData?.alternate_mobile,
          email: customerViewData?.email,
          address: customerViewData?.address,
          state_id: customerViewData?.state_id,
          district_id: customerViewData?.district_id,
          city_id: customerViewData?.city_id,
          pincode: customerViewData?.pincode,
        },
        documents: {
          mci_certificate: customerViewData?.mci_certificate,
        },
      };
    }
    if (state.user_type == "doctor" && state.pharmacy_type == "self") {
      return {
        doctor_basic_details: {
          qualification: customerViewData?.qualification,
          specialization_name: customerViewData?.specialization?.name,
          name: customerViewData?.name
        },
        doctor_contact_details: {
          mobile: customerViewData?.mobile,
          alternate_mobile: customerViewData?.alternate_mobile,
          email: customerViewData?.email,
          address: customerViewData?.address,
          state_id: customerViewData?.state_id,
          district_id: customerViewData?.district_id,
          city_id: customerViewData?.city_id,
          pincode: customerViewData?.pincode,
        },
        pharmacy_basic_details: {
          pharmacy_name: customerViewData?.doctorSelfPharmacy?.pharmacy_name,
          drug_licence_number_20_a:
            customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_a,
          drug_licence_number_20_b:
            customerViewData?.doctorSelfPharmacy?.drug_licence_number_20_b,
          gst_number: customerViewData?.doctorSelfPharmacy?.gst_number,
        },
        documents: {
          mci_certificate: customerViewData?.mci_certificate,
          drug_licence_certificate_20_a:
            customerViewData?.doctorSelfPharmacy?.drug_licence_certificate_20_a,
          drug_licence_certificate_20_b:
            customerViewData?.doctorSelfPharmacy?.drug_licence_certificate_20_b,
          gst_certificate:
            customerViewData?.doctorSelfPharmacy?.gst_certificate,
        },
      };
    }
    if (state.user_type == "pharmacy" && state.pharmacy_type == "independent") {
      return {
        pharmacy_basic_details: {
          pharmacy_name: customerViewData?.pharmacy_name,
          drug_licence_number_20_a: customerViewData?.drug_licence_number_20_a,
          drug_licence_number_20_b: customerViewData?.drug_licence_number_20_b,
          gst_number: customerViewData?.gst_number,
        },
        pharmacy_contact_details: {
          mobile: customerViewData?.mobile,
          alternate_mobile: customerViewData?.alternate_mobile,
          email: customerViewData?.email,
          address: customerViewData?.address,
          state_id: customerViewData?.state_id,
          district_id: customerViewData?.district_id,
          city_id: customerViewData?.city_id,
          pincode: customerViewData?.pincode,
        },
        documents: {
          drug_licence_certificate_20_a:
            customerViewData?.drug_licence_certificate_20_a,
          drug_licence_certificate_20_b:
            customerViewData?.drug_licence_certificate_20_b,
          gst_certificate: customerViewData?.gst_certificate,
        },
      };
    }
    if (state.user_type == "pharmacy" && state.pharmacy_type == "attach") {
      return {
        pharmacy_basic_details: {
          pharmacy_name: customerViewData?.pharmacy_name,
          drug_licence_number_20_a: customerViewData?.drug_licence_number_20_a,
          drug_licence_number_20_b: customerViewData?.drug_licence_number_20_b,
          gst_number: customerViewData?.gst_number,
        },
        pharmacy_contact_details: {
          mobile: customerViewData?.mobile,
          alternate_mobile: customerViewData?.alternate_mobile,
          email: customerViewData?.email,
          address: customerViewData?.address,
          state_id: customerViewData?.state_id,
          district_id: customerViewData?.district_id,
          city_id: customerViewData?.city_id,
          pincode: customerViewData?.pincode,
        },
        documents: {
          drug_licence_certificate_20_a:
            customerViewData?.drug_licence_certificate_20_a,
          drug_licence_certificate_20_b:
            customerViewData?.drug_licence_certificate_20_b,
          gst_certificate: customerViewData?.gst_certificate,
        },
      };
    }
  };

  const validationSchema = Yup.object().shape({});

  const resetFunc = () => { };

  const onSubmit = (value) => {
    console.log(value);
    console.log(state);
    value.documents["mci_certificate"] = mciCertificate;
    value.documents["drug_licence_certificate_20_a"] = drugLicense1;
    value.documents["drug_licence_certificate_20_b"] = drugLicense2;
    value.documents["gst_certificate"] = gstCertificate;
    value["user_type"] = state.user_type;
    value["doctor_id"] = state.doctor_id;
    value["pharmacy_type"] = state.pharmacy_type;
    value["pharmacy_id"] = state.pharmacy_id;
    console.log(value);
    dispatch(customerEdit(value, onEditSuccess, onEditError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/customers/customer/1");
  };

  const onEditSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    setShowSuccessMsg(data.data.message);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onEditError = (err) => {
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    console.log(err);
  };

  useEffect(() => {
    fetchCustomerView();
  }, []);

  const fetchCustomerView = () => {
    console.log(state);
    setLoading(true);
    dispatch(customerDetail(state, onDetailsSuccess, onDetailsError));
  };

  const onDetailsSuccess = (data) => {
    setLoading(false);
    console.log(data);
    setCustomerViewData(data?.data?.data);
    setDoctorSelectedState(data?.data?.data?.state_id);
    setDoctorSelectedDistrict(data?.data?.data?.district_id);
    setDoctorSelectedCity(data?.data?.data?.city_id);

    if (data?.data?.data?.customer_type == "doctor") {
      setPharmacySelectedState(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy?.state_id
      );
      setPharmacySelectedDistrict(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy?.district_id
      );
      setPharmacySelectedCity(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy?.city_id
      );
    } else {
      setPharmacySelectedState(data?.data?.data?.state_id);
      setPharmacySelectedDistrict(data?.data?.data?.district_id);
      setPharmacySelectedCity(data?.data?.data?.city_id);
    }

    setMciCertificate(data?.data?.data?.mci_certificate);

    if (state.user_type == "doctor" && state.pharmacy_type == "attach") {
      setDrugLicense1(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy
          ?.drug_licence_certificate_20_a
      );
      setDrugLicense2(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy
          ?.drug_licence_certificate_20_b
      );
      setGstCertificate(
        data?.data?.data?.doctorAttachPharmacy?.pharmacy?.gst_certificate
      );
    }

    if (state.user_type == "doctor" && state.pharmacy_type == "self") {
      setDrugLicense1(
        data?.data?.data?.doctorSelfPharmacy?.drug_licence_certificate_20_a
      );
      setDrugLicense2(
        data?.data?.data?.doctorSelfPharmacy?.drug_licence_certificate_20_b
      );
      setGstCertificate(data?.data?.data?.doctorSelfPharmacy?.gst_certificate);
    }
    if (state.user_type == "pharmacy") {
      setDrugLicense1(data?.data?.data?.drug_licence_certificate_20_a);
      setDrugLicense2(data?.data?.data?.drug_licence_certificate_20_b);
      setGstCertificate(data?.data?.data?.gst_certificate);
    }
  };
  const onDetailsError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const handleDoc1Change = (e) => {
    console.log(e.target.files[0]);
    dispatch(
      documentPhoto1(
        e.target.files[0],
        { user_type: state.user_type },
        ondoc1UploadSuccess,
        ondoc1UploadError
      )
    );
  };
  const ondoc1UploadSuccess = async (data) => {
    console.log(data.data.data);
    setDrugLicense1(data?.data?.data?.filePath);
  };
  const ondoc1UploadError = (err) => { };

  const handleDoc2Change = (e) => {
    console.log(e.target.files[0]);
    dispatch(
      documentPhoto1(
        e.target.files[0],
        { user_type: state.user_type },
        ondoc2UploadSuccess,
        ondoc2UploadError
      )
    );
  };
  const ondoc2UploadSuccess = async (data) => {
    console.log(data.data.data);
    setDrugLicense2(data?.data?.data?.filePath);
  };
  const ondoc2UploadError = (err) => { };

  const handleDoc3Change = (e) => {
    console.log(e.target.files[0]);
    dispatch(
      documentPhoto1(
        e.target.files[0],
        { user_type: state.user_type },
        ondoc3UploadSuccess,
        ondoc3UploadError
      )
    );
  };
  const ondoc3UploadSuccess = async (data) => {
    console.log(data.data.data);
    setGstCertificate(data?.data?.data?.filePath);
  };
  const ondoc3UploadError = (err) => { };

  const handleMicChange = (e) => {
    console.log(e.target.files[0]);
    dispatch(
      documentPhoto1(
        e.target.files[0],
        { user_type: state.user_type },
        onMicUploadSuccess,
        onMicUploadError
      )
    );
  };
  const onMicUploadSuccess = async (data) => {
    console.log(data.data.data);
    setMciCertificate(data?.data?.data?.filePath);
  };

  const onMicUploadError = (err) => { };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        (customerViewData?.code
          ? ` / ${customerViewData.code} - edit`
          : " / edit")}
    </span>
  );

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <Formik
        initialValues={initialValues()}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="view-spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : (
              <>
                {state.user_type == "doctor" &&
                  state.pharmacy_type == "attach" && (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Basic details
                        </label>
                      </div>
                      <div className="row mt-3 text-capitalize">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Doctor Code
                              </label>
                              <p className="fs_14">{customerViewData?.code}</p>
                            </div>

                            {/* <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Doctor Name
                              </label>
                              <p className="fs_14">DR. {customerViewData?.name.toUpperCase()}</p>
                            </div> */}

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.name"}
                                label={"Doctor Name"}
                                type={"text"}
                                placeholder={"Enter Name"}
                                name="doctor_basic_details.name"
                                value={
                                  values?.doctor_basic_details?.name
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.qualification"}
                                label={"Qualification"}
                                type={"text"}
                                placeholder={"Enter Qualification"}
                                name="doctor_basic_details.qualification"
                                value={
                                  values?.doctor_basic_details?.qualification
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.fieldForceUser?.name
                                  ? customerViewData?.onboardingFieldforce
                                    ?.fieldForceUser?.name
                                  : "Not Yet Onboarded"}
                              </p>
                            </div>

                            <div className="my-2">
                              <label className="color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.updatedAt
                                  ? moment(
                                    customerViewData?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.specialization"}
                                label={"Specialization"}
                                type={"text"}
                                placeholder={"Enter Specialization"}
                                name={"doctor_basic_details.specialization"}
                                value={
                                  values?.doctor_basic_details
                                    ?.specialization_name
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.mobile"}
                                label={"Mobile Number"}
                                disabled={true}
                                type={"text"}
                                placeholder={"Enter mobile number"}
                                name={"doctor_contact_details.mobile"}
                                maxLength={10}
                                value={values?.doctor_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"doctor_contact_details.alternate_mobile"}
                                maxLength={10}
                                value={
                                  values?.doctor_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                placeholder={"Enter email address"}
                                disabled={true}
                                name={"doctor_contact_details.email"}
                                value={values?.doctor_contact_details?.email}
                              />
                            </div>
                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_two"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 3"
                                      id={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors.address_one && (
                                  <ErrorMsg
                                    name={
                                      "doctor_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors.address_two && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors.address_three && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.address.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"doctor_contact_details.address.landmark"}
                                value={
                                  values?.doctor_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>
                                <Field name={"doctor_contact_details.state_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={doctorStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedState(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"doctor_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={doctorDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "doctor_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field name={"doctor_contact_details.city_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={doctorCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedCity(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"doctor_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"doctor_contact_details.pincode"}
                                value={values?.doctor_contact_details?.pincode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Basic Details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Pharmacy Code
                              </label>
                              <p className="fs_14">
                                {
                                  customerViewData?.doctorAttachPharmacy
                                    ?.pharmacy?.code
                                }
                              </p>
                            </div>

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>

                            <div className="my-2">
                              <InputCapitalField
                                setFieldValue={setFieldValue}
                                id={"pharmacy_basic_details.pharmacy_name"}
                                label={"Pharmacy Name"}
                                type={"text"}
                                placeholder={"Enter Pharmacy Name"}
                                name={"pharmacy_basic_details.pharmacy_name"}
                                value={
                                  values?.pharmacy_basic_details?.pharmacy_name
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_basic_details.gst_number"}
                                label={"GST Number"}
                                type={"text"}
                                placeholder={"Enter GST Number"}
                                name={"pharmacy_basic_details.gst_number"}
                                value={
                                  values?.pharmacy_basic_details?.gst_number
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {
                                  customerViewData?.doctorAttachPharmacy
                                    ?.pharmacy?.onboardingFieldforce
                                    ?.fieldForceUs
                                }
                              </p>
                            </div>

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.doctorAttachPharmacy
                                  ?.pharmacy?.onboardingFieldforce?.updatedAt
                                  ? moment(
                                    customerViewData?.doctorAttachPharmacy
                                      ?.pharmacy?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                label={"Drug License Number - 20A"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20A"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_a
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                label={"Drug License Number - 20B"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20B"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_b
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.mobile"}
                                label={"Mobile Number"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter mobile number"}
                                name={"pharmacy_contact_details.mobile"}
                                value={values?.pharmacy_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"alternate_mobile"}
                                value={
                                  values?.pharmacy_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter email address"}
                                name={"pharmacy_contact_details.email"}
                                value={values?.pharmacy_contact_details?.email}
                              />
                            </div>

                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={"pharmacy_contact_details.address_two"}
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors?.address?.address_one && (
                                  <ErrorMsg
                                    name={
                                      "pharmacy_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors?.address?.address_two && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors?.address?.address_three && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"pharmacy_contact_details.landmark"}
                                value={
                                  values?.pharmacy_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.state_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={pharmacyStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedState(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={pharmacyDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.city_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={pharmacyCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedCity(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"pharmacy_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"pharmacy_contact_details.pincode"}
                                value={
                                  values?.pharmacy_contact_details?.pincode
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  MCI Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleMicChange(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{ position: "relative" }}
                              >
                                {pdfChecker(mciCertificate) ? (
                                  <Document file={mciCertificate}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={mciCertificate}
                                  />
                                )}
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.6 )",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(mciCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(mciCertificate);
                                            setFileName("MCI Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "MCI Certificate"
                                            );
                                            setImageUrlData(mciCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate 1
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      // accept="pdf/*"
                                      onChange={(e) =>
                                        handleDoc1Change(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense1) ? (
                                    <Document file={drugLicense1}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense1}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(drugLicense1) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense1);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense1);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate - 2
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc2Change(
                                          e,
                                          "documents.drug_licence_certificate_20_b",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  // width: "15rem",
                                  // border: "1px solid black",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense2) ? (
                                    <Document file={drugLicense2}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense2}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(drugLicense2) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense2);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense2);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  GST Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc3Change(
                                          e,
                                          "documents.gst_certificate",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(gstCertificate) ? (
                                    <Document file={gstCertificate}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={gstCertificate}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(gstCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(gstCertificate);
                                            setFileName("GST Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "GST Certificate"
                                            );
                                            setImageUrlData(gstCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {state.user_type == "doctor" &&
                  state.pharmacy_type == "dispensing" && (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Basic details
                        </label>
                      </div>
                      <div className="row mt-3 text-capitalize">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Doctor Code
                              </label>
                              <p className="fs_14">{customerViewData?.code}</p>
                            </div>
                            {/* <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Doctor Name
                              </label>
                              <p className="fs_14">DR. {customerViewData?.name.toUpperCase()}</p>
                            </div> */}

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.name"}
                                label={"Doctor Name"}
                                type={"text"}
                                placeholder={"Enter Name"}
                                name="doctor_basic_details.name"
                                value={
                                  values?.doctor_basic_details?.name
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.qualification"}
                                label={"Qualification"}
                                type={"text"}
                                placeholder={"Enter Qualification"}
                                name="doctor_basic_details.qualification"
                                value={
                                  values?.doctor_basic_details?.qualification
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.fieldForceUser?.name
                                  ? customerViewData?.onboardingFieldforce
                                    ?.fieldForceUser?.name
                                  : "Not Yet Onboarded"}
                              </p>
                            </div>

                            <div className="my-2">
                              <label className="color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.updatedAt
                                  ? moment(
                                    customerViewData?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.specialization"}
                                label={"Specialization"}
                                type={"text"}
                                placeholder={"Enter Specialization"}
                                name={"doctor_basic_details.specialization"}
                                value={
                                  values?.doctor_basic_details
                                    ?.specialization_name
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.mobile"}
                                label={"Mobile Number"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter mobile number"}
                                name={"doctor_contact_details.mobile"}
                                maxLength={10}
                                value={values?.doctor_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"doctor_contact_details.alternate_mobile"}
                                maxLength={10}
                                value={
                                  values?.doctor_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter email address"}
                                name={"doctor_contact_details.email"}
                                value={values?.doctor_contact_details?.email}
                              />
                            </div>
                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_two"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 3"
                                      id={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors.address_one && (
                                  <ErrorMsg
                                    name={
                                      "doctor_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors.address_two && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors.address_three && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.address.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"doctor_contact_details.address.landmark"}
                                value={
                                  values?.doctor_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>
                                <Field name={"doctor_contact_details.state_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={doctorStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedState(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"doctor_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={doctorDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "doctor_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field name={"doctor_contact_details.city_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={doctorCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedCity(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"doctor_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"doctor_contact_details.pincode"}
                                value={values?.doctor_contact_details?.pincode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  MCI Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleMicChange(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{ position: "relative" }}
                              >
                                {pdfChecker(mciCertificate) ? (
                                  <Document file={mciCertificate}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={mciCertificate}
                                  />
                                )}
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.6 )",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(mciCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(mciCertificate);
                                            setFileName("MCI Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "MCI Certificate"
                                            );
                                            setImageUrlData(mciCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {state.user_type == "doctor" &&
                  state.pharmacy_type == "self" && (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Basic details
                        </label>
                      </div>
                      <div className="row mt-3 text-capitalize">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Doctor Code
                              </label>
                              <p className="fs_14">{customerViewData?.code}</p>
                            </div>
                            {/* <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Doctor Name
                              </label>
                              <p className="fs_14">DR. {customerViewData?.name.toUpperCase()}</p>
                            </div> */}
                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.name"}
                                label={"Doctor Name"}
                                type={"text"}
                                placeholder={"Enter Name"}
                                name="doctor_basic_details.name"
                                value={
                                  values?.doctor_basic_details?.name
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.qualification"}
                                label={"Qualification"}
                                type={"text"}
                                placeholder={"Enter Qualification"}
                                name="doctor_basic_details.qualification"
                                value={
                                  values?.doctor_basic_details?.qualification
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.fieldForceUser?.name
                                  ? customerViewData?.onboardingFieldforce
                                    ?.fieldForceUser?.name
                                  : "Not Yet Onboarded"}
                              </p>
                            </div>

                            <div className="my-2">
                              <label className="color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.updatedAt
                                  ? moment(
                                    customerViewData?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_basic_details.specialization"}
                                label={"Specialization"}
                                type={"text"}
                                placeholder={"Enter Specialization"}
                                name={"doctor_basic_details.specialization"}
                                value={
                                  values?.doctor_basic_details
                                    ?.specialization_name
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Doctor’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.mobile"}
                                label={"Mobile Number"}
                                labelColor="color_747F9E"
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter mobile number"}
                                name={"doctor_contact_details.mobile"}
                                maxLength={10}
                                value={values?.doctor_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"doctor_contact_details.alternate_mobile"}
                                maxLength={10}
                                value={
                                  values?.doctor_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter email address"}
                                name={"doctor_contact_details.email"}
                                value={values?.doctor_contact_details?.email}
                              />
                            </div>
                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_two"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "doctor_contact_details.address.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 3"
                                      id={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "doctor_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.doctor_contact_details?.address
                                          ?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors.address_one && (
                                  <ErrorMsg
                                    name={
                                      "doctor_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors.address_two && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors.address_three && (
                                    <ErrorMsg
                                      name={
                                        "doctor_contact_details.address.address_two"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"doctor_contact_details.address.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"doctor_contact_details.address.landmark"}
                                value={
                                  values?.doctor_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>
                                <Field name={"doctor_contact_details.state_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={doctorStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedState(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"doctor_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={doctorDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "doctor_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field name={"doctor_contact_details.city_id"}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={doctorCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={doctorCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.doctor_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setDoctorSelectedCity(selected?.value);
                                        setFieldValue(
                                          "doctor_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="doctor_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"doctor_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"doctor_contact_details.pincode"}
                                value={values?.doctor_contact_details?.pincode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Basic Details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputCapitalField
                                setFieldValue={setFieldValue}
                                id={"pharmacy_basic_details.pharmacy_name"}
                                label={"Pharmacy Name"}
                                type={"text"}
                                placeholder={"Enter Pharmacy Name"}
                                name={"pharmacy_basic_details.pharmacy_name"}
                                value={
                                  values?.pharmacy_basic_details?.pharmacy_name
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_basic_details.gst_number"}
                                label={"GST Number"}
                                type={"text"}
                                placeholder={"Enter GST Number"}
                                name={"pharmacy_basic_details.gst_number"}
                                value={
                                  values?.pharmacy_basic_details?.gst_number
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                label={"Drug License Number - 20A"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20A"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_a
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                label={"Drug License Number - 20B"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20B"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_b
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-xxl-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  MCI Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleMicChange(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{ position: "relative" }}
                              >
                                {pdfChecker(mciCertificate) ? (
                                  <Document file={mciCertificate}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={mciCertificate}
                                  />
                                )}
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.6 )",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(mciCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(mciCertificate);
                                            setFileName("MCI Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "MCI Certificate"
                                            );
                                            setImageUrlData(mciCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-xxl-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate 1
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      // accept="pdf/*"
                                      onChange={(e) =>
                                        handleDoc1Change(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense1) ? (
                                    <Document file={drugLicense1}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense1}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(drugLicense1) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense1);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense1);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-xxl-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate - 2
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc2Change(
                                          e,
                                          "documents.drug_licence_certificate_20_b",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  // width: "15rem",
                                  // border: "1px solid black",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense2) ? (
                                    <Document file={drugLicense2}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense2}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(drugLicense2) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense2);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense2);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-xxl-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  GST Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc3Change(
                                          e,
                                          "documents.gst_certificate",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(gstCertificate) ? (
                                    <Document file={gstCertificate}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={gstCertificate}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(gstCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(gstCertificate);
                                            setFileName("GST Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "GST Certificate"
                                            );
                                            setImageUrlData(gstCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {state.user_type == "pharmacy" &&
                  state.pharmacy_type == "attach" && (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Basic Details
                        </label>
                      </div>
                      <div className="row mt-3 text-capitalize">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Pharmacy Code
                              </label>
                              <p className="fs_14">{customerViewData?.code}</p>
                            </div>

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>

                            <div className="my-2">
                              <InputCapitalField
                                setFieldValue={setFieldValue}
                                id={"pharmacy_basic_details.pharmacy_name"}
                                label={"Pharmacy Name"}
                                type={"text"}
                                placeholder={"Enter Pharmacy Name"}
                                name={"pharmacy_basic_details.pharmacy_name"}
                                value={
                                  values?.pharmacy_basic_details?.pharmacy_name
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_basic_details.gst_number"}
                                label={"GST Number"}
                                type={"text"}
                                placeholder={"Enter GST Number"}
                                name={"pharmacy_basic_details.gst_number"}
                                value={
                                  values?.pharmacy_basic_details?.gst_number
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {
                                  customerViewData?.onboardingFieldforce
                                    ?.fieldForceUser?.name
                                }
                              </p>
                            </div>
                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.updatedAt
                                  ? moment(
                                    customerViewData?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                label={"Drug License Number - 20A"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20A"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_a
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                label={"Drug License Number - 20B"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20B"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_b
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.mobile"}
                                label={"Mobile Number"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter mobile number"}
                                name={"pharmacy_contact_details.mobile"}
                                value={values?.pharmacy_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"alternate_mobile"}
                                value={
                                  values?.pharmacy_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter email address"}
                                name={"pharmacy_contact_details.email"}
                                value={values?.pharmacy_contact_details?.email}
                              />
                            </div>

                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={"pharmacy_contact_details.address_two"}
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors?.address?.address_one && (
                                  <ErrorMsg
                                    name={
                                      "pharmacy_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors?.address?.address_two && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors?.address?.address_three && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"pharmacy_contact_details.landmark"}
                                value={
                                  values?.pharmacy_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>

                                <Field
                                  name={"pharmacy_contact_details.state_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={pharmacyStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedState(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={pharmacyDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.city_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={pharmacyCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedCity(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"pharmacy_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"pharmacy_contact_details.pincode"}
                                value={
                                  values?.pharmacy_contact_details?.pincode
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                      <div className="row mt-3">
                        {/* <div className="col-6 col-xl-5 col-xxl-4 ">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  MCI Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleMicChange(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className="manufacturer-doc-upload-container"
                                style={{ position: "relative" }}
                              >
                                {pdfChecker(mciCertificate) ? (
                                  <Document file={mciCertificate}>
                                    <Page pageNumber={1} width={410} />
                                  </Document>
                                ) : (
                                  <img
                                    className="image-upload"
                                    src={mciCertificate}
                                  />
                                )}
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.6 )",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(mciCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(mciCertificate);
                                            setFileName("MCI Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "MCI Certificate"
                                            );
                                            setImageUrlData(mciCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate 1
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      // accept="pdf/*"
                                      onChange={(e) =>
                                        handleDoc1Change(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense1) ? (
                                    <Document file={drugLicense1}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense1}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(drugLicense1) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense1);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense1);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate - 2
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc2Change(
                                          e,
                                          "documents.drug_licence_certificate_20_b",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  // width: "15rem",
                                  // border: "1px solid black",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense2) ? (
                                    <Document file={drugLicense2}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense2}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(drugLicense2) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense2);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense2);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  GST Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc3Change(
                                          e,
                                          "documents.gst_certificate",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(gstCertificate) ? (
                                    <Document file={gstCertificate}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={gstCertificate}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(gstCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(gstCertificate);
                                            setFileName("GST Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "GST Certificate"
                                            );
                                            setImageUrlData(gstCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {state.user_type == "pharmacy" &&
                  state.pharmacy_type == "independent" && (
                    <>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Basic Details
                        </label>
                      </div>
                      <div className="row mt-3 text-capitalize">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Jivini’s Pharmacy Code
                              </label>
                              <p className="fs_14">{customerViewData?.code}</p>
                            </div>

                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Pharmacy Type
                              </label>
                              <p className="fs_14">
                                {customerViewData?.pharmacy_type}
                              </p>
                            </div>

                            <div className="my-2">
                              <InputCapitalField
                                setFieldValue={setFieldValue}
                                id={"pharmacy_basic_details.pharmacy_name"}
                                label={"Pharmacy Name"}
                                type={"text"}
                                placeholder={"Enter Pharmacy Name"}
                                name={"pharmacy_basic_details.pharmacy_name"}
                                value={
                                  values?.pharmacy_basic_details?.pharmacy_name
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_basic_details.gst_number"}
                                label={"GST Number"}
                                type={"text"}
                                placeholder={"Enter GST Number"}
                                name={"pharmacy_basic_details.gst_number"}
                                value={
                                  values?.pharmacy_basic_details?.gst_number
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="mb-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded By
                              </label>
                              <p className="fs_14">
                                {
                                  customerViewData?.onboardingFieldforce
                                    ?.fieldForceUser?.name
                                }
                              </p>
                            </div>
                            <div className="my-2">
                              <label className=" color_747F9E fs_14">
                                Onboarded Date & Time
                              </label>
                              <p className="fs_14">
                                {customerViewData?.onboardingFieldforce
                                  ?.updatedAt
                                  ? moment(
                                    customerViewData?.onboardingFieldforce
                                      ?.updatedAt
                                  ).format("DD MMM YYYY HH:mm A")
                                  : "--"}
                              </p>
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                label={"Drug License Number - 20A"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20A"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_a"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_a
                                }
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                label={"Drug License Number - 20B"}
                                type={"text"}
                                placeholder={"Enter Drug License Number - 20B"}
                                name={
                                  "pharmacy_basic_details.drug_licence_number_20_b"
                                }
                                value={
                                  values?.pharmacy_basic_details
                                    ?.drug_licence_number_20_b
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                        <label className="basic-title mx-2 mb-1">
                          Pharmacy’s Contact details
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.mobile"}
                                label={"Mobile Number"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter mobile number"}
                                name={"pharmacy_contact_details.mobile"}
                                value={values?.pharmacy_contact_details?.mobile}
                              />
                            </div>
                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.alternate_mobile"}
                                label={"Alternative Mobile Number"}
                                type={"text"}
                                placeholder={"Enter alternative mobile number"}
                                name={"alternate_mobile"}
                                value={
                                  values?.pharmacy_contact_details
                                    ?.alternate_mobile
                                }
                              />
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.email"}
                                label={"Email Address"}
                                type={"text"}
                                disabled={true}
                                placeholder={"Enter email address"}
                                name={"pharmacy_contact_details.email"}
                                value={values?.pharmacy_contact_details?.email}
                              />
                            </div>

                            <div className="my-2">
                              <label className=" mx-1 fn_Nunito fs_14 color_747F9E">
                                Address
                              </label>
                              <div>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address.address_one"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 1"
                                      id={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_one"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_one
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={"pharmacy_contact_details.address_two"}
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_two"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_two
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={
                                    "pharmacy_contact_details.address_three"
                                  }
                                >
                                  {({ field, meta }) => (
                                    <input
                                      {...field}
                                      placeholder="Address line 2"
                                      id={
                                        "pharmacy_contact_details.address_three"
                                      }
                                      className={
                                        "w-100 input input-field py-2 my-1"
                                      }
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                      value={
                                        values?.pharmacy_contact_details
                                          ?.address?.address_three
                                      }
                                    />
                                  )}
                                </Field>
                                {(errors?.address?.address_one && (
                                  <ErrorMsg
                                    name={
                                      "pharmacy_contact_details.address.address_one"
                                    }
                                  />
                                )) ||
                                  (errors?.address?.address_two && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_two"
                                      }
                                    />
                                  )) ||
                                  (errors?.address?.address_three && (
                                    <ErrorMsg
                                      name={
                                        "pharmacy_contact_details.address.address_three"
                                      }
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="my-2">
                              <InputField
                                id={"pharmacy_contact_details.landmark"}
                                label={"Landmark"}
                                type={"text"}
                                placeholder={"Enter landmark"}
                                name={"pharmacy_contact_details.landmark"}
                                value={
                                  values?.pharmacy_contact_details?.address
                                    ?.landmark
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="my-2">
                            <div className="mx-4 px-3">
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  State
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.state_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyStateOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your state"}
                                      value={pharmacyStateOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.state_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedState(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.state_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.state_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fn_Nunito fs_14 color_747F9E">
                                  District
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.district_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyDistrictOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your district"}
                                      value={pharmacyDistrictOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.district_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedDistrict(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.district_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.district_id" />
                              </div>
                              <div className="select-container">
                                <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 color_747F9E">
                                  City
                                </label>
                                <Field
                                  name={"pharmacy_contact_details.city_id"}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      options={pharmacyCityOptions}
                                      styles={customStyles}
                                      placeholder={"Enter your city"}
                                      value={pharmacyCityOptions.filter(
                                        (s) =>
                                          s.value ==
                                          values?.pharmacy_contact_details
                                            ?.city_id
                                      )}
                                      onChange={(selected) => {
                                        setPharmacySelectedCity(
                                          selected?.value
                                        );
                                        setFieldValue(
                                          "pharmacy_contact_details.city_id",
                                          selected?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMsg name="pharmacy_contact_details.city_id" />
                              </div>
                              <InputField
                                id={"pharmacy_contact_details.pincode"}
                                label={"Pincode"}
                                type={"text"}
                                placeholder={"Enter pincode"}
                                name={"pharmacy_contact_details.pincode"}
                                value={
                                  values?.pharmacy_contact_details?.pincode
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                        <label className="basic-title mx-2 mb-1">
                          Documents
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate 1
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      // accept="pdf/*"
                                      onChange={(e) =>
                                        handleDoc1Change(
                                          e,
                                          "documents.drug_licence_certificate_20_a",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense1) ? (
                                    <Document file={drugLicense1}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense1}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />

                                      {pdfChecker(drugLicense1) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense1);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense1);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  Drug License Certificate - 2
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc2Change(
                                          e,
                                          "documents.drug_licence_certificate_20_b",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  // width: "15rem",
                                  // border: "1px solid black",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(drugLicense2) ? (
                                    <Document file={drugLicense2}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={drugLicense2}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(drugLicense2) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(drugLicense2);
                                            setFileName(
                                              "Drug Manufacturing License"
                                            );
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "Drug Manufacturing License"
                                            );
                                            setImageUrlData(drugLicense2);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-8 col-xl-5 col-xxl-4 mx-auto mx-xl-0"
                          style={{ minWidth: "480px" }}
                        >
                          <div className="mx-4 px-3">
                            <div className="my-2">
                              <div className="upload-image-label-container d-flex justify-content-between">
                                <label className="my-1 color_747F9E">
                                  GST Certificate
                                </label>
                                <span className="d-flex align-items-center">
                                  <img src={reupload} />
                                  <label className="my-1 Medium_Purple fs_12 ms-1 fw_700">
                                    RE-UPLOAD
                                    <input
                                      className="upload-document-input"
                                      type="file"
                                      name="file"
                                      accept="image/*, application/pdf"
                                      onChange={(e) =>
                                        handleDoc3Change(
                                          e,
                                          "documents.gst_certificate",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </label>
                                </span>
                              </div>
                              <div
                                className={` h-100`}
                                style={{
                                  height: "25rem",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  scrollBehavior: "smooth",
                                  position: "relative",
                                }}
                              >
                                <div className="manufacturer-doc-upload-container">
                                  {pdfChecker(gstCertificate) ? (
                                    <Document file={gstCertificate}>
                                      <Page pageNumber={1} width={410} />
                                    </Document>
                                  ) : (
                                    <img
                                      className="image-upload"
                                      src={gstCertificate}
                                    />
                                  )}
                                </div>
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    zIndex: "55",
                                    top: "0",
                                    maxWidth: "450px",
                                  }}
                                >
                                  <div
                                    className="manufacturer-doc-upload-container"
                                    style={{
                                      backgroundColor: "rgb(38, 44, 61, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="align-items-center"
                                      style={{
                                        position: "absolute",
                                        zIndex: "55",
                                        top: "43%",
                                        left: "40%",
                                      }}
                                    >
                                      <img src={eye} className="mb-2" />
                                      {pdfChecker(gstCertificate) ? (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setOpenPdf(true);
                                            setPdfFileUrl(gstCertificate);
                                            setFileName("GST Certificate");
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="fs_16 fw_600"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#E7E5FF",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setImageModal(true);
                                            setImageTitleData(
                                              "GST Certificate"
                                            );
                                            setImageUrlData(gstCertificate);
                                          }}
                                        >
                                          VIEW
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}

            <PdfView
              show={openPdf}
              handleClose={() => setOpenPdf(false)}
              pdfUrl={pdfFileUrl}
              filename={filename}
            />
            <ImageViewModal
              show={imageModal}
              ShowClose={() => setImageModal(false)}
              imageTitleData={imageTitleData}
              imageUrlData={imageUrlData}
            />
            <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
            <ErrorPopup
              show={showErrorPopup}
              title={showErrorMsg}
              handleClose={closeErrorPopup}
            />
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default CustomerDoctorEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
