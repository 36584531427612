import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { superAdminList } from "../../redux/actions/superAdminActions";
import SuperUserListTableData from "./super-user-list-table-data";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const SuperUserListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [superListData, setSuperListData] = useState([]);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      dispatch(superAdminList({ search, page }, onSuccess, onError));
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    dispatch(superAdminList({ search, page }, onSuccess, onError));
  }, [page, search]);

  const fetchSuperAdminList = () => {
    dispatch(superAdminList({ search, page }, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    setSuperListData(data.data.data);
    console.log(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Employee ID
            </th>
            <th className="table-heads " width={"10%"}>
              Name
            </th>
            <th className="table-heads " width={"10%"}>
              Email Address
            </th>
            <th className="table-heads " width={"10%"}>
              Phone Number
            </th>
            <th className="table-heads " width={"22%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : superListData?.superAdmins?.length > 0 ? (
            <>
              {superListData?.superAdmins?.map((eachRow, index) => (
                <SuperUserListTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  fetchSuperAdminList={fetchSuperAdminList}
                  currentPage={superListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      {superListData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={superListData?.pagination}
          nextFunc={() => {
            navigate(
              `/super-user/user/${superListData?.pagination?.currentPage + 1}`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/super-user/user/${superListData?.pagination?.currentPage - 1}`
            );
            setPage(page - 1);
          }}
        />
      )}
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default SuperUserListTable;
