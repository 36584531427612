import React, { useEffect } from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    maxWidth: "45rem",
    height: "25.5rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

export const TableFooter = styled("div")(
  {
    background: "#2AD3E7",
    border: "1px solid #e7e5ff",
    borderBottomLeftRadius: " 15px",
    borderBottomRightRadius: "15px",
    maxWidth: "45rem",
    height: "3rem",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

const OrdersViewTable = ({ maxWidth = "60rem", orderProducts, total , data , orderdata  }) => {
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table className="main-table">
          <tr className="table-rows">
            <th className="table-heads color_262c3d" width={"8%"}>
              Product ID
            </th>
            <th className="table-heads color_262c3d" width={"9%"}>
              Product Name
            </th>
            <th className="table-heads" width={"8%"}>
              Division
            </th>
            <th className="table-heads" width={"8%"}>
              Qty
            </th>

            <th className="table-heads" width={"8%"}>
              Price
            </th>
          </tr>
          {data?.map((item) => (
            <tr className="table-rows1 color_b0b3c7">
              <td className="table-each-row-data">{item?.product?.product_id}</td>
              <td className="table-each-row-data">{item.product.product_name}</td>
              <td className="table-each-row-data">{item.vendorDivision.name}</td>
              <td className="table-each-row-data">{item.offer_qty}</td>
              <td className="table-each-row-data">₹{item.price}</td>
            </tr>
          ))}
        </table>
      </TableContainer>
      <TableFooter
        className="d-flex justify-content-between align-items-center px-4 table-footer"
        maxWidth={maxWidth}
      >
        <div>
          <label className="text-white ">Total</label>
        </div>
        <div>
          <label className="text-white fw_600 mx-5">₹ {orderdata?.total} </label>
        </div>
      </TableFooter>
    </div>
  );
};

export default OrdersViewTable;

const order = [
  {
    productId: "PRD1234567",
    productName: "Product Name goes here",
    division: "Distributors Name",
    qty: "150",
    price: "150",
  },
];
