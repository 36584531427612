import React from "react";
import { Line } from "react-chartjs-2";
import "./analytics.css";
import Select from "react-select";
import {
  Chart as ChartJS,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { getYears } from "../../components/utilites/helper";

ChartJS.register(
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const AnalyticsGraph = ({ year, setYear, graphData }) => {
  const theData = {
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          data: graphData?.dataArr,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, "rgba(42, 211, 231, 0.5)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.5)");
            return gradient;
          },
          tension: 0.4,
          fill: true,
          showLine: true,
          borderColor: "#2AD3E7", // Color of the line border
          borderWidth: 2,
          pointStyle: "circle",
          pointRadius: 3,
          pointBackgroundColor: "#2AD3E7",
          pointBorderColor: "#2AD3E7", // Point border color
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#668185",
          titleColor: "white",
          bodyColor: "white",
          bodyFont: {
            weight: "bold",
            size: 14,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            display: true,
          },
          grid: {
            display: true,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const yearOptions = getYears()

  return (
    <div className="graph-container">
      <div className="d-flex justify-content-end">
        <div className="mb-2">
          <Select
            options={yearOptions}
            styles={customStyles}
            value={yearOptions.filter((s) => s.value == year)}
            className="select-element"
            onChange={(e) => {
              console.log(e.value);
              if (year != null) {
                setYear(e.value);
              }
            }}
          />
        </div>
      </div>
      <Line data={theData.data} options={theData.options}>
        Order
      </Line>
      <div></div>
    </div>
  );
};

export default AnalyticsGraph;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    backgroundColor: "#E7E5FF",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
