import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import back from "../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { distributorVersionDetail } from "../../redux/actions/distributorActions";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const DistributorVersionView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [distributorData, setDistributorData] = useState(null);
  const [distibutorOldData, setDistibutorOldData] = useState("");
  const [distibutorNewData, setDistibutorNewData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(params?.id);
    dispatch(distributorVersionDetail(params?.id, onSuccess, onError));
    setLoading(true)
  }, []);

  const onSuccess = (data) => {
    console.log("adsada", data.data.data);
    setDistributorData(data.data.data);
    setDistibutorOldData(data?.data?.data?.old_data);
    setDistibutorNewData(data?.data?.data?.new_data);
    setLoading(false);
  };
  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
    nestedKeyLabel: {
      fontSize: "14px",
    },
    value: {
      fontSize: "14px",
    },
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (distributorData?.distributor?.distributor_id
          ? ` / ${distributorData?.distributor?.distributor_id} - Version History`
          : " / Version History")}
    </span>
  );

  const data = [
    {
      heading: "Distributor ID",
      title: distributorData?.distributor?.distributor_id
        ? distributorData?.distributor?.distributor_id
        : '--',
    },
    {
      heading: "Distributor Name",
      title: distributorData?.distributor?.firm_name
        ? distributorData?.distributor?.firm_name
        : '--',
    },
    {
      heading: "No. of Orders",
      title: distributorData?.distributor?.orders?.length
        ? distributorData?.distributor?.orders?.length
        : '--',
    },
    {
      heading: "No. of Manufacturer",
      title: distributorData?.distributor?.vendorDistributors?.length
        ? distributorData?.distributor?.vendorDistributors?.length
        : '--',
    },
    {
      heading: "Status",
      title: distributorData?.distributor?.status ? "Active" : "Inactive",
    },
    {
      heading: "Updated By",
      title: distributorData?.updatedByAdmin?.name ? distributorData?.updatedByAdmin?.name : '--',
    },
    {
      heading: "Updated At",
      title:
        distributorData?.updatedAt &&
        moment(distributorData?.updatedAt).format("DD MMM YYYY"),
    },
  ];
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
      </div>
      {
        loading == true ?
          <div className="view-spinner-container">
            <LoadingSpinnerTable
              show={loading}
            />
          </div> :
          <>
            <div className="pt-3 px-4 row">
              {data.map((item, index) => (
                <div className="mb-2 col-4 col-md-3 col-xl-2 col-xxl label-conatiner mx-3 ">
                  <h5 className="color_b0b3c7 text-nowrap fs_15 fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p className={` fs_16 fn_Nunito text-nowrap fw_600 `}>
                    {item.title === "Active" ? (
                      <p className={` fs_16 fn_Nunito text-nowrap fw_600 text_green`}>
                        {item.title}
                      </p>
                    ) : item.title === "Inactive" ? (
                      <p className={` fs_16 fn_Nunito text-nowrap fw_600 text_red`}>
                        {item.title}
                      </p>
                    ) : (
                      <p className={` fs_16 fn_Nunito text-nowrap fw_600`}>
                        {item.title}
                      </p>
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className=" mx-3 d-none d-xl-block form-sub-details my-3 mb-4">
              <div className="d-flex justify-content-between">
                <div className="col-5 mx-4 ">JSON Old Data</div>
                <div className="col-6">
                  <span className="ms-4 ps-2">JSON New Data</span>
                </div>
              </div>
            </div>
            <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-5  mt-xl-0">
              <div
                className="col-10 col-xl-6 my-2 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                style={{ minHeight: "30rem" }}
              >
                <div>
                  <h6
                    className="px-3 py-3 my-auto"
                    style={{
                      backgroundColor: "#E7E5FF",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Old Data
                  </h6>
                  <div className="">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <>
                        {distibutorOldData === null ? (
                          <>Empty data</>
                        ) : (
                          <>
                            <JSONTree theme={theme} data={distibutorOldData} />;
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-10 col-xl-6 my-2 mx-xl-3 mx-auto"
                style={{ minHeight: "30rem" }}
              >
                <div>
                  <h6
                    className="px-3 py-3 my-auto"
                    style={{
                      backgroundColor: "#E7E5FF",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    New Data
                  </h6>
                  <div className="">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <>
                        {distibutorNewData === null ? (
                          <>Empty data</>
                        ) : (
                          <>
                            <JSONTree theme={theme} data={distibutorNewData} />;
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

    </MainLayout>
  );
};

export default DistributorVersionView;
const newData = [
  {
    heading: "Checker Id",
    title: "adminVersionData?.id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.new_data?.[0]?.name",
  },
  {
    heading: "Admin Id",
    title: "adminVersionData?.new_data?.[0]?.admin_id",
  },
  {
    heading: "Approved By",
    title:
      "adminVersionData?.new_data?.[0]?.approved_by === null ? " -
      " : adminVersionData?.new_data?.[0]?.approved_by,",
  },
  {
    heading: "Approved Date",
    title: "sfsdfdf",
  },
  {
    heading: "Updated Date",
    title: "sfcsdvdsvc",
  },
  {
    heading: "Updated By",
    title: "cvcxvcxvcx",
  },
];
const oldData = [
  {
    heading: "Checker Id",
    title: "adminVersionData?.id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.old_data?.name",
  },
  {
    heading: "Role Id",
    title: "adminVersionData?.old_data?.role_id",
  },
  {
    heading: "Name",
    title: "adminVersionData?.old_data?.name",
  },
  {
    heading: "Email",
    title: "adminVersionData?.old_data?.email",
  },
  {
    heading: "Admin Id",
    title: "adminVersionData?.old_data?.id",
  },

  {
    heading: "Approved Date",
    title: "dgfvgfdcbcv",
  },
];
