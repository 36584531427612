import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import back from "../../assets/icons/back.svg";
import "./analytics.css";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import Select from "react-select";
import ClearBtn, { AdminButton } from "../../components/buttons/clearBtn";
import FilterBtn from "../../components/buttons/filterBtn";
import AnalyticsGraph from "./AnalyticsGraph";
import { useDispatch } from "react-redux";
import {
  analyticsList,
  distributorList,
  divisionList,
  doctorList,
  fieldForceUserList,
  headQuaterList,
  manufacturerList,
  pharmacyList,
  productList,
} from "../../redux/actions/analyticsActions";
import {
  fetchDistrictList,
  fetchStateList,
} from "../../redux/actions/commonActions";
import AnalyticsTable from "./AnalyticsTable";
const Analytics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [year, setYear] = useState(new Date().getFullYear());
  const [graphData, setGraphData] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [headquaterOptions, setHeadquaterOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [fieldForceOptions, setFieldForceOptions] = useState([]);
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedHeadquater, setSelectedHeadquater] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedFieldForceUser, setSelectedFieldForceUser] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
    dispatch(
      manufacturerList(onFetchManufacturerSuccess, onFetchManufacturerError)
    );
    dispatch(
      divisionList(
        { vendor_id: selectedManufacturer },
        onFetchDivisionSuccess,
        onFetchDivisionError
      )
    );
    dispatch(
      productList(
        { division_id: selectedDivision, vendor_id: selectedManufacturer },
        onFetchProductSuccess,
        onFetchProductError
      )
    );
    dispatch(
      fieldForceUserList(
        { division_id: selectedDivision, vendor_id: selectedManufacturer },
        onFetchFieldForceSuccess,
        onFetchFieldForceError
      )
    );
    dispatch(pharmacyList({}, onFetchPharmacySuccess, onFetchPharmacyError));
    dispatch(doctorList({}, onFetchDoctorSuccess, onFetchDoctorError));
    dispatch(
      distributorList(
        { state_id: selectedState, district_id: selectedDistrict },
        onFetchDistributorSuccess,
        onFetchDistributorError
      )
    );
    dispatch(
      headQuaterList(
        { vendor_id: selectedManufacturer },
        onFetchHeadquaterSuccess,
        onFetchHeadquaterError
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      divisionList(
        { vendor_id: selectedManufacturer },
        onFetchDivisionSuccess,
        onFetchDivisionError
      )
    );
    dispatch(
      productList(
        { division_id: selectedDivision, vendor_id: selectedManufacturer },
        onFetchProductSuccess,
        onFetchProductError
      )
    );

    dispatch(
      fieldForceUserList(
        { division_id: selectedDivision, vendor_id: selectedManufacturer },
        onFetchFieldForceSuccess,
        onFetchFieldForceError
      )
    );
    dispatch(
      headQuaterList(
        { vendor_id: selectedManufacturer },
        onFetchHeadquaterSuccess,
        onFetchHeadquaterError
      )
    );
  }, [selectedManufacturer, selectedDivision]);

  useEffect(() => {
    dispatch(
      pharmacyList(
        {
          vendor_id: selectedManufacturer,
          state_id: selectedState,
          district_id: selectedDistrict,
        },
        onFetchPharmacySuccess,
        onFetchPharmacyError
      )
    );
    dispatch(
      doctorList(
        {
          vendor_id: selectedManufacturer,
          state_id: selectedState,
          district_id: selectedDistrict,
        },
        onFetchDoctorSuccess,
        onFetchDoctorError
      )
    );
    dispatch(
      distributorList(
        {
          vendor_id: selectedManufacturer,
          state_id: selectedState,
          district_id: selectedDistrict,
        },
        onFetchDistributorSuccess,
        onFetchDistributorError
      )
    );
  }, [selectedManufacturer, selectedState, selectedDistrict]);

  const onFetchManufacturerSuccess = (data) => {
    setManufacturerOptions(data);
  };

  const onFetchManufacturerError = (err) => {
    console.log(err);
  };
  const onFetchDivisionSuccess = (data) => {
    setDivisionOptions(data);
  };

  const onFetchDivisionError = (err) => {
    console.log(err);
  };

  const onFetchProductSuccess = (data) => {
    console.log(data);
    setProductOptions(data);
  };

  const onFetchProductError = (err) => {
    console.log(err);
  };

  const onFetchFieldForceSuccess = (data) => {
    console.log(data);
    setFieldForceOptions(data);
  };

  const onFetchFieldForceError = (err) => {
    console.log(err);
  };

  const onFetchPharmacySuccess = (data) => {
    console.log(data);
    setPharmacyOptions(data);
  };

  const onFetchPharmacyError = (err) => {
    console.log(err);
  };

  const onFetchDoctorSuccess = (data) => {
    console.log(data);
    setDoctorOptions(data);
  };

  const onFetchDoctorError = (err) => {
    console.log(err);
  };

  const onFetchDistributorSuccess = (data) => {
    setDistributorOptions(data);
    console.log(data);
  };

  const onFetchDistributorError = (err) => {
    console.log(err);
  };

  const onFetchHeadquaterSuccess = (data) => {
    console.log(data, "hqqqqq");
    setHeadquaterOptions(data);
  };

  const onFetchHeadquaterError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch(
      analyticsList(
        {
          year: year ? year : new Date().getFullYear(),
          vendor_id: selectedManufacturer,
          doctor_id: selectedDoctor,
          pharmacy_id: selectedPharmacy,
          distributor_id: selectedDistributor,
          state_id: selectedState,
          district_id: selectedDistrict,
          division_id: selectedDivision,
          product_id: selectedProduct,
          field_force_user_id: selectedFieldForceUser,
          head_quater_id: selectedHeadquater,
        },
        onAnalyticsSuccess,
        onAnalyticsError
      )
    );
  }, [year]);

  useEffect(() => {
    if (selectedState !== null) {
      console.log(selectedState, "hqqqqq");
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log(data);
  };

  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const onGetStateSuccess = (data) => {
    console.log(data, "hqqqqq");
    setStateOptions(data);
    console.log();
  };

  const onGetStateError = (err) => {
    console.log(err);
  };

  const filterSubmit = () => {
    dispatch(
      analyticsList(
        {
          year: new Date().getFullYear(),
          vendor_id: selectedManufacturer,
          doctor_id: selectedDoctor,
          pharmacy_id: selectedPharmacy,
          distributor_id: selectedDistributor,
          state_id: selectedState,
          district_id: selectedDistrict,
          division_id: selectedDivision,
          product_id: selectedProduct,
          field_force_user_id: selectedFieldForceUser,
          head_quater_id: selectedHeadquater,
        },
        onAnalyticsSuccess,
        onAnalyticsError
      )
    );
  };

  const clearFunction = () => {
    console.log("clear");
    setSelectedManufacturer(null);
    setSelectedDivision(null);
    setSelectedProduct(null);
    setSelectedDistributor(null);
    setSelectedState(null);
    setSelectedDistrict(null);
    setSelectedHeadquater(null);
    setSelectedPharmacy(null);
    setSelectedDoctor(null);
    setSelectedDistributor(null);
    setSelectedFieldForceUser(null);
    dispatch(
      analyticsList(
        {
          year: new Date().getFullYear(),
        },
        onAnalyticsSuccess,
        onAnalyticsError
      )
    );
  };

  const onAnalyticsSuccess = (data) => {
    setGraphData(data?.data?.data);
    console.log(data?.data?.data);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
    navigate("/dashboard");
  };

  const onAnalyticsError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const errStr = err.data.message;
    const modifiedErrStr = errStr.charAt(0).toUpperCase() + errStr.slice(1);
    setShowErrorMsg(modifiedErrStr);
  };

  return (
    <MainLayout breadCrumb="ADMIN / Analytics">
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
      </div>
      <div className="scrolling">
        <div className="position-relative pb-5">
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center justify-content-xl-start">
            <label className="basic-title mx-2 mb-1">Filter</label>
          </div>
          <div className="dropdown-container p-3 gap-lg-4 mx-2 mx-lg-4 my-2 d-flex ">
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Manufacturer</label>
              <div>
                <Select
                  options={manufacturerOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={manufacturerOptions.filter(
                    (s) => s.value == selectedManufacturer
                  )}
                  onChange={(selected) => {
                    setSelectedManufacturer(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Division</label>
              <div>
                <Select
                  options={divisionOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={divisionOptions.filter(
                    (s) => s.value == selectedDivision
                  )}
                  onChange={(selected) => {
                    setSelectedDivision(selected?.value);
                    //   setSelectedProduct(null);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Headquater</label>
              <div>
                <Select
                  options={headquaterOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={headquaterOptions.filter(
                    (s) => s.value == selectedHeadquater
                  )}
                  onChange={(selected) => {
                    setSelectedHeadquater(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Product</label>
              <div>
                <Select
                  options={productOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={productOptions.filter(
                    (s) => s.value == selectedProduct
                  )}
                  onChange={(selected) => {
                    setSelectedProduct(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Field Force</label>
              <div>
                <Select
                  options={fieldForceOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={fieldForceOptions.filter(
                    (s) => s.value == selectedFieldForceUser
                  )}
                  onChange={(selected) => {
                    setSelectedFieldForceUser(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">State</label>
              <div>
                <Select
                  options={stateOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={stateOptions.filter((s) => s.value == selectedState)}
                  onChange={(selected) => {
                    console.log(selected.value, "hqqqqqqqq");
                    setSelectedState(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">District</label>
              <div>
                <Select
                  options={districtOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={districtOptions.filter(
                    (s) => s.value == selectedDistrict
                  )}
                  onChange={(selected) => {
                    setSelectedDistrict(selected?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Pharmacy</label>
              <div>
                <Select
                  options={pharmacyOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={pharmacyOptions.filter(
                    (s) => s.value == selectedPharmacy
                  )}
                  onChange={(seleted) => {
                    setSelectedPharmacy(seleted?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Doctor</label>
              <div>
                <Select
                  options={doctorOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={doctorOptions.filter((s) => s.value == selectedDoctor)}
                  onChange={(seleted) => {
                    setSelectedDoctor(seleted?.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mx-2">
              <label className="color_#262C3D fs_14">Distributor</label>
              <div>
                <Select
                  options={distributorOptions}
                  styles={customStyles}
                  className="dropdown-element"
                  value={distributorOptions.filter(
                    (s) => s.value == selectedDistributor
                  )}
                  onChange={(seleted) => {
                    setSelectedDistributor(seleted?.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mx-5 mb-4">
            <AdminButton onClick={clearFunction}> CLEAR</AdminButton>
            <button
              type="button"
              onClick={() => filterSubmit()}
              className="filter-buttonn"
            >
              FILTER
            </button>
          </div>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center justify-content-xl-start">
            <label className="basic-title mx-2 mb-1">Order Analytics</label>
          </div>
          <div className="mx-4">
            <AnalyticsGraph
              year={year}
              setYear={setYear}
              graphData={graphData}
            />
          </div>
        </div>
        <div className="py-5">
          <div className="basic-details-container my-5 mb-4 mx-4 my-auto d-flex align-items-center justify-content-xl-start">
            <label className="basic-title mx-2 mb-1">Analytics Table</label>
          </div>
          <div>
            <AnalyticsTable graphData={graphData} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Analytics;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
