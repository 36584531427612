import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { useDispatch } from "react-redux";
import { settingsRejectReasonDetail } from "../../../redux/actions/settingsActions";

const ReasonRejectionView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [rejectReasonData, setRejectReasonData] = useState("");

  useEffect(() => {
    dispatch(settingsRejectReasonDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setRejectReasonData(data?.data?.data);
  };
  const onError = (err) => {
    console.log(err);
  };

  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  Reasons for rejection  / View">
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          {/* <AdminButton
            background="#7065E4"
            color="white"
            width="90px"
            onClick={() => navigate(`/reason-for-rejection-edit`)}
          >
            <span className="m-2">EDIT</span>
          </AdminButton> */}
        </div>
      </div>

      <div className="mx-4 px-3 mt-3">
        <div className="my-2">
          <label className=" Neutral_Color fs_14">Reasons for Rejection</label>
          <p className="fs_14">{rejectReasonData?.title}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default ReasonRejectionView;
