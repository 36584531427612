import React, { useEffect, useState } from "react";
import CreateBtn from "../../components/buttons/createBtn";
import VersionHistoryBtn from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import searcher from "../../assets/icons/searchInput.svg";
import "./manufacturer.css";
import ManufacturerListTable from "./manufacturer-list-table";
import { useLocation } from "react-router-dom";
import ManufacturerPremiumTable from "./premium-user/manufacturer-premium-table";

const ManufacturerList = () => {
  const [search, setSearch] = useState(null);
  const [userData, setUserData] = useState(null);
  const user_type = localStorage.getItem("userType");

  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  const location = useLocation();
  const url = location.pathname;
  const pathArray = url.split("/");
  const activePath = "/" + pathArray[1];
  const activeSubPath = "/" + pathArray[2];

  console.log(location.pathname, "path");
  console.log(activePath, "acpath");
  console.log(activeSubPath, "subpath");

  return (
    <>
      <MainLayout breadCrumb="ADMIN  /  Manufacturer">
        <div>
          <div className="search-container d-flex justify-content-between ">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 text-dark px-2"
                type="text"
                placeholder="Search manufacturers here by manufacturer ID, manufacturer name..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className=" d-flex justify-content-between m-1 mb-2">
              {activePath == "/manufacturer" &&
                activeSubPath == "/subscibe-list" && (
                  <VersionHistoryBtn link="/manufacturer/subscibe-list/version-list/1" />
                )}
              {activePath == "/manufacturer" &&
                activeSubPath == "/premium-list" && (
                  <VersionHistoryBtn link="/manufacturer/premium-list/version-list/1" />
                )}
              {(user_type == "super_admin" && userData?.write) ||
              user_type == "admin"
                ? activePath == "/manufacturer" &&
                  activeSubPath == "/subscibe-list" && (
                    <CreateBtn link="/manufacturer/subscibe-list/create" />
                  )
                : ""}
            </div>
          </div>
          {activePath == "/manufacturer" &&
            activeSubPath == "/subscibe-list" && (
              <ManufacturerListTable search={search} />
            )}
          {activePath == "/manufacturer" &&
            activeSubPath == "/premium-list" && (
              <ManufacturerPremiumTable search={search} />
            )}
        </div>
      </MainLayout>
    </>
  );
};

export default ManufacturerList;
