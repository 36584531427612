import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import VersionHistoryBtn, {
  AdminButton,
} from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import DeleteModal from "../../../components/modals/deleteModal";
import {
  superAdminRoleDelete,
  superAdminRoleDetail,
} from "../../../redux/actions/superAdminActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const SuperUserRoleView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false);
  const [adminViewRoleData, setAdminViewRoleData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSuperAdminView();
  }, []);

  const fetchSuperAdminView = () => {
    setLoading(true);
    dispatch(superAdminRoleDetail(params.id, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setAdminViewRoleData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    const msg = err.data.message;
    const updated_msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    setCatchedErrorMsg(updated_msg);
  };

  const delFunc = () => {
    dispatch(superAdminRoleDelete(params.id, onDelSuccess, onDeleteError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/super-user");
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onDeleteError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (adminViewRoleData?.role_id
          ? ` / ${adminViewRoleData?.role_id} - view`
          : " / view")}
    </span>
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          <VersionHistoryBtn
            link={`/super-user/role/version-view/${adminViewRoleData?.id}`}
          />

          <AdminButton
            background="#7065E4"
            color="white"
            width="90px"
            onClick={() =>
              navigate(`/super-user/role/edit/${adminViewRoleData?.id}`)
            }
          >
            <span className="m-2">EDIT</span>
          </AdminButton>

          <AdminButton
            background="#FF6F5B"
            color="white"
            width="100px"
            type="button"
            onClick={() => setDeleteShow(true)}
          >
            <span className="mx-2">DELETE</span>
          </AdminButton>
        </div>
      </div>
      {loading == true ? (
        <div className="view-spinner-container">
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <div className="mx-4 px-3 mt-3">
          <div className="my-2">
            <label className=" Neutral_Color fs_14">Role Id</label>
            <p className="fs_14">{adminViewRoleData?.role_id}</p>
          </div>
          <div className="my-2">
            <label className=" Neutral_Color fs_14">Role Name</label>
            <p className="fs_14">{adminViewRoleData?.role_name}</p>
          </div>
          <div className="my-2">
            <label className=" Neutral_Color fs_14">Role Level</label>
            <p className="fs_14">{adminViewRoleData?.role_level}</p>
          </div>
          <div className="mt-3">
            <label className=" color_b0b3c7 fs_14">Permission</label>
          </div>
          <div className="my-1 d-flex  label-container align-items-center justify-content-start ">
            <div className="col-5">
              <label className="color_b0b3c7 fs_14">Models</label>
            </div>
            <label className="color_b0b3c7 fs_14 ">Read</label>
            <label className="color_b0b3c7 fs_14 ms-4 ps-2">Write</label>
          </div>
          {adminViewRoleData?.superAdminPermissions?.map((ele, index) => (
            <div
              className="d-flex my-1 label-container align-items-center justify-content-start"
              key={index}
            >
              {ele?.read == false && ele?.write == false ? (
                ""
              ) : (
                <>
                  <div className="col-5">
                    <label className="fs_14">
                      {ele?.adminPermissionModule?.module_name}
                    </label>
                  </div>

                  <div>
                    <span className="fs_14 my-1">
                      <input
                        type={"checkbox"}
                        className="CheckBox"
                        checked={ele?.read}
                        readOnly
                      />
                    </span>
                  </div>
                  <div className="ms-5">
                    <span className="fs_14 my-1">
                      <input
                        type={"checkbox"}
                        className="CheckBox"
                        checked={ele?.write}
                        readOnly
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
        title={`superuser - ${adminViewRoleData?.role_id} ?`}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />

      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default SuperUserRoleView;
