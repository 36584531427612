import React, { useEffect, useState } from "react";
import { Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import "../customer/customer.css";
import ApporveRejectModal from "../../../components/modals/approve-reject-modal";
import { onboardRequestDetail } from "../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import DoctorRequestView from "../../../components/customer-vew/doctorRequestView";
import PharmacyRequestView from "../../../components/customer-vew/pharmacy-request-View";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const RequestView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [rejectShow, setRejectShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [customerViewData, setCustomerViewData] = useState([]);
  const [customerTypeData, setCustomerTypeData] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(customerTypeData?.user_type, "dshbjd");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  console.log(state);

  useEffect(() => {
    console.log(state);
    dispatch(onboardRequestDetail(state, onSuccess, onError));
    setCustomerTypeData(state);
    setLoading(true);
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setCustomerViewData(data.data.data);
    // setCustomerDoctordata(data.data.data.pharmacyDoctors);
    console.log(data.data.data.pharmacyDoctors.pharmacy_id);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (customerViewData.code
          ? ` / ${customerViewData.code} - view`
          : " / view")}
    </span>
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          {/* <AdminButton
            background="#2AD3E7"
            color="white"
            width="100px"
            type="button"
            onClick={() => {
              setApproveShow(true);
            }}
          >
            <span className="mx-2">APPROVE</span>
          </AdminButton>
          <AdminButton
            background="#FF6F5B"
            color="white"
            width="100px"
            type="button"
            onClick={() => {
              setRejectShow(true);
            }}
          >
            <span className="mx-2">REJECT</span>
          </AdminButton> */}

          <ApporveRejectModal
            title="Do you want to approve this user??"
            btnName="APPROVE"
            btnColor="#2AD3E7"
            show={approveShow}
            handleClose={() => setApproveShow(false)}
          />
          <ApporveRejectModal
            title="Do you want to reject this user?"
            btnName="REJECT"
            show={rejectShow}
            handleClose={() => setRejectShow(false)}
          />
        </div>
      </div>

      {loading == true ? (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <>
          <div className="fn_Nunito scrolling">
            {customerTypeData?.user_type == "doctor" ? (
              <>
                <DoctorRequestView customerViewData={customerViewData} />
              </>
            ) : (
              <PharmacyRequestView customerViewData={customerViewData} />
            )}
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default RequestView;
