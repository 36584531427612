import React from "react";

const ArrowRightIcon = ({ fill = "#7065E4", width = 10, height = 10 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.89963 12.0818L6.65314 7.32179C6.9802 6.99318 7.16382 6.54842 7.16382 6.08479C7.16382 5.62115 6.9802 5.17639 6.65314 4.84779L1.89413 0.0817871L0.835135 1.14229L5.59413 5.90779C5.641 5.95467 5.66733 6.01825 5.66733 6.08454C5.66733 6.15083 5.641 6.21441 5.59413 6.26129L0.841134 11.0213L1.89963 12.0818Z"
          fill={fill}
        />
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M1.89963 12.0818L6.65314 7.32179C6.9802 6.99318 7.16382 6.54842 7.16382 6.08479C7.16382 5.62115 6.9802 5.17639 6.65314 4.84779L1.89413 0.0817871L0.835135 1.14229L5.59413 5.90779C5.641 5.95467 5.66733 6.01825 5.66733 6.08454C5.66733 6.15083 5.641 6.21441 5.59413 6.26129L0.841134 11.0213L1.89963 12.0818Z"
          fill="rgba(176,179,199,1)"
        />
      </svg> */}
    </div>
  );
};

export default ArrowRightIcon;
