import React, { useEffect } from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "15px",
    maxWidth: "30rem",
    height: "15rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

const HeadQuaterListTable = ({
  maxWidth = "45rem",
  headQuatersList,
  editSetForm,
  setFieldValue,
}) => {
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table className="main-table">
          <thead>
            <tr className="table-rows color_747f9e text-nowrap">
              <th className="table-heads " width={"1%"}>
                #
              </th>
              <th className="table-heads " width={"3%"}>
                Head Quarters Name
              </th>
              <th className="table-heads " width={"3%"}>
                State
              </th>
              <th className="table-heads" width={"8%"}>
                District
              </th>
              <th className="table-heads" width={"8%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {headQuatersList.map((item, index) => (
              <tr className="table-rows1 color_262c3d text-nowrap" key={index}>
                <td className="table-each-row-data">{index + 1}</td>
                <td className="table-each-row-data">{item?.name}</td>
                <td className="table-each-row-data">{item?.state?.label}</td>
                <td className="table-each-row-data">
                  {item.district.map((e, index) => {
                    return (
                      <span>
                        {index ? " | " : ""}
                        {e.label}
                      </span>
                    );
                  })}
                </td>
                <td className="table-each-row-data">
                  <button
                    className="button-edit1"
                    type="button"
                    onClick={() => editSetForm(item, index, setFieldValue)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default HeadQuaterListTable;

const order = [
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Bengaluru | Mysuru | Mandya",
  },
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Mysuru ",
  },
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Bengaluru | Mysuru | Mandya",
  },
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Bengaluru | Mysuru | Mandya",
  },
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Mysuru ",
  },
  {
    headQuarterName: "Head Quarters Name Here",
    State: "Karnataka",
    district: "Mysuru ",
  },
];
