import { Field } from "formik";
import React, { useEffect, useState } from "react";
// import { BsAlignCenter } from "react-icons/bs";
// import { useDispatch } from "react-redux";
import Select from "react-select";
import ErrorMsg from "../errorMsg";
// import ErrorMsg from "../errorMsg";

const SelectField = ({
  label,
  labelColor = "Neutral_Color",
  name,
  type,
  value,
  optionList,
  placeholder,
  optionsAction,
  menuHeight,
  color = "Neutral_Color",
  fontWeigth = "fw_400",
  style = true,
}) => {
  // const dispatch = useDispatch();
  const [selected, setSelected] = useState({
    label: "",
    value: "",
  });
  // const [optionList, setOptionList] = useState([]);

  // useEffect(() => {
  //   dispatch(optionsAction(onSuccess, onError));
  // }, []);

  // const onSuccess = (data) => {
  //   setOptionList(data);
  // };

  // const onError = (err) => {
  //   console.log(err);
  // };

  return (
    <div className={`${color} d-flex  flex-column mb-2`}>
      <label className={`${fontWeigth} ${labelColor} fw_400 fs_14 my-1`}>
        {label}
      </label>
      <Field name={name}>
        {({ form, field, meta }) => (
          <div className="d-flex">
            <Select
              {...field}
              type={type}
              value={optionList.find((s) => s.value == value)}
              cacheOptions
              defaultOptions
              placeholder={placeholder}
              options={optionList}
              onChange={(selected) => {
                setSelected(selected);
                form.setFieldValue(name, selected.value);
              }}
              className={`w-100`}
              styles={style ? customStyles : customStyles2 }
              maxMenuHeight={menuHeight}
            />
          </div>
        )}
      </Field>
      <ErrorMsg name={name} />
    </div>
  );
};

export default SelectField;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "black",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#262C3D",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};

export const customStyles2 = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "black",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "480px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#262C3D",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
