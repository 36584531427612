import { api } from "./api";

export const faqListApi = (params) => {
  return api.get(`/admin/faq/list`, { params });
};
export const faqCreateApi = (params) => {
  return api.post(`/admin/faq/create`, JSON.stringify(params));
};
export const faqEditApi = (params) => {
  return api.post(`/admin/faq/edit`, JSON.stringify(params));
};
export const faqViewApi = (params) => {
  return api.get(`/admin/faq/view`, { params: params });
};
export const faqVersionListApi = (params) => {
  return api.get(`/admin/faq/version-list`, { params });
};
export const faqVersionDetailApi = (params) => {
  return api.get(`/admin/faq/version-view`, { params: params });
};
export const faqDeleteApi = (params) => {
  return api.delete(`/admin/faq/delete`, { data: params });
};
