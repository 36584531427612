import React, { useEffect, useState } from "react";
import TableContainer from "../../components/layout/table-container/table-container";
import ManufacturerListTableData from "./manufacturer-list-table-data";
import UpArrow from "../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../assets/icons/filterArrowDown.svg";
import { useDispatch } from "react-redux";
import { manufacturerList } from "../../redux/actions/manufacturerActions";
import TablePagination from "../../components/Pagination/tablePagination";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const ManufacturerListTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [manufacturerListData, setManufacturerListData] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      fetchManufacturerList();
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      fetchManufacturerList();
    }
  }, [status]);

  useEffect(() => {
    fetchManufacturerList();
  }, [page, status]);

  const fetchManufacturerList = () => {
    dispatch(manufacturerList({ search, page, status }, onSuccess, onError));
    setLoading(true);
  };

  useEffect(() => {
    fetchManufacturerList();
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setManufacturerListData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads" width={"10%"}>
              Manufacturer ID
            </th>
            <th className="table-heads " width={"10%"}>
              Manufacturer Name
            </th>
            <th className="table-heads text-center" width={"10%"}>
              No. of Orders
            </th>
            <th className="table-heads text-center" width={"12%"}>
              No. of Distributors
            </th>
            <th className="table-heads " width={"10%"}>
              Email Status
            </th>
            <th className="table-heads text-nowrap" width={"8%"}>
              <span onClick={() => setOpenStatus(!openStatus)}>
                Status
                {openStatus ? (
                  <img src={UpArrow} className="mb-1 mx-1" />
                ) : (
                  <img src={DownArrow} className=" mx-1" />
                )}
              </span>
              {openStatus ? (
                <div className=" position-relative">
                  <div className="position-absolute status-container d-flex gap-3 px-3">
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="Active"
                        name="status"
                        value={"active"}
                        checked={status == "active"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("active");
                        }}
                      />
                      <span
                        for="Active"
                        className={
                          active === "active"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Active
                      </span>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="Inactive"
                        name="status"
                        value={"inactive"}
                        checked={status == "inactive"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("inactive");
                        }}
                      />
                      <label
                        htmlFor="Inactive"
                        className={
                          active === "inactive"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Inactive
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2 cursor_pointer"
                        type="radio"
                        id="draft"
                        name="status"
                        value={"draft"}
                        checked={status == "draft"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("draft");
                        }}
                      />
                      <label
                        htmlFor="draft"
                        className={
                          active === "draft"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Draft
                      </label>
                    </div>

                    <button
                      className="filter-btn px-2 py-0 my-2 fs_12"
                      style={{ height: "28px" }}
                      onClick={() => {
                        setStatus(null);
                        setOpenStatus(!openStatus);
                      }}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              ) : null}
            </th>
            <th className="table-heads " width={"22%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : manufacturerListData?.manufacturers?.length > 0 ? (
            <>
              {manufacturerListData?.manufacturers?.map((eachRow, index) => (
                <ManufacturerListTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  fetchManufacturerList={fetchManufacturerList}
                  currentPage={manufacturerListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {manufacturerListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={manufacturerListData?.pagination}
            nextFunc={() => {
              navigate(
                `/manufacturer/subscibe-list/${manufacturerListData?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/manufacturer/subscibe-list/${manufacturerListData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default ManufacturerListTable;
