import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import * as Yup from "yup";
import "../manufacturer.css";
import ImageUploading from "react-images-uploading";
import InputField from "../../../components/form/inputField/inputField";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import Select from "react-select";
import ErrorMsg from "../../../components/form/errorMsg";
import upload from "../../../assets/icons/uploadImg.svg";
import reupload from "../../../assets/icons/reUpload.svg";
import uploadDoc from "../../../assets/icons/uploadDoc.svg";
import back from "../../../assets/icons/back.svg";
import Switch from "react-switch";
import eye from "../../../assets/icons/viewEye.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCityList,
  fetchDistrictList,
  fetchPincodeAutoList,
  fetchStateList,
} from "../../../redux/actions/commonActions";
import {
  documentUpld1,
  documentUpld2,
  documentUpld3,
  manufacturerCreateDraft,
  manufacturerLogoUpload,
} from "../../../redux/actions/manufacturerActions";
import { Document, Page, pdfjs } from "react-pdf";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";
import ValueInputField from "../../../components/form/inputField/ValueInputField";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { pdfChecker } from "../../../components/utilites/helper";
import PdfView from "../../../components/modals/pdfView";
import ImageViewModal from "../../../components/modals/customer/image-view-modal";
import PincodeInputField from "../../../components/form/inputField/pincodeInput";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ManufacturerCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoImages, setLogoImages] = useState([]); // LOGO IMAGE UPLOAD
  const [viewImage, setViewImage] = useState(null);
  const [logoImg, setLogoImg] = useState(null);
  const [gstimg, setGstImg] = useState(null);
  const [licenceImg, setLicenceImg] = useState(null);
  const [licence2Img, setLicence2Img] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [logoUpldLoading, setLogoUpldLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [pincodeSet, setPincodeSet] = useState(null);
  const [pincodeData, setPincodeData] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [imageTitleData, setImageTitleData] = useState("");
  const [imageUrlData, setImageUrlData] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  const [intValues, setintValues] = useState();

  console.log(pincodeSet, "pin");

  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const nameregex = /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/;
  const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  const logoimageUpdate = (imageList, addUpdateIndex) => {
    console.log(imageList[0]?.file);
    setLogoUpldLoading(true);
    // data for submit
    if (imageList[0]?.file) {
      dispatch(
        manufacturerLogoUpload(
          imageList[0]?.file,
          onLogoUploadSuccess,
          onLogoUploadError
        )
      );
    }

    setLogoImages(imageList);
  };

  const onLogoUploadSuccess = (data) => {
    console.log(data);
    setLogoImg(data.data.data.filePath);
  };
  const onLogoUploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
  };

  const docTimeOutFnc = () => {
    setShowErrorPopup(false);
  };

  const docimageUpdateOne = (file) => {
    console.log(file.target.files[0]);
    dispatch(
      documentUpld1(
        file.target.files[0],
        ondoc1UploadSuccess,
        ondoc1UploadError
      )
    );
    // setDocOneImages(imageList);
  };

  const ondoc1UploadSuccess = async (data) => {
    console.log(data.data.data);
    setGstImg(data.data.data.filePath);
  };

  const ondoc1UploadError = (err) => {
    console.log(err.data.message, "hqqqqq");
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message + " please select the correct format");
    setTimeout(docTimeOutFnc, 2000);
  };

  const docimageUpdateTwo = (file) => {
    dispatch(
      documentUpld2(
        file.target.files[0],
        ondoc2UploadSuccess,
        ondoc2UploadError
      )
    );
    // setDocTwoImages(imageList);
  };

  const ondoc2UploadSuccess = (data) => {
    setLicenceImg(data.data.data.filePath);
  };
  const ondoc2UploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
  };

  const docimageUpdateThree = (file) => {
    dispatch(
      documentUpld3(
        file.target.files[0],
        ondoc3UploadSuccess,
        ondoc3UploadError
      )
    );
    // setDocTwoImages(imageList);
  };

  const ondoc3UploadSuccess = (data) => {
    setLicence2Img(data.data.data.filePath);
  };
  const ondoc3UploadError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
    setTimeout(docTimeOutFnc, 2000);
  };

  const handlePincode = (e, setFieldValue) => {
    console.log(e.target.value.length, "pin");
    setFieldValue("pincode", e.target.value);
    if (e.target.value.length === 6) {
      setPincodeSet(e.target.value);
    } else {
      setSelectedState(null);
      setSelectedDistrict(null);
      setSelectedCity(null);
    }
  };

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  useEffect(() => {
    if (pincodeSet != null) {
      dispatch(
        fetchPincodeAutoList(
          { pincode: pincodeSet },
          onGetPincodeSuccess,
          onGetPincodeError
        )
      );
    }
  }, [pincodeSet]);

  const onGetPincodeSuccess = (data) => {
    console.log(data?.data?.data?.state_id, "pin");
    setPincodeData(data?.data?.data);
    setSelectedState(data?.data?.data?.state_id);
    setSelectedDistrict(data?.data?.data?.district_id);
    setSelectedCity(data?.data?.data?.city_id);
    initialValues["state_id"] = data?.data?.data?.state_id;
  };

  console.log(selectedState, "pin");
  const onGetPincodeError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch(
      fetchDistrictList(
        { state_id: selectedState },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
    fetchCityList(
      { state_id: selectedState, district_id: selectedDistrict },
      onGetCitySuccess,
      onGetCityError
    );
  }, []);
  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict !== null) {
      dispatch(
        fetchCityList(
          { state_id: selectedState, district_id: selectedDistrict },
          onGetCitySuccess,
          onGetCityError
        )
      );
    }
  }, [selectedState, selectedDistrict]);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log("District", data);
  };
  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const onGetCitySuccess = (data) => {
    setCityOptions(data);
    console.log("city", data);
  };
  const onGetCityError = (err) => {
    console.log(err);
  };

  const initialValues = {
    name: intValues?.name ? intValues?.name : "",
    logo: intValues?.logo ? intValues?.logo : "",
    drug_licence_1: intValues?.drug_licence_1 ? intValues?.drug_licence_1 : "",
    drug_licence_2: intValues?.drug_licence_2 ? intValues?.drug_licence_2 : "",
    cin_number: intValues?.cin_number ? intValues?.cin_number : "",
    fssai_number: intValues?.fssai_number ? intValues?.fssai_number : "",
    gstin_number: intValues?.gstin_number ? intValues?.gstin_number : "",
    pan_number: intValues?.pan_number ? intValues?.pan_number : "",
    order_validity: intValues?.order_validity ? intValues?.order_validity : "",
    email: intValues?.email ? intValues?.email : "",
    mobile: intValues?.mobile ? intValues?.mobile : "",
    alternate_mobile: intValues?.alternate_mobile ? intValues?.alternate_mobile : "",
    address_one: intValues?.address_one ? intValues?.address_one : "",
    address_two: intValues?.address_two ? intValues?.address_two : "",
    address_three: intValues?.address_three ? intValues?.address_three : "",
    landmark: intValues?.landmark ? intValues?.landmark : "",
    pincode: pincodeSet == null ? "" : pincodeSet,
    state_id: selectedState ? selectedState : "",
    district_id: selectedDistrict ? selectedDistrict : "",
    city_id: selectedCity ? selectedCity : "",
    gst_certificate: intValues?.gst_certificate ? intValues?.gst_certificate : "",
    drug_manufacturing_license: intValues?.drug_manufacturing_license ? intValues?.drug_manufacturing_license : "",
    drug_manufacturing_licens2: intValues?.drug_manufacturing_licens2 ? intValues?.drug_manufacturing_licens2 : "",
    is_subscribed: intValues?.is_subscribed === true ? intValues?.is_subscribed : false,
    invoicing_value: intValues?.invoicing_value ? intValues?.invoicing_value : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    drug_licence_1: Yup.string().required("Required"),
    drug_licence_2: Yup.string()
      .notOneOf(
        [Yup.ref("drug_licence_1")],
        "Drug Licence 1 & 2 should not be same"
      )
      .required("* Required"),
    cin_number: Yup.string()
      .matches(/^[A-Z0-9 ]*$/, "Capital letters and numbers are allowed only")
      .max(21)
      .required("Required"),
    fssai_number: Yup.string().required("Required"),
    gstin_number: Yup.string().required("Required"),
    pan_number: Yup.string().required("Required"),
    order_validity: Yup.string().required("Required"),
    order_validity: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email").required("Required"),
    mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    alternate_mobile: Yup.string().matches(phoneRegex, "Not a valid Number"),
    address_one: Yup.string().required("Required"),
    address_two: Yup.string().required("Required"),
    landmark: Yup.string().required("Required"),
    state_id: Yup.string().required("Required"),
    district_id: Yup.string().required("Required"),
    city_id: Yup.string().required("Required"),
    pincode: Yup.string()
      .matches(pincoderegex, "Not a valid Number")
      .required("Required"),
    invoicing_value: Yup.string().required("Required"),
  });
  const clearFunc = (setFieldValue) => {
    setFieldValue("name", "");
    setFieldValue("order_validity", "");
    setFieldValue("mobile", "");
    setFieldValue("email", "");
    setFieldValue("alternate_mobile", "");
    setFieldValue("address_one", "");
    setFieldValue("address_two", "");
    setFieldValue("address_three", "");
    setFieldValue("landmark", "");
    setFieldValue("state_id", "");
    setFieldValue("district_id", "");
    setFieldValue("city_id", "");
    setFieldValue("pincode", "");
    setFieldValue("GSTCertificate", "");
    setFieldValue("cin_number", "");
    setFieldValue("drug_licence_1", "");
    setFieldValue("drug_licence_2", "");
    setFieldValue("fssai_number", "");
    setFieldValue("gstin_number", "");
    setFieldValue("pan_number", "");
    setFieldValue("invoicing_value", "");
    setLicenceImg(null);
    setLicence2Img(null);
    setGstImg(null);
    setLogoImages([]);
    setLogoImg(null);
  };
  const onSubmit = (values) => {
    values["logo"] = logoImg;
    values["gst_certificate"] = gstimg;
    values["drug_manufacturing_license"] = licenceImg;
    values["drug_manufacturing_licens2"] = licence2Img;
    values["address"] = {
      address_one: values.address_one,
      address_two: values.address_two,
      address_three: values.address_three,
      landmark: values.landmark,
    };
    console.log(values, "hqqqqq");
    dispatch(manufacturerCreateDraft(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    console.log(data);
    navigate("/manufacturer/subscibe-list/create/designation");
  };

  const timeOutFnc = () => {
    setShowErrorPopup(false);
  };
  const onError = (err) => {
    console.log(err.data.message);
    setShowErrorMsg(err.data.message);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  return (
    <MainLayout breadCrumb="ADMIN  / Manufacturer / Create">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, values }) => {
          setintValues(values);
          return (
            <Form>
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <div className="d-flex justify-content-center align-items-center mx-3">
                    <label className="Medium_Purple fw_60 text-uppercase">
                      Premium
                    </label>
                    <Switch
                      checked={values?.is_subscribed}
                      onChange={() => {
                        if (!values.is_subscribed) {
                          setFieldValue("is_subscribed", true);
                        } else {
                          setFieldValue("is_subscribed", false);
                        }
                      }}
                      className="mx-3"
                      onColor="#d3d6eb"
                      offColor="#d3d6eb"
                      onHandleColor="#7065E4"
                      offHandleColor="#747F9E"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                      height={20}
                      width={40}
                    />
                  </div>
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    type="button"
                    onClick={() => clearFunc(setFieldValue)}
                  >
                    CLEAR
                  </AdminButton>
                  <AdminButton
                    background="#7065e4"
                    color="white"
                    width="200px"
                    type="submit"
                  >
                    <span className="m-2">SAVE & CONTINUE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="fn_Nunito scrolling">
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center">
                  <label className="basic-title mx-2 mb-1">Basic Details</label>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between"
                          style={{ maxWidth: "500px" }}
                        >
                          <label className="">Manufacturer Logo</label>
                          {logoImages.length === 1 ? (
                            <ImageUploading
                              value={logoImages}
                              onChange={logoimageUpdate}
                              dataURLKey="data_url"
                              maxFileSize={1048576}
                              className="cursor_pointer"
                              acceptType={["jpg", "png"]}
                            >
                              {({ onImageUpload }) => (
                                <div
                                  onClick={() =>
                                    logoImages.length === 1
                                      ? onImageUpload()
                                      : null
                                  }
                                >
                                  <img src={reupload} />
                                  <span className=" ms-1 fs_12 fw_700 Medium_Purple cursor_pointer">
                                    RE-UPLOAD
                                  </span>
                                </div>
                              )}
                            </ImageUploading>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <ImageUploading
                            value={logoImages}
                            onChange={logoimageUpdate}
                            dataURLKey="data_url"
                            maxFileSize={1048576}
                            acceptType={["jpg", "png"]}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                              errors,
                            }) => (
                              // write your building UI
                              <div
                                className={
                                  logoImages.length === 0
                                    ? `logo-upload-container `
                                    : `logo-upload-container `
                                }
                                {...dragProps}
                                onClick={() =>
                                  logoImages.length === 0
                                    ? onImageUpload()
                                    : null
                                }
                              >
                                {logoImages.length === 0 ? (
                                  <div
                                    className={`h-100 d-flex flex-column align-items-center justify-content-around p-2`}
                                  >
                                    <div
                                      className={`d-flex flex-column align-items-center justify-content-center `}
                                    >
                                      <img
                                        src={upload}
                                        width="50px"
                                        height="50px"
                                      />
                                      <span className="color_#262C3D pt-2 fs_14 fs_lg_12">
                                        Drag your image here, or
                                        <span className="Medium_Purple fw_700 ms-1">
                                          Browse
                                        </span>
                                      </span>
                                      <label className="color_747f9e pt-1 fs_10">
                                        Supports: JPEG and PNG images only.{" "}
                                        <span className="color_black">
                                          (2MB Max, 540X540px Resolution)
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  <div className={`w-100 h-100`}>
                                    {imageList.map((image, index) => (
                                      <div
                                        key={index}
                                        className={`upload_image`}
                                      >
                                        <div className={`overlay`}></div>

                                        <img
                                          src={image.data_url}
                                          layout="fill"
                                          objectfit="fill"
                                          object-position="center"
                                          className={`logo-upload-container `}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {errors && ( // error message object from react-images-uploading
                                  <div>
                                    <span
                                      className={` pandetails_form_error_text text-danger fs_12 fw_600 fw-semibold  `}
                                    >
                                      {errors.maxFileSize &&
                                        `Image maximum file size exceeds`}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </ImageUploading>
                        </div>
                      </div>
                      <div className="my-2 mt-3 ">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"name"}
                          label={"Manufacturer Name"}
                          labelColor="color_262c3d"
                          type={"text"}
                          placeholder={"Enter manufacturer name"}
                          name={"name"}
                          value={values?.name}
                        />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"drug_licence_1"}
                          label={"Drug License 1"}
                          labelColor="color_262c3d"
                          type={"text"}
                          placeholder={"Enter Drug License 1 number"}
                          name={"drug_licence_1"}
                          value={values?.drug_licence_1}
                        />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"drug_licence_2"}
                          label={"Drug License 2"}
                          labelColor="color_262c3d"
                          type={"text"}
                          placeholder={"Enter Drug License 2 number"}
                          name={"drug_licence_2"}
                          value={values?.drug_licence_2}
                        />
                      </div>
                      <div
                        className="my-2"
                      // style={{ maxWidth: "480px" }}
                      >
                        <div className="my-2">
                          <InputCapitalField
                            setFieldValue={setFieldValue}
                            id={"cin_number"}
                            label={"CIN No"}
                            labelColor="color_262c3d"
                            type={"text"}
                            placeholder={"Enter CIN. no"}
                            name={"cin_number"}
                            maxLength={21}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"fssai_number"}
                          label={"FSSAI Number"}
                          labelColor="color_262c3d"
                          type={"text"}
                          placeholder={"Enter FSSAI number"}
                          name={"fssai_number"}
                          maxLength={14}
                        />
                      </div>
                    </div>
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className={`Neutral_Color d-flex flex-column mb-2`}
                        >
                          <label className={`color_262c3d fw_400 fs_14 my-1`}>
                            GST Number
                          </label>
                        </div>
                        <Field name={"gstin_number"}>
                          {({ field, meta }) => (
                            <div className="in-field d-flex">
                              <input
                                type="text"
                                {...field}
                                placeholder={`Enter GST number`}
                                className={`input input-field`}
                                onBlur={() => {
                                  setFieldValue(
                                    "pan_number",
                                    values?.gstin_number?.substring(
                                      2,
                                      values?.gstin_number?.length - 3
                                    )
                                  );
                                }}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue(
                                    "gstin_number",
                                    value?.toUpperCase()
                                  );
                                }}
                                maxLength={15}
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name={"gstin_number"} />
                      </div>
                    </div>
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className={`Neutral_Color d-flex flex-column mb-2`}
                        >
                          <label className={`color_262c3d fw_400 fs_14 my-1`}>
                            PAN Number
                          </label>
                        </div>
                        <Field name={"pan_number"}>
                          {({ field, meta }) => (
                            <div className="in-field d-flex">
                              <input
                                type="text"
                                {...field}
                                style={{ background: "#e7e5ff" }}
                                placeholder={`Enter PAN number`}
                                className={`input input-field`}
                                disabled={true}
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name={"pan_number"} />
                      </div>
                    </div>
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <ValueInputField
                          id={"order_validity"}
                          label={"Order Validity"}
                          labelColor={"color_262c3d"}
                          type={"number"}
                          placeholder={
                            "Enter number of days for order validity"
                          }
                          name={"order_validity"}
                          valueText="Days"
                          InputMaxWidth="450px"
                          value={values?.order_validity}
                        />
                      </div>
                    </div>
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <ValueInputField
                          id={"invoicing_value"}
                          label={"Invoice Value"}
                          labelColor={"color_262c3d"}
                          type={"decimal"}
                          placeholder={
                            "Enter value for calculate invoicing value"
                          }
                          name={"invoicing_value"}
                          valueText="%"
                          InputMaxWidth="450px"
                          value={values?.invoicing_value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                    <label className="basic-title mx-2 mb-1">
                      Contact Details
                    </label>
                  </div>
                  <div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div
                          className="mx-md-4 px-3 mx-auto"
                          style={{ maxWidth: "480px" }}
                        >
                          <div className="my-2">
                            <InputField
                              id={"email"}
                              label={"Email Address"}
                              labelColor="color_262c3d"
                              type={"text"}
                              placeholder={"Enter email address"}
                              name={"email"}
                              value={values?.email}
                            />
                          </div>
                          <div className="my-2">
                            <InputField
                              id={"mobile"}
                              label={"Mobile Number"}
                              labelColor="color_262c3d"
                              type={"text"}
                              placeholder={"Enter mobile number"}
                              name={"mobile"}
                              value={values?.mobile}
                              maxLength={10}
                            />
                          </div>
                          <div className="my-2">
                            <InputField
                              id={"alternate_mobile"}
                              label={"Alternative Mobile Number"}
                              labelColor="color_262c3d"
                              type={"text"}
                              placeholder={"Enter alternative mobile number"}
                              name={"alternate_mobile"}
                              value={values?.alternate_mobile}
                              maxLength={10}
                            />
                          </div>
                          <div className="my-2" style={{ height: "175px" }}>
                            <label className=" mx-1 fn_Nunito fs_14">
                              Address
                            </label>
                            <div>
                              <Field name={"address_one"}>
                                {({ field, meta }) => (
                                  <input
                                    {...field}
                                    placeholder="Address line 1"
                                    id={"address_one"}
                                    className={
                                      "w-100 input input-field py-2 my-1"
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={"address_two"}>
                                {({ field, meta }) => (
                                  <input
                                    {...field}
                                    placeholder="Address line 2"
                                    id={"address_two"}
                                    className={
                                      "w-100 input input-field py-2 my-1"
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={"address_three"}>
                                {({ field, meta }) => (
                                  <input
                                    {...field}
                                    placeholder="Address line 3"
                                    id={"address_two"}
                                    className={
                                      "w-100 input input-field py-2 my-1"
                                    }
                                  />
                                )}
                              </Field>
                              {(errors.address_one && (
                                <ErrorMsg name={"address_one"} />
                              )) ||
                                (errors.address_two && (
                                  <ErrorMsg name={"address_two"} />
                                )) ||
                                (errors.address_three && (
                                  <ErrorMsg name={"address_three"} />
                                ))}
                            </div>
                          </div>
                          <div className="my-2">
                            <InputField
                              id={"landmark"}
                              label={"Landmark"}
                              labelColor="color_262c3d"
                              type={"text"}
                              placeholder={"Enter landmark"}
                              name={"landmark"}
                              value={values?.landmark}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="my-2">
                          <div
                            className="mx-md-4 px-3 mx-auto"
                            style={{ maxWidth: "480px" }}
                          >
                            <PincodeInputField
                              id={"pincode"}
                              label={"Pincode"}
                              labelColor="color_262c3d"
                              type={"text"}
                              placeholder={"Enter pincode"}
                              name={"pincode"}
                              maxLength={6}
                              value={values?.pincode}
                              onChange={(e) => handlePincode(e, setFieldValue)}
                            />
                            <div className="select-container">
                              <label className="w-100 mx-1 fn_Nunito fs_14">
                                State
                              </label>
                              <Field name={"state_id"}>
                                {({ field, meta }) => (
                                  <Select
                                    {...field}
                                    options={stateOptions}
                                    styles={customStyles}
                                    placeholder={"Enter your state"}
                                    value={stateOptions.filter(
                                      (s) => s.value == values.state_id
                                    )}
                                    onChange={(selected) => {
                                      setSelectedState(selected?.value);
                                      setFieldValue(
                                        "state_id",
                                        selected?.value
                                      );
                                      setFieldValue("pincode", "");
                                      setPincodeSet(null);
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMsg name="state_id" />
                            </div>
                            <div className="select-container">
                              <label className="w-100 mx-1 fn_Nunito fs_14">
                                District
                              </label>
                              <Field name={"district_id"}>
                                {({ field, meta }) => (
                                  <Select
                                    {...field}
                                    options={districtOptions}
                                    styles={customStyles}
                                    placeholder={"Enter your district"}
                                    value={districtOptions.filter(
                                      (s) => s.value == values.district_id
                                    )}
                                    onChange={(selected) => {
                                      setSelectedDistrict(selected?.value);
                                      setFieldValue(
                                        "district_id",
                                        selected?.value
                                      );
                                      setFieldValue("pincode", "");
                                      setPincodeSet(null);
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMsg name="district_id" />
                            </div>
                            <div className="select-container">
                              <label className="w-100 mx-1 fw_400 fn_Nunito fs_14">
                                City
                              </label>
                              <Field name={"city_id"}>
                                {({ field, meta }) => (
                                  <Select
                                    {...field}
                                    options={cityOptions}
                                    styles={customStyles}
                                    placeholder={"Enter your city"}
                                    value={cityOptions.filter(
                                      (s) => s.value == values.city_id
                                    )}
                                    onChange={(selected) => {
                                      setSelectedCity(selected?.value);
                                      setFieldValue("city_id", selected?.value);
                                      setFieldValue("pincode", "");
                                      setPincodeSet(null);
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMsg name="city_id" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Documents</label>
                </div>
                <div className="row mt-3">
                  <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                    <div
                      className="mx-xxl-4 px-3 mx-auto my-2"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label>Drug License 1</label>
                          {licenceImg !== "" && licenceImg !== null && (
                            <>
                              <label
                                htmlFor="DrugLicense"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />{" "}
                                Re-UPload
                              </label>
                            </>
                          )}
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense"
                            accept="image/*, application/pdf"
                            onChange={(e) => docimageUpdateTwo(e)}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      {licenceImg !== "" && licenceImg !== null ? (
                        <div
                          className={` h-100 my-2`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            {pdfChecker(licenceImg) ? (
                              <Document file={licenceImg}>
                                <Page pageNumber={1} width={410} />
                              </Document>
                            ) : (
                              <img className="image-upload" src={licenceImg} />
                            )}
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                {pdfChecker(licenceImg) ? (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setOpenPdf(true);
                                      setPdfFileUrl(licenceImg);
                                      setFileName("Drug Manufacturing License");
                                    }}
                                  >
                                    VIEW
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setImageModal(true);
                                      setImageTitleData(
                                        "Drug Manufacturing License"
                                      );
                                      setImageUrlData(licenceImg);
                                    }}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="DrugLicense"
                            className="manufacturer-doc-upload-container w-100 text-center"
                          >
                            <div className=" w-100 text-center">
                              <img src={uploadDoc} alt="doc" className="mt-5" />
                              <p className="Medium_Purple fw_700 my-1 fs_17">
                                Choose File
                              </p>
                              <p className="color_b0b3c7 fs_12">
                                Supports: PDF and image files
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense"
                            accept="image/*, application/pdf"
                            onChange={(e) => docimageUpdateTwo(e)}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                    <div
                      className="mx-xxl-4 px-3 mx-auto my-2"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label>Drug License 2</label>
                          {licence2Img !== "" && licence2Img !== null && (
                            <>
                              <label
                                htmlFor="DrugLicense2"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />{" "}
                                Re-UPload
                              </label>
                            </>
                          )}
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense2"
                            accept="image/*, application/pdf"
                            onChange={(e) => docimageUpdateThree(e)}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      {licence2Img !== "" && licence2Img !== null ? (
                        <div
                          className={` h-100 my-2`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            {pdfChecker(licence2Img) ? (
                              <Document file={licence2Img}>
                                <Page pageNumber={1} width={410} />
                              </Document>
                            ) : (
                              <img className="image-upload" src={licence2Img} />
                            )}
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                {pdfChecker(licence2Img) ? (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setOpenPdf(true);
                                      setPdfFileUrl(licence2Img);
                                      setFileName("Drug Manufacturing License");
                                    }}
                                  >
                                    VIEW
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setImageModal(true);
                                      setImageTitleData(
                                        "Drug Manufacturing License"
                                      );
                                      setImageUrlData(licence2Img);
                                    }}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="DrugLicense2"
                            className="manufacturer-doc-upload-container w-100 text-center"
                          >
                            <div className=" w-100 text-center">
                              <img src={uploadDoc} alt="doc" className="mt-5" />
                              <p className="Medium_Purple fw_700 my-1 fs_17">
                                Choose File
                              </p>
                              <p className="color_b0b3c7 fs_12">
                                Supports: PDF and image files
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense2"
                            accept="image/*, application/pdf"
                            onChange={(e) => docimageUpdateThree(e)}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-xxl-4" style={{ minWidth: "450px" }}>
                    <div
                      className="mx-xxl-4 px-3 mx-auto my-2"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label>GST Certificate</label>
                          {gstimg !== "" && gstimg !== null && (
                            <>
                              <label
                                htmlFor="GSTCertificate"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />{" "}
                                Re-UPload
                              </label>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          name="upload"
                          id="GSTCertificate"
                          accept="image/*, application/pdf"
                          style={{ display: "none" }}
                          onChange={(e) => docimageUpdateOne(e)}
                        />
                      </div>
                      {gstimg !== "" && gstimg !== null ? (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            {pdfChecker(gstimg) ? (
                              <Document file={gstimg}>
                                <Page pageNumber={1} width={410} />
                              </Document>
                            ) : (
                              <img className="image-upload" src={gstimg} />
                            )}
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                {pdfChecker(gstimg) ? (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setOpenPdf(true);
                                      setPdfFileUrl(gstimg);
                                      setFileName("Drug Manufacturing License");
                                    }}
                                  >
                                    VIEW
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="fs_16 fw_600"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#E7E5FF",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      setImageModal(true);
                                      setImageTitleData(
                                        "Drug Manufacturing License"
                                      );
                                      setImageUrlData(gstimg);
                                    }}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="GSTCertificate"
                            className="manufacturer-doc-upload-container w-100 text-center"
                          >
                            <div className=" w-100 text-center">
                              <img src={uploadDoc} alt="doc" className="mt-5" />
                              <p className="Medium_Purple fw_700 my-1 fs_17">
                                Choose File
                              </p>
                              <p className="color_b0b3c7 fs_12">
                                Supports: PDF and image files
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="GSTCertificate"
                            accept="image/*, application/pdf"
                            style={{ display: "none" }}
                            onChange={(e) => docimageUpdateOne(e)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
      <PdfView
        show={openPdf}
        handleClose={() => setOpenPdf(false)}
        pdfUrl={pdfFileUrl}
        filename={filename}
      />
      <ImageViewModal
        show={imageModal}
        ShowClose={() => setImageModal(false)}
        imageTitleData={imageTitleData}
        imageUrlData={imageUrlData}
      />
    </MainLayout>
  );
};

export default ManufacturerCreate;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
