import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { verifyResetPassword } from "../../../redux/actions/authActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const ResetPasswordLink = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  useEffect(() => {
    console.log(params?.id);
    dispatch(verifyResetPassword(params?.id, onSuccess, onError));
  }, []);

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
  }

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setVerified(true);
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
    setLoading(false);
    setVerified(false);
  }

  const onError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return loading ? (
    <div className="d-flex justify-content-center align-items-center spinner-container">
      <Spinner />
    </div>
  ) : verified === true ? (
    <>
      <Navigate to="/reset-password" state={{ data: params?.id }} />
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
    </>
  ) : (
    <>
      <Navigate to="/" />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ResetPasswordLink;
