import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import "./manufacturerModal.css";
import TableContainer from "../../layout/table-container/table-container";
import FieldForceVerificationTableData from "./FieldForceVerificationTableData";
import { useDispatch } from "react-redux";
import { fieldForceBulkVerify } from "../../../redux/actions/manufacturerActions";
import FieldForceBulkErrorModal from "./bulkupload-error-modals/fieldForceBulkErrorModal";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import UnablePopup from "../../popups/UnablePopUp";

const FieldForceDataVerificationModal = ({
  show,
  ShowClose,
  fieldForceData,
  vendor_id,
  division_id,
}) => {
  const dispatch = useDispatch();
  const [verifiedList, setVerifiedList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [ffBluckStatusData, setFfBluckStatusData] = useState("");
  const [FFErrorModal, setFFErrorModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showUnablePopup, setShowUnablePopup] = useState(false);
  const [showUnableMsg, setShowUnableMsg] = useState("");

  const checkedFieldForce = (e, data) => {
    const { checked } = e.target;
    let newArr = verifiedList;
    setVerifiedList([...newArr, data]);
    if (!checked) {
      setVerifiedList(
        verifiedList.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(data)
        )
      );
    }
  };

  const checkAll = (e) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (checked) {
      setVerifiedList(fieldForceData?.fieldForceData);
    } else {
      setVerifiedList([]);
    }
  };

  useEffect(() => {
    if (verifiedList?.length != fieldForceData?.fieldForceData?.length) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [verifiedList]);

  useEffect(() => {
    setBulkUploadData(fieldForceData?.fieldForceData);
  }, [fieldForceData]);

  const verifyFieldForceHandler = () => {
    dispatch(
      fieldForceBulkVerify(
        {  
          vendor_id, 
          division_id,
          fieldForceData: verifiedList},
        onVerifySuccess,
        onVerifyError
      )
    );
  };

  const searchHandler = (e) => {
    let newArr = fieldForceData.fieldForceData.filter(
      (fieldForce) =>
        fieldForce.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        fieldForce.employee_id
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setBulkUploadData(newArr);
  };


  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setShowUnablePopup(false);
    setFFErrorModal(true);
    setIsCheckAll(false);
  }

  const onVerifySuccess = (data) => {
    console.log(data, "upload");
    setFfBluckStatusData(data?.data?.data);
    ShowClose();
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    if (data.data.message == "unable to create field force user" ||
      data.data.message == "unable to create field force users") {
      setShowUnablePopup(true);
      setShowUnableMsg(str_modify);
    } else {
      setShowSuccessPopup(true);
      setShowSuccessMsg(str_modify);
    }
    setTimeout(timeOutFnc, 2000);
    setVerifiedList([]);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onVerifyError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setVerifiedList([]);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={ShowClose}
        dialogClassName="field-force-table-container col-10 px-5 mx-auto"
        size=""
      >
        <ModalHeader className="d-flex justify-content-between align-items-center">
          <div>
            <span className="fn_Nunito fw_600 fs_18">
              Field Force User Data -
              <span className="fw_500">
                {fieldForceData?.original_name?.length > 35
                  ? fieldForceData?.original_name?.substring(0, 35) + "..."
                  : fieldForceData?.original_name}
              </span>
            </span>
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            <AdminButton
              background="#E7E5FF"
              width="100px"
              type="button"
              onClick={() => {
                ShowClose();
              }}
            >
              CLOSE
            </AdminButton>
            <AdminButton
              background="#2AD3E7"
              color="white"
              width="100px"
              type="button"
              disabled={verifiedList?.length == 0}
              onClick={verifyFieldForceHandler}
            >
              <span className="m-2">VERIFY</span>
            </AdminButton>
          </div>
        </ModalHeader>
        <ModalBody className="text-nowrap">
          <div className="modal-table-container">
            <div className="search-container d-flex justify-content-between ">
              <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                <img className="styled-SVG" src={searcher} color={""} />
                <input
                  className="search-input fs_14 fw_400 px-2"
                  type="text"
                  placeholder="Search Field Force user by Field Force Name..."
                  onChange={searchHandler}
                />
              </div>
            </div>
            <div
              className="table-container text-nowrap"
              style={{ minHeight: "36rem", maxHeight: "36rem" }}
            >
              <table className="main-table">
                <tr className="table-rows color_747f9e">
                  <th className="table-heads " width={"4%"}>
                    <input
                      type={"checkbox"}
                      className="CheckBox my-1"
                      value="Full order forward from CFA"
                      name="ReimbursementCheck"
                      checked={isCheckAll}
                      onChange={checkAll}
                    />
                  </th>
                  <th className="table-heads " width={"4%"}>
                    SL. No.
                  </th>
                  <th className="table-heads " width={"10%"}>
                    Company's Employee ID
                  </th>
                  <th className="table-heads " width={"14%"}>
                    Employee Name
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Designation
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Mobile
                  </th>
                  <th className="table-heads " width={"10%"}>
                    Division
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Head Quaters
                  </th>
                  <th className="table-heads " width={"10%"}>
                    State
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Actions
                  </th>
                </tr>
                {bulkUploadData?.length > 0 &&
                  bulkUploadData?.map((eachRow, index) => (
                    <FieldForceVerificationTableData
                      data={eachRow}
                      key={index}
                      index={index}
                      checkedFieldForce={checkedFieldForce}
                      isChecked={verifiedList.includes(eachRow)}
                    />
                  ))}
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <FieldForceBulkErrorModal
        show={FFErrorModal}
        ShowClose={() => setFFErrorModal(false)}
        ffBluckStatusData={ffBluckStatusData}
      />
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <UnablePopup
        show={showUnablePopup}
        title={showUnableMsg} />
    </>
  );
};

export default FieldForceDataVerificationModal;
