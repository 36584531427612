import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import "../customer/customer.css";
import ApporveRejectModal from "../../../components/modals/approve-reject-modal";
import { changeDistriRequestView } from "../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import moment from "moment";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const CustomerDistributorReqView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [rejectAppName, setRejectName] = useState("");
  const [approveShow, setApproveShow] = useState(false);
  const [customerDistriViewData, setCustomerDistriViewData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(changeDistriRequestView(params.id, onSuccess, onError));
    setLoading(true);
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setCustomerDistriViewData(data.data.data);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const DoctorBasicDetails = [
    {
      heading: "Jivini’s Doctor Code",
      value: customerDistriViewData?.doctor?.code
        ? customerDistriViewData?.doctor?.code
        : "--",
    },
    {
      heading: "Doctor Name",
      value: customerDistriViewData?.doctor?.name
        ? customerDistriViewData?.doctor?.name
        : "--",
    },
    {
      heading: "Pharmacy Type",
      value: customerDistriViewData?.doctor?.pharmacy_type
        ? customerDistriViewData?.doctor?.pharmacy_type
        : "--",
    },
    {
      heading: "Qualification",
      value: customerDistriViewData?.doctor?.qualification
        ? customerDistriViewData?.doctor?.qualification
        : "--",
    },
    {
      heading: "Specialization",
      value: customerDistriViewData?.doctor?.specialization?.name
        ? customerDistriViewData?.doctor?.specialization?.name
        : "--",
    },
    {
      heading: "MCI Number",
      value: customerDistriViewData?.doctor?.medical_council_registration_number
        ? customerDistriViewData?.doctor?.medical_council_registration_number
        : "--",
    },
    {
      heading: "Onboarded MR",
      value: customerDistriViewData?.doctor?.onboardingFieldforce
        ?.fieldForceUser?.name
        ? customerDistriViewData?.doctor?.onboardingFieldforce?.fieldForceUser
            ?.name
        : "--",
    },
    {
      heading: "Onboarded Date & Time",
      value: customerDistriViewData?.doctor?.onboardingFieldforce?.createdAt
        ? moment(
            customerDistriViewData?.doctor?.onboardingFieldforce?.createdAt
          ).format("DD MMM YYYY HH:mm A")
        : "",
    },
  ];

  const DoctorContactDetails = [
    {
      heading: "Mobile Number",
      value: customerDistriViewData?.doctor?.mobile
        ? customerDistriViewData?.doctor?.mobile
        : "--",
    },
    {
      heading: "Alternate Mobile Number",
      value: customerDistriViewData?.doctor?.alternate_mobile
        ? customerDistriViewData?.doctor?.alternate_mobile
        : "--",
    },
    {
      heading: "Email Address",
      value: customerDistriViewData?.doctor?.email
        ? customerDistriViewData?.doctor?.email
        : "--",
    },
    {
      heading: "Address",
      value: customerDistriViewData?.doctor?.address?.address_one
        ? customerDistriViewData?.doctor?.address?.address_one
        : "--",
    },
    {
      heading: "Landmark",
      value: customerDistriViewData?.doctor?.address?.landmark
        ? customerDistriViewData?.doctor?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      value: customerDistriViewData?.doctor?.state?.name
        ? customerDistriViewData?.doctor?.state?.name
        : "--",
    },
    {
      heading: "District",
      value: customerDistriViewData?.doctor?.district?.name
        ? customerDistriViewData?.doctor?.district?.name
        : "--",
    },
    {
      heading: "City",
      value: customerDistriViewData?.doctor?.city?.name
        ? customerDistriViewData?.doctor?.city?.name
        : "--",
    },
    {
      heading: "Pincode",
      value: customerDistriViewData?.doctor?.pincode
        ? customerDistriViewData?.doctor?.pincode
        : "--",
    },
  ];

  const PharmacyBasicData = [
    {
      heading: "Jivini’s Pharmacy Code",
      title: customerDistriViewData?.pharmacy?.code
        ? customerDistriViewData?.pharmacy?.code
        : "--",
    },
    {
      heading: "Pharmacy Name",
      title: customerDistriViewData?.pharmacy?.pharmacy_name
        ? customerDistriViewData?.pharmacy?.pharmacy_name
        : "--",
    },
    {
      heading: "Drug License Number - 20A",
      title: customerDistriViewData?.pharmacy?.drug_licence_number_20_a
        ? customerDistriViewData?.pharmacy?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug License Number - 20B",
      title: customerDistriViewData?.pharmacy?.drug_licence_number_20_b
        ? customerDistriViewData?.pharmacy?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "GST Number",
      title: customerDistriViewData?.pharmacy?.gst_number
        ? customerDistriViewData?.pharmacy?.gst_number
        : "--",
    },
    // {
    //   heading: "Onboarded By",
    //   title: "",
    // },
    // {
    //   heading: "Onboarded Date & Time",
    //   title: "12 sep 2022, 7:33 PM ",
    // },
    // {
    //   heading: "Linked Manufacturer",
    //   title: "--",
    // },
  ];

  const PharmacyContactData = [
    {
      heading: "Mobile Number",
      title: `+${
        customerDistriViewData?.pharmacy?.country_code
          ? customerDistriViewData?.pharmacy?.country_code
          : ""
      } ${
        customerDistriViewData?.pharmacy?.mobile
          ? customerDistriViewData?.pharmacy?.mobile
          : "--"
      }`,
    },
    {
      heading: "Alternate Mobile Number",
      title: `+${
        customerDistriViewData?.pharmacy?.country_code
          ? customerDistriViewData?.pharmacy?.country_code
          : ""
      } ${
        customerDistriViewData?.pharmacy?.alternate_mobile
          ? customerDistriViewData?.pharmacy?.alternate_mobile
          : "--"
      }`,
    },
    {
      heading: "Email Address",
      title: customerDistriViewData?.pharmacy?.email
        ? customerDistriViewData?.pharmacy?.email
        : "--",
    },
    {
      heading: "Address",
      title: `${
        customerDistriViewData?.pharmacy?.address?.address_one
          ? customerDistriViewData?.pharmacy?.address?.address_one
          : "--"
      }${customerDistriViewData?.pharmacy?.address?.address_two ? "," : ""} ${
        customerDistriViewData?.pharmacy?.address?.address_two
          ? customerDistriViewData?.pharmacy?.address?.address_two
          : ""
      }${customerDistriViewData?.pharmacy?.address?.address_three ? "," : ""} ${
        customerDistriViewData?.pharmacy?.address?.address_three
          ? customerDistriViewData?.pharmacy?.address?.address_three
          : ""
      }`,
    },
    {
      heading: "Landmark",
      title: customerDistriViewData?.pharmacy?.address?.landmark
        ? customerDistriViewData?.pharmacy?.address?.landmark
        : "--",
    },
    {
      heading: "State",
      title: customerDistriViewData?.pharmacy?.state?.name
        ? customerDistriViewData?.pharmacy?.state?.name
        : "--",
    },
    {
      heading: "District",
      title: customerDistriViewData?.pharmacy?.district?.name
        ? customerDistriViewData?.pharmacy?.district?.name
        : "--",
    },
    {
      heading: "City",
      title: customerDistriViewData?.pharmacy?.city?.name
        ? customerDistriViewData?.pharmacy?.city?.name
        : "--",
    },
    {
      heading: "Pincode",
      title: customerDistriViewData?.pharmacy?.pincode
        ? customerDistriViewData?.pharmacy?.pincode
        : "--",
    },
  ];

  const ManufacturerDetails = [
    {
      heading: "Manufacturer Name",
      value: customerDistriViewData?.vendor?.name
        ? customerDistriViewData?.vendor?.name
        : "--",
    },
    {
      heading: "Mobile Number",
      value: customerDistriViewData?.vendor?.mobile
        ? customerDistriViewData?.vendor?.mobile
        : "--",
    },
    {
      heading: "Drug License 20A ",
      value: customerDistriViewData?.vendor?.drug_licence_1
        ? customerDistriViewData?.vendor?.drug_licence_1
        : "--",
    },
    {
      heading: "Drug License 20B",
      value: customerDistriViewData?.vendor?.drug_licence_2
        ? customerDistriViewData?.vendor?.drug_licence_2
        : "--",
    },
    {
      heading: "CIN. No",
      value: customerDistriViewData?.vendor?.cin_number
        ? customerDistriViewData?.vendor?.cin_number
        : "--",
    },
    {
      heading: "FSSAI Number",
      value: customerDistriViewData?.vendor?.fssai_number
        ? customerDistriViewData?.vendor?.fssai_number
        : "--",
    },
    {
      heading: "GSTIN Number",
      value: customerDistriViewData?.vendor?.gstin_number
        ? customerDistriViewData?.vendor?.gstin_number
        : "--",
    },
    {
      heading: "PAN Number",
      value: customerDistriViewData?.vendor?.pan_number
        ? customerDistriViewData?.vendor?.pan_number
        : "--",
    },
  ];

  const CurrentDistributorDetails = [
    {
      heading: "Distributor ID",
      value: customerDistriViewData?.distributor?.distributor_id
        ? customerDistriViewData?.distributor?.distributor_id
        : "--",
    },
    {
      heading: "Distributor Name",
      value: customerDistriViewData?.distributor?.firm_name
        ? customerDistriViewData?.distributor?.firm_name
        : "--",
    },
    {
      heading: "Mobile Number",
      value: customerDistriViewData?.distributor?.mobile
        ? customerDistriViewData?.distributor?.mobile
        : "--",
    },
    {
      heading: "Drug license Number - 20A",
      value: customerDistriViewData?.distributor?.drug_licence_number_20_a
        ? customerDistriViewData?.distributor?.drug_licence_number_20_a
        : "--",
    },
    {
      heading: "Drug license Number - 20B",
      value: customerDistriViewData?.distributor?.drug_licence_number_20_b
        ? customerDistriViewData?.distributor?.drug_licence_number_20_b
        : "--",
    },
    {
      heading: "FSSAI Number",
      value: customerDistriViewData?.distributor?.fssai_number
        ? customerDistriViewData?.distributor?.fssai_number
        : "--",
    },
    {
      heading: "GST Number",
      value: customerDistriViewData?.distributor?.gst_number
        ? customerDistriViewData?.distributor?.gst_number
        : "--",
    },
    {
      heading: "No. of Orders",
      value: customerDistriViewData?.distributor?.noOfOrders
        ? customerDistriViewData?.distributor?.noOfOrders
        : "--",
    },
    {
      heading: "Linked Manufacturer",
      value:
        customerDistriViewData?.distributor?.vendorDistributors?.length > 0 &&
        customerDistriViewData?.distributor?.vendorDistributors.map(
          (ele, index) => {
            return (
              <span key={index}>
                {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                {ele?.vendor?.name ? ele?.vendor?.name : "--"}
              </span>
            );
          }
        ),
    },
  ];

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (customerDistriViewData.doctor?.code
          ? ` / ${customerDistriViewData.doctor?.code} - view`
          : " / view")}
    </span>
  );

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        {customerDistriViewData.approval == "pending" && (
          <div className=" d-flex justify-content-between m-1 mb-2">
            <AdminButton
              background="#7065E4"
              border="0px"
              color="white"
              width="100px"
              type="button"
              onClick={() => {
                setApproveShow(true);
                setRejectName("APPROVE");
              }}
            >
              <span className="mx-2">APPROVE</span>
            </AdminButton>
            <AdminButton
              background="#FF6F5B"
              border="0px"
              color="white"
              width="100px"
              type="button"
              onClick={() => {
                setApproveShow(true);
                setRejectName("Reject");
              }}
            >
              <span className="mx-2">REJECT</span>
            </AdminButton>
          </div>
        )}
      </div>

      {loading == true ? (
        <div className="spinner-container">
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <>
          <div className="fn_Nunito scrolling">
            {customerDistriViewData?.doctor?.is_basic_details == true && (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Doctor’s Basic Details
                  </label>
                </div>
                <div className="row mt-3 pt-3 ps-5 ">
                  {DoctorBasicDetails.map((item, index) => (
                    <div
                      key={index}
                      className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                    >
                      <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p
                        className={`fs_15 fn_Nunito  fw_500 color_262c3d text-capitalize`}
                      >
                        {item.value}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {customerDistriViewData?.doctor?.is_contact_details == true && (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Doctor’s Contact Details
                  </label>
                </div>
                <div className="row mt-3 pt-3 ps-5 ">
                  {DoctorContactDetails.map((item, index) => (
                    <div
                      key={index}
                      className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                    >
                      <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                        {item.value}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {customerDistriViewData?.pharmacy?.is_details == true && (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Pharmacy Basic Details
                  </label>
                </div>
                <div className="row mt-3 pt-3 ps-5 ">
                  {PharmacyBasicData.map((item, index) => (
                    <div
                      key={index}
                      className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                    >
                      <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p
                        className={`fs_15 fn_Nunito  fw_500 color_262c3d text-capitalize`}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {customerDistriViewData?.pharmacy?.is_contact_details == true && (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Pharmacy Contact Details
                  </label>
                </div>
                <div className="row mt-3 pt-3 ps-5 ">
                  {PharmacyContactData.map((item, index) => (
                    <div
                      key={index}
                      className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                    >
                      <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">
                  Manufacturer Details
                </label>
              </div>
              <div className="row mt-3 pt-3 ps-5 ">
                {ManufacturerDetails.map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                  >
                    <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            </>
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">
                  Current Distributor Details
                </label>
              </div>
              <div className="row mt-3 pt-3 ps-5 ">
                {CurrentDistributorDetails.map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 col-sm-6 col-md-6 col-xxl-3 mx-3 px-3 distributor-data-container"
                  >
                    <h5 className="color_b0b3c7 text-nowrap fs_14 fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={`fs_15 fn_Nunito  fw_500 color_262c3d`}>
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            </>
          </div>
        </>
      )}

      <ApporveRejectModal
        status={rejectAppName == "APPROVE" ? "approved" : "rejected"}
        title={rejectAppName == "APPROVE" ? "approve" : "reject"}
        btnName={rejectAppName == "APPROVE" ? "APPROVE" : "REJECT"}
        btnColor={rejectAppName == "APPROVE" ? "#2AD3E7" : "#FF6F5B"}
        show={approveShow}
        handleClose={() => setApproveShow(false)}
      />
    </MainLayout>
  );
};

export default CustomerDistributorReqView;
