import React from "react";
import { useNavigate } from "react-router-dom";
import errorimage from "./../assets/images/404error.png";
import leftArrow from "./../assets/images/left-arrow.png"
import "./styles/styles.css"

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center error_main_conatiner fn_Nunito">
      <div className="text-center pb-5 mb-5 mb-sm-0 pb-sm-0">
        <img src={errorimage} className="errorimage" />
        <div className="mt-4">
          <span className="fw_700 fs_25 fs_sm_20 mt-2 ">Page Not Found</span>
        </div>

        <div>
          <p className="col-8 col-sm-7 mx-auto color_747f9e fs_16 fs_sm_12">
            The link you clicked may be broken or the page may have been removed
            or renamed.
          </p>
        </div>
         <button className="error_btn text-white fs_12 px-4 py-2 mt-3 " onClick={() => navigate(-1)}><img src={leftArrow} height="12px" className="mb-1 me-2 "/>GO BACK</button>
      
      </div>
    </div>
  );
};

export default PageNotFound;
