import React from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const DivisionListTableData = ({
  data,
  index,
  setActiveDivision,
  setSelectedDivisionData,
  currentPage,
  user_type,
  userData,
  setOnEnableShow,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const vendorId = params?.id.split("&")?.[0];
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];
  console.log(pathType, "sssss");

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }
  return (
    <tr className="table-rows1 color_262c3d" key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data?.name ? data?.name : "--"}</td>
      <td className="table-each-row-data">
        {data?.offer_type
          ? data?.offer_type.charAt(0).toUpperCase() + data?.offer_type.slice(1)
          : "--"}
      </td>


      <td
        className="table-each-row-data text-truncate"
        style={{ maxWidth: "250px" }}
      >
        {data?.reimbursement_method ? reimbursementOptions[data?.reimbursement_method] : "--"}
      </td>

      <td className="table-each-row-data text-capitalize">{`${data?.order_limit ? data?.order_limit : "--"
        } - ₹ ${data?.order_amount}`}</td>
      {data?.status == true ? (
        <td className="table-each-row-data text_green">Active</td>
      ) : (
        <td className="table-each-row-data text_red">Inactive</td>
      )}
      <td className="table-each-row-data">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendorId}&divisions&view&${data?.id}`
              );
            }}
          >
            <span className=""> View</span>
          </button>
          <>
            {(user_type == "super_admin" && userData?.write) ||
              user_type == "admin" ? (
              <>
                <span className="vr vr_line mt-1"></span>
                <button
                  className="button-edit1"
                  type="button"
                  onClick={() => {
                    navigate(
                      `/manufacturer/${pathType}/view/${vendorId}&divisions&edit&${data?.id}`
                    );
                  }}
                >
                  Edit
                </button>
                <span className="vr  vr_line mt-1 "></span>
                <button
                  className={
                    data.status == true ? `button-disable` : `button-enable`
                  }
                  type="button"
                  color={"green"}
                  onClick={() => {
                    setOnEnableShow(true);
                    setSelectedDivisionData(data);
                  }}
                >
                  {data?.status == true ? "disable" : "enable"}
                </button>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      </td>
    </tr>
  );
};

export default DivisionListTableData;
