import React from "react";
// import "./orderTable.css";
import styled from "@emotion/styled";

export const TableContainer = styled("div")(
  {
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    maxWidth: "45rem",
    height: "25rem",
    overflowY: "scroll",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

export const TableFooter = styled("div")(
  {
    background: "#2AD3E7",
    border: "1px solid #e7e5ff",
    borderBottomLeftRadius: " 15px",
    borderBottomRightRadius: "15px",
    maxWidth: "45rem",
    height: "3rem",
  },
  (props) => ({
    maxWidth: props.maxWidth,
  })
);

const OrdersTable = ({ maxWidth = "60rem", header, total, data }) => {
  return (
    <div className="">
      <TableContainer maxWidth={maxWidth}>
        <table className="main-table">
          <tr className="table-rows">
            <th className="order-table-heads color_262c3d" width={"10%"}>
              Product Name
            </th>
            <th className="order-table-heads color_262c3d" width={"10%"}>
              Distributors
            </th>
            <th className="order-table-heads color_262c3d" width={"10%"}>
              Qty
            </th>
            <th className="order-table-heads color_262c3d" width={"10%"}>
              Offer
            </th>
            <th className="order-table-heads color_262c3d" width={"8%"}>
              Price
            </th>
          </tr>
          {data.orderProducts?.length > 0 &&
            data.orderProducts?.map((ele, index) => (
              <tr className="table-rows1" key={index}>
                <td className="order-table-each-row-data">
                  {ele?.product?.product_name}
                </td>
                <td className="order-table-each-row-data">
                  {ele?.distributor?.firm_name}
                </td>
                <td className="order-table-each-row-data">{ele?.quantity}</td>
                <td className="order-table-each-row-data">
                  {ele?.offer_type == "price" && `₹ ${ele?.after_off}`}
                  {ele?.offer_type == "quantity" && `${ele?.offer_qty}`}
                  {ele?.offer_type == "discount" && `₹ ${ele?.after_off}`}
                </td>
                <td className="order-table-each-row-data">₹ {parseFloat(ele?.price).toFixed(2)}</td>
              </tr>
            ))}
        </table>
      </TableContainer>
      <TableFooter
        className="d-flex justify-content-between align-items-center px-4 table-footer"
        maxWidth={maxWidth}
      >
        <div>
          <label className="text-white ">Total</label>
        </div>
        <div>
          <label className="text-white fw_600 mx-5">
            ₹ {parseFloat(total).toFixed(2)}
          </label>
        </div>
      </TableFooter>
    </div>
  );
};

export default OrdersTable;
