import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/icons/back.svg";
import edit from "../../../assets/icons/edit.svg";
import delet from "../../../assets/icons/delete-bin.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import VersionHistoryBtn, {
  AdminButton,
} from "../../../components/buttons/versionHistoryBtn";
import VersionHistoryResBtn from "../../../components/buttons/versionHistoryResBtn";
import "../manufacturer.css";
import { useDispatch } from "react-redux";
import {
  invoiceDetails,
  loginToPortalManufacturer,
  manufacturerCreateDesignation,
  manufacturerDelete,
  manufacturerDetail,
  manufacturerEnableDisable,
  toggleAnalytics,
} from "../../../redux/actions/manufacturerActions";
import DeleteModal from "../../../components/modals/deleteModal";
import EnableDisableModal from "../../../components/modals/enableDisableModal";
import GeneratePoModal from "../../../components/modals/manufacturer/generatePoModal";
import GenerateInvoiceModal from "../../../components/modals/manufacturer/invoice/generateInvoiceModal";
import ChangeStatusModal from "../../../components/modals/manufacturer/invoice/changeStatusModal";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import Switch from "react-switch";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import GevSideTopBar from "../../../components/manufacturer/gevSideTopBar";
import GevViewContainer from "../../../components/manufacturer/gevViewContainer";

const ManufacturerView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const vendorId = params?.id.split("&")?.[0];
  const activePath = params?.id.split("&")?.[1];
  const activeSubPath = params?.id.split("&")?.[2];
  const subId = params?.id.split("&")?.[3];
  console.log(activePath, "active");
  console.log(activeSubPath, "active");

  const [manufacturerViewData, setManufacturerViewData] = useState([]);
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [generatePOShow, setGeneratePOShow] = useState(false); // GENERATE PO
  const [generateInvoiceShow, setGenerateInvoiceShow] = useState(false); // GENERATE INVOICE
  const [changeStatus, setChangeStatus] = useState(false); // INVOICE CHANGE STATUS
  const [invoiceDetailsData, setinvoiceDetailsData] = useState(""); //PO INVOICE DETAILS
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [toggleSuccessPopup, setToggleSuccessPopup] = useState(false);
  const [toggleSuccessMsg, setToggleSuccessMsg] = useState();
  const [toggleErrorPopup, setToggleErrorPopup] = useState(false);
  const [toggleErrorMsg, setToggleErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  //MAUNFACTURER VIEW
  useEffect(() => {
    fetchManufacturerView();
  }, []);

  const fetchManufacturerView = () => {
    setLoading(true);
    dispatch(manufacturerDetail(vendorId, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setManufacturerViewData(data.data.data);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const enableDisableFunc = (id) => {
    console.log(id);
    dispatch(
      manufacturerEnableDisable(
        id,
        onEnableDisableSuccess,
        onEnableDisableError
      )
    );
  };

  const timeOutFnc1 = () => {
    setShowSuccessPopup(false);
    setOnEnableShow(false);
    fetchManufacturerView();
  };

  const onEnableDisableSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc1, 2000);
  };

  const onEnableDisableError = (err) => {
    console.log(err);
  };
  const delFunc = () => {
    dispatch(manufacturerDelete(vendorId, onDelSuccess, onDeleteError));
  };

  const timeOutFnc2 = () => {
    setShowSuccessPopup1(false);
    navigate("/manufacturer/subscibe-list/1");
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup1(true);
    const str1 = data.data.message;
    const str_modify1 = str1.charAt(0).toUpperCase() + str1.slice(1);
    setShowSuccessMsg1(str_modify1);
    setTimeout(timeOutFnc2, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onDeleteError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const loginToPortalFunc = () => {
    dispatch(
      loginToPortalManufacturer(vendorId, onNavigateSuccess, onNavigateError)
    );
  };

  const onNavigateSuccess = (data) => {
    console.log(data.data.data.url);
    window.open(data?.data?.data?.url, "_blank");
  };

  const onNavigateError = (err) => {
    console.log(err);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        ` /  ${manufacturerViewData?.vendor?.name} - VIEW `}
    </span>
  );

  const analyticsAccess = () => {
    console.log(vendorId, "lssss");
    dispatch(toggleAnalytics(vendorId, onToggleSuccess, onToggleError));
  };

  const toggleTimeOutFnc = () => {
    setToggleSuccessPopup(false);
    fetchManufacturerView();
  };

  const closeToggleErrorPopup = () => {
    setToggleErrorPopup(false);
  };

  const onToggleSuccess = (data) => {
    console.log(data.data.message);
    setToggleSuccessPopup(true);
    setToggleSuccessMsg(data.data.message);
    setTimeout(toggleTimeOutFnc, 2000);
  };

  const onToggleError = (err) => {
    console.log(err);
    setToggleErrorPopup(true);
    setToggleErrorMsg(err.data.message);
  };

  const backFnc = () => {
    if (
      activeSubPath == "list" ||
      activeSubPath == "" ||
      activePath == "po" || 
      activeSubPath == "uninvocedList" || 
      activeSubPath == "invocedList"
    ) {
      navigate("/manufacturer/subscibe-list/1");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {loading == true ? (
        <div className="view-spinner-container">
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <MainLayout breadcrumbData={Breadcrumb}>
          <div className="search-container d-flex justify-content-between align-items-center">
            <div
              className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
              onClick={() => backFnc()}
            >
              <img src={back} alt="back_btn" />
              <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                BACK
              </span>
            </div>

            <>
              {activePath == "manufacturer-details" && (
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <label>Analytics Access</label>
                    <Switch
                      checked={manufacturerViewData?.vendor?.is_analytics}
                      onChange={() => analyticsAccess()}
                      className="mx-3"
                      onColor="#d3d6eb"
                      offColor="#d3d6eb"
                      onHandleColor="#7065E4"
                      offHandleColor="#747F9E"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                  </div>
                  <VersionHistoryResBtn
                    link={`/manufacturer/subscibe-list/version-view/${manufacturerViewData?.vendor?.id}`}
                  />
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      <AdminButton
                        className="d-none d-xl-block"
                        background="#7065E4"
                        color="white"
                        width="90px"
                        onClick={() => {
                          if (manufacturerViewData?.vendor.status == "draft") {
                            dispatch(
                              manufacturerCreateDesignation(
                                { vendor_id: manufacturerViewData?.vendor?.id },
                                () => {},
                                () => {}
                              )
                            );
                            navigate(
                              "/manufacturer/subscibe-list/create/designation",
                              {
                                state: true,
                              }
                            );
                          } else
                            navigate(
                              `/manufacturer/subscibe-list/edit/${manufacturerViewData?.vendor.id}`
                            );
                        }}
                      >
                        <span className="m-2">EDIT</span>
                      </AdminButton>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <button
                          className="edit-small-btn py-1 px-3 mx-2 d-xl-none"
                          onClick={() =>
                            navigate(
                              `/manufacturer/edit/${manufacturerViewData?.vendor?.id}`
                            )
                          }
                        >
                          <img src={edit} alt="edit" className="py-1" />
                        </button>
                      </OverlayTrigger>

                      {manufacturerViewData?.vendor?.status != "draft" && (
                        <AdminButton
                          background="#2AD3E7"
                          color="white"
                          width="140px"
                          type="button"
                          onClick={() => loginToPortalFunc()}
                        >
                          <span className="">Login to Portal</span>
                        </AdminButton>
                      )}

                      <button
                        className="delete-small-btn py-2 px-3 me-2 d-xl-none"
                        onClick={() => setDeleteShow(true)}
                      >
                        <img src={delet} alt="delete" />
                      </button>
                      {manufacturerViewData?.vendor?.status != "draft" && (
                        <AdminButton
                          color={
                            manufacturerViewData?.vendor?.status == "active"
                              ? "#FF6F5B"
                              : "#24D626"
                          }
                          border={
                            manufacturerViewData?.vendor?.status == "active"
                              ? "1px solid #FF6F5B"
                              : "1px solid #24D626"
                          }
                          width="110px"
                          onClick={() => setOnEnableShow(true)}
                        >
                          <span className="m-2">
                            {manufacturerViewData?.vendor?.status === "active"
                              ? "DISABLE"
                              : "ENABLE"}
                          </span>
                        </AdminButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <EnableDisableModal
                    show={onEnableShow}
                    handleClose={() => setOnEnableShow(false)}
                    status={manufacturerViewData?.vendor?.status}
                    title={`manufacturer  ${manufacturerViewData?.vendor?.employee_id} ?`}
                    id={vendorId}
                    enableDisableFunc={enableDisableFunc}
                  />
                </div>
              )}
              {activePath == "designations" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      {activeSubPath == "list" && (
                        <AdminButton
                          className="d-none d-xl-block"
                          background="#7065E4"
                          color="white"
                          width="110px"
                          onClick={() => {
                            navigate(
                              `/manufacturer/subscibe-list/view/${vendorId}&designations&add`
                            );
                          }}
                        >
                          <span className="m-2">ADD MORE</span>
                        </AdminButton>
                      )}
                      {activeSubPath == "view" && (
                        <>
                          <AdminButton
                            className="d-none d-xl-block"
                            background="#7065E4"
                            color="white"
                            width="80px"
                            onClick={() => {
                              navigate(
                                `/manufacturer/subscibe-list/view/${vendorId}&designations&edit&${subId}`
                              );
                            }}
                          >
                            <span className="m-2">EDIT</span>
                          </AdminButton>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {activePath == "headQuarters" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      {activeSubPath == "list" && (
                        <AdminButton
                          className="d-none d-xl-block"
                          background="#7065E4"
                          color="white"
                          width="110px"
                          onClick={() => {
                            navigate(
                              `/manufacturer/subscibe-list/view/${vendorId}&headQuarters&add`
                            );
                          }}
                        >
                          <span className="m-2">ADD MORE</span>
                        </AdminButton>
                      )}
                      {activeSubPath == "view" && (
                        <>
                          <AdminButton
                            className="d-none d-xl-block"
                            background="#7065E4"
                            color="white"
                            width="80px"
                            onClick={() => {
                              navigate(
                                `/manufacturer/subscibe-list/view/${vendorId}&headQuarters&edit&${subId}`
                              );
                            }}
                          >
                            <span className="m-2">EDIT</span>
                          </AdminButton>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {activePath == "divisions" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      {activeSubPath == "list" && (
                        <AdminButton
                          className="d-none d-xl-block"
                          background="#7065E4"
                          color="white"
                          width="110px"
                          onClick={() => {
                            navigate(
                              `/manufacturer/subscibe-list/view/${vendorId}&divisions&add`
                            );
                          }}
                        >
                          <span className="m-2">ADD MORE</span>
                        </AdminButton>
                      )}
                      {activeSubPath == "view" && (
                        <>
                          <AdminButton
                            className="d-none d-xl-block"
                            background="#7065E4"
                            color="white"
                            width="80px"
                            onClick={() => {
                              navigate(
                                `/manufacturer/subscibe-list/view/${vendorId}&divisions&edit&${subId}`
                              );
                            }}
                          >
                            <span className="m-2">EDIT</span>
                          </AdminButton>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {activePath == "products" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      {activeSubPath == "list" && (
                        <AdminButton
                          className="d-none d-xl-block"
                          background="#7065E4"
                          color="white"
                          width="110px"
                          onClick={() => {
                            navigate(
                              `/manufacturer/subscibe-list/view/${vendorId}&products&add`
                            );
                          }}
                        >
                          <span className="m-2">CREATE</span>
                        </AdminButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {activePath == "po" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      <AdminButton
                        background="#7065E4"
                        type="button"
                        color="white"
                        width="260px"
                        onClick={() => setGeneratePOShow(true)}
                      >
                        <span className="m-2">GENERATE PURCHASE ORDER</span>
                      </AdminButton>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {activePath == "invoices" && (
                <>
                  {(user_type == "super_admin" && userData?.write) ||
                  user_type == "admin" ? (
                    <>
                      {activeSubPath == "uninvoced" && (
                        <AdminButton
                          background="#7065E4"
                          type="button"
                          color="white"
                          width="180px"
                          onClick={() => setGenerateInvoiceShow(true)}
                        >
                          <span className="m-2">GENERATE INVOICE</span>
                        </AdminButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          </div>
          <div className="d-xxl-flex">
            <GevSideTopBar/>
            <div className="view-main-container">
              <>
                <GevViewContainer
                  manufacturerViewData={manufacturerViewData.vendor}
                  totalOrders={manufacturerViewData.totalOrders}
                />
              </>
            </div>
          </div>

          <DeleteModal
            delFunc={delFunc}
            show={deleteShow}
            handleClose={() => setDeleteShow(false)}
          />
          <GeneratePoModal
            manufacturerViewData={manufacturerViewData}
            show={generatePOShow}
            handleClose={() => setGeneratePOShow(false)}
          />
          <GenerateInvoiceModal
            manufacturerViewData={manufacturerViewData}
            show={generateInvoiceShow}
            handleClose={() => setGenerateInvoiceShow(false)}
          />         
          <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
          <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
          <SuccessPopup show={toggleSuccessPopup} title={toggleSuccessMsg} />
          <ErrorPopup
            show={showErrorPopup}
            title={showErrorMsg}
            handleClose={closeErrorPopup}
          />
          <ErrorPopup
            show={toggleErrorPopup}
            title={toggleErrorMsg}
            handleClose={closeToggleErrorPopup}
          />
        </MainLayout>
      )}
    </>
  );
};

export default ManufacturerView;
