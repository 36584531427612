import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { distributorVersionList } from "../../redux/actions/distributorActions";
import DistributorVersionTableData from "./distributor-version-table-data";
import "./distributor.css";
import UpArrow from "../../assets/icons/filterArrowUp.svg";
import DownArrow from "../../assets/icons/filterArrowDown.svg";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const DistributorVersionTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [distributorVesrionList, setDistributorVersionList] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      setLoading(true);
      dispatch(
        distributorVersionList({ search, page, status }, onSuccess, onError)
      );
    }
  }, [search]);

  useEffect(() => {
    if (status !== null) {
      setPage(1);
      setLoading(true);
      dispatch(
        distributorVersionList({ search, page, status }, onSuccess, onError)
      );
    }
  }, [status]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      distributorVersionList({ page, search, status }, onSuccess, onError)
    );
  }, [page, status]);

  const onSuccess = (data) => {
    console.log(data.data);
    setDistributorVersionList(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Distributor ID
            </th>
            <th className="table-heads " width={"10%"}>
              Distributor Name
            </th>
            <th className="table-heads text-center" width={"10%"}>
              No. of Orders
            </th>
            <th className="table-heads text-center" width={"15%"}>
              No. of Manufacturer
            </th>
            <th className="table-heads text-nowrap" width={"8%"}>
              <span onClick={() => setOpenStatus(!openStatus)}>
                Status
                {openStatus ? (
                  <img src={UpArrow} className="mb-1 mx-1" />
                ) : (
                  <img src={DownArrow} className=" mx-1" />
                )}
              </span>
              {openStatus ? (
                <div className=" position-relative">
                  <div className="position-absolute status-container d-flex gap-3 px-3">
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2"
                        type="radio"
                        id="active"
                        name="status"
                        value={true}
                        checked={status == "true"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("active");
                        }}
                      />
                      <label
                        htmlFor="active"
                        className={
                          active === "active"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Active
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        className="radio me-2"
                        type="radio"
                        id="inactive"
                        name="status"
                        value={false}
                        checked={status == "false"}
                        onClick={(e) => {
                          setOpenStatus(!openStatus);
                          setStatus(e.target.value);
                          setActive("inactive");
                        }}
                      />
                      <label
                        htmlFor="inactive"
                        className={
                          active === "inactive"
                            ? "color_262c3d cursor_pointer"
                            : "color_747f9e cursor_pointer"
                        }
                      >
                        Inactive
                      </label>
                    </div>
                    <button
                      className="filter-btn px-2 py-0 my-2 fs_12"
                      style={{ height: "28px" }}
                      onClick={() => {
                        setStatus(null);
                        setOpenStatus(!openStatus);
                        setActive("");
                      }}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              ) : null}
            </th>
            <th className="table-heads " width={"10%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : distributorVesrionList?.distributors?.length > 0 ? (
            <>
              {distributorVesrionList?.distributors?.map((eachRow, index) => (
                <DistributorVersionTableData
                  key={eachRow.id}
                  data={eachRow}
                  index={index}
                  currentPage={distributorVesrionList?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {distributorVesrionList?.pagination?.totalPages > 0 && (
          <TablePagination
            data={distributorVesrionList?.pagination}
            nextFunc={() => {
              navigate(
                `/distributors/distributor/version-list/${distributorVesrionList?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/distributors/distributor/version-list/${distributorVesrionList?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
    </>
  );
};

export default DistributorVersionTable;
