import { api } from "./api";

export const loginApi = (params) => {
  return api.post("/admin/auth/login", JSON.stringify(params));
};

export const forgetPasswordApi = (params) => {
  return api.post("/admin/auth/forgot-password", JSON.stringify(params));
};

export const verifyEmail = (params) => {
  return api.post(
    "/admin/auth/verify-reset-password-url",
    JSON.stringify(params)
  );
};

export const createNewPasswordApi = (params) => {
  return api.post("/admin/auth/create-new-password", JSON.stringify(params));
};

export const resetPasswordApi = (params) => {
  return api.post("/admin/auth/reset-password", JSON.stringify(params));
};

export const logoutApi = (params) => {
  return api.delete("/admin/auth/logout", JSON.stringify(params));
};
