import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableContainer from "../../components/layout/table-container/table-container";
import TablePagination from "../../components/Pagination/tablePagination";
import { faqVersionList } from "../../redux/actions/faqActions";
import FaqVersionTableData from "./faq-version-list-table-data";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const FaqVersionTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [faqVersionListData, setfaqVersionListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPage(1);
    fetchFaqVersionList();
  }, [search]);

  useEffect(() => {
    fetchFaqVersionList();
  }, [page]);

  const fetchFaqVersionList = () => {
    dispatch(faqVersionList({ search, page }, onSuccess, onError));
    setLoading(true);
  };

  const onSuccess = (data) => {
    console.log(data.data.data);
    setfaqVersionListData(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              FAQ ID
            </th>
            <th className="table-heads " width={"10%"}>
              FAQ Questions
            </th>
            {/* <th className="table-heads " width={"10%"}>
          FAQ Answers
        </th> */}
            <th className="table-heads " width={"10%"}>
              Created for
            </th>
            <th className="table-heads " width={"10%"}>
              Created By
            </th>
            <th className="table-heads " width={"15%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : faqVersionListData?.faqs?.length > 0 ? (
            <>
              {faqVersionListData?.faqs?.map((eachRow, index) => (
                <FaqVersionTableData
                  data={eachRow}
                  key={eachRow.id}
                  index={index}
                  fetchFaqVersionList={fetchFaqVersionList}
                  currentPage={faqVersionListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      <div className={loading == true ? `loading-event` : ""}>
        {faqVersionListData?.pagination?.totalPages > 0 && (
          <TablePagination
            data={faqVersionListData?.pagination}
            nextFunc={() => {
              navigate(
                `/faq/version-list${faqVersionListData?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/faq/version-list${faqVersionListData?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
    </>
  );
};

export default FaqVersionTable;
