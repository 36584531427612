import React, { useEffect, useState } from "react";
import { PoUninvoicedList } from "../../../../redux/actions/manufacturerActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import searcher from "../../../../assets/icons/searchInput.svg";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";

const UninvoicedList = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [poUninvoicedData, setPoUninvoicedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const vendorId = params?.id.split("&")?.[0];

  useEffect(() => {
    setLoading(true);
    dispatch(PoUninvoicedList({ vendor_id: vendorId }, onSuccess, onError));
  }, []);
  useEffect(() => {
    dispatch(
      PoUninvoicedList({ search, vendor_id: vendorId }, onSuccess, onError)
    );
  }, [search]);

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setPoUninvoicedData(data?.data?.data);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
  };

  return (
    <div className="my-3">
      <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
        <img className="styled-SVG" src={searcher} color={""} />
        <input
          className="search-input fs_14 fw_400 text-dark px-2"
          type="text"
          placeholder="Search invoice here by id ..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="manufacturer-product-table-container">
        <table className="main-table text-nowrap">
          <thead
            style={{ position: "sticky", top: 0, zIndex: 55 }}
            className="bg-white"
          >
            <tr className="table-rows color_747f9e">
              <th className="table-heads py-2" width={"4%"}>
                SL No
              </th>
              <th className="table-heads" width={"10%"}>
                Purchase Order ID
              </th>
              <th className="table-heads " width={"8%"}>
                Offer Type
              </th>
              <th className="table-heads" width={"10%"}>
                Generated Date(From)
              </th>
              <th className="table-heads" width={"10%"}>
                Generated Date(To)
              </th>
              <th className="table-heads" width={"10%"}>
                Purchase Order Value
              </th>
            </tr>
          </thead>
          <tbody style={{ position: "relative" }}>
            {loading == true ? (
              <div className="gev-spinner-container">
                <LoadingSpinnerTable />
              </div>
            ) : (
              <>
                {poUninvoicedData?.length > 0 ? (
                  poUninvoicedData?.map((item, index) => (
                    <tr className="table-rows1">
                      <td className="table-each-row-data">{index + 1}</td>
                      <td className="table-each-row-data">
                        {item?.code ? item?.code : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.offer_type
                          ? item?.offer_type.charAt(0).toUpperCase() +
                            item?.offer_type.slice(1)
                          : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {item?.updatedAt
                          ? moment(item?.updatedAt).format(
                              "DD MMM YYYY HH:mm A"
                            )
                          : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {" "}
                        {item?.createdAt
                          ? moment(item?.createdAt).format(
                              "DD MMM YYYY HH:mm A"
                            )
                          : "--"}
                      </td>
                      <td className="table-each-row-data">
                        {" "}
                        {item?.total_value ? `₹ ${item?.total_value}` : "--"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="1"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UninvoicedList;
