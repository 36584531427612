import { call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ADVERTISEMENT_PHOTO,
  SETTINGS_ADVERTISEMENT_CREATE,
  SETTINGS_ADVERTISEMENT_DELETE,
  SETTINGS_ADVERTISEMENT_LIST,
  SETTINGS_CITY_CREATE,
  SETTINGS_CITY_EDIT,
  SETTINGS_CITY_LIST,
  SETTINGS_DISTRICT_CREATE,
  SETTINGS_DISTRICT_DETAIL,
  SETTINGS_DISTRICT_EDIT,
  SETTINGS_DISTRICT_LIST,
  SETTINGS_PINCODE_CREATE,
  SETTINGS_PINCODE_EDIT,
  SETTINGS_PINCODE_FILTER,
  SETTINGS_PINCODE_LIST,
  SETTINGS_PRIVACY_POLICY_EDIT,
  SETTINGS_PRIVACY_POLICY_VIEW,
  SETTINGS_REJECT_REASON_CREATE,
  SETTINGS_REJECT_REASON_DETAIL,
  SETTINGS_REJECT_REASON_LIST,
  SETTINGS_STATE_CREATE,
  SETTINGS_STATE_EDIT,
  SETTINGS_STATE_LIST,
  SETTINGS_TERMS_EDIT,
  SETTINGS_TERMS_VIEW,
} from "../actions/types";
import {
  settingsAdvertisementCreateApi,
  settingsAdvertisementDeleteApi,
  settingsAdvertisementListApi,
  settingsCityCreateApi,
  settingsCityEditApi,
  settingsCityListApi,
  settingsDistrictListApi,
  settingsDistrictListCreateApi,
  settingsDistrictListEditApi,
  settingsDistrictViewApi,
  settingsImageUploadApi,
  settingsPincodeCreateApi,
  settingsPincodeEditApi,
  settingsPincodeFilter,
  settingsPincodeListApi,
  settingsPrivacyPolicyEditApi,
  settingsPrivacyPolicyViewApi,
  settingsRejectReasonCreateApi,
  settingsRejectReasonListApi,
  settingsRejectReasonViewApi,
  settingsStateListApi,
  settingsStateListCreateApi,
  settingsStateListEditApi,
  settingsTermsConditionEditApi,
  settingsTermsConditionViewApi,
} from "../apis/settingsApis";

export function* settingsStateListWorker(action) {
  try {
    const res = yield call(settingsStateListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsStateCreateWorker(action) {
  try {
    const res = yield call(settingsStateListCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsStateEditWorker(action) {
  try {
    const res = yield call(settingsStateListEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsDistrictListWorker(action) {
  try {
    const res = yield call(settingsDistrictListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsDistrictCreateWorker(action) {
  try {
    const res = yield call(settingsDistrictListCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsDistrictDetailWorker(action) {
  try {
    const res = yield call(settingsDistrictViewApi, {
      district_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsDistrictEditWorker(action) {
  try {
    const res = yield call(settingsDistrictListEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsCityListWorker(action) {
  try {
    const res = yield call(settingsCityListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsCityCreateWorker(action) {
  try {
    const res = yield call(settingsCityCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsCityEditWorker(action) {
  try {
    const res = yield call(settingsCityEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsPincodeListWorker(action) {
  try {
    console.log(action.data);
    const res = yield call(settingsPincodeListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsPincodeCreateWorker(action) {
  try {
    const res = yield call(settingsPincodeCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsPincodeEditWorker(action) {
  try {
    const res = yield call(settingsPincodeEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsRejectReasonListWorker(action) {
  try {
    const res = yield call(settingsRejectReasonListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsRejectReasonDetailWorker(action) {
  try {
    const res = yield call(settingsRejectReasonViewApi, {
      rejection_reason_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsRejectReasonCreateWorker(action) {
  try {
    const res = yield call(settingsRejectReasonCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsPrivacyPolicyViewWorker(action) {
  try {
    const res = yield call(settingsPrivacyPolicyViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsPrivacyPolicyEditWorker(action) {
  try {
    const res = yield call(settingsPrivacyPolicyEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsTermsConditionViewWorker(action) {
  try {
    const res = yield call(settingsTermsConditionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsTermsConditionEditWorker(action) {
  try {
    const res = yield call(settingsTermsConditionEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsImageUploadWorker(action) {
  console.log(action.data);
  try {
    const file = new FormData();
    file.append("file", action.data);
    const res = yield call(settingsImageUploadApi, file);

    console.log(res);
    if (res.status === 200 && res.data.status) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* settingPincodeFilterWorker(action) {
  try {
    const res = yield call(settingsPincodeFilter, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* settingsAdevrtiseentCreateWorker(action) {
  try {
    const res = yield call(settingsAdvertisementCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* settingsAdevrtiseentDeleteWorker(action) {
  try {
    const res = yield call(settingsAdvertisementDeleteApi, { banner_id : action.id });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* settingsAdevrtiseentListWorker(action) {
  try {
    const res = yield call(settingsAdvertisementListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* settingsDistrictListWatcher() {
  yield takeLatest(SETTINGS_DISTRICT_LIST, settingsDistrictListWorker);
}

export function* settingsDistrictCreateWatcher() {
  yield takeLatest(SETTINGS_DISTRICT_CREATE, settingsDistrictCreateWorker);
}

export function* settingsDistrictDetailWatcher() {
  yield takeLatest(SETTINGS_DISTRICT_DETAIL, settingsDistrictDetailWorker);
}

export function* settingsDistrictEditWatcher() {
  yield takeLatest(SETTINGS_DISTRICT_EDIT, settingsDistrictEditWorker);
}

export function* settingsRejectReasonListWatcher() {
  yield takeLatest(SETTINGS_REJECT_REASON_LIST, settingsRejectReasonListWorker);
}

export function* settingsRejectReasonDetailWatcher() {
  yield takeLatest(
    SETTINGS_REJECT_REASON_DETAIL,
    settingsRejectReasonDetailWorker
  );
}

export function* settingsRejectReasonCreateWatcher() {
  yield takeLatest(
    SETTINGS_REJECT_REASON_CREATE,
    settingsRejectReasonCreateWorker
  );
}

export function* settingsPrivacyPolicyViewWatcher() {
  yield takeLatest(
    SETTINGS_PRIVACY_POLICY_VIEW,
    settingsPrivacyPolicyViewWorker
  );
}

export function* settingsPrivacyPolicyEditWatcher() {
  yield takeLatest(
    SETTINGS_PRIVACY_POLICY_EDIT,
    settingsPrivacyPolicyEditWorker
  );
}

export function* settingsTermsConditionViewWatcher() {
  yield takeLatest(SETTINGS_TERMS_VIEW, settingsTermsConditionViewWorker);
}

export function* settingsTermsConditionEditWatcher() {
  yield takeLatest(SETTINGS_TERMS_EDIT, settingsTermsConditionEditWorker);
}

export function* settingsImageUploadWatcher() {
  yield takeEvery(ADVERTISEMENT_PHOTO, settingsImageUploadWorker);
}

export function* settingsStateListWatcher() {
  yield takeLatest(SETTINGS_STATE_LIST, settingsStateListWorker);
}

export function* settingsStateCreateWatcher() {
  yield takeLatest(SETTINGS_STATE_CREATE, settingsStateCreateWorker);
}

export function* settingsStateEditWatcher() {
  yield takeLatest(SETTINGS_STATE_EDIT, settingsStateEditWorker);
}

export function* settingsCityListWatcher() {
  yield takeLatest(SETTINGS_CITY_LIST, settingsCityListWorker);
}

export function* settingsCityCreateWatcher() {
  yield takeLatest(SETTINGS_CITY_CREATE, settingsCityCreateWorker);
}

export function* settingsCityEditWatcher() {
  yield takeLatest(SETTINGS_CITY_EDIT, settingsCityEditWorker);
}

export function* settingsPincodeListWatcher() {
  yield takeLatest(SETTINGS_PINCODE_LIST, settingsPincodeListWorker);
}

export function* settingsPincodeCreateWatcher() {
  yield takeLatest(SETTINGS_PINCODE_CREATE, settingsPincodeCreateWorker);
}

export function* settingsPincodeEditWatcher() {
  yield takeLatest(SETTINGS_PINCODE_EDIT, settingsPincodeEditWorker);
}

export function* settingPincodeFilterWatcher() {
  yield takeLatest(SETTINGS_PINCODE_FILTER, settingPincodeFilterWorker);
}
export function* settingsAdevrtiseentCreateWatcher() {
  yield takeLatest(
    SETTINGS_ADVERTISEMENT_CREATE,
    settingsAdevrtiseentCreateWorker
  );
}
export function* settingsAdevrtiseentListWatcher() {
  yield takeLatest(SETTINGS_ADVERTISEMENT_LIST, settingsAdevrtiseentListWorker);
}
export function* settingsAdevrtiseentDeleteWatcher() {
  yield takeLatest(SETTINGS_ADVERTISEMENT_DELETE, settingsAdevrtiseentDeleteWorker);
}