import React, { useEffect, useState } from "react";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import TableContainer from "../../../components/layout/table-container/table-container";
import { Spinner } from "react-bootstrap";
import { customerVerifyList } from "../../../redux/actions/customerVerificationActions";
import { useDispatch } from "react-redux";
import CustomerVerificationTableData from "./customerVerificationTableData";
import TablePagination from "../../../components/Pagination/tablePagination";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const CustomerverificationTable = ({ search }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_no = parseInt(params?.page);
  const [page, setPage] = useState(page_no ? page_no : 1);
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [customerListData, setCustomerListData] = useState([]);

  useEffect(() => {
    if (search !== null) {
      setPage(1);
      fetchCustomerList();
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    fetchCustomerList();
  }, [page, search]);

  const fetchCustomerList = () => {
    setLoading(true);
    dispatch(customerVerifyList({ page, search }, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setCustomerListData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr className="table-rows color_747f9e">
            <th className="table-heads " width={"4%"}>
              #
            </th>
            <th className="table-heads " width={"10%"}>
              Jivini’s Doctor Code
            </th>
            <th className="table-heads " width={"10%"}>
              Jivini’s Pharmacy Code
            </th>
            <th className="table-heads " width={"12%"}>
              Doctor Name
            </th>
            <th className="table-heads " width={"12%"}>
              Pharmacy Name
            </th>
            <th className="table-heads " width={"8%"}>
              City
            </th>
            <th className="table-heads " width={"8%"}>
              Pharmacy Type
            </th>
            <th className="table-heads " width={"8%"}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable show={loading} />
            </div>
          ) : customerListData?.customers?.length > 0 ? (
            <>
              {customerListData?.customers?.map((eachRow, index) => (
                <CustomerVerificationTableData
                  data={eachRow}
                  key={index}
                  index={index}
                  fetchCustomerList={fetchCustomerList}
                  currentPage={customerListData?.pagination?.currentPage}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="1" className="text-center no-data-container fs_16">
                <span>~ No Data Found ~</span>
              </td>
            </tr>
          )}
        </tbody>
      </TableContainer>
      {customerListData?.pagination?.totalPages > 0 && (
        <TablePagination
          data={customerListData?.pagination}
          nextFunc={() => {
            navigate(
              `/support/customer-verification/${customerListData?.pagination?.currentPage + 1
              }`
            );
            setPage(page + 1);
          }}
          prevFunc={() => {
            navigate(
              `/support/customer-verification/${customerListData?.pagination?.currentPage - 1
              }`
            );
            setPage(page - 1);
          }}
        />
      )}
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
      // handleClose={closeCatchedErrPopup}
      />
    </>
  );
};

export default CustomerverificationTable;
