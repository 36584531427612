import { call, takeLatest } from "redux-saga/effects";
import {
  INVOICE_PTR_UPDATE,
  INVOICE_REUPLOAD,
  INVOICE_VERIFIED_LIST,
  INVOICE_VERIFY,
  INVOICE_VERIFY_DETAIL,
  INVOICE_VERIFY_LIST,
  REMARK_LIST,
} from "../actions/types";
import {
  invoicePtrUpdateApi,
  invoiceReuploadApi,
  invoiceVerifiedListApi,
  invoiceVerifyApi,
  invoiceVerifyListApi,
  invoiceVerifyViewApi,
  remarkListApi
} from "../apis/invoiceVerificationApis";

export function* invoiceVerifyListWorker(action) {
  try {
    const res = yield call(invoiceVerifyListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* invoiceVerifiedListWorker(action) {
  try {
    const res = yield call(invoiceVerifiedListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* invoiceVerifyDetailWorker(action) {
  try {
    const res = yield call(invoiceVerifyViewApi, {
      order_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* fetchRemarkListWorker(action) {
  try {
    const res = yield call(remarkListApi);
    if (res.status == "200") {
      console.log(res);
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* invoiceReuploadWorker(action) {
  try {
    const res = yield call(invoiceReuploadApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* invoiceVerifyWorker(action) {
  try {
    const res = yield call(invoiceVerifyApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* invoicePtrUpdateWorker(action) {
  try {
    const res = yield call(invoicePtrUpdateApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* invoiceVerifyListWatcher() {
  yield takeLatest(INVOICE_VERIFY_LIST, invoiceVerifyListWorker);
}

export function* invoiceVerifiedListWatcher() {
  yield takeLatest(INVOICE_VERIFIED_LIST, invoiceVerifiedListWorker);
}

export function* invoiceVerifyDetailWatcher() {
  yield takeLatest(INVOICE_VERIFY_DETAIL, invoiceVerifyDetailWorker);
}

export function* fetchRemarkListWatcher() {
  yield takeLatest(REMARK_LIST, fetchRemarkListWorker);
}

export function* invoiceReuploadWatcher() {
  yield takeLatest(INVOICE_REUPLOAD, invoiceReuploadWorker);
}

export function* invoiceVerifyWatcher() {
  yield takeLatest(INVOICE_VERIFY, invoiceVerifyWorker);
}

export function* invoicePtrUpdateWatcher() {
  yield takeLatest(INVOICE_PTR_UPDATE, invoicePtrUpdateWorker);
}