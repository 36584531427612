import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { AdminButton } from "../buttons/versionHistoryBtn";
import "./modal.css";
import { useDispatch } from "react-redux";
import { settingsAdvertisementDelete } from "../../redux/actions/settingsActions";

const BannerDeleteModal = ({
  show,
  handleClose,
  deletedId,
  ViewAddList,
  setLoading,
}) => {
  const dispatch = useDispatch();

  const deleteFnc = (id) => {
    console.log(id);
    if (show) {
      dispatch(settingsAdvertisementDelete(id, onDelSuccess, onDelError));
      setLoading(true);
    }
  };

  const onDelSuccess = (data) => {
    console.log(data);
    ViewAddList();  
    handleClose();
    setLoading(false);
  };

  const onDelError = (err) => {
    console.log(err);
  };

  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="my-modal">
      <ModalBody>
        <div className="p-2">
          <p className="fn_Nunito fw_700 fs_18 color_262c3d my-2 px-0">
            Are you sure ?
          </p>
          <p className="fn_Nunito fw_400 fs_16 color_747f9e my-2 px-0">
            Do you want to delete this banner
          </p>
          <div className="d-flex justify-content-between">
            <AdminButton
              width="150px"
              color="white"
              border="1px solid #FF6F5B"
              background="#FF6F5B"
              onClick={() => deleteFnc(deletedId)}
            >
              <span className="m-2">DELETE</span>
            </AdminButton>
            <AdminButton
              width="160px"
              background="#7065e4"
              color="white"
              onClick={() => handleClose()}
            >
              <span className="m-2">NO</span>
            </AdminButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BannerDeleteModal;
