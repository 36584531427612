import React, { useState } from "react";
import SideBar from "../sidebar/sidebar";
import Topnav from "../topnav/topnav";
import "./mainLayout.css";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, useParams } from "react-router-dom";

const MainLayout = ({ children, breadcrumbData = "" }) => {
  const [open, setOpen] = useState(false);
  const params = useParams();

  // const DynamicUserBreadcrumb = ({ match }) => (
  //   <span>
  //     {match?.route?.path?.endsWith("view/") && `/ VIEW /  `}
  //     {/* {match?.params?.id} */}
  //   </span>
  // );

  const routes = [
    { path: "/", breadcrumb: "Admin" },
    { path: "/dashboard", breadcrumb: " / dashboard" },
    { path: "/analytics", breadcrumb: " / analytics" },
    {
      path: "/manufacturer/subscibe-list/:page",
      breadcrumb: " / Manufacturers ",
    },
    {
      path: "/manufacturer/premium-list/:page",
      breadcrumb: " / Manufacturers / premium Manufacturers list ",
    },
    { path: "/manufacturer/subscibe-list/create", breadcrumb: " / create" },
    {
      path: "/manufacturer/subscibe-list/view/:id",
      breadcrumb: breadcrumbData,
    },

    {
      path: "/manufacturer/subscibe-list/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/manufacturer/premium-list/view/:id",
      breadcrumb: breadcrumbData,
    },

    {
      path: "/manufacturer/premium-list/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/manufacturer/subscibe-list/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "/manufacturer/subscibe-list/version-view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/manufacturer/premium-list/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "/manufacturer/premium-list/version-view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/manufacturer/subscibe-list/create/designation",
      breadcrumb: " / designation",
    },
    {
      path: "/manufacturer/subscibe-list/create/division",
      breadcrumb: " / division",
    },
    {
      path: "/manufacturer/subscibe-list/create/head-quater",
      breadcrumb: " / head quater",
    },

    {
      path: "/distributors/distributor/:page",
      breadcrumb: " / distributors / ONBOARDED DISTRIBUTORS",
    },
    { path: "/distributors/distributor/view/:id", breadcrumb: breadcrumbData },

    {
      path: "/distributors/distributor/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/distributors/distributor/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "/distributors/distributor/version-view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/distributors/onboard-distributor/:page",
      breadcrumb: " / distributors / ONBOARDing DISTRIBUTORS",
    },
    {
      path: "/distributors/onboard-distributor/create",
      breadcrumb: " / create",
    },

    {
      path: "/distributors/onboard-distributor/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/distributors/onboard-distributor/edit/:id",
      breadcrumb: breadcrumbData,
    },

    {
      path: "/customers/customer/:page",
      breadcrumb: " / customers / onboarded customer",
    },
    {
      path: "/customers/customer/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/customers/customer/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/customers/customer/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "/customers/customer/version-view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/customers/request/:page",
      breadcrumb: " / customers / Onboarding Requests",
    },
    {
      path: "/customers/request/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/customers/distributor-request/:page",
      breadcrumb: " / customers / Change Distributor Request",
    },
    {
      path: "/customers/distributor-request/view/:id",
      breadcrumb: breadcrumbData,
    },

    { path: "/super-user/user/:page", breadcrumb: " / super user / users" },
    { path: "/super-user/user/create", breadcrumb: " / create" },
    {
      path: "/super-user/user/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/super-user/user/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/super-user/user/version-list/:page",
      breadcrumb: " / Version History",
    },
    {
      path: "/super-user/user/version-view/:id",
      breadcrumb: breadcrumbData,
    },

    { path: "/super-user/role/:page", breadcrumb: " / super user / role" },
    { path: "/super-user/role/create", breadcrumb: " / create" },
    {
      path: "/super-user/role/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "super-user/role/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/super-user/role/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "super-user/role/version-view/:id",
      breadcrumb: breadcrumbData,
    },

    { path: "/faq/:page", breadcrumb: " / faq" },
    { path: "/faq/create", breadcrumb: " / create" },
    {
      path: "/faq/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/faq/edit/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/faq/version-list/:page",
      breadcrumb: " / Version History",
    },

    {
      path: "/faq/version-view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/support/invoice-verification/:page",
      breadcrumb: " / support / invoice verification",
    },
    {
      path: "/support/invoice-verification/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/support/verified-invoices/:page",
      breadcrumb: " / support / verified invoices",
    },
    {
      path: "/support/verified-invoices/view/:id",
      breadcrumb: breadcrumbData,
    },
    {
      path: "/support/customer-verification/:page",
      breadcrumb: " / support / customer verification",
    },
    {
      path: "/support/customer-verification/view/:id",
      breadcrumb: breadcrumbData,
    },
    { path: "/settings/state", breadcrumb: " / settings / state" },
    { path: "/settings/district", breadcrumb: " / settings / district" },
    { path: "/settings/city", breadcrumb: " / settings / city" },
    { path: "/settings/pincode", breadcrumb: " / settings / pincode" },
    {
      path: "/settings/reason-for-rejection",
      breadcrumb: " / settings / reason for rejection",
    },
    { path: "/settings/reason-for-rejection/create", breadcrumb: " / create" },
    { path: "/settings/reason-for-rejection/view/:id", breadcrumb: " / view" },
    {
      path: "/settings/privacy-policy/view",
      breadcrumb: " / settings / Privacy Policy / view",
    },
    {
      path: "/settings/privacy-policy/edit",
      breadcrumb: " / settings / Privacy Policy / edit",
    },
    {
      path: "/settings/terms-condition/view",
      breadcrumb: " / settings / terms condition / view",
    },
    {
      path: "/settings/terms-condition/edit",
      breadcrumb: " / settings / terms condition / edit",
    },
    {
      path: "/settings/advertisement",
      breadcrumb: " / settings / advertisement",
    },
    {
      path: "ff-training/chapters/:page",
      breadcrumb: "/ Field Force Training / Chapters",
    },
    {
      path: "ff-training/chapters/create",
      breadcrumb: "/ Field Force Training / Chapters - create",
    }
  ];

  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    disableDefaults: true,
  });

  return (
    <>
      <div className="w-100 main-sub-container">
        <div className="main-page">
          <div className="container-main-div">
            <div className="mx-lg-4">
              {breadcrumbs.map(({ match, breadcrumb }) => (
                <NavLink
                  key={match.pathname}
                  to={match.pathname}
                  className="header-route-page fw_400 cursor_pointer"
                >
                  <span className="fs_18">{breadcrumb}</span>
                </NavLink>
              ))}
            </div>

            <div className="main-list-container my-2 py-1 d-flex justify-content-between flex-column">
              <main className="d-flex flex-column w-100 main-container">
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
