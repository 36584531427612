import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { useNavigate } from "react-router";
import historyIcon from "../../assets/icons/history.svg";

export const VersionAdminButton = styled("button")(
  {
    backgroundColor: "transparent",
    outline: "0px",
    marginRight: "8px",
    width: "60px",
    "@media(min-width: 1200px)": {
      width: "140px",
    },
    height: "42px",
    background: "#ffffff",
    border: "1px solid #e7e5ff",
    borderRadius: "5px",
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#7065e4",
  },
  (props) => ({
    color: props.color,
    background: props.background,
    width: props.width,
    border: props.border,
    color: props.color,
  })
);

const VersionHistoryResBtn = ({ link = "/", title = "Version History" }) => {
  const navigate = useNavigate();
  return (
    <VersionAdminButton onClick={() => navigate(link)}>
      <>
        <img src={historyIcon} className="d-xl-none" alt="history" />
        <span className="d-none d-xl-block">{title}</span>
      </>
    </VersionAdminButton>
  );
};

export default VersionHistoryResBtn;
