import React from "react";
import "./tablePagination.css";
import ArrowRightIcon from "./arrowrightIcon";
import ArrowLeftIcon from "./arrowleftIcon";

const TablePagination = ({ data, nextFunc, prevFunc }) => {
  console.log(data);
  return (
    <div className="rounded-4 white_shade_bg py-2  w-100">
      <div className="ms-auto align-items-center justify-content-end gap-4  d-flex fs_13 fw_500 me-0 pe-1 me-sm-4 pe-sm-2">
        <span className=" content px-3 pt-1 ">
          <span className=" Medium_Purple fw_700">{data?.currentPage}</span>/
          <span className="fw_700 ms-1">{data?.totalPages}</span>
        </span>
        <div className="d-flex align-items-center gap-2">
          <button
            type="button"
            onClick={prevFunc}
            className=" navigate-btn "
            disabled={data?.currentPage <= 1}
          >
            {data?.currentPage <= 1 ? (
              <ArrowLeftIcon fill="#B0B3C7" width={12} height={12} />
            ) : (
              <ArrowLeftIcon fill="#7065E4" width={12} height={12} />
            )}
          </button>
          <button
            type="button"
            onClick={nextFunc}
            className=" navigate-btn  "
            disabled={data?.currentPage >= data?.totalPages}
          >
            {data?.currentPage >= data?.totalPages ? (
              <ArrowRightIcon fill="#B0B3C7" width={12} height={12} />
            ) : (
              <ArrowRightIcon fill="#7065E4" width={12} height={12} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
