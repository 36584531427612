import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import InputField from "../../../components/form/inputField/inputField";
import back from "../../../assets/icons/back.svg";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { chapterCreate } from "../../../redux/actions/ffTrainingAction";

const ChapterCreate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState();

    const initialValues = {
        name: "",
        priority: ""
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        priority: Yup.number().required("Required"),
    });
    const clearFunc = (setFieldValue) => {
        setFieldValue("name", "");
        setFieldValue("priority", "");
    };

    const onSubmit = (values) => {
        console.log(values);
        dispatch(chapterCreate(values, onSuccess, onError));
    };

    const timeOutFnc = () => {
        setShowSuccessPopup(false);
        navigate("/ff-training/chapters/1");
    };

    const onSuccess = (data) => {
        console.log(data);
        setShowSuccessPopup(true);
        const str = data.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowSuccessMsg(str_modify);
        setTimeout(timeOutFnc, 2000);
    };

    const closeErrorPopup = () => {
        setShowErrorPopup(false);
    };

    const onError = (err) => {
        console.log(err);
        const str = err.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowErrorMsg(str_modify);
        setShowErrorPopup(true);
    };

    return (
        <MainLayout breadCrumb="ADMIN / FIELD FORCE TRAINING / CHAPTER - Create">
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ setFieldValue, values }) => (
                        <Form className="">
                            <div className="search-container d-flex justify-content-between ">
                                <div
                                    className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    <img src={back} alt="back_btn" />
                                    <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                                        BACK
                                    </span>
                                </div>
                                <div className=" d-flex justify-content-between m-1 mb-2">
                                    <AdminButton
                                        background="#E7E5FF"
                                        width="100px"
                                        type="button"
                                        onClick={() => clearFunc(setFieldValue)}
                                    >
                                        CLEAR
                                    </AdminButton>
                                    <AdminButton
                                        background="#7065e4"
                                        color="white"
                                        width="100px"
                                        type="submit"
                                    >
                                        <span className="m-2">SAVE</span>
                                    </AdminButton>
                                </div>
                            </div>
                            <div className="">
                                <div className="form-width ms-4 ps-3 py-1">
                                    <div className="my-2 ">
                                        <InputField
                                            id={"name"}
                                            label={"Chapter Name"}
                                            labelColor={"color_262c3d"}
                                            type={"text"}
                                            placeholder={"Enter Chapter Name"}
                                            name={"name"}
                                            value={values.name}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <InputField
                                            id={"priority"}
                                            label={"Priority"}
                                            labelColor={"color_262c3d"}
                                            type={"number"}
                                            placeholder={"Enter priority"}
                                            name={"priority"}
                                            value={values.priority}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
                <ErrorPopup
                    show={showErrorPopup}
                    title={showErrorMsg}
                    handleClose={closeErrorPopup}
                />
            </div>
        </MainLayout>
    );
};

export default ChapterCreate;