import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Modal, ModalBody } from "react-bootstrap";
import "../modal.css";
import InputField from "../../form/inputField/inputField";
import ErrorMsg from "../../form/errorMsg";
import { useDispatch } from "react-redux";
import SelectField from "../../form/selectField/selectField";
import { invoiceReupload } from "../../../redux/actions/invoiceVerificationAction";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import { useNavigate } from "react-router-dom";
// import SelectCustomField from "../../form/selectField/selectCustomField";

const ReuploadModal = ({ show, handleClose, data, initialValues, cfaUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const inValues = {
    ...initialValues,
    user_type: "",
    order_type: cfaUser ? "" : "distributor_order",
  };

  console.log(inValues, "int values");

  const validationSchema = Yup.object().shape({
    user_type: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(invoiceReupload(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/support/invoice-verification/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    handleClose();
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(() => {
      setShowErrorPopup(false);
    }, 2500);
  };

  const UserOptions = [
    { value: "customer", label: "Customer" },
    { value: "distributor", label: "Distributor" },
  ];
  const CfaUserOptions = [{ value: "cfa", label: "CFA" }];

  const orderOptions = [
    { value: "cfa_order", label: "CFA Order Invoice" },
    { value: "distributor_order", label: "Distributor Order Invoice" },
  ];
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName=""
        contentClassName="link-modal col-5 mx-auto p-2 fn_Nunito"
      >
        <ModalBody>
          <Formik
            initialValues={inValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form className="p-1">
                <p className="fn_Nunito fw_700 fs_18 my-2 px-0">REUPLOAD</p>

                <div className="my-3 mt-4">
                  <p className="color_747f9e  fs_16 fw_500 m-0">
                    <span className="color_262C3D me-2">Customer Name :</span>
                    {data?.orderHeirarchy?.user_type
                      ? data?.orderHeirarchy?.user_type == "doctor"
                        ? data?.doctor?.name
                        : data?.pharmacy?.pharmacy_name
                      : "--"}
                  </p>
                  <p className="color_747f9e  fs_16 fw_500 m-0">
                    <span className="color_262C3D me-2">
                      Distributor Name :
                    </span>
                    {data?.distributor?.firm_name
                      ? data?.distributor?.firm_name
                      : "--"}
                  </p>
                  <p className="color_747f9e  fs_16 fw_500 m-0">
                    <span className="color_262C3D me-2">
                      {" "}
                      Manufacturer Name :
                    </span>
                    {data?.vendor?.name ? data?.vendor?.name : "--"}
                  </p>
                </div>
                {cfaUser && (
                  <div className="my-2">
                    <SelectField
                      label={"Invoice Type"}
                      labelColor={"color_262c3d"}
                      placeholder={"Select type"}
                      optionList={orderOptions}
                      name={"order_type"}
                      id={"order_type"}
                      menuHeight="130px"
                      value={""}
                    />
                  </div>
                )}
                <div className="my-2 mb-4">
                  <SelectField
                    label={"User"}
                    labelColor={"color_262c3d"}
                    placeholder={"Select user"}
                    optionList={
                      values?.order_type == "cfa_order"
                        ? CfaUserOptions
                        : UserOptions
                    }
                    name={"user_type"}
                    id={"user_type"}
                    menuHeight="130px"
                    value={""}
                  />
                </div>

                <div className="d-flex justify-content-end align-items-center gap-3">
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="modal-logout-btn fs_14 fw_700 px-4 py-2"
                    style={{ width: "130px" }}
                  >
                    NO
                  </button>
                  <button
                    type="submit"
                    className="modal-cancel-btn fs_14 text-white py-2 px-4 fw_700"
                    style={{ width: "130px" }}
                  >
                    Send
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ReuploadModal;
