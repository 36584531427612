import React, { useEffect, useState } from "react";
import { gevDesignationView } from "../../../../redux/actions/godsEyeViewActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const DesignationDetailsView = () => {
  const [designationData, setDesignationData] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const desig_id = params?.id.split("&")?.[3];

  useEffect(() => {
    dispatch(gevDesignationView(desig_id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data?.data?.data, "ssss");
    setDesignationData(data?.data?.data);
  };
  const onError = (err) => { };

  const options = [
    { value: "L1", label: "Level 1" },
    { value: "L2", label: "Level 2" },
    { value: "L3", label: "Level 3" },
    { value: "L4", label: "Level 4" },
    { value: "L5", label: "Level 5" },
    { value: "L6", label: "Level 6" },
    { value: "L7", label: "Level 7" },
    { value: "L8", label: "Level 8" },
    { value: "L9", label: "Level 9" },
    { value: "L10", label: "Level 10" },
    { value: "L11", label: "Level 11" },
    { value: "L12", label: "Level 12" },
    { value: "L13", label: "Level 13" },
    { value: "L14", label: "Level 14" },
    { value: "L15", label: "Level 15" },
    { value: "admin", label: "Admin" },
  ];
  return (
    <>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">
          Designations - {designationData?.designation_id}
        </label>
      </div>
      <div className="col-6">
        <div className="mx-4 px-3 py-2 pt-4">
          <div className="my-2">
            <label className="color_747f9e fs_16">Designation Name</label>
            <p className="fs_16 color_262c3d">{designationData?.name}</p>
          </div>
          {designationData?.approval_authority && (
            <div className="my-2">
              <label className="color_747f9e fs_16">Order Approval Limit</label>
              <p className="fs_16 color_262c3d">{`₹ ${designationData?.order_approval_upto}`}</p>
            </div>
          )}
          <div className="my-2">
            <label className="color_747f9e fs_16">Approval Level</label>
            <p className="fs_16 color_262c3d">
              {
                options.find(
                  (ele) => ele.value == designationData?.approval_level
                )?.label
              }
            </p>
          </div>
          <div className="my-2">
            <label className="color_747f9e fs_16">Approval Authority</label>

            {designationData?.approval_authority == true ? (
              <p className="fs_16 text_green">Enabled</p>
            ) : (
              <p className="fs_16 text_red">
                <p className="fs_16 text_red">Disabled</p>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignationDetailsView;
