import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import TableContainer from "../../../layout/table-container/table-container";
import "../manufacturerModal.css";
import PurchaseOrderSendMail from "./purchaseOrderSendMail";
import PurchaseOrderTableData from "./purchaseOrderTableData";

import moment from "moment";

const PurchaseOrderTable = ({
  csvTableData,
  showPO,
  handleClosePO,
  purchaseOrderData,
  manufacturerViewData,
  purchaseOrderMailData,
}) => {
  console.log(purchaseOrderData, "orderdata");
  const [poSendMail, setpoSendMail] = useState(false);
  const emails = purchaseOrderData?.emails;

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }

  const purchaseOrderDetails = [
    {
      title: "Purchase Order ID",
      data: purchaseOrderData?.code ? purchaseOrderData?.code : "--",
    },
    {
      title: "Manufacturer Name",
      data: manufacturerViewData?.vendor?.name
        ? manufacturerViewData?.vendor?.name
        : "--",
    },
    {
      title: "Distributor Name",
      data: purchaseOrderData?.purchaseOrderInvoices?.[0]?.distributor
        ?.firm_name
        ? purchaseOrderData?.purchaseOrderInvoices?.[0]?.distributor?.firm_name
        : "--",
    },
    {
      title: "Reimbursement Method",
      data: purchaseOrderData?.reimbursement_method
        ? reimbursementOptions[purchaseOrderData?.reimbursement_method]
        : "--",
    },
    {
      title: "Generated Date",
      data: purchaseOrderData?.createdAt
        ? moment(purchaseOrderData?.createdAt).format("DD MMMM YYYY")
        : "--",
    },
  ];

  return (
    <>
      <Modal
        centered
        show={showPO}
        onHide={handleClosePO}
        dialogClassName="po-table-modal"
        contentClassName="PO-table-container fn_Nunito p-3"
      >
        <ModalBody>
          <div className="">
            <span className="fw_700 fs_26 fn_Nunito">Purchase Order</span>
          </div>
          <div className="row mt-3">
            {purchaseOrderDetails?.map((item) => (
              <div className="mb-2 col-6 col-md-4 col-lg-3 col-xl-2 po-details-card-container">
                <h5 className="color_747f9e text-nowrap fs_15 fw_500 m-0">
                  {item?.title}
                </h5>
                <p className={` fs_15 fn_Nunito fw_400 `}>{item?.data}</p>
              </div>
            ))}
          </div>
          <div className="modal-table-container">
            <TableContainer>
              <thead>
                <tr className="table-rows color_747f9e">
                  <th className="table-heads " width={"3%"}>
                    SL No.
                  </th>
                  <th className="table-heads " width={"8%"}>
                    PO Number
                  </th>
                  <th className="table-heads " width={"8%"}>
                    PO Date
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Doctor Code
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Doctor Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    City Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Pharmacy Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Order ID
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Division Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Distributor Invoiced Date
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Distributor Invoice ID
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Company Invoice Id
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Product ID
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Product Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Min Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Max Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Integral Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Offer Type
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Ordered Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Ordered Free Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Discount On ptr
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Invoice Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Invoice Free Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Net Rate
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Company Billed Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Company Billed Free Qty
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Po Billed Price
                  </th>
                  <th className="table-heads " width={"8%"}>
                    MRP
                  </th>
                  <th className="table-heads " width={"8%"}>
                    PTR
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Product Gross Value
                  </th>

                  <th className="table-heads " width={"8%"}>
                    Distributor Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Distributor Code
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Distributor Location
                  </th>
                  <th className="table-heads " width={"8%"}>
                    CFA Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    CFA Code
                  </th>
                  <th className="table-heads " width={"8%"}>
                    CFA location
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Linked Level 2 Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Linked Level 2 Code
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Linked Level 2 Phone Number
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Linked Level 3 Name
                  </th>
                  <th className="table-heads " width={"8%"}>
                    Linked Level 3 Code
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Linked Level 3 Phone Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {csvTableData?.map((item, index) => (
                  <tr className="table-rows1">
                    <td className="table-each-row-data">{index + 1}</td>
                    <td className="table-each-row-data">
                      {item?.po_number ? item?.po_number : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.po_date ? item?.po_date : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.doctor_code ? item?.doctor_code : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.doctor_name ? item?.doctor_name : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.city ? item?.city : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.pharmacy_name ? item?.pharmacy_name : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.order_id ? item?.order_id : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.division_name ? item?.division_name : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_invoice_date
                        ? moment(item?.distributor_invoice_date).format(
                          "DD-MM-YYYY"
                        )
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_invoice_id
                        ? item?.distributor_invoice_id
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.company_invoice_id
                        ? item?.company_invoice_id
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.product_id ? item?.product_id : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.product_name ? item?.product_name : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.min_qty ? item?.min_qty : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.max_qty ? item?.max_qty : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.integral_qty ? item?.integral_qty : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.offer_type ? item?.offer_type : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.order_quantity ? item?.order_quantity : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.order_free_quantity
                        ? item?.order_free_quantity
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.discount_on_ptr ? item?.discount_on_ptr : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.invoice_qty ? item?.invoice_qty : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.invoice_free_qty ? item?.invoice_free_qty : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.netrate ? item?.netrate : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.company_billed_qty
                        ? item?.company_billed_qty
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.company_billed_free_qty
                        ? item?.company_billed_free_qty
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.Po_billed_price ? item?.Po_billed_price : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.mrp ? item?.mrp : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.ptr ? item?.ptr : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.product_gross_value
                        ? item?.product_gross_value
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_name ? item?.distributor_name : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_code ? item?.distributor_code : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_location
                        ? item?.distributor_location
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_cfa_name
                        ? item?.distributor_cfa_name
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_cfa_code
                        ? item?.distributor_cfa_code
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.distributor_cfa_location
                        ? item?.distributor_cfa_location
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level2_name
                        ? item?.linked_level2_name
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level2_code
                        ? item?.linked_level2_code
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level2_mobile
                        ? item?.linked_level2_mobile
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level3_name
                        ? item?.linked_level3_name
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level3_code
                        ? item?.linked_level3_code
                        : "--"}
                    </td>
                    <td className="table-each-row-data">
                      {item?.linked_level3_mobile
                        ? item?.linked_level3_mobile
                        : "--"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="close-btn  px-5 py-2  fs_14  me-2"
              onClick={handleClosePO}
            >
              <span>CANCEL</span>
            </button>
            <button
              type="button"
              className="filter-btn px-5 py-2 fs_14  ms-2 fw_500"
              onClick={() => {
                handleClosePO();
                setpoSendMail(true);
              }}
            >
              PROCEED
            </button>
          </div>
        </ModalBody>
      </Modal>
      <PurchaseOrderSendMail
        purchaseOrderMailData={purchaseOrderMailData}
        manufacturerViewData={manufacturerViewData}
        purchaseOrderData={purchaseOrderData}
        emails={emails}
        show={poSendMail}
        handleClose={() => setpoSendMail(false)}
      />
    </>
  );
};

export default PurchaseOrderTable;
