import axios from "axios";
import { toast } from "react-toastify";
import { url } from "../config";

export const api = axios.create({
    baseURL: url,
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
  });

  api.interceptors.request.use(
    async (config) => {
      console.log(config);
      if (localStorage.getItem("accessToken")) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "accessToken"
        )}`;
      }
      return config;
    },
    async (error) => {}
  );

  api.interceptors.response.use(
    async (config) => {
      // if (config.data.message == "Authentication Failed")
      //   store.dispatch({
      //     type: ADMIN_LOGOUT,
      //     onSuccess: () => {},
      //     onError: () => {},
      //   });
      // localStorage.clear();
      // window.location.reload();
      console.log(config);
      return config;
    },
    async (error) => {
      console.log(error.response);
      const status = error.response.status;
      if (status === 401) {
        localStorage.clear();
        window.location.reload();
        toast.error("Unauthorized or session expired", {
          position: "bottom-right",
          theme: "light",
        });  
      }
      return error.response;
      // const status
    }
  );