import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrderTableModal from "../../../components/modals/customer/orderTableModal";
import { customerOrderCancel } from "../../../redux/actions/customerActions";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const CustomersViewTableData = ({ data, index, fetchCustomerView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();


  // console.log(data?.orderProducts);

  const cancelFunc = () => {
    dispatch(
      customerOrderCancel(
        {
          order_id: data.id,
        },
        cancelSuccess,
        cancelError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchCustomerView();
  };

  const cancelSuccess = (data) => {
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const cancelError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const [showOrderTableModal, setShowOrderTableModal] = useState(false);
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">{index + 1}</td>
      <td className="table-each-row-data">
        {data?.order_id ? data?.order_id : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.createdAt
          ? moment(data?.createdAt).format("DD MMM YYYY HH:mm A")
          : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.total ? data?.total : "--"}
      </td>

      <td className="table-each-row-data">
        {data?.vendor?.name ? data?.vendor?.name : "--"}
      </td>

      <td className="table-each-row-data">
        {data?.distributor?.firm_name ? data?.distributor?.firm_name : "--"}
      </td>
      <td className="table-each-row-data">
        <span
          className={`${
            orderStatus?.find((e) => e?.status == data?.status)?.color
          }`}
        >
          {orderStatus?.find((e) => e?.status == data?.status)?.value}
        </span>
      </td>
      <td className="table-each-row-data">
        {data?.reason_for_rejection ? data?.reason_for_rejection : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.rejectedBy ? data?.rejectedBy : "--"}
      </td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => setShowOrderTableModal(true)}
          >
            <span className=""> View</span>
          </button>

          <span className="vr  vr_line mt-1 "></span>
          <button
            className="button-disable"
            type="button"
            // bgColor={"red"}
            color={"green"}
            onClick={() => cancelFunc()}
          >
            Cancel
          </button>
        </div>
      </td>

      <OrderTableModal
        data={data?.orderProducts}
        index={index}
        orderdata={data}
        show={showOrderTableModal}
        ShowClose={() => setShowOrderTableModal(false)}
        fetchCustomerView={fetchCustomerView}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </tr>
  );
};

export default CustomersViewTableData;

export const orderStatus = [
  { status: "acknowledged", value: "Acknowledged", color: "color_2ad3e7 " },
  {
    status: "partially_acknowledged",
    value: "Partially Acknowledged",
    color: "color_2ad3e7 ",
  },
  { status: "fully_invoiced", value: "Invoiced", color: "text_green " },
  {
    status: "partially_invoiced",
    value: "Partial Invoiced",
    color: "Medium_Purple",
  },
  { status: "pending", value: "Approval Pending", color: "Medium_Purple" },
  { status: "expired", value: "Expired", color: "text-danger" },
  { status: "cancelled", value: "Cancelled", color: "text-danger" },
  { status: "approval_failed", value: "Approval Failed", color: "text-danger" },
  { status: "approved", value: "Approved", color: "color_2ad3e7" },
  { status: "delivered", value: "Delivered", color: "text_green" },
  {
    status: "out_for_delivery",
    value: "Out For Delivery",
    color: "text-danger",
  },
  { status: "rejected", value: "Rejected", color: "text-danger" },
];
