import { all } from "redux-saga/effects";
import {
  faqCreateWatcher,
  faqDeleteWatcher,
  faqDetailWatcher,
  faqEditWatcher,
  faqListWatcher,
  faqVersionDetailWatcher,
  faqVersionWatcher,
} from "./faqSaga";
import {
  createNewPasswordWatcher,
  forgetPasswordWorker,
  loginWatcher,
  logoutWatcher,
  resetPasswordWatcher,
  verifyResetPasswordWatcher,
} from "./authSaga";
import {
  distdivisionListWatcher,
  distributorCreateWatcher,
  distributorDeleteWatcher,
  distributorDetailWatcher,
  distributorEditWatcher,
  distributorEnableDisableWatcher,
  distributorListWatcher,
  distributorSendMailWatcher,
  distributorVersionDetailWatcher,
  distributorVesrionListWatcher,
  loginToPortalDistributorWatcher,
  lonboardingDistributorListWatcher,
  manufacturerListOptionsWatcher,
  uploadDrugLicenceWatcher,
  uploadGstWatcher,
  uploadLogoWatcher,
} from "./distributorSaga";
import {
  fetchPermissionListWatcher,
  superAdminCreateWatcher,
  superAdminDeleteWatcher,
  superAdminDetailWatcher,
  superAdminEditWatcher,
  superAdminListWatcher,
  superAdminVersionDetailWatcher,
  superAdminVersionWatcher,
  superAdminRoleListWatcher,
  superAdminRoleDeleteWorker,
  superAdminRoleDeleteWatcher,
  superAdminRoleCreateWatcher,
  fetchRolePermissionListWatcher,
  superAdminRoleDetailWatcher,
  superAdminRoleEditWatcher,
  superAdminRoleVersionWatcher,
  superAdminRoleVersionDetailWatcher,
  fetchRoleListWatcher,
} from "./superAdminSaga";
import {
  addDesignationWatcher,
  addDivisionWatcher,
  addHeadQuarterWatcher,
  createDoctorWalker,
  createPharmacyWalker,
  distributorBulkVerifyWatcher,
  distributorCsvUploadWatcher,
  doctorBulkVerifyWatcher,
  doctorCsvUploadWatcher,
  doctorDivViewUploadWatcher,
  doctorDivisionViewUploadWatcher,
  document1UploadWatcher,
  document2UploadWatcher,
  document3UploadWatcher,
  editDesignationWatcher,
  editDivisionWatcher,
  editHeadQuarterWatcher,
  enableDisableDivisionWatcher,
  enableDisableHeadQuarterWatcher,
  fetchreimbursementListWatcher,
  ffDivisionViewUploadWatcher,
  fieldForceBulkVerifyWatcher,
  fieldForceCsvUploadWatcher,
  generateInvoiceWatcher,
  generatePoInvoiceWatcher,
  generatePoWatcher,
  invoiceChangeStatusWatcher,
  invoiceDetailsWatcher,
  invoiceSendMailWatcher,
  loginToPortalCfaWatcher,
  loginToPortalManufacturerWatcher,
  manufacturerCreateDraftWatcher,
  manufacturerCreateWatcher,
  manufacturerDeleteWatcher,
  manufacturerDetailWatcher,
  manufacturerEditWatcher,
  manufacturerEnableDisableWatcher,
  manufacturerListWatcher,
  manufacturerLogoUpldWatcher,
  manufacturerPremiumListWatcher,
  manufacturerPremiumVersionWatcher,
  manufacturerVersionDetailWatcher,
  manufacturerVersionWatcher,
  pharmacyBulkUploadWatcher,
  pharmacyCsvUploadWatcher,
  pharmacyDivViewUploadWatcher,
  pharmacyDivisionViewUploadWatcher,
  poCsvDownloadWatcher,
  poCsvSendMailWatcher,
  poDistributorListWatcher,
  poInvoicedListWatcher,
  poListWatcher,
  poOfferTypeListWatcher,
  poStateListWatcher,
  poUninvoicedListWatcher,
  poViewWatcher,
  productBulkVerifyWatcher,
  productCsvUploadWatcher,
  productDivisionViewUploadWatcher,
  sendMailWatcher,
  toggleAnalyticsWatcher,
  updateManufacturerCreateWatcher,
} from "./manufacturerSaga";
import {
  cityListWatcher,
  districtListWatcher,
  pincodeAutoListWatcher,
  secondCityListWatcher,
  secondDistrictListWatcher,
  secondStateListWatcher,
  ssecondStateListWatcher,
  stateListWatcher,
} from "./commonSaga";
import {
  changeDistriRequestDetailWatcher,
  changeDistriRequestListWatcher,
  customerApprovalRejectWatcher,
  customerDeleteWatcher,
  customerDetailWatcher,
  customerEnableDisableWatcher,
  customerListWatcher,
  customerOrderCancelWatcher,
  customerUpldWatcher,
  customerVersionDetailWatcher,
  customerVersionWatcher,
  editCustomerWatcher,
  onboardRequestDetailWatcher,
  onboardRequestListWatcher,
} from "./customerSaga";
import {
  fetchRemarkListWatcher,
  invoicePtrUpdateWatcher,
  invoiceReuploadWatcher,
  invoiceVerifiedListWatcher,
  invoiceVerifyDetailWatcher,
  invoiceVerifyListWatcher,
  invoiceVerifyWatcher,
} from "./invoiceVerificationSaga";
import {
  settingPincodeFilterWatcher,
  settingsAdevrtiseentCreateWatcher,
  settingsAdevrtiseentDeleteWatcher,
  settingsAdevrtiseentListWatcher,
  settingsCityCreateWatcher,
  settingsCityCreateWorker,
  settingsCityEditWatcher,
  settingsCityEditWorker,
  settingsCityListWatcher,
  settingsCityListWorker,
  settingsDistrictCreateWatcher,
  settingsDistrictDetailWatcher,
  settingsDistrictEditWatcher,
  settingsDistrictListWatcher,
  settingsImageUploadWatcher,
  settingsPincodeCreateWatcher,
  settingsPincodeEditWatcher,
  settingsPincodeListWatcher,
  settingsPrivacyPolicyEditWatcher,
  settingsPrivacyPolicyViewWatcher,
  settingsRejectReasonCreateWatcher,
  settingsRejectReasonDetailWatcher,
  settingsRejectReasonListWatcher,
  settingsStateCreateWatcher,
  settingsStateEditWatcher,
  settingsStateListWatcher,
  settingsTermsConditionEditWatcher,
  settingsTermsConditionViewWatcher,
} from "./settingsSaga";
import { dashboardWatcher, graphCardWatcher } from "./dashboardSaga";
import {
  GevCfasendMailWatcher,
  gevCfaEditWatcher,
  gevCfaListWatcher,
  gevCfaReportListWatcher,
  gevCfaViewWatcher,
  gevCfacCreateWatcher,
  gevDesignationListWatcher,
  gevDesignationReportListWatcher,
  gevDesignationViewWatcher,
  gevDesignationWatcher,
  gevDivisionReportListWatcher,
  gevDivisionViewViewWatcher,
  gevDivisionViewWatcher,
  gevDivisionViewWorker,
  gevDivisionWatcher,
  gevFFReportListWatcher,
  gevFieldForceEditWatcher,
  gevFieldForceViewWatcher,
  gevFieldForceWatcher,
  gevHeadQuaterViewListWatcher,
  gevHeadQuaterViewWatcher,
  gevHeadQuaterWatcher,
  gevHqReportListWatcher,
  gevOrdersReportListWatcher,
  gevOrdersViewWatcher,
  gevOrdersWatcher,
  gevProductCreateWatcher,
  gevProductEditWatcher,
  gevProductListWatcher,
  gevProductReportListWatcher,
  gevProductViewWatcher,
  gevRepManagerWatcher,
  gevproductDeleteWatcher,
  packingTypeWatcher,
  productDoctorListWatcher,
  productHeadQuaterListWatcher,
  productPharmacyListWatcher,
  productStateListWatcher,
  vendorDivisionListWatcher,
} from "./godsEyeViewSaga";
import {
  customerVerifyDetailWatcher,
  customerVerifyListWatcher,
  verifyReportWatcher,
} from "./customerVerifySaga";
import {
  analyticsManufacturerListWatcher,
  analyticsStatusWatcher,
  analyticsWatcher,
  distributorAnalyticsListWatcher,
  divisionListWatcher,
  doctorListWatcher,
  fieldForceListWatcher,
  headquaterListWatcher,
  pharmacyListWatcher,
  productListAnalyticsWatcher,
} from "./analyticsSaga";
import { ffTrainingChapterDropDownWatcher, ffTrainingChaptersCreateWatcher, ffTrainingChaptersDeleteWatcher, ffTrainingChaptersEditWatcher, ffTrainingChaptersListWatcher, ffTrainingChaptersViewWatcher, ffTrainingQaCreateWatcher, ffTrainingQaListWatcher, ffTrainingQasDeleteWatcher, ffTrainingQasEditWatcher, ffTrainingQasViewWatcher } from "./ffTrainingSaga";

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    forgetPasswordWorker(),
    verifyResetPasswordWatcher(),
    resetPasswordWatcher(),
    logoutWatcher(),
    stateListWatcher(),
    districtListWatcher(),
    cityListWatcher(),
    faqListWatcher(),
    faqCreateWatcher(),
    faqDetailWatcher(),
    faqEditWatcher(),
    faqVersionWatcher(),
    faqVersionDetailWatcher(),
    faqDeleteWatcher(),
    superAdminListWatcher(),
    superAdminCreateWatcher(),
    superAdminEditWatcher(),
    superAdminDetailWatcher(),
    superAdminVersionWatcher(),
    superAdminVersionDetailWatcher(),
    fetchPermissionListWatcher(),
    superAdminDeleteWatcher(),
    distributorListWatcher(),
    distributorDetailWatcher(),
    distributorVersionDetailWatcher(),
    distributorVesrionListWatcher(),
    distributorEnableDisableWatcher(),
    distributorDeleteWatcher(),
    manufacturerListOptionsWatcher(),
    distributorEditWatcher(),
    uploadLogoWatcher(),
    uploadDrugLicenceWatcher(),
    uploadGstWatcher(),
    manufacturerListWatcher(),
    manufacturerDetailWatcher(),
    manufacturerVersionWatcher(),
    manufacturerVersionDetailWatcher(),
    manufacturerDeleteWatcher(),
    manufacturerEnableDisableWatcher(),
    manufacturerCreateDraftWatcher(),
    manufacturerCreateWatcher(),
    manufacturerEditWatcher(),
    manufacturerLogoUpldWatcher(),
    document1UploadWatcher(),
    document2UploadWatcher(),
    fetchreimbursementListWatcher(),
    customerListWatcher(),
    customerDetailWatcher(),
    customerVersionWatcher(),
    customerVersionDetailWatcher(),
    customerDeleteWatcher(),
    customerOrderCancelWatcher(),
    customerEnableDisableWatcher(),
    productCsvUploadWatcher(),
    productBulkVerifyWatcher(),
    fieldForceCsvUploadWatcher(),
    fieldForceBulkVerifyWatcher(),
    distributorCsvUploadWatcher(),
    distributorBulkVerifyWatcher(),
    doctorCsvUploadWatcher(),
    doctorBulkVerifyWatcher(),
    pharmacyCsvUploadWatcher(),
    pharmacyBulkUploadWatcher(),
    invoiceVerifyListWatcher(),
    invoiceVerifyDetailWatcher(),
    fetchRemarkListWatcher(),
    invoiceReuploadWatcher(),
    invoiceVerifyWatcher(),
    loginToPortalManufacturerWatcher(),
    loginToPortalDistributorWatcher(),
    sendMailWatcher(),
    settingsDistrictListWatcher(),
    settingsDistrictCreateWatcher(),
    settingsDistrictDetailWatcher(),
    onboardRequestListWatcher(),
    onboardRequestDetailWatcher(),
    settingsDistrictEditWatcher(),
    settingsRejectReasonListWatcher(),
    settingsRejectReasonDetailWatcher(),
    settingsRejectReasonCreateWatcher(),
    settingsPrivacyPolicyViewWatcher(),
    settingsPrivacyPolicyEditWatcher(),
    settingsTermsConditionViewWatcher(),
    settingsTermsConditionEditWatcher(),
    settingsImageUploadWatcher(),
    updateManufacturerCreateWatcher(),
    settingsStateListWatcher(),
    settingsStateCreateWatcher(),
    settingsStateEditWatcher(),
    settingsCityListWatcher(),
    settingsCityCreateWatcher(),
    settingsCityEditWatcher(),
    settingsPincodeEditWatcher(),
    settingsPincodeCreateWatcher(),
    settingsPincodeListWatcher(),
    settingPincodeFilterWatcher(),
    addDesignationWatcher(),
    addHeadQuarterWatcher(),
    addDivisionWatcher(),
    editDivisionWatcher(),
    editDesignationWatcher(),
    editHeadQuarterWatcher(),
    enableDisableHeadQuarterWatcher(),
    enableDisableDivisionWatcher(),
    poStateListWatcher(),
    poListWatcher(),
    poDistributorListWatcher(),
    poOfferTypeListWatcher(),
    generatePoWatcher(),
    poUninvoicedListWatcher(),
    poInvoicedListWatcher(),
    generatePoInvoiceWatcher(),
    generateInvoiceWatcher(),
    invoiceChangeStatusWatcher(),
    invoiceDetailsWatcher(),
    invoiceSendMailWatcher(),
    poCsvSendMailWatcher(),
    editCustomerWatcher(),
    customerUpldWatcher(),
    poCsvDownloadWatcher(),
    dashboardWatcher(),
    graphCardWatcher(),
    gevDesignationListWatcher(),
    gevHeadQuaterViewListWatcher(),
    gevFieldForceWatcher(),
    gevFieldForceViewWatcher(),
    gevFieldForceEditWatcher(),
    gevOrdersWatcher(),
    gevDesignationWatcher(),
    gevDivisionWatcher(),
    gevHeadQuaterWatcher(),
    gevRepManagerWatcher(),
    gevOrdersViewWatcher(),
    gevCfaListWatcher(),
    gevCfaViewWatcher(),
    gevCfaEditWatcher(),
    gevProductListWatcher(),
    gevProductViewWatcher(),
    productStateListWatcher(),
    packingTypeWatcher(),
    gevProductEditWatcher(),
    vendorDivisionListWatcher(),
    lonboardingDistributorListWatcher(),
    toggleAnalyticsWatcher(),
    gevDivisionViewWatcher(),
    customerVerifyListWatcher(),
    customerVerifyDetailWatcher(),
    verifyReportWatcher(),
    settingsAdevrtiseentCreateWatcher(),
    superAdminRoleListWatcher(),
    superAdminRoleCreateWatcher(),
    superAdminRoleDeleteWatcher(),
    fetchRolePermissionListWatcher(),
    superAdminRoleDetailWatcher(),
    superAdminRoleEditWatcher(),
    superAdminRoleVersionWatcher(),
    superAdminRoleVersionDetailWatcher(),
    fetchRoleListWatcher(),
    distributorSendMailWatcher(),
    productDivisionViewUploadWatcher(),
    ffDivisionViewUploadWatcher(),
    doctorDivisionViewUploadWatcher(),
    pharmacyDivisionViewUploadWatcher(),
    doctorDivViewUploadWatcher(),
    pharmacyDivViewUploadWatcher(),
    distributorCreateWatcher(),
    distdivisionListWatcher(),
    changeDistriRequestListWatcher(),
    changeDistriRequestDetailWatcher(),
    customerApprovalRejectWatcher(),
    productHeadQuaterListWatcher(),
    gevProductCreateWatcher(),
    productDoctorListWatcher(),
    productPharmacyListWatcher(),
    gevproductDeleteWatcher(),
    settingsAdevrtiseentListWatcher(),
    settingsAdevrtiseentDeleteWatcher(),
    createDoctorWalker(),
    createPharmacyWalker(),
    secondStateListWatcher(),
    secondDistrictListWatcher(),
    secondCityListWatcher(),
    loginToPortalCfaWatcher(),
    manufacturerPremiumListWatcher(),
    manufacturerPremiumVersionWatcher(),
    gevCfacCreateWatcher(),
    GevCfasendMailWatcher(),
    gevDesignationViewWatcher(),
    gevHeadQuaterViewWatcher(),
    gevDivisionViewViewWatcher(),
    gevDesignationReportListWatcher(),
    gevHqReportListWatcher(),
    gevDivisionReportListWatcher(),
    gevProductReportListWatcher(),
    gevFFReportListWatcher(),
    gevOrdersReportListWatcher(),
    gevCfaReportListWatcher(),
    pincodeAutoListWatcher(),
    divisionListWatcher(),
    productListAnalyticsWatcher(),
    fieldForceListWatcher(),
    distributorAnalyticsListWatcher(),
    pharmacyListWatcher(),
    doctorListWatcher(),
    headquaterListWatcher(),
    analyticsWatcher(),
    analyticsStatusWatcher(),
    document3UploadWatcher(),
    createNewPasswordWatcher(),
    analyticsManufacturerListWatcher(),
    poViewWatcher(),
    ffTrainingChaptersListWatcher(),
    ffTrainingChaptersCreateWatcher(),
    ffTrainingChaptersDeleteWatcher(),
    ffTrainingChaptersViewWatcher(),
    ffTrainingChaptersEditWatcher(),
    ffTrainingQaListWatcher(),
    ffTrainingQaCreateWatcher(),
    ffTrainingQasDeleteWatcher(),
    ffTrainingQasViewWatcher(),
    ffTrainingQasEditWatcher(),
    ffTrainingChapterDropDownWatcher(),
    invoiceVerifiedListWatcher(),
    invoicePtrUpdateWatcher()
  ]);
}
