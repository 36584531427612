import React from "react";
import { useState } from "react";
import searcher from "../../assets/icons/searchInput.svg";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import InvoiceVerifyListTable from "./invoice-verify-list-table";

const InvoiceVerificationList = () => {
  const [search, setSearch] = useState(null)
  return (
    <MainLayout breadCrumb="ADMIN  /  Invoice Verification">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 text-dark fw_400 px-2"
              type="text"
              placeholder="Search Invoices here by Invoice ID, Customer Name, Field Force User Name, Distributor Name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <InvoiceVerifyListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default InvoiceVerificationList;
