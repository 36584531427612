import React, { useEffect, useState } from "react";
import "./forgot-reset-password.css";
import subLogo from "../../../assets/images/auth/resetPasswordImage.png";
import jiviniLogo from "../../../assets/images/auth/jiviniLogo.png";
import eyeOn from "../../../assets/icons/auth/eye-on.svg";
import eyeOff from "../../../assets/icons/auth/eye-off.svg";
import eyeDead from "../../../assets/icons/auth/eyedead.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../redux/actions/authActions";
import { Spinner } from "react-bootstrap";
import ErrorMsg from "../../../components/form/errorMsg";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState();
  const [confirmViewPassword, setConfirmViewPassword] = useState();
  const [passwordDisable, setPasswordDisable] = useState(false);
  const [confirmPasswordDisable, setConfirmPasswordDisable] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [criteriaMet, setCriteriaMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const passwordCriteria = [
    {
      name: "Your password should be at least 8 characters long.",
      regex: /.{8,}/,
    },
    {
      name: "Include at least one uppercase letter in your password.",
      regex: /[A-Z]/,
    },
    {
      name: "Include at least one lowercase letter in your password.",
      regex: /[a-z]/,
    },
    { name: "Your password should contain at least one digit.", regex: /\d/ },
    {
      name: "specialCharacter",
      regex: /[!@#$%^&*()_+{}\[\]:;<>,.?~-]/,
    },
  ];

  const checkPasswordCriteria = (password) => {
    const newCriteriaMet = {};
    passwordCriteria.forEach((criteria) => {
      newCriteriaMet[criteria.name] = criteria.regex.test(password);
    });
    setCriteriaMet(newCriteriaMet);
  };

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const loginSchema = Yup.object().shape({
    password: Yup.string("")
      .matches(passwordRegex, "Password criteria not matching")
      .trim()
      .required("Password is required"),
    confirm_password: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Your password didn't match"
        ),
      })
      .required("Confirm password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    setSubmitDisable(true);
    dispatch(
      resetPassword(
        {
          resetToken: state?.data,
          password: values.password,
          confirm_password: values.confirm_password,
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    setLoading(false);
    setSubmitDisable(false);
    navigate("/password-changed");
  };

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
    setLoading(false);
    setSubmitDisable(false);
  };

  const onError = (err) => {
    console.log(err);
    // navigate("/email-verification-fail")
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const handleChange1 = (e) => {
    if (e.target.value.length > 0) {
      checkPasswordCriteria(e.target.value);
      setPasswordDisable(true);
    } else {
      setPasswordDisable(false);
    }
  };

  const handleChange2 = (e) => {
    if (e.target.value.length > 0) {
      setConfirmPasswordDisable(true);
    } else {
      setConfirmPasswordDisable(false);
    }
  };
  const FormEnableCheck = () => {
    const {
      values: { email, password, confirm_password },
    } = useFormikContext();
    useEffect(() => {
      if (
        // email.trim() != "" &&
        password.trim() != "" &&
        confirm_password.trim() != ""
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [email, password, confirm_password]);
    return null;
  };

  return (
    <div className="reset-container fn_Nunito d-flex flex-column flex-lg-row justify-content-center pt-4 pt-lg-0   ">
      <div className="">
        <img
          src={jiviniLogo}
          alt="website logo"
          className="asscents-logo-image d-lg-none"
        />
      </div>
      <div className="left-view pb-4 pb-lg-0  d-flex flex-column justify-content-center">
        <img
          src={subLogo}
          alt="Asscents Website"
          className="reset-img align-self-center"
        />
      </div>
      <div className="form-view justify-content-center d-flex flex-column mt- mt-lg-0">
        <div className=" forgot-password-form-container  col-10  col-lg-7 col-xl-7 mx-auto  d-flex text-start flex-column p-2">
          <img
            src={jiviniLogo}
            alt="website logo"
            className="asscents-logo-image d-none d-lg-block "
          />
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <FormEnableCheck />
              <h1 className="fw_700 fs_22">Reset Password</h1>

              <div className="w-100 mt-lg-0">
                <label className="w-100 mx-1 label-input">Password</label>
                <Field name={"password"}>
                  {({ field, meta }) => (
                    <div className="position-relative">
                      <input
                        {...field}
                        placeholder="Enter your new password"
                        id={"password"}
                        type={passwordVisible ? "text" : "password"}
                        className={"w-100 input-field"}
                        onKeyUp={(e) => handleChange1(e)}
                      />
                      <img
                        className="verification_password_eye__icon cursor_pointer"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        src={
                          passwordDisable
                            ? passwordVisible
                              ? eyeOn
                              : eyeOff
                            : eyeDead
                        }
                        alt="show password or not"
                      />
                    </div>
                  )}
                </Field>
                <ErrorMsg name="password" />
              </div>
              <div className="w-100 mt-lg-0">
                <label className="w-100 mx-1 label-input">
                  Confirm Password
                </label>
                <Field name={"confirm_password"}>
                  {({ field, meta }) => (
                    <div className="position-relative">
                      <input
                        {...field}
                        placeholder="Re-enter your password"
                        id={"confirmPassword"}
                        type={confirmViewPassword ? "text" : "password"}
                        className={"w-100 input-field"}
                        onKeyUp={(e) => handleChange2(e)}
                      />
                      <img
                        className="verification_password_eye__icon cursor_pointer"
                        onClick={() =>
                          setConfirmViewPassword(!confirmViewPassword)
                        }
                        src={
                          confirmPasswordDisable
                            ? confirmViewPassword
                              ? eyeOn
                              : eyeOff
                            : eyeDead
                        }
                        alt="show password or not"
                      />
                    </div>
                  )}
                </Field>
                <ErrorMsg name="confirm_password" />
              </div>
              <button
                className="verification-button fs_16 fs_md_14 text-center mt-2 py-1 px-2"
                type="submit"
                disabled={disable || submitDisable}
              >
                {loading && <Spinner size="sm" />}
                Reset Password
              </button>
              <div
                className="px-2 "
                style={{
                  height: "17.5rem",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="position-relative">
                  <div className="criteria-list my-5 gap-1 position-absolute">
                    <span className="fs_14 fw_700">Password Criteria</span>
                    {passwordCriteria.map((criteria, index) => (
                      <div className="d-flex align-items-center">
                        <div className="mx-2 mt-2"> * </div>
                        <div
                          key={index}
                          className={`criteria ${
                            criteriaMet[criteria.name] ? "criteria-matched" : ""
                          }`}
                        >
                          {criteria.name == "specialCharacter" ? (
                            <label>
                              Add at least one special character <br />
                              {`(e.g., !@#$%^&*()-_=+{}[]:;<>,.?~/)`} to your
                              password.
                            </label>
                          ) : (
                            <span>{criteria.name}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
          <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
          <ErrorPopup
            show={showErrorPopup}
            title={showErrorMsg}
            handleClose={closeErrorPopup}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
