import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";

const ReasonRejectionEdit = () => {
  const navigate = useNavigate();

  const initialValues = {
    rejection_text: "",
  };

  const validationSchema = Yup.object().shape({
    rejection_text: Yup.string().required("Required"),
  });
  const resetFunc = (setFieldValue) => {
    setFieldValue("rejection_text", "");
  };

  const onSubmit = (values) => {
    console.log(values);
  };
  return (
    <MainLayout breadCrumb="ADMIN / Super Users - Edit">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            <div className="form-width ms-4 ps-3 py-1">
              <div className="my-4 ">
                <div className="my-2">
                  <label className="color_747f9e fs_14">
                    Rejection Reason ID
                  </label>
                  <p className="fs_14">RR1234567</p>
                </div>
                <InputField
                  id={"rejection_text"}
                  label={"Reasons for Rejection"}
                  labelColor={"color_747f9e"}
                  type={"text"}
                  placeholder={"Enter reason for rejection"}
                  name={"rejection_text"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default ReasonRejectionEdit;
