import React, { useEffect, useState } from "react";
import InputCapitalField from "../../../../../components/form/inputField/inputCapitalField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ErrorMsg from "../../../../../components/form/errorMsg";
import InputField from "../../../../../components/form/inputField/inputField";
import {
  designationList,
  gevDivisionList,
  gevFieldForceEdit,
  gevFieldForceView,
  gevHeadQuaterList,
  gevReportingManagerList,
} from "../../../../../redux/actions/godsEyeViewActions";
import { useDispatch } from "react-redux";
import { AdminButton } from "../../../../../components/buttons/versionHistoryBtn";
import back from "../../../../../assets/icons/back.svg";
import SuccessPopup from "../../../../../components/popups/SuccessPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../../../components/utilites/loaderSpinningContainer";
import ErrorPopup from "../../../../../components/popups/ErrorPopup";
import { useParams } from "react-router-dom";

const FFUserEdit = ({ vendorId, id, activeFF, setActiveFF }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [viewData, setviewData] = useState(null);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [headQuaterOptions, setHeadQuaterOptions] = useState([]);
  const [reportingManagerOptions, setReportingManagerOptions] = useState([]);
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [onShowSuccessMsg, setOnshowSuccessMsg] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [onShowErrorMsg, setOnshowErrorMsg] = useState("");
  const subId = params?.id.split("&")?.[3];
  const activePath = params?.id.split("&")?.[1];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    gevFFView();
  }, []);

  const gevFFView = () => {
    if (activePath == "fieldForce") {
      setLoading(true);
      dispatch(gevFieldForceView(subId, onffSuccess, onffError));
      dispatch(
        designationList(
          vendorId,
          fetchDesignationListSuccess,
          fetchDesignationListError
        )
      );
      dispatch(
        gevDivisionList(
          vendorId,
          fetchDivisionListSuccess,
          fetchDivisionListError
        )
      );
      dispatch(
        gevHeadQuaterList(
          vendorId,
          fetchHeadQuaterSuccess,
          fetchHeadQuaterError
        )
      );
    }
  };
  useEffect(() => {
    if (selectedDivision.length > 0 && selectedLevel !== null) {
      dispatch(
        gevReportingManagerList(
          {
            vendor_id: vendorId,
            division_id: selectedDivision,
            approval_level: selectedLevel,
          },
          fetchReportingManagerSuccess,
          fetchReportingManagerError
        )
      );
    }
  }, [selectedDivision, selectedLevel]);

  const fetchDesignationListSuccess = (data) => {
    setDesignationOptions(data);
    console.log(data);
  };

  const fetchDesignationListError = (err) => {
    console.log(err);
  };
  const fetchDivisionListSuccess = (data) => {
    setDivisionList(data);
    console.log(data);
  };

  const fetchDivisionListError = (err) => {
    console.log(err);
  };
  const fetchHeadQuaterSuccess = (data) => {
    setHeadQuaterOptions(data);
  };

  const fetchHeadQuaterError = (data) => {
    console.log(data);
  };
  const fetchReportingManagerSuccess = (data) => {
    setReportingManagerOptions(data);
  };

  const fetchReportingManagerError = (data) => {
    console.log(data);
  };

  const onffSuccess = (data) => {
    setLoading(false);
    console.log(data?.data?.data);
    setviewData(data?.data?.data);
    setSelectedLevel(data?.data?.data?.fieldForceDesignation?.approval_level);
    // setSelectedDivision(data?.data?.data?.division_id);
    setSelectedDivision(data?.data?.data?.fieldForceDivisions?.map(
      (ele) => ele?.vendorDivision?.id))

  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const initialValues = {
    vendor_id: vendorId,
    user_id: viewData?.id,
    employee_id: viewData?.code,
    employee_name: viewData?.name,
    country_code: "91",
    mobile: viewData?.mobile,
    email: viewData?.email,
    designation_id: viewData?.designation_id,
    // division_id: viewData?.division_id,
    divisions: viewData?.fieldForceDivisions?.map(
      (ele) => ele?.vendorDivision?.id
    ),
    head_quaters: viewData?.fieldForceUserHeadQuaters?.map(
      (ele) => ele?.headQuater?.id
    ),
    reporting_manager: viewData?.reporting_manager,
  };
  const resetFunc = (setFieldValue) => {
    setFieldValue("employee_id", viewData?.code);
    setFieldValue("employee_name", viewData?.name);
    setFieldValue("email", viewData?.email);
    setFieldValue("mobile", viewData?.mobile);
    setFieldValue("designation_id", viewData?.designation_id);
    setFieldValue("division_id", viewData?.division_id);
    setFieldValue("divisions", viewData?.fieldForceDivisions?.map((ele) => ele?.vendorDivision?.id));
    setFieldValue("head_quaters", viewData?.fieldForceUserHeadQuaters?.map((ele) => ele?.headQuater?.id));
    setFieldValue("reporting_manager", viewData?.reporting_manager);
  };
  const validationSchema = Yup.object().shape({
    employee_name: Yup.string().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    dispatch(gevFieldForceEdit(values, onSuccess, onError));
  };
  const timeOutFnc = () => {
    setOnshowSuccess(false);
    setActiveFF(81);
  };

  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    const msg = data.data.message;
    const updated_msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    setOnshowSuccessMsg(updated_msg);
    setTimeout(timeOutFnc, 2000);
  };

  const onError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setOnshowErrorMsg(err.data.message);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values }) => (
          <Form className="">
            <div className="search-container d-flex justify-content-between ">
              <div></div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="gev-view-spinner-container">
                <LoadingSpinnerTable />
              </div>
            ) : (
              <div className="scroll-container">
                <div className=" px-4  ">
                  <div className="my-4 mx-4">
                    <label className=" color_747F9E fs_14">User ID</label>
                    <p className="fs_14">{viewData?.user_id}</p>
                  </div>

                  <div className=" px-4 " style={{ maxWidth: "450px" }}>
                    <div className="my-4">
                      <InputCapitalField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Company Employee ID"}
                        type={"text"}
                        placeholder={"Enter company employee id"}
                        name={"employee_id"}
                        value={values?.employee_id}
                        disabled={true}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Employee Name"}
                        type={"text"}
                        placeholder={"Enter employee name"}
                        name={"employee_name"}
                        value={values?.employee_name}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Mobile Number"}
                        type={"text"}
                        placeholder={"Enter mobile number"}
                        name={"mobile"}
                        value={values?.mobile}
                      />
                    </div>
                    <div className="my-4">
                      <InputField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Email"}
                        type={"text"}
                        placeholder={"Enter email"}
                        name={"email"}
                        value={values?.email}
                      />
                    </div>

                    <div className="my-2" style={{ maxWidth: "450px" }}>
                      <label className="color_747f9e fw_400 fs_14 my-1">
                        Designation
                      </label>
                      <Field>
                        {({ form, Field }) => (
                          <Select
                            options={designationOptions}
                            styles={customStyles}
                            {...Field}
                            placeholder={"Select Role"}
                            id="designation_id"
                            value={designationOptions.filter(
                              (c) => c.value == values?.designation_id
                            )}
                            onChange={(selectedOption) => {
                              setSelectedLevel(selectedOption?.approval_level);
                              form.setFieldValue(
                                "designation_id",
                                selectedOption.value
                              );
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="designation_id" />
                    </div>
                    <div className="my-2" style={{ maxWidth: "450px" }}>
                      <label className="color_747f9e fw_400 fs_14 my-1">
                        Division Name
                      </label>
                      {/* <Field>
                        {({ form, Field }) => (
                          <Select
                            options={divisionList}
                            styles={customStyles}
                            {...Field}
                            placeholder={"Select Division"}
                            id="division_id"
                            value={divisionList.filter(
                              (c) => c.value == values?.division_id
                            )}
                            onChange={(selectedOption) => {
                              setSelectedDivision(selectedOption?.value);
                              form.setFieldValue(
                                "division_id",
                                selectedOption.value
                              );
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="division_id" /> */}
                      <Field>
                        {({ form, Field }) => (
                          <Select
                            isMulti
                            options={divisionList}
                            styles={customStyles2}
                            {...Field}
                            placeholder={"Select Divisions"}
                            id="divisions"
                            value={divisionList.filter((o1) =>
                              values?.divisions?.some((o2) => o2 == o1.value)
                            )}
                            onChange={(selectedOption) => {
                              setSelectedDivision(
                                selectedOption
                                  ? selectedOption.map(
                                    (item) => item.value
                                  )
                                  : []
                              );

                              form.setFieldValue(
                                "divisions",
                                selectedOption
                                  ? selectedOption.map((item) => item.value)
                                  : []
                              );
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="divisions" />
                    </div>
                    <div className="my-2" style={{ maxWidth: "450px" }}>
                      <label className="color_747f9e fw_400 fs_14 my-1">
                        Head Quaters
                      </label>

                      <Field>
                        {({ form, Field }) => (
                          <Select
                            isMulti
                            options={headQuaterOptions}
                            styles={customStyles2}
                            {...Field}
                            placeholder={"Select Areas"}
                            id="head_quaters"
                            value={headQuaterOptions.filter((o1) =>
                              values?.head_quaters?.some((o2) => o2 == o1.value)
                            )}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "head_quaters",
                                selectedOption
                                  ? selectedOption.map((item) => item.value)
                                  : []
                              );
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="head_quaters" />
                    </div>

                    <div className="my-2" style={{ maxWidth: "450px" }}>
                      <label className="color_747f9e fw_400 fs_14 my-1">
                        Reporting Manager
                      </label>
                      <Field>
                        {({ form, Field }) => (
                          <Select
                            options={reportingManagerOptions}
                            styles={customStyles}
                            {...Field}
                            placeholder={"Select reporting manager / Superior"}
                            id="reporting_manager"
                            name="reporting_manager"
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "reporting_manager",
                                selectedOption.value
                              );
                            }}
                            value={reportingManagerOptions?.filter(
                              (c) => c?.value == values?.reporting_manager
                            )}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="reporting_manager" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <SuccessPopup show={onShowSuccess} title={onShowSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={onShowErrorMsg}
        handleClose={() => setShowErrorPopup(false)}
      />
    </div>
  );
};

export default FFUserEdit;


export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    height: "43px",

    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
export const customStyles2 = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    // height: "43px",

    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
