import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import InputField from "../../../../components/form/inputField/inputField";
import InputCapitalField from "../../../../components/form/inputField/inputCapitalField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import {
  fetchDoctorList,
  fetchPharmacyList,
  gevProductEdit,
  gevProductView,
  packingTypeList,
  productHeadQuaterList,
  productStateList,
  vendorDivisionList,
} from "../../../../redux/actions/godsEyeViewActions";
import { toast } from "react-toastify";
import Add from "../../../../assets/icons/newplus.svg";
import deleteIcon from "../../../../assets/icons/delete.svg";
import { AiOutlinePlus } from "react-icons/ai";
import "./product.css";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import back from "../../../../assets/icons/back.svg";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import DownArrow from "../../../../assets/icons/filterArrowDown.svg";
import UpArrow from "../../../../assets/icons/filterArrowUp.svg";
import { useNavigate, useParams } from "react-router-dom";

const ProductEdit = ({ vendorId }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate()
  const [productDetailData, setProductDetailData] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [showStateDrop, setShowStateDrop] = useState(false);
  const [selectState, setSelectState] = useState(null);
  const [divisionList, setDivisionList] = useState([]);
  const [packingList, setPackingList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [offerType, setOfferType] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState("");
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openOfferType, setOpenOfferType] = useState([0, -1]); //  OFFER TYPE DROP DOWN OPEN
  const [selectedOtId, setSelectedOtId] = useState(""); // OFFER TYPE SELECT
  const [openHeadQua, setopenHeadQua] = useState([0, -1]); // HEAD QUARTER DROP DOWN OPEN
  const [searchHQ, setSearchHQ] = useState(""); // HEAD QUARTER SELECT
  const [selectedValue, setSelectedValue] = useState(""); // HEAD QUARTER SELECT
  const [selectedHQId, setSelectedHQId] = useState(null); // HEAD QUARTER SELECT
  const [headQuaterOption, setHeadQuaterOption] = useState([]);
  const [openDocList, setOpenDocList] = useState([0, -1]);
  const [DoctorOption, setDoctorOption] = useState([]); // Doc SELECT
  const [selectedDocValue, setSelectedDocValue] = useState(""); // doc SELECT
  const [searchDoc, setSearchDoc] = useState(""); // Doc SELECT
  const [selectedDocId, setSelectedDocId] = useState(""); // DocSELECT
  const [offrDiscount, setOffrDiscount] = useState(false);
  const [pharmacyOption, setPharmacyOption] = useState([]); // Doc SELECT
  const [customerType, setcustomerType] = useState("doctor");
  const selectedProductId = params?.id.split("&")?.[3];
  const [deletedOfferId, setDeletedOfferId] = useState([])
  const [deletedStateOfferId, setDeletedStateOfferId] = useState([])


  const handleOptionClick = (value) => {
    // HEAD QUARTER SELECT
    setSelectedValue(value);
  };
  const filteredHQData = headQuaterOption.filter((item) =>
    item?.label?.toLocaleLowerCase().includes(searchHQ)
  );
  const handleDocOptionClick = (value) => {
    // HEAD QUARTER SELECT
    setSelectedDocValue(value);
  };

  const filteredDocData = DoctorOption.filter(
    (item) =>
      item?.label?.toLocaleLowerCase().includes(searchDoc?.toLocaleLowerCase()) ||
      item?.code?.toLocaleLowerCase().includes(searchDoc?.toLocaleLowerCase())
  );

  const filteredPharmacyData = pharmacyOption.filter(
    (item) =>
      item?.label?.toLocaleLowerCase().includes(searchDoc?.toLocaleLowerCase()) ||
      item?.code?.toLocaleLowerCase().includes(searchDoc?.toLocaleLowerCase())
  );

  useEffect(() => {
    dispatch(packingTypeList(onPackingSuccess, onPackingError));
    gevProductViewFnc();
    dispatch(productStateList(onStateFetchSuccess, onStateFetchError));
    dispatch(vendorDivisionList(vendorId, onDevisionSuccess, onDevisionError));
    dispatch(productHeadQuaterList(vendorId, onFetchHqSuccess, onFetchHqError));
    dispatch(fetchDoctorList(vendorId, onFetchDoctorSuccess, onFetchDoctorError));
    dispatch(fetchPharmacyList(vendorId, onFetchPharmacySuccess, onFetchPharmacyError));
  }, []);

  const onFetchHqSuccess = (data) => {
    console.log(data);
    setHeadQuaterOption(data);
  };
  const onFetchHqError = (err) => {
    console.log(err);
  };

  const gevProductViewFnc = () => {
    setLoading(true);
    dispatch(gevProductView(selectedProductId, onffSuccess, onffError));
  };
  const onFetchDoctorSuccess = (data) => {
    console.log(data, "doc data");
    setDoctorOption(data);
  };
  const onFetchDoctorError = (err) => {
    console.log(err);
  };
  const onFetchPharmacySuccess = (data) => {
    console.log(data, "pharmacy data");
    setPharmacyOption(data);
  };
  const onFetchPharmacyError = (err) => {
    console.log(err);
  };

  const onffSuccess = (data) => {
    let productDetailData = data.data.data;
    setProductDetailData(data?.data?.data);
    setOfferType(data?.data?.data?.vendorDivision?.offer_type);
    setLoading(false);

    setOffrDiscount(
      productDetailData.productStateOffers?.forEach((ele) =>
        ele.offers.forEach((ex) => ex.offer_type)
      )
    );
    setProductDetailData(productDetailData);


    productDetailData.productStateOffers.forEach((ele) => {
      if (!ele?.is_default) {
        ele.offers.forEach((itm) => {
          if (Array.isArray(itm.doctorProductOffers) && itm.doctorProductOffers.length > 0) {
            // Map the array to get the code of each doctor and join them with commas
            itm.doctor_code = itm.doctorProductOffers.map((doctor) => doctor.doctor.code).join(",");
          } else {
            itm.doctor_code = ''; // Handle the case where there are no doctorProductOffers
          }
        });
      }
    });

    productDetailData.productStateOffers.forEach((ele) => {
      if (!ele?.is_default) {
        ele.offers.forEach((itm) => {
          if (Array.isArray(itm.pharmacyProductOffers) && itm.pharmacyProductOffers.length > 0) {
            // Map the array to get the code of each doctor and join them with commas
            itm.pharmacy_code = itm.pharmacyProductOffers.map((pharmacy) => pharmacy.pharmacy.code).join(",");
          } else {
            itm.pharmacy_code = ''; // Handle the case where there are no doctorProductOffers
          }
        });
      }
    });


  };

  const onffError = (err) => {
    setLoading(false);
    console.log(err);
  };

  const onStateFetchSuccess = (data) => {
    let newArr = data.data.data.states.map((ele) => ({
      id: ele.id,
      label: ele.name,
    }));
    setStateOption(newArr);
  };
  const onStateFetchError = (err) => {
    console.log(err);
  };

  const onPackingSuccess = (data) => {
    console.log(data, "pack data");
    setPackingList(data);
  };

  const onPackingError = (err) => {
    console.log(err);
  };
  const onDevisionSuccess = (data) => {
    setDivisionList(data);
    console.log(data);
  };

  const onDevisionError = (err) => {
    console.log(err);
  };

  const addMoreOffer = (values, index, setFieldValue) => {
    let value = values?.product_offers[`${index}`].offers;
    value.push({
      min_qty: values?.product_offers?.[0].offers[0].min_qty || "",
      max_qty: values?.product_offers?.[0].offers[0].max_qty || "",
      integral_qty: values?.product_offers?.[0].offers[0].integral_qty || "",
      mrp: values?.product_offers?.[0].offers[0].mrp || "",
      ptr: values?.product_offers?.[0].offers[0].ptr || "",
      pts: values?.product_offers?.[0].offers[0].pts || "",
      offer: "",
      offer_type: offerTypeOptions.filter((offert) => offert.value === offerType)[0].value || "",
      offer_qty: "",
      after_off: "",
      head_quater_id: "",
      doctor_code: "",
      pharmacy_code: "",
    });
    console.log(value, index);
    setFieldValue(`values.product_offers[${index}].offers`, value);
  };

  const closeErrorPopup1 = () => {
    setShowErrorPopup1(false);
  };

  const addMoreState = (values, setFieldValue) => {
    let value = values?.product_offers;
    console.log(selectState);
    if (values?.product_offers.find((ele) => ele.state_id == selectState)) {
      setShowErrorPopup1(true);
      setShowErrorMsg1("Selected state offer already exists");
    } else {
      if (selectState != null) {
        const newData = {
          state_id: selectState,
          is_default: false,
          offers: [
            {
              min_qty: values?.product_offers?.[0].offers[0].min_qty || "",
              max_qty: values?.product_offers?.[0].offers[0].max_qty || "",
              integral_qty: values?.product_offers?.[0].offers[0].integral_qty || "",
              mrp: values?.product_offers?.[0].offers[0].mrp || "",
              ptr: values?.product_offers?.[0].offers[0].ptr || "",
              pts: values?.product_offers?.[0].offers[0].pts || "",
              offer: "",
              offer_type: offerTypeOptions.filter((offert) => offert.value === offerType)[0].value || "",
              offer_qty: "",
              after_off: "",
              head_quater_id: "",
              doctor_code: "",
              pharmacy_code: "",
            },
          ],
        };
        value.push(newData);
        setFieldValue(`values.product_offers`, value);
        setShowStateDrop(!showStateDrop);
      } else {
        toast.warning("Select a state", {
          position: "bottom-right",
          theme: "light",
        });
      }
    }
  };
  // const deleteOffer = (values, index, idx, setFieldValue) => {
  //   if (values?.product_offers[`${index}`].offers.length == 1) {
  //     values?.product_offers[`${index}`]?.offers.splice(idx, 1);
  //     values?.product_offers.splice(index, 1);
  //   } else {
  //     values?.product_offers[`${index}`]?.offers.splice(idx, 1);
  //   }
  //   console.log(values);
  //   setFieldValue(values);
  // };
  const deleteOffer = (values, index, idx, setFieldValue) => {
    if (values?.product_offers[`${index}`].offers.length == 1) {
      const offerToDelete = values?.product_offers[`${index}`]?.id;
      if (offerToDelete) {
        setDeletedStateOfferId([...deletedStateOfferId, offerToDelete]);
      }
      values?.product_offers[`${index}`]?.offers.splice(idx, 1);
      values?.product_offers.splice(index, 1);
    } else {
      const offerToDelete = values?.product_offers[`${index}`]?.offers?.[idx]?.id;
      if (offerToDelete) {
        setDeletedOfferId([...deletedOfferId, offerToDelete]);
      }
      values?.product_offers[`${index}`]?.offers.splice(idx, 1);
    }
    console.log(values);
    setFieldValue(values);
  };

  const initialValues = {
    state_select: "",
    vendor_id: vendorId,
    company_product_id: productDetailData?.company_product_id,
    product_name: productDetailData?.product_name,
    division_id: productDetailData?.division_id,
    packing_type_id: productDetailData?.productPackingDetail?.packing_type_id,

    ships_box_quantity: productDetailData?.productPackingDetail
      ?.ships_box_quantity
      ? productDetailData?.productPackingDetail?.ships_box_quantity
      : null,
    product_offers: productDetailData?.productStateOffers,
  };

  const validationSchema = Yup.object().shape({
    company_product_id: Yup.string().required("Required"),
    product_name: Yup.string().required("Required"),
    division_id: Yup.string().required("Required"),
    // packing_type_id: Yup.string().required("Required"),
    // ships_box_quantity: Yup.string().required("Required").nullable(""),
  });

  const resetFunc = (setFieldValue) => {
    setFieldValue("company_product_id", productDetailData?.company_product_id);
    setFieldValue("product_name", productDetailData?.product_name);
    setFieldValue("division_id", productDetailData?.division_id);
    setFieldValue(
      "packing_type_id",
      productDetailData?.productPackingDetail?.packing_type_id
    );

    setFieldValue(
      "ships_box_quantity",
      productDetailData?.productPackingDetail?.ships_box_quantity
    );
    setFieldValue("product_offers", productDetailData?.productStateOffers);
  };

  const onSubmit = (values) => {
    values["product_id"] = productDetailData.id;

    let newValues = values;
    if (offerType == "discount") {
      newValues?.product_offers?.forEach((ele) =>
        ele.offers.forEach(
          (ex) => (ex.after_off = ex.ptr - (ex.ptr / 100) * ex.offer)
        )
      );
    } else if (offerType == "price") {
      newValues?.product_offers?.forEach((ele) =>
        ele.offers.forEach((ex) => (ex.after_off = ex.ptr - ex.offer))
      );
    }
    // newValues?.product_offers?.forEach((ele) =>
    //   ele.offers.forEach(
    //     (ex) => (ex.head_quater_id = ex?.head_quater_id?.value)
    //   )
    // );
    // newValues?.product_offers?.forEach((ele) =>
    //   ele.offers.forEach((ex) => (ex.pharmacy_code = ex?.pharmacy_code?.code))
    // );
    // newValues?.product_offers?.forEach((ele) =>
    //   ele.offers.forEach((ex) => (ex.doctor_code = ex?.doctor_code?.code))
    // );

    newValues?.product_offers?.forEach((ele) =>
      ele.offers.forEach((ex) => (ex.offer_type = ex?.offer_type))
    );
    newValues.deletedStateOfferId = deletedStateOfferId;
    newValues.deletedOfferId = deletedOfferId;
    console.log(newValues);
    dispatch(gevProductEdit(newValues, onSuccess, onError));
  };
  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setShowEditPopup(true);
    setTimeout(timeOutFnc, 2000);
    setShowEdit(false);
  };
  const timeOutFnc = () => {
    setOnshowSuccess(false);

    navigate(`/manufacturer/subscibe-list/view/${vendorId}&products&list&1`);

    setShowEditPopup(false);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
  };

  console.log(
    stateOption?.find((item) => item.id == selectState),
    "stateeeeeeee"
  );

  return (
    <>
      <div className=" my-2 py-1">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="search-container d-flex justify-content-between ">
                <div></div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    onClick={() => resetFunc(setFieldValue)}
                    type="button"
                  >
                    RESET
                  </AdminButton>
                  <AdminButton
                    background="#2AD3E7"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="scroll-container" style={{ height: "40rem" }}>
                {loading == true ? (
                  <div className="gev-view-spinner-container">
                    <LoadingSpinnerTable />
                  </div>
                ) : (
                  <>
                    <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                      Basic Details
                    </div>
                    <div className="form-width ms-3">
                      <div className="px-4 py-1">
                        <InputField
                          id={"company_product_id"}
                          label={"Company’s Product ID"}
                          labelColor={"color_747f9e"}
                          type={"text"}
                          placeholder={"Enter Company’s Product ID"}
                          name={"company_product_id"}
                          value={values?.company_product_id}
                        />
                      </div>
                      <div className="px-4 ">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"product_name"}
                          label={"Product Name"}
                          labelColor={"color_747f9e"}
                          type={"text"}
                          placeholder={"Enter product name"}
                          name={"product_name"}
                          value={values?.product_name}
                        />
                      </div>
                      <div className="px-4 py-1 pb-4">
                        <label className="color_747f9e fw_400 fs_14">
                          Division Name
                        </label>
                        <br></br>
                        <label className="color_747f9e fw_400 fs_14">
                          {productDetailData?.vendorDivision?.name}
                        </label>
                      </div>
                    </div>
                    <>
                      <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                        Packaging Details Per Piece
                      </div>
                      <div className="form-width ms-3">
                        <div className="px-4 py-1 pb-4">
                          <label className="color_747f9e fw_400 fs_14">
                            Type of Packaging
                          </label>
                          <Field>
                            {({ form, Field }) => (
                              <Select
                                options={packingList}
                                styles={customStyles}
                                {...Field}
                                placeholder={"Select type of packaging"}
                                id="packing_type_id"
                                value={packingList.find(
                                  (c) => c.value == values?.packing_type_id
                                )}
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    "packing_type_id",
                                    selectedOption.value
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="px-4 py-1">
                          <label className="fw_400 fs_14 my-1  color_747f9e ">
                            Shipper Pack
                          </label>
                          <div className=" d-flex align-items-center py-2 input_select_container">
                            <Field name={"ships_box_quantity"}>
                              {({ field, meta }) => (
                                <input
                                  type="number"
                                  className="input-holding   py-1 ms- mx-2"
                                  {...field}
                                  id="ships_box_quantity"
                                  name="ships_box_quantity"
                                  placeholder="Enter ships in box quantity"
                                />
                              )}
                            </Field>
                            <div
                              className="mx-2 me-3 ps-2 text-center"
                              style={{ borderLeft: "1px solid #747F9E" }}
                            >
                              <span className="Medium_Purple fw_500 fn_Nunito">
                                {packingList?.map((ele) => {
                                  if (ele.value == values?.packing_type_id) {
                                    return ele.label;
                                  }
                                })}
                              </span>
                            </div>
                          </div>
                          <ErrorMsg name="ships_box_quantity" />
                        </div>
                      </div>
                    </>
                    <>
                      <div className="d-flex text-left px-3 mx-4 my-3 py-1 form-sub-details fw_600">
                        Offers, Quantity and Pricing Details
                      </div>
                      <div className="default-container ms-4 ps-3 pb-4">
                        <div className="py-1">
                          <p className="color_262c3d fn_Nunito">
                            Offer Type :
                            <span className="color_2ad3e7 ms-2 fw_500">
                              {divisionList?.map((ele) => {
                                if (ele.value == values?.division_id) {
                                  return ele.offer_type == "quantity"
                                    ? setOfferType("quantity")
                                    : ele.offer_type == "discount"
                                      ? setOfferType("discount")
                                      : ele.offer_type == "price"
                                        ? setOfferType("price")
                                        : "";
                                }
                              })}
                              {offerType === "quantity"
                                ? " Quantity"
                                : offerType === "discount"
                                  ? " Discount"
                                  : offerType == "price"
                                    ? " Price"
                                    : "Select a division"}
                            </span>
                          </p>
                        </div>
                        <>
                          <div className=" py-1">
                            <span className="color_2ad3e7 fw_500">Default</span>
                            {values?.product_offers?.map(
                              (ele, index) =>
                                ele?.is_default && (
                                  <div
                                    className="default-content-container ms-4 ps-2 py-4"
                                    key={index}
                                  >
                                    {ele.offers.map((ex, idx) => (
                                      <div className="d-flex" key={idx}>
                                        <div className="id-container-number">
                                          <p className="label-id">ID</p>
                                          <p className="id mt-1">#{idx + 1}</p>
                                        </div>
                                        <div className="d-flex overflow-scroll pb-2">
                                          <div className="id-container">
                                            <p className="label-id">Mini Qty</p>
                                            <div className="quantity-container1">
                                              <div className="id-number1">
                                                <Field
                                                  className="input1"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].min_qty`}
                                                />
                                              </div>
                                              <div className="units">
                                                {values.holding_value_unit}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="id-container">
                                            <p className="label-id">Max Qty</p>
                                            <div className="quantity-container1">
                                              <div className="id-number1">
                                                <Field
                                                  className="input1"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].max_qty`}
                                                />
                                              </div>
                                              <div className="units">
                                                {values.holding_value_unit}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="id-container">
                                            <p className="label-id">
                                              Integral Qty
                                            </p>
                                            <div className="quantity-container1">
                                              <div className="id-number1">
                                                <Field
                                                  className="input1"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].integral_qty`}
                                                />
                                              </div>
                                              <div className="units">
                                                {values.holding_value_unit}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="id-container">
                                            <p className="label-id">MRP</p>
                                            <div className="quantity-container2">
                                              <span className="Medium_Purple fs_14 fw_600">
                                                ₹.
                                              </span>
                                              <div className="id-number2">
                                                <Field
                                                  className="input2"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].mrp`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="id-container">
                                            <p className="label-id">PTR</p>
                                            <div className="quantity-container2">
                                              <span className="Medium_Purple fs_14 fw_600">
                                                ₹.
                                              </span>
                                              <div className="id-number2">
                                                <Field
                                                  className="input2"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].ptr`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="id-container">
                                            <p className="label-id">PTS</p>
                                            <div className="quantity-container2">
                                              <span className="Medium_Purple fs_14 fw_600">
                                                ₹.
                                              </span>
                                              <div className="id-number2">
                                                <Field
                                                  className="input2"
                                                  type="number"
                                                  placeholder="Enter"
                                                  name={`product_offers[${index}].offers[${idx}].pts`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {divisionList?.map((ele, didx) => {
                                            if (
                                              ele.value == values?.division_id
                                            ) {
                                              return (
                                                <>
                                                  <span key={didx}></span>
                                                  {ele.offer_type ==
                                                    "quantity" && (
                                                      <>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            Quantity
                                                          </p>
                                                          <div className="quantity-container1">
                                                            <div className="id-number1">
                                                              <Field
                                                                className="input1"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].offer`}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            Offer Qty
                                                          </p>
                                                          <div className="quantity-container1">
                                                            <div className="id-number1">
                                                              <Field
                                                                className="input1"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].offer_qty`}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  {ele.offer_type ==
                                                    "price" && (
                                                      <>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            Offer
                                                          </p>
                                                          <div className="quantity-container2">
                                                            <span className="Medium_Purple fs_14 fw_600">
                                                              ₹.
                                                            </span>
                                                            <div className="id-number2">
                                                              <Field
                                                                className="input2"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].offer`}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            Off Price
                                                          </p>
                                                          <div className="quantity-container2">
                                                            <span className="Medium_Purple fs_14 fw_600">
                                                              ₹.
                                                            </span>
                                                            <div className="id-number2">
                                                              <Field
                                                                className="input2"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].after_off`}
                                                                disabled={true}
                                                                value={
                                                                  values
                                                                    ?.product_offers[
                                                                    index
                                                                  ].offers[idx]
                                                                    .offer
                                                                    ? values
                                                                      ?.product_offers[
                                                                      index
                                                                    ].offers[
                                                                      idx
                                                                    ].ptr -
                                                                    values
                                                                      ?.product_offers[
                                                                      index
                                                                    ].offers[
                                                                      idx
                                                                    ].offer
                                                                    : values
                                                                      ?.product_offers[
                                                                      index
                                                                    ]?.offers[
                                                                      idx
                                                                    ]?.ptr
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  {ele.offer_type ==
                                                    "discount" && (
                                                      <>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            Offer Discount
                                                          </p>
                                                          <div className="quantity-container1">
                                                            <div className="id-number1">
                                                              <Field
                                                                className="input1"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].offer`}
                                                              />
                                                            </div>
                                                            <div className="Medium_Purple">
                                                              %
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="id-container">
                                                          <p className="label-id">
                                                            After Off
                                                          </p>
                                                          <div className="quantity-container2">
                                                            <span className="Medium_Purple fs_14 fw_600">
                                                              ₹.
                                                            </span>
                                                            <div className="id-number2">
                                                              <Field
                                                                className="input2"
                                                                type="number"
                                                                placeholder="Enter"
                                                                name={`product_offers[${index}].offers[${idx}].after_off`}
                                                                disabled={true}
                                                                value={
                                                                  values
                                                                    ?.product_offers[
                                                                    index
                                                                  ].offers[idx]
                                                                    .offer
                                                                    ? values
                                                                      ?.product_offers[
                                                                      index
                                                                    ].offers[
                                                                      idx
                                                                    ].ptr -
                                                                    (values
                                                                      ?.product_offers[
                                                                      index
                                                                    ].offers[
                                                                      idx
                                                                    ].ptr *
                                                                      values
                                                                        ?.product_offers[
                                                                        index
                                                                      ].offers[
                                                                        idx
                                                                      ].offer) /
                                                                    100
                                                                    : values
                                                                      ?.product_offers[
                                                                      index
                                                                    ]?.offers[
                                                                      idx
                                                                    ]?.ptr
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )
                            )}
                          </div>
                        </>
                        <div className="default-container">
                          {values?.product_offers?.map(
                            (ele, index) =>
                              !ele?.is_default && (
                                <div
                                  className="default-content-container ms-4 ps-2 py-4"
                                  key={index}
                                >
                                  <div
                                    className={
                                      offerType == "quantity"
                                        ? `d-flex justify-content-between Add-more-table-quantity-container`
                                        : `d-flex justify-content-between Add-more-table-container`
                                    }
                                  >
                                    <span className="color_2ad3e7 fs_16">
                                      {stateOption.find(
                                        (st) => st.id == ele?.state_id
                                      ) &&
                                        stateOption.find(
                                          (st) => st.id == ele?.state_id
                                        )["label"] &&
                                        stateOption.find(
                                          (st) => st.id == ele?.state_id
                                        )["label"]}
                                    </span>
                                    <div className="add-more-button-container my-2">
                                      <AiOutlinePlus
                                        size={16}
                                        color="#7065E4"
                                      />
                                      <button
                                        className="add-button1"
                                        type="button"
                                        onClick={() => {
                                          addMoreOffer(
                                            values,
                                            index,
                                            setFieldValue
                                          );
                                        }}
                                      >
                                        Add more offer
                                      </button>
                                    </div>
                                  </div>
                                  {ele.offers.map((ex, idx) => (
                                    <div className="d-flex" key={idx}>
                                      <div className="id-container-number">
                                        <p className="label-id">ID</p>
                                        <p className="id mt-1">#{idx + 1}</p>
                                      </div>
                                      <div className="d-flex overflow-scroll pb-2">
                                        <div className="id-container">
                                          <p className="label-id">Mini Qty</p>
                                          <div className="quantity-container1">
                                            <div className="id-number1">
                                              <Field
                                                className="input1"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].min_qty`}
                                              />
                                            </div>
                                            <div className="units">
                                              {values.holding_value_unit}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="id-container">
                                          <p className="label-id">Max Qty</p>
                                          <div className="quantity-container1">
                                            <div className="id-number1">
                                              <Field
                                                className="input1"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].max_qty`}
                                              />
                                            </div>
                                            <div className="units">
                                              {values.holding_value_unit}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="id-container">
                                          <p className="label-id">
                                            Integral Qty
                                          </p>
                                          <div className="quantity-container1">
                                            <div className="id-number1">
                                              <Field
                                                className="input1"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].integral_qty`}
                                              />
                                            </div>
                                            <div className="units">
                                              {values.holding_value_unit}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="id-container">
                                          <p className="label-id">MRP</p>
                                          <div className="quantity-container2">
                                            <span className="Medium_Purple fs_14 fw_600">
                                              ₹.
                                            </span>
                                            <div className="id-number2">
                                              <Field
                                                className="input2"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].mrp`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="id-container">
                                          <p className="label-id">PTR</p>
                                          <div className="quantity-container2">
                                            <span className="Medium_Purple fs_14 fw_600">
                                              ₹.
                                            </span>
                                            <div className="id-number2">
                                              <Field
                                                className="input2"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].ptr`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="id-container">
                                          <p className="label-id">PTS</p>
                                          <div className="quantity-container2">
                                            <span className="Medium_Purple fs_14 fw_600">
                                              ₹.
                                            </span>
                                            <div className="id-number2">
                                              <Field
                                                className="input2"
                                                type="number"
                                                placeholder="Enter"
                                                name={`product_offers[${index}].offers[${idx}].pts`}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="id-container ">
                                          <p className="label-id">Offer Type</p>
                                          <div className=" d-flex align-items-center py-2 input_select_container">
                                            <div
                                              style={{
                                                width: "120px",
                                                height: "23px",
                                              }}
                                              className={`input-holding px-1 mx-2 text-capitalize`}
                                            >
                                              {values?.product_offers[index]
                                                ?.offers[idx]?.offer_type
                                                ? values?.product_offers[index]
                                                  ?.offers[idx]?.offer_type
                                                : "Select offer type"}
                                            </div>
                                            <Field
                                              className="input2 w-100 d-none"
                                              type="text"
                                              placeholder="Enter offer type"
                                              name={`product_offers[${index}].offers[${idx}].offer_type`}
                                            />
                                            <div className="mx-2 me-3 text-center">
                                              <span
                                                onClick={() => {
                                                  if (
                                                    openOfferType[0] == index &&
                                                    openOfferType[1] == idx
                                                  ) {
                                                    setOpenOfferType([0, -1]);
                                                  } else {
                                                    setOpenOfferType([
                                                      index,
                                                      idx,
                                                    ]);
                                                  }
                                                }}
                                                className=" Medium_Purple"
                                              >
                                                {openOfferType[0] == index &&
                                                  openOfferType[1] == idx ? (
                                                  <img
                                                    src={UpArrow}
                                                    className="mb-1 mx-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={DownArrow}
                                                    className=" mx-1"
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="">
                                            {openOfferType[0] == index &&
                                              openOfferType[1] == idx ? (
                                              <div className=" position-relative">
                                                <div className="offertype-container">
                                                  <div
                                                    className="d-flex flex-column cursor_pointer"
                                                    style={{
                                                      overflowX: "hidden",
                                                      overflowY: "scroll",
                                                      height: "7.5rem",
                                                    }}
                                                  >
                                                    {offerTypeOptions.map(
                                                      (ele, indexxxx) => {
                                                        return (
                                                          <div
                                                            className={` ${ele.value ==
                                                              selectedOtId
                                                              ? "selected-opt px-3 py-1"
                                                              : " px-3 py-1"
                                                              }`}
                                                            key={indexxxx}
                                                          >
                                                            <div
                                                              className="text-capitilize"
                                                              onClick={() => {
                                                                {
                                                                  setOpenOfferType(
                                                                    [0, -1]
                                                                  );
                                                                  setSelectedOtId(
                                                                    ele
                                                                  );
                                                                  setFieldValue(
                                                                    `product_offers[${index}].offers[${idx}].offer_type`,
                                                                    ele.value
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {ele.label}
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>

                                        {values?.product_offers[index].offers[
                                          idx
                                        ].offer_type == "discount" && (
                                            <>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  Offer Discount
                                                </p>
                                                <div className="quantity-container1">
                                                  <div className="id-number1">
                                                    <Field
                                                      className="input1"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].offer`}
                                                    />
                                                  </div>
                                                  <div className="Medium_Purple">
                                                    %
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  After Off
                                                </p>
                                                <div className="quantity-container2">
                                                  <span className="Medium_Purple fs_14 fw_600">
                                                    ₹.
                                                  </span>
                                                  <div className="id-number2">
                                                    <Field
                                                      className="input2"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].after_off`}
                                                      disabled={true}
                                                      value={
                                                        values?.product_offers[
                                                          index
                                                        ].offers[idx].offer
                                                          ? values
                                                            ?.product_offers[
                                                            index
                                                          ].offers[idx].ptr -
                                                          (values
                                                            ?.product_offers[
                                                            index
                                                          ].offers[idx].ptr *
                                                            values
                                                              ?.product_offers[
                                                              index
                                                            ].offers[idx]
                                                              .offer) /
                                                          100
                                                          : values
                                                            ?.product_offers[
                                                            index
                                                          ]?.offers[idx]?.ptr
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        {values?.product_offers[index].offers[
                                          idx
                                        ].offer_type == "price" && (
                                            <>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  Off Price
                                                </p>
                                                <div className="quantity-container2">
                                                  <span className="Medium_Purple fs_14 fw_600">
                                                    ₹.
                                                  </span>
                                                  <div className="id-number2">
                                                    <Field
                                                      className="input2"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].offer`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  Net Rate
                                                </p>
                                                <div className="quantity-container2">
                                                  <span className="Medium_Purple fs_14 fw_600">
                                                    ₹.
                                                  </span>
                                                  <div className="id-number2">
                                                    <Field
                                                      className="input2"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].after_off`}
                                                      disabled={true}
                                                      value={
                                                        values?.product_offers[
                                                          index
                                                        ].offers[idx].offer
                                                          ? values
                                                            ?.product_offers[
                                                            index
                                                          ].offers[idx].ptr -
                                                          values
                                                            ?.product_offers[
                                                            index
                                                          ].offers[idx].offer
                                                          : values
                                                            ?.product_offers[
                                                            index
                                                          ].offers[idx].ptr
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        {values?.product_offers[index].offers[
                                          idx
                                        ].offer_type == "quantity" && (
                                            <>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  Quantity
                                                </p>
                                                <div className="quantity-container1">
                                                  <div className="id-number1">
                                                    <Field
                                                      className="input1"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].offer`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="id-container">
                                                <p className="label-id">
                                                  Offer Qty
                                                </p>
                                                <div className="quantity-container1">
                                                  <div className="id-number1">
                                                    <Field
                                                      className="input1"
                                                      type="number"
                                                      placeholder="Enter"
                                                      name={`product_offers[${index}].offers[${idx}].offer_qty`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        {/* <div className="id-container ">
                                          <p className="label-id">
                                            Head Quarters
                                          </p>
                                          <div className=" d-flex align-items-center py-2 input_select_container">
                                            <div
                                              style={{
                                                width: "180px",
                                                height: "23px",
                                              }}
                                              className={`input-holding px-1 mx-2 `}
                                            >
                                              {values?.product_offers[index]
                                                ?.offers[idx]?.head_quater_id
                                                ?.value
                                                ? values?.product_offers[index]
                                                    ?.offers[idx]
                                                    ?.head_quater_id?.label
                                                : "Select an option"}
                                            </div>
                                            <Field
                                              className="input2 w-100 d-none"
                                              type="text"
                                              placeholder="Enter Headquater Name"
                                              name={`product_offers[${index}].offers[${idx}].head_quater_id`}
                                            />

                                            <div className="mx-2 me-3 text-center">
                                              <span
                                                onClick={() => {
                                                  if (
                                                    openHeadQua[0] == index &&
                                                    openHeadQua[1] == idx
                                                  ) {
                                                    setopenHeadQua([0, -1]);
                                                  } else {
                                                    setopenHeadQua([
                                                      index,
                                                      idx,
                                                    ]);
                                                  }
                                                }}
                                                className="cursor_pointer Medium_Purple"
                                              >
                                                {openHeadQua[0] == index &&
                                                openHeadQua[1] == idx ? (
                                                  <img
                                                    src={UpArrow}
                                                    className="mb-1 mx-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={DownArrow}
                                                    className=" mx-1"
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="">
                                            {openHeadQua[0] == index &&
                                            openHeadQua[1] == idx ? (
                                              <div className=" position-relative">
                                                <div className="product-container">
                                                  <div className="mx-2">
                                                    <div className=" d-flex py-1">
                                                      <input
                                                        type="text"
                                                        className="py-1 ms-1 product-input-container"
                                                        placeholder="Search Here"
                                                        id="text"
                                                        onChange={(e) =>
                                                          setSearchHQ(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="d-flex flex-column cursor_pointer"
                                                    style={{
                                                      overflowX: "hidden",
                                                      overflowY: "scroll",
                                                      height: "7.5rem",
                                                    }}
                                                  >
                                                    {filteredHQData.map(
                                                      (ele, indexxxx) => {
                                                        return (
                                                          <div
                                                            className={` ${
                                                              ele.value ==
                                                              selectedHQId
                                                                ? "selected-opt px-3 py-1"
                                                                : " px-3 py-1"
                                                            }`}
                                                            key={indexxxx}
                                                          >
                                                            <div
                                                              onChange={(e) =>
                                                                selectedValue(
                                                                  e.target.value
                                                                )
                                                              }
                                                              onClick={() => {
                                                                {
                                                                  handleOptionClick(
                                                                    ele.label
                                                                  );
                                                                  setopenHeadQua(
                                                                    [0, -1]
                                                                  );
                                                                  setSelectedHQId(
                                                                    ele.value
                                                                  );
                                                                  setFieldValue(
                                                                    `product_offers[${index}].offers[${idx}].doctor_code`,
                                                                    null
                                                                  );
                                                                  setFieldValue(
                                                                    `product_offers[${index}].offers[${idx}].pharmacy_code`,
                                                                    null
                                                                  );
                                                                  setFieldValue(
                                                                    `product_offers[${index}].offers[${idx}].head_quater_id`,
                                                                    ele
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {ele.label}
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div> */}
                                        <div className="id-container">
                                          <p className="label-id">
                                            Link Customer
                                          </p>
                                          <div className=" d-flex align-items-center py-2 input_select_container">
                                            <div
                                              style={{
                                                width: "180px",
                                                height: "23px",
                                              }}
                                              className={`input-holding px-1 mx-2 `}
                                            >

                                              {values?.product_offers[index]?.offers[idx]?.doctor_code
                                                ? `${values?.product_offers[index]?.offers[idx]?.doctor_code?.split(',')[0]}`
                                                : values?.product_offers[index]?.offers[idx]?.pharmacy_code
                                                  ? values?.product_offers[index]?.offers[idx]?.pharmacy_code?.split(',')[0] : "Select customer ID"}
                                              <span title={`${values?.product_offers[index]?.offers[idx]?.doctor_code},${values?.product_offers[index]?.offers[idx]?.pharmacy_code}`} className="Medium_Purple cursor_pointer">
                                                {(() => {
                                                  const doctorCodes = values?.product_offers[index]?.offers[idx]?.doctor_code?.split(',').filter(Boolean) || [];
                                                  const pharmacyCodes = values?.product_offers[index]?.offers[idx]?.pharmacy_code?.split(',').filter(Boolean) || [];

                                                  const totalCodesCount = doctorCodes.length + pharmacyCodes.length;
                                                  const displayCount = totalCodesCount > 1 ? totalCodesCount - 1 : 0;

                                                  return displayCount > 0 ? ` +${displayCount}` : null;
                                                })()}
                                              </span>
                                            </div>
                                            <Field
                                              className="input2 w-100 d-none"
                                              type="text"
                                              placeholder="Enter Doctor ID"
                                              name={`product_offers[${index}].offers[${idx}].doctor_code`}
                                            />
                                            <div className="mx-2 me-3 text-center">
                                              <span
                                                onClick={() => {
                                                  if (
                                                    openDocList[0] == index &&
                                                    openDocList[1] == idx
                                                  ) {
                                                    setOpenDocList([0, -1]);
                                                  } else {
                                                    setOpenDocList([
                                                      index,
                                                      idx,
                                                    ]);
                                                  }
                                                  setSelectedHQId(
                                                    values?.product_offers[
                                                      index
                                                    ]?.offers[idx]
                                                      ?.head_quater_id?.value
                                                  );
                                                  setSelectedDocId(
                                                    values?.product_offers[
                                                      index
                                                    ]?.offers[idx]?.doctor_code
                                                      ?.value
                                                      ? values?.product_offers[
                                                        index
                                                      ]?.offers[idx]
                                                        ?.doctor_code?.value
                                                      : values?.product_offers[
                                                        index
                                                      ]?.offers[idx]
                                                        ?.pharmacy_code?.value
                                                        ? values?.product_offers[
                                                          index
                                                        ]?.offers[idx]
                                                          ?.pharmacy_code?.value
                                                        : null
                                                  );
                                                }}
                                                className="cursor_pointer Medium_Purple"
                                              >
                                                {openDocList[0] == index &&
                                                  openDocList[1] == idx ? (
                                                  <img
                                                    src={UpArrow}
                                                    className="mb-1 mx-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={DownArrow}
                                                    className=" mx-1"
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            {openDocList[0] == index &&
                                              openDocList[1] == idx ? (
                                              <div className=" position-relative">
                                                <div className="product-container">
                                                  <div className="d-flex mx-2 py-1 gap-3 cursor_pointer">
                                                    <div
                                                      className={
                                                        customerType == "doctor"
                                                          ? "Medium_Purple fw_600"
                                                          : "color_747F9E"
                                                      }
                                                      onClick={() =>
                                                        setcustomerType(
                                                          "doctor"
                                                        )
                                                      }
                                                    >
                                                      Doctor
                                                    </div>
                                                    <div
                                                      className={
                                                        customerType ==
                                                          "pharmacy"
                                                          ? "Medium_Purple fw_600"
                                                          : "color_747F9E"
                                                      }
                                                      onClick={() =>
                                                        setcustomerType(
                                                          "pharmacy"
                                                        )
                                                      }
                                                    >
                                                      Pharmacy
                                                    </div>
                                                  </div>
                                                  <>
                                                    <div className="mx-2">
                                                      <div className=" d-flex py-1">
                                                        <input
                                                          type="text"
                                                          className="py-1 ms-1 product-input-container"
                                                          placeholder="Search Here"
                                                          id="text"
                                                          onChange={(e) =>
                                                            setSearchDoc(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>

                                                    {customerType ==
                                                      "doctor" && (
                                                        <div
                                                          className="d-flex flex-column py-3"
                                                          style={{
                                                            overflowX: "hidden",
                                                            overflowY: "scroll",
                                                            height: "7.5rem",
                                                          }}
                                                        >
                                                          {filteredDocData.map(
                                                            (ele, indexxxx) => {
                                                              let selectedArray = values.product_offers[index].offers[idx].doctor_code?.split(",") || [];

                                                              return (
                                                                <div
                                                                  className={` ${selectedArray?.includes(ele.code)
                                                                    ? "selected-opt px-3 py-1"
                                                                    : " px-3 py-1"
                                                                    }`}
                                                                  key={indexxxx}
                                                                >
                                                                  <div
                                                                    className="cursor_pointer"
                                                                    onClick={() => {
                                                                      {
                                                                        handleDocOptionClick(
                                                                          ele.code
                                                                        );

                                                                        let selectedValues = values.product_offers[index].offers[idx].doctor_code || null;
                                                                        let selectedArray = selectedValues ? selectedValues.split(",") : [];
                                                                        if (selectedArray.includes(ele.code)) {
                                                                          // If already selected, remove it
                                                                          selectedArray = selectedArray.filter((code) => code !== ele.code);
                                                                        } else {
                                                                          values.product_offers[index].offers.forEach((off, offerIdx) => {
                                                                            if (offerIdx !== idx) {
                                                                              let offerDoctorCodes = off.doctor_code ? off.doctor_code.split(",") : [];
                                                                              if (offerDoctorCodes.includes(ele.code)) {
                                                                                // Remove the doctor code from this index
                                                                                offerDoctorCodes = offerDoctorCodes.filter((code) => code !== ele.code);
                                                                                // Update the field value
                                                                                setFieldValue(`product_offers[${index}].offers[${offerIdx}].doctor_code`, offerDoctorCodes.join(","));
                                                                              }
                                                                            }
                                                                          });
                                                                          // If not selected, add it
                                                                          // selectedArray.push(ele.code);
                                                                          selectedArray.unshift(ele.code);
                                                                        }
                                                                        const updatedValues = selectedArray.join(",");
                                                                        console.log(updatedValues, "updatedValuesupdatedValues");
                                                                        setFieldValue(
                                                                          `product_offers[${index}].offers[${idx}].doctor_code`,
                                                                          updatedValues
                                                                        );
                                                                        setSelectedDocId(updatedValues)

                                                                        console.log(ele.code, "selected values");
                                                                      }
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <div className="fs_14 color_747f9e fw_700">
                                                                        {
                                                                          ele.label
                                                                        }
                                                                      </div>
                                                                      {ele.code}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      )}
                                                    {customerType ==
                                                      "pharmacy" && (
                                                        <div
                                                          className="d-flex flex-column py-3"
                                                          style={{
                                                            overflowX: "hidden",
                                                            overflowY: "scroll",
                                                            height: "7.5rem",
                                                          }}
                                                        >
                                                          {filteredPharmacyData.map(
                                                            (ele, indexxxx) => {
                                                              let selectedArray = values.product_offers[index].offers[idx].pharmacy_code?.split(",") || [];

                                                              return (
                                                                <div
                                                                  className={` ${selectedArray.includes(ele.code)
                                                                    ? "selected-opt px-3 py-1"
                                                                    : " px-3 py-1"
                                                                    }`}
                                                                  key={indexxxx}
                                                                >
                                                                  <div
                                                                    className="cursor_pointer"
                                                                    onClick={() => {
                                                                      {
                                                                        handleDocOptionClick(
                                                                          ele.code
                                                                        );

                                                                        let selectedValues = values.product_offers[index].offers[idx].pharmacy_code || null;
                                                                        let selectedArray = selectedValues ? selectedValues.split(",") : [];
                                                                        if (selectedArray.includes(ele.code)) {
                                                                          // If already selected, remove it
                                                                          selectedArray = selectedArray.filter((code) => code !== ele.code);
                                                                        } else {
                                                                          values.product_offers[index].offers.forEach((off, offerIdx) => {
                                                                            if (offerIdx !== idx) {
                                                                              let offerPharmayCodes = off.pharmacy_code ? off.pharmacy_code.split(",") : [];
                                                                              if (offerPharmayCodes.includes(ele.code)) {
                                                                                // Remove the doctor code from this index
                                                                                offerPharmayCodes = offerPharmayCodes.filter((code) => code !== ele.code);
                                                                                // Update the field value
                                                                                setFieldValue(`product_offers[${index}].offers[${offerIdx}].pharmacy_code`, offerPharmayCodes.join(","));
                                                                              }
                                                                            }
                                                                          });
                                                                          // If not selected, add it
                                                                          // selectedArray.push(ele.code);
                                                                          selectedArray.unshift(ele.code);
                                                                        }
                                                                        const updatedValues = selectedArray.join(",");
                                                                        console.log(updatedValues, "updatedValuesupdatedValues");
                                                                        setFieldValue(
                                                                          `product_offers[${index}].offers[${idx}].pharmacy_code`,
                                                                          updatedValues
                                                                        );
                                                                        setSelectedDocId(updatedValues)
                                                                      }
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <div className="fs_14 color_747f9e fw_700">
                                                                        {
                                                                          ele.label
                                                                        }
                                                                      </div>
                                                                      {ele.code}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      )}
                                                  </>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div
                                          className="id-container"
                                          onClick={() =>
                                            deleteOffer(
                                              values,
                                              index,
                                              idx,
                                              setFieldValue
                                            )
                                          }
                                        >
                                          <div className="mt-4">
                                            <div className="quantity-container-delete px-2 mt-3">
                                              <img
                                                className="styled-svg-del mx-1"
                                                src={deleteIcon}
                                                color={""}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      {!showStateDrop && (
                        <div
                          className="add-state-conatiner1 ms-4 ps-3 pb-5"
                          onClick={() => setShowStateDrop(!showStateDrop)}
                        >
                          <img className="styled-svg-add" src={Add} />
                          <span className="add-text">
                            Add value for different state
                          </span>
                        </div>
                      )}
                      {showStateDrop && (
                        <div className="ms-4 ps-5 pb-5 d-flex my-2">
                          <div
                            className=""
                            style={{ width: "100%", maxWidth: "300px" }}
                          >
                            <Field>
                              {({ form, Field }) => (
                                <Select
                                  {...Field}
                                  menuPlacement="top"
                                  options={stateOption}
                                  styles={customStyles2}
                                  placeholder={"Select state"}
                                  id="state_select"
                                  name="state_select"
                                  value={stateOption?.find(
                                    (c) => c?.id == values?.state_select
                                  )}
                                  onChange={(selectedOption) => {
                                    setSelectState(selectedOption?.id);
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          <button
                            className="add-button"
                            type="button"
                            onClick={() => addMoreState(values, setFieldValue)}
                          >
                            ADD
                          </button>
                        </div>
                      )}
                    </>
                  </>
                )}
              </div>

              <SuccessPopup
                show={onShowSuccess}
                title={"Product Edited Successfully"}
              />
              <ErrorPopup
                show={showErrorPopup}
                title={showErrorMsg}
                handleClose={closeErrorPopup}
              />
              <ErrorPopup
                show={showErrorPopup1}
                title={showErrorMsg1}
                handleClose={closeErrorPopup1}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ProductEdit;

const offerTypeOptions = [
  {
    label: "Discount",
    value: "discount",
  },
  {
    label: "Quantity",
    value: "quantity",
  },
  {
    label: "Price",
    value: "price",
  },
];

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    height: "43px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};

export const customStyles2 = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "300px",
    height: "43px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? null : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
