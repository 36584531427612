import { call, put, take, takeLatest } from "redux-saga/effects";
import {
  ANALYTICS_DIVISION,
  ANALYTICS_DOCTOR,
  ANALYTICS_FIELD_FORCE,
  ANALYTICS_PHARMACY,
  ANALYTICS_PRODUCT,
  ANALYTICS_STATUS,
  ANALYTICS_HEADQUATER,
  ANALYTICS_DISTRIBUTOR,
  ANALYTICS_CARD,
  ANALYTICS_MANUFACTURER,
} from "../actions/types";
import {
  analyticsApi,
  analyticsCheckerApi,
  distributorListApi,
  divisionListApi,
  doctorListApi,
  fieldForceListApi,
  pharmacyListApi,
  productListApi,
  headquaterListApi,
  manufacturerListApi,
} from "../apis/analyticsApi";
import { INDEX_SUCCESS } from "../actions/returnType";

export function* analyticsWorker(action) {
  try {
    const res = yield call(analyticsApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* analyticsStatusWorker(action) {
  try {
    const res = yield call(analyticsCheckerApi);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerListWorker(action) {
  try {
    console.log("caklling api of man");
    const res = yield call(manufacturerListApi);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
        offer_type: ele.offer_type,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* divisionListWorker(action) {
  try {
    const res = yield call(divisionListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
        offer_type: ele.offer_type,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productListWorker(action) {
  try {
    const res = yield call(productListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.product_name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fieldForceListWorker(action) {
  try {
    const res = yield call(fieldForceListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorListWorker(action) {
  try {
    const res = yield call(distributorListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.firm_name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pharmacyListWorker(action) {
  try {
    const res = yield call(pharmacyListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.pharmacy_name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* doctorListWorker(action) {
  try {
    const res = yield call(doctorListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* headquaterListWorker(action) {
  try {
    const res = yield call(headquaterListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* analyticsWatcher() {
  yield takeLatest(ANALYTICS_CARD, analyticsWorker);
}

export function* analyticsStatusWatcher() {
  yield takeLatest(ANALYTICS_STATUS, analyticsStatusWorker);
}

export function* analyticsManufacturerListWatcher() {
  yield takeLatest(ANALYTICS_MANUFACTURER, manufacturerListWorker);
}

export function* divisionListWatcher() {
  yield takeLatest(ANALYTICS_DIVISION, divisionListWorker);
}

export function* productListAnalyticsWatcher() {
  yield takeLatest(ANALYTICS_PRODUCT, productListWorker);
}

export function* fieldForceListWatcher() {
  yield takeLatest(ANALYTICS_FIELD_FORCE, fieldForceListWorker);
}

export function* distributorAnalyticsListWatcher() {
  yield takeLatest(ANALYTICS_DISTRIBUTOR, distributorListWorker);
}

export function* pharmacyListWatcher() {
  yield takeLatest(ANALYTICS_PHARMACY, pharmacyListWorker);
}

export function* doctorListWatcher() {
  yield takeLatest(ANALYTICS_DOCTOR, doctorListWorker);
}

export function* headquaterListWatcher() {
  yield takeLatest(ANALYTICS_HEADQUATER, headquaterListWorker);
}
