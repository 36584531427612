import moment from "moment";
import React from "react";

const InvoiceDetailsSection = ({ data, total }) => {
  return (
    <div className="mb-4">
      <label className="Medium_Purple text-nowrap fs_14 fw_500 mt-1 mb-2">
        Invoice Details
      </label>
      <div className="d-flex flex-wrap gap-1">
        <div className="me-1">
          <label className="w-100 color_747f9e">Distributor Invoice Id</label>
          <label>{data?.invoice_id ? data?.invoice_id : "--"}</label>
        </div>
        <div className="mx-1">
          <label className="w-100 color_747f9e">Distributor Invoice Date</label>
          <label>
            {data?.invoice_date
              ? moment(data?.invoice_date).format("DD MMM YYYY")
              : "--"}
          </label>
        </div>
        <div className="mx-1">
          <label className="w-100 color_747f9e">Invoice Value</label>
          <label>{total ? `₹ ${total}` : "--"}</label>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailsSection;
