export const LOGIN = "LOGIN";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
export const LOGOUT = "LOGOUT";
export const STATE_LIST = "STATE_LIST";
export const SECOND_STATE_LIST = "SECOND_STATE_LIST";
export const SECOND_DISTRICT_LIST = "SECOND_DISTRICT_LIST";
export const SECOND_CITY_LIST = "SECOND_CITY_LIST";
export const DISTRICT_LIST = "DISTRICT_LIST";
export const CITY_LIST = "CITY_LIST";
export const PINCODE_AUTO_LIST = "PINCODE_AUTO_LIST";
export const DIVISION_LIST = "DIVISION_LIST";

export const DASHBOARD_VIEW = "DASHBOARD_VIEW";
export const GRAPH_CARD = "GRAPH_CARD";

export const FAQ_LIST = "FAQ_LIST";
export const CREATE_FAQ = "CREATE_FAQ";
export const EDIT_FAQ = "EDIT_FAQ";
export const FAQ_DETAIL = "FAQ_DETAIL";
export const FAQ_VERSION_LIST = "FAQ_VERSION_LIST";
export const FAQ_VERSION_DETAIL = "FAQ_VERSION_DETAIL";
export const DELETE_FAQ = "DELETE_FAQ";

export const DISTRIBUTOR_LIST = "DISTRIBUTOR_LIST";
export const ONBOARDING_DISTRIBUTOR_LIST = "ONBOARDING_DISTRIBUTOR_LIST";
export const DISTRIBUTOR_DETAIL = "DISTRIBUTOR_DETAIL";
export const CREATE_DISTRIBUTOR = "CREATE_DISTRIBUTOR ";
export const EDIT_DISTRIBUTOR = "EDIT_DISTRIBUTOR";
export const DISTRIBUTOR_ENABLE_DISABLE = "DISTRIBUTOR_ENABLE_DISABLE";
export const DELETE_DISTROBUTOR = "DELETE_DISTROBUTOR";
export const DISTRIBUTOR_VERSION_LIST = "DISTRIBUTOR_VERSION_LIST";
export const DISTRIBUTOR_VESRION_DETAIL = "DISTRIBUTOR_VESRION_DETAIL";
export const DISTRIBUTOR_LOGIN_PORTAL = " DISTRIBUTOR_LOGIN_PORTAL";
export const MANUFACTURER_DROPDOWN = "MANUFACTURER_DROPDOWN";
export const LOGO_UPLOAD = "LOGO_UPLOAD";
export const GST_CERTIFICATE_UPLOAD = "GST_CERTIFICATE_UPLOAD";
export const DRUG_LICENCE_UPLOAD = "DRUG_LICENCE_UPLOAD";

export const SUPER_ADMIN_LIST = "SUPER_ADMIN_LIST";
export const CREATE_SUPER_ADMIN = "CREATE_SUPER_ADMIN";
export const SUPER_ADMIN_EDIT = "SUPER_ADMIN_EDIT";
export const SUPER_ADMIN_VERSION_LIST = "SUPER_ADMIN_VERSION_LIST";
export const SUPER_ADMIN_DETAIL = "SUPER_ADMIN_DETAIL";
export const SUPER_ADMIN_VERSION_DETAIL = "SUPER_ADMIN_VERSION_DETAIL";
export const PERMISSION_LIST = "PERMISSION_LIST";
export const DELETE_SUPER_ADMIN = "DELETE_SUPER_ADMIN";

export const SUPER_ADMIN_ROLE_LIST = "SUPER_ADMIN_ROLE_LIST";
export const CREATE_SUPER_ADMIN_ROLE = "CREATE_SUPER_ADMIN_ROLE";
export const DELETE_SUPER_ADMIN_ROLE = "DELETE_SUPER_ADMIN_ROLE";
export const ROLE_PERMISSION_LIST = "ROLE_PERMISSION_LIST";
export const SUPER_ADMIN_ROLE_DETAIL = "SUPER_ADMIN_ROLE_DETAIL";
export const SUPER_ADMIN_ROLE_EDIT = "SUPER_ADMIN_ROLE_EDIT";
export const SUPER_ADMIN_ROLE_VERSION_LIST = "SUPER_ADMIN_ROLE_VERSION_LIST";
export const SUPER_ADMIN_ROLE_VERSION_DETAIL =
  "SUPER_ADMIN_ROLE_VERSION_DETAIL";
export const ROLE_LIST = "ROLE_LIST";

export const MANUFACTURER_LIST = "MANUFACTURER_LIST";
export const MANUFACTURER_PREMIUM_LIST = "MANUFACTURER_PREMIUM_LIST";
export const CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
export const CREATE_MANUFACTURER_DRAFT = "CREATE_MANUFACTURER_DRAFT";
export const UPDATE_MANUFACTURER_CREATE = "UPDATE_MANUFACTURER_CREATE";
export const MANUFACTURER_EDIT = "MANUFACTURER_EDIT";
export const MANUFACTURER_DETAIL = "MANUFACTURER_DETAIL";
export const MANUFACTURER_VERSION_LIST = "MANUFACTURER_VERSION_LIST";
export const MANUFACTURER_PREMIUM_VERSION_LIST =
  "MANUFACTURER_PREMIUM_VERSION_LIST";
export const MANUFACTURER_VERSION_DETAIL = "MANUFACTURER_VERSION_DETAIL";
export const REIMBURSEMENT_LIST = "REIMBURSEMENT_LIST";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";
export const ENALBE_DISABLE_MANUFACTURER = "ENALBE_DISABLE_MANUFACTURER";
export const MANUFACTURER_LOGO_UPLOAD = "MANUFACTURER_LOGO_UPLOAD";
export const DOCUMENT1_IMG_UPLOAD = "DOCUMENT1_IMG_UPLOAD";
export const DOCUMENT2_IMG_UPLOAD = "DOCUMENT2_IMG_UPLOAD";
export const DOCUMENT3_IMG_UPLOAD = "DOCUMENT3_IMG_UPLOAD";
export const PRODUCT_CSV_UPLOAD = "PRODUCT_CSV_UPLOAD";
export const FIELD_FORCE_CSV_UPLOAD = "FIELD_FORCE_CSV_UPLOAD";
export const DISTRIBUTOR_CSV_UPLOAD = "DISTRIBUTOR_CSV_UPLOAD";
export const DOCTOR_CSV_UPLOAD = "DOCTOR_CSV_UPLOAD";
export const PHARMACY_CSV_UPLOAD = "PHARMACY_CSV_UPLOAD";
export const PRODUCT_BULK_VERIFY = "PRODUCT_BULK_VERIFY";
export const FIELD_FORCE_BULK_VERIFY = "FIELD_FORCE_BULK_VERIFY";
export const DISTRIBUTOR_BULK_VERIFY = "DISTRIBUTOR_BULK_VERIFY";
export const DOCTOR_BULK_VERIFY = "DOCTOR_BULK_VERIFY";
export const PHARMACY_BULK_VERIFY = "PHARMACY_BULK_VERIFY";
export const LOGIN_TO_PORTAL_MANUFACTURER = "LOGIN_TO_PORTAL_MANUFACTURER";
export const LOGIN_TO_PORTAL_DISTRIBUTOR = "LOGIN_TO_PORTAL_DISTRIBUTOR";
export const LOGIN_TO_PORTAL_CFA = "LOGIN_TO_PORTAL_CFA";
export const SEND_MAIL = "SEND_MAIL";
export const DISTRIBUTOR_SEND_MAIL = "DISTRIBUTOR_SEND_MAIL";
export const GEV_CFA_SEND_MAIL = "GEV_CFA_SEND_MAIL";
export const DISTRIBUTOR_DIVISION_LIST = "DISTRIBUTOR_DIVISION_LIST";

export const PRODUCT_DIVISION_VIEW = "PRODUCT_DIVISION_VIEW";
export const FIELDFORCE_DIVISION_VIEW = "FIELDFORCE_DIVISION_VIEW";
export const DOCTOR_DIVISION_VIEW = "DOCTOR_DIVISION_VIEW";
export const CREATE_DOCTOR = "CREATE_DOCTOR";
export const CREATE_PHARMACY = "CREATE_PHARMACY";
export const PHARMACY_DIVISION_VIEW = "PHARMACY_DIVISION_VIEW";
export const DOCTOR_DIV_VIEW = "DOCTOR_DIV_VIEW";
export const PHARMACY_DIV_VIEW = "PHARMACY_DIV_VIEW";

export const EDIT_DESIGNATION = "EDIT_DESIGNATION";
export const EDIT_HEAD_QUARTER = "EDIT_HEAD_QUARTER";
export const EDIT_DIVISION = "EDIT_DIVISION";
export const ADD_DESIGNATION = "ADD_DESIGNATION";
export const ADD_HEAD_QUARTER = "ADD_HEAD_QUARTER";
export const ADD_DIVISION = "ADD_DIVISION";
export const ENABLE_DISABLE_HEAD_QUARTER = "ENABLE_DISABLE_HEAD_QUARTER";
export const ENABLE_DISABLE_DIVISION = "ENABLE_DISABLE_DIVISION";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const PO_LIST = "PO_LIST";
export const PO_VIEW = "PO_VIEW";
export const PO_STATE_LIST = "PO_STATE_LIST";
export const PO_DISTRIBUTOR_LIST = "PO_DISTRIBUTOR_LIST";
export const PO_OFFERTYPE_LIST = "PO_OFFERTYPE_LIST";
export const GENERATE_PO = "GENERATE_PO";
export const PO_UNINVOICED_LIST = "PO_UNINVOICED_LIST";
export const PO_INVOICED_LIST = "PO_INVOICED_LIST";
export const GENERATE_PO_INVOICE = "GENERATE_PO_INVOICE";
export const GENERATE_INVOICE = "GENERATE_INVOICE";
export const INVOICE_CHANGE_STATUS = "INVOICE_CHANGE_STATUS";
export const INVOICE_DETAILS = "INVOICE_DETAILS";
export const INVOICE_MAIL = "INVOICE_MAIL";
export const PO_CSV_MAIL = "PO_CSV_MAIL";
export const PO_CSV_DOWNLOAD = "PO_CSV_DOWNLOAD";
export const TOGGLE_ANALYTICS = "TOGGLE_ANALYTICS";

export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const CUSTOMER_DETAIL = "CUSTOMER_DETAIL";
export const CUSTOMER_VERSION_LIST = "CUSTOMER_VERSION_LIST";
export const CUSTOMER_VERSION_DETAIL = "CUSTOMER_VERSION_DETAIL";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const ENALBE_DISABLE_CUSTOMER = "ENALBE_DISABLE_CUSTOMER";
export const REQUEST_LIST = "REQUEST_LIST";
export const REQUEST_DETAIL = "REQUEST_DETAIL";
export const DISTRIBUTOR_REQUEST_LIST = "DISTRIBUTOR_REQUEST_LIST";
export const DISTRIBUTOR_REQUEST_VIEW = "DISTRIBUTOR_REQUEST_VIEW";
export const APPROVE_REJECT_CUSTOMER = "APPROVE_REJECT_CUSTOMER";
export const DOCUMENT_IMGAGE_UPLOAD = "DOCUMENT_IMGAGE_UPLOAD";

export const INVOICE_VERIFY_LIST = "INVOICE_VERIFY_LIST";
export const INVOICE_VERIFY_DETAIL = "INVOICE_VERIFY_DETAIL";
export const REMARK_LIST = "REMARK_LIST";
export const INVOICE_REUPLOAD = "INVOICE_REUPLOAD";
export const INVOICE_VERIFY = "INVOICE_VERIFY";
export const INVOICE_VERIFIED_LIST = "INVOICE_VERIFIED_LIST"
export const INVOICE_PTR_UPDATE = "INVOICE_PTR_UPDATE"

export const SETTINGS_DISTRICT_LIST = "SETTINGS_DISTRICT_LIST";
export const SETTINGS_DISTRICT_CREATE = "SETTINGS_DISTRICT_CREATE";
export const SETTINGS_DISTRICT_DETAIL = "SETTINGS_DISTRICT_DETAIL";
export const SETTINGS_DISTRICT_EDIT = "SETTINGS_DISTRICT_EDIT";
export const SETTINGS_REJECT_REASON_LIST = "SETTINGS_REJECT_REASON_LIST";
export const SETTINGS_REJECT_REASON_DETAIL = "SETTINGS_REJECT_REASON_DETAIL";
export const SETTINGS_REJECT_REASON_CREATE = "SETTINGS_REJECT_REASON_CREATE";
export const SETTINGS_PRIVACY_POLICY_VIEW = "SETTINGS_PRIVACY_POLICY_VIEW";
export const SETTINGS_PRIVACY_POLICY_EDIT = "SETTINGS_PRIVACY_POLICY_EDIT";
export const SETTINGS_TERMS_VIEW = "SETTINGS_TERMS_VIEW";
export const SETTINGS_TERMS_EDIT = "SETTINGS_TERMS_EDIT";
export const ADVERTISEMENT_PHOTO = "ADVERTISEMENT_PHOTO";
export const SETTINGS_STATE_LIST = "SETTINGS_STATE_LIST";
export const SETTINGS_STATE_CREATE = "SETTINGS_STATE_CREATE";
export const SETTINGS_STATE_EDIT = "SETTINGS_STATE_EDIT";
export const SETTINGS_CITY_LIST = "SETTINGS_CITY_LIST";
export const SETTINGS_CITY_CREATE = "SETTINGS_CITY_CREATE";
export const SETTINGS_CITY_EDIT = "SETTINGS_CITY_EDIT";
export const SETTINGS_PINCODE_LIST = "SETTINGS_PINCODE_LIST";
export const SETTINGS_PINCODE_CREATE = "SETTINGS_PINCODE_CREATE";
export const SETTINGS_PINCODE_EDIT = "SETTINGS_PINCODE_EDIT";
export const SETTINGS_PINCODE_FILTER = "SETTINGS_PINCODE_FILTER";
export const SETTINGS_ADVERTISEMENT_CREATE = "SETTINGS_ADVERTISEMENT_CREATE";
export const SETTINGS_ADVERTISEMENT_LIST = "SETTINGS_ADVERTISEMENT_LIST";
export const SETTINGS_ADVERTISEMENT_DELETE = "SETTINGS_ADVERTISEMENT_DELETE";

export const GEV_FIELDFORCE_LIST = "GEV_FIELDFORCE_LIST";
export const GEV_DESIGNATION_LIST = "GEV_DESIGNATION_LIST";
export const GEV_DESIGNATION_REPORT_LIST = "GEV_DESIGNATION_REPORT_LIST";
export const GEV_HQ_REPORT_LIST = "GEV_HQ_REPORT_LIST";
export const GEV_DIVISION_REPORT_LIST = "GEV_DIVISION_REPORT_LIST";
export const GEV_PRODUCT_REPORT_LIST = "GEV_PRODUCT_REPORT_LIST";
export const GEV_FF_REPORT_LIST = "GEV_FF_REPORT_LIST";
export const GEV_ORDERS_REPORT_LIST = "GEV_ORDERS_REPORT_LIST";
export const GEV_CFA_REPORT_LIST = "GEV_CFA_REPORT_LIST";
export const GEV_DESIGNATION_VIEW = "GEV_DESIGNATION_VIEW";
export const GEV_HEADQUATER_VIEW_LIST = "GEV_HEADQUATER_VIEW_LIST";
export const GEV_HEADQUATER_VIEW = "GEV_HEADQUATER_VIEW";
export const GEV_FIELDFORCE_VIEW = "GEV_FIELDFORCE_VIEW";
export const GEV_DIVISION_VIEW_LIST = "GEV_DIVISION_VIEW_LIST";
export const GEV_DIVISION_VIEW = "GEV_DIVISION_VIEW";

export const GEV_FIELDFORCE_EDIT = "GEV_FIELDFORCE_EDIT";
export const GEV_ORDERS_LIST = "GEV_ORDERS_LIST";
export const DESIGNATION_LIST = "DESIGNATION_LIST";
export const GEV_DIVISION_LIST = "GEV_DIVISION_LIST";
export const GEV_HEADQUATER_LIST = "GEV_HEADQUATER_LIST";
export const GEV_REP_MANAGER_LIST = "GEV_REP_MANAGER_LIST";
export const GEV_ORDERS_VIEW = "GEV_ORDERS_VIEW";
export const GEV_CFA_LIST = "GEV_CFA_LIST";
export const GEV_CFA_VIEW = "GEV_CFA_VIEW";
export const GEV_CFA_EDIT = "GEV_CFA_EDIT";
export const GEV_CFA_CREATE = "GEV_CFA_CREATE";
export const GEV_PRODUCT_LIST = "GEV_PRODUCT_LIST";
export const GEV_PRODUCT_VIEW = "GEV_PRODUCT_VIEW";
export const PRODUCT_STATE_LIST = "PRODUCT_STATE_LIST";
export const PACKING_TYPE = "PACKING_TYPE";
export const GEV_PRODUCT_EDIT = "GEV_PRODUCT_EDIT";
export const GEV_PRODUCT_CREATE = "GEV_PRODUCT_CREATE";
export const GEV_PRODUCT_DELETE = "GEV_PRODUCT_DELETE";
export const VENDOR_DIVISION_LIST = "VENDOR_DIVISION_LIST";
export const PRODUCT_HEADQUATER_LIST = "PRODUCT_HEADQUATER_LIST";
export const FETCH_DOCTOR_LIST = "FETCH_DOCTOR_LIST";
export const FETCH_PHARMACY_LIST = "FETCH_PHARMACY_LIST";

export const CUSTOMER_VERIFY_LIST = "CUSTOMER_VERIFY_LIST";
export const CUSTOMER_VERIFY_DETAIL = "CUSTOMER_VERIFY_DETAIL";
export const VERIFY_REPORT_MODAL = "VERIFY_REPORT_MODAL";

export const ANALYTICS_CARD = "ANALYTICS_CARD";
export const ANALYTICS_STATUS = "ANALYTICS_STATUS";
export const ANALYTICS_DIVISION = "ANALYTICS_DIVISION";
export const ANALYTICS_PRODUCT = "ANALYTICS_PRODUCT";
export const ANALYTICS_FIELD_FORCE = "ANALYTICS_FIELD_FORCE";
export const ANALYTICS_DISTRIBUTOR = "ANALYTICS_DISTRIBUTOR";
export const ANALYTICS_PHARMACY = "ANALYTICS_PHARMACY";
export const ANALYTICS_DOCTOR = "ANALYTICS_DOCTOR";
export const ANALYTICS_HEADQUATER = "ANALYTICS_HEADQUATER";
export const ANALYTICS_MANUFACTURER = "ANALYTICS_MANUFACTURER";

export const CHAPTERS_LIST = "CHAPTERS_LIST"
export const CHAPTERS_VIEW = "CHAPTERS_VIEW"
export const CHAPTERS_EDIT = "CHAPTERS_EDIT"
export const CHAPTERS_CREATE = "CHAPTERS_CREATE"
export const CHAPTERS_DELETE = "CHAPTERS_DELETE"

export const TRAINING_QA_LIST = "TRAINING_QA_LIST"
export const TRAINING_QA_VIEW = "TRAINING_QA_VIEW"
export const TRAINING_QA_EDIT = "TRAINING_QA_EDIT"
export const TRAINING_QA_CREATE = "TRAINING_QA_CREATE"
export const TRAINING_QA_DELETE = "TRAINING_QA_DELETE"
export const CHAPTERS_DROP_DOWN = "CHAPTERS_DROP_DOWN"