import React from "react";
import { useNavigate } from "react-router-dom";

const FaqVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">{(currentPage - 1) * 10 + (index + 1)}</td>
      <td className="table-each-row-data">{data.faq_id}</td>
      <td className="table-each-row-data ">
        <span className="d-inline-block text-truncate text-capitalize col-10 faq-qstn-slice">
          {data.question}
        </span>
      </td>
      {/* <td className="table-each-row-data">
        <span className="d-inline-block text-truncate col-10 faq-ans-slice">
          <span
            dangerouslySetInnerHTML={{ __html: data.answer }}
            className=""
          />
        </span>
      </td> */}
     <td className="table-each-row-data text-capitalize">
        {data?.faqUserTypes?.map((ele, index) => {
          return (
            <span key={index}>
              {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
              {ele?.user_type}
            </span>
          );
        })}
      </td>
       <td className="table-each-row-data">{data?.addedBy?.name ? data?.addedBy?.name : '--'}</td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/faq/version-view/${data.id}`)}
          >
            <span className=""> View</span>
          </button>
        </div>
      </td> 
    </tr>
  );
};

export default FaqVersionTableData;
