import { type } from "@testing-library/user-event/dist/type";
import {
  ADD_DESIGNATION,
  ADD_DIVISION,
  ADD_HEAD_QUARTER,
  CREATE_MANUFACTURER,
  CREATE_MANUFACTURER_DRAFT,
  DELETE_MANUFACTURER,
  DISTRIBUTOR_BULK_VERIFY,
  DISTRIBUTOR_CSV_UPLOAD,
  DOCTOR_BULK_VERIFY,
  DOCTOR_CSV_UPLOAD,
  DOCUMENT1_IMG_UPLOAD,
  DOCUMENT2_IMG_UPLOAD,
  EDIT_DESIGNATION,
  EDIT_DIVISION,
  EDIT_HEAD_QUARTER,
  ENABLE_DISABLE_DIVISION,
  ENABLE_DISABLE_HEAD_QUARTER,
  ENALBE_DISABLE_MANUFACTURER,
  FIELD_FORCE_BULK_VERIFY,
  FIELD_FORCE_CSV_UPLOAD,
  GENERATE_INVOICE,
  GENERATE_PO,
  GENERATE_PO_INVOICE,
  INVOICE_CHANGE_STATUS,
  INVOICE_DETAILS,
  INVOICE_MAIL,
  LOGIN_TO_PORTAL_MANUFACTURER,
  MANUFACTURER_DETAIL,
  MANUFACTURER_EDIT,
  MANUFACTURER_LIST,
  MANUFACTURER_LOGO_UPLOAD,
  MANUFACTURER_VERSION_DETAIL,
  MANUFACTURER_VERSION_LIST,
  PHARMACY_BULK_VERIFY,
  PHARMACY_CSV_UPLOAD,
  PO_CSV_DOWNLOAD,
  PO_CSV_MAIL,
  PO_DISTRIBUTOR_LIST,
  PO_INVOICED_LIST,
  PO_LIST,
  PO_OFFERTYPE_LIST,
  PO_STATE_LIST,
  PO_UNINVOICED_LIST,
  PRODUCT_BULK_VERIFY,
  PRODUCT_CSV_UPLOAD,
  REIMBURSEMENT_LIST,
  SEND_MAIL,
  TOGGLE_ANALYTICS,
  UPDATE_MANUFACTURER_CREATE,
  PRODUCT_DIVISION_VIEW,
  FIELDFORCE_DIVISION_VIEW,
  DOCTOR_DIVISION_VIEW,
  PHARMACY_DIVISION_VIEW,
  DOCTOR_DIV_VIEW,
  PHARMACY_DIV_VIEW,
  CREATE_DOCTOR,
  CREATE_PHARMACY,
  LOGIN_TO_PORTAL_CFA,
  MANUFACTURER_PREMIUM_LIST,
  MANUFACTURER_PREMIUM_VERSION_LIST,
  DOCUMENT3_IMG_UPLOAD,
  PO_VIEW,
} from "./types";

export const manufacturerList = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURER_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const manufacturerPremiumList = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURER_PREMIUM_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const manufacturerCreate = (data, onSuccess, onError) => {
  return {
    type: CREATE_MANUFACTURER,
    data,
    onSuccess,
    onError,
  };
};

export const manufacturerCreateDraft = (data, onSuccess, onError) => {
  return {
    type: CREATE_MANUFACTURER_DRAFT,
    data,
    onSuccess,
    onError,
  };
};

export const manufacturerCreateDesignation = (data, onSuccess, onError) => {
  return {
    type: UPDATE_MANUFACTURER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const manufacturerEdit = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const manufacturerDetail = (id, onSuccess, onError) => {
  return {
    type: MANUFACTURER_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const manufacturerVersionList = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURER_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const manufacturerPremiumVersionList = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURER_PREMIUM_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const manufacturerVersionDetial = (id, onSuccess, onError) => {
  return {
    type: MANUFACTURER_VERSION_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const reimbursementList = (onSuccess, onError) => {
  return {
    type: REIMBURSEMENT_LIST,
    onSuccess,
    onError,
  };
};
export const manufacturerDelete = (id, onSuccess, onError) => {
  return {
    type: DELETE_MANUFACTURER,
    id,
    onSuccess,
    onError,
  };
};
export const manufacturerEnableDisable = (id, onSuccess, onError) => {
  return {
    type: ENALBE_DISABLE_MANUFACTURER,
    id,
    onSuccess,
    onError,
  };
};
export const manufacturerLogoUpload = (image, onSuccess, onError) => {
  return {
    type: MANUFACTURER_LOGO_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const documentUpld1 = (image, onSuccess, onError) => {
  return {
    type: DOCUMENT1_IMG_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const documentUpld2 = (image, onSuccess, onError) => {
  return {
    type: DOCUMENT2_IMG_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const documentUpld3 = (image, onSuccess, onError) => {
  return {
    type: DOCUMENT3_IMG_UPLOAD,
    image,
    onSuccess,
    onError,
  };
};

export const productCsvUpload = (data, file, onSuccess, onError) => {
  return {
    type: PRODUCT_CSV_UPLOAD,
    data,
    file,
    onSuccess,
    onError,
  };
};

export const fieldForceCsvUpload = (data, file, onSuccess, onError) => {
  return {
    type: FIELD_FORCE_CSV_UPLOAD,
    data,
    file,
    onSuccess,
    onError,
  };
};

export const distributorCsvUpload = (data, file, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_CSV_UPLOAD,
    data,
    file,
    onSuccess,
    onError,
  };
};

export const doctorCsvUpload = (data, file, onSuccess, onError) => {
  return {
    type: DOCTOR_CSV_UPLOAD,
    data,
    file,
    onSuccess,
    onError,
  };
};

export const pharmacyCsvUpload = (data, file, onSuccess, onError) => {
  return {
    type: PHARMACY_CSV_UPLOAD,
    data,
    file,
    onSuccess,
    onError,
  };
};

export const productBulkVerify = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_BULK_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

export const fieldForceBulkVerify = (data, onSuccess, onError) => {
  return {
    type: FIELD_FORCE_BULK_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

export const distributorBulkVerify = (data, onSuccess, onError) => {
  return {
    type: DISTRIBUTOR_BULK_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

export const doctorBulkVerify = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_BULK_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

export const pharmacyBulkVerify = (data, onSuccess, onError) => {
  return {
    type: PHARMACY_BULK_VERIFY,
    data,
    onSuccess,
    onError,
  };
};

// DIVISION VIEW STARTS

export const productDivisionView = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_DIVISION_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const fieldForceDivisionView = (data, onSuccess, onError) => {
  return {
    type: FIELDFORCE_DIVISION_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const createDoctorAction = (data, onSuccess, onError) => {
  return {
    type: CREATE_DOCTOR,
    data,
    onSuccess,
    onError,
  };
};

export const createPharmacyAction = (data, onSuccess, onError) => {
  return {
    type: CREATE_PHARMACY,
    data,
    onSuccess,
    onError,
  };
};

export const doctorDivisionView = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_DIVISION_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const pharmacyDivisionView = (data, onSuccess, onError) => {
  return {
    type: PHARMACY_DIVISION_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const doctorProductDivisionView = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_DIV_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const pharmacyDivView = (data, onSuccess, onError) => {
  return {
    type: PHARMACY_DIV_VIEW,
    data,
    onSuccess,
    onError,
  };
};

// DIVISION VIEW ENDS

export const loginToPortalManufacturer = (vendor_id, onSuccess, onError) => {
  return {
    type: LOGIN_TO_PORTAL_MANUFACTURER,
    vendor_id,
    onSuccess,
    onError,
  };
};
export const loginToPortalCfa = (cfa_id, onSuccess, onError) => {
  return {
    type: LOGIN_TO_PORTAL_CFA,
    cfa_id,
    onSuccess,
    onError,
  };
};
export const sendMail = (data, onSuccess, onError) => {
  return {
    type: SEND_MAIL,
    data,
    onSuccess,
    onError,
  };
};

export const designationAdd = (data, onSuccess, onError) => {
  return {
    type: ADD_DESIGNATION,
    data,
    onSuccess,
    onError,
  };
};

export const headquarterAdd = (data, onSuccess, onError) => {
  return {
    type: ADD_HEAD_QUARTER,
    data,
    onSuccess,
    onError,
  };
};

export const divisionAdd = (data, onSuccess, onError) => {
  return {
    type: ADD_DIVISION,
    data,
    onSuccess,
    onError,
  };
};

export const designationEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_DESIGNATION,
    data,
    onSuccess,
    onError,
  };
};

export const headquarterEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_HEAD_QUARTER,
    data,
    onSuccess,
    onError,
  };
};

export const divisionEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_DIVISION,
    data,
    onSuccess,
    onError,
  };
};

export const enableDisableHeadQuarter = (data, onSuccess, onError) => {
  return {
    type: ENABLE_DISABLE_HEAD_QUARTER,
    data,
    onSuccess,
    onError,
  };
};

export const enableDisableDivision = (data, onSuccess, onError) => {
  return {
    type: ENABLE_DISABLE_DIVISION,
    data,
    onSuccess,
    onError,
  };
};

export const PoStateList = (onSuccess, onError) => {
  return {
    type: PO_STATE_LIST,
    onSuccess,
    onError,
  };
};
export const PoDistributorList = (data, onSuccess, onError) => {
  return {
    type: PO_DISTRIBUTOR_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const PoOfferType = (data, onSuccess, onError) => {
  return {
    type: PO_OFFERTYPE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const PoList = (data, onSuccess, onError) => {
  return {
    type: PO_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const PoView = (data, onSuccess, onError) => {
  return {
    type: PO_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const generatePo = (data, onSuccess, onError) => {
  return {
    type: GENERATE_PO,
    data,
    onSuccess,
    onError,
  };
};

export const PoUninvoicedList = (data, onSuccess, onError) => {
  return {
    type: PO_UNINVOICED_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const PoInvoicedList = (data, onSuccess, onError) => {
  return {
    type: PO_INVOICED_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const generatePoInvoice = (data, onSuccess, onError) => {
  return {
    type: GENERATE_PO_INVOICE,
    data,
    onSuccess,
    onError,
  };
};
export const generateInvoice = (data, onSuccess, onError) => {
  return {
    type: GENERATE_INVOICE,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceChangeStatus = (data, onSuccess, onError) => {
  return {
    type: INVOICE_CHANGE_STATUS,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceDetails = (data, onSuccess, onError) => {
  return {
    type: INVOICE_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceMail = (data, onSuccess, onError) => {
  return {
    type: INVOICE_MAIL,
    data,
    onSuccess,
    onError,
  };
};
export const poCsvMail = (data, onSuccess, onError) => {
  return {
    type: PO_CSV_MAIL,
    data,
    onSuccess,
    onError,
  };
};
export const poCsvDownload = (data, onSuccess, onError) => {
  return {
    type: PO_CSV_DOWNLOAD,
    data,
    onSuccess,
    onError,
  };
};

export const toggleAnalytics = (id, onSuccess, onError) => {
  return {
    type: TOGGLE_ANALYTICS,
    id,
    onSuccess,
    onError,
  };
};
