import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import back from "../../assets/icons/back.svg";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import InputField from "../../components/form/inputField/inputField";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import * as Yup from "yup";
import "./faq.css";
import ErrorMsg from "../../components/form/errorMsg";
import ReactQuill from "react-quill";
import { faqCreate } from "../../redux/actions/faqActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";

const FaqCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setAnswerData, setSetAnswerData] = useState("");
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    question: "",
    answer: "",
    user_type: "",
  };
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Required"),
    user_type: Yup.array().required("Required"),
    answer: Yup.string().required("Required"),
  });

  const clearFunc = (setFieldValue) => {
    setFieldValue("question", "");
    setFieldValue("user_type", []);
    setFieldValue("answer", "");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ align: "" }],
    ],
  };

  const onSubmit = (values) => {
    console.log(values);
    dispatch(faqCreate(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate("/faq/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };

  const timeOutFnc1 = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err.data);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc1, 2000);
  };

  return (
    <MainLayout breadCrumb="ADMIN /  FAQ’s - Create">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <div className="search-container d-flex justify-content-between ">
                <div
                  className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={back} alt="back_btn" />
                  <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                    BACK
                  </span>
                </div>
                <div className=" d-flex justify-content-between m-1 mb-2">
                  <AdminButton
                    background="#E7E5FF"
                    width="100px"
                    type="button"
                    onClick={() => clearFunc(setFieldValue)}
                  >
                    CLEAR
                  </AdminButton>
                  <AdminButton
                    background="#7065e4"
                    color="white"
                    width="100px"
                    type="submit"
                  >
                    <span className="m-2">SAVE</span>
                  </AdminButton>
                </div>
              </div>
              <div className="faq-form-width ms-4 ps-3 py-1">
                <div className="my-2 mt-4 ">
                  <InputField
                    id={"question"}
                    label={"FAQ Questions"}
                    labelColor={"color_262c3d"}
                    type={"text"}
                    placeholder={"Enter FAQ questions"}
                    name={"question"}
                    value={values.question}
                  />
                </div>
                <div className="my-2 mb-4">
                  <label className="fs_14 color_262c3d fw_400">
                    FAQ Answer
                  </label>
                  <div className="faq-answer-container">
                    <Field name="answer">
                      {({ field }) => (
                        <ReactQuill
                          // value={field.value}
                          onChange={field.onChange(field.name)}
                          modules={modules}
                          style={{ maxHeight: "196px", height: "60vh" }}
                          className={"quill"}
                          placeholder={"Write FAQ answers"}
                          value={values.answer}
                        />
                      )}
                    </Field>
                  </div>
                  <ErrorMsg name={"answer"} />
                </div>
                <div className="my-3">
                  <label className="fs_14 color_262c3d fw_400">
                    Created for
                  </label>
                  {createdDummy?.map((ele, index) => (
                    <div
                      className="d-flex my-1 label-container align-items-center justify-content-start"
                      key={index}
                    >
                      <div className=" d-flex align-items-end">
                        <span className="fs_14 my-1">
                          <Field
                            type={"checkbox"}
                            className="CheckBox"
                            value={ele?.value}
                            name="user_type"
                          />
                        </span>
                        <label className="fs_14 color_262c3d text-nowrap fw_400 mx-2">
                          {ele.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <ErrorMsg name={"user_type"} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SuccessPopup show={onShowSuccess} title={"FAQ Created Successfully"} />
      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
    </MainLayout>
  );
};

export default FaqCreate;
const createdDummy = [
  { value: "customer", label: "Customers" },
  { value: "distributor", label: "Distributors" },
  { value: "vendor", label: "Manufactrer" },
  { value: "field_force_user", label: "Field Force" },
];
