import { api } from "./api";

export const dashboardApi = (params) => {
  return api.get("/admin/dashboard", { params });
};

export const graphCardApi = (params) => {
  console.log(params);
  return api.get(`/admin/dashboard/${params.urlParams}`, {
    params,
  });
};
