import React from "react";

const InvoiceRemarks = ({
  values,
  cfaUser,
  cfaDistributorRemarksValues,
  cfaRemarksValues,
  hideCfaRemarks,
  hideActualRemarks,
  isSkip,
}) => {
  return (
    <>
      {cfaUser == true ? (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">Invoice remarks</label>
          </div>

          <>
            {/*------------- CFA remarks starts-----------  */}

            <>
              {!isSkip && (
                <>
                  <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
                    <div
                      className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                      style={{ minHeight: "30rem" }}
                    >
                      <label className="color_747F9E fs_14">CFA Remarks</label>

                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Is That Uploaded Picture Is Clear?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.is_image_clear == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the Invoice Number match with the uploaded image
                          ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.invoice_number_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the Invoice date match with the uploaded image ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.invoice_date_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the distributor name match with the uploaded
                          image ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.distributor_name_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the product name match with the uploaded image ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.product_name_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the quantity match with the uploaded image ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.quantity_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="color_262C3D fs_14">
                          Does the cfa name match with the uploaded image ?
                        </label>
                        <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                          {values?.cfaRemarks.cfa_name_match == true ? (
                            <label className="text_green">Yes</label>
                          ) : (
                            <label className="text_red">No</label>
                          )}
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </>
              )}
            </>

            {/*------------- CFA remarks ends-----------  */}
          </>
          {!isSkip || !hideCfaRemarks && (
            <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
              <div
                className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                style={{ minHeight: "30rem" }}
              >
                <label className="color_747F9E fs_14">CFA Remarks</label>

                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Is That Uploaded Picture Is Clear?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues.is_image_clear == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the Invoice Number match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.invoice_number_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the Invoice date match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.invoice_date_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the distributor name match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.distributor_name_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the product name match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.product_name_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the quantity match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.quantity_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="color_262C3D fs_14">
                    Does the cfa name match with the uploaded image ?
                  </label>
                  <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                    {cfaRemarksValues?.cfa_name_match == true ? (
                      <label className="text_green">Yes</label>
                    ) : (
                      <label className="text_red">No</label>
                    )}
                  </div>
                </div>
              </div>

            </div>
          )}

          <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
            <div
              className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
              style={{ minHeight: "30rem" }}
            >
              <label className="color_747F9E fs_14">Distributor Remarks</label>

              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Is That Uploaded Picture Is Clear?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.is_image_clear == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice Number match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.invoice_number_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice date match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.invoice_date_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the distributor name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.distributor_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the product name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.product_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the quantity match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.quantity_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the customer name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.customer_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
              style={{ minHeight: "30rem" }}
            >
              <label className="color_747F9E fs_14">Customer Remarks</label>

              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Is That Uploaded Picture Is Clear?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.is_image_clear == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice Number match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.invoice_number_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice date match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.invoice_date_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the distributor name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.distributor_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the product name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.product_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the quantity match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.quantity_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the customer name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.customer_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
            <label className="basic-title mx-2 mb-1">Invoice remarks</label>
          </div>
          <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
            <div
              className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
              style={{ minHeight: "30rem" }}
            >
              <label className="color_747F9E fs_14">Distributor Remarks</label>

              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Is That Uploaded Picture Is Clear?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.is_image_clear == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice Number match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.invoice_number_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice date match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.invoice_date_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the distributor name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.distributor_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the product name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.product_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the quantity match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.quantity_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the customer name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.distributorRemarks.customer_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-10 col-lg-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
              style={{ minHeight: "30rem" }}
            >
              <label className="color_747F9E fs_14">Customer Remarks</label>

              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Is That Uploaded Picture Is Clear?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.is_image_clear == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice Number match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.invoice_number_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the Invoice date match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.invoice_date_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the distributor name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.distributor_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the product name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.product_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the quantity match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.quantity_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="color_262C3D fs_14">
                  Does the customer name match with the uploaded image ?
                </label>
                <div className="color_262C3D fs_14 fw_600 d-flex align-items-center">
                  {values?.customerRemarks.customer_name_match == true ? (
                    <label className="text_green">Yes</label>
                  ) : (
                    <label className="text_red">No</label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvoiceRemarks;
