import React, { useEffect, useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import CreateDoctorModal from "./CreateDoctorModal";

const DoctorDivisionList = ({
  setActiveDoctor,
  doctorDivisionData,
  loading,
  setSelectedDoctorID,
  vendor_Id,
  division_id,
  doctorDivisionView
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showOpenModal, setShowOpenModal] = useState(false);

  useEffect(() => {
    setFilteredData(doctorDivisionData);
  }, [doctorDivisionData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value.replace(/ /g, "");
    setSearchInput(value);

    if (trimmedValue === "") {
      setFilteredData(doctorDivisionData);
    } else {
      const filtered = doctorDivisionData.filter((data) => {
        const doctorWithoutSpaces =
          data?.name
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase()) ||
          data?.mobile
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase());
        return doctorWithoutSpaces;
      });
      setFilteredData(filtered);
    }
  };
  return (
    <>
      <div className="main-list-container my-2 py-1 d-flex justify-content-between flex-column">
        <main className="d-flex flex-column w-100 main-container">
          <div className="search-container d-flex justify-content-between align-items-center">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 text-dark px-2"
                type="text"
                placeholder="Search doctors here by Doctor Name, Mobile Number, Email Address..."
                onChange={handleInputChange}
              />
            </div>
            <div>
              <AdminButton
                background="#7065e4"
                color="white"
                width="100px"
                type="submit"
                onClick={() => {
                  setShowOpenModal(true);
                }}
              >
                <span className="m-2">CREATE</span>
              </AdminButton>
            </div>
          </div>
          <div style={{ height: "32rem", overflow: "auto" }}>
            <table className="main-table  text-nowrap">
              <thead
                style={{ position: "sticky", top: 0, zIndex: 55 }}
                className="bg-white"
              >
                <tr className="table-rows color_747f9e">
                  <th className="table-heads py-2" width={"10%"}>
                    SL. No
                  </th>
                  <th className="table-heads py-2" width={"20%"}>
                    Doctor Name
                  </th>
                  <th className="table-heads py-2" width={"20%"}>
                    Mobile Number
                  </th>
                  <th className="table-heads py-2" width={"15%"}>
                    City
                  </th>
                  <th className="table-heads py-2" width={"23%"}>
                    Email Address
                  </th>
                  <th className="table-heads py-2" width={"10%"}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {filteredData?.length > 0 ? (
                  filteredData?.map((data, index) => {
                    return (
                      <tr className="table-rows1 color_262c3d" key={index}>
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.name ? data?.name : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.mobile ? data?.mobile : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {data?.city?.name ? data?.city?.name : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.email ? data?.email : "--"}
                        </td>
                        <td className="table-each-row-data">
                          <button
                            className="button-view"
                            type="button"
                            onClick={() => {
                              setActiveDoctor(32);
                              setSelectedDoctorID(data?.id);
                            }}
                          >
                            <span className=""> View</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
      <CreateDoctorModal
        show={showOpenModal}
        handleClose={() => {
          setShowOpenModal(false);
        }}
        vendor_Id={vendor_Id}
        division_id={division_id}
        doctorDivisionView={doctorDivisionView}
      />
    </>
  );
};

export default DoctorDivisionList;
