import React, { useEffect, useState } from "react";
import back from "../../../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { gevCfaView } from "../../../../redux/actions/godsEyeViewActions";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import { loginToPortalCfa } from "../../../../redux/actions/manufacturerActions";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { useNavigate, useParams } from "react-router-dom";

const CfaView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [cfaData, setCfaData] = useState("");
  const [loading, setLoading] = useState(false);
  const vendor_id = params?.id.split("&")?.[0];
  const subId = params?.id.split("&")?.[3];
  useEffect(() => {
    gevCfaViewFnc();
  }, []);

  const gevCfaViewFnc = () => {
    setLoading(true);
    dispatch(gevCfaView(subId, onffSuccess, onffError));
  };

  const onffSuccess = (data) => {
    console.log(data?.data?.data);
    setCfaData(data?.data?.data);
    setLoading(false);
  };

  const onffError = (err) => {
    console.log(err);
    setLoading(false);
  };
  const loginToPortalFunc = () => {
    dispatch(loginToPortalCfa(cfaData?.id, onNavigateSuccess, onNavigateError));
  };

  const onNavigateSuccess = (data) => {
    console.log(data.data.data.url);
    window.open(data?.data?.data?.url, "_blank");
  };

  const onNavigateError = (err) => {
    console.log(err);
  };

  return (
    <>
      <div className="search-container d-flex justify-content-between ">
        <div></div>
        <div className="my-2">
          <AdminButton
            background="#2AD3E7"
            color="white"
            width="140px"
            type="button"
            onClick={() => loginToPortalFunc()}
          >
            <span className="">Login to Portal</span>
          </AdminButton>
          <AdminButton
            background="#7065E4"
            color="white"
            width="100px"
            onClick={() => {
              navigate(
                `/manufacturer/subscibe-list/view/${vendor_id}&cfa&edit&${cfaData?.id}`
              );
            }}
          >
            <span className="m-2">EDIT</span>
          </AdminButton>
        </div>
      </div>
      <div className="scroll-container">
        <div style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable />
            </div>
          ) : (
            <>
              <div className="form-width px-4 mx-2">
                <div className="my-4">
                  <label className=" color_747F9E fs_14">CFA ID</label>
                  <p className="fs_14">
                    {cfaData?.cfa_id ? cfaData?.cfa_id : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">CFA Name</label>
                  <p className="fs_14">
                    {cfaData?.cfa_name
                      ? cfaData?.cfa_name.charAt(0).toUpperCase() +
                        cfaData?.cfa_name.slice(1)
                      : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    CFA Depot Manager Name
                  </label>
                  <p className="fs_14">
                    {cfaData?.cfa_depot_manager
                      ? cfaData?.cfa_depot_manager
                      : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    CFA Email Address
                  </label>
                  <p className="fs_14">
                    {cfaData?.email ? cfaData?.email : "--"}
                  </p>
                </div>

                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    CFA Depot Manager Mobile Numbers
                  </label>
                  <p className="fs_14">
                    {cfaData?.mobile ? cfaData?.mobile : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">
                    Alternate Mobile Number
                  </label>
                  <p className="fs_14">
                    {cfaData?.alternate_mobile
                      ? cfaData?.alternate_mobile
                      : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">State</label>
                  <p className="fs_14">
                    {cfaData?.state?.name ? cfaData?.state?.name : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Address</label>
                  <p className="fs_14">
                    {cfaData?.address?.address_one},{" "}
                    {cfaData?.address?.address_two},
                    <span className="ms-1">
                      {cfaData?.address?.address_three}
                    </span>
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Landmark</label>
                  <p className="fs_14">
                    {cfaData?.address?.landmark
                      ? cfaData?.address?.landmark.charAt(0).toUpperCase() +
                        cfaData?.address?.landmark.slice(1)
                      : "--"}
                  </p>
                </div>
                <div className="my-4">
                  <label className=" color_747F9E fs_14">Pincode</label>
                  <p className="fs_14">
                    {cfaData?.pincode ? cfaData?.pincode : "--"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CfaView;
