import { call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  CREATE_MANUFACTURER_CLEAR,
  CREATE_MANUFACTURER_LOADING,
  CREATE_MANUFACTURER_SUCCESS,
  CREATE_MANUFACTURER_UPDATE,
} from "../actions/returnType";

import {
  ADD_DESIGNATION,
  ADD_DIVISION,
  ADD_HEAD_QUARTER,
  CREATE_MANUFACTURER,
  CREATE_MANUFACTURER_DRAFT,
  DELETE_MANUFACTURER,
  DISTRIBUTOR_BULK_VERIFY,
  DISTRIBUTOR_CSV_UPLOAD,
  DOCTOR_BULK_VERIFY,
  DOCTOR_CSV_UPLOAD,
  DOCUMENT1_IMG_UPLOAD,
  DOCUMENT2_IMG_UPLOAD,
  EDIT_DESIGNATION,
  EDIT_DIVISION,
  EDIT_HEAD_QUARTER,
  ENABLE_DISABLE_DIVISION,
  ENABLE_DISABLE_HEAD_QUARTER,
  ENALBE_DISABLE_HEAD_QUARTER,
  ENALBE_DISABLE_MANUFACTURER,
  FIELD_FORCE_BULK_VERIFY,
  FIELD_FORCE_CSV_UPLOAD,
  GENERATE_INVOICE,
  GENERATE_PO,
  GENERATE_PO_INVOICE,
  INVOICE_CHANGE_STATUS,
  INVOICE_DETAILS,
  INVOICE_MAIL,
  LOGIN_TO_PORTAL_MANUFACTURER,
  MANUFACTURER_DETAIL,
  MANUFACTURER_EDIT,
  MANUFACTURER_LIST,
  MANUFACTURER_LOGO_UPLOAD,
  MANUFACTURER_VERSION_DETAIL,
  MANUFACTURER_VERSION_LIST,
  PHARMACY_BULK_VERIFY,
  PHARMACY_CSV_UPLOAD,
  PO_CSV_DOWNLOAD,
  PO_CSV_MAIL,
  PO_DISTRIBUTOR_LIST,
  PO_INVOICED_LIST,
  PO_LIST,
  PO_OFFERTYPE_LIST,
  PO_STATE_LIST,
  PO_UNINVOICED_LIST,
  PRODUCT_BULK_VERIFY,
  PRODUCT_CSV_UPLOAD,
  REIMBURSEMENT_LIST,
  SEND_MAIL,
  TOGGLE_ANALYTICS,
  UPDATE_MANUFACTURER_CREATE,
  PRODUCT_DIVISION_VIEW,
  FIELDFORCE_DIVISION_VIEW,
  DOCTOR_DIVISION_VIEW,
  PHARMACY_DIVISION_VIEW,
  DOCTOR_DIV_VIEW,
  PHARMACY_DIV_VIEW,
  CREATE_DOCTOR,
  CREATE_PHARMACY,
  LOGIN_TO_PORTAL_CFA,
  MANUFACTURER_PREMIUM_LIST,
  MANUFACTURER_PREMIUM_VERSION_LIST,
  DOCUMENT3_IMG_UPLOAD,
  PO_VIEW,
} from "../actions/types";

import {
  fieldForceCsvUploadApi,
  manufactuereDocumentUploadApi,
  manufacturerCreateApi,
  manufacturerCreateDraftApi,
  manufacturerDeleteApi,
  manufacturerEditApi,
  manufacturerEnableDisableApi,
  manufacturerListApi,
  manufacturerLogoUploadApi,
  manufacturerVersionDetailApi,
  manufacturerVersionListApi,
  manufacturerViewApi,
  productBulkVerify,
  productCsvUploadApi,
  reimbursementListApi,
  distributorCSVUploadApi,
  doctorCSVUploadApi,
  pharmacyCSVUploadApi,
  fieldForceBulkVerify,
  distributorBulkVerify,
  doctorBulkVerify,
  pharmacyBulkVerify,
  loginToPortalManufacturerApi,
  sendMailApi,
  addDesignationApi,
  addHeadQuarterApi,
  addDivisionApi,
  editDivisionApi,
  editDesignationApi,
  editHeadQuarterApi,
  enableDisableHeadQuaterApi,
  enableDisableDivisionApi,
  poStateListApi,
  poDistributorListApi,
  poListApi,
  poOfferTypeListApi,
  generatePoApi,
  poUnvoicedListApi,
  poInvoicedListApi,
  generatePoInvoiceApi,
  generateInvoiceApi,
  invoiceChangeStatusApi,
  InvoiceDetailsApi,
  invoiceSendMailApi,
  poCsvSendMailApi,
  poCsvDownloadApi,
  toggleAnalyticsApi,
  productDivisionViewApi,
  ffDivisionViewApi,
  doctorDivisionViewApi,
  pharmacyDivisionViewApi,
  doctorDivViewApi,
  pharmacyDivViewApi,
  createDoctorApi,
  createPharmacyApi,
  loginToPortalCfaApi,
  manufacturerPremiumListApi,
  manufacturerPremiumVersionListApi,
  poViewApi,
} from "../apis/manufactuereApi";

export function* manufacturerListWorker(action) {
  try {
    const res = yield call(manufacturerListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerPremiumListWorker(action) {
  try {
    const res = yield call(manufacturerPremiumListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerCreateWorker(action) {
  try {
    const res = yield call(manufacturerCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield put({ type: CREATE_MANUFACTURER_CLEAR });
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerCreateDraftWorker(action) {
  try {
    yield put({ type: CREATE_MANUFACTURER_LOADING });
    const res = yield call(manufacturerCreateDraftApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield put({
        type: CREATE_MANUFACTURER_SUCCESS,
        vendor_id: res?.data?.data?.id,
      });
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* updateManufacturerCreateWorker(action) {
  try {
    let manCreateData = yield select(
      (state) => state.manufacturerCreateReducer
    );
    if (action?.data?.vendor_id) {
      console.log("action", action?.data?.vendor_id);
      yield put({
        type: CREATE_MANUFACTURER_UPDATE,
        vendor_id: action?.data?.vendor_id,
      });
    } else if (action?.data?.designations) {
      yield put({
        type: CREATE_MANUFACTURER_UPDATE,
        designations: action?.data?.designations,
      });
      yield action.onSuccess();
    } else if (action?.data?.headquaters) {
      yield put({
        type: CREATE_MANUFACTURER_UPDATE,
        headquaters: action?.data?.headquaters,
      });
      yield action.onSuccess();
    } else if (action?.data?.divisions) {
      yield put({
        type: CREATE_MANUFACTURER_UPDATE,
        divisions: action?.data?.divisions,
      });
      yield action.onSuccess();
    } else {
      yield action.onError();
    }

    console.log("herere", manCreateData);
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerEditWorker(action) {
  try {
    const res = yield call(manufacturerEditApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerDetailWorker(action) {
  try {
    const res = yield call(manufacturerViewApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerVersionWorker(action) {
  try {
    const res = yield call(manufacturerVersionListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* manufacturerPremiumVersionWorker(action) {
  try {
    const res = yield call(manufacturerPremiumVersionListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerVersionDetailWorker(action) {
  try {
    const res = yield call(manufacturerVersionDetailApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fetchreimbursementListWorker(action) {
  try {
    const res = yield call(reimbursementListApi);
    if (res.status == "200") {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerDeleteWorker(action) {
  try {
    const res = yield call(manufacturerDeleteApi, { vendor_id: action.id });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerEnableDisableWorker(action) {
  try {
    const res = yield call(manufacturerEnableDisableApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerLogoUpldWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(manufacturerLogoUploadApi, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* document1UploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(manufactuereDocumentUploadApi, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* document2UploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(manufactuereDocumentUploadApi, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* document3UploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(manufactuereDocumentUploadApi, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productCsvUploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.file, action.file.name);
    const res = yield call(productCsvUploadApi, action.data, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* productBulkVerifyWorker(action) {
  try {
    console.log(action.data);
    action["data"].state_id = "ebefc74a-7d2d-11ed-a1eb-0242ac120002";
    console.log(action.data);
    const res = yield call(productBulkVerify, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fieldForceCsvUploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.file, action.file.name);
    const res = yield call(fieldForceCsvUploadApi, action.data, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* fieldForceBulkVerifyWorker(action) {
  try {
    const res = yield call(fieldForceBulkVerify, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorCsvUploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.file, action.file.name);
    const res = yield call(distributorCSVUploadApi, action.data, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorBulkVerifyWorker(action) {
  try {
    const res = yield call(distributorBulkVerify, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* doctorCsvUploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.file, action.file.name);
    const res = yield call(doctorCSVUploadApi, action.data, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* doctorBulkVerifyWorker(action) {
  try {
    const res = yield call(doctorBulkVerify, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pharmacyCsvUploadWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.file, action.file.name);
    const res = yield call(pharmacyCSVUploadApi, action.data, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pharmacyBulkUploadWorker(action) {
  try {
    const res = yield call(pharmacyBulkVerify, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

// DIVISION VIEW STARTS

export function* productDivisionViewUploadWorker(action) {
  try {
    const res = yield call(productDivisionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* ffDivisionViewUploadWorker(action) {
  try {
    const res = yield call(ffDivisionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* doctorDivisionViewUploadWorker(action) {
  try {
    const res = yield call(doctorDivisionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pharmacyDivisionViewUploadWorker(action) {
  try {
    const res = yield call(pharmacyDivisionViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* doctorDivViewUploadWorker(action) {
  try {
    const res = yield call(doctorDivViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* createDoctorWorker(action) {
  try {
    const res = yield call(createDoctorApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* createPharmacyWorker(action) {
  try {
    const res = yield call(createPharmacyApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pharmacyDivViewUploadWorker(action) {
  try {
    const res = yield call(pharmacyDivViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

// DIVISION VIEW ENDS

export function* loginToPortalManufacturerWorker(action) {
  try {
    console.log(action);
    const res = yield call(loginToPortalManufacturerApi, {
      vendor_id: action.vendor_id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* loginToPortalCfaWorker(action) {
  try {
    console.log(action);
    const res = yield call(loginToPortalCfaApi, {
      cfa_id: action.cfa_id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* sendMailWorker(action) {
  try {
    const res = yield call(sendMailApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* addDesignationWorker(action) {
  try {
    const res = yield call(addDesignationApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* addHeadQuarterWorker(action) {
  try {
    const res = yield call(addHeadQuarterApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* addDivisionWorker(action) {
  try {
    const res = yield call(addDivisionApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* editDesignationWorker(action) {
  try {
    const res = yield call(editDesignationApi, action.data);
    console.log(action.onSuccess);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* editHeadQuarterWorker(action) {
  try {
    const res = yield call(editHeadQuarterApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* edtiDivisionWorker(action) {
  try {
    const res = yield call(editDivisionApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* enableDisableHeadQuarterWorker(action) {
  try {
    const res = yield call(enableDisableHeadQuaterApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* enableDisableDivisionWorker(action) {
  try {
    const res = yield call(enableDisableDivisionApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poStateListWorker(action) {
  try {
    console.log(action);
    const res = yield call(poStateListApi, action.data);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poDistributorListWorker(action) {
  try {
    const res = yield call(poDistributorListApi, action.data);
    // console.log(res , "response");
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.distributor.id,
        label: ele.distributor.firm_name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poOfferTypeWorker(action) {
  try {
    const res = yield call(poOfferTypeListApi, action?.id);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.offer_type,
        label: ele.offer_type,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poListWorker(action) {
  try {
    const res = yield call(poListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poViewWorker(action) {
  try {
    const res = yield call(poViewApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* generatePoWorker(action) {
  try {
    const res = yield call(generatePoApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poUninvoicedListWorker(action) {
  try {
    const res = yield call(poUnvoicedListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poInvoicedListWorker(action) {
  try {
    const res = yield call(poInvoicedListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* generatePoInvoiceWorker(action) {
  try {
    const res = yield call(generatePoInvoiceApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* generateInvoiceWorker(action) {
  try {
    const res = yield call(generateInvoiceApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* invoiceChangeStatusWorker(action) {
  try {
    const res = yield call(invoiceChangeStatusApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* invoiceDetailsWorker(action) {
  try {
    const res = yield call(InvoiceDetailsApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* invoiceSendMailWorker(action) {
  try {
    const res = yield call(invoiceSendMailApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poCsvSendMailWorker(action) {
  try {
    const res = yield call(poCsvSendMailApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* poCsvDownloadWorker(action) {
  try {
    const res = yield call(poCsvDownloadApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* toggleAnalyticsWorker(action) {
  try {
    const res = yield call(toggleAnalyticsApi, {
      vendor_id: action.id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerListWatcher() {
  yield takeLatest(MANUFACTURER_LIST, manufacturerListWorker);
}

export function* manufacturerCreateWatcher() {
  yield takeLatest(CREATE_MANUFACTURER, manufacturerCreateWorker);
}

export function* manufacturerCreateDraftWatcher() {
  yield takeLatest(CREATE_MANUFACTURER_DRAFT, manufacturerCreateDraftWorker);
}

export function* manufacturerEditWatcher() {
  yield takeLatest(MANUFACTURER_EDIT, manufacturerEditWorker);
}

export function* manufacturerDetailWatcher() {
  yield takeLatest(MANUFACTURER_DETAIL, manufacturerDetailWorker);
}

export function* manufacturerVersionWatcher() {
  yield takeLatest(MANUFACTURER_VERSION_LIST, manufacturerVersionWorker);
}

export function* fetchreimbursementListWatcher() {
  yield takeLatest(REIMBURSEMENT_LIST, fetchreimbursementListWorker);
}

export function* manufacturerVersionDetailWatcher() {
  yield takeLatest(
    MANUFACTURER_VERSION_DETAIL,
    manufacturerVersionDetailWorker
  );
}

export function* manufacturerDeleteWatcher() {
  yield takeLatest(DELETE_MANUFACTURER, manufacturerDeleteWorker);
}

export function* manufacturerEnableDisableWatcher() {
  yield takeLatest(
    ENALBE_DISABLE_MANUFACTURER,
    manufacturerEnableDisableWorker
  );
}

export function* manufacturerLogoUpldWatcher() {
  yield takeLatest(MANUFACTURER_LOGO_UPLOAD, manufacturerLogoUpldWorker);
}

export function* document1UploadWatcher() {
  yield takeLatest(DOCUMENT1_IMG_UPLOAD, document1UploadWorker);
}

export function* document2UploadWatcher() {
  yield takeLatest(DOCUMENT2_IMG_UPLOAD, document2UploadWorker);
}
export function* document3UploadWatcher() {
  yield takeLatest(DOCUMENT3_IMG_UPLOAD, document3UploadWorker);
}

export function* productCsvUploadWatcher() {
  yield takeLatest(PRODUCT_CSV_UPLOAD, productCsvUploadWorker);
}

export function* productBulkVerifyWatcher() {
  yield takeLatest(PRODUCT_BULK_VERIFY, productBulkVerifyWorker);
}

export function* fieldForceCsvUploadWatcher() {
  yield takeLatest(FIELD_FORCE_CSV_UPLOAD, fieldForceCsvUploadWorker);
}

export function* fieldForceBulkVerifyWatcher() {
  yield takeLatest(FIELD_FORCE_BULK_VERIFY, fieldForceBulkVerifyWorker);
}

export function* distributorCsvUploadWatcher() {
  yield takeLatest(DISTRIBUTOR_CSV_UPLOAD, distributorCsvUploadWorker);
}

export function* distributorBulkVerifyWatcher() {
  yield takeLatest(DISTRIBUTOR_BULK_VERIFY, distributorBulkVerifyWorker);
}

export function* doctorCsvUploadWatcher() {
  yield takeLatest(DOCTOR_CSV_UPLOAD, doctorCsvUploadWorker);
}

export function* doctorBulkVerifyWatcher() {
  yield takeLatest(DOCTOR_BULK_VERIFY, doctorBulkVerifyWorker);
}

export function* pharmacyCsvUploadWatcher() {
  yield takeLatest(PHARMACY_CSV_UPLOAD, pharmacyCsvUploadWorker);
}

export function* pharmacyBulkUploadWatcher() {
  yield takeLatest(PHARMACY_BULK_VERIFY, pharmacyBulkUploadWorker);
}

// DIVISION VIEW STARTS

export function* productDivisionViewUploadWatcher() {
  yield takeLatest(PRODUCT_DIVISION_VIEW, productDivisionViewUploadWorker);
}

export function* ffDivisionViewUploadWatcher() {
  yield takeLatest(FIELDFORCE_DIVISION_VIEW, ffDivisionViewUploadWorker);
}

export function* doctorDivisionViewUploadWatcher() {
  yield takeLatest(DOCTOR_DIVISION_VIEW, doctorDivisionViewUploadWorker);
}

export function* createDoctorWalker() {
  yield takeLatest(CREATE_DOCTOR, createDoctorWorker);
}

export function* createPharmacyWalker() {
  yield takeLatest(CREATE_PHARMACY, createPharmacyWorker);
}

export function* pharmacyDivisionViewUploadWatcher() {
  yield takeLatest(PHARMACY_DIVISION_VIEW, pharmacyDivisionViewUploadWorker);
}

export function* doctorDivViewUploadWatcher() {
  yield takeLatest(DOCTOR_DIV_VIEW, doctorDivViewUploadWorker);
}

export function* pharmacyDivViewUploadWatcher() {
  yield takeLatest(PHARMACY_DIV_VIEW, pharmacyDivViewUploadWorker);
}

// DIVISION VIEW ENDS

export function* loginToPortalManufacturerWatcher() {
  yield takeLatest(
    LOGIN_TO_PORTAL_MANUFACTURER,
    loginToPortalManufacturerWorker
  );
}

export function* sendMailWatcher() {
  yield takeLatest(SEND_MAIL, sendMailWorker);
}

export function* updateManufacturerCreateWatcher() {
  yield takeLatest(UPDATE_MANUFACTURER_CREATE, updateManufacturerCreateWorker);
}

export function* addDesignationWatcher() {
  yield takeLatest(ADD_DESIGNATION, addDesignationWorker);
}

export function* addHeadQuarterWatcher() {
  yield takeLatest(ADD_HEAD_QUARTER, addHeadQuarterWorker);
}

export function* addDivisionWatcher() {
  yield takeLatest(ADD_DIVISION, addDivisionWorker);
}

export function* editDesignationWatcher() {
  yield takeLatest(EDIT_DESIGNATION, editDesignationWorker);
}

export function* editHeadQuarterWatcher() {
  yield takeLatest(EDIT_HEAD_QUARTER, editHeadQuarterWorker);
}

export function* editDivisionWatcher() {
  yield takeLatest(EDIT_DIVISION, edtiDivisionWorker);
}

export function* enableDisableHeadQuarterWatcher() {
  yield takeLatest(ENABLE_DISABLE_HEAD_QUARTER, enableDisableHeadQuarterWorker);
}

export function* enableDisableDivisionWatcher() {
  yield takeLatest(ENABLE_DISABLE_DIVISION, enableDisableDivisionWorker);
}

export function* poStateListWatcher() {
  yield takeLatest(PO_STATE_LIST, poStateListWorker);
}

export function* poListWatcher() {
  yield takeLatest(PO_LIST, poListWorker);
}

export function* poViewWatcher() {
  yield takeLatest(PO_VIEW, poViewWorker);
}

export function* poDistributorListWatcher() {
  yield takeLatest(PO_DISTRIBUTOR_LIST, poDistributorListWorker);
}

export function* poOfferTypeListWatcher() {
  yield takeLatest(PO_OFFERTYPE_LIST, poOfferTypeWorker);
}

export function* generatePoWatcher() {
  yield takeLatest(GENERATE_PO, generatePoWorker);
}

export function* poUninvoicedListWatcher() {
  yield takeLatest(PO_UNINVOICED_LIST, poUninvoicedListWorker);
}

export function* poInvoicedListWatcher() {
  yield takeLatest(PO_INVOICED_LIST, poInvoicedListWorker);
}

export function* generatePoInvoiceWatcher() {
  yield takeLatest(GENERATE_PO_INVOICE, generatePoInvoiceWorker);
}

export function* generateInvoiceWatcher() {
  yield takeLatest(GENERATE_INVOICE, generateInvoiceWorker);
}

export function* invoiceChangeStatusWatcher() {
  yield takeLatest(INVOICE_CHANGE_STATUS, invoiceChangeStatusWorker);
}

export function* invoiceDetailsWatcher() {
  yield takeLatest(INVOICE_DETAILS, invoiceDetailsWorker);
}

export function* invoiceSendMailWatcher() {
  yield takeLatest(INVOICE_MAIL, invoiceSendMailWorker);
}

export function* poCsvSendMailWatcher() {
  yield takeLatest(PO_CSV_MAIL, poCsvSendMailWorker);
}

export function* poCsvDownloadWatcher() {
  yield takeLatest(PO_CSV_DOWNLOAD, poCsvDownloadWorker);
}
export function* toggleAnalyticsWatcher() {
  yield takeLatest(TOGGLE_ANALYTICS, toggleAnalyticsWorker);
}
export function* loginToPortalCfaWatcher() {
  yield takeLatest(LOGIN_TO_PORTAL_CFA, loginToPortalCfaWorker);
}
export function* manufacturerPremiumListWatcher() {
  yield takeLatest(MANUFACTURER_PREMIUM_LIST, manufacturerPremiumListWorker);
}
export function* manufacturerPremiumVersionWatcher() {
  yield takeLatest(
    MANUFACTURER_PREMIUM_VERSION_LIST,
    manufacturerPremiumVersionWorker
  );
}
