import React, { useState } from "react";
import EnableDisableModal from "../../../components/modals/enableDisableModal";
import DeleteModal from "../../../components/modals/deleteModal";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const CustomerVerificationTableData = ({
  data,
  index,
  fetchCustomerList,
  currentPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [typeData, setTypeData] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showSuccessPopup1, setShowSuccessPopup1] = useState(false);
  const [showSuccessMsg1, setShowSuccessMsg1] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showErrorPopup1, setShowErrorPopup1] = useState(false);
  const [showErrorMsg1, setShowErrorMsg1] = useState();
  return (
    <tr className="table-rows1 " key={index}>
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.code
            ? data?.code
            : "--"
          : data?.pharmacyDoctors?.length !== 0
          ? data?.pharmacyDoctors?.[0]?.doctor?.code
          : "--"}
      </td>
      <td className="table-each-row-data">
        {data?.customer_type == "doctor"
          ? data?.doctorAttachPharmacy?.pharmacy?.code
            ? data?.doctorAttachPharmacy?.pharmacy?.code
            : "--"
          : data?.code
          ? data?.code
          : "--"}
      </td>
      <td className="table-each-row-data text-capitalize">
      {data?.customer_type == "doctor"
          ? data?.name
            ? data?.name
            : "--"
          : data?.pharmacyDoctors?.length !== 0
          ? data?.pharmacyDoctors?.[0]?.doctor?.name
          : "--"}
      </td>
      <td className="table-each-row-data text-capitalize">

      {data?.customer_type == "doctor"
          ? data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name
            ? data?.doctorAttachPharmacy?.pharmacy?.pharmacy_name
            : "--"
          : data?.pharmacy_name
          ? data?.pharmacy_name
          : "--"}
      
      </td>

      <td className="table-each-row-data text-capitalize">
        {data?.city?.name ? data?.city?.name : "--"}
      </td>
      <td className="table-each-row-data text-capitalize">
        {data?.pharmacy_type ? data?.pharmacy_type : "--"}
      </td>

      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() =>
              navigate(`/support/customer-verification/view/${data.id}`, {
                state: {
                  user_type: data?.customer_type,
                  pharmacy_id: data?.customer_type !== "doctor" && data.id,
                  doctor_id: data?.customer_type == "doctor" && data.id,
                },
              })
            }
          >
            <span className=""> View</span>
          </button>
        </div>
      </td>

      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <SuccessPopup show={showSuccessPopup1} title={showSuccessMsg1} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        // handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={showErrorPopup1}
        title={showErrorMsg1}
        // handleClose={closeErrorPopup1}
      />
    </tr>
  );
};

export default CustomerVerificationTableData;
