import { call, put, take, takeLatest } from "redux-saga/effects";
import {
  CITY_LIST,
  DISTRICT_LIST,
  DIVISION_LIST,
  PINCODE_AUTO_LIST,
  SECOND_CITY_LIST,
  SECOND_DISTRICT_LIST,
  SECOND_STATE_LIST,
  STATE_LIST,
} from "../actions/types";
import * as commonApis from "../apis/commonApis.js";

export function* stateListWorker(action) {
  try {
    const res = yield call(commonApis.stateListApi, {});
    if (res.status == 200) {
      let newArr = res.data.data.states.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* secondStateListWorker(action) {
  try {
    const res = yield call(commonApis.stateListApi, {});
    if (res.status == 200) {
      let newArr = res.data.data.states.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* districtListWorker(action) {
  try {
    const res = yield call(commonApis.districtListApi, action.data);
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.districts.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* secondDistrictListWorker(action) {
  try {
    const res = yield call(commonApis.districtListApi, action.data);
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.districts.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* cityListWorker(action) {
  try {
    const res = yield call(commonApis.cityListApi, action.data);
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.cities.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* secondCityListWorker(action) {
  try {
    const res = yield call(commonApis.cityListApi, action.data);
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.cities.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* divisionListWorker(action) {
  try {
    const res = yield call(commonApis.divisionListApi);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* pincodeAutoListWorker(action) {
  try {
    const res = yield call(commonApis.pincodeAutoListApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* stateListWatcher() {
  yield takeLatest(STATE_LIST, stateListWorker);
}

export function* districtListWatcher() {
  yield takeLatest(DISTRICT_LIST, districtListWorker);
}

export function* cityListWatcher() {
  yield takeLatest(CITY_LIST, cityListWorker);
}

export function* divisionistWatcher() {
  yield takeLatest(DIVISION_LIST, divisionListWorker);
}
export function* secondStateListWatcher() {
  yield takeLatest(SECOND_STATE_LIST, secondStateListWorker);
}
export function* secondDistrictListWatcher() {
  yield takeLatest(SECOND_DISTRICT_LIST, secondDistrictListWorker);
}
export function* secondCityListWatcher() {
  yield takeLatest(SECOND_CITY_LIST, secondCityListWorker);
}
export function* pincodeAutoListWatcher() {
  yield takeLatest(PINCODE_AUTO_LIST, pincodeAutoListWorker);
}
