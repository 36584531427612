import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import back from "../../assets/icons/back.svg";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import "./superUser.css";
import * as Yup from "yup";
import ErrorMsg from "../../components/form/errorMsg";
import InputField from "../../components/form/inputField/inputField";
import {
  fetchRoleList,
  permissionsList,
  superAdminDetail,
  superAdminEdit,
} from "../../redux/actions/superAdminActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../components/popups/SuccessPopup";
import ErrorPopup from "../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import InputCapitalField from "../../components/form/inputField/inputCapitalField";
import Select from "react-select";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const SuperUserEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [adminViewData, setAdminViewData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [permission, setPermission] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  const phoneRegex = /^([+]\d{2})?\d{10}$/;

  useEffect(() => {
    setLoading(true);
    dispatch(superAdminDetail(params.id, fetchDetailSuccess, fetchDetailError));
    dispatch(fetchRoleList(fetchPermissionSuccess, fetchPermissionError));
  }, []);

  const fetchDetailSuccess = (data) => {
    setLoading(false);
    setAdminViewData(data.data.data);
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const fetchDetailError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  const fetchPermissionSuccess = (data) => {
    console.log("permissions", data);
    setPermission(data);
  };

  const fetchPermissionError = (data) => {
    console.log(data);
  };

  const initialValues = {
    admin_id: adminViewData?.id || "",
    employee_id: adminViewData?.employee_id || "",
    name: adminViewData?.name || "",
    email: adminViewData?.email || "",
    mobile: adminViewData?.mobile || "",
    role_id: adminViewData?.role_id || "",
  };

  const validationSchema = Yup.object().shape({
    employee_id: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email").required("Required"),
    mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
  });

  const resetFunc = (setFieldValue) => {
    setFieldValue("employee_id", adminViewData?.employee_id);
    setFieldValue("name", adminViewData?.name);
    setFieldValue("email", adminViewData?.email);
    setFieldValue("mobile", adminViewData?.mobile);
    setFieldValue("role_id", adminViewData?.role_id);
  };

  const onSubmit = (values) => {
    console.log(values);
    dispatch(superAdminEdit(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/super-user/user/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        (adminViewData?.employee_id
          ? ` / ${adminViewData?.employee_id} - edit`
          : " / edit")}
    </span>
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="view-spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : (
              <div className="scrolling">
                <div className="form-width ms-4 ps-3 py-1">
                  <div className="my-4 ">
                    <InputField
                      id={"employee_id"}
                      label={"Employee ID"}
                      labelColor={"Neutral_Color"}
                      type={"text"}
                      placeholder={"Enter employee id"}
                      name={"employee_id"}
                      value={values.employee_id}
                    />
                  </div>
                  <div className="my-4">
                    <InputCapitalField
                      setFieldValue={setFieldValue}
                      id={"name"}
                      label={"Name"}
                      labelColor={"Neutral_Color"}
                      type={"text"}
                      placeholder={"Enter name"}
                      name={"name"}
                      value={values.name}
                    />
                  </div>
                  <div className="select-container">
                    <label className="w-100 mx-1 Neutral_Color  fs_14">
                      Role
                    </label>
                    <Field name={"role_id"}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          options={permission}
                          styles={customStyles}
                          placeholder={"Enter role"}
                          value={permission.filter(
                            (s) => s.value == values.role_id
                          )}
                          onChange={(selected) => {
                            setFieldValue("role_id", selected?.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMsg name="role_id" />
                  </div>
                  <div className="my-4">
                    <InputField
                      id={"email"}
                      label={"Email Address"}
                      labelColor={"Neutral_Color"}
                      type={"text"}
                      disabled={true}
                      placeholder={"Enter email address"}
                      name={"email"}
                      value={values.email}
                    />
                  </div>
                  <div className="my-4">
                    <InputField
                      id={"mobile"}
                      label={"Phone Number"}
                      labelColor={"Neutral_Color"}
                      type={"text"}
                      placeholder={"Enter phone number"}
                      name={"mobile"}
                      maxLength={10}
                      value={values.mobile}
                    />
                  </div>

                  {/* <div className="my-0">
                      <label className="Neutral_Color fs_14">Permission</label>
                    </div>
                    <div className="my-1 d-flex  label-container align-items-center justify-content-start ">
                      <div className="col-5">
                        <label className="color_b0b3c7 fs_14">Models</label>
                      </div>
                      <label className="color_b0b3c7 fs_14 ">Read</label>
                      <label className="color_b0b3c7 fs_14 ms-4 ps-2">Write</label>
                    </div>
                    {permission?.map((ele, index) => (
                      <div
                        className="d-flex my-1 label-container align-items-center justify-content-start"
                        key={index}
                      >
                        <div className="col-5">
                          <label className="fs_14">{ele.label}</label>
                        </div>
                        <div className="ms-2">
                          <span className="fs_14 my-1">
                            <input
                              type={"checkbox"}
                              className="CheckBox"
                              onChange={(e) => {
                                console.log(values.permissions, ele.value);
                                selectPermission(
                                  e,
                                  ele?.value,
                                  setFieldValue,
                                  "read",
                                  values
                                );
                              }}
                            />
                          </span>
                        </div>
                        <div className="ms-5">
                          <span className="fs_14 my-1">
                            <input
                              type={"checkbox"}
                              className="CheckBox"
                              onChange={(e) => {
                                values.permissions((ele) => {
                                  console.log(ele);
                                });
                                console.log(ele.value);
                                selectPermission(
                                  e,
                                  ele?.value,
                                  setFieldValue,
                                  "write",
                                  values
                                );
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                    <ErrorMsg name={"permissions"} /> */}
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default SuperUserEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
