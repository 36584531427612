import {
  CREATE_SUPER_ADMIN,
  DELETE_SUPER_ADMIN,
  PERMISSION_LIST,
  SUPER_ADMIN_DETAIL,
  SUPER_ADMIN_EDIT,
  SUPER_ADMIN_LIST,
  SUPER_ADMIN_VERSION_DETAIL,
  SUPER_ADMIN_VERSION_LIST,
  SUPER_ADMIN_ROLE_LIST,
  DELETE_SUPER_ADMIN_ROLE,
  CREATE_SUPER_ADMIN_ROLE,
  ROLE_PERMISSION_LIST,
  SUPER_ADMIN_ROLE_DETAIL,
  SUPER_ADMIN_ROLE_EDIT,
  SUPER_ADMIN_ROLE_VERSION_LIST,
  SUPER_ADMIN_ROLE_VERSION_DETAIL,
  ROLE_LIST,
} from "./types";

export const superAdminList = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const superAdminCreate = (data, onSuccess, onError) => {
  return {
    type: CREATE_SUPER_ADMIN,
    data,
    onSuccess,
    onError,
  };
};
export const superAdminEdit = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const superAdminDetail = (id, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const superAdminVersionList = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const superAdminVersionDetial = (id, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_VERSION_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const permissionsList = (onSuccess, onError) => {
  return {
    type: PERMISSION_LIST,
    onSuccess,
    onError,
  };
};
export const superAdminDelete = (id, onSuccess, onError) => {
  return {
    type: DELETE_SUPER_ADMIN,
    id,
    onSuccess,
    onError,
  };
};

// SUPERADMIN ROLE

export const superAdminroleList = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_ROLE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const superAdminRoleCreate = (data, onSuccess, onError) => {
  return {
    type: CREATE_SUPER_ADMIN_ROLE,
    data,
    onSuccess,
    onError,
  };
};

export const superAdminRoleEdit = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_ROLE_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const superAdminRoleDetail = (id, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_ROLE_DETAIL,
    id,
    onSuccess,
    onError,
  };
};

export const permissionsRoleList = (onSuccess, onError) => {
  return {
    type: ROLE_PERMISSION_LIST,
    onSuccess,
    onError,
  };
};

export const superAdminRoleVersionList = (data, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_ROLE_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const superAdminRoleVersionDetail = (id, onSuccess, onError) => {
  return {
    type: SUPER_ADMIN_ROLE_VERSION_DETAIL,
    id,
    onSuccess,
    onError,
  };
};

export const superAdminRoleDelete = (id, onSuccess, onError) => {
  return {
    type: DELETE_SUPER_ADMIN_ROLE,
    id,
    onSuccess,
    onError,
  };
};
export const fetchRoleList = (onSuccess, onError) => {
  return {
    type: ROLE_LIST,
    onSuccess,
    onError,
  };
};
