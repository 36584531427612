import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/deleteModal";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { chapterDelete } from "../../../redux/actions/ffTrainingAction";

const ChaptersListTableData = ({
    data,
    index,
    fetchChaptersList,
    currentPage,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState();

    const delFunc = () => {
        dispatch(chapterDelete({ id: deleteId }, onDelSuccess, onDeleteError));
    };

    const timeOutFnc = () => {
        setShowSuccessPopup(false);
        fetchChaptersList();
    };

    const onDelSuccess = (data) => {
        setShowSuccessPopup(true);
        const str = data.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowSuccessMsg(str_modify);
        setTimeout(timeOutFnc, 2000);
    };

    const closeErrorPopup = () => {
        setShowErrorPopup(false);
    };

    const onDeleteError = (err) => {
        console.log(err);
        const str = err.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowErrorMsg(str_modify);
        setShowErrorPopup(true);
    };

    return (
        <tr className="table-rows1 ">
            <td className="table-each-row-data">
                {(currentPage - 1) * 10 + (index + 1)}
            </td>
            <td className="table-each-row-data">{data.chapter_id}</td>
            <td className="table-each-row-data">{data.name}</td>
            <td className="table-each-row-data">{data.priority}</td>
            <td className="table-each-row-data ">
                <div className="all-button d-flex align-items-center">
                    <button
                        className="button-view"
                        type="button"
                        onClick={() => navigate(`/ff-training/chapters/view/${data.id}`)}
                    >
                        <span className=""> View</span>
                    </button>
                    <span className="vr  vr_line mt-1 "></span>
                    <button
                        className="button-edit1"
                        type="button"
                        onClick={() => navigate(`/ff-training/chapters/edit/${data.id}`)}
                    >
                        Edit
                    </button>

                    <span className="vr vr_line mt-1"></span>
                    <button
                        className="button-disable"
                        type="button"
                        color={"green"}
                        onClick={() => {
                            setDeleteId(data.id);
                            setDeleteShow(true);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </td>

            <DeleteModal
                delFunc={delFunc}
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                title={`chapter - ${data.chapter_id} ?`}
            />
            <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
            <ErrorPopup
                show={showErrorPopup}
                title={showErrorMsg}
                handleClose={closeErrorPopup}
            />
        </tr>
    );
};

export default ChaptersListTableData;
