import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const errorToast = (message) => {
  toast.error(message, {
    position: "bottom-right",
    theme: "light",
  });  
};

export default errorToast