import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ForgotPassword from "../modules/auth/forgot-reset-password/forgot-password";
import PasswordChanged from "../modules/auth/forgot-reset-password/password-changed";
import PasswordEmailSent from "../modules/auth/forgot-reset-password/password-email-sent";
import ResetPassword from "../modules/auth/forgot-reset-password/reset-password";
import Login from "../modules/auth/login/login";
import CustomerEdit from "../modules/customers/customer/customer-edit/customer-edit";
import CustomersList from "../modules/customers/customer/customer-list";
import CustomerVersionView from "../modules/customers/customer/customer-version-details";
import CustomerVersionList from "../modules/customers/customer/customer-version-list";
import CustomerView from "../modules/customers/customer/customer-view";
import CustomerRequestList from "../modules/customers/request/request-list";
import RequestView from "../modules/customers/request/request-view";
import DistributorView from "../modules/distributor/distributor-details";
import DistributorEdit from "../modules/distributor/distributor-edit";
import DistributorList from "../modules/distributor/distributor-list";
import DistributorVersionView from "../modules/distributor/distributor-version-details";
import DistributorVersionList from "../modules/distributor/distributor-version-list";
import PageNotFound from "../modules/error-page";
import ManufacturerVersionView from "../modules/manufacturer/manufacturer-version-view";
import FaqCreate from "../modules/FAQ's/faq-create";
import FaqEdit from "../modules/FAQ's/faq-edit";
import FaqList from "../modules/FAQ's/faq-list";
import FaqVersionList from "../modules/FAQ's/faq-version-list";
import FaqVersionView from "../modules/FAQ's/faq-version-view";
import FaqView from "../modules/FAQ's/faq-view";
import ManufacturerCreate from "../modules/manufacturer/manufacturer-create/manufacturer-create";
import ManufacturerEdit from "../modules/manufacturer/manufacturer-edit";
import ManufacturerList from "../modules/manufacturer/manufacturer-list";
import ManufacturerVersionList from "../modules/manufacturer/manufacturer-version-list";
import SuperUserCreate from "../modules/super-admin/super-user-create";
import SuperUserEdit from "../modules/super-admin/super-user-edit";
import SuperUserList from "../modules/super-admin/super-user-list";
import SuperUserVersionList from "../modules/super-admin/super-user-version-history-list";
import SuperUserVersionView from "../modules/super-admin/super-user-version-view";
import SuperUserView from "../modules/super-admin/super-user-view";
import SuperUserRoleCreate from "../../src/modules/super-admin/superUserRole/super-user-role-create";
import SuperUserRoleList from "../../src/modules/super-admin/superUserRole/super-user-role-list";
import SuperUserRoleEdit from "../../src/modules/super-admin/superUserRole/super-user-role-edit";
import SuperUserRoleView from "../modules/super-admin/superUserRole/super-user-role-view";
import SuperUserRoleVersionList from "../../src/modules/super-admin/superUserRole/super-user-role-version-history-list";
import SuperUserRoleVersionView from "../../src/modules/super-admin/superUserRole/super-user-role-version-view";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./protectedRoute";
import PrivateRoute from "./privateRoute";
import ResetPasswordLink from "../modules/auth/forgot-reset-password/resetPasswordLink";
import ManufacturerDivisionCreate from "../modules/manufacturer/manufacturer-create/manufacturer-division-create";
import ManufacturerView from "../modules/manufacturer/manufacturer-view/manufacturer-view";
import ManufacturerDesignationCreate from "../modules/manufacturer/manufacturer-create/manufacturer-designation-create";
import ManufacturerHeadQuaterCreate from "../modules/manufacturer/manufacturer-create/manufacturer-headQuarter-create";
import InvoiceVerificationList from "../modules/invoice-verification/invoice-verification-list";
import InvoiceVerificationView from "../modules/invoice-verification/invoice-verification-view";
import Advertisement from "../modules/settings/advertisement/advertisement";
import TermsConditionView from "../modules/settings/terms&Condition/termsConditionView";
import TermsConditionEdit from "../modules/settings/terms&Condition/termsConditionEdit";
import PrivacyPolicyEdit from "../modules/settings/privacy-policy/privacyPolicyEdit";
import PrivacyPolicyView from "../modules/settings/privacy-policy/privacyPolicyView";
import ReasonRejectionList from "../modules/settings/reasons-for-rejection/reasonRejectionList";
import ReasonRejectionCreate from "../modules/settings/reasons-for-rejection/reasonRejectionCreate";
import ReasonRejectionEdit from "../modules/settings/reasons-for-rejection/reasonRejectionEdit";
import ReasonRejectionView from "../modules/settings/reasons-for-rejection/reasonRejectionView";
import Dashboard from "../modules/Dashboard/Dashboard";
import SettingsDistrict from "../modules/settings/district/district";
import SettingsState from "../modules/settings/state/settingsState";
import SettingsCity from "../modules/settings/city/settingsCity";
import SettingsPincode from "../modules/settings/pincode/settingsPincode";
import CustomerDoctorEdit from "../modules/customers/customer/customer-edit/customer-doctor-edit";
import Analytics from "../modules/analytics/Analytics";
import OnboardingDistributor from "../modules/distributor/Onboarding-distributor/onboarding-distributor";
import OnboardingDistributorView from "../modules/distributor/Onboarding-distributor/onboarding-distributor-details";
import OnboardingDistributorEdit from "../modules/distributor/Onboarding-distributor/onboarding-distributor-edit";
import CustomerVerificationList from "../modules/support/customer-verification/customerVerificationList";
import CustomerVerificationView from "../modules/support/customer-verification/customer-verification-view";
import DistributorCreate from "../modules/distributor/Onboarding-distributor/distributor-create";
import CustomerDistributorReqList from "../modules/customers/customerDistributorRequest/CustomerDistributorReqList";
import CustomerDistributorReqView from "../modules/customers/customerDistributorRequest/CustomerDistributorReqView";
import ManufacturerPremiumView from "../modules/manufacturer/premium-user/manufacturer-premium-view";
import ManufacturerPremiumEdit from "../modules/manufacturer/premium-user/manufacturer-premium-edit";
import ManufacturerPremiumVersionTable from "../modules/manufacturer/premium-user/manufacturer-premium-version-list";
import ManufacturerPremiumVersionView from "../modules/manufacturer/premium-user/manufacturer-premium-version-view";
import CreateNewPassword from "../modules/auth/login/CreateNewPassword";
import NewPasswordChanged from "../modules/auth/login/NewPasswordChanged";
import ChaptersList from "../modules/ff-training/ff-training-chapters/chapters-list";
import ChapterCreate from "../modules/ff-training/ff-training-chapters/chapter-create";
import ChaptersView from "../modules/ff-training/ff-training-chapters/chapter-view";
import ChapterEdit from "../modules/ff-training/ff-training-chapters/chapter-edit";
import TrainingQaList from "../modules/ff-training/ff-training-qa/training-qa-list";
import TrainingQaCreate from "../modules/ff-training/ff-training-qa/training-qa-create";
import TrainingQaEdit from "../modules/ff-training/ff-training-qa/training-qa-edit";
import TrainingQaView from "../modules/ff-training/ff-training-qa/training-qa-view";
import InvoiceVerifiedList from "../modules/invoice-verification/verified-invoice-list";
import VerifiedInvoiceView from "../modules/invoice-verification/verified-invoice-view";

const MainRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/create-new-password" element={<CreateNewPassword />} />
          <Route
            path="/new-password-created"
            element={<NewPasswordChanged />}
          />
          <Route
            path="/reset-password-link/:id"
            element={<ResetPasswordLink />}
          />
          <Route path="/password-changed" element={<PasswordChanged />} />
          <Route path="/password-email-sent" element={<PasswordEmailSent />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route
            path="/manufacturer/subscibe-list/:page"
            element={<ManufacturerList />}
          />
          <Route
            path="/manufacturer/premium-list/:page"
            element={<ManufacturerList />}
          />
          <Route
            path="/manufacturer/subscibe-list/create"
            element={<ManufacturerCreate />}
          />
          <Route
            path="/manufacturer/subscibe-list/view/:id"
            element={<ManufacturerView />}
          />
          <Route
            path="/manufacturer/premium-list/view/:id"
            element={<ManufacturerPremiumView />}
          />
          <Route
            path="/manufacturer/subscibe-list/edit/:id"
            element={<ManufacturerEdit />}
          />
          <Route
            path="/manufacturer/premium-list/edit/:id"
            element={<ManufacturerPremiumEdit />}
          />
          <Route
            path="/manufacturer/subscibe-list/version-list/:page"
            element={<ManufacturerVersionList />}
          />
          <Route
            path="/manufacturer/premium-list/version-list/:page"
            element={<ManufacturerPremiumVersionTable />}
          />
          <Route
            path="/manufacturer/subscibe-list/version-view/:id"
            element={<ManufacturerVersionView />}
          />
          <Route
            path="/manufacturer/premium-list/version-view/:id"
            element={<ManufacturerPremiumVersionView />}
          />
          <Route
            path="/manufacturer/subscibe-list/create/designation"
            element={<ManufacturerDesignationCreate />}
          />
          <Route
            path="/manufacturer/subscibe-list/create/division"
            element={<ManufacturerDivisionCreate />}
          />
          <Route
            path="/manufacturer/subscibe-list/create/head-quater"
            element={<ManufacturerHeadQuaterCreate />}
          />

          <Route
            path="/distributors/distributor/:page"
            element={<DistributorList />}
          />

          <Route
            path="/distributors/distributor/view/:id"
            element={<DistributorView />}
          />
          <Route
            path="/distributors/distributor/edit/:id"
            element={<DistributorEdit />}
          />
          <Route
            path="/distributors/distributor/version-list/:page"
            element={<DistributorVersionList />}
          />
          <Route
            path="/distributors/distributor/version-view/:id"
            element={<DistributorVersionView />}
          />
          <Route
            path="/distributors/onboard-distributor/:page"
            element={<OnboardingDistributor />}
          />
          <Route
            path="/distributors/onboard-distributor/create"
            element={<DistributorCreate />}
          />
          <Route
            path="/distributors/onboard-distributor/view/:id"
            element={<OnboardingDistributorView />}
          />
          <Route
            path="/distributors/onboard-distributor/edit/:id"
            element={<OnboardingDistributorEdit />}
          />

          <Route path="/customers/customer/:page" element={<CustomersList />} />
          <Route
            path="/customers/customer/view/:id"
            element={<CustomerView />}
          />
          <Route
            path="/customers/customer/edit/:id"
            element={<CustomerDoctorEdit />}
          />
          <Route
            path="/customers/customer/version-list/:page"
            element={<CustomerVersionList />}
          />
          <Route
            path="/customers/customer/version-view/:id"
            element={<CustomerVersionView />}
          />

          <Route
            path="/customers/request/:page"
            element={<CustomerRequestList />}
          />
          <Route path="/customers/request/view/:id" element={<RequestView />} />

          <Route
            path="/customers/distributor-request/:page"
            element={<CustomerDistributorReqList />}
          />
          <Route
            path="/customers/distributor-request/view/:id"
            element={<CustomerDistributorReqView />}
          />

          <Route path="/super-user/user/:page" element={<SuperUserList />} />
          <Route path="/super-user/user/create" element={<SuperUserCreate />} />
          <Route path="/super-user/user/edit/:id" element={<SuperUserEdit />} />
          <Route path="/super-user/user/view/:id" element={<SuperUserView />} />
          <Route
            path="/super-user/user/version-list/:page"
            element={<SuperUserVersionList />}
          />
          <Route
            path="/super-user/user/version-view/:id"
            element={<SuperUserVersionView />}
          />

          <Route
            path="/super-user/role/:page"
            element={<SuperUserRoleList />}
          />
          <Route
            path="/super-user/role/create"
            element={<SuperUserRoleCreate />}
          />
          <Route
            path="/super-user/role/edit/:id"
            element={<SuperUserRoleEdit />}
          />
          <Route
            path="/super-user/role/view/:id"
            element={<SuperUserRoleView />}
          />
          <Route
            path="/super-user/role/version-list/:page"
            element={<SuperUserRoleVersionList />}
          />
          <Route
            path="/super-user/role/version-view/:id"
            element={<SuperUserRoleVersionView />}
          />

          <Route path="/faq/:page" element={<FaqList />} />
          <Route path="/faq/create" element={<FaqCreate />} />
          <Route path="/faq/edit/:id" element={<FaqEdit />} />
          <Route path="/faq/view/:id" element={<FaqView />} />
          <Route path="/faq/version-list/:page" element={<FaqVersionList />} />
          <Route path="/faq/version-view/:id" element={<FaqVersionView />} />

          <Route path="/settings/advertisement" element={<Advertisement />} />

          <Route
            path="/settings/terms-condition/view"
            element={<TermsConditionView />}
          />
          <Route
            path="/settings/terms-condition/edit"
            element={<TermsConditionEdit />}
          />

          <Route
            path="/settings/privacy-policy/view"
            element={<PrivacyPolicyView />}
          />
          <Route
            path="/settings/privacy-policy/edit"
            element={<PrivacyPolicyEdit />}
          />

          <Route
            path="/settings/reason-for-rejection"
            element={<ReasonRejectionList />}
          />
          <Route
            path="/settings/reason-for-rejection/create"
            element={<ReasonRejectionCreate />}
          />
          <Route
            path="/settings/reason-for-rejection/edit"
            element={<ReasonRejectionEdit />}
          />
          <Route
            path="/settings/reason-for-rejection/view/:id"
            element={<ReasonRejectionView />}
          />
          <Route path="/settings/state" element={<SettingsState />} />
          <Route path="/settings/district" element={<SettingsDistrict />} />
          <Route path="/settings/city" element={<SettingsCity />} />
          <Route path="/settings/pincode" element={<SettingsPincode />} />

          <Route
            path="support/invoice-verification/:page"
            element={<InvoiceVerificationList />}
          />
          <Route
            path="support/invoice-verification/view/:id"
            element={<InvoiceVerificationView />}
          />
          <Route
            path="support/verified-invoices/:page"
            element={<InvoiceVerifiedList />}
          />
          <Route
            path="support/verified-invoices/view/:id"
            element={<VerifiedInvoiceView />}
          />
          <Route
            path="support/customer-verification/:page"
            element={<CustomerVerificationList />}
          />
          <Route
            path="support/customer-verification/view/:id"
            element={<CustomerVerificationView />}
          />
          <Route path="ff-training/chapters/:page" element={<ChaptersList />} />
          <Route path="ff-training/chapters/create" element={<ChapterCreate />} />
          <Route path="ff-training/chapters/view/:id" element={<ChaptersView />} />
          <Route path="ff-training/chapters/edit/:id" element={<ChapterEdit />} />

          <Route path="ff-training/qa/:page" element={<TrainingQaList />} />
          <Route path="ff-training/qa/create" element={<TrainingQaCreate />} />
          <Route path="ff-training/qa/edit/:id" element={<TrainingQaEdit />} />
          <Route path="ff-training/qa/view/:id" element={<TrainingQaView />} />

        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
};

export default MainRoute;
