import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import back from "../../../assets/icons/back.svg";
import "../superUser.css";
import { useDispatch } from "react-redux";
import { superAdminRoleVersionDetail } from "../../../redux/actions/superAdminActions";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const SuperUserRoleVersionView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [superVersionRoleViewData, setSuperVersionRoleViewData] = useState([]);
  const [superAdminRoleVersionViewOldData, setsuperAdminRoleVersionViewOldData] = useState([]);
  const [superAdminRoleVersionViewNewData, setSuperAdminRoleVersionViewNewData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(superAdminRoleVersionDetail(params.id, onSuccess, onError));
    setLoading(true)
  }, []);

  const onSuccess = (data) => {
    setLoading(false)
    console.log('cdfcbedfbv', data.data.data);
    setSuperVersionRoleViewData(data.data.data);
    setsuperAdminRoleVersionViewOldData(data.data.data.adminRoleVersion.old_data);
    setSuperAdminRoleVersionViewNewData(data.data.data.adminRoleVersion.new_data);
  };

  const onError = (err) => {
    setLoading(false)
    console.log(err);
  };
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
    nestedKeyLabel: {
      fontSize: "14px",
    },
    value: {
      fontSize: "14px",
    },
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (superVersionRoleViewData?.role_id
          ? ` / ${superVersionRoleViewData?.role_id} - view`
          : " / view")}
    </span>
  );

  const data = [
    {
      heading: "Role ID",
      title: superVersionRoleViewData?.role_id ? superVersionRoleViewData?.role_id : '--',
    },
    {
      heading: "Role Name",
      title: superVersionRoleViewData?.role_name ? superVersionRoleViewData?.role_name : '--',
    },
    {
      heading: "Updated By",
      title: superVersionRoleViewData?.adminRoleVersion?.updatedBy?.name
        ? superVersionRoleViewData?.adminRoleVersion?.updatedBy?.name
        : '--',
    },
    {
      heading: "Updated At",
      title: superVersionRoleViewData?.adminRoleVersion?.updatedAt ?
        moment(superVersionRoleViewData?.adminRoleVersion?.updatedAt).format(
          "DD MMM YYYY HH:mm A"
        ) : '--',
    },
  ];

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
      </div>
      {
        loading == true ?
          <div className="view-spinner-container">
            <LoadingSpinnerTable
              show={loading}
            />
          </div> :
          <>
            <div className="pt-3 px-4 row">
              {data.map((item, index) => (
                <div className="mb-2 col-4 col-md-3 col-xl-2 col-xxl label-conatiner mx-3 ">
                  <h5 className="color_b0b3c7 text-nowrap fs_15 fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p className={` fs_16 fn_Nunito fw_600 `}>
                    {item.heading === "Role ID" ? (
                      <p
                        className={` fs_16 fn_Nunito col-12 division-container fw_500 `}
                      >
                        {item.title}
                      </p>
                    ) : item.heading === "Role Name" ? (
                      <p
                        className={` fs_16 fn_Nunito col-12 division-container fw_500 `}
                      >
                        {item.title}
                      </p>
                    ) : item.heading === "Updated By" ? (
                      <p
                        className={`fs_15 fn_Nunito fw_500 color_262c3d  col-lg-10 email-slice `}
                        title={item.title}
                      >
                        {item.title}
                      </p>
                    ) : (
                      <p className={` fs_16 fn_Nunito text-nowrap fw_500`}>
                        {item.title}
                      </p>
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className=" mx-3 d-none d-xl-block form-sub-details my-3 mb-4">
              <div className="d-flex justify-content-between">
                <div className="col-5 mx-4 ">JSON Old Data</div>
                <div className="col-6">
                  <span className="ms-4 ps-2">JSON New Data</span>
                </div>
              </div>
            </div>
            <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-5  mt-xl-0">
              <div
                className="col-10 col-xl-6 my-2 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                style={{ minHeight: "30rem" }}
              >
                <div>
                  <h6
                    className="px-3 py-3 my-auto"
                    style={{
                      backgroundColor: "#E7E5FF",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Old Data
                  </h6>
                  <div className="">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <>
                        {superAdminRoleVersionViewOldData == null ? (
                          <>Empty data</>
                        ) : (
                          <>
                            <JSONTree
                              theme={theme}
                              data={superAdminRoleVersionViewOldData}
                            />
                            ;
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-10 col-xl-6 my-2 mx-xl-3 mx-auto"
                style={{ minHeight: "30rem" }}
              >
                <div>
                  <h6
                    className="px-3 py-3 my-auto"
                    style={{
                      backgroundColor: "#E7E5FF",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    New Data
                  </h6>
                  <div className="">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <>
                        {superAdminRoleVersionViewNewData === null ? (
                          <>Empty data</>
                        ) : (
                          <>
                            <JSONTree
                              theme={theme}
                              data={superAdminRoleVersionViewNewData}
                            />
                            ;
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

    </MainLayout>
  );
};

export default SuperUserRoleVersionView;
