import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { gevHeadQuaterView } from "../../../../redux/actions/godsEyeViewActions";

const HeadQuarterView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [HeadquarterData, setHeadquarterData] = useState([]);
  const vendorId = params?.id.split("&")?.[0];
  const hq_id = params?.id.split("&")?.[3];

  useEffect(() => {
    dispatch(
      gevHeadQuaterView(
        { vendor_id: vendorId, head_quater_id: hq_id },
        onSuccess,
        onError
      )
    );
  }, []);

  const onSuccess = (data) => {
    console.log(data?.data?.data, "ssss");
    setHeadquarterData(data?.data?.data);
  };
  const onError = (err) => {};
  return (
    <>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">
          Head Quarters - {HeadquarterData?.code}
        </label>
      </div>
      <div className="col-6">
        <div className="mx-4 px-3 py-2 pt-4">
          <div className="my-2">
            <label className="color_747f9e fs_16">Head Quarters Name</label>
            <p className="fs_16 color_262c3d">
              {HeadquarterData?.name ? HeadquarterData?.name : "--"}
            </p>
          </div>
          <div className="my-2">
            <label className="color_747f9e fs_16">State</label>
            <p className="fs_16 color_262c3d">
              {HeadquarterData?.state?.name
                ? HeadquarterData?.state?.name
                : "--"}
            </p>
          </div>
          <div className="my-2">
            <label className="color_747f9e fs_16">District</label>
            <p className="fs_16 color_262c3d">
              {HeadquarterData?.headQuaterDistricts?.length > 0 &&
                HeadquarterData?.headQuaterDistricts?.map((ele, index) => {
                  return (
                    <span className="slice-word" key={index}>
                      <span style={{ color: "#B0B3C7" }}>
                        {index ? " | " : ""}
                      </span>
                      {ele?.district?.name}
                    </span>
                  );
                })}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadQuarterView;
