import {
  CUSTOMER_VERIFY_DETAIL,
  CUSTOMER_VERIFY_LIST,
  VERIFY_REPORT_MODAL,
} from "./types";

export const customerVerifyList = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VERIFY_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const customerVerifyDetail = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VERIFY_DETAIL,
    data,
    onSuccess,
    onError,
  };
};
export const verifyReportModal = (data, onSuccess, onError) => {
  return {
    type: VERIFY_REPORT_MODAL,
    data,
    onSuccess,
    onError,
  };
};
