import React, { useEffect, useState } from "react";
import back from "../../../assets/icons/back.svg";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCityList,
  fetchDistrictList,
  fetchStateList,
} from "../../../redux/actions/commonActions";
import ErrorMsg from "../../../components/form/errorMsg";
import Select from "react-select";
import StateListTable from "../../../components/tables/stateListTable";
import CityListTable from "../../../components/tables/cityListTable";
import {
  settingPincodeFilter,
  settingsCityCreate,
  settingsCityEdit,
  settingsCityList,
  settingsPincodeCreate,
  settingsPincodeEdit,
  settingsPincodeList,
} from "../../../redux/actions/settingsActions";
import PincodeListTable from "../../../components/tables/pincodeListTable";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import searcher from "../../../assets/icons/searchInput.svg";

const SettingsPincode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [disable, setDisable] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const [pincodeListData, setPincodeListData] = useState([]);
  // const [stateId, setStateId] = useState("");
  // const [districtId, setDistrictId] = useState("");
  // const [cityId, setCityId] = useState("");
  const [pincodeId, setPincodeId] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
    fetchPincodeList();
  }, []);

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
    if (selectedState !== null && selectedDistrict !== null) {
      fetchCityList(
        { state_id: selectedState, district_id: selectedDistrict },
        onGetCitySuccess,
        onGetCityError
      );
    }
  }, []);
  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState !== null && selectedDistrict !== null) {
      dispatch(
        fetchCityList(
          { state_id: selectedState, district_id: selectedDistrict },
          onGetCitySuccess,
          onGetCityError
        )
      );
    }
  }, [selectedState, selectedDistrict]);
  console.log(selectedDistrict);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };

  const onGetStateError = (err) => {
    console.log(err);
  };

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log(data);
  };

  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const onGetCitySuccess = (data) => {
    setCityOptions(data);
    console.log(data);
  };

  const onGetCityError = (err) => {
    console.log(err);
  };

  const fetchPincodeList = () => {
    setLoading(true);
    dispatch(
      settingPincodeFilter(
        {
          page,
          pincode: search,
          state_id: selectedState,
          district_id: selectedDistrict,
          city_id: selectedCity,
        },
        onListSuccess,
        onListError
      )
    );
  };

  useEffect(() => {
    fetchPincodeList();
  }, [page, search]);

  useEffect(() => {
    setPage(1);
    fetchPincodeList();
  }, [selectedState, selectedDistrict, selectedCity, search]);

  const onListSuccess = (data) => {
    console.log(data?.data);
    setPincodeListData(data?.data?.data);
  };

  const onListError = (err) => {
    console.log(err);
  };

  const initialValues = {
    pincode: "",
    state_id: "",
    district_id: "",
    city_id: "",
  };
  const validationSchema = Yup.object().shape({
    pincode: Yup.string().trim().required("Required"),
    state_id: Yup.string().trim().required("Required"),
    district_id: Yup.string().trim().required("Required"),
    city_id: Yup.string().trim().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // console.log(values);
    if (!editEnable) {
      dispatch(settingsPincodeCreate(values, onSuccess, onError));
      console.log(values);
    } else {
      console.log(values);
      dispatch(
        settingsPincodeEdit(
          {
            pincode_id: pincodeId,
            city_id: values?.city_id,
            district_id: values?.district_id,
            state_id: values?.state_id,
            pincode: values?.pincode,
          },
          onEditSuccess,
          onEditError
        )
      );
    }

    resetForm({
      values: {
        pincode: "",
        state_id: "",
        district_id: "",
      },
    });
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    fetchPincodeList();
  };

  const onSuccess = (data) => {
    console.log(data);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setShowSuccessPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const onEditSuccess = (data) => {
    console.log(data);
    fetchPincodeList();
    setEditEnable(false);
  };

  const onEditError = (err) => {
    console.log(err);
  };

  const editSetForm = (item, index, setFieldValue) => {
    console.log(item);
    setEditEnable(true);
    // setEditIndex(index);
    setFieldValue("pincode", item.pincode);
    setFieldValue("state_id", item.state_id);
    setFieldValue("district_id", item.district_id);
    setFieldValue("city_id", item.city_id);
    setSelectedState(item?.state_id);
    setSelectedDistrict(item?.district_id);
    setSelectedCity(item?.city_id);
    setPincodeId(item?.id);
  };

  const FormEnableCheck = () => {
    const {
      values: { pincode, state_id, district_id, city_id },
    } = useFormikContext();
    useEffect(() => {
      if (
        pincode.trim() != "" &&
        state_id.trim() !== "" &&
        district_id.trim() !== "" &&
        city_id.trim() !== ""
      ) {
        setDisable(false);
      } else setDisable(true);
    }, [state_id, district_id, city_id, pincode]);
    return null;
  };

  // useEffect(() => {
  //   console.log("selectedState", selectedState);
  //   dispatch(
  //     settingPincodeFilter(
  //       {
  //         state_id: selectedState,
  //         district_id: selectedDistrict,
  //         city_id: selectedCity,
  //       },
  //       onListSuccess,
  //       onListError
  //     )
  //   );
  // }, [selectedState, selectedDistrict, selectedCity]);

  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  pincode">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            {/* <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
            </div> */}
            {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
              <>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Pincode</label>
                </div>
                <div className="d-lg-flex">
                  <div className="ms-4  col-lg-5 px-3 px-lg-2 py-1">
                    <div className="form-two-width">
                      <div className="select-container mt-4 mb-2">
                        <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                          State
                        </label>
                        <Field name={"state_id"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={stateOptions}
                              styles={customStyles}
                              placeholder={"Enter your state"}
                              value={stateOptions.filter(
                                (s) => s.value == values.state_id
                              )}
                              onChange={(selected) => {
                                setSelectedState(selected?.value);
                                setFieldValue("state_id", selected?.value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="state_id" />
                      </div>
                      <div className="select-container mt-4 mb-2">
                        <label className="w-100 mx-1 fw_400 fn_Nunito fs_14">
                          City
                        </label>
                        <Field name={"city_id"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={cityOptions}
                              styles={customStyles}
                              placeholder={"Enter your city"}
                              value={cityOptions.filter(
                                (s) => s?.value == values?.city_id
                              )}
                              onChange={(selected) => {
                                setSelectedCity(selected.value);
                                setFieldValue("city_id", selected?.value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="city_id" />
                      </div>
                    </div>
                  </div>
                  <div className="ms-4  col-lg-5 px-3 px-lg-2 py-1">
                    <div className="form-two-width ">
                      <div className="w-100  mt-4 mb-2">
                        <label className="w-100 mx-1 fn_Nunito fs_14 color_262c3d">
                          District
                        </label>
                        <Field name={"state_id"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={districtOptions}
                              styles={customStyles}
                              placeholder={"Enter your district"}
                              value={districtOptions.filter(
                                (s) => s.value == values.district_id
                              )}
                              onChange={(selected) => {
                                setSelectedDistrict(selected?.value);
                                setFieldValue("district_id", selected?.value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="state_id" />
                      </div>
                      <div className="my-2  mt-4 mb-2">
                        <InputField
                          id={"pincode"}
                          label={"Pincode"}
                          labelColor={"color_262c3d"}
                          type={"text"}
                          placeholder={"Enter New Pincode"}
                          name={"pincode"}
                          value={values.pincode}
                        />
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-end form-two-width pe-2 pe-lg-0"
                      //   style={{ maxWidth: "400px" }}
                    >
                      {!editEnable ? (
                        <button
                          type="submit"
                          className="add-button fs_18 fw_700 fs_sm_12 px-4 py-2 w-50"
                          style={{ maxWidth: "210px" }}
                          disabled={disable}
                        >
                          Create
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                          style={{ maxWidth: "210px" }}
                          // disabled={disable}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <>
              <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                <label className="basic-title mx-2 mb-1">Pincode List</label>
              </div>
              <div className="mt-4 inside-table-container mx-4">
                <div className=" my-2 search-container ">
                  <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                    <img className="styled-SVG" src={searcher} color={""} />
                    <input
                      className="search-input fs_14 fw_400 px-2 text-dark fs_18"
                      type="text"
                      placeholder="Search pincode here by pincode..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className=" px-1">
                  <PincodeListTable
                    pincodeListData={pincodeListData}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                    setPage={setPage}
                    page={page}
                  />
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </MainLayout>
  );
};

export default SettingsPincode;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
