import React, { useEffect, useState } from "react";
import userLogo from "../../../assets/icons/sidebar/userProfile.svg";
import logoutLogo from "../../../assets/icons/sidebar/signOut.svg";
import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardSVG from "../../../assets/icons/sidebar/dashboardSVG";
import ManufacturerSvg from "../../../assets/icons/sidebar/manufacturerSvg";
import AnalyticsSvg from "../../../assets/icons/sidebar/AnalyticsSvg";
import DistributorSvg from "../../../assets/icons/sidebar/distributorSvg";
import ArrowUp from "../../../assets/icons/sidebar/upArrow.svg";
import ArrowDown from "../../../assets/icons/sidebar/downArrow.svg";
import CustomersSvg from "../../../assets/icons/sidebar/customersSvg";
import SuperUserSvg from "../../../assets/icons/sidebar/superUserSvg";
import FaqSvg from "../../../assets/icons/sidebar/FaqSvg";
import LogoutModal from "../modals/logoutModal";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import InvoiceVerifySvg from "../../../assets/icons/sidebar/invoiceVerifySvg";
import SettingsSvg from "../../../assets/icons/sidebar/settingsSvg";
import SuperAdminView from "./superAdminView";

import SupportSvg from "../../../assets/icons/sidebar/supportSvg";
import arrRig from "../../../assets/icons/sidebar/arrow-right-line.svg";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopup from "../../popups/SuccessPopup";
import FfTrainingSvg from "../../../assets/icons/sidebar/ffTraining";

const SidebarComponent = ({ open, setOpen, userPermissionList }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [customer, setCustomer] = useState(true);
  const [superUser, setSuperUser] = useState(true);
  const [distributorOpen, setDistributorOpen] = useState(true);
  const [manufacturerOpen, setManufacturerOpen] = useState(true);
  const [settingsOpen, setSettingsOpen] = useState(true);
  const [supportOpen, setSupportOpen] = useState(true);
  const [ffTrainingOpen, setFfTrainingOpen] = useState(true);
  const [logoutShow, setLogoutShow] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const logoutHandler = () => {
    dispatch(logout(logoutSuccess, logoutError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/");
  };

  const logoutSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const logoutError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const userType = localStorage.getItem("userType");

  const permissions = localStorage.getItem("permissions");
  const superUserpermissions = JSON.parse(permissions);

  const location = useLocation();
  const url = location.pathname;
  const pathArray = url.split("/");
  const activePath = "/" + pathArray[1];
  const activeSubPath = "/" + pathArray[2];

  return (
    <div>
      <div className="sidebar-col">
        <div className="nav-container p-3 d-flex flex-column justify-content-between mx-0">
          <ul className="nav-options">
            <Link
              to={"/dashboard"}
              className="text-decoration-none"
              onClick={() => {
                setDistributorOpen(false);
                setCustomer(false);
                setSettingsOpen(false);
                setSupportOpen(false);
                setFfTrainingOpen(false);
              }}
            >
              <li
                className={`nav-items d-flex justify-content-start align-items-center p-2 mt-2 
              ${activePath === "/dashboard" && "nav-items-active"}`}
              >
                {activePath == "/dashboard" ? (
                  <DashboardSVG fill="#7065E4" />
                ) : (
                  <DashboardSVG fill="#B0B3C7" />
                )}

                <span className="nav-text fs_14 fn_Nunito fw_600">
                  Dashboard
                </span>
              </li>
            </Link>

            {userType == "super_admin" ? (
              ""
            ) : (
              <Link
                to={"/analytics"}
                className="text-decoration-none"
                onClick={() => {
                  setDistributorOpen(false);
                  setCustomer(false);
                  setSettingsOpen(false);
                  setSupportOpen(false);
                  setFfTrainingOpen(false);
                }}
              >
                <li
                  className={`nav-items d-flex justify-content-start align-items-center p-2 mt-2 
              ${activePath === "/analytics" && "nav-items-active"}`}
                >
                  {activePath == "/analytics" ? (
                    <AnalyticsSvg fill="#7065E4" />
                  ) : (
                    <AnalyticsSvg fill="#B0B3C7" />
                  )}

                  <span className="nav-text fs_14 fn_Nunito fw_600">
                    analytics
                  </span>
                </li>
              </Link>
            )}

            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Manufacturer") ? (
                <li
                  className={`nav-items mt-2  px-2 p-1 ${activePath == "/manufacturer" && "nav-items-active"
                    } ${manufacturerOpen && "nav-items-dd"}`}
                >
                  <div
                    onClick={() => {
                      navigate("/manufacturer/subscibe-list/1");
                      setCustomer(false);
                      setSettingsOpen(false);
                      setSupportOpen(false);
                      setFfTrainingOpen(false);
                    }}
                    className="d-flex justify-content-between align-items-centers "
                  >
                    <label
                      className="w-100"
                      onClick={() => {
                        navigate("/manufacturer/subscibe-list/1");
                        setManufacturerOpen(true);
                      }}
                    >
                      {activePath == "/manufacturer" ? (
                        <ManufacturerSvg fill="#7065E4" />
                      ) : (
                        <ManufacturerSvg fill="#B0B3C7" />
                      )}

                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        Manufacturers
                      </span>
                    </label>
                    <span
                      onClick={() => setManufacturerOpen(!manufacturerOpen)}
                    >
                      {manufacturerOpen && activePath == "/manufacturer" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {manufacturerOpen && activePath == "/manufacturer" && (
                    <ul className="nav-options ms-3">
                      <Link
                        to={"/manufacturer/subscibe-list/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`mt-2 mb-1 ${activeSubPath == "/subscibe-list" && "color_2ad3e7"
                            }`}
                        >
                          <label
                            title="Subscribed manufacturers"
                            className="nav-text fn_Nunito fs_14 fw_600 text-nowrap text-truncate cursor_pointer"
                            style={{ maxWidth: "200px" }}
                          >
                            subscribed manufacturers
                          </label>
                        </li>
                      </Link>
                      <Link
                        to={"/manufacturer/premium-list/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={` mb-2 ${activeSubPath == "/premium-list" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            premium manufacturers
                          </span>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 p-1 ${activePath == "/manufacturer" && "nav-items-active"
                  } ${manufacturerOpen && "nav-items-dd"}`}
              >
                <div
                  onClick={() => {
                    navigate("/manufacturer/subscibe-list/1");
                    setCustomer(false);
                    setSettingsOpen(false);
                    setSupportOpen(false);
                    setFfTrainingOpen(false);
                  }}
                  className="d-flex justify-content-between align-items-centers "
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/manufacturer/subscibe-list/1");
                      setManufacturerOpen(true);
                    }}
                  >
                    {activePath == "/manufacturer" ? (
                      <ManufacturerSvg fill="#7065E4" />
                    ) : (
                      <ManufacturerSvg fill="#B0B3C7" />
                    )}

                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      Manufacturers
                    </span>
                  </label>
                  <span onClick={() => setManufacturerOpen(!manufacturerOpen)}>
                    {manufacturerOpen && activePath == "/manufacturer" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {manufacturerOpen && activePath == "/manufacturer" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/manufacturer/subscibe-list/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`mt-2 mb-1 ${activeSubPath == "/subscibe-list" && "color_2ad3e7"
                          }`}
                      >
                        <label
                          title="Subscribed manufacturers"
                          className="nav-text fn_Nunito fs_14 fw_600 text-nowrap text-truncate cursor_pointer"
                          style={{ maxWidth: "200px" }}
                        >
                          subscribed manufacturers
                        </label>
                      </li>
                    </Link>
                    <Link
                      to={"/manufacturer/premium-list/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={` mb-2 ${activeSubPath == "/premium-list" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          premium manufacturers
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Distributor") ? (
                <li
                  className={`nav-items mt-2  px-2 p-1 ${activePath == "/distributors" && "nav-items-active"
                    } ${distributorOpen && "nav-items-dd"}`}
                >
                  <div
                    onClick={() => {
                      navigate("/distributors/distributor/1");
                      setCustomer(false);
                      setSettingsOpen(false);
                      setSupportOpen(false);
                      setFfTrainingOpen(false);
                    }}
                    className="d-flex justify-content-between align-items-centers "
                  >
                    <label
                      className="w-100"
                      onClick={() => {
                        navigate("/distributors/distributor/1");
                        setDistributorOpen(true);
                      }}
                    >
                      {activePath == "/distributors" ? (
                        <DistributorSvg fill="#7065E4" />
                      ) : (
                        <DistributorSvg fill="#B0B3C7" />
                      )}

                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        distributors
                      </span>
                    </label>
                    <span onClick={() => setDistributorOpen(!distributorOpen)}>
                      {distributorOpen && activePath == "/distributors" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {distributorOpen && activePath == "/distributors" && (
                    <ul className="nav-options ms-3">
                      <div
                        onClick={() => navigate("/distributors/distributor/1")}
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/distributor" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            ONBOARDED distributors
                          </span>
                        </li>
                      </div>
                      <Link
                        to={"distributors/onboard-distributor/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/onboard-distributor" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            ONBOARDING distributors
                          </span>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 p-1 ${activePath == "/distributors" && "nav-items-active"
                  } ${distributorOpen && "nav-items-dd"}`}
              >
                <div
                  onClick={() => {
                    navigate("/distributors/distributor/1");
                    setCustomer(false);
                    setSettingsOpen(false);
                    setSupportOpen(false);
                    setFfTrainingOpen(false);
                  }}
                  className="d-flex justify-content-between align-items-centers "
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/distributors/distributor/1");
                      setDistributorOpen(true);
                    }}
                  >
                    {activePath == "/distributors" ? (
                      <DistributorSvg fill="#7065E4" />
                    ) : (
                      <DistributorSvg fill="#B0B3C7" />
                    )}

                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      distributors
                    </span>
                  </label>
                  <span onClick={() => setDistributorOpen(!distributorOpen)}>
                    {distributorOpen && activePath == "/distributors" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {distributorOpen && activePath == "/distributors" && (
                  <ul className="nav-options ms-3">
                    <div
                      onClick={() => navigate("/distributors/distributor/1")}
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/distributor" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          ONBOARDED distributors
                        </span>
                      </li>
                    </div>
                    <Link
                      to={"distributors/onboard-distributor/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/onboard-distributor" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          ONBOARDING distributors
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

            {/* CUSTOMER */}
            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Customer") ? (
                <li
                  className={`nav-items mt-2  px-2 py-1 ${activePath == "/customers" && "nav-items-active"
                    } ${customer && "nav-items-dd"}`}
                >
                  <div
                    className="d-flex justify-content-between align-items-centers"
                    onClick={() => {
                      {
                        navigate("/customers/customer/1");
                        setDistributorOpen(false);
                      }
                    }}
                  >
                    <label
                      className="w-100"
                      onClick={() => {
                        navigate("/customers/customer/1");
                        setCustomer(true);
                      }}
                    >
                      {activePath == "/customers" ? (
                        <CustomersSvg fill="#7065E4" />
                      ) : (
                        <CustomersSvg fill="#B0B3C7" />
                      )}
                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        CUSTOMERS
                      </span>
                    </label>
                    <span onClick={() => setCustomer(!customer)}>
                      {customer && activePath == "/customers" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {customer && activePath == "/customers" && (
                    <ul className="nav-options ms-3">
                      <Link
                        to={"/customers/customer/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/customer" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Onboarded Customers
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/customers/request/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/request" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Onboarding Requests
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/customers/distributor-request/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/distributor-request" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <label
                            className="nav-text fn_Nunito fs_14 cursor_pointer fw_600 text-truncate"
                            title="Change Distributor Requests"
                            style={{ maxWidth: "183px" }}
                          >
                            Change Distributor Requests
                          </label>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 py-1 ${activePath == "/customers" && "nav-items-active"
                  } ${customer && "nav-items-dd"}`}
              >
                <div
                  className="d-flex justify-content-between align-items-centers"
                  onClick={() => {
                    {
                      navigate("/customers/customer/1");
                      setDistributorOpen(false);
                    }
                  }}
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/customers/customer/1");
                      setCustomer(true);
                    }}
                  >
                    {activePath == "/customers" ? (
                      <CustomersSvg fill="#7065E4" />
                    ) : (
                      <CustomersSvg fill="#B0B3C7" />
                    )}
                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      CUSTOMERS
                    </span>
                  </label>
                  <span onClick={() => setCustomer(!customer)}>
                    {customer && activePath == "/customers" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {customer && activePath == "/customers" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/customers/customer/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/customer" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Onboarded Customers
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/customers/request/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/request" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Onboarding Requests
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/customers/distributor-request/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/distributor-request" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <label
                          className="nav-text fn_Nunito fs_14 cursor_pointer fw_600 text-truncate"
                          title="Change Distributor Requests"
                          style={{ maxWidth: "183px" }}
                        >
                          Change Distributor Requests
                        </label>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

            {/* SUPERUSER */}
            {userType == "super_admin" ? (
              ""
            ) : (
              <li
                className={`nav-items mt-2  px-2 py-1 ${activePath == "/super-user" && "nav-items-active"
                  } ${superUser && "nav-items-dd"}`}
              >
                <div
                  className="d-flex justify-content-between align-items-centers"
                  onClick={() => {
                    {
                      navigate("/super-user/user/1");
                      setCustomer(false);
                      setDistributorOpen(false);
                    }
                  }}
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/super-user/user/1");
                      setSuperUser(true);
                    }}
                  >
                    {activePath == "/super-user" ? (
                      <SuperUserSvg fill="#7065E4" />
                    ) : (
                      <SuperUserSvg fill="#B0B3C7" />
                    )}
                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      SUPER USERS
                    </span>
                  </label>
                  <span onClick={() => setSuperUser(!superUser)}>
                    {superUser && activePath == "/super-user" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {superUser && activePath == "/super-user" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/super-user/user/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/user" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          USERS
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/super-user/role/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/role" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          ROLES
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

            {userType == "super_admin" ? (
              ""
            ) : (
              <Link
                to={"/faq/1"}
                className="text-decoration-none"
                onClick={() => {
                  setDistributorOpen(false);
                  setCustomer(false);
                  setSettingsOpen(false);
                  setSupportOpen(false);
                  setFfTrainingOpen(false);
                }}
              >
                <li
                  className={`nav-items d-flex justify-content-start align-items-center p-2 mt-2 
              ${activePath == "/faq" && "nav-items-active"}`}
                  onClick={() => {
                    navigate("/faq/1");
                  }}
                >
                  {activePath == "/faq" ? (
                    <FaqSvg fill="#7065E4" />
                  ) : (
                    <FaqSvg fill="#B0B3C7" />
                  )}

                  <span className="nav-text fs_14 fn_Nunito fw_600">FAQ’s</span>
                </li>
              </Link>
            )}

            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Settings") ? (
                <li
                  className={`nav-items mt-2  px-2 py-1 ${activePath == "/settings" && "nav-items-active"
                    } ${settingsOpen && "nav-items-dd"}`}
                >
                  <div
                    onClick={() => {
                      {
                        navigate("/settings/state");
                        setDistributorOpen(false);
                        setCustomer(false);
                        setSupportOpen(false);
                        setFfTrainingOpen(false);
                      }
                    }}
                    className="d-flex justify-content-between align-items-centers"
                  >
                    <label
                      className="w-100"
                      onClick={() => setSettingsOpen(true)}
                    >
                      {activePath == "/settings" ? (
                        <SettingsSvg fill="#7065E4" />
                      ) : (
                        <SettingsSvg fill="#B0B3C7" />
                      )}
                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        Settings
                      </span>
                    </label>
                    <span onClick={() => setSettingsOpen(!settingsOpen)}>
                      {settingsOpen && activePath == "/settings" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {settingsOpen && activePath == "/settings" && (
                    <ul className="nav-options ms-3">
                      <Link
                        to={"/settings/state"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/state" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            State
                          </span>
                        </li>
                      </Link>

                      <Link
                        to={"/settings/district"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/district" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            District
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/city"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/city" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            City
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/pincode"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/pincode" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Pincode
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/reason-for-rejection"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/reason-for-rejection" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Reasons For Rejection
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/privacy-policy/view"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/privacy-policy" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Privacy Policy
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/terms-condition/view"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/terms-condition" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Terms & Condition
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/settings/advertisement"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/advertisement" && "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Advertisement
                          </span>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 py-1 ${activePath == "/settings" && "nav-items-active"
                  } ${settingsOpen && "nav-items-dd"}`}
              >
                <div
                  onClick={() => {
                    {
                      navigate("/settings/state");
                      setDistributorOpen(false);
                      setCustomer(false);
                      setSupportOpen(false);
                      setFfTrainingOpen(false);
                    }
                  }}
                  className="d-flex justify-content-between align-items-centers"
                >
                  <label
                    className="w-100"
                    onClick={() => setSettingsOpen(true)}
                  >
                    {activePath == "/settings" ? (
                      <SettingsSvg fill="#7065E4" />
                    ) : (
                      <SettingsSvg fill="#B0B3C7" />
                    )}
                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      Settings
                    </span>
                  </label>
                  <span onClick={() => setSettingsOpen(!settingsOpen)}>
                    {settingsOpen && activePath == "/settings" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {settingsOpen && activePath == "/settings" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/settings/state"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/state" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          State
                        </span>
                      </li>
                    </Link>

                    <Link
                      to={"/settings/district"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/district" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          District
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/city"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/city" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          City
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/pincode"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/pincode" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Pincode
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/reason-for-rejection"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/reason-for-rejection" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Reasons For Rejection
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/privacy-policy/view"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/privacy-policy" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Privacy Policy
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/terms-condition/view"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/terms-condition" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Terms & Condition
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/settings/advertisement"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/advertisement" && "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Advertisement
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}
            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Support") ? (
                <li
                  className={`nav-items mt-2  px-2 py-1 ${activePath == "/support" && "nav-items-active"
                    } ${supportOpen && "nav-items-dd"}`}
                >
                  <div
                    className="d-flex justify-content-between align-items-centers"
                    onClick={() => {
                      {
                        navigate("/support/invoice-verification/1");
                        setDistributorOpen(false);
                        setCustomer(false);
                        setSettingsOpen(false);
                      }
                    }}
                  >
                    <label
                      className="w-100"
                      onClick={() => {
                        navigate("/support/invoice-verification/1");
                        setSupportOpen(true);
                      }}
                    >
                      {activePath == "/support" ? (
                        <SupportSvg fill="#7065E4" />
                      ) : (
                        <SupportSvg fill="#B0B3C7" />
                      )}
                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        support
                      </span>
                    </label>
                    <span onClick={() => setSupportOpen(!supportOpen)}>
                      {supportOpen && activePath == "/support" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {supportOpen && activePath == "/support" && (
                    <ul className="nav-options ms-3">
                      <Link
                        to={"/support/invoice-verification/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/invoice-verification" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            invoice verification
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"/support/verified-invoices/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/verified-invoices" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Verified Invoices
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"support/customer-verification/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/customer-verification" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Customer Verification
                          </span>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 py-1 ${activePath == "/support" && "nav-items-active"
                  } ${supportOpen && "nav-items-dd"}`}
              >
                <div
                  className="d-flex justify-content-between align-items-centers"
                  onClick={() => {
                    {
                      navigate("/support/invoice-verification/1");
                      setDistributorOpen(false);
                      setCustomer(false);
                      setSettingsOpen(false);
                    }
                  }}
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/support/invoice-verification/1");
                      setSupportOpen(true);
                    }}
                  >
                    {activePath == "/support" ? (
                      <SupportSvg fill="#7065E4" />
                    ) : (
                      <SupportSvg fill="#B0B3C7" />
                    )}
                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      support
                    </span>
                  </label>
                  <span onClick={() => setSupportOpen(!supportOpen)}>
                    {supportOpen && activePath == "/support" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {supportOpen && activePath == "/support" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/support/invoice-verification/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/invoice-verification" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          invoice verification
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"/support/verified-invoices/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/verified-invoices" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Verified Invoices
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"support/customer-verification/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/customer-verification" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Customer Verification
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

            {userType == "super_admin" ? (
              userPermissionList?.length > 0 &&
                userPermissionList.includes("Ff Training") ? (
                <li
                  className={`nav-items mt-2  px-2 py-1 ${activePath == "/ff-training" && "nav-items-active"
                    } ${ffTrainingOpen && "nav-items-dd"}`}
                >
                  <div
                    className="d-flex justify-content-between align-items-centers"
                    onClick={() => {
                      {
                        navigate("/ff-training/chapters/1");
                        setDistributorOpen(false);
                        setCustomer(false);
                        setSettingsOpen(false);
                        setSupportOpen(false)
                      }
                    }}
                  >
                    <label
                      className="w-100"
                      onClick={() => {
                        navigate("/ff-training/chapters/1");
                        setFfTrainingOpen(true);
                      }}
                    >
                      {activePath == "/ff-training" ? (
                        <SupportSvg fill="#7065E4" />
                      ) : (
                        <SupportSvg fill="#B0B3C7" />
                      )}
                      <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                        Field Force Training
                      </span>
                    </label>
                    <span onClick={() => setFfTrainingOpen(!ffTrainingOpen)}>
                      {ffTrainingOpen && activePath == "/ff-training" ? (
                        <img src={ArrowUp} className="me-2" />
                      ) : (
                        <img src={ArrowDown} className="me-2" />
                      )}
                    </span>
                  </div>
                  {ffTrainingOpen && activePath == "/ff-training" && (
                    <ul className="nav-options ms-3">
                      <Link
                        to={"/ff-training/chapters/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/chapters" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Field Force Chapters
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={"ff-training/qa/1"}
                        className="text-decoration-none Medium_Purple"
                      >
                        <li
                          className={`my-2  ${activeSubPath == "/qa" &&
                            "color_2ad3e7"
                            }`}
                        >
                          <span className="nav-text fn_Nunito fs_14 fw_600">
                            Field Force Q/A
                          </span>
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              ) : (
                ""
              )
            ) : (
              <li
                className={`nav-items mt-2  px-2 py-1 ${activePath == "/ff-training" && "nav-items-active"
                  } ${ffTrainingOpen && "nav-items-dd"}`}
              >
                <div
                  className="d-flex justify-content-between align-items-centers"
                  onClick={() => {
                    {
                      navigate("/ff-training/chapters/1");
                      setDistributorOpen(false);
                      setCustomer(false);
                      setSettingsOpen(false);
                      setSupportOpen(false)
                    }
                  }}
                >
                  <label
                    className="w-100"
                    onClick={() => {
                      navigate("/ff-training/chapters/1");
                      setSupportOpen(true);
                    }}
                  >
                    {activePath == "/ff-training" ? (
                      <FfTrainingSvg fill="#7065E4" />
                    ) : (
                      <FfTrainingSvg fill="#B0B3C7" />
                    )}
                    <span className="nav-text fn_Nunito fs_14 fw_600 cursor_pointer">
                      Field Force Training
                    </span>
                  </label>
                  <span onClick={() => setFfTrainingOpen(!ffTrainingOpen)}>
                    {ffTrainingOpen && activePath == "/ff-training" ? (
                      <img src={ArrowUp} className="me-2" />
                    ) : (
                      <img src={ArrowDown} className="me-2" />
                    )}
                  </span>
                </div>
                {ffTrainingOpen && activePath == "/ff-training" && (
                  <ul className="nav-options ms-3">
                    <Link
                      to={"/ff-training/chapters/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/chapters" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Field Force Chapters
                        </span>
                      </li>
                    </Link>
                    <Link
                      to={"ff-training/qa/1"}
                      className="text-decoration-none Medium_Purple"
                    >
                      <li
                        className={`my-2  ${activeSubPath == "/qa" &&
                          "color_2ad3e7"
                          }`}
                      >
                        <span className="nav-text fn_Nunito fs_14 fw_600">
                          Field Force Q/A
                        </span>
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            )}

          </ul>
          <div className="contact-div">
            <li
              className={`nav-items d-flex justify-content-start align-items-center  px-2 `}
              onClick={() => {
                setLogoutShow(true);
              }}
            >
              <img src={userLogo} />
              <div className="logout-div">
                <span className="nav-text-2">Logout</span>
                <img src={logoutLogo} />
              </div>
            </li>
          </div>
        </div>
      </div>
      <LogoutModal
        logoutShow={logoutShow}
        handleLogoutClose={() => setLogoutShow(false)}
        logoutFunc={logoutHandler}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </div>
  );
};

export default SidebarComponent;
