import React, { useState } from "react";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import searcher from "../../../assets/icons/searchInput.svg";
import CreateBtn from "../../../components/buttons/createBtn";
import TrainingQaListTable from "./training-qa-list-table";

const TrainingQaList = () => {
    const [search, setSearch] = useState(null);
    return (
        <MainLayout breadCrumb="ADMIN / FIELD FORCE TRAINING / Q/A">
            <div>
                <div className="search-container d-flex justify-content-between ">
                    <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
                        <img className="styled-SVG" src={searcher} color={""} />
                        <input
                            className="search-input fs_14 text-dark fw_400 px-2"
                            type="text"
                            placeholder="Search here..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>

                    <div className=" d-flex justify-content-between m-1 mb-2">
                        <CreateBtn link="/ff-training/qa/create" />
                    </div>
                </div>
                <TrainingQaListTable search={search} />
            </div>
        </MainLayout>
    );
};

export default TrainingQaList;
