import styled from "@emotion/styled";
import React from "react";

export const AdminButton = styled("button")(
    {
        backgroundColor: "transparent",
        outline: "0px",
        marginRight: "15px",
        width: "120px",
        height: "40px",
        background: "#FFFFFF",
        border: "1px solid #e7e5ff",
        borderRadius: "7px",
        // borderColor:'#747F9E',
        border: '1.5px solid #747F9E',
        fontFamily: "Nunito Sans",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        color: "#7065e4",

        // "@media (max-width: 768px)": {
        //   height: "35px",
        // },
    },
    (props) => ({
        background:
            props.disabled == true ? `${props.background}99` : props.background,
        width: props.width,
        border: props.border,
        color: props.color,
        disabled: props.disabled,
    })
);

const clearBtn = () => {
    return (
        <AdminButton> CLEAR</AdminButton>
    );
};

export default clearBtn;
