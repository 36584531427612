import {
  CREATE_FAQ,
  DELETE_FAQ,
  EDIT_FAQ,
  FAQ_DETAIL,
  FAQ_LIST,
  FAQ_VERSION_DETAIL,
  FAQ_VERSION_LIST,
} from "./types";

export const faqList = (data, onSuccess, onError) => {
  return {
    type: FAQ_LIST,
    data,
    onSuccess,    
    onError,
  };
};
export const faqCreate = (data, onSuccess, onError) => {
  return {
    type: CREATE_FAQ,
    data,
    onSuccess,
    onError,
  };
};

export const faqEdit = (data, onSuccess, onError) => {
  return {
    type: EDIT_FAQ,
    data,
    onSuccess,
    onError,
  };
};
export const faqDetail = (id, onSuccess, onError) => {
  return {
    type: FAQ_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const faqVersionList = (data,onSuccess, onError) => {
  return {
    type: FAQ_VERSION_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const faqVersionDetial = (id, onSuccess, onError) => {
  return {
    type: FAQ_VERSION_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const faqDelete = (id, onSuccess, onError) => {
  return {
    type: DELETE_FAQ,
    id,
    onSuccess,
    onError,
  };
};