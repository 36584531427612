import React from "react";

const ProductBasicView = ({ productData }) => {
  return (
    <>
      <div className="m-4">
        <div className="basic-details-container  my-auto d-flex align-items-center">
          <label className="basic-title  mb-1">Basic Details</label>
        </div>
        <div className="product-details-container py-2">
          <div className="container-div">
            <span className="label-id color_747f9e">Product ID</span>
            <span className="id-number">
              {productData?.product_id ? productData?.product_id : "--"}
            </span>
          </div>
          <div className="container-div">
            <span className="label-id color_747f9e">Company’s Product ID</span>
            <span className="id-number">
              {productData?.company_product_id
                ? productData?.company_product_id.charAt(0).toUpperCase() +
                productData?.company_product_id.slice(1)
                : "--"}
            </span>
          </div>
          <div className="container-div">
            <span className="label-id color_747f9e">Product Name</span>
            <span className="id-number">
              {productData?.product_name
                ? productData?.product_name.charAt(0).toUpperCase() +
                productData?.product_name.slice(1)
                : "--"}
            </span>
          </div>
          <div className="container-div">
            <span className="label-id color_747f9e">Division Name</span>
            <span className="id-number">
              {productData?.vendorDivision?.name
                ? productData?.vendorDivision?.name
                : "--"}
            </span>
          </div>
        </div>
      </div>
      <div className="m-4">
        <div className="basic-details-container align-items-center">
          <span className="basic-title mb-1">Packaging Details Per Piece</span>
        </div>

        <div className="product-details-container">
          <div className="container-div mt-4">
            <span className="label-id color_747f9e">Type of Packaging</span>
            <span className="id-number">
              {productData?.productPackingDetail?.packingType?.name}
            </span>
          </div>
          {/* <div className="container-div">
      <span className="label-id color_747f9e">Holding Value</span>
      <span className="id-number">
        {basicDetails?.productPackingDetail?.holding_value}
      </span>
    </div> */}
          <div className="container-div">
            <span className="label-id color_747f9e">Shipper Pack Quantity</span>
            <span className="id-number">
              {productData?.productPackingDetail?.ships_box_quantity ?? "--"}
              <span className="ms-1">
                {productData?.productPackingDetail?.holding_value_unit}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBasicView;
