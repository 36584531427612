import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EnableDisableModal from "../../../../components/modals/enableDisableModal";
import "../../manufacturer.css";
import { useDispatch } from "react-redux";
import {
  gevDesignationList,
  gevDesignationReportList,
} from "../../../../redux/actions/godsEyeViewActions";
import searcher from "../../../../assets/icons/searchInput.svg";
import TablePagination from "../../../../components/Pagination/tablePagination";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import exportFromJSON from "export-from-json";
import ErrorPopup from "../../../../components/popups/ErrorPopup";

const DesignationDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [designationList, setDesignationList] = useState([]);
  const [onEnableShow, setOnEnableShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const vendor_id = params?.id.split("&")?.[0];
  const page_no = params?.id.split("&")?.[3];
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(page_no);
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // CSV
  const [showErrorMsg, setshowErrorMsg] = useState(""); // CSV

  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];
  console.log(pathType, "sssss");

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  useEffect(() => {
    setPage(page_no);
  }, [page, page_no]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      gevDesignationList({ vendor_id, page, search }, onSuccess, onError)
    );
  }, [page, search]);

  useEffect(() => {
    setPage(1);
    dispatch(gevDesignationList({ vendor_id, search }, onSuccess, onError));
  }, [search]);

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setDesignationList(data.data.data);
  };
  const onError = (err) => {
    setLoading(false);
  };

  const options = [
    { value: "L1", label: "Level 1" },
    { value: "L2", label: "Level 2" },
    { value: "L3", label: "Level 3" },
    { value: "L4", label: "Level 4" },
    { value: "L5", label: "Level 5" },
    { value: "L6", label: "Level 6" },
    { value: "L7", label: "Level 7" },
    { value: "L8", label: "Level 8" },
    { value: "L9", label: "Level 9" },
    { value: "L10", label: "Level 10" },
    { value: "L11", label: "Level 11" },
    { value: "L12", label: "Level 12" },
    { value: "L13", label: "Level 13" },
    { value: "L14", label: "Level 14" },
    { value: "L15", label: "Level 15" },
    { value: "admin", label: "Admin" },
  ];

  const fileName = "report"; // CSV
  const exportType = exportFromJSON.types.csv; // CSV

  const exportTableCsv = () => {
    console.log("clicked");
    dispatch(gevDesignationReportList(vendor_id, onReprtSuccess, onReprtError));
  };
  const closeSuccessPopup = () => {
    setShowErrorPopup(false);
  };

  const onReprtSuccess = (data) => {
    console.log(data);
    if (data?.data?.data?.length > 0) {
      const tableData = [];
      data?.data?.data?.map((data, index) => {
        tableData.push({
          "sl no": index + 1,
          "Designation Name": data.name ? data.name : "--",
          "Order Approval Limit": data?.approval_authority
            ? `₹. ${data.order_approval_upto}`
            : "--",
          "Approval Level": options.find(
            (ele) => ele.value == data?.approval_level
          )?.label,
          "Approval Authority": data.approval_authority === true ? "yes" : "No",
        });
      });
      exportFromJSON({ data: tableData, fileName, exportType });
    } else {
      setShowErrorPopup(true);
      setshowErrorMsg("No data Found");
      setTimeout(closeSuccessPopup, 2000);
    }
  };
  const onReprtError = (err) => {
    setShowErrorPopup(true);
    setshowErrorMsg("Something went wrong");
    setTimeout(closeSuccessPopup, 2000);
  };

  return (
    <>
      <div className="ms-5 mx-3 my-3 mt-4">
        <div className="d-flex justify-content-between">
          <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 text-dark px-2"
              type="text"
              placeholder="Search designation here by designation name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <button
              className="verify-btn text-uppercase px-2 py-2 my-2 fs_12"
              type="button"
              onClick={() => exportTableCsv()}
            >
              download
            </button>
          </div>
        </div>

        <div
          className="manufacturer-table-container"
          style={{ height: "33.5rem" }}
        >
          <table className="main-table  text-nowrap">
            <thead
              style={{ position: "sticky", top: 0, zIndex: 55 }}
              className="bg-white"
            >
              <tr className="table-rows color_747f9e">
                <th className="table-heads py-2" width={"2%"}>
                  #
                </th>
                <th className="table-heads " width={"8%"}>
                  Designation Name
                </th>
                <th className="table-heads " width={"6%"}>
                  Order Approval Limit
                </th>
                <th className="table-heads " width={"6%"}>
                  Approval Level
                </th>
                <th className="table-heads " width={"8%"}>
                  Approval Authority
                </th>

                <th className="table-heads " width={"18%"}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="mt-3" style={{ position: "relative" }}>
              {loading == true ? (
                <div className="division-spinner-container">
                  <LoadingSpinnerTable />
                </div>
              ) : (
                <>
                  {designationList?.designations?.length > 0 ? (
                    designationList?.designations?.map((data, index) => (
                      <tr className="table-rows1 color_262c3d" key={index}>
                        <td className="table-each-row-data">
                          {(designationList?.pagination?.currentPage - 1) * 10 +
                            (index + 1)}
                        </td>
                        <td className="table-each-row-data">{data.name}</td>
                        <td className="table-each-row-data">
                          {data?.approval_authority
                            ? `₹. ${data.order_approval_upto}`
                            : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {
                            options.find(
                              (ele) => ele.value == data?.approval_level
                            )?.label
                          }
                        </td>
                        {data.approval_authority === true ? (
                          <td className="table-each-row-data text_green">
                            Enabled
                          </td>
                        ) : (
                          <td className="table-each-row-data text_red">
                            Disabled
                          </td>
                        )}
                        <td className="table-each-row-data ">
                          <div className="all-button d-flex align-items-center">
                            <button
                              className="button-view"
                              type="button"
                              onClick={() => {
                                navigate(
                                  `/manufacturer/${pathType}/view/${vendor_id}&designations&view&${data?.id}`
                                );
                              }}
                            >
                              <span className="">View</span>
                            </button>
                            {(user_type == "super_admin" && userData?.write) ||
                              user_type == "admin" ? (
                              <>
                                <span className="vr vr_line mt-1"></span>
                                <button
                                  className="button-edit1"
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/manufacturer/${pathType}/view/${vendor_id}&designations&edit&${data?.id}`
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                            {/* <span className="vr  vr_line mt-1 "></span>
                    <button
                      className={
                        data.approval_authority
                          ? `button-disable`
                          : `button-enable`
                      }
                      type="button"
                      color={"green"}
                      onClick={() => setOnEnableShow(true)}
                    >
                      {data.approval_authority ? "disable" : "enable"}
                    </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="1"
                        className="text-center list-data-container fs_16"
                      >
                        <span>~ No Data Found ~</span>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          <EnableDisableModal
            //   enableDisableFunc={enableDisableFunc}
            handleClose={() => setOnEnableShow(false)}
            //   id={data.id}
            //   status={data?.status}
            show={onEnableShow}
          />
        </div>
        {designationList?.pagination?.totalPages > 0 && (
          <TablePagination
            data={designationList?.pagination}
            nextFunc={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendor_id}&designations&list&${designationList?.pagination?.currentPage + 1
                }`
              );
              setPage(page + 1);
            }}
            prevFunc={() => {
              navigate(
                `/manufacturer/${pathType}/view/${vendor_id}&designations&list&${designationList?.pagination?.currentPage - 1
                }`
              );
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeSuccessPopup}
      />
    </>
  );
};

export default DesignationDetails;

const data = [
  {
    index: "1",
    DesignationName: "Designation Name Goes Here",
    ApprovalLimit: "₹. 10,000 - ₹. 20,000",
    ApprovalLevel: "Level 1",
    ApprovalAuthority: "Disabled",
  },
  {
    index: "2",
    DesignationName: "Designation Name Goes Here",
    ApprovalLimit: "₹. 10,000 - ₹. 20,000",
    ApprovalLevel: "Level 2",
    ApprovalAuthority: "Enabled",
  },
  {
    index: "3",
    DesignationName: "Designation Name Goes Here",
    ApprovalLimit: "₹. 10,000 - ₹. 20,000",
    ApprovalLevel: "Level 3",
    ApprovalAuthority: "Disabled",
  },
  {
    index: "4",
    DesignationName: "Designation Name Goes Here",
    ApprovalLimit: "₹. 10,000 - ₹. 20,000",
    ApprovalLevel: "Level 4",
    ApprovalAuthority: "Disabled",
  },
];
