import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import InputFieldAmount from "../../../components/form/inputField/inputFieldAmount";
import DesignationListTable from "../../../components/tables/designationListTable";
import Switch from "react-switch";
import "../manufacturer.css";
import ErrorMsg from "../../../components/form/errorMsg";
import { useDispatch, useSelector } from "react-redux";
import { manufacturerCreateDesignation } from "../../../redux/actions/manufacturerActions";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";

const ManufacturerDesignationCreate = () => {
  const reducerData = useSelector((state) => state.manufacturerCreateReducer);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const [designationList, setDesignationList] = useState([]);
  const [editEnable, setEditEnable] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [approvalLevel, setApprovalLevel] = useState("");

  window.onload = () => {
    navigate("/manufacturer");
  };

  useEffect(() => {
    if (reducerData?.designations?.length > 0) {
      setDesignationList(reducerData.designations);
    }
  }, []);

  const initialValues = {
    name: "",
    approval_level: "",
    approval_authority: false,
    customer_verification: false,
    order_approval_from: "",
    order_approval_upto: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    order_approval_from: Yup.number().when(["approval_authority"], {
      is: (approvalAuthority) => {
        return approvalAuthority === true;
      },
      then: Yup.number()
        .integer("Invalid")
        .typeError("invalid value")
        .required("Required"),
    }),
    order_approval_upto: Yup.number().when(["approval_authority"], {
      is: (approvalAuthority) => {
        return approvalAuthority === true;
      },
      then: Yup.number()
        .integer("Invalid")
        .typeError("invalid value")
        .required("Required"),
    }),
    approval_level: Yup.string().required("Required"),
    approval_authority: Yup.boolean().required("Required"),
  });

  const clearFunc = (setFieldValue) => {
    setFieldValue("name", "");
    setFieldValue("order_approval_upto", "");
    setFieldValue("order_approval_from", "");
    setFieldValue("approval_level", "");
    setFieldValue("approval_authority", false);
    setFieldValue("customer_verification", false);
  };

  const addDesignation = (values, { resetForm }) => {
    if (!editEnable) {
      setDesignationList([...designationList, values]);
    } else {
      designationList[editIndex] = values;
      setDesignationList([...designationList]);
    }
    resetForm({
      values: {
        name: "",
        order_approval_upto: "",
        order_approval_from: "",
        approval_level: "",
        approval_authority: false,
        customer_verification: false,
      },
    });
    setEditEnable(false);
  };

  const FormEnableCheck = () => {
    const {
      values: { name, approval_level },
    } = useFormikContext();
    useEffect(() => {
      if (name.trim() != "") {
        setDisable(false);
      } else setDisable(true);
    }, [name, approval_level]);
    return null;
  };

  const options = [
    { value: "L1", label: "Level 1" },
    { value: "L2", label: "Level 2" },
    { value: "L3", label: "Level 3" },
    { value: "L4", label: "Level 4" },
    { value: "L5", label: "Level 5" },
    { value: "L6", label: "Level 6" },
    { value: "L7", label: "Level 7" },
    { value: "L8", label: "Level 8" },
    { value: "L9", label: "Level 9" },
    { value: "L10", label: "Level 10" },
    { value: "L11", label: "Level 11" },
    { value: "L12", label: "Level 12" },
    { value: "L13", label: "Level 13" },
    { value: "L14", label: "Level 14" },
    { value: "L15", label: "Level 15" },
    { value: "admin", label: "Admin" },
  ];

  const onSubmit = () => {
    console.log(designationList);
    dispatch(
      manufacturerCreateDesignation(
        { designations: designationList },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    console.log("added designations");
    navigate("/manufacturer/subscibe-list/create/head-quater");
  };
  const onError = (err) => {
    console.log(err);
  };

  const editSetForm = (item, index, setFieldValue) => {
    setEditEnable(true);
    setEditIndex(index);
    setFieldValue("name", item.name);
    setFieldValue("approval_authority", item.approval_authority);
    setFieldValue("approval_level", item.approval_level);
    setFieldValue("approval_all", item.approval_all);
    setFieldValue("order_approval_upto", item.order_approval_upto);
    setFieldValue("order_approval_from", item.order_approval_from);
  };

  return (
    <MainLayout breadCrumb="ADMIN  /  Manufacturer / Create / Designations">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addDesignation}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            <div className="search-container d-flex justify-content-between ">
              <div className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer">
                {state && (
                  <React.Fragment>
                    <div onClick={() => navigate(-1)}>
                      <img src={back} alt="back_btn" />
                      <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                        BACK
                      </span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  type="button"
                  onClick={() => clearFunc(setFieldValue)}
                >
                  CLEAR
                </AdminButton>
                <AdminButton
                  background="#7065e4"
                  color="white"
                  width="100px"
                  type="button"
                  onClick={onSubmit}
                >
                  <span className="m-2">NEXT</span>
                </AdminButton>
              </div>
            </div>
            <div className="scrolling">
              <div className="fn_Nunito">
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Designations</label>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-6 col-md-9">
                    <div className="mx-4 px-3">
                      <div className="select-container">
                        <label className="w-100 mx-1 fn_Nunito fs_14">
                          Approval Level
                        </label>
                        <Field name={"approval_level"}>
                          {({ form, field, meta }) => (
                            <div className="d-flex">
                              <Select
                                {...field}
                                // type={type}
                                value={options.filter(
                                  (s) => s.value == values?.approval_level
                                )}
                                cacheOptions
                                defaultOptions
                                placeholder={"Select level"}
                                options={options}
                                onChange={(selected) => {
                                  form.setFieldValue(
                                    "approval_level",
                                    selected.value
                                  );
                                  setApprovalLevel(selected.value);
                                }}
                                className={`w-100`}
                                styles={customStyles}
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name="approval_level" />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"name"}
                          label={"Designation Name"}
                          labelColor="color_262c3d"
                          type={"text"}
                          placeholder={"Enter Designation Name"}
                          name={"name"}
                          value={values?.name}
                        />
                      </div>
                      {values?.approval_level != "admin" && (
                        <>
                          <div className="d-flex my-4 align-items-center color_747f9e fw_400 fs_14">
                            <label>Customer Verification</label>
                            <Switch
                              checked={values?.customer_verification}
                              onChange={() => {
                                if (!values.customer_verification) {
                                  setFieldValue("customer_verification", true);
                                } else {
                                  setFieldValue("customer_verification", false);
                                }
                              }}
                              className="mx-3"
                              onColor="#d3d6eb"
                              offColor="#d3d6eb"
                              onHandleColor="#7065E4"
                              offHandleColor="#747F9E"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={40}
                            />
                          </div>
                          <div className="d-flex my-2 align-items-center color_747f9e fw_400 fs_14">
                            <label>Approval Authority</label>
                            <Switch
                              checked={values?.approval_authority}
                              onChange={() => {
                                if (!values.approval_authority) {
                                  setFieldValue("approval_authority", true);
                                } else {
                                  setFieldValue("approval_authority", false);
                                  setFieldValue("order_approval_upto", "");
                                  setFieldValue("order_approval_from", "");
                                }
                              }}
                              className="mx-3"
                              onColor="#d3d6eb"
                              offColor="#d3d6eb"
                              onHandleColor="#7065E4"
                              offHandleColor="#747F9E"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={40}
                            />
                          </div>
                          {values?.approval_authority && (
                            <>
                              <div>
                              <InputFieldAmount
                                  setFieldValue={setFieldValue}
                                  type="number"
                                  label={"Order Approval Limit From"}
                                  labelColor="color_747f9e"
                                  className="amount_input  py-1  ms-1"
                                  id="order_approval_from"
                                  name="order_approval_from"
                                  placeholder="Enter amount"
                                  value={values?.order_approval_from}
                                />
                                <InputFieldAmount
                                  setFieldValue={setFieldValue}
                                  type="number"
                                  label={"Order Approval Limit Upto"}
                                  labelColor="color_747f9e"
                                  className="amount_input  py-1  ms-1"
                                  id="order_approval_upto"
                                  name="order_approval_upto"
                                  placeholder="Enter amount"
                                  value={values?.order_approval_upto}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div
                        className="d-flex justify-content-end"
                        style={{ maxWidth: "450px" }}
                      >
                        {!editEnable ? (
                          <button
                            type="submit"
                            className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                            style={{ maxWidth: "210px" }}
                            disabled={disable}
                          >
                            Add More
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                            style={{ maxWidth: "210px" }}
                            disabled={disable}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Designations List
                  </label>
                </div>
                <div className="mx-4 px-3 my-3 mb-5">
                  <DesignationListTable
                    designationList={designationList}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default ManufacturerDesignationCreate;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
