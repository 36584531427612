import {
  CITY_LIST,
  DISTRICT_LIST,
  DIVISION_LIST,
  PINCODE_AUTO_LIST,
  SECOND_CITY_LIST,
  SECOND_DISTRICT_LIST,
  SECOND_STATE_LIST,
  STATE_LIST,
} from "./types";

export const fetchStateList = (onSuccess, onError) => {
  return {
    type: STATE_LIST,
    onSuccess,
    onError,
  };
};
export const fetchSecondStateList = (onSuccess, onError) => {
  return {
    type: SECOND_STATE_LIST,
    onSuccess,
    onError,
  };
};

export const fetchSecondDistrictList = (data, onSuccess, onError) => {
  return {
    type: SECOND_DISTRICT_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const fetchSecondCityList = (data, onSuccess, onError) => {
  return {
    type: SECOND_CITY_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const fetchDistrictList = (data, onSuccess, onError) => {
  return {
    type: DISTRICT_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const fetchCityList = (data, onSuccess, onError) => {
  return {
    type: CITY_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const fetchPincodeAutoList = (data, onSuccess, onError) => {
  return {
    type: PINCODE_AUTO_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const fetchDivisionList = (onSuccess, onError) => {
  return {
    type: DIVISION_LIST,
    onSuccess,
    onError,
  };
};
