import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const SuperUserRoleVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  return (
    <tr className="table-rows1 ">
      <td className="table-each-row-data">
        {(currentPage - 1) * 10 + (index + 1)}
      </td>
      <td className="table-each-row-data">{data.role_id}</td>
      <td className="table-each-row-data">{data.role_name}</td>
      <td className="table-each-row-data">
        {data?.adminRoleVersion?.updatedBy?.name
          ? data?.adminRoleVersion?.updatedBy?.name
          : "--"}
      </td>
      <td className="table-each-row-data">
        {moment(data?.adminRoleVersion?.updatedAt).format(
          "DD MMM YYYY HH:mm A"
        )}
      </td>
      <td className="table-each-row-data ">
        <div className="all-button d-flex align-items-center">
          <button
            className="button-view"
            type="button"
            onClick={() => navigate(`/super-user/role/version-view/${data.id}`)}
          >
            <span className=""> View</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default SuperUserRoleVersionTableData;
