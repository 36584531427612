import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { AdminButton } from "../buttons/versionHistoryBtn";
import "./modal.css";
import SuccessPopup from "../popups/SuccessPopup";
import ErrorPopup from "../popups/ErrorPopup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { custoDistriReqApproveReject } from "../../redux/actions/customerActions";

const ApporveRejectModal = ({
  show,
  handleClose,
  title = "Do you want to",
  btnColor = "#FF6F5B",
  btnName = "",
  status,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const approvalRejectFnc = () => {
    dispatch(
      custoDistriReqApproveReject(
        {
          request_id: params.id,
          approval: status,
        },
        onApprovalRejectSuccess,
        onApprovalRejectError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/customers/distributor-request/1");
  };

  const onApprovalRejectSuccess = (data) => {
    console.log(data);
    handleClose();
    setShowSuccessPopup(true);
    setShowSuccessMsg(data.data.message);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onApprovalRejectError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    setShowErrorMsg(err.data.message);
  };
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="my-modal"
      >
        <ModalBody>
          <div className="p-2">
            <p className="fn_Nunito fw_700 fs_18 color_262c3d my-2 px-0">
              Are you sure ?
            </p>
            <p className="fn_Nunito fw_400 fs_16 color_747f9e my-2 px-0">
              Do you want to {title} this user??
            </p>
            <div className="d-flex justify-content-between">
              <AdminButton
                width="150px"
                color="white"
                border="1px solid {btnColor}"
                background={btnColor}
                onClick={() => {
                  approvalRejectFnc();
                }}
              >
                <span className="m-2">{btnName}</span>
              </AdminButton>
              <AdminButton
                width="150px"
                background="#7065e4"
                color="white"
                onClick={() => handleClose()}
              >
                <span className="m-2">NO</span>
              </AdminButton>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ApporveRejectModal;
