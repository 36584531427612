import React, { useState } from "react";
import searcher from "../../assets/icons/searchInput.svg";
import back from "../../assets/icons/back.svg";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import { useNavigate } from "react-router-dom";
import DistributorVersionTable from "./distributor-version-list-table";

const DistributorVersionList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  return (
    <MainLayout breadCrumb="ADMIN  /  Distributor  / Version History">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="d-flex w-100">
            <div
              className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
              onClick={() => navigate(-1)}
            >
              <img src={back} alt="back_btn" />
              <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                BACK
              </span>
            </div>
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 px-2 text-dark"
                type="text"
                placeholder="Search distributors here by distributor ID, distributor name..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <DistributorVersionTable search={search} />
      </div>
    </MainLayout>
  );
};

export default DistributorVersionList;
