import { call, take, takeLatest } from "redux-saga/effects";
import {
  CREATE_DISTRIBUTOR,
  DELETE_DISTROBUTOR,
  DISTRIBUTOR_DETAIL,
  DISTRIBUTOR_DIVISION_LIST,
  DISTRIBUTOR_ENABLE_DISABLE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_SEND_MAIL,
  DISTRIBUTOR_VERSION_LIST,
  DISTRIBUTOR_VESRION_DETAIL,
  DRUG_LICENCE_UPLOAD,
  EDIT_DISTRIBUTOR,
  GST_CERTIFICATE_UPLOAD,
  LOGIN_TO_PORTAL_DISTRIBUTOR,
  LOGO_UPLOAD,
  MANUFACTURER_DROPDOWN,
  ONBOARDING_DISTRIBUTOR_LIST,
} from "../actions/types.js";
import * as distributorApis from "../apis/distributorApis.js";

export function* distributorListWorker(action) {
  try {
    const res = yield call(distributorApis.distributorListApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* onboardingDistributorListWorker(action) {
  try {
    const res = yield call(
      distributorApis.onboardingDistributorListApi,
      action.data
    );
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorDetailWorker(action) {
  try {
    const res = yield call(distributorApis.distributorDetailApi, {
      distributor_id: action.id,
    });
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorVesrionListWorker(action) {
  try {
    const res = yield call(distributorApis.distributorVersionList, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorVersionDetailWorker(action) {
  try {
    const res = yield call(distributorApis.distributorVersionDetailApi, {
      distributor_id: action.id,
    });
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorEnableDisableWorker(action) {
  try {
    const res = yield call(distributorApis.enableDisableDistributorApi, {
      distributor_id: action.id,
    });
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* distributorDeleteWorker(action) {
  try {
    const res = yield call(distributorApis.deleteDistributorApi, {
      distributor_id: action.id,
    });
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* manufacturerListOptionsWorker(action) {
  try {
    const res = yield call(distributorApis.manufacturerOptionsApi);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}

export function* distributorEditWorker(action) {
  try {
    const res = yield call(distributorApis.distributorEditApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* uploadLogoWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(distributorApis.distributorLogoUploadApi, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* uploadDrugLicenceWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(distributorApis.distributorDrugLicenceUpload, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* uploadGstWorker(action) {
  try {
    const file = new FormData();
    file.append("file", action.image);
    const res = yield call(distributorApis.distributorGstUpload, file);
    console.log(res);
    if (res.status == 200) {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}

export function* loginToPortalDistributorWorker(action) {
  try {
    console.log(action);
    const res = yield call(distributorApis.loginToPortalDistributorApi, {
      distributor_id: action.distributor_id,
    });
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* distributorSendMailWorker(action) {
  try {
    const res = yield call(distributorApis.DistributorsendMailApi, action.data);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* distributorCreateWorker(action) {
  try {
    const res = yield call(distributorApis.distributorCreateApi, action.data);
    console.log(res);
    if (res.status == "200") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      data: {
        message: "Something went wrong",
      },
    });
  }
}
export function* divisionListWorker(action) {
  try {
    const res = yield call(distributorApis.divisionsOptionsApi, action.data);
    console.log(res);
    if (res.status == 200) {
      let newArr = res.data.data.map((ele) => ({
        value: ele.id,
        label: ele.name,
      }));
      yield action.onSuccess(newArr);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    yield action.onError({
      "data": {
        message: "Something went wrong"
      }
    })
  }
}


export function* distributorListWatcher() {
  yield takeLatest(DISTRIBUTOR_LIST, distributorListWorker);
}

export function* distributorDetailWatcher() {
  yield takeLatest(DISTRIBUTOR_DETAIL, distributorDetailWorker);
}
export function* distributorVesrionListWatcher() {
  yield takeLatest(DISTRIBUTOR_VERSION_LIST, distributorVesrionListWorker);
}

export function* distributorVersionDetailWatcher() {
  yield takeLatest(DISTRIBUTOR_VESRION_DETAIL, distributorVersionDetailWorker);
}

export function* distributorEnableDisableWatcher() {
  yield takeLatest(DISTRIBUTOR_ENABLE_DISABLE, distributorEnableDisableWorker);
}

export function* distributorDeleteWatcher() {
  yield takeLatest(DELETE_DISTROBUTOR, distributorDeleteWorker);
}

export function* manufacturerListOptionsWatcher() {
  yield takeLatest(MANUFACTURER_DROPDOWN, manufacturerListOptionsWorker);
}

export function* distributorEditWatcher() {
  yield takeLatest(EDIT_DISTRIBUTOR, distributorEditWorker);
}

export function* uploadLogoWatcher() {
  yield takeLatest(LOGO_UPLOAD, uploadLogoWorker);
}

export function* uploadDrugLicenceWatcher() {
  yield takeLatest(DRUG_LICENCE_UPLOAD, uploadDrugLicenceWorker);
}

export function* uploadGstWatcher() {
  yield takeLatest(GST_CERTIFICATE_UPLOAD, uploadGstWorker);
}

export function* loginToPortalDistributorWatcher() {
  yield takeLatest(LOGIN_TO_PORTAL_DISTRIBUTOR, loginToPortalDistributorWorker);
}
export function* lonboardingDistributorListWatcher() {
  yield takeLatest(
    ONBOARDING_DISTRIBUTOR_LIST,
    onboardingDistributorListWorker
  );
}
export function* distributorSendMailWatcher() {
  yield takeLatest(DISTRIBUTOR_SEND_MAIL, distributorSendMailWorker);
}
export function* distributorCreateWatcher() {
  yield takeLatest(CREATE_DISTRIBUTOR, distributorCreateWorker);
}
export function* distdivisionListWatcher() {
  yield takeLatest(DISTRIBUTOR_DIVISION_LIST, divisionListWorker);
}
