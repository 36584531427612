import React from "react";

const SuperUserSvg = ({
  fill = "#B0B3C7",
  width = "14px",
  height = "14px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.73684 12.1579C7.73722 12.6841 7.86355 13.2025 8.10526 13.6699C8.12422 13.7033 8.13414 13.7411 8.13405 13.7795C8.13395 13.8179 8.12385 13.8556 8.10474 13.889C8.08562 13.9223 8.05815 13.95 8.02504 13.9695C7.99192 13.989 7.95431 13.9995 7.91589 14H2.57895C0.891579 14 0 13.1158 0 11.4358C0 9.55684 1.06105 7.36842 4.05263 7.36842H7C7.5844 7.33408 8.16896 7.43297 8.70955 7.65763C9.25013 7.88229 9.7326 8.22684 10.1205 8.66526C10.1418 8.69399 10.1558 8.72744 10.1613 8.76275C10.1668 8.79806 10.1636 8.83418 10.1521 8.86801C10.1406 8.90184 10.121 8.93237 10.0951 8.95699C10.0692 8.98161 10.0377 8.99958 10.0034 9.00937C9.34203 9.22845 8.76679 9.65084 8.35977 10.2163C7.95274 10.7817 7.73473 11.4612 7.73684 12.1579ZM5.53295 5.89474C6.11588 5.89474 6.68573 5.72188 7.17042 5.39802C7.65511 5.07416 8.03288 4.61384 8.25596 4.07528C8.47904 3.53672 8.53741 2.9441 8.42368 2.37237C8.30996 1.80063 8.02925 1.27546 7.61705 0.863265C7.20485 0.451069 6.67968 0.170359 6.10795 0.0566342C5.53622 -0.0570907 4.9436 0.00127704 4.40504 0.224356C3.86648 0.447436 3.40616 0.825207 3.0823 1.3099C2.75844 1.79459 2.58558 2.36444 2.58558 2.94737C2.58558 3.72906 2.8961 4.47873 3.44884 5.03147C4.00158 5.58421 4.75126 5.89474 5.53295 5.89474Z"
        fill={fill}
      />
      <path
        d="M12.5002 12C12.5002 11.908 12.4918 11.8163 12.4752 11.7258L12.9824 11.4333L12.4824 10.5667L11.9751 10.8598C11.835 10.74 11.674 10.647 11.5002 10.5855V10H10.5002V10.5855C10.3264 10.647 10.1655 10.74 10.0254 10.8598L9.51807 10.5667L9.01807 11.4333L9.52523 11.7258C9.49192 11.9071 9.49192 12.0929 9.52523 12.2742L9.01807 12.5667L9.51807 13.4333L10.0254 13.1403C10.1655 13.2601 10.3265 13.3531 10.5002 13.4145V14H11.5002V13.4145C11.674 13.353 11.835 13.26 11.9751 13.1402L12.4824 13.4333L12.9824 12.5667L12.4752 12.2742C12.4918 12.1837 12.5002 12.092 12.5002 12ZM11.5002 12C11.5002 12.0989 11.4709 12.1956 11.416 12.2778C11.361 12.36 11.2829 12.4241 11.1916 12.4619C11.1002 12.4998 10.9997 12.5097 10.9027 12.4904C10.8057 12.4711 10.7166 12.4235 10.6467 12.3536C10.5768 12.2836 10.5291 12.1945 10.5098 12.0975C10.4905 12.0006 10.5004 11.9 10.5383 11.8087C10.5761 11.7173 10.6402 11.6392 10.7224 11.5843C10.8047 11.5293 10.9013 11.5 11.0002 11.5C11.1328 11.5 11.26 11.5527 11.3538 11.6464C11.4476 11.7402 11.5002 11.8674 11.5002 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default SuperUserSvg;
