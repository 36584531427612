import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import back from "../../../assets/icons/back.svg";
import uploadDoc from "../../../assets/icons/uploadDoc.svg";
import upload from "../../../assets/icons/uploadImg.svg";
import reupload from "../../../assets/icons/reUpload.svg";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import "../manufacturer.css";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import Switch from "react-switch";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import InputField from "../../../components/form/inputField/inputField";
import ErrorMsg from "../../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import {
  fetchCityList,
  fetchDistrictList,
  fetchStateList,
} from "../../../redux/actions/commonActions";
import {
  documentUpld1,
  documentUpld2,
  manufacturerDetail,
  manufacturerEdit,
  manufacturerLogoUpload,
} from "../../../redux/actions/manufacturerActions";
import { Document, Page } from "react-pdf";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";
import ValueInputField from "../../../components/form/inputField/ValueInputField";
import eye from "../../../assets/icons/viewEye.svg";
import PdfView from "../../../components/modals/pdfView";
import SuccessPopup from "../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../components/popups/ErrorPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../../components/utilites/loaderSpinningContainer";

const ManufacturerPremiumEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [manufacturerViewData, setManufacturerViewData] = useState([]);
  const [logoImages, setLogoImages] = useState([]); // LOGO IMAGE UPLOAD
  const [licenceImg, setLicenceImg] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [gstimg, setGstImg] = useState(null);
  const [logoUpldLoading, setLogoUpldLoading] = useState(false);
  const [logoImg, setLogoImg] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [filename, setFileName] = useState(null);
  const [onShowSuccess, setOnshowSuccess] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [catchedErrorPopup, setCatchedErrorPopup] = useState(false);
  const [catchedErrorMsg, setCatchedErrorMsg] = useState();

  const phoneRegex = /^([+]\d{2})?\d{10}$/;
  const nameregex = /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/;
  const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  const logoimageUpdate = (file) => {
    dispatch(
      manufacturerLogoUpload(
        file.target.files[0],
        onLogoUploadSuccess,
        onLogoUploadError
      )
    );
  };

  const onLogoUploadSuccess = (data) => {
    console.log(data);
    setLogoImg(data.data.data.filePath);
  };
  const onLogoUploadError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    fetchManufacturerView();
  }, []);
  const fetchManufacturerView = () => {
    setLoading(true);
    dispatch(manufacturerDetail(params.id, onViewSuccess, onViewError));
  };
  const onViewSuccess = (data) => {
    console.log(data.data.data);
    setLoading(false);
    setManufacturerViewData(data.data.data);
    setLicenceImg(data?.data?.data?.vendor?.drug_manufacturing_license);
    setGstImg(data?.data?.data?.vendor?.gst_certificate);
    setLogoImg(
      data?.data?.data?.vendor?.logo ? data?.data?.data?.vendor?.logo : null
    );

    dispatch(
      fetchDistrictList(
        { state_id: data?.data?.data?.vendor?.state_id },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
    dispatch(
      fetchCityList(
        {
          state_id: data?.data?.data?.vendor?.state_id,
          district_id: data?.data?.data?.vendor?.district_id,
        },
        onGetCitySuccess,
        onGetCityError
      )
    );
  };

  const closeCatchedErrPopup = () => {
    setCatchedErrorPopup(false);
  };

  const onViewError = (err) => {
    setLoading(false);
    console.log(err);
    setCatchedErrorPopup(true);
    setCatchedErrorMsg(err.data.message);
  };

  useEffect(() => {
    dispatch(fetchStateList(onGetStateSuccess, onGetStateError));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDistrictList(
        { state_id: selectedState },
        onGetDistrictSuccess,
        onGetDistrictError
      )
    );
  }, [selectedState]);

  useEffect(() => {
    if (selectedState !== null) {
      dispatch(
        fetchDistrictList(
          { state_id: selectedState },
          onGetDistrictSuccess,
          onGetDistrictError
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict !== null) {
      dispatch(
        fetchCityList(
          { state_id: selectedState, district_id: selectedDistrict },
          onGetCitySuccess,
          onGetCityError
        )
      );
    } else {
      setCityOptions([]);
    }
  }, [selectedState, selectedDistrict]);

  const onGetStateSuccess = (data) => {
    console.log(data);
    setStateOptions(data);
  };
  const onGetStateError = (err) => {
    console.log(err);
  };

  const onGetDistrictSuccess = (data) => {
    setDistrictOptions(data);
    console.log(data);
  };
  const onGetDistrictError = (err) => {
    console.log(err);
  };

  const onGetCitySuccess = (data) => {
    setCityOptions(data);
    console.log(data);
  };
  const onGetCityError = (err) => {
    console.log(err);
  };

  const initialValues = {
    vendor_id: manufacturerViewData?.vendor?.id,
    name: manufacturerViewData?.vendor?.name,
    email: manufacturerViewData?.vendor?.email,
    mobile: manufacturerViewData?.vendor?.mobile,
    alternate_mobile: manufacturerViewData?.vendor?.alternate_mobile,
    address_one: manufacturerViewData?.vendor?.address?.address_one,
    address_two: manufacturerViewData?.vendor?.address?.address_two,
    address_three: manufacturerViewData?.vendor?.address?.address_three,
    landmark: manufacturerViewData?.vendor?.address?.landmark,
    state_id: manufacturerViewData?.vendor?.state_id,
    district_id: manufacturerViewData?.vendor?.district_id,
    city_id: manufacturerViewData?.vendor?.city_id,
    pincode: manufacturerViewData?.vendor?.pincode,
    cin_number: manufacturerViewData?.vendor?.cin_number,
    drug_licence_1: manufacturerViewData?.vendor?.drug_licence_1,
    drug_licence_2: manufacturerViewData?.vendor?.drug_licence_2,
    fssai_number: manufacturerViewData?.vendor?.fssai_number,
    gstin_number: manufacturerViewData?.vendor?.gstin_number,
    pan_number: manufacturerViewData?.vendor?.pan_number,
    order_validity: manufacturerViewData?.vendor?.setting?.order_validity,
    is_subscribed: manufacturerViewData?.vendor?.is_subscribed,
    invoicing_value: manufacturerViewData?.vendor?.invoicing_value,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    order_validity: Yup.string().required("Required"),
    invoicing_value: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email").required("Required"),
    mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    alternate_mobile: Yup.string()
      .matches(phoneRegex, "Not a valid Number")
      .required("Required"),
    address_one: Yup.string().required("Required"),
    address_two: Yup.string().required("Required"),
    landmark: Yup.string().required("Required"),
    state_id: Yup.string().required("Required"),
    district_id: Yup.string().required("Required"),
    city_id: Yup.string().required("Required"),
    pincode: Yup.string()
      .matches(pincoderegex, "Not a valid Number")
      .required("Required"),
  });
  const resetFunc = (setFieldValue) => {
    setFieldValue("name", manufacturerViewData?.vendor?.name);
    setFieldValue(
      "order_validity",
      manufacturerViewData?.vendor?.setting?.order_validity
    );
    setFieldValue("mobile", manufacturerViewData?.vendor?.mobile);
    setFieldValue("email", manufacturerViewData?.vendor?.email);
    setFieldValue(
      "alternate_mobile",
      manufacturerViewData?.vendor?.alternate_mobile
    );
    setFieldValue(
      "address_one",
      manufacturerViewData?.vendor?.address?.address_one
    );
    setFieldValue(
      "address_two",
      manufacturerViewData?.vendor?.address?.address_two
    );
    setFieldValue(
      "address_three",
      manufacturerViewData?.vendor?.address?.address_three
    );
    setFieldValue("landmark", manufacturerViewData?.vendor?.address?.landmark);
    setFieldValue("state_id", manufacturerViewData?.vendor?.state_id);
    setFieldValue("district_id", manufacturerViewData?.vendor?.district_id);
    setFieldValue("city_id", manufacturerViewData?.vendor?.city_id);
    setFieldValue("pincode", manufacturerViewData?.vendor?.pincode);
    setFieldValue(
      "GSTCertificate",
      manufacturerViewData?.vendor?.GSTCertificate
    );
    setFieldValue("cin_number", manufacturerViewData?.vendor?.cin_number);
    setFieldValue(
      "drug_licence_1",
      manufacturerViewData?.vendor?.drug_licence_1
    );
    setFieldValue(
      "drug_licence_2",
      manufacturerViewData?.vendor?.drug_licence_2
    );
    setFieldValue("fssai_number", manufacturerViewData?.vendor?.fssai_number);
    setFieldValue("gstin_number", manufacturerViewData?.vendor?.gstin_number);
    setFieldValue("pan_number", manufacturerViewData?.vendor?.pan_number);
    setLicenceImg(manufacturerViewData?.vendor?.drug_manufacturing_license);
    setGstImg(manufacturerViewData?.vendor?.gst_certificate);
    setLogoImg(
      manufacturerViewData?.vendor?.logo
        ? manufacturerViewData?.vendor?.logo
        : null
    );
    setFieldValue("invoicing_value", manufacturerViewData?.vendor?.invoicing_value);
  };

  const docimageUpdateOne = (file) => {
    console.log(file.target.files[0]);
    dispatch(
      documentUpld1(
        file.target.files[0],
        ondoc1UploadSuccess,
        ondoc1UploadError
      )
    );
    // setDocOneImages(imageList);
  };

  const ondoc1UploadSuccess = async (data) => {
    console.log(data.data.data);
    setGstImg(data.data.data.filePath);
  };

  const ondoc1UploadError = (err) => { };

  const docimageUpdateTwo = (file) => {
    dispatch(
      documentUpld2(
        file.target.files[0],
        ondoc2UploadSuccess,
        ondoc2UploadError
      )
    );
    // setDocTwoImages(imageList);
  };

  const ondoc2UploadSuccess = (data) => {
    setLicenceImg(data.data.data.filePath);
  };
  const ondoc2UploadError = (err) => {
    console.log(err);
  };

  const onSubmit = (values) => {
    console.log(values);
    values["logo"] = logoImg;
    values["gst_certificate"] = gstimg;
    values["drug_manufacturing_license"] = licenceImg;
    values["address"] = {
      address_one: values.address_one,
      address_two: values.address_two,
      address_three: values.address_three,
      landmark: values.landmark,
    };
    dispatch(manufacturerEdit(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setOnshowSuccess(false);
    navigate("/manufacturer/premium-list/1");
  };

  const onSuccess = (data) => {
    console.log(data);
    console.log(data.data.message);
    setOnshowSuccess(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  useEffect(() => {
    console.log("afsdfs", logoImages);
  }, [logoImages]);

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("edit/:id") &&
        ` /  ${manufacturerViewData?.vendor?.employee_id} - edit `}
    </span>
  );

  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>

              <div className=" d-flex justify-content-between m-1 mb-2 align-items-center">
                <div className="d-flex align-items-center">
                  <label className="Medium_Purple fw_60 text-uppercase">
                    Premium
                  </label>
                  <Switch
                    checked={values?.is_subscribed}
                    onChange={() => {
                      if (!values.is_subscribed) {
                        setFieldValue("is_subscribed", true);
                      } else {
                        setFieldValue("is_subscribed", false);
                      }
                    }}
                    className="mx-3"
                    onColor="#d3d6eb"
                    offColor="#d3d6eb"
                    onHandleColor="#7065E4"
                    offHandleColor="#747F9E"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                    height={20}
                    width={40}
                  />
                </div>
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  onClick={() => resetFunc(setFieldValue)}
                  type="button"
                >
                  RESET
                </AdminButton>
                <AdminButton
                  background="#2AD3E7"
                  color="white"
                  width="100px"
                  type="submit"
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            {loading == true ? (
              <div className="view-spinner-container">
                <LoadingSpinnerTable show={loading} />
              </div>
            ) : (
              <div className="fn_Nunito scrolling">
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Basic Details</label>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label className="color_747f9e">
                            Manufacturer Logo
                          </label>
                          {logoImg !== "" && logoImg !== null && (
                            <>
                              <label
                                htmlFor="DrugLicense"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />
                                Re-UPload
                              </label>
                            </>
                          )}
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense"
                            accept="image/*"
                            onChange={(e) => logoimageUpdate(e)}
                            style={{ display: "none" }}
                          />
                        </div>
                        <div style={{ maxWidth: "450px" }}>
                          {logoImg !== "" && logoImg !== null ? (
                            <div className="manufacturer-doc-upload-container">
                              <img src={logoImg} />
                            </div>
                          ) : (
                            <>
                              <label
                                htmlFor="GSTCertificate"
                                className="manufacturer-doc-upload-container w-100 text-center"
                              >
                                <div
                                  style={{ height: "12rem" }}
                                  className={`d-flex align-items-center justify-content-center `}
                                >
                                  <div className="d-flex flex-column align-items-center justify-content-center">
                                    <img
                                      src={upload}
                                      width="50px"
                                      height="50px"
                                    />
                                    <span className="color_#262C3D pt-2 fs_14 fs_lg_12">
                                      Drag your image here, or
                                      <span className="Medium_Purple fw_700 ms-1">
                                        Browse
                                      </span>
                                    </span>
                                    <label className="color_747f9e pt-1 fs_10">
                                      Supports: JPEG and PNG images only.{" "}
                                      <span className="color_black">
                                        (2MB Max, 540X540px Resolution)
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </label>
                              <input
                                type="file"
                                name="upload"
                                id="GSTCertificate"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => logoimageUpdate(e)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="my-2 mt-3">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"name"}
                          label={"Manufacturer Name"}
                          labelColor="Neutral_Color"
                          type={"text"}
                          placeholder={"Enter manufacturer name"}
                          name={"name"}
                          value={values.name}
                        />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"drug_licence_1"}
                          label={"Drug License 1"}
                          labelColor="Neutral_Color"
                          type={"text"}
                          placeholder={"Enter Drug License 1 number"}
                          name={"drug_licence_1"}
                          value={values.drug_licence_1}
                        />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"drug_licence_2"}
                          label={"Drug License 2"}
                          labelColor="Neutral_Color"
                          type={"text"}
                          placeholder={"Enter Drug License 2 number"}
                          name={"drug_licence_2"}
                          value={values.drug_licence_2}
                        />
                      </div>
                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"cin_number"}
                          label={"CIN No"}
                          labelColor="Neutral_Color"
                          type={"text"}
                          placeholder={"Enter CIN. no"}
                          name={"cin_number"}
                          value={values.cin_number}
                          maxLength={21}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="mx-md-4 px-3 mx-auto"
                      style={{ maxWidth: "480px" }}
                    >

                      <div className="my-2">
                        <InputCapitalField
                          setFieldValue={setFieldValue}
                          id={"fssai_number"}
                          label={"FSSAI Number"}
                          labelColor="Neutral_Color"
                          type={"text"}
                          placeholder={"Enter FSSAI number"}
                          name={"fssai_number"}
                          maxLength={14}
                          value={values.fssai_number}
                        />
                      </div>
                      <div className="my-2">
                        <div
                          className={`Neutral_Color d-flex flex-column mb-2`}
                        >
                          <label className={`Neutral_Color fw_400 fs_14 my-1`}>
                            GST Number
                          </label>
                        </div>
                        <Field name={"gstin_number"}>
                          {({ field, meta }) => (
                            <div className="in-field d-flex">
                              <input
                                type="text"
                                {...field}
                                placeholder={`Enter GST number`}
                                className={`input input-field`}
                                value={values.gstin_number}
                                onBlur={() => {
                                  setFieldValue(
                                    "pan_number",
                                    values?.gstin_number?.substring(
                                      2,
                                      values?.gstin_number?.length - 3
                                    )
                                  );
                                }}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue(
                                    "gstin_number",
                                    value?.toUpperCase()
                                  );
                                }}
                                maxLength={15}
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name={"gstin_number"} />
                      </div>
                      <div className="my-2">
                        <div
                          className={`Neutral_Color d-flex flex-column mb-2`}
                        >
                          <label className={`Neutral_Color fw_400 fs_14 my-1`}>
                            PAN Number
                          </label>
                        </div>
                        <Field name={"pan_number"}>
                          {({ field, meta }) => (
                            <div className="in-field d-flex">
                              <input
                                type="text"
                                {...field}
                                style={{ background: "#e7e5ff" }}
                                placeholder={`Enter PAN number`}
                                className={`input input-field`}
                                value={values.pan_number}
                                disabled={true}
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name={"pan_number"} />
                      </div>
                      <div className="my-2">
                        <ValueInputField
                          id={"order_validity"}
                          label={"Order Validity"}
                          labelColor={"Neutral_Color"}
                          type={"number"}
                          placeholder={
                            "Enter number of days for order validity"
                          }
                          name={"order_validity"}
                          valueText="Days"
                          InputMaxWidth="450px"
                          value={values.order_validity}
                        />
                      </div>
                      <div className="my-2">
                        <ValueInputField
                          id={"invoicing_value"}
                          label={"Invoice Value"}
                          labelColor={"color_747f9e"}
                          type={"number"}
                          placeholder={
                            "Enter value for calculate invoicing value"
                          }
                          name={"invoicing_value"}
                          valueText="%"
                          InputMaxWidth="450px"
                          value={values.invoicing_value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Contact Details
                  </label>
                </div>
                <div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div
                        className="mx-md-4 px-3 mx-auto"
                        style={{ maxWidth: "480px" }}
                      >
                        <div className="my-2">
                          <InputField
                            id={"email"}
                            label={"Email Address"}
                            disabled={true}
                            type={"text"}
                            placeholder={"Enter email address"}
                            name={"email"}
                            value={values.email}
                          />
                        </div>
                        <div className="my-2">
                          <InputField
                            id={"mobile"}
                            label={"Mobile Number"}
                            type={"text"}
                            placeholder={"Enter mobile number"}
                            name={"mobile"}
                            maxLength={10}
                            disabled={true}
                            value={values.mobile}
                          />
                        </div>
                        <div className="my-2">
                          <InputField
                            id={"alternate_mobile"}
                            label={"Alternative Mobile Number"}
                            type={"text"}
                            placeholder={"Enter alternative mobile number"}
                            name={"alternate_mobile"}
                            maxLength={10}
                            value={values?.alternate_mobile}
                          />
                        </div>
                        <div className="my-2">
                          <label className=" mx-1 fn_Nunito fs_14 Neutral_Color">
                            Address
                          </label>
                          <div>
                            <Field name={"address_one"}>
                              {({ field, meta }) => (
                                <input
                                  {...field}
                                  placeholder="Address line 1"
                                  id={"address_one"}
                                  className={
                                    "w-100 input input-field py-2 my-1"
                                  }
                                />
                              )}
                            </Field>
                            <Field name={"address_two"}>
                              {({ field, meta }) => (
                                <input
                                  {...field}
                                  placeholder="Address line 2"
                                  id={"address_two"}
                                  className={
                                    "w-100 input input-field py-2 my-1"
                                  }
                                />
                              )}
                            </Field>
                            <Field name={"address_three"}>
                              {({ field, meta }) => (
                                <input
                                  {...field}
                                  placeholder="Address line 3"
                                  id={"address_three"}
                                  className={
                                    "w-100 input input-field py-2 my-1"
                                  }
                                />
                              )}
                            </Field>
                            {(errors.address_one && (
                              <ErrorMsg name={"address_one"} />
                            )) ||
                              (errors.address_two && (
                                <ErrorMsg name={"address_two"} />
                              )) ||
                              (errors.address_three && (
                                <ErrorMsg name={"address_three"} />
                              ))}
                          </div>
                        </div>
                        <div className="my-2">
                          <InputField
                            id={"landmark"}
                            label={"Landmark"}
                            type={"text"}
                            placeholder={"Enter landmark"}
                            name={"landmark"}
                            value={values.landmark}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="mx-md-4 px-3 mx-auto"
                        style={{ maxWidth: "480px" }}
                      >
                        <InputField
                          id={"pincode"}
                          label={"Pincode"}
                          type={"text"}
                          placeholder={"Enter pincode"}
                          name={"pincode"}
                          value={values.pincode}
                        />
                        <div className="select-container">
                          <label className="w-100 mx-1 fn_Nunito fs_14 Neutral_Color">
                            State
                          </label>
                          <Field name={"state_id"}>
                            {({ field, meta }) => (
                              <Select
                                {...field}
                                options={stateOptions}
                                styles={customStyles}
                                placeholder={"Enter your state"}
                                value={stateOptions.filter(
                                  (s) => s.value == values.state_id
                                )}
                                onChange={(selected) => {
                                  setSelectedState(selected?.value);
                                  setFieldValue("state_id", selected?.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMsg name="state_id" />
                        </div>
                        <div className="select-container">
                          <label className="w-100 mx-1 fn_Nunito fs_14 Neutral_Color">
                            District
                          </label>
                          <Field name={"district_id"}>
                            {({ field, meta }) => (
                              <Select
                                {...field}
                                options={districtOptions}
                                styles={customStyles}
                                placeholder={"Enter your district"}
                                value={districtOptions.filter(
                                  (s) => s.value == values.district_id
                                )}
                                onChange={(selected) => {
                                  setSelectedDistrict(selected?.value);
                                  setFieldValue("district_id", selected?.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMsg name="district_id" />
                        </div>
                        <div className="select-container">
                          <label className="w-100 mx-1 fw_400 fn_Nunito fs_14 Neutral_Color">
                            City
                          </label>
                          <Field name={"city_id"}>
                            {({ field, meta }) => (
                              <Select
                                {...field}
                                options={cityOptions}
                                styles={customStyles}
                                placeholder={"Enter your city"}
                                value={cityOptions.filter(
                                  (s) => s.value == values.city_id
                                )}
                                onChange={(selected) => {
                                  setSelectedCity(selected?.value);
                                  setFieldValue("city_id", selected?.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMsg name="city_id" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Documents</label>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-6">
                    <div
                      className="mx-xl-4 px-3 mx-auto my-2"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label className="Neutral_Color">
                            GST Certificate
                          </label>
                          {gstimg !== "" && gstimg !== null && (
                            <>
                              <label
                                htmlFor="GSTCertificate"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />{" "}
                                Re-UPload
                              </label>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          name="upload"
                          id="GSTCertificate"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          setFieldValue={setFieldValue}
                          onChange={(e) => docimageUpdateOne(e)}
                        />
                      </div>
                      {gstimg !== "" && gstimg !== null ? (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            <Document file={gstimg}>
                              <Page pageNumber={1} width={448} />
                            </Document>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  type="button"
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(gstimg);
                                    setFileName("GST Certificate");
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="GSTCertificate"
                            className="manufacturer-doc-upload-container w-100 text-center"
                          >
                            <div className=" w-100 text-center">
                              <img src={uploadDoc} alt="doc" className="mt-5" />
                              <p className="Medium_Purple fw_700 my-1 fs_17">
                                Choose File
                              </p>
                              <p className="color_b0b3c7 fs_12">
                                Supports: PDF files only
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="GSTCertificate"
                            accept="application/pdf"
                            style={{ display: "none" }}
                            onChange={(e) => docimageUpdateOne(e)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div
                      className="mx-xl-4 px-3 mx-auto my-2"
                      style={{ maxWidth: "480px" }}
                    >
                      <div className="my-2">
                        <div
                          className="d-flex justify-content-between align-items-end"
                          style={{ maxWidth: "450px" }}
                        >
                          <label className="Neutral_Color">
                            Drug Manufacturing License
                          </label>
                          {licenceImg !== "" && licenceImg !== null && (
                            <>
                              <label
                                htmlFor="DrugLicense"
                                style={{ textTransform: "uppercase" }}
                                className="fs_12 me-2 Medium_Purple fw_700 align-items-center"
                              >
                                <img src={reupload} className="mb-1" />{" "}
                                Re-UPload
                              </label>
                            </>
                          )}
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense"
                            accept="application/pdf"
                            onChange={(e) => docimageUpdateTwo(e)}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      {licenceImg !== "" && licenceImg !== null ? (
                        <div
                          className={` h-100`}
                          style={{
                            height: "25rem",
                            // width: "15rem",
                            // border: "1px solid black",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            scrollBehavior: "smooth",
                            position: "relative",
                          }}
                        >
                          <div className="manufacturer-doc-upload-container">
                            <Document file={licenceImg}>
                              <Page pageNumber={1} width={415} />
                            </Document>
                          </div>
                          <div
                            className="w-100"
                            style={{
                              position: "absolute",
                              zIndex: "55",
                              top: "0",
                              maxWidth: "450px",
                            }}
                          >
                            <div
                              className="manufacturer-doc-upload-container"
                              style={{
                                backgroundColor: "rgb(38, 44, 61, 0.5)",
                              }}
                            >
                              <div
                                className="align-items-center"
                                style={{
                                  position: "absolute",
                                  zIndex: "55",
                                  top: "43%",
                                  left: "40%",
                                }}
                              >
                                <img src={eye} className="mb-2" />
                                <button
                                  type="button"
                                  className="fs_16 fw_600"
                                  style={{
                                    // position: "absolute",
                                    // zIndex: "55",
                                    // top: "80px",
                                    // left: "180px",
                                    backgroundColor: "transparent",
                                    color: "#E7E5FF",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setOpenPdf(true);
                                    setPdfFileUrl(licenceImg);
                                    setFileName("Drug Manufacturing License");
                                  }}
                                >
                                  VIEW
                                </button>
                              </div>
                            </div>
                          </div>
                          <PdfView
                            show={openPdf}
                            handleClose={() => setOpenPdf(false)}
                            pdfUrl={pdfFileUrl}
                            filename={filename}
                          />
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="DrugLicense"
                            className="manufacturer-doc-upload-container w-100 text-center"
                          >
                            <div className=" w-100 text-center">
                              <img src={uploadDoc} alt="doc" className="mt-5" />
                              <p className="Medium_Purple fw_700 my-1 fs_17">
                                Choose File
                              </p>
                              <p className="color_b0b3c7 fs_12">
                                Supports: PDF files only
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="DrugLicense"
                            accept="application/pdf"
                            onChange={(e) => docimageUpdateTwo(e)}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <SuccessPopup
        show={onShowSuccess}
        title={"Manufacturer Edited Successfully"}
      />

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      <ErrorPopup
        show={catchedErrorPopup}
        title={catchedErrorMsg}
        handleClose={closeCatchedErrPopup}
      />
    </MainLayout>
  );
};

export default ManufacturerPremiumEdit;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",
    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
