import React from 'react'

const FfTrainingSvg = ({ fill = "#B0B3C7", width = "14px", height = "14px" }) => {
    return (
        <svg width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4386_23499)">

                <path d="M2.14158 0.6521C2.14121 0.6521 2.14078 0.6521 2.1404 0.6521C2.00799 0.6521 1.88345 0.703716 1.78941 0.797521C1.69434 0.892363 1.64196 1.01865 1.64196 1.15308V8.66527C1.64196 8.94074 1.86696 9.1654 2.14356 9.16611C3.30967 9.16889 5.26336 9.41193 6.61113 10.8224V2.96065C6.61113 2.86727 6.58728 2.77954 6.54226 2.70695C5.43607 0.925501 3.31033 0.654834 2.14158 0.6521Z"
                    fill={fill} />
                <path d="M12.3581 8.66531V1.15308C12.3581 1.01865 12.3057 0.892363 12.2106 0.797521C12.1166 0.703716 11.992 0.6521 11.8597 0.6521C11.8593 0.6521 11.8588 0.6521 11.8585 0.6521C10.6898 0.654881 8.56402 0.925548 7.45778 2.707C7.41277 2.77959 7.38896 2.86731 7.38896 2.96069V10.8224C8.73674 9.41193 10.6904 9.16889 11.8565 9.16611C12.1331 9.1654 12.3581 8.94074 12.3581 8.66531Z"
                    fill={fill} />
                <path d="M13.4991 2.38452H13.1359V8.66526C13.1359 9.36861 12.5628 9.94214 11.8584 9.94389C10.8693 9.94624 9.23838 10.1397 8.08335 11.2328C10.081 10.7437 12.1868 11.0617 13.387 11.3352C13.5368 11.3693 13.6917 11.334 13.8117 11.2383C13.9314 11.1429 14 11.0002 14 10.8471V2.88541C14 2.60923 13.7753 2.38452 13.4991 2.38452Z"
                    fill={fill} />
                <path d="M0.864138 8.66526V2.38452H0.500891C0.224755 2.38452 0 2.60923 0 2.88541V10.8469C0 11.0001 0.0686332 11.1428 0.18827 11.2382C0.308237 11.3339 0.462944 11.3693 0.613032 11.335C1.81317 11.0615 3.91907 10.7436 5.9166 11.2327C4.76162 10.1396 3.13073 9.9462 2.14163 9.94384C1.43724 9.94214 0.864138 9.36861 0.864138 8.66526Z"
                    fill={fill} />


            </g>
        </svg>
    )
}

export default FfTrainingSvg
