import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import back from "../../assets/icons/back.svg";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import ProductsTable from "./products-table";
import {
    invoiceVerify,
    invoiceVerifyDetail,
} from "../../redux/actions/invoiceVerificationAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import ErrorPopup from "../../components/popups/ErrorPopup";
import InvoiceverificationPages from "./invoice-questions-section/invoiceDistributorQstn";
import InvoiceImageOptions from "./invoice-image-view/invoice-image-options";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";
import InvoiceRemarks from "./invoice-questions-section/invoiceRemarks";
import "./invoice-verify.css";
import InvoiceDetailsSection from "./invoice-details-section";
import CfaInvoiceDetailsSection from "./cfa-invoice-details-section";
import ArrowUp from "../../assets/icons/indicator-up.svg";
import ArrowDown from "../../assets/icons/indicator-down.svg";
import InvoiceImageIcon from "../../../src/assets/icons/invoice-image-icon.svg";
import { getFileExtension } from "../../components/utilites/helper";


const VerifiedInvoiceView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [reuploadShow, setReuploadShow] = useState(false);
    const [verifySuccessShow, setVerifySuccessShow] = useState(false);
    const [invoiceViewData, setInvoiceViewData] = useState([]);
    const [invoiceOrderData, setInvoiceOrderData] = useState([]);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [openDistributor, setOpenDistributor] = useState(true);
    const [openCustomer, setOpenCustomer] = useState(true);
    const [openOrders, setOpenOrders] = useState(true);
    const [openSkipModal, setOpenSkipModal] = useState(false);
    const [hideCfaRemarks, setHideCfaRemarks] = useState(false);
    const [hideActualRemarks, setHideActualRemarks] = useState(false);
    const [showImageError, setShowImageError] = useState(false);
    const [showImageErrorDistri, setShowImageErrorDistri] = useState(false);
    const [showImageErrorInvOne, setShowImageErrorInvOne] = useState(false);
    const [showImageErrorCstmr, setShowImageErrorCstmr] = useState(false);
    const [isSkip, setIsSkip] = useState("");

    const [showTwoHeading, setShowTwoHeading] = useState(false);
    const [showFourHeading, setShowFourHeading] = useState(false);
    const [cfaVerifyText, setCfaVerifyText] = useState(false);

    const [stepOneComplition, setStepOneComplition] = useState(false); // VERIFICATION STEPS
    const [StepName, setStepName] = useState("Step 1"); // VERIFICATION STEPS
    const [finalStepComplition, setFinalStepComplition] = useState(false); // VERIFICATION STEPS

    const [CFAactiveInvoice, setCFAactiveInvoice] = useState(0);

    const [distributoractiveInvoice, setDistributoractiveInvoice] = useState(0);
    const [CustomeractiveInvoice, setCustomeractiveInvoice] = useState(0);

    // ------------------------ IF CFA IS TRUE START ------------------------
    const [cfastepComplition, setCfaStepComplition] = useState(false); // IF CFA IS TRUE
    // const [cfastepTwoComplition, setCfaStepTwoComplition] = useState(false); // IF CFA IS TRUE

    const InvoiceCfaData = invoiceViewData?.cfaOrderInvoices?.filter(
        (item) => item?.user_type == "cfa"
    );
    const [cfaUrl, setCfaUrl] = useState(null);

    const InvoiceCfaDistributorData = invoiceViewData?.cfaOrderInvoices?.filter(
        (item) => item?.user_type == "distributor"
    );

    const [cfaTable, setCfaTable] = useState([]);
    const [cfaInvoiceDetails, setCfaInvoiceDetails] = useState("");

    //--------------------- CFA AND DISTRIBUTOR REMARKS SKIP-------------------------------

    const [cfaRemarksValues, setCfaRemarksValues] = useState({
        is_image_clear: InvoiceCfaData && InvoiceCfaData[0]?.is_image_clear || false,
        invoice_number_match: InvoiceCfaData && InvoiceCfaData[0]?.invoice_number_match || false,
        invoice_date_match: InvoiceCfaData && InvoiceCfaData[0]?.invoice_date_match || false,
        distributor_name_match: InvoiceCfaData && InvoiceCfaData[0]?.distributor_name_match || false,
        product_name_match: InvoiceCfaData && InvoiceCfaData[0]?.product_name_match || false,
        quantity_match: InvoiceCfaData && InvoiceCfaData[0]?.quantity_match || false,
        cfa_name_match: InvoiceCfaData && InvoiceCfaData[0]?.cfa_name_match || false,
    });

    const handleSkip = (setFieldValue) => {
        setFieldValue("cfaRemarks.is_image_clear", true);
        setFieldValue("cfaRemarks.invoice_number_match", true);
        setFieldValue("cfaRemarks.invoice_date_match", true);
        setFieldValue("cfaRemarks.distributor_name_match", true);
        setFieldValue("cfaRemarks.product_name_match", true);
        setFieldValue("cfaRemarks.quantity_match", true);
        setFieldValue("cfaRemarks.cfa_name_match", true);
        // Update property values to true
        setCfaRemarksValues({
            ...cfaRemarksValues,
            is_image_clear: true,
            invoice_number_match: true,
            invoice_date_match: true,
            distributor_name_match: true,
            product_name_match: true,
            quantity_match: true,
            cfa_name_match: true,
        });
    };

    //--------------------- CFA AND DISTRIBUTOR REMARKS SKIP-------------------------------

    useEffect(() => {
        if (cfaUrl == null) {
            setCfaUrl(InvoiceCfaData?.[0]?.url);
            setCfaTable(InvoiceCfaData?.[0]?.cfaInvoiceProducts);
            setCfaInvoiceDetails(InvoiceCfaData?.[0]);
        }
    }, [InvoiceCfaDistributorData, InvoiceCfaData]);

    // ------------------------ IF CFA IS TRUE END ------------------------

    // ------------------------ DISTRIBUTOR AND CUSTOMER VERIFICATION START ------------------------

    const InvoiceDistributorData = invoiceViewData?.invoices?.filter(
        (item) => item?.user_type == "distributor"
    );
    const [distributorUrl, setDistributorUrl] = useState(null);
    const [distributorProductTable, setDistributorProductTable] = useState([]);
    const [distributorInvoiceDetails, setDistributorInvoiceDetails] =
        useState("");

    const InvoiceCustomerData = invoiceViewData?.invoices?.filter(
        (item) => item?.user_type != "distributor"
    );
    const [customerUrl, setCustomerUrl] = useState(null);

    useEffect(() => {
        if (distributorUrl == null) {
            setDistributorUrl(InvoiceDistributorData?.[0]?.url);
            setDistributorProductTable(
                InvoiceDistributorData?.[0]?.distributorInvoiceProducts
            );
            setDistributorInvoiceDetails(InvoiceDistributorData?.[0]);
        }
        if (customerUrl == null) {
            setCustomerUrl(InvoiceCustomerData?.[0]?.url);
        }
    }, [InvoiceDistributorData, InvoiceCustomerData]);

    // ------------------------ DISTRIBUTOR AND CUSTOMER VERIFICATION END ------------------------

    useEffect(() => {
        dispatch(invoiceVerifyDetail(params.id, onSuccess, onError));
        setLoading(true);
    }, []);

    const onSuccess = (data) => {
        setInvoiceViewData(data?.data?.data);
        setInvoiceOrderData(data?.data?.data?.orderProducts);
        setLoading(false);
    };
    const onError = (err) => {
        console.log(err);
        setLoading(false);
    };

    const viewPath = window.location.pathname.split('/')[2];
    console.log(viewPath, "paramas in inv view");


    const distributorData = [
        {
            heading: "Distributor ID",
            title: invoiceViewData?.distributor?.distributor_id
                ? invoiceViewData?.distributor?.distributor_id
                : "--",
        },
        {
            heading: "Firm Name",
            title: invoiceViewData?.distributor?.firm_name
                ? invoiceViewData?.distributor?.firm_name
                : "--",
        },
        {
            heading: "Mobile Number",
            title: invoiceViewData?.distributor?.mobile
                ? invoiceViewData?.distributor?.mobile
                : "--",
        },
        {
            heading: "GST Number",
            title: invoiceViewData?.distributor?.gst_number
                ? invoiceViewData?.distributor?.gst_number
                : "--",
        },
        {
            heading: "Address",
            title: `${invoiceViewData?.distributor?.address?.address_one
                ? invoiceViewData?.distributor?.address?.address_one
                : "--"
                } ${invoiceViewData?.distributor?.address?.address_two ? "," : ""} ${invoiceViewData?.distributor?.address?.address_two
                    ? invoiceViewData?.distributor?.address?.address_two
                    : ""
                }${invoiceViewData?.distributor?.address?.address_three ? "," : ""} ${invoiceViewData?.distributor?.address?.address_three
                    ? invoiceViewData?.distributor?.address?.address_three
                    : ""
                }`,
        },
        {
            heading: "state",
            title: invoiceViewData?.distributor?.state?.name
                ? invoiceViewData?.distributor?.state?.name
                : "--",
        },
        {
            heading: "City",
            title: invoiceViewData?.distributor?.city?.name
                ? invoiceViewData?.distributor?.city?.name
                : "--",
        },
    ];

    const customerData = [
        {
            heading: "Jivini’s Customer Code",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? invoiceViewData?.doctor?.code
                        ? invoiceViewData?.doctor?.code
                        : "--"
                    : invoiceViewData?.pharmacy?.code
                        ? invoiceViewData?.pharmacy?.code
                        : "--",
        },
        {
            heading: "Customer Name",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? invoiceViewData?.doctor?.name
                        ? invoiceViewData?.doctor?.name
                        : "--"
                    : invoiceViewData?.pharmacy?.pharmacy_name
                        ? invoiceViewData?.pharmacy?.pharmacy_name
                        : "--",
        },
        {
            heading: "Customer Type",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? "Doctor"
                    : invoiceViewData?.customer_type == "pharmacy"
                        ? "Pharmacy"
                        : "--",
        },
        {
            heading: "Pharmacy Type",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? invoiceViewData?.doctor?.pharmacy_type
                        ? invoiceViewData?.doctor?.pharmacy_type
                        : "--"
                    : invoiceViewData?.pharmacy?.pharmacy_type
                        ? invoiceViewData?.pharmacy?.pharmacy_type
                        : "--",
        },
        {
            heading: "City",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? invoiceViewData?.doctor?.city?.name
                        ? invoiceViewData?.doctor?.city?.name
                        : "--"
                    : invoiceViewData?.pharmacy?.city?.name
                        ? invoiceViewData?.pharmacy?.city?.name
                        : "--",
        },
        {
            heading: "Mobile Number",
            title:
                invoiceViewData?.customer_type == "doctor"
                    ? invoiceViewData?.doctor?.mobile
                        ? invoiceViewData?.doctor?.mobile
                        : "--"
                    : invoiceViewData?.pharmacy?.mobile
                        ? invoiceViewData?.pharmacy?.mobile
                        : "--",
        },
        {
            heading: "GST Number",
            title: invoiceViewData?.pharmacy?.gst_number
                ? invoiceViewData?.pharmacy?.gst_number
                : "--",
        },
        {
            heading: "Address",
            title: `${invoiceViewData?.pharmacy?.address?.address_one
                ? invoiceViewData?.pharmacy?.address?.address_one
                : "--"
                } ${invoiceViewData?.pharmacy?.address?.address_two ? "," : ""} ${invoiceViewData?.pharmacy?.address?.address_two
                    ? invoiceViewData?.pharmacy?.address?.address_two
                    : ""
                }${invoiceViewData?.pharmacy?.address?.address_three ? "," : ""} ${invoiceViewData?.pharmacy?.address?.address_three
                    ? invoiceViewData?.pharmacy?.address?.address_three
                    : ""
                }`,
        },
    ];

    const orderData = [
        {
            heading: "Order ID",
            title: invoiceViewData?.order_id ? invoiceViewData?.order_id : "--",
        },
        {
            heading: "Order Date",
            title: invoiceViewData?.createdAt
                ? invoiceViewData?.createdAt &&
                moment(invoiceViewData?.createdAt).format("DD MMM YYYY HH:mm A")
                : "--",
        },
        {
            heading: "Order Value",
            title: invoiceViewData?.total ? `₹ ${invoiceViewData?.total}` : "--",
        },
        {
            heading: "Order Status",
            title: invoiceViewData?.status ? invoiceViewData?.status : "--",
        },
        {
            heading: "Delivered  Date",
            title: invoiceViewData?.delivery_date
                ? invoiceViewData?.delivery_date &&
                moment(invoiceViewData?.delivery_date).format("DD MMM YYYY HH:mm A")
                : "--",
        },
    ];


    const initialValues = {
        order_id: invoiceViewData?.id,
        cfaRemarks: {
            is_image_clear: false,
            invoice_number_match: false,
            invoice_date_match: false,
            distributor_name_match: false,
            product_name_match: false,
            quantity_match: false,
            cfa_name_match: false,
        },

        distributorRemarks: {
            is_image_clear: InvoiceDistributorData ? InvoiceDistributorData[0]?.is_image_clear || false : false,
            invoice_number_match: InvoiceDistributorData && InvoiceDistributorData[0]?.invoice_number_match || false,
            invoice_date_match: InvoiceDistributorData && InvoiceDistributorData[0]?.invoice_date_match || false,
            distributor_name_match: InvoiceDistributorData && InvoiceDistributorData[0]?.distributor_name_match || false,
            product_name_match: InvoiceDistributorData && InvoiceDistributorData[0]?.product_name_match || false,
            quantity_match: InvoiceDistributorData && InvoiceDistributorData[0]?.quantity_match || false,
            customer_name_match: InvoiceDistributorData && InvoiceDistributorData[0]?.customer_name_match || false,
        },

        customerRemarks: {
            is_image_clear: InvoiceCustomerData && InvoiceCustomerData[0]?.is_image_clear,
            invoice_number_match: InvoiceCustomerData && InvoiceCustomerData[0]?.invoice_number_match,
            invoice_date_match: InvoiceCustomerData && InvoiceCustomerData[0]?.invoice_date_match,
            distributor_name_match: InvoiceCustomerData && InvoiceCustomerData[0]?.distributor_name_match,
            product_name_match: InvoiceCustomerData && InvoiceCustomerData[0]?.product_name_match,
            quantity_match: InvoiceCustomerData && InvoiceCustomerData[0]?.quantity_match,
            customer_name_match: InvoiceCustomerData && InvoiceCustomerData[0]?.customer_name_match,
        },
    };

    const validationSchema = Yup.object().shape({});

    const onSubmit = (values, { resetForm }) => {
        console.log(values);
        dispatch(invoiceVerify(values, onVerifySuccess, onVerifyError));
    };

    const timeOutFnc = () => {
        setVerifySuccessShow(false);
        navigate("/support/invoice-verification");
    };

    const onVerifySuccess = (data) => {
        console.log(data);
        setVerifySuccessShow(true);
        setTimeout(timeOutFnc, 2000);
    };

    const closeErrorPopup = () => {
        setShowErrorPopup(false);
    };

    const onVerifyError = (err) => {
        console.log(err);
        const str = err.data.message;
        const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
        setShowErrorMsg(str_modify);
        setShowErrorPopup(true);
    };

    const getIsSkip = (data) => {
        console.log(data, "getIsSkip");
        setIsSkip(data);
    };

    const routes = [
        { path: "/", breadcrumb: "Admin" },
        {
            path: `/support/${viewPath}`,
            breadcrumb: `/ ${viewPath}`,
        },
        {
            path: `/support/${viewPath}/view/:id`,
            breadcrumb: invoiceViewData?.distributor?.distributor_id
                ? ` / ${invoiceViewData?.distributor?.distributor_id} - view`
                : " / view",
        },
    ];
    const Breadcrumb = ({ match }) => (
        <span>
            {match?.route?.path?.endsWith("view/:id") &&
                (invoiceViewData?.distributor?.distributor_id
                    ? ` / ${invoiceViewData?.distributor?.distributor_id} - view`
                    : " / view")}
        </span>
    );

    const [userData, setUserData] = useState(null);
    const user_type = localStorage.getItem("userType");
    const is_subscribed = localStorage.getItem("is_subscribed");
    useEffect(() => {
        if (user_type === "super_user") {
            const permissionData = JSON.parse(localStorage.getItem("permissions"));
            const permissionValue = permissionData?.find(
                (item) => item?.module_name === "Support" && item
            );

            setUserData(permissionValue);
        }
    }, []);

    return (
        <MainLayout breadcrumbData={Breadcrumb}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ setFieldValue, values }) => (
                    <Form className="">
                        <div className="search-container d-flex justify-content-between ">
                            <div
                                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                                onClick={() => navigate(-1)}
                            >
                                <img src={back} alt="back_btn" />
                                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                                    BACK
                                </span>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            {loading == true ? (
                                <div className="list-spinner-container">
                                    <LoadingSpinnerTable show={loading} />
                                </div>
                            ) : (
                                <div className="fn_Nunito scrolling">
                                    <>
                                        <div className="invoice-basic-details-container my-3 mt-4 mx-4 my-auto d-flex justify-content-between align-items-center">
                                            <label className="basic-title mx-2 mb-1">
                                                Distributor Details
                                            </label>
                                            <div className="me-3">
                                                <span
                                                    onClick={() =>
                                                        setOpenDistributor(!openDistributor)
                                                    }
                                                >
                                                    {openDistributor ? (
                                                        <img src={ArrowDown} />
                                                    ) : (
                                                        <img src={ArrowUp} />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {openDistributor != true && (
                                            <>
                                                <div className="row mt-3 pt-3 ms-4 ps-2">
                                                    {distributorData.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-4 mx-xl-0"
                                                            style={{ maxWidth: "270px" }}
                                                        >
                                                            <h5 className="color_747f9e text-nowrap fs_14 fw_500 m-0">
                                                                {item.heading}
                                                            </h5>
                                                            <p
                                                                className={`fs_15 fn_Nunito fw_500 text-capitalize text-wrap`}
                                                            >
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                    <>
                                        <div className="invoice-basic-details-container my-3 mt-4 mx-4 my-auto d-flex justify-content-between align-items-center ">
                                            <label className="basic-title mx-2 mb-1">
                                                Customer Details
                                            </label>
                                            <div className="me-3">
                                                <span
                                                    onClick={() => setOpenCustomer(!openCustomer)}
                                                >
                                                    {openCustomer ? (
                                                        <img src={ArrowDown} />
                                                    ) : (
                                                        <img src={ArrowUp} />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {openCustomer != true && (
                                            <>
                                                <div className="row mt-3 pt-3 ms-4 ps-2">
                                                    {customerData.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="mb-2 col-sm-6 col-md-6 col-xxl-2 mx-4 mx-xl-0"
                                                            style={{ maxWidth: "270px" }}
                                                        >
                                                            <h5 className="color_747f9e text-nowrap fs_14 fw_500 m-0">
                                                                {item.heading}
                                                            </h5>
                                                            <p
                                                                className={`fs_15 fn_Nunito fw_500 text-capitalize text-wrap`}
                                                            >
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                    <>
                                        <div className="invoice-basic-details-container my-3 mt-4 mx-4 my-auto d-flex justify-content-between align-items-center ">
                                            <label className="basic-title mx-2 mb-1">
                                                Order Details
                                            </label>
                                            <div className="me-3">
                                                <span onClick={() => setOpenOrders(!openOrders)}>
                                                    {openOrders ? (
                                                        <img src={ArrowDown} />
                                                    ) : (
                                                        <img src={ArrowUp} />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {openOrders != true && (
                                            <>
                                                <div className="row mt-3 pt-3 ms-4 ps-2">
                                                    {orderData?.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="mb-2 col-sm-6 col-md-6 col-xxl"
                                                            style={{ maxWidth: "270px" }}
                                                        >
                                                            <h5 className="color_747f9e text-nowrap fs_14 fw_500 m-0">
                                                                {item.heading}
                                                            </h5>
                                                            {item?.heading == "Order Status" ? (
                                                                <span
                                                                    className={`${orderStatus?.find(
                                                                        (e) => e?.status == item?.title
                                                                    )?.color
                                                                        }`}
                                                                >
                                                                    {
                                                                        orderStatus?.find(
                                                                            (e) => e?.status == item?.title
                                                                        )?.value
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <p className={`fs_15 fn_Nunito fw_500 `}>
                                                                    {item.title}
                                                                </p>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                    {/* ----------------- VERIFICATION SECTION ----------------- */}
                                    {invoiceViewData?.placed_to_cfa == true ? (
                                        //  IF PLACED TO CFA IS TRUE
                                        <>
                                            {cfastepComplition && (
                                                <div className="verified-container mx-4 px-4 py-3 my-2 text-nowrap">
                                                    <div className="align-items-center d-flex">
                                                        <label className="px-3">
                                                            CFA Invoice Verification -
                                                        </label>
                                                        <label
                                                            className={
                                                                cfaVerifyText
                                                                    ? "fw_700 text-warning w-100 fs_20"
                                                                    : "fw_700 text_green w-100 fs_20"
                                                            }
                                                        >
                                                            {cfaVerifyText
                                                                ? "Skipped"
                                                                : "Verified Successfully"}
                                                        </label>
                                                    </div>
                                                    {stepOneComplition && (
                                                        <div>
                                                            <div className="align-items-center d-flex my-1">
                                                                <label className="px-3">
                                                                    Distributor Invoice Verification -
                                                                </label>
                                                                <label className="fw_700 text_green w-100 fs_20">
                                                                    Verified Successfully
                                                                </label>
                                                            </div>
                                                            {showFourHeading && (
                                                                <>
                                                                    <div className="align-items-center d-flex my-1">
                                                                        <label className="px-3">
                                                                            Customer Invoice Verification -
                                                                        </label>
                                                                        <label className="fw_700 text_green w-100 fs_20">
                                                                            Verified Successfully
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {finalStepComplition == false && (
                                                <>
                                                    {cfastepComplition == false ? (
                                                        <>
                                                            <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                                                                <label className="basic-title mx-2 mb-1">
                                                                    CFA Invoice Verification
                                                                </label>
                                                            </div>
                                                            <div className="mx-5 mt-3 d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    {showImageError && (
                                                                        <>
                                                                            <div className="d-flex justify-content-center align-items-center invoice-image-container">
                                                                                <div>
                                                                                    <img
                                                                                        src={InvoiceImageIcon}
                                                                                        className="mx-2"
                                                                                        style={{
                                                                                            height: "20px",
                                                                                            width: "20px",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <span className="fs_15">
                                                                                    Please Upload Image
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <AdminButton
                                                                        type="button"
                                                                        background="#2AD3E7"
                                                                        color="white"
                                                                        width="90px"
                                                                        style={{
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                        onClick={() => {
                                                                            setOpenSkipModal(true);
                                                                        }}
                                                                    >
                                                                        <span className="m-2">Skip</span>
                                                                    </AdminButton>
                                                                </div>
                                                            </div>
                                                            <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
                                                                <div className="col-10 col-xl-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3">
                                                                    <div>
                                                                        <CfaInvoiceDetailsSection
                                                                            data={cfaInvoiceDetails}
                                                                            total={invoiceViewData?.total}
                                                                        />
                                                                    </div>
                                                                    <label className="Medium_Purple text-nowrap fs_14 fw_500 mt-1 mb-4">
                                                                        Product Details
                                                                    </label>
                                                                    <div className="my-2 mx-lg-auto">
                                                                        <ProductsTable
                                                                            cfaUser={
                                                                                invoiceViewData?.placed_to_cfa ==
                                                                                true
                                                                            }
                                                                            cfaTbaledata={cfaTable}
                                                                            total={invoiceViewData?.total}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="col-10 col-xl-6 my-4 mx-xl-3 mx-auto mt-5 mt-xl-4"
                                                                    style={{ minHeight: "30rem" }}
                                                                >
                                                                    <div className=" d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <label className="color_747f9e text-nowrap fs_14 fw_500">
                                                                                Invoice By
                                                                            </label>
                                                                            <p
                                                                                className={`fs_15 fn_Nunito fw_500 text-capitalize`}
                                                                            >
                                                                                {invoiceViewData?.cfa?.cfa_name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className=" my-2 mx-lg-auto"
                                                                        style={{
                                                                            border: "2px solid #7065e4",
                                                                            borderRadius: "10px",
                                                                        }}
                                                                    >
                                                                        {cfaUrl && getFileExtension(cfaUrl) === "pdf" ?
                                                                            <div className="invoice-doc-container mx-2 my-2">
                                                                                <iframe src={cfaUrl} className="w-100 h-100" />
                                                                            </div> :
                                                                            <InvoiceImageOptions
                                                                                image={cfaUrl}
                                                                            />}
                                                                        {/* <InvoiceImageOptions image={cfaUrl} /> */}
                                                                    </div>
                                                                    <div
                                                                        className="mx-auto"
                                                                        style={{
                                                                            backgroundColor: "rgb(0,0,0,.2)",
                                                                            maxWidth: "60%",
                                                                            borderRadius: "7px",
                                                                        }}
                                                                    >
                                                                        {InvoiceCfaData?.length > 1 && (
                                                                            <>
                                                                                <p className="text-center Medium_Purple fw_500 mt-5">
                                                                                    Invoice images
                                                                                </p>
                                                                                <div className="d-flex justify-content-center gap-5 px-2">
                                                                                    {InvoiceCfaData?.map(
                                                                                        (item, index) => (
                                                                                            <div>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    onClick={() => {
                                                                                                        setCfaUrl(item?.url);
                                                                                                        setCfaTable(
                                                                                                            item?.cfaInvoiceProducts
                                                                                                        );
                                                                                                        setCfaInvoiceDetails(
                                                                                                            item
                                                                                                        );
                                                                                                        setCFAactiveInvoice(
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        className="invoice-list-image"
                                                                                                        src={item?.url}
                                                                                                    />
                                                                                                </div>
                                                                                                <label
                                                                                                    className={
                                                                                                        CFAactiveInvoice ==
                                                                                                            index
                                                                                                            ? "my-1 w-100 text-center text-uppercase Medium_Purple"
                                                                                                            : "my-1 w-100 text-center text-uppercase"
                                                                                                    }
                                                                                                >
                                                                                                    {item?.invoice_id}
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <>
                                                                        <div className="py-3">
                                                                            <InvoiceCfaQstn
                                                                                values={values}
                                                                                setFieldValue={setFieldValue}
                                                                                invoiceViewData={invoiceViewData}
                                                                                setCfaStepComplition={
                                                                                    setCfaStepComplition
                                                                                }
                                                                                setReuploadShow={setReuploadShow}
                                                                                // setCfaStepName={setCfaStepName}
                                                                                setCfaUrl={InvoiceCfaData?.[0]?.url}
                                                                                // setCfaUrl={null}
                                                                                setShowImageError={setShowImageError}
                                                                                handleSkip={handleSkip}
                                                                            />
                                                                        </div>
                                                                    </> */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {stepOneComplition == false ? (
                                                                <>
                                                                    <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                                                                        <label className="basic-title mx-2 mb-1">
                                                                            Invoice Verification
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        {showImageErrorInvOne && (
                                                                            <>
                                                                                <div className="d-flex invoice-image-container my-3 mt-4 mx-4">
                                                                                    <div>
                                                                                        <img
                                                                                            src={InvoiceImageIcon}
                                                                                            className="mx-2"
                                                                                            style={{
                                                                                                height: "20px",
                                                                                                width: "20px",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <span className="fs_15">
                                                                                        Please Upload Image
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
                                                                        <div className="col-10 col-xl-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3">
                                                                            <div>
                                                                                <InvoiceDetailsSection
                                                                                    data={distributorInvoiceDetails}
                                                                                    total={invoiceViewData?.total}
                                                                                />
                                                                            </div>
                                                                            <label className="Medium_Purple text-nowrap fs_14 fw_500 mt-1 mb-4">
                                                                                Product Details
                                                                            </label>
                                                                            <div className="my-2 mx-lg-auto">
                                                                                <ProductsTable
                                                                                    data={invoiceOrderData}
                                                                                    distributorProductTable={
                                                                                        distributorProductTable
                                                                                    }
                                                                                    total={invoiceViewData?.total}
                                                                                    distributor={
                                                                                        invoiceViewData?.distributor
                                                                                            ?.firm_name
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="col-10 col-xl-6 my-4 mx-xl-3 mx-auto mt-5 mt-xl-4"
                                                                            style={{ minHeight: "30rem" }}
                                                                        >
                                                                            <div className="">
                                                                                <label className="color_747f9e text-nowrap fs_14 fw_500">
                                                                                    Invoice By
                                                                                </label>
                                                                                <p
                                                                                    className={`fs_15 fn_Nunito fw_500 text-capitalize`}
                                                                                >
                                                                                    {invoiceViewData?.distributor
                                                                                        ?.firm_name
                                                                                        ? invoiceViewData?.distributor
                                                                                            ?.firm_name
                                                                                        : "--"}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className=" my-2 mx-lg-auto"
                                                                                style={{
                                                                                    border: "2px solid #7065e4",
                                                                                    borderRadius: "10px",
                                                                                }}
                                                                            >
                                                                                {distributorUrl && getFileExtension(distributorUrl) === "pdf" ?
                                                                                    <div className="invoice-doc-container mx-2 my-2">
                                                                                        <iframe src={distributorUrl} className="w-100 h-100" />
                                                                                    </div> :
                                                                                    <InvoiceImageOptions
                                                                                        image={distributorUrl}
                                                                                    />}
                                                                                {/* <InvoiceImageOptions
                                              image={distributorUrl}
                                            /> */}
                                                                            </div>
                                                                            <div
                                                                                className="mx-auto"
                                                                                style={{
                                                                                    backgroundColor: "rgb(0,0,0,.2)",
                                                                                    maxWidth: "60%",
                                                                                    borderRadius: "7px",
                                                                                }}
                                                                            >
                                                                                {InvoiceDistributorData?.length >
                                                                                    1 && (
                                                                                        <>
                                                                                            <p className="text-center Medium_Purple fw_500 mt-5">
                                                                                                Invoice images
                                                                                            </p>
                                                                                            <div className="d-flex justify-content-center gap-5 px-2 ">
                                                                                                {InvoiceDistributorData?.map(
                                                                                                    (item, index) => (
                                                                                                        <div>
                                                                                                            <div
                                                                                                                key={index}
                                                                                                                onClick={() => {
                                                                                                                    setDistributorUrl(
                                                                                                                        item?.url
                                                                                                                    );
                                                                                                                    setDistributorProductTable(
                                                                                                                        item?.distributorInvoiceProducts
                                                                                                                    );
                                                                                                                    setDistributorInvoiceDetails(
                                                                                                                        item
                                                                                                                    );
                                                                                                                    setDistributoractiveInvoice(
                                                                                                                        index
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    className="invoice-list-image"
                                                                                                                    src={item?.url}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <label
                                                                                                                className={
                                                                                                                    distributoractiveInvoice ==
                                                                                                                        index
                                                                                                                        ? "my-1 w-100 text-center text-uppercase Medium_Purple"
                                                                                                                        : "my-1 w-100 text-center text-uppercase"
                                                                                                                }
                                                                                                            >
                                                                                                                {item?.invoice_id}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <>
                                                                        <div className="py-5">
                                                                            <InvoiceverificationPages
                                                                                setFieldValue={setFieldValue}
                                                                                setStepOneComplition={
                                                                                    setStepOneComplition
                                                                                }
                                                                                setReuploadShow={setReuploadShow}
                                                                                setStepName={setStepName}
                                                                                setShowImageErrorInvOne={
                                                                                    setShowImageErrorInvOne
                                                                                }
                                                                                setDistributorUrl={
                                                                                    InvoiceDistributorData?.[0]?.url
                                                                                }
                                                                                setFinalStepComplition={setFinalStepComplition}
                                                                            // setDistributorUrl={null}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                </>
                                                            ) : (
                                                                null

                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        //  IF PLACED TO CFA IS FALSE
                                        <>
                                            {stepOneComplition == false ? (
                                                <>
                                                    <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                                                        <label className="basic-title mx-2 mb-1">
                                                            Invoice Verification
                                                        </label>
                                                    </div>
                                                    <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-lg-0 mt-xl-2">
                                                        <div
                                                            className="col-10 col-xl-6 my-4 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
                                                            style={{
                                                                height: "60rem",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            <div>
                                                                <InvoiceDetailsSection
                                                                    data={distributorInvoiceDetails}
                                                                    total={invoiceViewData?.total}
                                                                />
                                                            </div>
                                                            <label className="Medium_Purple text-nowrap fs_14 fw_500 mt-1 mb-4">
                                                                Product Details
                                                            </label>
                                                            <div className="my-2 mx-lg-auto">
                                                                <ProductsTable
                                                                    data={invoiceOrderData}
                                                                    distributorProductTable={
                                                                        distributorProductTable
                                                                    }
                                                                    total={invoiceViewData?.total}
                                                                    distributor={
                                                                        invoiceViewData?.distributor?.firm_name
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="col-10 col-xl-6 my-4 mx-xl-3 mx-auto mt-5 mt-xl-4"
                                                            style={{ minHeight: "30rem" }}
                                                        >
                                                            <div className="">
                                                                <label className="color_747f9e text-nowrap fs_14 fw_500">
                                                                    Invoice By
                                                                </label>
                                                                <p
                                                                    className={`fs_15 fn_Nunito fw_500 text-capitalize`}
                                                                >
                                                                    {invoiceViewData?.distributor?.firm_name
                                                                        ? invoiceViewData?.distributor
                                                                            ?.firm_name
                                                                        : "--"}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className=" my-2 mx-lg-auto"
                                                                style={{
                                                                    border: "2px solid #7065e4",
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                {distributorUrl && getFileExtension(distributorUrl) === "pdf" ?
                                                                    <div className="invoice-doc-container mx-2 my-2">
                                                                        <iframe src={distributorUrl} className="w-100 h-100" />
                                                                    </div> :
                                                                    <InvoiceImageOptions
                                                                        image={distributorUrl}
                                                                    />}
                                                            </div>
                                                            <div
                                                                className="mx-auto"
                                                                style={{
                                                                    backgroundColor: "rgb(0,0,0,.2)",
                                                                    maxWidth: "60%",
                                                                    borderRadius: "7px",
                                                                }}
                                                            >
                                                                {InvoiceDistributorData?.length > 1 && (
                                                                    <>
                                                                        <p className="text-center Medium_Purple fw_500 mt-5">
                                                                            Invoice images
                                                                        </p>
                                                                        <div className="d-flex justify-content-center gap-5 px-2">
                                                                            {InvoiceDistributorData?.map(
                                                                                (item, index) => (
                                                                                    <div>
                                                                                        <div
                                                                                            key={index}
                                                                                            onClick={() => {
                                                                                                setDistributorUrl(
                                                                                                    item?.url
                                                                                                );
                                                                                                setDistributorProductTable(
                                                                                                    item?.distributorInvoiceProducts
                                                                                                );
                                                                                                setDistributorInvoiceDetails(
                                                                                                    item
                                                                                                );
                                                                                                setDistributoractiveInvoice(
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className="invoice-list-image"
                                                                                                src={item?.url}
                                                                                            />
                                                                                        </div>
                                                                                        <label
                                                                                            className={
                                                                                                distributoractiveInvoice ==
                                                                                                    index
                                                                                                    ? "my-1 w-100 text-center text-uppercase Medium_Purple"
                                                                                                    : "my-1 w-100 text-center text-uppercase"
                                                                                            }
                                                                                        >
                                                                                            {item?.invoice_id}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (null
                                            )}
                                        </>
                                    )}
                                    <InvoiceRemarks
                                        values={values}
                                        cfaUser={invoiceViewData?.placed_to_cfa == true}
                                        cfaRemarksValues={cfaRemarksValues}
                                        cfaDistributorRemarksValues={cfaRemarksValues}
                                        hideCfaRemarks={hideCfaRemarks}
                                        hideActualRemarks={hideActualRemarks}
                                        isSkip={isSkip}
                                    />
                                </div>
                            )}
                        </div>
                        <ErrorPopup
                            show={showErrorPopup}
                            title={showErrorMsg}
                            handleClose={closeErrorPopup}
                        />
                    </Form>
                )}
            </Formik>
        </MainLayout>
    );
};

export default VerifiedInvoiceView;

export const orderStatus = [
    { status: "pending", value: "Approval Pending", color: "text-warning" },
    { status: "approved", value: "Approved", color: "color_2ad3e7" },
    { status: "fully_invoiced", value: "Fully Invoiced", color: "text_green" },
    {
        status: "partially_invoiced",
        value: "Partially Invoiced",
        color: "Medium_Purple",
    },
    { status: "rejected", value: "Rejected", color: "text-danger" },
    { status: "approval_failed", value: "Approval Failed", color: "text-danger" },
    { status: "cancelled", value: "Cancelled", color: "text-danger" },
    { status: "expired", value: "Expired", color: "text-danger" },
    { status: "acknowledged", value: "Acknowledged", color: "text-danger" },
    {
        status: "partially_acknowledged",
        value: "Partially acknowledged",
        color: "Medium_Purple",
    },
    { status: "hold", value: "Hold", color: "text-danger" },
];
