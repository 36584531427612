import React, { useState } from "react";
import CreateBtn from "../../components/buttons/createBtn";
import searcher from "../../assets/icons/searchInput.svg";
import VersionHistoryBtn from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import FaqListTable from "./faq-list-table";
import "./faq.css";

const FaqList = () => {
  const [search, setSearch] = useState(null);
  return (
    <MainLayout breadCrumb="ADMIN / FAQ’s">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 text-dark fw_400 px-2"
              type="text"
              placeholder="Search FAQ's here by FAQ ID, FAQ Questions..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className=" d-flex justify-content-between m-1 mb-2">
            <VersionHistoryBtn link="/faq/version-list/1" />
            <CreateBtn link="/faq/create" />
          </div>
        </div>
        <FaqListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default FaqList;
