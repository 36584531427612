import {
  ADVERTISEMENT_PHOTO,
  SETTINGS_ADVERTISEMENT_CREATE,
  SETTINGS_ADVERTISEMENT_DELETE,
  SETTINGS_ADVERTISEMENT_LIST,
  SETTINGS_CITY_CREATE,
  SETTINGS_CITY_EDIT,
  SETTINGS_CITY_LIST,
  SETTINGS_DISTRICT_CREATE,
  SETTINGS_DISTRICT_DETAIL,
  SETTINGS_DISTRICT_EDIT,
  SETTINGS_DISTRICT_LIST,
  SETTINGS_PINCODE_CREATE,
  SETTINGS_PINCODE_EDIT,
  SETTINGS_PINCODE_FILTER,
  SETTINGS_PINCODE_LIST,
  SETTINGS_PRIVACY_POLICY_EDIT,
  SETTINGS_PRIVACY_POLICY_VIEW,
  SETTINGS_REJECT_REASON_CREATE,
  SETTINGS_REJECT_REASON_DETAIL,
  SETTINGS_REJECT_REASON_LIST,
  SETTINGS_STATE_CREATE,
  SETTINGS_STATE_EDIT,
  SETTINGS_STATE_LIST,
  SETTINGS_TERMS_EDIT,
  SETTINGS_TERMS_VIEW,
} from "./types";

export const settingsStateList = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_STATE_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const settingsStateCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_STATE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsStateEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_STATE_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const settingsDistrictList = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_DISTRICT_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const settingsDistrictCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_DISTRICT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsDistrictDetail = (id, onSuccess, onError) => {
  return {
    type: SETTINGS_DISTRICT_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const settingsDistrictEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_DISTRICT_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const settingsCityList = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_CITY_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const settingsCityCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_CITY_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsCityEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_CITY_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const settingsPincodeList = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_PINCODE_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const settingsPincodeCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_PINCODE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsPincodeEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_PINCODE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const settingsRejectReasonList = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_REJECT_REASON_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const settingsRejectReasonCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_REJECT_REASON_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsRejectReasonDetail = (id, onSuccess, onError) => {
  return {
    type: SETTINGS_REJECT_REASON_DETAIL,
    id,
    onSuccess,
    onError,
  };
};
export const settingsPrivacyPolicyView = (onSuccess, onError) => {
  return {
    type: SETTINGS_PRIVACY_POLICY_VIEW,
    onSuccess,
    onError,
  };
};
export const settingsPrivacyPolicyEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_PRIVACY_POLICY_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const settingsTermsConditionView = (onSuccess, onError) => {
  return {
    type: SETTINGS_TERMS_VIEW,
    onSuccess,
    onError,
  };
};
export const settingsTermsConditionEdit = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_TERMS_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const settingsImageUploadAction = (data, onSuccess, onError) => {
  return {
    type: ADVERTISEMENT_PHOTO,
    data,
    onSuccess,
    onError,
  };
};

export const settingPincodeFilter = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_PINCODE_FILTER,
    data,
    onSuccess,
    onError,
  };
};
export const settingsAdvertisementCreate = (data, onSuccess, onError) => {
  return {
    type: SETTINGS_ADVERTISEMENT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const settingsAdvertisementList = (onSuccess, onError) => {
  return {
    type: SETTINGS_ADVERTISEMENT_LIST,
    onSuccess,
    onError,
  };
};

export const settingsAdvertisementDelete = (id, onSuccess, onError) => {
  return {
    type: SETTINGS_ADVERTISEMENT_DELETE,
    id,
    onSuccess,
    onError,
  };
};