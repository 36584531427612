import React from "react";
import { useNavigate } from "react-router-dom";

const ManufacturerVersionTableData = ({ data, index, currentPage }) => {
  const navigate = useNavigate();
  return (
    <tbody>
      <tr className="table-rows1 ">
        <td className="table-each-row-data">
          {(currentPage - 1) * 10 + (index + 1)}
        </td>
        <td className="table-each-row-data">{data.employee_id}</td>
        <td className="table-each-row-data">{data.name}</td>
        <td className="table-each-row-data">{data?.orders?.length}</td>
        <td className="table-each-row-data text-center">
          {data?.vendorDistributors?.length}
        </td>
        {data?.status == "active" ? (
          <td className="table-each-row-data text_green">Active</td>
        ) : data?.status == "inactive" ? (
          <td className="table-each-row-data text_red">Inactive</td>
        ) : (
          <td className="table-each-row-data Medium_Purple">Draft</td>
        )}
        <td className="table-each-row-data ">
          <div className="all-button d-flex align-items-center">
            <button
              className="button-view"
              type="button"
              onClick={() =>
                navigate(`/manufacturer/subscibe-list/version-view/${data.id}`)
              }
            >
              <span className=""> View</span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ManufacturerVersionTableData;
