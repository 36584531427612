import React from "react";
import dangerIcon from "../../assets/icons/dangericon.png";
import { Modal, ModalBody } from "react-bootstrap";
import "../modals/modal.css";

const ErrorPopup = ({ show, title, handleClose }) => {
  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="my-modal">
      <ModalBody className="m-4 p-4">
        <div className="p-2">
          <img
            src={dangerIcon}
            alt="mail"
            className=" mx-auto d-block"
            style={{ height: "30px" }}
          />
          <p
            className="fn_Nunito fw_600 fs_22 color_262c3d my-2 px-0 text-capitalize"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            {title}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ErrorPopup;
