import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminButton } from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import back from "../../assets/icons/back.svg";
import { useDispatch } from "react-redux";
import { faqVersionDetial } from "../../redux/actions/faqActions";
import { JSONTree } from "react-json-tree";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const FaqVersionView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [faqVersionViewData, setFaqVersionViewData] = useState([]);
  const [faqVersionViewOldData, setFaqVersionViewOldData] = useState([]);
  const [faqVersionViewNewData, setFaqVersionViewNewData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFaqVersionData();
  }, []);

  const fetchFaqVersionData = () => {
    dispatch(faqVersionDetial(params.id, onSuccess, onError));
    setLoading(true);
  };
  const onSuccess = (data) => {
    console.log(data.data.data);
    setFaqVersionViewData(data.data.data);
    setFaqVersionViewOldData(data.data.data.faqVersion.old_data);
    setFaqVersionViewNewData(data.data.data.faqVersion.new_data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
    nestedKeyLabel: {
      fontSize: "14px",
    },
    value: {
      fontSize: "14px",
    },
  };
  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (faqVersionViewData?.faq_id
          ? ` / ${faqVersionViewData?.faq_id} - VERSION HISTORY `
          : " / VERSION HISTORY ")}
    </span>
  );

  const data = [
    {
      heading: "FAQ ID",
      title: faqVersionViewData?.faq_id ? faqVersionViewData?.faq_id : "--",
    },
    {
      heading: "FAQ Questions",
      title: faqVersionViewData?.question
        ? faqVersionViewData?.question.charAt(0).toUpperCase() +
          faqVersionViewData?.question.slice(1)
        : "--",
    },
    {
      heading: "Created for",
      title: faqVersionViewData?.faqUserTypes
        ? faqVersionViewData?.faqUserTypes.map((ele, index) => {
            return (
              <span key={index}>
                {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                {ele?.user_type}
              </span>
            );
          })
        : "--",
    },
    {
      heading: "Created By",
      title: faqVersionViewData?.addedBy?.name
        ? faqVersionViewData?.addedBy?.name
        : "--",
    },

    {
      heading: "Updated By",
      title: faqVersionViewData?.faqVersion?.updatedBy?.name
        ? faqVersionViewData?.faqVersion?.updatedBy?.name
        : "--",
    },
    {
      heading: "Updated Date",
      title: moment(faqVersionViewData?.faqVersion?.updatedAt).format(
        "DD MMM YYYY HH:mm A"
      )
        ? moment(faqVersionViewData?.faqVersion?.updatedAt).format(
            "DD MMM YYYY HH:mm A"
          )
        : "--",

      // faqVersionViewData?.faqVersion?.updatedAt,
    },
  ];
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
      </div>
      {loading == true ? (
        <div className="view-spinner-container">
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <>
          <div className="pt-3 px-4 row">
            {data.map((item, index) => (
              <div className="mb-2 col-4 col-md-3 col-xl-2 col-xxl label-conatiner mx-3 ">
                <h5 className="color_b0b3c7 text-nowrap fs_15 fw_500 m-0">
                  {item.heading}
                </h5>
                <p className={` fs_16 fn_Nunito fw_600 `}>
                  {item.heading === "Created for" ? (
                    <p
                      className={` fs_16 fn_Nunito text-wrap fw_500 text-truncate faq-qstn-slice text-capitalize`}
                    >
                      {item.title}
                    </p>
                  ) : item.heading === "FAQ Questions" ? (
                    <p
                      className={` fs_16 fn_Nunito text-nowrap fw_500 text-truncate  col-12 faq-qstn-slice text-capitalize`}
                    >
                      {item.title}
                    </p>
                  ) : (
                    <p
                      className={` fs_16 fn_Nunito text-nowrap fw_600 text-capitalize`}
                    >
                      {item.title}
                    </p>
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className=" mx-3 d-none d-xl-block form-sub-details my-3 mb-4">
            <div className="d-flex justify-content-between">
              <div className="col-5 mx-4 ">JSON Old Data</div>
              <div className="col-6">
                <span className="ms-4 ps-2">JSON New Data</span>
              </div>
            </div>
          </div>
          <div className="d-xl-flex flex-column flex-md-row justify-content-between col-xl-11 gap-xxl-4 mt-5  mt-xl-0">
            <div
              className="col-10 col-xl-6 my-2 mx-auto mx-xl-3 px-xl-2 ms-xl-4 ps-xl-3"
              style={{ minHeight: "30rem" }}
            >
              <div>
                <h6
                  className="px-3 py-3 my-auto"
                  style={{
                    backgroundColor: "#E7E5FF",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Old Data
                </h6>
                <div className="">
                  <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <>
                      {faqVersionViewOldData === null ? (
                        <>Empty data</>
                      ) : (
                        <>
                          <JSONTree
                            theme={theme}
                            data={faqVersionViewOldData}
                          />
                          ;
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-10 col-xl-6 my-2 mx-xl-3 mx-auto"
              style={{ minHeight: "30rem" }}
            >
              <div>
                <h6
                  className="px-3 py-3 my-auto"
                  style={{
                    backgroundColor: "#E7E5FF",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  New Data
                </h6>
                <div className="">
                  <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <>
                      {faqVersionViewNewData === null ? (
                        <>Empty data</>
                      ) : (
                        <>
                          <JSONTree
                            theme={theme}
                            data={faqVersionViewNewData}
                          />
                          ;
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default FaqVersionView;
