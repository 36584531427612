import React, { useEffect } from "react";
import "../../modules/manufacturer/manufacturer.css";
import { useNavigate, useParams } from "react-router-dom";
import ManufacturerDetails from "../../modules/manufacturer/manufacturer-view/manufacturer-details";
import DesignationDetails from "../../modules/manufacturer/manufacturer-view/designation/designation-details";
import DesignationAdd from "../../modules/manufacturer/manufacturer-view/designation/designation-add";
import DesignationDetailsView from "../../modules/manufacturer/manufacturer-view/designation/designation-details-view";
import DesignationEdit from "../../modules/manufacturer/manufacturer-view/designation/designation-edit";
import HeadQuarterList from "../../modules/manufacturer/manufacturer-view/head-quarters/head-quarters-list";
import HeadQuarterView from "../../modules/manufacturer/manufacturer-view/head-quarters/head-quarter-view";
import HeadQuarterAdd from "../../modules/manufacturer/manufacturer-view/head-quarters/head-quarter-add";
import HeadQuaterEdit from "../../modules/manufacturer/manufacturer-view/head-quarters/head-quater-edit";
import DivisionList from "../../modules/manufacturer/manufacturer-view/division/division-list";
import DivisionView from "../../modules/manufacturer/manufacturer-view/division/division-view";
import DivisionAdd from "../../modules/manufacturer/manufacturer-view/division/division-add";
import DivisionEdit from "../../modules/manufacturer/manufacturer-view/division/division-edit";
import ProductList from "../../modules/manufacturer/manufacturer-view/product/product-list";
import ProductCreate from "../../modules/manufacturer/manufacturer-view/product/product-create";
import ProductView from "../../modules/manufacturer/manufacturer-view/product/product-view";
import ProductEdit from "../../modules/manufacturer/manufacturer-view/product/product-edit";
import FFUserList from "../../modules/manufacturer/manufacturer-view/field-force/user/user-list";
import FFUserView from "../../modules/manufacturer/manufacturer-view/field-force/user/user-view";
import FFUserEdit from "../../modules/manufacturer/manufacturer-view/field-force/user/user-edit";
import OrdersList from "../../modules/manufacturer/manufacturer-view/orders/orders-list";
import OrderView from "../../modules/manufacturer/manufacturer-view/orders/order-view";
import CfaList from "../../modules/manufacturer/manufacturer-view/cfa/cfaList";
import CfaView from "../../modules/manufacturer/manufacturer-view/cfa/cfaView";
import CfaEdit from "../../modules/manufacturer/manufacturer-view/cfa/cfaEdit";
import CfaCreate from "../../modules/manufacturer/manufacturer-view/cfa/cfaCreate";
import PurchaseOrderTable from "../../modules/manufacturer/manufacturer-view/purchase-order/purchaseOrderTable";
import InvoicedPOView from "../../modules/manufacturer/manufacturer-view/invoice/invoicedPOView";
import InvoiceList from "../../modules/manufacturer/manufacturer-view/invoice/invoice-list";
import { useDispatch } from "react-redux";

const GevViewContainer = ({ manufacturerViewData, totalOrders }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const vendorId = params?.id.split("&")?.[0];
  const activePath = params?.id.split("&")?.[1];
  const activeSubPath = params?.id.split("&")?.[2];
  const subId = params?.id.split("&")?.[3];


  return (
    <div>
      {activePath == "manufacturer-details" && (
        <ManufacturerDetails
          manufacturerViewData={manufacturerViewData}
          totalOrders={totalOrders}
        />
      )}
      {activePath == "designations" && (
        <>
          {activeSubPath == "list" && <DesignationDetails />}
          {activeSubPath == "add" && <DesignationAdd />}
          {activeSubPath == "view" && <DesignationDetailsView />}
          {activeSubPath == "edit" && <DesignationEdit />}
        </>
      )}
      {activePath == "headQuarters" && (
        <>
          {activeSubPath == "list" && <HeadQuarterList />}
          {activeSubPath == "view" && <HeadQuarterView />}
          {activeSubPath == "add" && <HeadQuarterAdd />}
          {activeSubPath == "edit" && <HeadQuaterEdit />}
        </>
      )}

      {activePath == "divisions" && (
        <>
          {activeSubPath == "list" && <DivisionList />}
          {activeSubPath == "view" && <DivisionView vendor_id={vendorId} />}
          {activeSubPath == "add" && <DivisionAdd />}
          {activeSubPath == "edit" && <DivisionEdit />}
        </>
      )}
      {activePath == "products" && (
        <>
          {activeSubPath == "list" && <ProductList />}
          {activeSubPath == "add" && <ProductCreate vendorId={vendorId} />}
          {activeSubPath == "view" && <ProductView />}
          {activeSubPath == "edit" && <ProductEdit vendorId={vendorId} />}
        </>
      )}
      {activePath == "fieldForce" && (
        <>
          {activeSubPath == "list" && <FFUserList />}
          {activeSubPath == "view" && <FFUserView vendorId={vendorId} />}
          {activeSubPath == "edit" && <FFUserEdit vendorId={vendorId} />}
        </>
      )}

      {activePath == "orders" && (
        <>
          {activeSubPath == "list" && <OrdersList />}
          {activeSubPath == "view" && <OrderView />}
        </>
      )}
      {activePath == "cfa" && (
        <>
          {activeSubPath == "list" && <CfaList />}
          {activeSubPath == "view" && <CfaView />}
          {activeSubPath == "edit" && <CfaEdit />}
          {activeSubPath == "add" && <CfaCreate />}
        </>
      )}
      {activePath == "po" && (
        <>
          <PurchaseOrderTable manufacturerViewData={manufacturerViewData} />
        </>
      )}

      {activePath == "invoices" && (
        <>
          <InvoiceList />
         
        </>

      )}
    </div>
  );
};

export default GevViewContainer;
