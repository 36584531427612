import React, { useState } from "react";
import leftArrowButton from "../../../assets/icons/leftArrowButton.svg";
import rightArrowButton from "../../../assets/icons/rightArrowButton.svg";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import DesibledRightArrow from "../../../assets/icons/Disabled-right-button.svg";
const InvoiceverificationPages = ({
  setStepName,
  setFieldValue,
  setStepOneComplition,
  setReuploadShow,
  setDistributorUrl,
  setShowImageErrorInvOne,
}) => {
  const [pageCount, setPageCount] = useState(1);
  const [showButtons, setShowButtons] = useState(true);
  const [count, setCount] = useState([]);
  const [imageClear, setImageClear] = useState(false);

  const leftClickedButton = () => {
    if (pageCount > 1) {
      setPageCount(pageCount - 1);
      setShowButtons(true);
    }
  };

  const rightClickedButton = () => {
    if (pageCount < 7) {
      setPageCount(pageCount + 1);
      setShowButtons(true);
    }
  };

  const SubmitAnswerButton = () => {
    setPageCount(pageCount + 1);
    if (pageCount == 7) {
      // setDistributorSUbmit(true);
      setStepOneComplition(true);
      setStepName("Step 1");
    }
  };
  const ImageNotClearFnc = (setFieldValue) => {
    setFieldValue("distributorRemarks.is_image_clear", false);
    setFieldValue("distributorRemarks.invoice_number_match", false);
    setFieldValue("distributorRemarks.invoice_date_match", false);
    setFieldValue("distributorRemarks.distributor_name_match", false);
    setFieldValue("distributorRemarks.product_name_match", false);
    setFieldValue("distributorRemarks.quantity_match", false);
    setFieldValue("distributorRemarks.customer_name_match", false);
  };

  return (
    <>
      <>
        {pageCount == 1 && (
          <div className="page-component pt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Invoice image</h1>
              <p className="fs_16">Is That Uploaded Picture Is Clear ?</p>
              {imageClear == true ? (
                <>
                  <AdminButton
                    type="button"
                    background="#FF6F5B"
                    color="white"
                    width="130px"
                    height="40px"
                    onClick={() => setImageClear(false)}
                  >
                    BACK
                  </AdminButton>
                  <AdminButton
                    type="button"
                    background="#7065E4"
                    color="white"
                    width="130px"
                    height="40px"
                    onClick={() => setReuploadShow(true)}
                  >
                    REUPLOAD
                  </AdminButton>
                </>
              ) : (
                <>
                  <div onClick={() => setCount([...count, 1])}>
                    <AdminButton
                      type="button"
                      background="#7065E4"
                      color="white"
                      width="130px"
                      height="40px"
                      onClick={() => {
                        if (setDistributorUrl !== null) {
                          setFieldValue(
                            "distributorRemarks.is_image_clear",
                            true
                          );
                          SubmitAnswerButton();
                        } else {
                          setShowImageErrorInvOne(true);
                          setShowButtons(false);
                        }
                      }}
                    >
                      Yes
                    </AdminButton>
                    &nbsp;
                    <AdminButton
                      type="button"
                      background="#FF6F5B"
                      color="white"
                      width="130px"
                      height="40px"
                      onClick={() => {
                        setFieldValue(
                          "distributorRemarks.is_image_clear",
                          false
                        );
                        setImageClear(true);
                        ImageNotClearFnc(setFieldValue);
                      }}
                    >
                      No
                    </AdminButton>
                  </div>
                  {showButtons && (
                    <div className="d-flex justify-content-center mt-5">
                      <div
                        className="d-flex justify-content-around"
                        style={{ width: "140px", height: "28px" }}
                      >
                        <button
                          id="left-arrow"
                          className="left-arrw-btn"
                          disabled
                          onClick={leftClickedButton}
                        >
                          <img src={leftArrowButton} />
                        </button>
                        <div id="page-count">
                          <span className="Medium_Purple fw_600">
                            {pageCount}
                          </span>
                          <span className="fw_600">/ 7</span>
                        </div>
                        <span
                          id="right-arrow"
                          onClick={
                            count[0] === 1 ? rightClickedButton : undefined
                          }
                        >
                          {count[0] === 1 ? (
                            <span>
                              <img src={rightArrowButton} />
                            </span>
                          ) : (
                            <span>
                              <img src={DesibledRightArrow} />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 2 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Invoice number</h1>
              <p className="fs_16">
                Does the Invoice Number match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 2])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.invoice_number_match",
                      true
                    );
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.invoice_number_match",
                      false
                    );
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>
              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={count[1] === 2 ? rightClickedButton : undefined}
                    >
                      {count[1] === 2 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 3 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Invoice date</h1>
              <p className="fs_16">
                Does the Invoice date match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 3])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.invoice_date_match",
                      true
                    );
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.invoice_date_match",
                      false
                    );
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>
              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={count[2] === 3 ? rightClickedButton : undefined}
                    >
                      {count[2] === 3 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 4 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Distributor name match</h1>
              <p className="fs_16">
                Does the distributor name match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 4])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.distributor_name_match",
                      true
                    );
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.distributor_name_match",
                      false
                    );
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>
              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={count[3] === 4 ? rightClickedButton : undefined}
                    >
                      {count[3] === 4 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 5 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Product name match</h1>
              <p className="fs_16">
                Does the product name match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 5])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.product_name_match",
                      true
                    );
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.product_name_match",
                      false
                    );
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>

              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={count[4] === 5 ? rightClickedButton : undefined}
                    >
                      {count[4] === 5 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 6 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Quantity match</h1>
              <p className="fs_16">
                Does the quantity match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 6])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue("distributorRemarks.quantity_match", true);
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue("distributorRemarks.quantity_match", false);
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>
              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={count[5] === 6 ? rightClickedButton : undefined}
                    >
                      {count[5] === 6 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {pageCount == 7 && (
          <div className="page-component mt-5">
            <div className="invoice-card text-center">
              <h1 className="fs_22 fw_700">Customer name match</h1>
              <p className="fs_16">
                Does the customer name match with the uploaded image ?
              </p>
              <div onClick={() => setCount([...count, 7])}>
                <AdminButton
                  type="button"
                  background="#7065E4"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.customer_name_match",
                      true
                    );
                    SubmitAnswerButton();
                  }}
                >
                  Yes
                </AdminButton>
                &nbsp;
                <AdminButton
                  type="button"
                  background="#FF6F5B"
                  color="white"
                  width="130px"
                  height="40px"
                  onClick={() => {
                    setFieldValue(
                      "distributorRemarks.customer_name_match",
                      false
                    );
                    SubmitAnswerButton();
                  }}
                >
                  No
                </AdminButton>
              </div>
              {showButtons && (
                <div className="d-flex justify-content-center mt-5">
                  <div
                    className="d-flex justify-content-around"
                    style={{ width: "140px", height: "28px" }}
                  >
                    <span id="left-arrow" onClick={leftClickedButton}>
                      <img src={leftArrowButton} />
                    </span>
                    <div id="page-count">
                      <span className="Medium_Purple fw_600">{pageCount}</span>
                      <span className="fw_600">/ 7</span>
                    </div>
                    <span
                      id="right-arrow"
                      onClick={
                        count[6] === 7 && count[6] < 7
                          ? rightClickedButton
                          : undefined
                      }
                    >
                      {count[6] === 7 ? (
                        <span>
                          <img src={rightArrowButton} />
                        </span>
                      ) : (
                        <span>
                          <img src={DesibledRightArrow} />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default InvoiceverificationPages;
