import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import back from "../../assets/icons/back.svg";
import VersionHistoryBtn, {
  AdminButton,
} from "../../components/buttons/versionHistoryBtn";
import MainLayout from "../../components/layout/mainLayout/mainLayout";
import DeleteModal from "../../components/modals/deleteModal";
import { faqDelete, faqDetail } from "../../redux/actions/faqActions";
import "./faq.css";
import ErrorPopup from "../../components/popups/ErrorPopup";
import SuccessPopup from "../../components/popups/SuccessPopup";
import { Spinner } from "react-bootstrap";
import LoadingSpinnerTable from "../../components/utilites/loaderSpinningContainer";

const FaqView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false);
  const [faqViewData, setfaqViewData] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchView();
  }, []);

  const fetchView = () => {
    dispatch(faqDetail(params.id, onSuccess, onError));
    setLoading(true);
  };

  const onSuccess = (data) => {
    console.log(data);
    setfaqViewData(data.data.data);
    setLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    setLoading(false);
  };

  const delFunc = () => {
    dispatch(faqDelete(params.id, onDelSuccess, onDeleteError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    navigate("/faq-list");
  };

  const onDelSuccess = (data) => {
    setShowSuccessPopup(true);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onDeleteError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const Breadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id") &&
        (faqViewData.faq_id ? ` / ${faqViewData.faq_id} - view` : " / view")}
    </span>
  );
  return (
    <MainLayout breadcrumbData={Breadcrumb}>
      <div className="search-container d-flex justify-content-between ">
        <div
          className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back_btn" />
          <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
            BACK
          </span>
        </div>
        <div className=" d-flex justify-content-between m-1 mb-2">
          <VersionHistoryBtn link={`/faq/version-view/${faqViewData?.id}`} />

          <AdminButton
            background="#7065E4"
            color="white"
            width="90px"
            onClick={() => navigate(`/faq/edit/${faqViewData?.id}`)}
          >
            <span className="m-2">EDIT</span>
          </AdminButton>

          <AdminButton
            background="#FF6F5B"
            color="white"
            width="100px"
            type="button"
            onClick={() => {
              setDeleteShow(true);
            }}
          >
            <span className="mx-2">DELETE</span>
          </AdminButton>
        </div>
      </div>
      {loading == true ? (
        <div className="view-spinner-container">
          <LoadingSpinnerTable show={loading} />
        </div>
      ) : (
        <div className="mx-4 px-3 mt-3 faq-form-width">
          <div className="my-2">
            <label className=" Neutral_Color fs_14">FAQ ID</label>
            <p className="fs_14 text-capitalize">{faqViewData.faq_id}</p>
          </div>
          <div className="my-2">
            <label className=" Neutral_Color fs_14">FAQ Questions</label>
            <p className="fs_14 text-capitalize">{faqViewData.question}</p>
          </div>
          <div className="my-2">
            <label className=" Neutral_Color fs_14">FAQ Answers</label>
            <p
              className="fs_14 text-capitalize"
              dangerouslySetInnerHTML={{ __html: faqViewData.answer }}
            ></p>
          </div>
          <div className="my-2">
            <label className=" Neutral_Color fs_14">Created For</label>
            <p className="fs_14 text-capitalize">
              {faqViewData?.faqUserTypes?.map((ele, index) => {
                return (
                  <span key={index}>
                    {index ? <span className="mx-1 color_b0b3c7">|</span> : ""}
                    {ele?.user_type}
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      )}

      <DeleteModal
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
        title={`FAQ - ${faqViewData.faq_id} ?`}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />

      <SuccessPopup
        show={showSuccessPopup}
        title={"FAQ Deleted Successfully"}
      />
    </MainLayout>
  );
};

export default FaqView;
