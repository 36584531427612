import React, { useEffect, useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import { useDispatch } from "react-redux";
import { AdminButton } from "../../buttons/versionHistoryBtn";
import CreatePharmacyModal from "./CreatePharmacyModal";

const PharmacyDivisionList = ({
  setActivePharmacy,
  pharmacyDivisionData,
  loading,
  setSelectedPharmacyID,
  pharmacyDivisionView,
  vendor_Id,
  division_id,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    setFilteredData(pharmacyDivisionData);
  }, [pharmacyDivisionData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value.replace(/ /g, "");
    setSearchInput(value);

    if (trimmedValue === "") {
      setFilteredData(pharmacyDivisionData);
    } else {
      const filtered = pharmacyDivisionData.filter((data) => {
        const pharmacyWithoutSpaces =
          data?.pharmacy_name
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase()) ||
          data?.mobile
            .replace(/ /g, "")
            .toLowerCase()
            .includes(trimmedValue.toLowerCase());
        return pharmacyWithoutSpaces;
      });
      setFilteredData(filtered);
    }
  };
  return (
    <>
      <div className="main-list-container my-2 py-1 d-flex justify-content-between flex-column">
        <main className="d-flex flex-column w-100 main-container">
          <div className="search-container d-flex justify-content-between align-items-center">
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 text-dark px-2"
                type="text"
                placeholder="Search pharmacies here by Pharmacy Name, Mobile Number, City, Email Address..."
                onChange={handleInputChange}
              />
            </div>
            <div>
              <AdminButton
                background="#7065e4"
                color="white"
                width="100px"
                type="submit"
                onClick={() => {
                  setOpenCreateModal(true);
                }}
              >
                <span className="m-2">CREATE</span>
              </AdminButton>
            </div>
          </div>
          <div style={{ height: "32rem", overflow: "auto" }}>
            <table className="main-table  text-nowrap">
              <thead
                style={{ position: "sticky", top: 0, zIndex: 55 }}
                className="bg-white"
              >
                <tr className="table-rows color_747f9e">
                  <th className="table-heads py-2" width={"10%"}>
                    SL. No
                  </th>
                  <th className="table-heads py-2" width={"23%"}>
                    Pharmacy Name
                  </th>
                  <th className="table-heads py-2" width={"20%"}>
                    Mobile Number
                  </th>
                  <th className="table-heads py-2" width={"15%"}>
                    City
                  </th>
                  <th className="table-heads py-2" width={"20%"}>
                    Email Address
                  </th>
                  <th className="table-heads py-2" width={"10%"}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {filteredData?.length > 0 ? (
                  filteredData?.map((data, index) => {
                    return (
                      <tr className="table-rows1 color_262c3d" key={index}>
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data">
                          {data?.pharmacy_name ? data?.pharmacy_name : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.mobile ? data?.mobile : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.city?.name ? data?.city?.name : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {data?.email ? data?.email : "--"}
                        </td>
                        <td className="table-each-row-data">
                          <button
                            className="button-view"
                            type="button"
                            onClick={() => {
                              setActivePharmacy(42);
                              setSelectedPharmacyID(data?.id);
                            }}
                          >
                            <span className=""> View</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
      <CreatePharmacyModal
        show={openCreateModal}
        handleClose={() => {
          setOpenCreateModal(false);
        }}
        pharmacyDivisionView={pharmacyDivisionView}
        vendor_Id={vendor_Id}
        division_id={division_id}
      />
    </>
  );
};

export default PharmacyDivisionList;
