import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import verifyIcon from "../../../assets/icons/verified.svg";
import "../modal.css";
import { useDispatch } from "react-redux";
import MailSent from "../../popups/MailSent";
import SuccessPopup from "../../popups/SuccessPopup";
import ErrorPopup from "../../popups/ErrorPopup";
import { DistributorsendMail } from "../../../redux/actions/distributorActions";

const DistributorSendMailModal = ({ show, handleClose, data, closeModal }) => {
  const dispatch = useDispatch();
  const [mailSent, setMailSent] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const sendMailFnc = () => {
    dispatch(
      DistributorsendMail(
        {
          distributor_id: data?.id,
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setMailSent(false);
  };

  const onSuccess = () => {
    console.log(data);
    setMailSent(true);
    handleClose();
    setTimeout(timeOutFnc, 2000);
    closeModal();
  };

  const timeOutFnc1 = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
    setTimeout(timeOutFnc1, 2000);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName=""
        contentClassName=""
      >
        <ModalBody>
          <div className="my-3 mt-4 text-center">
            {/* <img src={verifyIcon} alt="icon" className="my-2" /> */}
            <div className="color_747f9e  fw_500 fs_20 my-2">
              Mail will be sent to :
            </div>
            {/* <p className="color_747f9e fs_14 col-10 mx-auto">
            Lorem ipsum dolor sit amet consectetur. Donec risus diam vitae risus
            sem leo ac quisque. Vestibulum pretium .
          </p> */}

            {/* <p className="color_262c3d text-nowrap fs_14 fw_500 m-0">
              {data?.name}
            </p>
            <p className="color_262c3d text-nowrap fs_14 fw_500 m-0">
              {data?.employee_id}
            </p> */}
            <p className="color_262c3d text-nowrap fs_14 fw_500 m-0">
              {data?.email}
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 gap-2">
            <button
              type="button"
              className="modal-cancel-btn fs_14 text-white py-2 px-4 mx-3 fw_700"
              style={{ width: "150px" }}
              onClick={() => sendMailFnc()}
            >
              Send Mail
            </button>
          </div>
        </ModalBody>
      </Modal>
      <MailSent show={mailSent} />
      <ErrorPopup show={showErrorPopup} title={showErrorMsg} />
    </>
  );
};

export default DistributorSendMailModal;
