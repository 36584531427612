import React from "react";

const ArrowLeftIcon = ({ fill = "#B0B3C7", width = 10, height = 16 }) => {
  return (
    <div className="px-">
      <svg
        width={width}
        className=""
        height={height}
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.10061 12.082L1.34711 7.32203C1.02004 6.99343 0.836426 6.54866 0.836426 6.08503C0.836426 5.6214 1.02004 5.17664 1.34711 4.84803L6.10611 0.0820313L7.16511 1.14253L2.40611 5.90803C2.35924 5.95491 2.33291 6.01849 2.33291 6.08478C2.33291 6.15107 2.35924 6.21465 2.40611 6.26153L7.15911 11.0215L6.10061 12.082Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default ArrowLeftIcon;
