import React, { useState } from "react";
import searcher from "../../../assets/icons/searchInput.svg";
import back from "../../../assets/icons/back.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { useNavigate } from "react-router-dom";
import SuperUserRoleVersionTable from '../../../modules/super-admin/superUserRole/super-user-role-version-table'

const SuperUserRoleVersionList = () => {
  const [search, setSearch] = useState(null)
  const navigate = useNavigate();
  return (
    <MainLayout breadCrumb="ADMIN / Super User / Version History">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="d-flex w-100">
            <div
              className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
              onClick={() => navigate(-1)}
            >
              <img src={back} alt="back_btn" />
              <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                BACK
              </span>
            </div>
            <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
              <img className="styled-SVG" src={searcher} color={""} />
              <input
                className="search-input fs_14 fw_400 px-2"
                type="text"
                placeholder="Search Super User here by Role ID Role Name..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <SuperUserRoleVersionTable search={search}/>
      </div>
    </MainLayout>
  );
};

export default SuperUserRoleVersionList;