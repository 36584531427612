import { api } from "./api";

export const SuperAdminListApi = (params) => {
  return api.get(`/admin/super-admin/list`, { params });
};
export const superAdminCreateApi = (params) => {
  return api.post(`/admin/super-admin/create`, JSON.stringify(params));
};
export const superAdminEditApi = (params) => {
  return api.post(`/admin/super-admin/edit`, JSON.stringify(params));
};
export const superAdminViewApi = (params) => {
  return api.get(`/admin/super-admin/view`, { params: params });
};
export const superAdminVersionListApi = (params) => {
  return api.get(`/admin/super-admin/version-list`, { params });
};
export const superAdminVersionDetailApi = (params) => {
  return api.get(`/admin/super-admin/version-view`, { params: params });
};
export const permissionListApi = (params) => {
  return api.get(`/admin/super-admin/permissions-list`);
};
export const superAdminDeleteApi = (params) => {
  return api.delete(`/admin/super-admin/delete`, { data: params });
};

export const superAdminRoleListApi = (params) => {
  return api.get(`/admin/super-admin-role/list`, { params });
};

export const superAdminRoleCreateApi = (params) => {
  return api.post(`/admin/super-admin-role/create`, JSON.stringify(params));
};

export const superAdminRoleEditApi = (params) => {
  return api.post(`/admin/super-admin-role/edit`, JSON.stringify(params));
};

export const superAdminRoleViewApi = (params) => {
  return api.get(`/admin/super-admin-role/view`, { params: params });
};

export const superAdminRoleDeleteApi = (params) => {
  return api.delete(`/admin/super-admin-role/delete`, { data: params });
};

export const superAdminRoleVersionListApi = (params) => {
  return api.get(`/admin/super-admin-role/version-list`, { params });
};

export const superAdminRoleVersionDetailApi = (params) => {
  return api.get(`/admin/super-admin-role/version-view`, { params: params });
};

export const permissionRoleListApi = (params) => {
  return api.get(`/admin/super-admin-role/permissions-list`);
};
export const roleListApi = (params) => {
  return api.get(`/admin/super-admin/roles-list`);
};
