import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { invoiceChangeStatus } from "../../../../redux/actions/manufacturerActions";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../../popups/SuccessPopup";
import ErrorPopup from "../../../popups/ErrorPopup";

const ChangeStatusModal = ({
  show,
  handleClose,
  code,
  status,
  id,
  refreshFnc,
  refresh
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(status);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const initialValues = {
    invoice_id: id,
    status: status,
  };

  const validationSchema = Yup.object().shape({});
  
  const onSubmit = (values) => {
    console.log(values);
    dispatch(invoiceChangeStatus(values, onSuccess, onError));
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
    handleClose();
    refreshFnc();
  }

  const onSuccess = (data) => {
    console.log(data);
    setShowSuccessPopup(true)
    const str = (data.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false)
  }

  const onError = (err) => {
    console.log(err);
    const str = (err.data.message);
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName="my-modal"
      contentClassName="PO-table-container"
    >
      <ModalBody className="m-1 py-4">
        <label className="fn_Nunito fw_700 fs_18 color_262c3d">
          Change Status - {code}
        </label>
        <div
          style={{ backgroundColor: "#F8F8F8" }}
          className="align-items-center px-4 py-2 "
        >
          <div className=" d-flex align-items-start ">
            <span className="text-danger fs_14 me-2">Note: </span>
            <span className="color_747f9e fs_14">
              Please cross verify once before changing the invoice payment
              status.
            </span>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => (
            <Form className="p-1">
              <div className="mx-3 my-2">
                <label className="color_262c3d">Status</label>
                <div className="my-1 mt-2">
                  <Field
                    className="radio me-2"
                    type="radio"
                    id="paid"
                    name="status"
                    value={"paid"}
                    // checked={status == "false"}
                    onClick={(e) => {
                      // setOpenStatus(!openStatus);
                      setActive("paid");
                    }}
                  />
                  <span
                    for="paid"
                    className={
                      active === "paid"
                        ? "color_262c3d fs_14"
                        : "color_747f9e fs_14"
                    }
                  >
                    Paid
                  </span>
                </div>

                <div className="my-1">
                  <Field
                    className="radio me-2"
                    type="radio"
                    id="payment_pending"
                    name="status"
                    value={"payment_pending"}
                    // checked={status == "false"}
                    onClick={(e) => {
                      // setOpenStatus(!openStatus);
                      setActive("pending");
                    }}
                  />
                  <span
                    for="payment_pending"
                    className={
                      active === "pending"
                        ? "color_262c3d fs_14"
                        : "color_747f9e fs_14"
                    }
                  >
                    Payment Pending
                  </span>
                </div>

              </div>
              <div className="d-flex mt-3">
                <button
                  type="button"
                  className="close-btn w-50  py-2 fs_14 fs_14 fs_sm_12  me-2"
                  onClick={handleClose}
                >
                  <span className="mx-4">CANCEL</span>
                </button>
                <button
                  type="submit"
                  className="verify-btn w-50 py-2 fs_14 fs_sm_12 ms-2 fw_500"
                  onClick={() => { }}
                >
                  CONFIRM
                </button>
              </div>
            </Form>
          )}
        </Formik>

      </ModalBody>
      <SuccessPopup
        show={showSuccessPopup}
        title={showSuccessMsg}
      />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </Modal>
  );
};

export default ChangeStatusModal;
