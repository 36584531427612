import { CHAPTERS_CREATE, CHAPTERS_DELETE, CHAPTERS_DROP_DOWN, CHAPTERS_EDIT, CHAPTERS_LIST, CHAPTERS_VIEW, TRAINING_QA_CREATE, TRAINING_QA_DELETE, TRAINING_QA_EDIT, TRAINING_QA_LIST, TRAINING_QA_VIEW } from "./types";


export const chaptersList = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_LIST,
        data,
        onSuccess,
        onError,
    };
};

export const chapterView = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_VIEW,
        data,
        onSuccess,
        onError,
    };
};

export const chapterCreate = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const chapterDelete = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_DELETE,
        data,
        onSuccess,
        onError,
    };
};

export const chapterEdit = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_EDIT,
        data,
        onSuccess,
        onError,
    };
};



export const trainingQaList = (data, onSuccess, onError) => {
    return {
        type: TRAINING_QA_LIST,
        data,
        onSuccess,
        onError,
    };
};

export const trainingQaView = (data, onSuccess, onError) => {
    return {
        type: TRAINING_QA_VIEW,
        data,
        onSuccess,
        onError,
    };
};

export const trainingQaCreate = (data, onSuccess, onError) => {
    return {
        type: TRAINING_QA_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const trainingQaDelete = (data, onSuccess, onError) => {
    return {
        type: TRAINING_QA_DELETE,
        data,
        onSuccess,
        onError,
    };
};

export const trainingQaEdit = (data, onSuccess, onError) => {
    return {
        type: TRAINING_QA_EDIT,
        data,
        onSuccess,
        onError,
    };
};

export const chaptersDropDown = (data, onSuccess, onError) => {
    return {
        type: CHAPTERS_DROP_DOWN,
        data,
        onSuccess,
        onError,
    };
};