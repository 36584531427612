import { call, takeLatest } from "redux-saga/effects";

import {
    ffTraingChapterCreateApi,
    ffTraingChapterListApi,
    ffTraingChapterDeleteApi,
    ffTraingChapterEditApi,
    ffTraingChapterViewApi,
    ffTrainingQaEditApi,
    ffTrainingQaViewApi,
    ffTrainingQaDeleteApi,
    ffTrainingQaCreateApi,
    ffTrainingQaListApi,
    ffTrainingChaptersDropDownApi
} from "../apis/ffTrainingApis";
import { CHAPTERS_CREATE, CHAPTERS_DELETE, CHAPTERS_DROP_DOWN, CHAPTERS_EDIT, CHAPTERS_LIST, CHAPTERS_VIEW, TRAINING_QA_CREATE, TRAINING_QA_DELETE, TRAINING_QA_EDIT, TRAINING_QA_LIST, TRAINING_QA_VIEW } from "../actions/types";

export function* ffTrainingChapterListWorker(action) {
    try {
        const res = yield call(ffTraingChapterListApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingChapterCreateWorker(action) {
    try {
        const res = yield call(ffTraingChapterCreateApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingChapterDeleteWorker(action) {
    try {
        const res = yield call(ffTraingChapterDeleteApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingChapterViewWorker(action) {
    try {
        const res = yield call(ffTraingChapterViewApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingChapterEditWorker(action) {
    try {
        const res = yield call(ffTraingChapterEditApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

/**Training QA */
export function* ffTrainingQaListWorker(action) {
    try {
        const res = yield call(ffTrainingQaListApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingQaCreateWorker(action) {
    try {
        const res = yield call(ffTrainingQaCreateApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingQaDeleteWorker(action) {
    try {
        const res = yield call(ffTrainingQaDeleteApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingQaViewWorker(action) {
    try {
        const res = yield call(ffTrainingQaViewApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingQaEditWorker(action) {
    try {
        const res = yield call(ffTrainingQaEditApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}

export function* ffTrainingChaptersDropDownWorker(action) {
    try {
        const res = yield call(ffTrainingChaptersDropDownApi, action.data);
        console.log(res);

        if (res.status == "200") {
            yield action.onSuccess(res);
        } else {
            yield action.onError(res);
        }
    } catch (error) {
        yield action.onError({
            "data": {
                message: "Something went wrong"
            }
        })
    }
}


export function* ffTrainingChaptersListWatcher() {
    yield takeLatest(CHAPTERS_LIST, ffTrainingChapterListWorker);
}

export function* ffTrainingChaptersCreateWatcher() {
    yield takeLatest(CHAPTERS_CREATE, ffTrainingChapterCreateWorker);
}

export function* ffTrainingChaptersDeleteWatcher() {
    yield takeLatest(CHAPTERS_DELETE, ffTrainingChapterDeleteWorker);
}

export function* ffTrainingChaptersViewWatcher() {
    yield takeLatest(CHAPTERS_VIEW, ffTrainingChapterViewWorker);
}

export function* ffTrainingChaptersEditWatcher() {
    yield takeLatest(CHAPTERS_EDIT, ffTrainingChapterEditWorker);
}


export function* ffTrainingQaListWatcher() {
    yield takeLatest(TRAINING_QA_LIST, ffTrainingQaListWorker);
}

export function* ffTrainingQaCreateWatcher() {
    yield takeLatest(TRAINING_QA_CREATE, ffTrainingQaCreateWorker);
}

export function* ffTrainingQasDeleteWatcher() {
    yield takeLatest(TRAINING_QA_DELETE, ffTrainingQaDeleteWorker);
}

export function* ffTrainingQasViewWatcher() {
    yield takeLatest(TRAINING_QA_VIEW, ffTrainingQaViewWorker);
}

export function* ffTrainingQasEditWatcher() {
    yield takeLatest(TRAINING_QA_EDIT, ffTrainingQaEditWorker);
}

export function* ffTrainingChapterDropDownWatcher() {
    yield takeLatest(CHAPTERS_DROP_DOWN, ffTrainingChaptersDropDownWorker);
}