import React, { useEffect, useState } from "react";
import TableContainer from "../../../../components/layout/table-container/table-container";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  PoList,
  poCsvDownload,
  poCsvMail,
} from "../../../../redux/actions/manufacturerActions";
import moment from "moment";
import exportFromJSON from "export-from-json";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import searcher from "../../../../assets/icons/searchInput.svg";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import PurchaseOrderViewModal from "../../../../components/modals/manufacturer/purchase-order/poViewModal";

const PurchaseOrderTable = ({ manufacturerViewData }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [poData, setPoData] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const fileName = "report";
  const exportType = exportFromJSON.types.csv;
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");
  const vendorId = params?.id.split("&")?.[0];
  const [showDetail, setShowDetail] = useState(false);
  const [selectedPoId, setPoSelected] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Manufacturer" && item
      );

      setUserData(permissionValue);
    }
  }, []);
  useEffect(() => {
    dispatch(PoList(vendorId, onSuccess, onError));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(PoList({ search, vendor_id: vendorId }, onSuccess, onError));
  }, [search]);

  const onSuccess = (data) => {
    setLoading(false);
    console.log(data.data.data);
    setPoData(data?.data?.data);
    setLoading(false);
  };
  const onError = (err) => {
    setLoading(false);
    console.log(err);
    setLoading(false);
  };

  const csvUpload = (data) => {
    dispatch(
      poCsvDownload(
        { purchase_order_id: data, vendor_id: vendorId },
        onCsvSuccess,
        onCsvError
      )
    );
    console.log(data);
  };

  const onCsvSuccess = (data) => {
    console.log(data.data.data);
    const jsonArray = data.data.data?.map((item) => ({
      "PO Number": item?.po_number || "--",
      "PO Date": item?.po_date || "--",
      "Company Product ID": item?.company_product_id || "--",
      "Jivini Product ID": item?.product_id || "--",
      "Product Name": item?.product_name || "--",
      "Division Name": item?.division_name || "--",
      "MRP": item?.mrp || "--",
      "PTR": item?.ptr || "--",
      "PTS": item?.pts || "--",
      "Doctor Code": item?.doctor_code || "--",
      "Doctor Name": item?.doctor_name || "--",
      "Pharmacy Code": item?.pharmacy_code || "--",
      "Pharmacy Name": item?.pharmacy_name || "--",
      "City Name": item?.city || "--",
      "Order ID": item?.order_id || "--",
      "Order Date": item?.order_date || "--",
      "Ordered Qty": item?.order_quantity || "--",
      "Ordered Free Qty": item?.order_free_quantity || "--",
      "Gross Value Of Order Qty@Ptr": item?.product_gross_value || "--",
      "Gross Value Of Order Free Qty@Ptr": item?.gross_value_of_ordered_free_qty_ptr || "--",
      "Gross Value Of Order Qty@Pts": item?.product_gross_value_pts || "--",
      "Gross Value Of Order Free Qty@Pts": item?.gross_value_of_ordered_free_qty_pts || "--",
      "Distributors Jivini Id": item?.distributor_code || "--",
      "Distributors Name": item?.distributor_name || "--",
      "Distributors Location": item?.distributor_location || "--",
      "Distributors Invoice Id": item?.distributor_invoice_id || "--",
      "Distributors Invoice Date": item?.distributor_invoice_date ? moment(item?.distributor_invoice_date).format("DD-MM-YYYY") : "--",
      "Distributors Invoiced Qty": item?.invoice_qty || "--",
      "Distributors Invoiced Free Qty": item?.invoice_free_qty || "--",
      "Supplied Ptr": item?.invoice_free_qty || "--",
      "Gross Value Of Distributor Inv Qty@Ptr": item?.gross_value_of_dist_inv_qty_ptr || "--",
      "Gross Value Of Distributor Inv Free Qty@Ptr": item?.gross_value_of_dist_inv_free_qty_ptr || "--",
      "Gross Value Of Distributor Inv Qty@Pts": item?.gross_value_of_dist_inv_qty_pts || "--",
      "Gross Value Of Distributor Inv Free Qty@Pts": item?.gross_value_of_dist_inv_free_qty_pts || "--",
      "Gross Value Of Distributor Inv Qty@Supplied Ptr": item?.gross_value_of_dist_inv_qty_supplied_ptr || "--",
      "Gross Value Of Distributor Inv Free Qty@Supplied Ptr": item?.gross_value_of_dist_inv_free_qty_supplied_ptr || "--",
      "CFA Jivini Id": item?.distributor_cfa_code || "--",
      "CFA Name": item?.distributor_cfa_name || "--",
      "CFA Location": item?.distributor_cfa_location || "--",
      "CFA Inv No Reimbursement": item?.cfa_invoice_id || "--",
      "CFA Inv Date Reimbursement": item?.cfa_invoice_date ? moment(item?.cfa_invoice_date).format("DD-MM-YYYY") : "--",
      "Approval Linked Level 1 Name": item?.linked_level1_name || "--",
      "Approval Linked Level 1 Code": item?.linked_level1_code || "--",
      "Approval Linked Level 1 Mobile": item?.linked_level1_mobile || "--",
      "Approval Linked Level 2 Name": item?.linked_level2_name || "--",
      "Approval Linked Level 2 Code": item?.linked_level2_code || "--",
      "Approval Linked Level 2 Mobile": item?.linked_level2_mobile || "--",
    }));
    exportFromJSON({ data: jsonArray, fileName, exportType });
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onCsvError = (err) => {
    console.log(err.data);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }

  return (
    <div className="m-3 mt-4">
      <div className="search-box w-50 d-flex align-items-center p-2 m-1 mb-2">
        <img className="styled-SVG" src={searcher} color={""} />
        <input
          className="search-input fs_14 fw_400 text-dark px-2"
          type="text"
          placeholder="Search po here by id ..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        className=""
        style={{
          border: "1px solid #E7E5FF",
          borderRadius: "7px",
          overflow: "auto",
          height: "32rem",
        }}
      >
        {/* <TableContainer> */}
        <table className="main-table  text-nowrap">
          <thead
            style={{ position: "sticky", top: 0, zIndex: 55 }}
            className="bg-white"
          >
            <tr className="table-rows color_747f9e">
              <th className="table-heads py-2" width={"4%"}>
                SL No
              </th>
              <th className="table-heads" width={"10%"}>
                Purchase Order ID
              </th>
              <th className="table-heads " width={"12%"}>
                Distributor Name
              </th>
              <th className="table-heads" width={"10%"}>
                Reimbursement Method
              </th>
              <th className="table-heads" width={"10%"}>
                Generated Date(From)
              </th>
              <th className="table-heads" width={"10%"}>
                Generated Date(To)
              </th>
              <th className="table-heads" width={"10%"}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ position: "relative" }}>
            {loading == true ? (
              <div className="gev-spinner-container">
                <LoadingSpinnerTable />
              </div>
            ) : (
              <>
                {poData?.length > 0 ? (
                  poData?.map((item, index) => {
                    return (
                      <tr className="table-rows1">
                        <td className="table-each-row-data">{index + 1}</td>
                        <td className="table-each-row-data">
                          {item?.code ? item?.code : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {item.distributor?.firm_name
                            ? item.distributor?.firm_name
                            : "--"}
                        </td>
                        <td className="table-each-row-data text-capitalize">
                          {/* {item?.offer_type
                            ? item?.offer_type.charAt(0).toUpperCase() +
                            item?.offer_type.slice(1)
                            : "--"} */}
                          {item?.reimbursement_method ? reimbursementOptions[item?.reimbursement_method] : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {item?.createdAt
                            ? moment(item?.createdAt).format(
                              "DD MMM YYYY HH:mm A"
                            )
                            : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {item?.updatedAt
                            ? moment(item?.updatedAt).format(
                              "DD MMM YYYY HH:mm A"
                            )
                            : "--"}
                        </td>
                        <td className="table-each-row-data">
                          {(user_type == "super_admin" && userData?.write) ||
                            user_type == "admin" ? (
                            <>
                              <label
                                className="color_2ad3e7 fw_700 cursor_pointer mx-1"
                                onClick={() => {
                                  setPoSelected(item?.id);
                                  setShowDetail(true);
                                }}
                              >
                                VIEW
                              </label>
                              <button
                                className="button-edit1 ps-0"
                                type="button"
                                onClick={() => csvUpload(item?.id)}
                              >
                                Download PO
                              </button>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="1"
                      className="text-center no-data-container fs_16"
                    >
                      <span>~ No Data Found ~</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        {/* </TableContainer> */}
      </div>

      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
      {showDetail && (
        <PurchaseOrderViewModal
          poViewShow={showDetail}
          showClose={() => setShowDetail(false)}
          id={selectedPoId}
        />
      )}
    </div>
  );
};

export default PurchaseOrderTable;

const dummy = [
  {
    code: "POID1324567",
    name: "Distributor Name Goes Here...",
    type: "Off Quantity",
    from: "12 sep 2022, 7:33 PM",
    to: "12 sep 2022, 7:33 PM",
  },
];
