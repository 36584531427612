import React, { useEffect, useState } from "react";
import ProductBasicView from "../../../../components/manufacturer/productBasicView";
import ProductOffers from "../../../../components/manufacturer/productOffers";
import { useDispatch } from "react-redux";
import {
  gevProductDelete,
  gevProductView,
} from "../../../../redux/actions/godsEyeViewActions";
import back from "../../../../assets/icons/back.svg";
import { AdminButton } from "../../../../components/buttons/versionHistoryBtn";
import DeleteModal from "../../../../components/modals/deleteModal";
import SuccessPopup from "../../../../components/popups/SuccessPopup";
import ErrorPopup from "../../../../components/popups/ErrorPopup";
import LoadingSpinnerTable from "../../../../components/utilites/loaderSpinningContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ProductView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [productData, setProductData] = useState("");
  const [offerType, setOfferType] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const vendor_id = params?.id.split("&")?.[0];
  const selectedProductId = params?.id.split("&")?.[3];
  const location = useLocation();
  const pathVerify = location?.pathname;
  const parts = pathVerify.split("/");
  const pathType = parts[parts.length - 3];

  console.log(pathType, "sdfsdf");

  console.log(selectedProductId, "selectedProductId");
  useEffect(() => {
    gevProductViewFnc();
  }, []);

  const gevProductViewFnc = () => {
    setLoading(true);
    dispatch(gevProductView(selectedProductId, onffSuccess, onffError));
  };

  const onffSuccess = (data) => {
    console.log(data?.data?.data);
    setProductData(data?.data?.data);
    setOfferType(data?.data?.data?.vendorDivision?.offer_type);
    setLoading(false);
  };

  const onffError = (err) => {
    console.log(err);
    setLoading(false);
  };
  const delFunc = () => {
    dispatch(gevProductDelete(selectedProductId, onDelSuccess, onDeleteError));
  };

  const onDelSuccess = (data) => {
    console.log(data);
    setDeleteShow(false);
    setShowSuccessPopup(true);
    setShowSuccessMsg("Product Deleted Successfully");

    const timeOutFnc = () => {
      // setActiveProduct(71);
    };
    setTimeout(timeOutFnc, 2000);
  };

  const onDeleteError = (err) => {
    console.log(err);
    setShowErrorPopup(true);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
  };
  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  return (
    <>
      <div className="search-container d-flex justify-content-between ">
        <div></div>
        <div className="d-flex">
          <div className="my-2">
            <AdminButton
              background="#7065E4"
              color="white"
              width="100px"
              onClick={() => {
                navigate(
                  `/manufacturer/${pathType}/view/${vendor_id}&products&edit&${selectedProductId}`
                );
              }}
            >
              <span className="m-2">EDIT</span>
            </AdminButton>
          </div>
          <div className="my-2">
            <AdminButton
              className="d-none d-xl-block"
              background="#FF6F5B"
              color="white"
              width="100px"
              type="button"
              onClick={() => setDeleteShow(true)}
            >
              <span className="mx-2">DELETE</span>
            </AdminButton>
          </div>
        </div>
      </div>
      <div className="scroll-container" style={{ height: "42rem" }}>
        <div style={{ position: "relative" }}>
          {loading == true ? (
            <div className="list-spinner-container">
              <LoadingSpinnerTable />
            </div>
          ) : (
            <>
              <ProductBasicView productData={productData} />
              <ProductOffers
                offerType={offerType}
                offersList={productData?.productStateOffers}
                holding_value_unit={
                  productData?.productPackingDetail?.holding_value_unit
                }
              />
            </>
          )}
        </div>
      </div>
      <DeleteModal
        title={`product - ${productData?.product_name}`}
        delFunc={delFunc}
        show={deleteShow}
        handleClose={() => setDeleteShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default ProductView;
