import React, { useEffect, useState } from "react";
import back from "../../../../assets/icons/back.svg";
import TableContainer from "../../../../components/layout/table-container/table-container";
import { useDispatch } from "react-redux";
import moment from "moment";
import { invoiceDetails } from "../../../../redux/actions/manufacturerActions";
import { useParams } from "react-router-dom";

const InvoicedPOView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // const navigate = useNavigate();
  const activenvoiceId = params?.id.split("&")?.[4];
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  // INVOICE DETAILS DISPATCH
  useEffect(() => {
    dispatch(
      invoiceDetails(
        { invoice_id: activenvoiceId },
        onInvoiceSuccess,
        onInvoiceError
      )
    );
  }, []);

  const onInvoiceSuccess = (data) => {
    console.log(data);
    setInvoiceDetailsData(data?.data?.data);
  };

  const onInvoiceError = (err) => {
    console.log(err);
  };

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }

  return (
    <>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">Invoice Details</label>
      </div>
      <div className="my-3 mt-4 mx-4 px-3">
        <div className="my-2">
          <label className=" color_747f9e fs_14">Invoice ID</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.code
              ? invoiceDetailsData?.invoice?.code
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Invoiced Date & Time</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.createdAt
              ? moment(invoiceDetailsData?.invoice?.createdAt).format(
                "DD MMM YYYY HH:mm A"
              )
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Gross Invoiced Value</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.invoiced_value
              ? `₹ ${invoiceDetailsData?.invoice?.invoiced_value}`
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Invoiced Value</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.total_value
              ? `₹ ${invoiceDetailsData?.invoice?.total_value}`
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Ordered Qty</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.ordered_qty
              ? invoiceDetailsData?.invoice?.ordered_qty
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">No of POs</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.no_of_po
              ? invoiceDetailsData?.invoice?.no_of_po
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Taxes</label>
          <p className="fs_14">
            {invoiceDetailsData?.invoice?.taxes
              ? `₹ ${invoiceDetailsData?.invoice?.taxes}`
              : "--"}
          </p>
        </div>
        <div className="my-2">
          <label className=" color_747f9e fs_14">Status</label>
          {invoiceDetailsData?.invoice?.status == "paid" ? (
            <p className="fs_14 text_green">Paid</p>
          ) : (
            <p className="fs_14 text_red">Payment Pending</p>
          )}
        </div>
      </div>
      <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
        <label className="basic-title mx-2 mb-1">
          Invoiced Purchase Orders Details
        </label>
      </div>
      <div className="modal-table-container mt-4 mx-5">
        <TableContainer>
          <thead>
            <tr className="table-rows color_747f9e">
              <th className="table-heads " width={"3%"}>
                SL No.
              </th>
              <th className="table-heads " width={"12%"}>
                Purchase Order Id
              </th>
              <th className="table-heads " width={"12%"}>
                Reimbursement Method
              </th>
              {/* <th className="table-heads " width={"10%"}>
                Qty
              </th>
              <th className="table-heads " width={"10%"}>
                PTR
              </th> */}
              <th className="table-heads " width={"12%"}>
                Total Value
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceDetailsData?.invoice?.invoicedPos?.map((item, index) => (
              <tr className="table-rows1 ">
                <td className="table-each-row-data">{index + 1}</td>
                <td className="table-each-row-data">
                  {item?.purchaseOrder?.code ? item?.purchaseOrder?.code : "--"}
                </td>
                <td className="table-each-row-data">
                  {item?.purchaseOrder?.reimbursement_method
                    ? reimbursementOptions[item?.purchaseOrder?.reimbursement_method]
                    : "--"}
                </td>
                {/* <td className="table-each-row-data">
                  {item?.quantity ? item?.quantity : "--"}
                </td>
                <td className="table-each-row-data">
                  {item?.ptr ? item?.ptr : "--"}
                </td> */}
                <td className="table-each-row-data">
                  {item?.purchaseOrder?.total_value
                    ? `₹ ${item?.purchaseOrder?.total_value}`
                    : "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </TableContainer>

        <div style={{ backgroundColor: " #2AD3E7", borderRadius: "7px" }}>
          <div className="py-2 d-flex justify-content-between">
            <label className="mx-sm-5 mx-2 text-white fw_500">
              Total PO's Value
            </label>
            <label className="mx-sm-5 mx-2 text-white fw_500 pe-lg-5">
              ₹ {invoiceDetailsData?.invoice?.total_value}
            </label>
          </div>
          <div className="py-2 d-flex justify-content-between">
            <label className="mx-sm-5 mx-2 text-white fw_500">
              Gross Invoice Value
            </label>
            <label className="mx-sm-5 mx-2 text-white fw_500 pe-lg-5">
              ₹ {invoiceDetailsData?.invoice?.invoiced_value}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicedPOView;
