import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import { AdminButton } from "../../../components/buttons/versionHistoryBtn";
import InputField from "../../../components/form/inputField/inputField";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import back from "../../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import "../manufacturer.css";
import Select from "react-select";
import ErrorMsg from "../../../components/form/errorMsg";
import DivisionListTable from "../../../components/tables/divisionListTable";
import {
  manufacturerCreate
} from "../../../redux/actions/manufacturerActions";
import { useDispatch, useSelector } from "react-redux";
import SendMailModal from "../../../components/modals/manufacturer/send-mail-modal";
import InputCapitalField from "../../../components/form/inputField/inputCapitalField";

const ManufacturerDivisionCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reducerData = useSelector((state) => state.manufacturerCreateReducer);

  const [reimbursementActive, setReimbursementActive] = useState("");
  const [disable, setDisable] = useState(true);
  const [sendMailShow, setSendMailShowShow] = useState(false);
  const [sendMailData, setSendMailData] = useState(null);
  const [active, setActive] = useState("");
  const [divisionList, setDivisionList] = useState([]);
  const [editEnable, setEditEnable] = useState(false);
  const [editIndex, setEditIndex] = useState(0);

  useEffect(() => {
    if (reducerData?.divisions?.length > 0) {
      setDivisionList(reducerData?.divisions);
    }
  }, []);

  window.onload = () => {
    navigate("/manufacturer/subscibe-list/1");
  };

  const initialValues = {
    name: "",
    offer_type: "",
    order_limit: "",
    order_amount: "",
    reimbursement_method: "",
    approval_level: ""
  };
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Required"),
    // offer_type: Yup.string().required("Required"),
    // reimbursement_methods: Yup.string().required("Required"),
  });
  const clearFunc = (setFieldValue) => {
    setFieldValue("name", "");
    setFieldValue("offer_type", null);
    setFieldValue("order_limit", "");
    setFieldValue("order_amount", "");
    setFieldValue("reimbursement_method", "");
    setActive("")
    setReimbursementActive("")
  };

  const onSubmit = () => {
    console.log(divisionList);
    reducerData["divisions"] = divisionList;
    console.log(reducerData);
    dispatch(manufacturerCreate(reducerData, onSuccess, onError));
  };

  const onSuccess = (data) => {
    console.log(data);
    setSendMailShowShow(true);
    setSendMailData(data?.data?.data);
    // navigate("/manufacturer-list");
  };
  const onError = (err) => {
    console.log(err);
  };

  const closeModal = () => {
    navigate("/manufacturer/subscibe-list/1");
  };

  const addDivision = (values, { resetForm }) => {
    if (!editEnable) {
      setDivisionList([...divisionList, values]);
    } else {
      divisionList[editIndex] = values;
      setDivisionList([...divisionList]);
    }
    resetForm({
      values: {
        name: "",
        offer_type: "",
        order_limit: "",
        order_amount: "",
        reimbursement_method: "",
        approval_level: ""
      },
    });
    setEditEnable(false);
    setActive("")
    setReimbursementActive("")
  };

  const FormEnableCheck = () => {
    const {
      values: {
        name,
        offer_type,
        order_limit,
        order_amount,
        reimbursement_method,
        approval_level
      },
    } = useFormikContext();
    useEffect(() => {
      if (
        name.trim() != "" &&
        offer_type != "" &&
        order_limit != "" &&
        reimbursement_method != "" &&
        order_amount != "" &&
        approval_level != ""
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [name, offer_type, order_limit, order_amount, reimbursement_method, approval_level]);
    return null;
  };

  const editSetForm = (item, index, setFieldValue) => {
    setEditEnable(true);
    setEditIndex(index);
    console.log(item);
    setFieldValue("name", item?.name);
    setFieldValue("offer_type", item?.offer_type);
    setFieldValue("order_limit", item?.order_limit);
    setFieldValue("reimbursement_method", item?.reimbursement_method);
    setFieldValue("order_amount", item?.order_amount);
    setFieldValue("approval_level", item?.approval_level);
  };
  const options = [
    { value: "quantity", label: "Quantity" },
    { value: "price", label: "Price" },
    { value: "discount", label: "Discount" },
  ];

  const approvalOptions = [
    { value: "L1", label: "LEVEL 1" },
    { value: "L2", label: "LEVEL 2" },
    { value: "L3", label: "LEVEL 3" },
    { value: "L4", label: "LEVEL 4" },
    { value: "L5", label: "LEVEL 5" },
    { value: "L6", label: "LEVEL 6" },
    { value: "L7", label: "LEVEL 7" },
    { value: "L8", label: "LEVEL 8" },
    { value: "L9", label: "LEVEL 9" },
    { value: "L10", label: "LEVEL 10" },
    { value: "L11", label: "LEVEL 11" },
    { value: "L12", label: "LEVEL 12" },
    { value: "L13", label: "LEVEL 13" },
    { value: "L14", label: "LEVEL 14" },
    { value: "L15", label: "LEVEL 15" },
    { value: "admin", label: "ADMIN" },
  ];

  const reimbursementOptions = [
    { value: "forward_cfa", label: "Full Order Forward from CFA" },
    { value: "order_off_qty", label: "Order Offer Quantity" },
    { value: "full_order_qty", label: "Full Order Quantity" },
    { value: "credit_note", label: "Credit Note for Offer" }
  ];

  return (
    <MainLayout breadCrumb="ADMIN  /  Manufacturer / Create">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addDivision}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormEnableCheck />
            <div className="search-container d-flex justify-content-between ">
              <div
                className="d-flex align-items-center p-2 ms-3 m-1 mb-2 cursor_pointer"
                onClick={() => navigate(-1)}
              >
                <img src={back} alt="back_btn" />
                <span className="px-2 dark_grey_color fs_16 fn_Nunito fw_600">
                  BACK
                </span>
              </div>
              <div className=" d-flex justify-content-between m-1 mb-2">
                <AdminButton
                  background="#E7E5FF"
                  width="100px"
                  type="button"
                  onClick={() => clearFunc(setFieldValue)}
                >
                  CLEAR
                </AdminButton>
                <AdminButton
                  background="#7065e4"
                  color="white"
                  width="100px"
                  type="button"
                  onClick={onSubmit}
                >
                  <span className="m-2">SAVE</span>
                </AdminButton>
              </div>
            </div>
            <div className="scrolling">
              <div className="fn_Nunito ">
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">Divisions</label>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xl-6 col-md-9">
                  <div className="mx-4 px-3">
                    <div className="my-2">
                      <InputCapitalField
                        setFieldValue={setFieldValue}
                        id={"name"}
                        label={"Division Name"}
                        labelColor="color_262c3d"
                        type={"text"}
                        placeholder={"Enter your division name"}
                        name={"name"}
                        value={values?.name}
                      />
                      <div className="select-container">
                        <label className="w-100 mx-1 fn_Nunito fs_14">
                          Offer Type
                        </label>
                        <Field name={"offer_type"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={options}
                              styles={customStyles}
                              placeholder={"Select offer type"}
                              value={options.filter(
                                (s) => s.value == values?.offer_type
                              )}
                              onChange={(selected) => {
                                setFieldValue("offer_type", selected?.value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="offer_type" />
                      </div>
                      <div className="select-container">
                        <label className="w-100 mx-1 fn_Nunito fs_14">
                          Approval Level
                        </label>
                        <Field name={"approval_level"}>
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              options={approvalOptions}
                              styles={customStyles}
                              placeholder={"Select Approval Level"}
                              value={approvalOptions.filter(
                                (s) => s.value == values?.approval_level
                              )}
                              onChange={(selected) => {
                                setFieldValue("approval_level", selected?.value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMsg name="approval_level" />
                      </div>
                      <div className="my-2">
                        <label className="color_262c3d">
                          Reimbursement method
                        </label>
                        <div>
                          {reimbursementOptions?.map((ele, index) => (
                            <div
                              className="d-flex align-items-start my-2"
                              key={index}
                            >
                              <span className="fs_14 my-1">
                                <Field
                                  type="radio"
                                  className="radio me-1"
                                  value={ele.value}
                                  name="reimbursement_method"
                                  onClick={() => {
                                    setReimbursementActive(ele.value);
                                  }}
                                />
                              </span>
                              <label className={
                                reimbursementActive === ele.value
                                  ? "color_262c3d mx-1 mt-1"
                                  : "color_747f9e mx-1 mt-1"
                              } >{ele.label}</label>
                            </div>
                          ))}
                          <ErrorMsg name={"reimbursement_method"} />
                        </div>
                      </div>
                      <div className="my-2">
                        <label className="">Order limit per customer</label>
                        <div className="d-flex gap-2">
                          <div className="d-flex align-items-center ">
                            <Field
                              className="radio me-2"
                              type="radio"
                              id="Weekly"
                              name="order_limit"
                              value="weekly"
                              onClick={() => {
                                setActive("Weekly");
                              }}
                            />
                            <label
                              for="Weekly"
                              className={
                                active === "Weekly"
                                  ? "color_262c3d"
                                  : "color_747f9e"
                              }
                            >
                              Weekly
                            </label>
                          </div>
                          <div className="d-flex align-items-center ">
                            <Field
                              className="radio mx-2"
                              type="radio"
                              id="Monthly"
                              name="order_limit"
                              value="monthly"
                              onClick={() => {
                                setActive("Monthly");
                              }}
                            />
                            <label
                              for="Monthly"
                              className={
                                active === "Monthly"
                                  ? "color_262c3d"
                                  : "color_747f9e"
                              }
                            >
                              Monthly
                            </label>
                          </div>
                          <div className="d-flex align-items-center ">
                            <Field
                              className="radio mx-2"
                              type="radio"
                              id="Quarterly"
                              name="order_limit"
                              value="quarterly"
                              onClick={() => {
                                setActive("Quarterly");
                              }}
                            />
                            <label
                              for="Quarterly"
                              className={
                                active === "Quarterly"
                                  ? "color_262c3d"
                                  : "color_747f9e"
                              }
                            >
                              Quarterly
                            </label>
                          </div>
                        </div>
                        <ErrorMsg name={"order_limit"} />
                        <InputField
                          id={"order_amount"}
                          // label={"Division Name"}
                          labelColor="color_262c3d"
                          type={"number"}
                          placeholder={"Enter Order Amount"}
                          name={"order_amount"}
                          value={values?.order_amount}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ maxWidth: "450px" }}
                    >
                      {!editEnable ? (
                        <button
                          type="submit"
                          className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                          style={{ maxWidth: "210px" }}
                          disabled={disable}
                        >
                          Add More
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="add-button fs_14 fw_700 fs_sm_12 px-4 py-2 w-50"
                          style={{ maxWidth: "210px" }}
                          disabled={disable}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="basic-details-container my-3 mt-4 mx-4 my-auto d-flex align-items-center ">
                  <label className="basic-title mx-2 mb-1">
                    Divisions List
                  </label>
                </div>
                <div className="mx-4 px-3 my-3 mb-5">
                  <DivisionListTable
                    divisionList={divisionList}
                    reimbursement={reimbursementOptions}
                    setFieldValue={setFieldValue}
                    editSetForm={editSetForm}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SendMailModal
        data={sendMailData}
        show={sendMailShow}
        closeModal={closeModal}
        handleClose={() => {
          navigate("/manufacturer/subscibe-list/1");
          setSendMailShowShow(false);
        }}
      />
    </MainLayout>
  );
};

export default ManufacturerDivisionCreate;
export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #B0B3C7",
    color: "#464646",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "450px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B0B3C7",
      fontSize: "14px",
      fontFamily: "Nunito Sans",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#a9a3e3", // Custom colour
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E7E5FF" : null,
    color: "#00000",

    // fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#E7E5FF" : "#E7E5FF",
    },
  }),
};
