import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import TableContainer from "../../../layout/table-container/table-container";
import "../manufacturerModal.css";
import GenerateInvoiceMailSendModal from "./InvoiceDownloadSendModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { generateInvoice } from "../../../../redux/actions/manufacturerActions";
import SuccessPopup from "../../../popups/SuccessPopup";
import ErrorPopup from "../../../popups/ErrorPopup";

const GenerateInvoiceTableModal = ({
  show,
  handleClose,
  invoiceTableData,
  manufacturerViewData,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [invoiceMailShow, setInvoiceMailShow] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const vendorId = params?.id.split("&")?.[0];

  const reimbursementOptions = {
    "forward_cfa": "Full Order Forward from CFA",
    "order_off_qty": "Order Offer Quantity",
    "full_order_qty": "Full Order Quantity",
    "credit_note": "Credit Note for Offer"
  }

  const invoiceDetails = [
    {
      title: "Month",
      data: moment(invoiceTableData?.[0]?.createdAt).format("MMMM"),
    },
    {
      title: "Year",
      data: moment(invoiceTableData?.[0]?.createdAt).format("YYYY"),
    },
    {
      title: "Reimbursement Method",
      data: reimbursementOptions[invoiceTableData?.[0]?.reimbursement_method],
    },
  ];
  const onSubmitGenerate = () => {
    dispatch(
      generateInvoice(
        {
          vendor_id: vendorId,
          reimbursement_method: invoiceTableData?.[0]?.reimbursement_method,
          month: moment(invoiceTableData?.[0]?.createdAt).format("MM"),
          year: moment(invoiceTableData?.[0]?.createdAt).format("YYYY"),
        },
        onSuccess,
        onError
      )
    );
  };

  const timeOutFnc = () => {
    setShowSuccessPopup(false);
  };

  const onSuccess = (data) => {
    console.log(data);
    setGeneratedData(data?.data?.data);
    setShowSuccessPopup(true);
    const str = data.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowSuccessMsg(str_modify);
    setTimeout(timeOutFnc, 2000);
  };

  const closeErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const onError = (err) => {
    console.log(err);
    const str = err.data.message;
    const str_modify = str.charAt(0).toUpperCase() + str.slice(1);
    setShowErrorMsg(str_modify);
    setShowErrorPopup(true);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="invoice-table-modal"
        contentClassName="PO-table-container fn_Nunito p-2"
        size="xl"
      >
        <ModalBody>
          <label className="fn_Nunito fw_700 fs_24 color_262c3d">
            Generate Invoice
          </label>
          <br />
          <span className="fs_14 color_747f9e fn_Nunito">
            Lorem ipsum dolor sit amet consectetur. Nisi lectus mattis elit
            quam.
          </span>
          <div className="row mt-3 ">
            {invoiceDetails?.map((item) => (
              <div
                className="mb-2 col-sm-6 col-lg-3 col-xl-2  "
                style={{ maxWidth: "150px" }}
              >
                <h5 className="color_747f9e text-nowrap fs_15 fw_500 m-0">
                  {item?.title}
                </h5>
                <p className={` fs_13 fn_Nunito fw_400 `}>{item?.data}</p>
              </div>
            ))}
          </div>
          <div className="modal-table-container">
            <TableContainer>
              <thead>
                <tr className="table-rows color_747f9e">
                  <th className="table-heads " width={"3%"}>
                    SL No.
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Purchase Order ID
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Generated Date(From)
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Generated Date(To)
                  </th>
                  <th className="table-heads " width={"12%"}>
                    Purchase Order Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceTableData?.map((item, index) => (
                  <tr className="table-rows1 ">
                    <td className="table-each-row-data">{index + 1}</td>
                    <td className="table-each-row-data">{item?.code}</td>
                    <td className="table-each-row-data">
                      {moment(item?.createdAt).format("DD MMM YYYY HH:mm A")}
                    </td>
                    <td className="table-each-row-data">
                      {moment(item?.createdAt).format("DD MMM YYYY HH:mm A")}
                    </td>
                    <td className="table-each-row-data">
                      ₹ {item?.total_value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="close-btn  px-5 py-2  fs_14  me-2"
              onClick={handleClose}
            >
              <span className="mx-4">CANCEL</span>
            </button>
            <button
              type="button"
              className="filter-btn px-5 py-2 fs_14  ms-2 fw_500"
              onClick={() => {
                onSubmitGenerate();
                handleClose();
                setInvoiceMailShow(true);
              }}
            >
              GENERATE INVOICE
            </button>
          </div>
        </ModalBody>
      </Modal>
      <GenerateInvoiceMailSendModal
        manufacturerViewData={manufacturerViewData}
        generatedData={generatedData}
        show={invoiceMailShow}
        handleClose={() => setInvoiceMailShow(false)}
      />
      <SuccessPopup show={showSuccessPopup} title={showSuccessMsg} />
      <ErrorPopup
        show={showErrorPopup}
        title={showErrorMsg}
        handleClose={closeErrorPopup}
      />
    </>
  );
};

export default GenerateInvoiceTableModal;
