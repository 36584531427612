import React, { useEffect, useState } from "react";
import CreateBtn from "../../../components/buttons/createBtn";
import searcher from "../../../assets/icons/searchInput.svg";
import MainLayout from "../../../components/layout/mainLayout/mainLayout";
import ReasonRejectionListTable from "./reasonRejectionListTable";

const ReasonRejectionList = () => {
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState(null);

  const user_type = localStorage.getItem("userType");
  useEffect(() => {
    if (user_type === "super_admin") {
      const permissionData = JSON.parse(localStorage.getItem("permissions"));
      const permissionValue = permissionData?.find(
        (item) => item?.module_name === "Settings" && item
      );

      setUserData(permissionValue);
    }
  }, []);

  return (
    <MainLayout breadCrumb="ADMIN  /  settings  /  Reasons for rejection">
      <div>
        <div className="search-container d-flex justify-content-between ">
          <div className="search-box w-50 d-flex align-items-center p-2 ms-3 m-1 mb-2">
            <img className="styled-SVG" src={searcher} color={""} />
            <input
              className="search-input fs_14 fw_400 px-2"
              type="text"
              placeholder="Search here by Reason For Rejection..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className=" d-flex justify-content-between m-1 mb-2">
            {/* <VersionHistoryBtn link="/faq-version-list" /> */}
            {(user_type == "super_admin" && userData?.write) ||
            user_type == "admin" ? (
              <CreateBtn link="/settings/reason-for-rejection/create" />
            ) : (
              ""
            )}
          </div>
        </div>
        <ReasonRejectionListTable search={search} />
      </div>
    </MainLayout>
  );
};

export default ReasonRejectionList;
