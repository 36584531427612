import React from "react";
import { useNavigate } from "react-router-dom";
import PasswordChangedImage from "../../../assets/images/auth/passwordChangedImage.png";
import "./forgot-reset-password.css";

const PasswordChanged = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex   justify-content-center align-items-center ">
      <div className=" verification-container  flex-column d-flex justify-content-center align-items-center ">
        <div className="ms-5">
          <img
            src={PasswordChangedImage}
            alt="website logo"
            className="verification-image"
          />
        </div>
        <div>
          <div className="mt-4 pt-1  col-12 text-center">
            <span className="fs_30 fs_md_26 color_262c3d fn_Nunito fw_700 ">
              Password Changed!
            </span>
          </div>
          <div className=" col-10 text-center mx-auto px-sm-5">
            <span className="  fs_20 fs_md_18 color_b0b3c7 fn_Nunito fw_500  ">
              Your Password has been changed successfully.
            </span>
          </div>
          <div className="w-50 mx-auto ">
            <button
              className="verification-button fs_16 text-center mt-4 py-1 px-2"
              type="button"
              onClick={() => navigate("/")}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChanged;
