import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PanViewer } from "react-image-pan-zoom-rotate";
import zooomin from "../../../assets/icons/image-tools/zoom-in.svg";
import zooomout from "../../../assets/icons/image-tools/zoom-out.svg";
import rotate from "../../../assets/icons/image-tools/rotate.svg";
import refresh from "../../../assets/icons/image-tools/refresh.svg";

const InvoiceImageOptions = ({ image, alt, ref }) => {
  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [flip, setFlip] = useState(false);

  const resetAll = () => {
    setDx(0);
    setDy(0);
    setZoom(1);
    setRotation(0);
    setFlip(false);
  };
  const zoomIn = () => {
    setZoom(zoom + 0.2);
  };

  const zoomOut = () => {
    if (zoom >= 1) {
      setZoom(zoom - 0.2);
    }
  };

  const rotateLeft = () => {
    if (rotation === -3) {
      setRotation(0);
    } else {
      setRotation(rotation - 1);
    }
  };

  const flipImage = () => {
    setFlip(!flip);
  };

  const onPan = (dx, dy) => {
    setDx(dx);
    setDy(dy);
  };

  const zooomIn = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Zoom In
    </Tooltip>
  );
  const zooomOut = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Zoom out
    </Tooltip>
  );
  const rotatett = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Rotate
    </Tooltip>
  );
  const reseett = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Reset
    </Tooltip>
  );

  return (
    <div className="position-relative" style={{ overflow: "hidden" }}>
      <div
        className="d-flex gap-2"
        style={{
          position: "absolute",
          right: "10px",
          zIndex: 2,
          bottom: 10,
          userSelect: "none",
          borderRadius: 2,
          //   background: "#fff",
          boxShadow: "0px 2px 6px rgba(53, 67, 93, 0.32)",
        }}
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={zooomIn}
        >
          <div

            className="p-2"
            onClick={zoomIn}
            style={{
              textAlign: "center",
              cursor: "pointer",
              height: 40,
              width: 40,
              borderRadius: "5px",
              background: "#E7E5FF",
            }}
          >
            <img src={zooomin} />
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={zooomOut}
        >
          <div
            className="p-2"
            onClick={zoomOut}
            style={{
              textAlign: "center",
              cursor: "pointer",
              height: 40,
              width: 40,
              borderRadius: "5px",
              background: "#E7E5FF",
            }}
          >
            <img src={zooomout} />
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={rotatett}
        >
          <div
            className="p-2"
            onClick={rotateLeft}
            style={{
              textAlign: "center",
              cursor: "pointer",
              height: 40,
              width: 40,
              borderRadius: "5px",
              background: "#E7E5FF",
            }}
          >
            <img src={rotate} />
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={reseett}
        >
          <div
            className="p-2"
            onClick={resetAll}
            style={{
              textAlign: "center",
              cursor: "pointer",
              height: 40,
              width: 40,
              borderRadius: "5px",
              background: "#E7E5FF",
            }}
          >
            <img src={refresh} />
          </div>
        </OverlayTrigger>
      </div>
      <PanViewer
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
        zoom={zoom}
        setZoom={setZoom}
        pandx={dx}
        pandy={dy}
        onPan={onPan}
        rotation={rotation}
        key={dx}
      >
        <div className="invoice-doc-container">
          <img
            style={{
              transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})`,
              maxHeight: "100%",
              width: "100%",
              borderRadius: "10px",
            }}
            src={image}
            alt={alt}
            ref={ref}
          />
        </div>
      </PanViewer>
    </div>
  );
};

export default InvoiceImageOptions;
